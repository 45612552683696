/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Checkbox,
  CssBaseline,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Box,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import parsePhoneNumber from 'libphonenumber-js';
import { Button } from 'primereact/button';
import axios from 'axios';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import 'yup-phone';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { CardLayout } from '../components/CardLayout';
import { MainButton } from '../components/common/MainButton';
import FormInput from '../components/common/FormInput';
import PhoneInput from '../components/common/PhoneInput';
import { Dialog } from 'primereact/dialog';
import { withTranslation } from 'react-i18next';
import FormInputGrid from '../components/common/FormInputGrid';
import Spinner from '../components/common/Spinner';

import { Dropdown } from 'primereact/dropdown';
import i18next from 'i18next';
import { IconFlagTR, IconFlagDE, IconFlagUS } from 'material-ui-flags';
import IconButton from '@material-ui/core/IconButton';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import PhoneInputEn from '../components/common/PhoneInputEn';
const useStyles = makeStyles((theme) => ({
  card: {
    padding: 20,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  commChannels: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  register: {
    '&:hover': {
      textDecoration: 'underline',
    },
    textTransform: 'none',
  },
}));

const PreRegister = ({ t }) => {
  const Schema = Yup.object().shape({
    stationaryPhone: Yup.string().notRequired().nullable(),
    password: Yup.string()
      .required(`${t('schema_shape.empty_password')}`)
      .min(8, `${t('schema_shape.min_password')}`),
    email: Yup.string()
      .email(`${t('schema_shape.valid_email')}`)
      .required(`${t('schema_shape.not_empty')}`),
    confirmPassword: Yup.string()
      .required(`${t('schema_shape.empty_password')}`)
      .oneOf(
        [Yup.ref('password'), null],
        `${t('schema_shape.match_password')}`
      ),
    emailRepeat: Yup.string()
      .email(`${t('schema_shape.valid_email')}`)
      .required(`${t('schema_shape.not_empty')}`)
      .oneOf([Yup.ref('email')], 'Email do not match'),
  });

  const { enqueueSnackbar } = useSnackbar();
  const selected = localStorage.getItem('i18nextLng') || 'en';

  const params = useParams();
  const token = params.id;
  const toast = React.useRef(null);
  let history = useHistory();

  const classes = useStyles();

  const [commChannels, setCommChannels] = useState([]);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [isDoctor, setIsDoctor] = useState(false);
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [user, setUser] = useState({});
  const [phone, setPhone] = useState(user.mobilePrefix);
  const [testPackages, setTestPackages] = useState([]);
  const [testPackage, setTestPackage] = useState({});
  const [selectedTestPackage, setSelectedTestPackage] = useState({});
  const [postUser, setPostUser] = useState({});
  const [loading, setLoading] = useState(false);

  const [lang, setLang] = useState({});

  const {
    handleSubmit,
    control,
    register,
    watch,
    errors,
    clearErrors,
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      fullPhone: user.fullPhone,
      name: user.name,
      surname: user.surname,
      email: user.email,
      emailRepeat: user.emailRepeat,
    },
  });
  const updateDoctor = () => setIsDoctor(!isDoctor);

  const onSubmit = (registerForm) => {
    const formData = new FormData();

    const image = file;
    delete registerForm['image'];

    const phoneNumber = parsePhoneNumber('+' + registerForm['mobilePhone']);
    if (phoneNumber) {
      registerForm['mobilePrefix'] = parseInt(phoneNumber.countryCallingCode);
      registerForm['mobilePhone'] = phoneNumber.nationalNumber;
    }

    if (registerForm['stationaryPhone']) {
      const stationaryPhone = parsePhoneNumber(
        '+' + registerForm['stationaryPhone']
      );
      if (stationaryPhone) {
        registerForm['mobilePhone'] = parseInt(stationaryPhone.nationalNumber);
      }
    }

    delete registerForm['password2'];
    registerForm['commChannels'] = commChannels.join();
    const registerFormData = new Blob([JSON.stringify(registerForm)], {
      type: 'application/json',
    });
    formData.append('registrationComm', registerFormData);
    formData.append('file', image);

    history.push({
      pathname: '/sms-dogrulama',
      state: { password: registerForm.confirmPassword, token },
    });
  };

  React.useEffect(() => {
    const subscription = watch((value) => {
      if (value.email === value.emailRepeat) {
        clearErrors('emailRepeat');
      } else {
        setError('emailRepeat', {
          type: 'oneOf',
          message: `${t('email_doesnt_match')}`,
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [clearErrors, setError, watch, errors]);

  const getUserInfo = () => {
    setLoading(true);
    axios
      .get('/users/getPreRegistrationUser', { params: { token } })
      .then((res) => {
        let result = res.data; // Bunu ekledikten sonra çeviri yapıyor ama bayrak ilk girişte değişmiyor
        setLang(result.preferredLanguage);
        setUser({
          name: result.name,
          surname: result.surname,
          email: result.email,
          fullPhone: result.mobilePrefix + result.mobilePhone,
          emailRepeat: result.email,
        });

        setPostUser({ ...result });
        setTestPackage(result.assessmentPackages);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const historyPush = () => {
    setDisplayResponsive(false);
    history.push({
      pathname: '/giris',
      state: { verificationPending: true },
    });
  };

  const styles = {
    header: {
      textAlign: 'center',
    },
  };
  const RegisterDialog = () => {
    return (
      <Dialog
        header={t('information')}
        headerStyle={{ textAlign: 'center' }}
        headerClassName={styles.header}
        footer={renderFooter}
        visible={displayResponsive}
        breakpoints={{ '960px': '75vw', '640px': '100vw' }}
        style={{ width: '50vw' }}
      >
        {t('register_success_message')}
      </Dialog>
    );
  };

  const renderFooter = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <MainButton
          type='submit'
          variant='contained'
          color='primary'
          className={classes.submit}
          onClick={() => historyPush()}
          autoFocus
        >
          {t('ok')}
        </MainButton>
      </div>
    );
  };

  React.useEffect(() => {
    axios.get('/users/getAssessmentPackages').then((res) => {
      setTestPackages(res.data);
    });
  }, []);

  let matchedPackage = testPackages.filter(
    (item) => item.id === testPackage?.id
  );

  useEffect(() => {
    setSelectedTestPackage(matchedPackage[0]);
  }, [matchedPackage]);

  const languageItems = [
    { label: 'English', value: 'en', code: 'US' },
    { label: 'Türkçe', value: 'tr', code: 'TR' },
  ];
  const onLangChange = (e) => {
    setLang(e.value);
    i18next.changeLanguage(e.value);
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className='country-item country-item-value'>
          {option.value === 'en' ? (
            <IconButton className={classes.muiIcon}>
              <IconFlagUS />
            </IconButton>
          ) : (
            <IconButton className={classes.muiIcon}>
              <IconFlagTR />
            </IconButton>
          )}
          <div>{option.label}</div>
        </div>
      );
    }
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className='country-item'>
        {option.value === 'en' ? (
          <IconButton className={classes.muiIcon}>
            <IconFlagUS />
          </IconButton>
        ) : (
          <IconButton className={classes.muiIcon}>
            <IconFlagTR />
          </IconButton>
        )}
        <div>{option.label}</div>
      </div>
    );
  };

  const confirm = async (registerForm) => {
    const isValidEmails = await validateEmail(registerForm.email);
    if (isValidEmails) {
      onSubmit(registerForm);
    } else {
      confirmDialog({
        message: `${t('enqueueSnackbar_message.english_charachter_valid')}`,
        header: `${t('appointment_alert_messages.confirmation')}`,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: `${t('yes')}`,
        rejectLabel: `${t('no')}`,
        accept: () => onSubmit(registerForm),
        reject: () => reject(),
      });
    }
  };

  const reject = () => {
    toast.current.show({
      severity: 'warn',
      summary: `${t('appointment_alert_messages.denied')}`,
      detail: `${t('appointment_alert_messages.action_declined')}`,
      life: 3000,
    });
  };

  const validateEmail = async (email) => {
    const re =
      /^([a-zA-Z0-9]+[._]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[._]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChangeEmail = (value) => {
    return value.toLowerCase();
  };

  // const userUpdate = (value) => {
  //   let user = postUser;
  //   user.assessmentPackages = value;
  //   const updateData = JSON.stringify(user);
  //   axios
  //     .post('users/updatePreRegistration', updateData, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     .catch((err) =>
  //       enqueueSnackbar(`${t('enqueueSnackbar_message.user_update_error')}`, {
  //         variant: 'error',
  //       })
  //     );
  // };

  // function handleChange(e) {
  //   const value = e.target.value;
  //   setTestPackage(value);
  //   userUpdate(value);
  // }

  useEffect(() => {
    reset(user);
  }, [user]);
  return (
    <>
      <ConfirmDialog />
      <Spinner loading={loading} />
      <Toast ref={toast} />
      <CardLayout title={t('register')} preferredLanguage={lang}>
        <CssBaseline />
        <RegisterDialog />
        <div className={classes.paper}>
          <Grid item={true} xs={12} sm={10} md={8} lg={6}>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(confirm)}
              encType='multipart/form-data'
            >
              <Controller
                name='name'
                control={control}
                render={(props) => (
                  <>
                    <label>{t('name')}</label>
                    <FormInput
                      {...props}
                      margin='dense'
                      required
                      disabled
                      defaultValue={user.name}
                      readOnly={true}
                    />
                  </>
                )}
              />

              <Controller
                name='surname'
                control={control}
                render={(props) => (
                  <>
                    <label>{t('surname')}</label>
                    <FormInput
                      {...props}
                      margin='dense'
                      required
                      disabled
                      defaultValue={user.surname}
                      readOnly={true}
                    />
                  </>
                )}
              />
              <Controller
                name='email'
                control={control}
                render={(props) => (
                  <>
                    <label>{t('email')}</label>
                    <FormInputGrid
                      // placeholder={t('email') + '*:'}
                      {...props}
                      handleChangeEmail={handleChangeEmail}
                      margin='dense'
                      required
                      disabled
                      defaultValue={user.email}
                      readOnly={true}
                    />
                  </>
                )}
              />
              <Controller
                name='emailRepeat'
                control={control}
                render={(props) => (
                  <>
                    <label>{t('email_repeat')}</label>
                    <FormInputGrid
                      // placeholder={t('email') + '*:'}
                      {...props}
                      handleChangeEmail={handleChangeEmail}
                      margin='dense'
                      required
                      disabled
                      defaultValue={user.emailRepeat}
                      readOnly={true}
                    />
                  </>
                )}
              />

              <Controller
                control={control}
                id='autocomplete-off'
                name='assessmentPackages'
                autocomplete='off'
                render={(props) => (
                  <div style={{ marginTop: '10px' }}>
                    <label>{t('test_package')}</label>
                    <Dropdown
                      {...register}
                      style={{ width: '100%' }}
                      options={testPackages}
                      appendTo='self'
                      value={testPackage}
                      disabled
                      // onChange={(e) => {
                      //   handleChange(e);
                      // }}
                      optionLabel='packageName'
                      placeholder={selectedTestPackage?.packageName}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                id='autocomplete-off'
                name='preferredLanguage'
                autocomplete='off'
                render={(props) => (
                  <div
                    className='countryFlagLocation'
                    style={{ marginTop: '10px' }}
                  >
                    <label>{t('preferred_language')}</label>
                    <Dropdown
                      style={{ width: '100%', height: '45px' }}
                      appendTo='self'
                      value={lang}
                      options={languageItems}
                      onChange={onLangChange}
                      disabled
                      optionLabel='preferredLanguage'
                      placeholder={setLang?.preferredLanguage}
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                    />
                  </div>
                )}
              />
              {selected === 'tr' ? (
                <Controller
                  name='fullPhone'
                  control={control}
                  render={(props) => (
                    <PhoneInput
                      placeholder={t('mobile_phone') + '*:'}
                      {...props}
                      readOnly={true}
                      disabled
                    />
                  )}
                />
              ) : (
                <Controller
                  name='fullPhone'
                  control={control}
                  render={(props) => (
                    <PhoneInputEn
                      placeholder={t('mobile_phone') + '*:'}
                      {...props}
                      readOnly={true}
                      disabled
                    />
                  )}
                />
              )}

              <Controller
                name='password'
                control={control}
                render={(props) => (
                  <>
                    <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                      {t('password_min_8')}
                    </span>
                    <FormInput
                      {...props}
                      margin='dense'
                      type='password'
                      required
                      label={t('password')}
                    />
                  </>
                )}
              />

              <Controller
                name='confirmPassword'
                control={control}
                render={(props) => (
                  <FormInput
                    {...props}
                    type='password'
                    margin='dense'
                    required
                    label={t('repeat_password')}
                  />
                )}
              />

              <MainButton
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                {t('Register')}
              </MainButton>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <Button
                    onClick={historyPush}
                    component={Link}
                    to='/giris'
                    color='secondary'
                    className={classes.register}
                  >
                    {t('have_account_message')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </div>
      </CardLayout>
    </>
  );
};

export default withTranslation()(PreRegister);
