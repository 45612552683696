import React from 'react';
import DashboardLayout from '../components/DashboardLayout';
import Users from './Users';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import Scores from './Scores';
import DashboardHome from './DashboardHome';
import AdminRoute from '../service/AdminRoute';
import NotFound404 from './NotFound404';
import GeneralSearch from './../components/GeneralSearch';
import IndividualSearch from './../components/IndividualSearch';
import AppointmentCalendar from './AppointmentCalendar';
import PendingAppointments from './PendingAppointments';
import NotCompletedAppointments from './NotCompletedAppointments';
import DoctorOrAdminRoute from '../service/DoctorOrAdminRoute';
import DoctorAppointmentCalendar from './DoctorAppointmentCalendar';
import DoctorRoute from '../service/DoctorRoute';
import DoctorsPatients from './DoctorsPatients';
import CompletedAppointments from './CompletedAppointments';
import Appointments from './Appointments';
import PendingDoctors from './PendingDoctors';
import Reports from './Reports-last';
import Stations from './Station';
import Locations from './Location';
import LocationInfo from '../components/LocationInfo';
import StationInfo from '../components/StationInfo';
import { ExpertReviewAppointment } from '../components/ExpertReviewAppointment';
import ActiveAppointments from './ActiveAppointments';
import AppointmentList from '../components/AppointmentList';
import { UserInfo } from '../components/UserInfo';
import { AppointmentResults } from '../components/AppointmentResults';
import { SmsVeritification } from '../components/SmsVeritification';
import PreRegisteredUsers from './PreRegisteredUsers';
import { UserPreInfo } from '../components/UserPreInfo';
import AutoReportText from './AutoReportText';
import Customers from './Customer';
import CustomerInfo from '../components/CustomerInfo';
import AutoReportAll from './auto-report/AutoReportAll';
import AutoReportPending from './auto-report/AutoReportPending';
import AutoReportConfirmed from './auto-report/AutoReportConfirmed';
import AutoReportPreparing from './auto-report/AutoReportPreparing';
import AutoReportErrors from './auto-report/AutoReportErrors';
import AutoReportPrepared from './auto-report/AutoReportPrepared';
import CompanyCenterLocations from './CompanyCenterLocations';
import CompanySizes from './CompanySizes';
import ExperienceLevels from './ExperienceLevels';
import IndustryCategories from './IndustryCategories';
import Generations from './Generations';
import CompanyCenterLocationInfo from '../components/CompanyCenterLocationInfo';
import CompanySizeInfo from '../components/CompanySizeInfo';
import ExperienceLevelInfo from '../components/ExperinceLevelInfo';
import IndustryCategoryInfo from '../components/IndustryCategoryInfo';
import GenerationInfo from '../components/GenerationInfo';
import SarfCompetencies from './SarfCompetencies';
import SarfCompetenciesDomain from './SarfCompetenciesDomain';
import SarfModules from './SarfModules';
import SarfPackages from './SarfPackages';
import SarfCompetencyDomainInfo from '../components/SarfCompetencyDomainInfo';
import SarfCompetencyInfo from '../components/SarfCompetencyInfo';
import SarfModuleInfo from '../components/SarfModuleInfo';
import SarfPackageInfo from '../components/SarfPackageInfo';
import PcList from './PcList';
import PcInfo from '../components/PcInfo';
import SarfParameterTemplateConfiguration from '../components/SarfParameterTemplate/SarfParameterTemplateConfiguration';
import SarfReportDefinitions from './SarfReportDefinitions';
import UserIdQuery from './UserIdQuery';
import SystemLogs from './SystemLogs';
import LocationAdmins from './LocationAdmins';
import Users_Pagination from './Users_Pagination';
import PreRegisteredUsers_Pagination from './PreRegisteredUsers_Pagination';
import Appointments_Pagination from './Appointments_Pagination';
import ActiveAppointments_Pagination from './ActiveAppointments_Pagination';
import NotCompletedAppointments_Pagination from './NotCompletedAppointments_Pagination';
import PendingAppointments_Pagination from './PendingAppointments_Pagination';
import CompletedAppointments_Pagination from './CompletedAppointments_Pagination';
import MailLogs from './MailLogs';
const Dashboard = () => {
	let { path } = useRouteMatch();
	const params = useParams();

	return (
		<DashboardLayout>
			<Switch>
				<DoctorOrAdminRoute exact path={path}>
					<DashboardHome />
				</DoctorOrAdminRoute>
				<AdminRoute exact path={path}>
					<DashboardHome />
				</AdminRoute>
				<AdminRoute exact path={`${path}/genel-arama`}>
					<GeneralSearch />
				</AdminRoute>
				<AdminRoute exact path={`${path}/bireysel-arama`}>
					<IndividualSearch />
				</AdminRoute>

				<Route
					path={`${path}/kullanicilar/:id`}
					render={(props) => <UserInfo {...props} />}
				/>
				<Route
					path={`${path}/kullanici-on-kayit/:id`}
					render={(props) => <UserPreInfo {...props} />}
				/>
				<AdminRoute path={`${path}/kullanicilar`}>
					<Users />
				</AdminRoute>
				<AdminRoute path={`${path}/onkayitli-kullanicilar`}>
					<PreRegisteredUsers />
				</AdminRoute>
				<AdminRoute path={`${path}/kullanicilar-pagination`}>
					<Users_Pagination />
				</AdminRoute>
				<AdminRoute path={`${path}/onkayitli-kullanicilar-pagination`}>
					<PreRegisteredUsers_Pagination />
				</AdminRoute>
				<AdminRoute path={`${path}/kullanici-id-sorgulama`}>
					<UserIdQuery />
				</AdminRoute>
				<AdminRoute path={`${path}/skorlar`}>
					<Scores />
				</AdminRoute>
				<AdminRoute path={`${path}/bekleyen-doktorlar`}>
					<PendingDoctors />
				</AdminRoute>
				<AdminRoute path={`${path}/randevu-takvimi`}>
					<AppointmentCalendar />
				</AdminRoute>
				<AdminRoute path={`${path}/aktif-randevular`}>
					<ActiveAppointments />
				</AdminRoute>
				<AdminRoute path={`${path}/onaylanmislar`}>
					<NotCompletedAppointments />
				</AdminRoute>
				<AdminRoute path={`${path}/bekleyen-randevular`}>
					<PendingAppointments />
				</AdminRoute>
				<DoctorRoute path={`${path}/doktor-randevu-takvimi`}>
					<DoctorAppointmentCalendar />
				</DoctorRoute>
				<AdminRoute path={`${path}/tüm-randevular`}>
					<Appointments />
				</AdminRoute>

				<AdminRoute path={`${path}/aktif-randevular-pagination`}>
					<ActiveAppointments_Pagination />
				</AdminRoute>
				<AdminRoute path={`${path}/onaylanmislar-pagination`}>
					<NotCompletedAppointments_Pagination />
				</AdminRoute>
				<AdminRoute path={`${path}/bekleyen-randevular-pagination`}>
					<PendingAppointments_Pagination />
				</AdminRoute>

				<AdminRoute path={`${path}/tüm-randevular-pagination`}>
					<Appointments_Pagination />
				</AdminRoute>
				<AdminRoute path={`${path}/tamamlanmis-randevular-pagination`}>
					<CompletedAppointments_Pagination />
				</AdminRoute>
				<DoctorRoute path={`${path}/doktorlar-hastalar`}>
					<DoctorsPatients />
				</DoctorRoute>

				<AdminRoute path={`${path}/oto-rapor-tum-liste`}>
					<AutoReportAll />
				</AdminRoute>
				<AdminRoute path={`${path}/oto-rapor-bekleyen`}>
					<AutoReportPending />
				</AdminRoute>
				<AdminRoute path={`${path}/oto-rapor-onaylanmis`}>
					<AutoReportConfirmed />
				</AdminRoute>
				<AdminRoute path={`${path}/oto-rapor-hazirlaniyor`}>
					<AutoReportPreparing />
				</AdminRoute>
				<AdminRoute path={`${path}/oto-rapor-hazirlandi`}>
					<AutoReportPrepared />
				</AdminRoute>
				<AdminRoute path={`${path}/oto-rapor-hata`}>
					<AutoReportErrors />
				</AdminRoute>

				<Route
					path={`${path}/tamamlanmis-randevular/:id`}
					render={(props) => <ExpertReviewAppointment {...props} />}
				/>
				<Route
					path={`${path}/sms-dogrulama`}
					render={(props) => <SmsVeritification {...props} />}
				/>
				<AdminRoute path={`${path}/tamamlanmis-randevular`}>
					<CompletedAppointments />
				</AdminRoute>

				<AdminRoute path={`${path}/randevu-takvimi`}>
					<AppointmentCalendar />
				</AdminRoute>
				<AdminRoute path={`${path}/randevular`}>
					<Appointments />
				</AdminRoute>
				<AdminRoute path={`${path}/bekleyen-randevular`}>
					<PendingAppointments />
				</AdminRoute>
				<AdminRoute path={`${path}/raporlar`}>
					<Reports />
				</AdminRoute>
				<AdminRoute path={`${path}/randevu-sonuclari/:id`}>
					<AppointmentResults />
				</AdminRoute>
				<AdminRoute path={`${path}/rapor-paragraflari`}>
					<AutoReportText />
				</AdminRoute>

				<Route
					path={`${path}/istasyonlar/:id`}
					render={(props) => <StationInfo {...props} />}
				/>
				<Route
					path={`${path}/pctanimlari/:id`}
					render={(props) => <PcInfo {...props} />}
				/>
				<Route
					path={`${path}/randevu-listesi/:id`}
					render={(props) => <AppointmentList {...props} />}
				/>
				<AdminRoute path={`${path}/istasyonlar`}>
					<Stations />
				</AdminRoute>
				<AdminRoute path={`${path}/pctanimlari`}>
					<PcList />
				</AdminRoute>

				<Route
					path={`${path}/endustri-categorileri/:id`}
					render={(props) => <IndustryCategoryInfo {...props} />}
				/>
				<Route
					path={`${path}/sirket-buyuklukleri/:id`}
					render={(props) => <CompanySizeInfo {...props} />}
				/>
				<Route
					path={`${path}/sirket-merkez-lokasyonlari/:id`}
					render={(props) => <CompanyCenterLocationInfo {...props} />}
				/>
				<Route
					path={`${path}/tecrube-seviyeleri/:id`}
					render={(props) => <ExperienceLevelInfo {...props} />}
				/>
				<Route
					path={`${path}/nesiller/:id`}
					render={(props) => <GenerationInfo {...props} />}
				/>

				<AdminRoute path={`${path}/endustri-categorileri`}>
					<IndustryCategories />
				</AdminRoute>
				<AdminRoute path={`${path}/sirket-buyuklukleri`}>
					<CompanySizes />
				</AdminRoute>
				<AdminRoute path={`${path}/sirket-merkez-lokasyonlari`}>
					<CompanyCenterLocations />
				</AdminRoute>
				<AdminRoute path={`${path}/tecrube-seviyeleri`}>
					<ExperienceLevels />
				</AdminRoute>
				<AdminRoute path={`${path}/nesiller`}>
					<Generations />
				</AdminRoute>

				<Route
					path={`${path}/sarf-modulleri/:id`}
					render={(props) => <SarfModuleInfo {...props} />}
				/>
				<Route
					path={`${path}/sarf-yetkinlikleri/:id`}
					render={(props) => <SarfCompetencyInfo {...props} />}
				/>
				<Route
					path={`${path}/yetkinlik-alanlari/:id`}
					render={(props) => <SarfCompetencyDomainInfo {...props} />}
				/>
				<Route
					path={`${path}/sarf-paketleri/:id`}
					render={(props) => <SarfPackageInfo {...props} />}
				/>

				<AdminRoute path={`${path}/sarf-modulleri`}>
					<SarfModules />
				</AdminRoute>
				<AdminRoute path={`${path}/sarf-yetkinlikleri`}>
					<SarfCompetencies />
				</AdminRoute>
				<AdminRoute path={`${path}/yetkinlik-alanlari`}>
					<SarfCompetenciesDomain />
				</AdminRoute>
				<AdminRoute path={`${path}/sarf-paketleri`}>
					<SarfPackages />
				</AdminRoute>
				<AdminRoute path={`${path}/sarf-raporu-tanimlamalari`}>
					<SarfReportDefinitions />
				</AdminRoute>

				<Route
					path={`${path}/lokasyonlar/:id`}
					render={(props) => <LocationInfo {...props} />}
				/>
				<AdminRoute path={`${path}/lokasyonlar`}>
					<Locations />
				</AdminRoute>
				<Route
					path={`${path}/musteriler/:id/:sarfParameterTemplateId`}
					render={(props) => <SarfParameterTemplateConfiguration {...props} />}
				/>
				<Route
					path={`${path}/musteriler/:id`}
					render={(props) => <CustomerInfo {...props} />}
				/>

				<AdminRoute path={`${path}/musteriler`}>
					<Customers />
				</AdminRoute>
				<AdminRoute path={`${path}/sistem-loglari`}>
					<SystemLogs />
				</AdminRoute>
				<AdminRoute path={`${path}/mail-loglari`}>
					<MailLogs />
				</AdminRoute>
				<AdminRoute path={`${path}/lokasyon-admin`}>
					<LocationAdmins />
				</AdminRoute>

				<Route component={NotFound404} />
			</Switch>
		</DashboardLayout>
	);
};

export default Dashboard;
