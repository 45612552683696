import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { withTranslation } from 'react-i18next';
import SarfCompetencyDomainService from '../service/SarfCompetencyDomainService';
import Spinner from '../components/common/Spinner';

// Define your schema for form validation

const SarfCompetencyDomainInfo = ({ competencyDomainId, t }) => {
	const schema = yup.object().shape({
		name: yup
			.string()
			.trim()
			.required('enter_competency_info')
			.max(255, 'enter_category_length'),
		domain: yup.object().nullable().required('enter_individual_domain_info'),
		subDomain: yup.object().nullable(),
	});
	const toast = useRef(null);
	const [domainId, setDomainId] = useState();
	const [domains, setDomains] = useState([]);
	const [subDomains, setSubDomains] = useState([]);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const languageValue = localStorage.getItem('i18nextLng');

	const {
		register,
		handleSubmit,
		setValue,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const params = useParams();
	competencyDomainId = params.id;

	const onSubmit = async (data) => {
		setLoading(true);
		const payload = {
			name: data?.name,
			domain: data?.domain,
			subDomain: data?.subDomain?.id == null ? null : data?.subDomain,
		};

		try {
			let res;

			if (competencyDomainId == 0) {
				res = await SarfCompetencyDomainService.save(payload);
			} else {
				res = await SarfCompetencyDomainService.update({
					...payload,
					id: competencyDomainId,
				});
			}

			toast.current.show({
				severity: 'success',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('appointment_alert_messages.transaction_successful')}`,
			});

			setTimeout(() => {
				setLoading(false);
				history.push('/panel/yetkinlik-alanlari');
			}, 1000);
		} catch (error) {
			const errorMessage =
				typeof error.response.data === 'object'
					? JSON.stringify(error.response.data)
					: error.response.data;

			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: errorMessage,
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getAllDomains();

		if (competencyDomainId && competencyDomainId != 0) {
			getCompetencyDomainsByCompetencyDomainId(competencyDomainId);
		} else {
			return;
		}
	}, [competencyDomainId]);

	useEffect(() => {
		if (domainId && domainId != 0) {
			getSubDomainsByDomainId(domainId);
		}
	}, [domainId]);

	const getCompetencyDomainsByCompetencyDomainId = async (
		competencyDomainId
	) => {
		if (competencyDomainId && competencyDomainId != 0) {
			try {
				const res = await SarfCompetencyDomainService.getById(
					competencyDomainId
				);
				const { id, name, domain, subDomain } = res.data;
				setValue('name', name);
				setValue('domain', domain);
				if (subDomain !== null || subDomain !== undefined) {
					setValue('subDomain', subDomain);
				}
				setDomainId(domain.id);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		}
	};

	const getAllDomains = async () => {
		try {
			const res = await SarfCompetencyDomainService.getAllDomains();
			setDomains(res.data);
		} catch (error) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t(`${error.message}`)}`,
			});
		}
	};

	const getSubDomainsByDomainId = async (id) => {
		try {
			const res = await SarfCompetencyDomainService.getSubDomainsByDomainId(id);
			// Add null value at the start of the response data
			let nullSubDomain = {
				id: null,
				name: t('null_subdomain_option'),
				isActive: false,
				domain: { id: null },
				autoReportTextId: null,
				reportDisplayOrder: null,
			};
			let updatedData = [nullSubDomain, ...res.data];
			setSubDomains(updatedData);
		} catch (error) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t(`${error.message}`)}`,
			});
		}
	};

	return (
		<>
			<Toast ref={toast} />
			<Spinner loading={loading} />
			<div className='card card-custom gutter-b'>
				<div className='card-header'>
					<div className='card-title'>
						<h3 className='card-label'>{t('competency_domain_detail')}</h3>
					</div>
					<div className='card-toolbar'>
						<ul className='ust_menu'>
							<li
								tooltip={t('turn_back')}
								onClick={() => history.push('/panel/yetkinlik-alanlari')}
							>
								<i className='pi pi-arrow-left' />
							</li>
							<li tooltip={t('save_infos')} onClick={handleSubmit(onSubmit)}>
								<i className='pi pi-save ' />
							</li>
						</ul>
					</div>
				</div>
				<div className='card-body'>
					<form className='p-fluid grid'>
						<div className='field col-12 md:col-4'>
							<label htmlFor='inputtext'>{t('competency_domain_name')}</label>
							<Controller
								name='name'
								control={control}
								defaultValue=''
								render={({ field }) => (
									<InputText
										id='inputtext'
										{...field}
										className='p-inputtext-sm'
										maxLength='255'
									/>
								)}
							/>
							{errors.name && (
								<small style={{ color: 'red' }}>{t(errors.name.message)}</small>
							)}
						</div>

						<div className='field col-12 md:col-4'>
							<label htmlFor='inputtext'>{t('individual_domain_name')}</label>
							<Controller
								name='domain'
								control={control}
								defaultValue={null}
								render={({ field }) => (
									<Dropdown
										value={field.value}
										className='p-inputtext-sm'
										filter={true}
										onChange={(e) => {
											field.onChange(e.value);
											getSubDomainsByDomainId(e.value.id);
										}}
										options={domains}
										optionLabel='name'
									/>
								)}
							/>
							{errors.domain && (
								<small style={{ color: 'red' }}>
									{t(errors.domain.message)}
								</small>
							)}
						</div>

						<div className='field col-12 md:col-4'>
							<label htmlFor='inputtext'>
								{t('individual_subdomain_name')}
							</label>
							<Controller
								name='subDomain'
								control={control}
								defaultValue={null}
								render={({ field }) => (
									<Dropdown
										value={field.value}
										className='p-inputtext-sm'
										filter={true}
										onChange={(e) => {
											field.onChange(e.value);
										}}
										options={subDomains}
										optionLabel='name'
									/>
								)}
							/>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(SarfCompetencyDomainInfo);
