import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

const Text = styled.span`
  display: inline-block;
  transform: skew(20deg);
  -webkit-transform: skew(20deg);
  -o-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -ms-transform: skew(20deg);
`;

const MyButton = styled(Button)`
  text-transform: uppercase;
  min-width: 100px;
  transform: skew(-20deg);
  -webkit-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -ms-transform: skew(-20deg);
`;

export const SecondaryButton = ({ children, ...props }) => {
  return (
    <MyButton variant={props.variant ? props.variant : "outlined"} {...props}>
      <Text disabled={props.disabled} type={props.type}>
        {children}
      </Text>
    </MyButton>
  );
};
