import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import PageTitle from './../components/PageTitle';
import moment from 'moment';
import styled from 'styled-components';
import 'moment/locale/tr';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode } from 'primereact/api';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { withTranslation } from 'react-i18next';
import AlertDialog from '../components/DialogComp';
import AuthenticationService from '../service/AuthenticationService';
import DatePicker, { registerLocale } from 'react-datepicker';
import Spinner from '../components/common/Spinner';
const CustomDropdown = styled(Dropdown)`
	/* Target the dropdown input */
	.p-dropdown-label {
		padding: 0.36rem;
		/* Add more styles here */
	}
`;

const ActiveAppointments = ({ t }) => {
	const [appointments, setAppointments] = useState([]);
	const [dateChangeLang, setDateChangeLang] = useState('tr-TR');
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		appointmentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		parentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		userId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
		userSurname: { value: null, matchMode: FilterMatchMode.CONTAINS },
		type: { value: null, matchMode: FilterMatchMode.CONTAINS },
		hub: { value: null, matchMode: FilterMatchMode.CONTAINS },
		completed: { value: null, matchMode: FilterMatchMode.CONTAINS },
		startTime: { value: null, matchMode: FilterMatchMode.CONTAINS },
		endTime: { value: null, matchMode: FilterMatchMode.CONTAINS },
		date: { value: null, matchMode: FilterMatchMode.CONTAINS },
		operatorId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		operatorFullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
		assessmentPackageName: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [first, setFirst] = useState(0); // Add this line
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState(null);
	const [startDate, setStartDate] = useState('');
	const [endTime, setEndTime] = useState('');
	const [startTime, setStartTime] = useState('');
	const [rows, setRows] = useState(10);
	const [assessmentPackages, setAssesmentPackages] = useState([]);
	const langValue = localStorage.getItem('i18nextLng') ?? 'tr';
	const [loading, setLoading] = useState(true);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const toast = useRef(null);
	const user = JSON.parse(localStorage.authenticatedUser);
	const appointmentTypes = [
		{
			type: 'A',
			label: 'A',
			description: `${t('create_appointment_types.desc_A')}`,
		},
		{
			type: 'B',
			label: 'B',
			description: `${t('create_appointment_types.desc_B')}`,
		},
		{
			type: 'C',
			label: 'C',
			description: `${t('create_appointment_types.desc_C')}`,
		},
		{
			type: 'X',
			label: 'GEN',
			description: `${t('create_appointment_types.desc_X')}`,
		},
		{
			type: 'Z',
			label: `${t('gen_managers')}`,
			description: `${t('create_appointment_types.desc_Z')}`,
		},
	];

	useEffect(() => {
		getAssesmentPackages();
		getActiveAppointments(first, rows, sortField, sortOrder, filters);
	}, [langValue, first, rows, sortField, sortOrder, filters]);
	const getAssesmentPackages = async () => {
		try {
			const result = await axios.get('/users/getAssessmentPackages');

			setAssesmentPackages(result.data);
		} catch (err) {
			console.log(err);
		}
	};
	const getActiveAppointments = async (
		first,
		rows,
		sortField,
		sortOrder,
		filters
	) => {
		setLoading(true);
		let page = Math.floor(first / rows) ? Math.floor(first / rows) : 0;
		let formattedFilters = formatParams(filters);
		const payload = {
			page: page,
			size: rows,
			sortOrder: sortOrder === 1 ? 'asc' : 'desc',
			...formattedFilters,
			sortBy: sortField,
			isActive: true,
			isCompleted: false,
		};
		if (AuthenticationService.isAdmin()) {
			try {
				const { data } = await axios.get(
					'/appointments/getAppointmentsPagination',
					{ params: payload }
				);
				activeAppointmentDataFormatter(data?.appointmentDTOList);

				setTotalRecords(data?.totalRecords);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		} else if (AuthenticationService.isOperator()) {
			try {
				const { data } = await axios.get(
					'/appointments/getAppointmentsPagination',
					{
						params: {
							...payload,
							operatorId: user.userId,
							isOperatorPanel: true,
						},
					}
				);
				activeAppointmentDataFormatter(data?.appointmentDTOList);
				setTotalRecords(data?.totalRecords);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		} else {
			try {
				const { data } = await axios.get(
					'/appointments/getAppointmentsPagination',
					{ params: { ...payload, locationAdminId: user?.userId } }
				);
				activeAppointmentDataFormatter(data?.appointmentDTOList);
				setTotalRecords(data?.totalRecords);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		}
	};

	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};
	const formatParams = (params) => {
		let formattedParams = {};

		if (params) {
			for (let field in params) {
				if (params[field].value !== undefined && params[field].value !== null) {
					formattedParams[field] = params[field].value;
				}
			}
		}

		return formattedParams;
	};
	const onSort = (event) => {
		console.log(event.sortField);
		setSortField(event.sortField);
		setSortOrder(event.sortOrder);
	};
	const activeAppointmentDataFormatter = (res) => {
		if (res != null) {
			for (const data of res) {
				data.hub =
					data.station?.location?.country?.name +
					'/' +
					data.station?.location?.city?.name +
					'/' +
					data.station?.location?.name +
					'/' +
					data.station?.name;
				data.userName = data.name; // assign the value of data.name to data.userName
				data.userSurname = data.surname; // assign the value of data.surname to data.userSurname
				data.startTime = data.start;
				data.endTime = data.end;
				data.operatorFullName = data.operatorName;
				delete data.name; // remove the name property from data
				delete data.surname; // remove the surname property from data
				delete data.start; // remove the start property from data
				delete data.end; // remove the end property from data
				delete data.operatorName; // remove the operatorName property from data
				data.fullName = data?.userName + ' ' + data?.userSurname; // Use data.userName instead of data.name
			}
		}
		setAppointments(res);
		setLoading(false);
	};

	useEffect(() => {
		if (langValue === 'en') {
			setDateChangeLang('en-US');
		} else setDateChangeLang('tr-TR');
	}, [langValue]);

	const onGlobalFilterChange = (e) => {
		const value = e.target.value.trim();
		let _filters = { ...filters };
		_filters['global'].value = value;

		if (value.length > 2 || value.length === 0) {
			setFilters(_filters);
		}

		setGlobalFilterValue(value);
	};

	const renderHeader = () => {
		return (
			<div className='clearfix'>
				<div className='float-right p-2'>
					<span className='p-input-icon-left'>
						<i className='pi pi-search' />
						<InputText
							value={globalFilterValue}
							className='p-inputtext-sm'
							onKeyPress={(e) => {
								if (e.target.value.length === 0 && e.key === ' ') {
									e.preventDefault();
								}
							}}
							onChange={onGlobalFilterChange}
							placeholder={t('search...')}
						/>
					</span>
				</div>
			</div>
		);
	};
	const setCompleted = (id) => {
		axios.get(`/appointments/setCompleted/?id=${id}`).then((res) => {
			getActiveAppointments();
		});
	};

	const cancelAppointment = (id) => {
		axios
			.get(`/appointments/cancelAppointment/${id}`)
			.then((res) => {
				getActiveAppointments();
			})
			.catch((err) => {
				error(err);
			});
	};

	const accept = (id) => {
		toast.current.show({
			severity: 'info',
			summary: `${t('appointment_alert_messages.congratulations')}`,
			detail: `${t(
				'appointment_alert_messages.appointment_terminated_success'
			)}`,
			life: 3000,
		});
		setCompleted(id);
	};

	const reject = (id) => {
		toast.current.show({
			severity: 'warn',
			summary: `${t('appointment_alert_messages.denied')}`,
			detail: `${t('appointment_alert_messages.action_declined')}`,
			life: 3000,
		});
	};

	const accept1 = (id) => {
		toast.current.show({
			severity: 'info',
			summary: `${t('appointment_alert_messages.congratulations')}`,
			detail: `${t('appointment_alert_messages.appointment_delete_success')}`,
			life: 3000,
		});
		cancelAppointment(id);
	};

	const reject1 = () => {
		toast.current.show({
			severity: 'warn',
			summary: `${t('appointment_alert_messages.denied')}`,
			detail: `${t('appointment_alert_messages.appointment_delete_error')}`,
			life: 3000,
		});
	};

	const error = (err) => {
		toast.current.show({
			severity: 'error',
			summary: `${t('appointment_alert_messages.short_error')}`,
			detail: `${err.response.data}`,
			life: 3000,
		});
	};
	const confirm1 = (id) => {
		confirmDialog({
			message: `${id} ${t(
				'appointment_alert_messages.want_delete_appointment'
			)}`,
			header: `${t('appointment_alert_messages.confirmation')}`,
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
			accept: () => accept1(id),
			reject: () => reject1(),
		});
	};

	const confirm = (id) => {
		confirmDialog({
			message: `${id} ${t('appointment_alert_messages.appointment_completed')}`,
			header: `${t('appointment_alert_messages.confirmation')}`,
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: `${t('appointment_alert_messages.yes')}`,
			rejectLabel: `${t('appointment_alert_messages.no')}`,
			accept: () => accept(id),
			reject: () => reject(),
		});
	};

	const deleteSvg = (
		<svg
			fill='#EC0044'
			height='32'
			viewBox='0 0 20 20'
			width='32'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M11.5 4C11.5 3.17157 10.8284 2.5 10 2.5C9.17157 2.5 8.5 3.17157 8.5 4H7.5C7.5 2.61929 8.61929 1.5 10 1.5C11.3807 1.5 12.5 2.61929 12.5 4H17C17.2761 4 17.5 4.22386 17.5 4.5C17.5 4.77614 17.2761 5 17 5H16.446L15.1499 16.2292C15.0335 17.2384 14.179 18 13.1631 18H6.83688C5.821 18 4.9665 17.2384 4.85006 16.2292L3.553 5H3C2.75454 5 2.55039 4.82312 2.50806 4.58988L2.5 4.5C2.5 4.22386 2.72386 4 3 4H11.5ZM15.438 5H4.561L5.84347 16.1146C5.90169 16.6192 6.32894 17 6.83688 17H13.1631C13.6711 17 14.0983 16.6192 14.1565 16.1146L15.438 5ZM8.5 7.5C8.74546 7.5 8.94961 7.65477 8.99194 7.85886L9 7.9375V14.0625C9 14.3041 8.77614 14.5 8.5 14.5C8.25454 14.5 8.05039 14.3452 8.00806 14.1411L8 14.0625V7.9375C8 7.69588 8.22386 7.5 8.5 7.5ZM11.5 7.5C11.7455 7.5 11.9496 7.65477 11.9919 7.85886L12 7.9375V14.0625C12 14.3041 11.7761 14.5 11.5 14.5C11.2545 14.5 11.0504 14.3452 11.0081 14.1411L11 14.0625V7.9375C11 7.69588 11.2239 7.5 11.5 7.5Z'
				fill='#EC0044'
			/>
		</svg>
	);

	const actionBodyTemplate = (rowData) => {
		return (
			<div
				style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
			>
				<Button
					className='p-button-text'
					type='button'
					color='secondary'
					tooltip={t('end_appointment')}
					onClick={() => confirm(rowData.appointmentId)}
				>
					<svg
						fill='#0099F7'
						height='35'
						viewBox='0 0 20 20'
						width='35'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M10 2C14.4183 2 18 5.58172 18 10C18 10.1109 17.9977 10.2212 17.9933 10.331C17.7323 9.942 17.3828 9.61741 16.9735 9.38618C16.6626 5.80765 13.6592 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C10.3395 17 10.6734 16.9758 11 16.9291V17.9381C10.6724 17.979 10.3387 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2ZM16.9707 10.6446C17.2993 11.001 17.5 11.4771 17.5 12V13H18C18.5523 13 19 13.4477 19 14V18C19 18.5523 18.5523 19 18 19H13C12.4477 19 12 18.5523 12 18V14C12 13.4477 12.4477 13 13 13H13.5V12C13.5 10.8954 14.3954 10 15.5 10C16.0816 10 16.6053 10.2483 16.9707 10.6446ZM14.5 13H16.5V12C16.5 11.4477 16.0523 11 15.5 11C14.9477 11 14.5 11.4477 14.5 12V13ZM14.8454 16.3663C14.9738 16.5952 15.2188 16.75 15.5 16.75C15.9142 16.75 16.25 16.4142 16.25 16C16.25 15.742 16.1197 15.5144 15.9213 15.3795C15.8013 15.2978 15.6562 15.25 15.5 15.25C15.0858 15.25 14.75 15.5858 14.75 16C14.75 16.133 14.7846 16.2579 14.8454 16.3663ZM13.3584 7.64645C13.532 7.82001 13.5513 8.08944 13.4163 8.28431L13.3584 8.35355L9.35355 12.3584C9.17999 12.532 8.91056 12.5513 8.71569 12.4163L8.64645 12.3584L6.64645 10.3584C6.45118 10.1632 6.45118 9.84658 6.64645 9.65131C6.82001 9.47775 7.08944 9.45846 7.28431 9.59346L7.35355 9.65131L9 11.298L12.6513 7.64645C12.8466 7.45118 13.1632 7.45118 13.3584 7.64645Z'
							fill='#0099F7'
						/>
					</svg>
				</Button>
				<Button
					className='p-button-text'
					tooltip={t('cancel_appointment')}
					onClick={() => confirm1(rowData.appointmentId)}
				>
					{deleteSvg}
				</Button>
			</div>
		);
	};

	const actionOperatorEdit = (rowData) => {
		return (
			<>
				<AlertDialog
					operatorInfo={rowData.operatorId}
					selected={rowData.operatorName}
				></AlertDialog>
			</>
		);
	};
	const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
		<InputText
			style={{ padding: '.5rem', borderRadius: '4px', borderColor: '#ddd' }} // your custom styles
			type='text'
			className='form-control'
			onClick={onClick}
			value={value}
			ref={ref}
		/>
	));
	const numberofpages = Math.ceil(totalRecords / rows);

	return (
		<>
			<ConfirmDialog />
			<PageTitle>{t('active_appointments')}</PageTitle>
			<Spinner loading={loading} />
			<Toast ref={toast} />
			<div className='card card-custom gutter-b'>
				<div className='card-body'>
					<DataTable
						value={appointments}
						totalRecords={totalRecords}
						first={first}
						rows={rows}
						paginator
						onPage={onPageChange}
						showGridlines
						onSort={onSort}
						sortField={sortField}
						sortOrder={sortOrder}
						responsiveLayout='scroll'
						header={renderHeader}
						paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
						rowsPerPageOptions={[10, 25, 50]}
						dataKey='id'
						rowHover
						filters={filters}
						filterDisplay='row'
						lazy='true'
						sortMode='single'
						globalFilterFields={[
							'location.name',
							'name',
							'surname',
							'appointmentId',
							'name',
						]}
						emptyMessage={t('no_records_found')}
						currentPageReportTemplate={t('currentPageReportTemplate')}
						numberofpages={numberofpages}
					>
						<Column
							headerStyle={{ maxWidth: '10rem', textAlign: 'center' }}
							header={t('action')}
							body={actionBodyTemplate}
							className='actionBody'
							bodyStyle={{ textAlign: 'center' }}
						/>

						{AuthenticationService.isLocationAdmin() && (
							<Column
								header={t('name_surname')}
								sortable={true}
								field='fullName'
								filterElement={
									<InputText
										onKeyPress={(e) => {
											if (e.target.value.length === 0 && e.key === ' ') {
												e.preventDefault();
											}
										}}
										onChange={(e) => {
											if (
												e.target.value.trim().length > 2 ||
												e.target.value.trim().length === 0
											) {
												setFilters({
													...filters,
													userName: {
														value: e.target.value.trim(),
														matchMode: FilterMatchMode.CONTAINS,
													},
												});
											}
										}}
									/>
								}
								filter
								style={{ maxWidth: '100px' }}
							></Column>
						)}

						<Column
							header={t('appointment_id')}
							sortable={true}
							field='appointmentId'
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (
											!/\d/.test(e.key) ||
											(e.target.value.length === 0 && e.key === ' ')
										) {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 0 ||
											e.target.value.trim().length === 0
										) {
											setFilters({
												...filters,
												appointmentId: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							style={{ maxWidth: '100px' }}
						></Column>
						<Column
							header={t('parent_id')}
							sortable={true}
							field='parentId'
							filter
							style={{ maxWidth: '100px' }}
						></Column>
						<Column
							header={t('user_id')}
							sortable={true}
							field='userId'
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (
											!/\d/.test(e.key) ||
											(e.target.value.length === 0 && e.key === ' ')
										) {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 0 ||
											e.target.value.trim().length === 0
										) {
											setFilters({
												...filters,
												userId: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							style={{ maxWidth: '100px' }}
						></Column>
						{AuthenticationService.isAdmin() && (
							<Column
								header={t('name')}
								sortable={true}
								field='userName'
								filter
								filterElement={
									<InputText
										onKeyPress={(e) => {
											if (e.target.value.length === 0 && e.key === ' ') {
												e.preventDefault();
											}
										}}
										onChange={(e) => {
											if (
												e.target.value.trim().length > 2 ||
												e.target.value.trim().length === 0
											) {
												setFilters({
													...filters,
													userName: {
														value: e.target.value.trim(),
														matchMode: FilterMatchMode.CONTAINS,
													},
												});
											}
										}}
									/>
								}
								style={{ minWidth: '100px' }}
							></Column>
						)}
						{AuthenticationService.isAdmin() && (
							<Column
								header={t('surname')}
								sortable={true}
								field='userSurname'
								filter
								filterElement={
									<InputText
										onKeyPress={(e) => {
											if (e.target.value.length === 0 && e.key === ' ') {
												e.preventDefault();
											}
										}}
										onChange={(e) => {
											if (
												e.target.value.trim().length > 2 ||
												e.target.value.trim().length === 0
											) {
												setFilters({
													...filters,
													userSurname: {
														value: e.target.value.trim(),
														matchMode: FilterMatchMode.CONTAINS,
													},
												});
											}
										}}
									/>
								}
								style={{ minWidth: '100px' }}
							></Column>
						)}
						<Column
							header={t('assessment_package')}
							field='assessmentPackageName'
							sortable={true}
							filterElement={
								<CustomDropdown
									options={assessmentPackages?.map((option) => ({
										label: option?.packageName,
										value: option?.packageName,
									}))}
									showClear={true}
									value={filters?.assessmentPackageName?.value}
									onChange={(e) => {
										setFilters({
											...filters,
											assessmentPackageName: {
												value: e.value,
												matchMode: FilterMatchMode.CONTAINS,
											},
										});
									}}
								/>
							}
							filter
							style={{ maxWidth: '120px' }}
						></Column>
						<Column
							header={t('type')}
							field='type'
							sortable={true}
							filter
							filterElement={
								<CustomDropdown
									options={appointmentTypes?.map((option) => ({
										label: option?.type,
										value: option?.type,
									}))}
									showClear={true}
									value={filters?.type?.value}
									onChange={(e) => {
										setFilters({
											...filters,
											type: {
												value: e.value,
												matchMode: FilterMatchMode.CONTAINS,
											},
										});
									}}
								/>
							}
							style={{ maxWidth: '100px' }}
						></Column>

						<Column
							header={t('hub')}
							sortable={true}
							field='hub'
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (e.target.value.length === 0 && e.key === ' ') {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 2 ||
											e.target.value.trim().length === 0
										) {
											setFilters({
												...filters,
												hub: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('start_time')}
							sortable={true}
							field='startTime'
							filter
							filterElement={
								<div className='appointment-start-time'>
									<DatePicker
										selected={startTime}
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={1}
										key={localStorage.getItem('i18nextLng')}
										locale={
											localStorage.getItem('i18nextLng') === 'tr' ? 'tr' : 'en'
										}
										customInput={<CustomInput />}
										timeCaption={t('start_time')}
										isClearable={'true'}
										dateFormat='HH:mm'
										onKeyDown={(e) => e.preventDefault()}
										onChange={(date) => {
											const formattedDate = moment(date).format('HH:mm');

											if (date === 'Invalid date') {
												return;
											}
											setFirst(0);
											setFilters(
												date
													? {
															...filters,
															startTime: {
																value: formattedDate,
																matchMode: FilterMatchMode.EQUALS,
															},
													  }
													: {
															...filters,
															startTime: {
																value: null,
																matchMode: FilterMatchMode.EQUALS,
															},
													  }
											);
											setStartTime(date);
										}}
									/>
								</div>
							}
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('end_time')}
							sortable={true}
							field='endTime'
							filter
							filterElement={
								<div className='appointment-start-time'>
									{' '}
									<DatePicker
										selected={endTime}
										showTimeSelect
										showTimeSelectOnly
										key={localStorage.getItem('i18nextLng')}
										locale={
											localStorage.getItem('i18nextLng') === 'tr' ? 'tr' : 'en'
										}
										customInput={<CustomInput />}
										timeCaption={t('end_time')}
										isClearable={'true'}
										dateFormat='HH:mm'
										timeIntervals={1}
										onKeyDown={(e) => e.preventDefault()}
										onChange={(date) => {
											const formattedDate = moment(date).format('HH:mm');

											if (date === 'Invalid date') {
												return;
											}
											setFirst(0);
											setFilters(
												date
													? {
															...filters,
															endTime: {
																value: formattedDate,
																matchMode: FilterMatchMode.EQUALS,
															},
													  }
													: {
															...filters,
															endTime: {
																value: null,
																matchMode: FilterMatchMode.EQUALS,
															},
													  }
											);
											setEndTime(date);
										}}
									/>{' '}
								</div>
							}
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('date')}
							sortable={true}
							field='date'
							body={(rowData) => {
								let d = rowData.date;
								let date = new Date(d);
								let formattedDate = date.toLocaleDateString(dateChangeLang);
								return formattedDate;
							}}
							filter
							filterElement={
								<DatePicker
									dateFormat={langValue === 'tr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
									key={localStorage.getItem('i18nextLng')}
									locale={
										localStorage.getItem('i18nextLng') === 'tr' ? 'tr' : 'en'
									}
									selected={startDate}
									showMonthDropdown
									showYearDropdown
									yearDropdownItemNumber={478}
									scrollableYearDropdown
									scrollableMonthDropdown
									popperPlacement='bottom-start'
									dropdownMode='scroll'
									customInput={<CustomInput />}
									isClearable={true}
									onKeyDown={(e) => e.preventDefault()}
									onChange={(date) => {
										const formattedDate = moment(date).format('YYYY-MM-DD');

										if (date === 'Invalid date') {
											return;
										}
										setFirst(0);
										setFilters(
											date
												? {
														...filters,
														date: {
															value: formattedDate,
															matchMode: FilterMatchMode.EQUALS,
														},
												  }
												: {
														...filters,
														date: {
															value: null,
															matchMode: FilterMatchMode.EQUALS,
														},
												  }
										);
										setStartDate(date);
									}}
								/>
							}
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('operatorId')}
							sortable={true}
							field='operatorId'
							// body={actionOperatorEdit}
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (
											!/\d/.test(e.key) ||
											(e.target.value.length === 0 && e.key === ' ')
										) {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 0 ||
											e.target.value.trim().length === 0
										) {
											setFilters({
												...filters,
												operatorId: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							style={{
								maxWidth: '100px',
								cursor: 'pointer',
							}}
						></Column>
						<Column
							header={t('operatorName')}
							sortable={true}
							field='operatorFullName'
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (e.target.value.length === 0 && e.key === ' ') {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 2 ||
											e.target.value.trim().length === 0
										) {
											setFilters({
												...filters,
												operatorFullName: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							style={{ minWidth: '100px' }}
						></Column>
					</DataTable>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(ActiveAppointments);
