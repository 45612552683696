import React, { useEffect, useState } from 'react';

import DemographicsService from '../service/DemographicsService';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Button, Grid, Typography } from '@material-ui/core';
import axios from 'axios';
import { searchDataTypes } from './IndividualSearch';
import LoadingButton from './common/LoadingButton';
import UserService from '../service/UserService';
import { useTranslation } from 'react-i18next';

const IndividualSearchResult = ({ userId, onSearchAgain }) => {
  const { t } = useTranslation();
  const [demographics, setDemographics] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  const [reportState, setReportState] = useState({
    DOMAIN_SCORES: 'IN_PROGRESS',
    SUBDOMAIN_SCORES: 'IN_PROGRESS',
    METRIC_RESULTS: 'IN_PROGRESS',
    HRV_RAWDATA: 'IN_PROGRESS',
    CAFEGAME_RAWDATA: 'IN_PROGRESS',
    FLEXIBILITY_METRICS: 'IN_PROGRESS',
    EYETRACKING_RAWDATA: 'IN_PROGRESS',
    HRV_TIMESERIES_RESULTS: 'IN_PROGRESS',
    HRV_FREQUENCY1_RESULTS: 'IN_PROGRESS',
    HRV_FREQUENCY2_RESULTS: 'IN_PROGRESS',
    CARDIOVASCULAR_FITNESS_RESULTS: 'IN_PROGRESS',
    CMJ_RESULTS: 'IN_PROGRESS',
    DEMOGRAPHICS_MAIN: 'IN_PROGRESS',
  });

  useEffect(() => {
    DemographicsService.getDemographicsInfoByUserId(userId).then((res) =>
      setDemographics(res.data)
    );

    UserService.getUserById(userId).then((res) => setUserInfo(res.data));

    Object.keys(searchDataTypes).forEach((searchDataType) => {
      axios
        .post('/dashboard/generateIndividualReport', {
          userId: userId,
          searchDataType: searchDataType,
        })
        .then((res) => {
          setReportState((prevState) => ({
            ...prevState,
            [searchDataType]: res.data.status,
          }));
        })
        .catch((err) => {
          setReportState((prevState) => ({
            ...prevState,
            [searchDataType]: 'FAILED',
          }));
        });
    });
  }, [userId]);

  const onDownload = (searchDataType) => {
    return axios
      .post(
        '/dashboard/download',
        { userId, searchDataType },
        { responseType: 'blob' }
      )
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        const fileExtension =
          searchDataType === searchDataTypes.PRESCREENING ? 'xlsx' : 'csv';
        link.setAttribute(
          'download',
          `${userId}_${searchDataType}.${fileExtension}`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  return (
    <>
      {userId && (
        <>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item={true} xs={6} container justifyContent="center">
              <Alert severity="success">
                <AlertTitle>{t('individual_user_reports_created')}</AlertTitle>
                {t('you_can_download_report_click')}
              </Alert>
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            {userInfo && (
              <Typography variant="h6" gutterBottom>
                {userInfo.name} {userInfo.surname}{' '}
                <span>({userInfo.userId})</span>
              </Typography>
            )}
          </Grid>
          <br />

          {!demographics && (
            <Grid container style={{ marginBottom: 5 }}>
              <Grid item={true} xs={6} justifyContent="center">
                <Alert severity="warning">
                  <AlertTitle>
                    {t('user_hasnot_filled_demographic_info')}
                  </AlertTitle>
                  {t('you_can_download_report_click')}
                </Alert>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item={true} xs={12}>
              <LoadingButton
                color="primary"
                onClick={() => onDownload(searchDataTypes.HRV_RAWDATA)}
                state={reportState.HRV_RAWDATA}
              >
                {searchDataTypes.HRV_RAWDATA} (.csv)
              </LoadingButton>
            </Grid>
            <Grid item={true} xs={12}>
              <LoadingButton
                color="primary"
                onClick={() => onDownload(searchDataTypes.CAFEGAME_RAWDATA)}
                state={reportState.CAFEGAME_RAWDATA}
              >
                {searchDataTypes.CAFEGAME_RAWDATA} (.csv)
              </LoadingButton>
            </Grid>
            <Grid item={true} xs={12}>
              <LoadingButton
                color="primary"
                onClick={() => onDownload(searchDataTypes.FLEXIBILITY_METRICS)}
                state={reportState.FLEXIBILITY_METRICS}
              >
                {searchDataTypes.FLEXIBILITY_METRICS} (.csv)
              </LoadingButton>
            </Grid>
            <Grid item={true} xs={12}>
              <LoadingButton
                color="primary"
                onClick={() =>
                  onDownload(searchDataTypes.HRV_FREQUENCY1_RESULTS)
                }
                state={reportState.HRV_FREQUENCY1_RESULTS}
              >
                {searchDataTypes.HRV_FREQUENCY1_RESULTS} (.csv)
              </LoadingButton>
            </Grid>
            <Grid item={true} xs={12}>
              <LoadingButton
                color="primary"
                onClick={() =>
                  onDownload(searchDataTypes.HRV_FREQUENCY2_RESULTS)
                }
                state={reportState.HRV_FREQUENCY2_RESULTS}
              >
                {searchDataTypes.HRV_FREQUENCY2_RESULTS} (.csv)
              </LoadingButton>
            </Grid>
            <Grid item={true} xs={12}>
              <LoadingButton
                color="primary"
                onClick={() =>
                  onDownload(searchDataTypes.HRV_TIMESERIES_RESULTS)
                }
                state={reportState.HRV_TIMESERIES_RESULTS}
              >
                {searchDataTypes.HRV_TIMESERIES_RESULTS} (.csv)
              </LoadingButton>
            </Grid>
            <Grid item={true} xs={12}>
              <LoadingButton
                color="primary"
                onClick={() => onDownload(searchDataTypes.EYETRACKING_RAWDATA)}
                state={reportState.EYETRACKING_RAWDATA}
              >
                {searchDataTypes.EYETRACKING_RAWDATA} (.csv)
              </LoadingButton>
            </Grid>
            <Grid item={true} xs={12}>
              <LoadingButton
                color="primary"
                onClick={() =>
                  onDownload(searchDataTypes.CARDIOVASCULAR_FITNESS_RESULTS)
                }
                state={reportState.CARDIOVASCULAR_FITNESS_RESULTS}
              >
                {searchDataTypes.CARDIOVASCULAR_FITNESS_RESULTS} (.csv)
              </LoadingButton>
            </Grid>
            <Grid item={true} xs={12}>
              <LoadingButton
                color="primary"
                onClick={() => onDownload(searchDataTypes.CMJ_RESULTS)}
                state={reportState.CMJ_RESULTS}
              >
                {searchDataTypes.CMJ_RESULTS} (.csv)
              </LoadingButton>
            </Grid>
            <Grid item={true} xs={12}>
              <LoadingButton
                color="primary"
                onClick={() => onDownload(searchDataTypes.DOMAIN_SCORES)}
                state={reportState.DOMAIN_SCORES}
              >
                {searchDataTypes.DOMAIN_SCORES} (.csv)
              </LoadingButton>
            </Grid>
            <Grid item={true} xs={12}>
              <LoadingButton
                color="primary"
                onClick={() => onDownload(searchDataTypes.SUBDOMAIN_SCORES)}
                state={reportState.SUBDOMAIN_SCORES}
              >
                {searchDataTypes.SUBDOMAIN_SCORES} (.csv)
              </LoadingButton>
            </Grid>
            <Grid item={true} xs={12}>
              <LoadingButton
                color="primary"
                onClick={() => onDownload(searchDataTypes.METRIC_RESULTS)}
                state={reportState.METRIC_RESULTS}
              >
                {searchDataTypes.METRIC_RESULTS} (.csv)
              </LoadingButton>
            </Grid>
            <Grid item={true} xs={12}>
              <LoadingButton
                color="primary"
                onClick={() => onDownload(searchDataTypes.DEMOGRAPHICS_MAIN)}
                state={reportState.DEMOGRAPHICS_MAIN}
              >
                {searchDataTypes.DEMOGRAPHICS_MAIN} (.csv)
              </LoadingButton>
            </Grid>
            <Grid item={true} xs={12}>
              <LoadingButton
                color="primary"
                onClick={() => onDownload(searchDataTypes.PRESCREENING)}
                state={reportState.PRESCREENING}
              >
                {searchDataTypes.PRESCREENING} (.csv)
              </LoadingButton>
            </Grid>
          </Grid>
        </>
      )}

      <Button
        color="secondary"
        onClick={onSearchAgain}
        style={{ marginTop: 20, textDecoration: 'none', textTransform: 'none' }}
      >
        {t('another_search')}
      </Button>
    </>
  );
};

export default IndividualSearchResult;
