import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { withTranslation } from 'react-i18next';
import IndustryCategoryService from '../service/IndustryCategoryService';

const IndustryCategoryInfo = ({ categoryId, fetchJCategories, t }) => {
	const toast = useRef(null);
	const [category, setCategory] = useState({
		id: '',
		name: '',
	});

	const params = useParams();
	categoryId = params.id;
	const onSubmit = () => {
		category.name = category.name.trim();
		if (category.name.length > 255) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_category_length')}`,
			});
			return;
		}
		if (category.name == null || category.name === '') {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_category_info')}`,
			});
			return;
		}
		if (category.name.length > 255) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_category_length')}`,
			});
			return;
		}
		if (category.id == 0) {
			IndustryCategoryService.save(category)
				.then((res) => {
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
					});
					setTimeout(() => {
						handleOnClick();
					}, 1000);
				})
				.catch((error) => {
					toast.current.show({
						severity: 'error',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: error.response.data,
					});
				});
		} else {
			IndustryCategoryService.update(category)
				.then((res) => {
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
					});
					setTimeout(() => {
						handleOnClick();
					}, 1000);
				})
				.catch((error) => {
					toast.current.show({
						severity: 'error',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: error.response.data,
					});
				});
		}
	};

	useEffect(() => {
		if (categoryId != 0) {
			getList(categoryId);
		} else {
			return;
		}
	}, [categoryId]);

	const getList = (categoryId) => {
		if (categoryId != null && categoryId !== 0) {
			IndustryCategoryService.getById(categoryId).then((res) => {
				setCategory(res.data);
			});
		}
	};
	const history = useHistory();
	const handleOnClick = useCallback(
		() => history.push('/panel/endustri-categorileri'),
		[history]
	);

	return (
		<>
			<Toast ref={toast} />
			<div className='card card-custom gutter-b'>
				<div className='card-header'>
					<div className='card-title'>
						<h3 className='card-label'>{t('industry_category_detail')}</h3>
					</div>
					<div className='card-toolbar'>
						<ul className='ust_menu'>
							<li tooltip={t('turn_back')} onClick={handleOnClick}>
								<i className='pi pi-arrow-left' />
							</li>
							<li tooltip={t('save_infos')} onClick={onSubmit}>
								<i className='pi pi-save ' />
							</li>
						</ul>
					</div>
				</div>
				<div className='card-body'>
					<form className='p-fluid grid'>
						<div className='field col-12 md:col-4'>
							<label htmlFor='inputtext'>{t('industry_category')}</label>
							<InputText
								id='inputtext'
								value={category.name}
								style={{ width: '600px' }}
								onChange={(e) =>
									setCategory({ ...category, name: e.target.value })
								}
								className='p-inputtext-sm'
							/>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};
export default withTranslation()(IndustryCategoryInfo);
