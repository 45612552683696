import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

const MyFooter = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4b5760;
  text-align: center;
  color: #929ea8;
  position: relative;
  bottom: -25px;
  left: 0;
  right: 0;
`;

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <MyFooter>© {year}</MyFooter>
      <Box
        style={{
          color: '#929ea8',
          background: '#4b5760',
          position: 'relative',
          left: 0,
          right: 0,
          bottom: -25,
        }}
        display='flex'
        paddingRight={2}
        paddingBottom={2}
        justifyContent='flex-end'
      >
        <span></span>
      </Box>
    </>
  );
};

export default Footer;
