import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Box,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import AuthenticationService from '../service/AuthenticationService';
import moment from 'moment';
import PageTitle from './../components/PageTitle';
import LayoutComponent from './../components/LayoutComponent';
import DoctorDemographicsService from '../service/DoctorDemographicsService';
import { useTranslation } from 'react-i18next';

const inputProps = { readOnly: true };

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
}));

export const DoctorDemographics = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const userData = AuthenticationService.getLoggedInUser();

  const [demographics, setDemographics] = useState(null);
  useEffect(() => {
    DoctorDemographicsService.getDoctorDemographicsInfo().then((res) =>
      setDemographics(res.data)
    );
  }, []);

  return (
    <>
      <LayoutComponent>
        {demographics ? (
          <Container>
            <PageTitle>{t('demographicsInfo')} </PageTitle>
            <Paper elevation={4} style={{ padding: 30 }}>
              <Grid container spacing={4}>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    label={t('name_surname')}
                    variant="outlined"
                    className={classes.input}
                    defaultValue={userData.name + ' ' + userData.surname}
                    InputProps={inputProps}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    label={t('email')}
                    variant="outlined"
                    className={classes.input}
                    defaultValue={userData.email}
                    InputProps={inputProps}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    label={t('birth_day')}
                    variant="outlined"
                    className={classes.input}
                    defaultValue={
                      demographics.birthDate &&
                      moment(demographics.birthDate).format('YYYY')
                    }
                    InputProps={inputProps}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    label={t('genders')}
                    variant="outlined"
                    className={classes.input}
                    defaultValue={
                      demographics.genderOther || demographics.gender.name
                    }
                    InputProps={inputProps}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    label={t('country')}
                    variant="outlined"
                    className={classes.input}
                    defaultValue={
                      demographics.countryResidenceOther ||
                      demographics.country.name
                    }
                    InputProps={inputProps}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    label={t('occupations')}
                    variant="outlined"
                    className={classes.input}
                    defaultValue={
                      demographics.occupationOther ||
                      demographics.occupation.name
                    }
                    InputProps={inputProps}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    label={t('job_title')}
                    variant="outlined"
                    className={classes.input}
                    defaultValue={
                      demographics.jobTitleOther || demographics.jobTitle.name
                    }
                    InputProps={inputProps}
                  />
                </Grid>

                <Grid item={true} xs={12} md={6}>
                  <TextField
                    label={t('company_worked')}
                    variant="outlined"
                    className={classes.input}
                    defaultValue={
                      demographics.companyName ? demographics.companyName : '-'
                    }
                    InputProps={inputProps}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Container>
        ) : (
          <Container>
            <Box textAlign="center" marginTop="20px">
              <Typography variant="h6">
                {' '}
                {t('not_fill_demographic_info')}
              </Typography>
              <Typography variant="body1">
                {' '}
                {t('fill_your_info')}{' '}
                <Button color="secondary" component={Link} to="/">
                  {t('click')}
                </Button>
              </Typography>
            </Box>
          </Container>
        )}
      </LayoutComponent>
    </>
  );
};
