import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CustomerService from '../../service/CustomerService';

import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { withTranslation } from 'react-i18next';

import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import CompanyCenterLocationService from '../../service/CompanyCenterLocationService';
import CompanySizeService from '../../service/CompanySizeService';
import CompanyIndustryCategoryService from '../../service/CompanyIndustryCategoryService';
import CustomerSarfDetails from './CustomerSarfDetails';
import AddIndustryCategoryModal from './AddIndustryCategoryModal';

const CustomerDetailInfo = ({ customerId, t, setLoading }) => {
	const toast = useRef(null);
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});

	const [customer, setCustomer] = useState({
		id: '',
		name: '',
		companySizes: '',
		companyType: '',
		customerHeadQuarterLocations: '',
	});
	const [companyTypes, setCompanyTypes] = useState([]);
	const [selectedCompanyType, setSelectedCompanyType] = useState();
	const [companyLocations, setCompanyLocations] = useState([]);
	const [selectedCompanyLocation, setSelectedCompanyLocation] = useState();
	const [companySizes, setCompanySizes] = useState([]);
	const [addIndustryCategoryOpen, setAddIndustryCategoryOpen] = useState(false);
	const [selectedCompanySize, setSelectedCompanySize] = useState();
	const [customerIndustryCategories, setCustomerIndustryCategories] = useState(
		[]
	);
	const [selectedList, setSelectedList] = useState(null);
	const langValue = localStorage.getItem('i18nextLng') || 'en';
	const onSubmit = () => {
		customer.name = customer.name ? customer.name.trim() : '';

		if (
			customer.name == null ||
			customer.name == '' ||
			customer.name == undefined
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_customer_name')}`,
			});
			return;
		}
		if (customer.name.length > 255) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_category_length')}`,
				life: 3000,
			});
			return;
		}
		if (
			selectedCompanyType == null ||
			selectedCompanyType == undefined ||
			selectedCompanyType == ''
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_company_type')}`,
			});
			return;
		}

		if (
			selectedCompanyLocation == null ||
			selectedCompanyLocation == undefined ||
			selectedCompanyLocation == ''
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_location_name')}`,
			});
			return;
		}

		if (
			selectedCompanySize == null ||
			selectedCompanySize == undefined ||
			selectedCompanySize == ''
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_size_info')}`,
			});
			return;
		}
		customer.companySizes = selectedCompanySize.id;
		customer.customerHeadQuarterLocations = selectedCompanyLocation.id;
		customer.companyType = selectedCompanyType.id;
		customerId = customer.id;

		if (customerId == 0) {
			setLoading(true);
			CustomerService.save(customer)
				.then((res) => {
					customerId = res.data.id;
					setLoading(false);
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
					});

					getCustomerById(res.data.id);
				})
				.catch((error) => {
					setLoading(false);
					toast.current.show({
						severity: 'error',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: error.response.data,
						life: 3000,
					});
				});
		} else {
			setLoading(true);
			CustomerService.update(customer)
				.then((res) => {
					setLoading(false);
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
					});
				})
				.catch((error) => {
					setLoading(false);
					toast.current.show({
						severity: 'error',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: error.response.data,
						life: 3000,
					});
				});
		}
	};

	const fetchCustomerCenterLocations = () => {
		CompanyCenterLocationService.getLocations()
			.then((res) => {
				//Gelen veri ile state güncelleniyor
				setCompanyLocations(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const fetchCompanySizes = () => {
		CompanySizeService.getList()
			.then((res) => {
				//Gelen veri ile state güncelleniyor
				setCompanySizes(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		let unmounted = false;
		setCompanyTypes([
			{ id: 1, name: `${t('product_owner')}` },
			{ id: 2, name: `${t('customer')}` },
			{ id: 3, name: `${t('other')}` },
		]);

		fetchCustomerCenterLocations();
		fetchCompanySizes();

		return () => {
			unmounted = true;
		};
	}, [langValue]);

	useEffect(() => {
		let unmounted = false;

		if (customerId != 0 && customerId !== null) {
			getCustomerById(customerId);
			getCompanyIndustryCategories(customerId);
		}
		return () => {
			unmounted = true;
		};
	}, [customerId]);

	useEffect(() => {
		let unmounted = false;
		if (customer.companyType == 1) {
			setSelectedCompanyType({ id: 1, name: `${t('product_owner')}` });
		} else if (customer.companyType == 2) {
			setSelectedCompanyType({ id: 2, name: `${t('customer')}` });
		} else if (customer.companyType == 3) {
			setSelectedCompanyType({ id: 3, name: `${t('other')}` });
		}

		return () => {
			unmounted = true;
		};
	}, [customer.companyType, langValue]);

	useEffect(() => {
		let unmounted = false;

		if (
			customer.customerHeadQuarterLocations !== undefined &&
			customer.customerHeadQuarterLocations !== null &&
			customer.customerHeadQuarterLocations !== ''
		) {
			getCompanyLocationById(customer.customerHeadQuarterLocations);
		} else {
			return;
		}

		return () => {
			unmounted = true;
		};
	}, [customer.customerHeadQuarterLocations]);

	useEffect(() => {
		let unmounted = false;
		if (
			customer.companySizes != undefined &&
			customer.companySizes != null &&
			customer.companySizes !== ''
		) {
			getCompanySizeById(customer.companySizes);
		} else {
			return;
		}

		return () => {
			unmounted = true;
		};
	}, [customer.companySizes]);

	const getCustomerById = (customerId) => {
		setLoading(true);
		CustomerService.getById(customerId)
			.then((res) => {
				setTimeout(() => {
					setLoading(false);
				}, 2000);
				setCustomer(res.data);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};

	const getCompanyLocationById = (id) => {
		CompanyCenterLocationService.getById(id).then((res) => {
			setSelectedCompanyLocation(res.data);
		});
	};
	const getCompanySizeById = (id) => {
		CompanySizeService.getById(id).then((res) => {
			setSelectedCompanySize(res.data);
		});
	};
	const history = useHistory();
	const handleOnClick = useCallback(
		() => history.push('/panel/musteriler'),
		[history]
	);

	const handleCloseIndustryCategoryModal = () => {
		setAddIndustryCategoryOpen(false);
	};

	const openIndustryCategoryModal = () => {
		if (
			(customer.id != 0) &
			(customer.id != undefined) &
			(customer.id != null)
		) {
			setAddIndustryCategoryOpen(true);
		} else {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('save_customer_message')}`,
			});
		}
	};
	const getCompanyIndustryCategories = (id) => {
		CompanyIndustryCategoryService.getById(id).then((res) => {
			setCustomerIndustryCategories(res.data);
		});
	};

	const deleteCompanyCategoryById = (row) => {
		CompanyIndustryCategoryService.delete(row)
			.then((res) => {
				toast.current.show({
					severity: 'success',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: `${t('appointment_alert_messages.transaction_successful')}`,
				});
				getCompanyIndustryCategories(customer.id);
			})
			.catch((error) => {
				toast.current.show({
					severity: 'error',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: error.response.data,
					life: 3000,
				});
			});
	};

	const acceptProcess = (rowData) => {
		deleteCompanyCategoryById({
			data: {
				id: rowData.customer.id,

				companyIndustryCategoryId: rowData.industryCategories.id,
			},
		});
	};

	const reject = () => {
		toast.current.show({
			severity: 'info',
			summary: `${t('appointment_alert_messages.transaction_status')}`,
			detail: `${t('appointment_alert_messages.oparation_cancel')}`,
			life: 3000,
		});
	};
	const confirmDelete = (event, rowData) => {
		confirmPopup({
			target: event.currentTarget,
			message: `${t('appointment_alert_messages.want_delete_register')}`,
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: () => acceptProcess(rowData),
			reject,
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
		});
	};

	const deleteSvg = (
		<svg
			fill='#EC0044'
			height='32'
			viewBox='0 0 20 20'
			width='20'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M11.5 4C11.5 3.17157 10.8284 2.5 10 2.5C9.17157 2.5 8.5 3.17157 8.5 4H7.5C7.5 2.61929 8.61929 1.5 10 1.5C11.3807 1.5 12.5 2.61929 12.5 4H17C17.2761 4 17.5 4.22386 17.5 4.5C17.5 4.77614 17.2761 5 17 5H16.446L15.1499 16.2292C15.0335 17.2384 14.179 18 13.1631 18H6.83688C5.821 18 4.9665 17.2384 4.85006 16.2292L3.553 5H3C2.75454 5 2.55039 4.82312 2.50806 4.58988L2.5 4.5C2.5 4.22386 2.72386 4 3 4H11.5ZM15.438 5H4.561L5.84347 16.1146C5.90169 16.6192 6.32894 17 6.83688 17H13.1631C13.6711 17 14.0983 16.6192 14.1565 16.1146L15.438 5ZM8.5 7.5C8.74546 7.5 8.94961 7.65477 8.99194 7.85886L9 7.9375V14.0625C9 14.3041 8.77614 14.5 8.5 14.5C8.25454 14.5 8.05039 14.3452 8.00806 14.1411L8 14.0625V7.9375C8 7.69588 8.22386 7.5 8.5 7.5ZM11.5 7.5C11.7455 7.5 11.9496 7.65477 11.9919 7.85886L12 7.9375V14.0625C12 14.3041 11.7761 14.5 11.5 14.5C11.2545 14.5 11.0504 14.3452 11.0081 14.1411L11 14.0625V7.9375C11 7.69588 11.2239 7.5 11.5 7.5Z'
				fill='#EC0044'
			/>
		</svg>
	);

	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<div style={{ display: 'inline-block', width: 'content-fit' }}>
					{' '}
					<Button
						type='button'
						className='p-button-text'
						tooltip={t('delete')}
						onClick={(event) => {
							confirmDelete(event, rowData);
						}}
						label={deleteSvg}
					></Button>
				</div>
			</React.Fragment>
		);
	};

	return (
		<>
			<Toast ref={toast} />

			<div className='card card-custom gutter-b'>
				<div className='card-header'>
					<div className='card-title'>
						<h3 className='card-label'>{t('customer_detail')}</h3>
					</div>
					<div className='card-toolbar'>
						<ul className='ust_menu'>
							<li tooltip={t('turn_back')} onClick={handleOnClick}>
								<i className='pi pi-arrow-left' />
							</li>
							<li tooltip={t('save_infos')} onClick={onSubmit}>
								<i className='pi pi-save ' />
							</li>
						</ul>
					</div>
				</div>
				<div className='card-body'>
					<form className='p-fluid grid'>
						<div className='field col-3 md:col-4'>
							<label htmlFor='inputtext'>{t('customer_name')}</label>
							<InputText
								style={{ paddingTop: '7.5px', paddingBottom: '8px' }}
								id='inputtext'
								value={customer.name}
								onChange={(e) =>
									setCustomer({ ...customer, name: e.target.value })
								}
								className='p-inputtext-sm'
							/>
						</div>
						<div className='field col-3 md:col-4'>
							<label htmlFor='dropdown'>{t('company_type')}</label>
							<Dropdown
								value={selectedCompanyType}
								className='p-inputtext-sm'
								onChange={(e) => {
									setSelectedCompanyType(e.value);
								}}
								options={companyTypes}
								optionLabel='name'
							/>
						</div>
						<div className='field col-3 md:col-4'>
							{' '}
							<label htmlFor='dropdown'>{t('headquarter_location')}</label>
							<Dropdown
								value={selectedCompanyLocation}
								className='p-inputtext-sm'
								filter={true}
								onChange={(e) => {
									setSelectedCompanyLocation(e.value);
								}}
								options={companyLocations}
								optionLabel='name'
							/>
						</div>
						<div className='field col-3 md:col-4 '>
							{' '}
							<label htmlFor='dropdown'>{t('company_size')}</label>
							<Dropdown
								value={selectedCompanySize}
								className='p-inputtext-sm'
								onChange={(e) => {
									setSelectedCompanySize(e.value);
								}}
								options={companySizes}
								optionLabel='name'
							/>
						</div>
						<div className=' col-12'></div>
						<div style={{ width: '66%' }}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									width: '100%',
								}}
							>
								<p>{t('industry_categories')}</p>{' '}
								<div>
									{' '}
									<Button
										type='button'
										icon='pi pi-plus'
										onClick={() => openIndustryCategoryModal()}
										label={t('create_customer_industry_category')}
										className='p-button-raised p-button-rounded p-button-outlined mr-3'
									></Button>
								</div>
							</div>

							<DataTable
								value={customerIndustryCategories}
								showGridlines
								scrollable
								scrollHeight='150px'
								dataKey='id'
								selection={selectedList}
								onValueChange={(e) => setSelectedList(e.value)}
								filters={filters}
								filterDisplay='menu'
								tableStyle={{
									margin: '0',
									padding: '0',
									width: '100%',
									height: '150px',
								}}
								globalFilterFields={['name']}
								emptyMessage={t('no_records_found')}
								currentPageReportTemplate={t('currentPageReportTemplate')}
							>
								<Column
									headerStyle={{
										textAlign: 'center',
										width: '4rem',
										alignItems: 'center',
										height: '2.5rem',
									}}
									style={{ maxWidth: '100px' }}
									bodyStyle={{
										display: 'flex',
										justifyContent: 'center',
										height: '2.5rem',
										alignItems: 'center',
										textAlign: 'center',
									}}
									body={actionBodyTemplate}
									header={t('action')}
								/>
								<Column
									headerStyle={{
										textAlign: 'center',
										height: '2.5rem',
									}}
									bodyStyle={{
										width: '80%',
										height: '2.5rem',
									}}
									field='industryCategories.name'
									header={t('industry_category')}
								></Column>
							</DataTable>
						</div>
					</form>
				</div>
				<CustomerSarfDetails
					setLoading={setLoading}
					customerId={customer.id}
					langValue={langValue}
					t={t}
				></CustomerSarfDetails>
			</div>
			<AddIndustryCategoryModal
				setLoading={setLoading}
				customerId={customer.id}
				t={t}
				addIndustryCategoryOpen={addIndustryCategoryOpen}
				handleCloseIndustryCategoryModal={handleCloseIndustryCategoryModal}
				getCompanyIndustryCategories={getCompanyIndustryCategories}
			></AddIndustryCategoryModal>
		</>
	);
};
export default withTranslation()(CustomerDetailInfo);
