import React, { useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5percent from '@amcharts/amcharts5/percent';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
const MiniDonutChart = ({ t, module, onHandle }) => {
  const history = useHistory();
  useEffect(() => {
    if (module.modules) {
      am5.addLicense('AM5C361499357');
      var root = am5.Root.new('chartdiv5');

      root.setThemes([am5themes_Animated.new(root)]);

      var chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          layout: root.verticalLayout,
          innerRadius: am5.percent(66),
        })
      );

      var series = chart.series.push(
        am5percent.PieSeries.new(root, {
          valueField: 'moduleScore',
          categoryField: 'moduleName',
          alignLabels: false,
        })
      );
      series.labels.template.setAll({
        textType: 'circular',
        centerX: 0,
        centerY: 0,
        radius: 6,
        inside: true,
        fill: am5.color('#ffffff'),
        fontSize: 22,
        text: `{donutText}`,
      });
      series.slices.template.setAll({
        fontSize: 20,
        templateField: 'columnSettings',
      });

      if (module.neoauvraScore) {
        let label = series.children.push(
          am5.Label.new(root, {
            text: module?.neoauvraScore ?? '',
            fontSize: 24,
            centerX: am5.percent(60),
            centerY: am5.percent(20),
          })
        );
        let label2 = series.children.push(
          am5.Label.new(root, {
            text: '/1000',
            fontSize: 14,
            centerX: am5.percent(0),
            centerY: am5.percent(-20),
            marginLeft: 30,
            marginTop: 10,
          })
        );
      }

      series.slices.template.set('toggleKey', 'none');

      series.slices.template.events.on('click', function (ev) {
        // console.log(ev.target.dataItem.dataContext);
        // onHandle(ev.target.dataItem.dataContext);
        series.slices.each(function (slice) {
          if (slice != ev.target && slice.get('active')) {
            slice.set('active', false);
          }
        });
      });

      // let label = series.children.push(
      //   am5.Label.new(root, {
      //     text: module.neoauvraScore,
      //     fontSize: 24,
      //     centerX: am5.percent(60),
      //     centerY: am5.percent(20),
      //   })
      // );

      let label3 = series.children.push(
        am5.Label.new(root, {
          text: `${t('score')}`,
          fontSize: 18,
          fontWeight: 'bold',
          centerX: am5.percent(40),
          centerY: am5.percent(90),
          fill: am5.color('#0A2365'),
        })
      );
      const mes = module.modules;

      const total = [];

      if (mes.length > 0) {
        for (let i = 0; i < mes.length; i++) {
          const modules = mes[i];
          if (modules.moduleScore) {
            let data = {
              moduleName: t(modules?.moduleName),
              moduleScore: 300,
              forcedTreeColor: modules?.forcedTreeColor,
              columnSettings: {
                fill: am5.color(modules?.forcedTreeColor),
              },
              donutText: modules.moduleScore ?? '',
              item: {
                label: t(modules?.moduleName),
                id: modules?.moduleId,
                parentMenu: true,
              },
            };
            total.push(data);
          }
        }
      }

      series.data.setAll(total);
      series.appear(1000, 100);

      return () => {
        maybeDisposeRoot1(root);
      };
    }
  }, [module, t]);

  const maybeDisposeRoot1 = (divId) => {
    am5.array.each(am5.registry.rootElements, function (root) {
      if (root.dom.id === divId) {
        root.dispose();
      }
    });
  };

  return <div id="chartdiv5" style={{ height: '100%' }}></div>;
};

export default withTranslation()(MiniDonutChart);
