import axios from 'axios';

class CompetencyNewDefinitionService {
	getById(id) {
		return axios.get(
			'/lookup/getSarfCompetencyDescriptionTextByCompetencyId/' + id
		);
	}

	save(row) {
		return axios.post('/lookup/saveSarfCompetencyDescriptionText', row);
	}

	delete(id) {
		return axios.delete(
			'/lookup/deleteSarfCompetencyDescriptionTextById/' + id
		);
	}

	update(row) {
		return axios.post('/lookup/updateSarfCompetencyDescriptionText', row);
	}
}

export default new CompetencyNewDefinitionService();
