import axios from 'axios';

class CompanyIndustryCategoryService {
	getById(id) {
		return axios.get(
			'/dashboard/getCustomerCompanyIndustryCategoriesByCustomerId/' + id
		);
	}

	save(row) {
		return axios.post('/dashboard/saveCustomerCompanyIndustryCategories', row);
	}

	delete(row) {
		return axios.delete(
			'/dashboard/deleteCustomerCompanyIndustryCategories',
			row
		);
	}
}

export default new CompanyIndustryCategoryService();
