import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import Spinner from '../common/Spinner';

import { InputText } from 'primereact/inputtext';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import ButtonMaterial from '@mui/material/Button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import CustomerSarfReportParametersTemplateService from '../../service/CustomerSarfReportParametersTemplateService';
import SarfPackageService from '../../service/SarfPackageService';
import ExperienceLevelService from '../../service/ExperienceLevelService';
import GenerationService from '../../service/GenerationService';
import CustomerSarfReportService from '../../service/CustomerSarfReportService';

const AddSarfReportModal = ({
	customerId,
	t,
	addSarfReportOpen,
	handleCloseAddSarfReportModal,
	getAllSarfReports,
	setLoading,
}) => {
	const toast = useRef(null);
	const toastInside = useRef(null);
	const [description, setDescription] = useState('');
	const [reportName, setReportName] = useState('');
	const [sarfReportTemplates, setSarfReportTemplates] = useState([]);
	const [selectedSarfReportTemplate, setSelectedSarfReportTemplate] =
		useState();
	const [sarfPackages, setSarfPackages] = useState([]);
	const [selectedSarfPackage, setSelectedSarfPackage] = useState();
	const [experienceLevels, setExperienceLevels] = useState([]);
	const [selectedExperienceLevel, setSelectedExperienceLevel] = useState();
	const [generations, setGenerations] = useState([]);
	const [selectedGeneration, setSelectedGeneration] = useState();

	useEffect(() => {
		let unmounted = false;

		if (addSarfReportOpen) {
			getAllSarfReportParameterTemplates(customerId);
			getAllSarfPackages();
			getExperienceLevels();
			getAllGenerations();
		} else {
			return;
		}

		return () => {
			unmounted = true;
		};
	}, [customerId, addSarfReportOpen]);

	const getAllSarfPackages = () => {
		SarfPackageService.getList()
			.then((res) => {
				setSarfPackages(res.data);
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	};

	const getAllSarfReportParameterTemplates = (id) => {
		CustomerSarfReportParametersTemplateService.getSarfTemplatesByCustomerId(id)
			.then((res) => {
				setSarfReportTemplates(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const getExperienceLevels = () => {
		ExperienceLevelService.getList()
			.then((res) => {
				setExperienceLevels(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getAllGenerations = () => {
		GenerationService.getGenerationList()
			.then((res) => {
				setGenerations(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const saveSarfReport = () => {
		if (reportName == '' || reportName == null || reportName == undefined) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_sarf_report_name')}`,
				life: 3000,
			});
			return;
		}
		if (reportName.trim() == '') {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_sarf_report_name')}`,
				life: 3000,
			});
			return;
		}

		if (reportName.trim().length > 255) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_category_length')}`,
				life: 3000,
			});
			return;
		}
		if (customerId == null || customerId == undefined) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('appointment_alert_messages.transaction_error')}`,
				life: 3000,
			});

			return;
		}
		if (
			selectedSarfReportTemplate == null ||
			selectedSarfReportTemplate == undefined
		) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_sarf_report_parameter_template')}`,
				life: 3000,
			});

			return;
		}
		if (selectedSarfPackage == null || selectedSarfPackage == undefined) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_package_info')}`,
				life: 3000,
			});

			return;
		}
		if (
			selectedExperienceLevel == null ||
			selectedExperienceLevel == undefined
		) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_seniority')}`,
				life: 3000,
			});

			return;
		}

		if (selectedGeneration == null || selectedGeneration == undefined) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_generation')}`,
				life: 3000,
			});

			return;
		}
		const trimmedDescription = description ? description.trim() : '';

		const dataSent = {
			customerId: customerId,
			description: trimmedDescription,
			generationId: selectedGeneration?.id,
			name: reportName.trim(),
			sarfPackageId: selectedSarfPackage?.id,
			sarfReportParametersTemplatesId: selectedSarfReportTemplate?.id,
			sarfScore: null,
			seniorityLevelId: selectedExperienceLevel?.id,
		};
		setLoading(true);
		CustomerSarfReportService.save(dataSent)
			.then((res) => {
				setTimeout(() => {
					handleCloseAddSarfReportModal();
					setLoading(false);
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
						life: 3000,
					});
					setDescription('');
					setReportName('');
					setSelectedGeneration(null);
					setSelectedSarfPackage();
					setSelectedSarfReportTemplate(null);
					setSelectedExperienceLevel(null);
				}, 2000);
				getAllSarfReports(customerId);
			})
			.catch((error) => {
				setLoading(false);
				toastInside.current.show({
					severity: 'error',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: error.response.data,
					life: 3000,
				});
			});
	};

	return (
		<>
			<Toast ref={toast} />

			<Dialog
				open={addSarfReportOpen}
				fullWidth={true}
				maxWidth={'sm'}
				onClose={() => {
					handleCloseAddSarfReportModal();
					setDescription('');
					setReportName('');
					setSelectedGeneration(null);
					setSelectedSarfPackage(null);
					setSelectedSarfReportTemplate(null);
					setSelectedExperienceLevel(null);
				}}
			>
				<Toast ref={toastInside} />

				<DialogContent
					style={{
						overflowY: 'visible',
						display: 'flex',
						justifyContent: 'start',
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<DialogContentText
						style={{
							marginBottom: '30px',
							textAlign: 'center',
							fontWeight: 'bold',
						}}
					>
						{t('report_definition')}
					</DialogContentText>

					<FormControl>
						<div
							style={{
								width: '100%',
							}}
						>
							<label htmlFor='inputtext'>{t('sarf_report_name')}</label>
							<InputText
								id='inputtext'
								style={{
									width: '100%',
									marginTop: '10px',
									marginBottom: '10px',
								}}
								value={reportName}
								onChange={(e) => setReportName(e.target.value)}
							/>
						</div>
						<div style={{ width: '100%' }}>
							<label htmlFor=''>{t('sarf_report_parameter_template')}</label>
							<Dropdown
								value={selectedSarfReportTemplate}
								style={{
									width: '100%',
									marginTop: '10px',
									marginBottom: '10px',
								}}
								appendTo='self'
								filter={true}
								className='p-inputtext-sm'
								onChange={(e) => {
									setSelectedSarfReportTemplate(e.value);
								}}
								options={sarfReportTemplates}
								optionLabel='name'
							/>
						</div>
						<div style={{ width: '100%' }}>
							<label htmlFor=''>{t('sarf_package')}</label>
							<Dropdown
								value={selectedSarfPackage}
								style={{
									width: '100%',
									marginTop: '10px',
									marginBottom: '10px',
								}}
								appendTo='self'
								className='p-inputtext-sm'
								onChange={(e) => {
									setSelectedSarfPackage(e.value);
								}}
								options={sarfPackages}
								optionLabel='name'
							/>
						</div>
						<div style={{ width: '100%' }}>
							<label htmlFor=''>{t('seniority_level')}</label>
							<Dropdown
								value={selectedExperienceLevel}
								style={{
									width: '100%',
									marginTop: '10px',
									marginBottom: '10px',
								}}
								appendTo='self'
								filter={true}
								className='p-inputtext-sm'
								onChange={(e) => {
									setSelectedExperienceLevel(e.value);
								}}
								options={experienceLevels}
								optionLabel='name'
							/>
						</div>
						<div style={{ width: '100%' }}>
							<label htmlFor=''>{t('generation_name')}</label>
							<Dropdown
								value={selectedGeneration}
								style={{
									width: '100%',
									marginTop: '10px',
									marginBottom: '10px',
								}}
								appendTo='self'
								className='p-inputtext-sm'
								onChange={(e) => {
									setSelectedGeneration(e.value);
								}}
								options={generations}
								optionLabel='name'
							/>
						</div>
						<div style={{ width: '100%' }}>
							<label htmlFor='inputtext'>{t('description')}</label>
							<InputText
								id='inputtext'
								style={{
									width: '100%',
									marginTop: '10px',
									marginBottom: '10px',
								}}
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
						</div>
						<DialogActions style={{ paddingRight: '0' }}>
							<ButtonMaterial
								onClick={() => {
									handleCloseAddSarfReportModal();
									setDescription('');
									setReportName('');
									setSelectedGeneration(null);
									setSelectedSarfPackage();
									setSelectedSarfReportTemplate(null);
									setSelectedExperienceLevel(null);
								}}
							>
								{' '}
								{t('Cancel')}
							</ButtonMaterial>
							<ButtonMaterial
								variant='contained'
								onClick={() => {
									saveSarfReport();
								}}
							>
								{' '}
								{t('save')}
							</ButtonMaterial>
						</DialogActions>
					</FormControl>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default withTranslation()(AddSarfReportModal);
