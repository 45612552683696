import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  forwardRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import ExpertReviewAppointmentService from '../service/ExpertReviewAppointmentService';
import { TabPanel, TabView } from 'primereact/tabview';
// import {Editor} from 'primereact/editor';
import AuthenticationService from '../service/AuthenticationService';
import axios from 'axios';
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from '@material-ui/core/Button';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import Dialog from '@material-ui/core/Dialog';
// import { Button } from 'primereact/button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Spinner from './common/Spinner';

import { useTheme } from '@material-ui/core/styles';
import ExpertRaportView from './ExpertRaportView';
import { makeStyles } from '@material-ui/core/styles';
import { maxWidth } from '@mui/system';
import { MainButton } from './common/MainButton';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ConfirmDialog } from 'primereact/confirmdialog'; // To use <ConfirmDialog> tag
import { confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method
import { da } from 'date-fns/locale';
const useStyles = makeStyles({
  root: {
    width: '100% !important',
    maxWidth: '1400px !important',
  },
  paper: { width: '100% !important', maxWidth: '1400px !important' },
});
export const ExpertReviewAppointment = () => {
  // const userData = AuthenticationService.getLoggedInUser();
  const { t } = useTranslation();

  const toast = useRef(null);
  const params = useParams();
  var appointmentId = params.id;
  const [appointment, setAppointment] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  //const [newActiveIndex, setNewActiveIndex] = useState(0);
  var newActiveIndex = 0;
  const [expertReview, setExpertReview] = useState({ appointmentId });
  const [expertReviewArray, setExpertReviewArray] = useState([]);
  const [expertReviewArrayTemp, setExpertReviewArrayTemp] = useState([]);
  const [metricModules, setMetricModules] = useState([]);
  const [metricDetails, setMetricDetails] = useState([]);
  const [userData, setUserData] = useState({});
  const [open, setOpen] = React.useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [stateEditor, setStateEditor] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleExpertApproved = () => {
    setLoading(true);
    axios
      .post(
        '/appointments/changeExpertApprovalStatus',
        {},
        {
          headers: {
            status: !appointment.expertApproved,
            appointmentId: appointmentId,
          },
        }
      )
      .then((res) => {
        {
          fetchAppointmentAndAllMetricData(appointmentId);
          toast.current.show({
            severity: 'success',
            summary: `${t('appointment_alert_messages.transaction_status')}`,
            detail: `${t('expert_alert_status_changed')}`,
          });
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.current.show({
          severity: 'error',
          summary: `${t('appointment_alert_messages.transaction_status')}`,
          detail: `${t('appointment_alert_messages.register_delete_error')}`,
        });
      });
  };

  const classes = useStyles();

  // let {path} = useRouteMatch();

  // console.log('path:', path, ' - params:', params);
  const onSubmit = () => {
    // e.preventDefault();
    // console.log('formData::::::::::::::::::>>>>>', e);

    if (expertReview.reviewText == null) {
      /*  toast.current.show({
        severity: 'error',
        summary: `${t('appointment_alert_messages.transaction_status')}`,
        detail: `${t('appointment_alert_messages.enter_expert_opinion')}`,
      });
      return;
      */
    }
    let tab = expertReviewArray[activeIndex];
    var sendData = {
      id: tab.expertReview?.id,
      reviewText: tab.expertReview?.reviewText,
      moduleId: tab.metricModule.id,
      appointmentId,
    };
    if (sendData.id == null || sendData.id.length < 1) {
      ExpertReviewAppointmentService.save(sendData).then((res) => {
        setActiveIndex(newActiveIndex);
        setIsChanged(false);
        setStateEditor(false);
        fetchAppointmentAndAllMetricData(appointmentId);
        toast.current.show({
          severity: 'success',
          summary: `${t('appointment_alert_messages.transaction_status')}`,
          detail: `${t('appointment_alert_messages.transaction_successful')}`,
        });
        // setTimeout(() => {
        //     handleOnClick();
        // }, 1000)
      });
    } else {
      ExpertReviewAppointmentService.update(sendData).then((res) => {
        setActiveIndex(newActiveIndex);
        setIsChanged(false);
        setStateEditor(false);
        fetchAppointmentAndAllMetricData(appointmentId);

        toast.current.show({
          severity: 'success',
          summary: `${t('appointment_alert_messages.transaction_status')}`,
          detail: `${t('appointment_alert_messages.transaction_successful')}`,
        });
        // setTimeout(() => {
        //     handleOnClick();
        // }, 1000)
      });
    }

    //console.log(station);
  };

  useEffect(() => {
    //console.log(appointmentId);
    fetchAppointmentAndAllMetricData(appointmentId);
    fetchUserInfo();
    // getCountries();
  }, [appointmentId]);

  const fetchAppointmentAndAllMetricData = (appointmentId) => {
    if (AuthenticationService.isAdmin()) {
      axios
        .get('/appointments/getAppointmentById/' + appointmentId)
        .then((res) => {
          setAppointment(res.data);
        });

      ExpertReviewAppointmentService.getMetricModulesAndExpertReviewsByAppointmentId(
        appointmentId
      ).then((resMod) => {
        if (resMod.data != null) {
          for (let index = 0; index < resMod.data.length; index++) {
            if (resMod.data[index].expertReview == null) {
              resMod.data[index].expertReview = { reviewText: '' };
            }
          }
        }
        setExpertReviewArray(resMod.data);
        setExpertReviewArrayTemp(JSON.parse(JSON.stringify(resMod.data)));
        setActiveIndex(newActiveIndex);
      });
    } else if (AuthenticationService.isExpert()) {
      axios
        .get('/appointments/getAppointmentById/' + appointmentId)
        .then((res) => {
          setAppointment(res.data);
        });

      ExpertReviewAppointmentService.getMetricModulesAndExpertReviewsByAppointmentId(
        appointmentId
      ).then((resMod) => {
        if (resMod.data != null) {
          for (let index = 0; index < resMod.data.length; index++) {
            if (resMod.data[index].expertReview == null) {
              resMod.data[index].expertReview = { reviewText: '' };
            }
          }
        }
        setExpertReviewArray(resMod.data);
        setExpertReviewArrayTemp(JSON.parse(JSON.stringify(resMod.data)));
        setActiveIndex(newActiveIndex);
      });
    }

    /*
    ExpertReviewAppointmentService.getMetricModules().then((resMod) => {
      setMetricModules(resMod.data);

      console.log('resMod:::::::::::::::>>>>>>>>>>>>>',resMod);
      if (appointmentId != null && appointmentId.length > 0) {
        ExpertReviewAppointmentService.getDetail(appointmentId).then((res) => {
          setExpertReviewArray(res.data);
          let d = { reviewText: '' };
          let moduleId = 0;
          if (resMod.data != null && resMod.data.length > 0) {
            moduleId = resMod.data[0].id;
            d = res.data.filter((f) => f.metricModule?.id === moduleId)[0];
          }
          if (d == null) {
            d = { reviewText: '' };
          }
          if (d.reviewText == null) {
            d.reviewText = '';
          }
          setExpertReview({ ...d, moduleId, appointmentId });


        });
      }
    });
    */
  };

  const fetchUserInfo = async () => {
    if (AuthenticationService.isAdmin()) {
      const response = await axios.get(
        '/users/getUserByAppointmentId/' + appointmentId
      );
      response.data.body.fullName =
        response.data.body.name + ' ' + response.data.body.surname;
      setUserData(response.data.body);
    } else if (AuthenticationService.isExpert()) {
      const response = await axios.get(
        '/users/getUserByAppointmentIdForExpert/' + appointmentId
      );
      setUserData(response.data);
    }
  };

  const getMetricDetail = (id) => {
    ExpertReviewAppointmentService.getMetricDetail(id).then((resp) => {
      setMetricDetails(resp.data);
    });
  };
  const history = useHistory();
  const handleOnClick = useCallback(
    () => history.push('/panel/tamamlanmis-randevular'),
    [history]
  );
  const renderHeader = () => {
    return (
      <span className='ql-formats'>
        <button
          className='ql-bold'
          aria-label='Bold'
        />
        <button
          className='ql-italic'
          aria-label='Italic'
        />
        <button
          className='ql-underline'
          aria-label='Underline'
        />
      </span>
    );
  };

  const handleHistory = (id) => {
    const url = `/appointment/${id}`;
    window.open(url);
  };

  const strUpperCase = (text) => {
    if (text) {
      text = text.charAt(0).toUpperCase() + text.slice(1);
      return text;
    }
  };

  const acceptProcess = () => {
    handleExpertApproved();
  };

  const reject = () => {
    toast.current.show({
      severity: 'info',
      summary: `${t('appointment_alert_messages.transaction_status')}`,
      detail: `${t('expert_alert_status_changed_cancel')}`,
      life: 3000,
    });
  };
  const confirmDelete = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: `${t('expert_alert_messages.want_change_status')}`,
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => acceptProcess(),
      reject,
      acceptLabel: `${t('yes')}`,
      rejectLabel: `${t('no')}`,
    });
  };

  return (
    <>
      <Spinner loading={loading} />
      <ConfirmPopup />
      <ConfirmDialog />
      <Toast ref={toast} />
      {/* <DialogComponent
        style={{ visibility: 'hidden' }}
        maxWidth="xl"
        visible={visible}
      >
        <ExpertRaportView userId={appointmentId} />
      </DialogComponent> */}
      <div>
        <Dialog
          classes={{ paper: classes.paper }}
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby='responsive-dialog-title'
        >
          <ExpertRaportView id={appointmentId} />
          <Button
            onClick={handleClose}
            color='primary'
            autoFocus
          >
            {t('close')}
          </Button>
        </Dialog>
      </div>
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>{t('expert_opinion')}</h3>
          </div>
          <div className='card-toolbar'>
            <ul className='ust_menu'>
              <li
                tooltip={t('turn_back')}
                onClick={handleOnClick}
              >
                <i className='pi pi-arrow-left' />
              </li>
              {/*               <li tooltip={t('save_infos')} onClick={onSubmit}>
                <i className="pi pi-save " />
              </li> */}
            </ul>
          </div>
        </div>
        <div className='card-body'>
          <div className='p-fluid grid'>
            <div className='field col-6 p-fluid grid'>
              <div className='field col-8 md:col-4 lg:col-3'>
                {t('user_id')}
              </div>
              <div className='field col-4 md:col-6 lg:col-6'>
                : <strong>{userData.userId}</strong>
              </div>
              <div className='col-12' />
              {AuthenticationService.isAdmin() && (
                <>
                  <div className='field col-8 md:col-4 lg:col-3'>
                    {t('name_surname')}
                  </div>
                  <div className='field col-4 md:col-6 lg:col-6'>
                    :{' '}
                    <strong>
                      {strUpperCase(userData?.name)}{' '}
                      {strUpperCase(userData?.surname)}
                    </strong>
                  </div>
                </>
              )}
            </div>

            <div className='field col-6 p-fluid grid'>
              <div className='field col-8 md:col-4 lg:col-3'>
                {t('appointment_id')}
              </div>
              <div className='field col-4 md:col-6 lg:col-5'>
                : <strong>{appointmentId}</strong>
              </div>
              <div className='field col-8 md:col-4 lg:col-4'>
                {/* <MainButton color="primary" onClick={handleClickOpen}>
                  {t('VIEW_REPORT')}
                </MainButton> */}
                <MainButton
                  color='primary'
                  onClick={() => handleHistory(appointmentId)}
                  className='mb-3'
                >
                  {t('VIEW_REPORT')}
                </MainButton>
                <Link
                  to={{
                    pathname: `/panel/randevu-sonuclari/${appointmentId}`,
                    query: `${userData.userId}`,
                    state: `${userData.userId}`,
                  }}
                >
                  <MainButton color='primary'>{t('show_results')}</MainButton>
                </Link>
              </div>
              <div className='col-12' />
              <div className='field col-8 md:col-4 lg:col-3'>
                {t('appointment_date')}
              </div>
              <div className='field col-4 md:col-6 lg:col-5'>
                :{' '}
                <strong>{moment(appointment.date).format('DD.MM.YYYY')}</strong>
              </div>
              {(AuthenticationService.isAdmin() ||
                AuthenticationService.isExpert()) && (
                <>
                  <div className='field col-8 md:col-4 lg:col-4'>
                    <MainButton
                      color={
                        appointment.expertApproved ? 'primary' : 'secondary'
                      }
                      onClick={(event) => confirmDelete(event)}
                    >
                      {appointment.expertApproved
                        ? t('withdraw_expert_appoval')
                        : t('give_expert_approvel')}
                    </MainButton>
                  </div>
                </>
              )}
              <div className='col-12' />
              <div className='field col-8 md:col-4 lg:col-3'>
                {t('expert_approved')}
              </div>

              <div className='field col-4 md:col-6 lg:col-5'>
                :{' '}
                <strong>
                  {appointment.expertApproved == true ? t('yes') : t('no')}
                </strong>
              </div>
            </div>
          </div>

          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => {
              let tab = expertReviewArray[activeIndex];
              let tabTemp = expertReviewArrayTemp[activeIndex];

              newActiveIndex = e.index;

              if (isChanged == true) {
                confirmDialog({
                  message: t('confirm_dialog_message'),
                  header: t(tab.metricModule.name),
                  icon: 'pi pi-exclamation-triangle',
                  acceptLabel: t('yes'),
                  rejectLabel: t('no'),
                  accept: () => {
                    setIsChanged(false);
                    onSubmit();
                    // return;
                  },
                  reject: () => {
                    setIsChanged(false);
                    tab.expertReview.reviewText =
                      tabTemp.expertReview.reviewText;
                    setActiveIndex(e.index);
                  },
                });
              } else {
                //alert("yokkkkkkkkkk");
                setActiveIndex(e.index);
                //setExpertReview({ ...d, moduleId, appointmentId });
              }

              // getMetricDetail(metricModules[e.index].id);
            }}
          >
            {expertReviewArray.map((tab) => {
              // console.log('------------------------TAB:::::>>>>>>', tab);
              // console.log('------------------------TAB name:::::>>>>>>', tab.name);

              return (
                <TabPanel
                  key={tab.metricModule.id}
                  header={t(tab.metricModule.name)}
                >
                  <form className='p-fluid grid'>
                    <div className='field col-4'>
                      <label htmlFor='dropdown'>
                        <strong>{t(tab.metricModule.name)}</strong>{' '}
                        {t('expert_opinion')}
                      </label>
                    </div>
                    <div className='col-offset-6 field col-2'>
                      <MainButton
                        style={{ float: 'right' }}
                        color='primary'
                        onClick={() => {
                          newActiveIndex = activeIndex;
                          onSubmit();
                        }}
                      >
                        {t('save')}
                      </MainButton>
                    </div>
                    <div className='field col-12'>
                      <CKEditor
                        editor={ClassicEditor}
                        data={tab.expertReview.reviewText}
                        onReady={(editor) => {
                          // editor.setData(expertReview.reviewText)
                          // You can store the "editor" and use when it is needed.

                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              'height',
                              '320px',
                              editor.editing.view.document.getRoot()
                            );
                          });
                          setIsChanged(false);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          //console.log( 'onChange.', editor );
                          if (stateEditor) {
                            setIsChanged(true);
                          }
                          tab.expertReview.reviewText = data ?? '';

                          /*setExpertReview({
                            ...expertReview,
                            reviewText: data??"",
                          });*/
                        }}
                        onFocus={(event, editor) => {
                          //const data = editor.getData();
                          //console.log( 'Focus.', editor );
                          setStateEditor(true);
                        }}
                      />
                    </div>
                  </form>
                </TabPanel>
              );
            })}
          </TabView>
        </div>
      </div>
    </>
  );
};
