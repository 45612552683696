import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import ButtonMaterial from '@mui/material/Button';

import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ConfirmPopup } from 'primereact/confirmpopup';
import IndustryCategoryService from '../../service/IndustryCategoryService';

import CompanyIndustryCategoryService from '../../service/CompanyIndustryCategoryService';

const AddIndustryCategoryModal = ({
	customerId,
	t,
	addIndustryCategoryOpen,
	handleCloseIndustryCategoryModal,
	getCompanyIndustryCategories,
	setLoading,
}) => {
	const toast = useRef(null);
	const toastInside = useRef(null);
	const [selectedIndustryCategory, setSelectedIndustryCategory] =
		useState(null);
	const [industryCategories, setIndustryCategories] = useState([]);

	useEffect(() => {
		let unmounted = false;
		fetchIndustryCategories();

		return () => {
			unmounted = true;
		};
	}, []);
	const fetchIndustryCategories = () => {
		//Datayı almak için servis çağırıyoruz.
		setLoading(true);
		IndustryCategoryService.getList()
			.then((res) => {
				//Gelen veri ile state güncelleniyor
				setIndustryCategories(res.data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};
	const saveCompanyIndustryCategory = () => {
		if (
			selectedIndustryCategory == '' ||
			selectedIndustryCategory == null ||
			selectedIndustryCategory == undefined
		) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_category_info')}`,
				life: 3000,
			});
			return;
		}
		const payload = {
			id: customerId,
			companyIndustryCategoryId: selectedIndustryCategory.id,
		};

		setLoading(true);

		CompanyIndustryCategoryService.save(payload)
			.then((res) => {
				setTimeout(() => {
					handleCloseIndustryCategoryModal();
					setLoading(false);
					setSelectedIndustryCategory(null);
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
						life: 3000,
					});
				}, 2000);
				getCompanyIndustryCategories(customerId);
			})
			.catch((error) => {
				setLoading(false);

				toastInside.current.show({
					severity: 'error',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: error.response.data,
					life: 3000,
				});
			});
	};

	return (
		<>
			<Toast ref={toast} />

			<Dialog
				open={addIndustryCategoryOpen}
				fullWidth={true}
				maxWidth={'sm'}
				onClose={() => {
					handleCloseIndustryCategoryModal();
					setSelectedIndustryCategory(null);
				}}
			>
				<Toast ref={toastInside} />

				<DialogContent
					style={{
						overflowY: 'visible',
						display: 'flex',
						justifyContent: 'start',
						flexDirection: 'column',
						height: '300px',
					}}
				>
					<DialogContentText style={{ marginBottom: '20px' }}>
						{t('select_industry_category')}
					</DialogContentText>

					<FormControl bodyStyle={{ width: '400px' }}>
						<div style={{ width: '100%', display: 'flex' }}>
							<Dropdown
								style={{ width: '100%' }}
								appendTo='self'
								value={selectedIndustryCategory}
								filter={true}
								scrollHeight={'150px'}
								onChange={(e) => {
									setSelectedIndustryCategory(e.value);
								}}
								options={industryCategories}
								optionLabel='name'
							/>
						</div>
					</FormControl>
				</DialogContent>
				<DialogActions style={{ marginBottom: '1rem', marginRight: '1rem' }}>
					<ButtonMaterial
						onClick={() => {
							handleCloseIndustryCategoryModal();
							setSelectedIndustryCategory(null);
						}}
					>
						{' '}
						{t('Cancel')}
					</ButtonMaterial>
					<ButtonMaterial
						variant='contained'
						onClick={() => {
							saveCompanyIndustryCategory();
						}}
					>
						{' '}
						{t('save')}
					</ButtonMaterial>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default withTranslation()(AddIndustryCategoryModal);
