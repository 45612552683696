import React, { useState } from 'react';
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import { FormHelperText, FormLabel, Grid, Input } from '@material-ui/core';

import 'react-phone-input-material-ui/lib/material.css';
import './phone-input.css';
import TextField from '@material-ui/core/TextField';
import { baseTheme } from './../../baseTheme';

const textFieldTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: '#0099F7',
    },
  },
});
const useStyles = makeStyles((theme) => ({
  countryList: {
    ...theme.typography.body1,
  },
  phoneContainer: {
    width: '100%',
  },
  root: {
    marginTop: 5,
    marginBottom: 5,
  },
}));

const FormInputGrid = (props) => {
  const classes = useStyles();
  const {
    field,
    fieldState: { error },
    type,
    options,
    select,
    handleChangeEmail,
  } = props;

  const [inputType, setInputType] = useState(type);

  const isLowerCaseInput = (data) => {
    if (handleChangeEmail) {
      return handleChangeEmail(data);
    }
  };
  return (
    <>
      <Grid
        container
        justifyContent='center'
        className={classes.root}
        alignItems='center'
      >
        <ThemeProvider theme={textFieldTheme}>
          <TextField
            error={!!error}
            helperText={error?.message}
            size='small'
            variant='outlined'
            fullWidth
            select={select}
            default
            {...field}
            label={props.label}
            defaultValue={props.defaultValue}
            margin={props.margin}
            required={props.required}
            type={inputType}
            disabled={props.disabled}
            inputProps={{ readOnly: props.readOnly }}
            onChange={(event) =>
              field.onChange(isLowerCaseInput(event.target.value))
            }
          />
        </ThemeProvider>
      </Grid>
    </>
  );
};

export default FormInputGrid;
