import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LookupService from '../service/LookupService';

import { MainButton } from '../components/common/MainButton';
import store from '../Store';
import ComponentWrapper from '../components/ComponentWrapper';
import {
	Container,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import FormInput from '../components/common/FormInput';
import LargeSelectInput from '../components/common/LargeSelectInput';
import axios from 'axios';
import SelectInput from '../components/common/SelectInput';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import DoctorDemographicsService from '../service/DoctorDemographicsService';
import { useTranslation } from 'react-i18next';

const otherRequired = (other, _) => other?.id === 0;

const otherChoice = { id: 0, name: 'Diğer' };
const radioOtherChoice = '{ "id": 0, "name": "Diğer" }';

export const DoctorDemographicsUpdateForm = ({ userId }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const Schema = Yup.object().shape({
		birthDate: Yup.string()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		gender: Yup.object()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		country: Yup.object()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		countryResidenceOther: Yup.string().when('country', {
			is: otherRequired,
			then: Yup.string().required(`${t('schema_shape.not_empty')}`),
			otherwise: Yup.string(),
		}),
		occupation: Yup.object()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		occupationOther: Yup.string().when('occupation', {
			is: otherRequired,
			then: Yup.string().required(`${t('schema_shape.not_empty')}`),
			otherwise: Yup.string(),
		}),
		jobTitle: Yup.object()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		jobTitleOther: Yup.string().when('jobTitle', {
			is: otherRequired,
			then: Yup.string().required(`${t('schema_shape.not_empty')}`),
			otherwise: Yup.string(),
		}),
	});

	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [jobTitles, setJobTitles] = useState([]);
	const [genders, setGenders] = useState([]);
	const [occupations, setOccupations] = useState([]);
	const [countries, setCountries] = useState([]);

	const { handleSubmit, watch, setValue, control, formState } = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		resolver: yupResolver(Schema),
	});

	const languageId = localStorage.getItem('i18nextLng');

	useEffect(() => {
		setLoading(true);

		if (userId) {
			DoctorDemographicsService.getDoctorDemographicsInfo().then((res) => {
				let data = res.data;
				setData(data);

				setValue('companyName', data.companyName);
				setValue('birthDate', new Date(data.birthDate));
				setValue('country', data.country ? data.country : otherChoice);
				if (!data.country) {
					setValue('countryResidenceOther', data.countryResidenceOther);
				}

				setValue('jobTitle', data.jobTitle ? data.jobTitle : otherChoice);
				if (!data.jobTitle) {
					setValue('jobTitleOther', data.jobTitleOther);
				}

				setValue('occupation', data.occupation ? data.occupation : otherChoice);
				if (!data.occupation) {
					setValue('occupationOther', data.occupationOther);
				}

				setValue('gender', data.gender ? data.gender : otherChoice);
				if (!data.gender) {
					setValue('genderOther', data.genderOther);
				}
			});
		}

		LookupService.fetchDemographicLookups(languageId).then((responses) => {
			setJobTitles(responses[0].data);
			setGenders(responses[1].data);
			setCountries(responses[7].data);
			setOccupations(responses[8].data);
			setLoading(false);
		});
	}, [setValue, userId, languageId]);

	const onFinish = (doctorDemographicsForm) => {
		if (!formState.isValid && Object.keys(formState.errors).length > 0) {
			enqueueSnackbar(`${t('demographic_form_popup.control_form_info')}`, {
				variant: 'error',
			});
			return;
		}

		doctorDemographicsForm['birthDate'] = new Date(
			doctorDemographicsForm['birthDate']
		);
		if (doctorDemographicsForm['genderOther']) {
			doctorDemographicsForm['gender'] = null;
		}
		if (doctorDemographicsForm['countryResidenceOther']) {
			doctorDemographicsForm['country'] = null;
		}
		if (doctorDemographicsForm['occupationOther']) {
			doctorDemographicsForm['occupation'] = null;
		}
		if (doctorDemographicsForm['workStatusOther']) {
			doctorDemographicsForm['workStatus'] = null;
		} else {
			doctorDemographicsForm['workStatusOther'] = null;
		}
		if (doctorDemographicsForm['jobTitleOther']) {
			doctorDemographicsForm['jobTitle'] = null;
		}
		if (
			doctorDemographicsForm['jobTitle'] != null &&
			doctorDemographicsForm['jobTitle'].id === 0 &&
			(doctorDemographicsForm['jobTitleOther'] == null ||
				doctorDemographicsForm['jobTitleOther'] == '')
		) {
			doctorDemographicsForm['jobTitle'] = null;
		}
		// if (doctorDemographicsForm['ethnicityOther']) {
		//   doctorDemographicsForm['ethnicity'] = null;
		// }
		if (doctorDemographicsForm['maritalStatusOther']) {
			doctorDemographicsForm['maritalStatus'] = null;
		}
		if (
			doctorDemographicsForm['maritalStatus'] != null &&
			doctorDemographicsForm['maritalStatus'].id === 0 &&
			(doctorDemographicsForm['maritalStatusOther'] == null ||
				doctorDemographicsForm['maritalStatusOther'] == '')
		) {
			doctorDemographicsForm['maritalStatus'] = null;
		}
		axios
			.post('/users/doctorInfo', doctorDemographicsForm)
			.then(() => {
				enqueueSnackbar(`${t('demographic_form_popup.success_update')}`, {
					variant: 'success',
				});
				store.steps.current = 0;
				store.isEditMode = false;
			})
			.catch(function (error) {
				enqueueSnackbar(`${t('demographic_form_popup.error_update')}`, {
					variant: 'error',
				});
			});
	};

	const gender = watch('gender');
	const occupation = watch('occupation');
	const country = watch('country');
	const jobTitle = watch('jobTitle');

	const showFormError = () => {
		if (
			!formState.isValid &&
			formState.isSubmitting &&
			Object.keys(formState.errors).length > 0
		) {
			enqueueSnackbar(`${t('demographic_form_popup.control_form_info')}`, {
				variant: 'error',
			});
		}
	};

	return (
		<>
			{data && (
				<form noValidate onSubmit={handleSubmit(onFinish)}>
					{formState.isSubmitted && showFormError()}
					<ComponentWrapper subtitle={t('demographic_info_update')}>
						<Grid container spacing={2}>
							<Grid item={true} xs={12}>
								<TextField
									label={t('birth_day')}
									variant='outlined'
									size='small'
									disabled={true}
									defaultValue={moment(data.birthDate).format('YYYY')}
									InputProps={{ readOnly: true }}
								/>
							</Grid>
							<Grid item={true} xs={12}>
								<Controller
									control={control}
									name='gender'
									render={({ field, fieldState: { error } }) => (
										<FormControl error={!!error}>
											<FormLabel color='secondary' component='legend'>
												{t('gender_identify')}
											</FormLabel>
											<RadioGroup
												defaultValue={
													data.gender
														? JSON.stringify(data.gender)
														: radioOtherChoice
												}
												{...field}
											>
												{genders.map((gender) => (
													<FormControlLabel
														key={gender.id}
														value={JSON.stringify(gender)}
														disabled={true}
														control={<Radio />}
														label={gender.name}
													/>
												))}
												{/*<FormControlLabel*/}
												{/*  key={0}*/}
												{/*  value={radioOtherChoice}*/}
												{/*  disabled={true}*/}
												{/*  control={<Radio />}*/}
												{/*  label={t('other')}*/}
												{/*/>*/}
												<FormHelperText>{error?.message}</FormHelperText>
											</RadioGroup>
										</FormControl>
									)}
								/>
							</Grid>
							{gender && gender.id === 0 && (
								<Grid
									item={true}
									xs={12}
									container
									alignItems='center'
									spacing={1}
								>
									<Grid item={true} xs={12} md={8}>
										<Controller
											name='genderOther'
											control={control}
											render={(props) => (
												<FormInput
													{...props}
													disabled={true}
													defaultValue={data.genderOther || null}
													required
													label={t('other_gender')}
												/>
											)}
										/>
									</Grid>
								</Grid>
							)}

							<Grid
								item={true}
								xs={12}
								container
								alignItems='center'
								spacing={1}
							>
								<Grid item={true} xs={12} md={8}>
									<Controller
										control={control}
										name='country'
										render={(props) => (
											<SelectInput
												{...props}
												defaultValue={data.country || otherChoice}
												required
												label={t('indicate_country')}
												options={countries}
											/>
										)}
									/>
								</Grid>
								{country?.id === 0 && (
									<Grid item={true} xs={12} md={8}>
										<Controller
											name='countryResidenceOther'
											control={control}
											render={(props) => (
												<FormInput
													{...props}
													required
													defaultValue={data.countryResidenceOther || null}
													label={t('indicate_country1')}
												/>
											)}
										/>
									</Grid>
								)}
							</Grid>
							<Grid
								item={true}
								xs={12}
								container
								alignItems='center'
								spacing={1}
							>
								<Grid item={true} xs={12} md={8}>
									<Controller
										control={control}
										name='occupation'
										render={(props) => (
											<LargeSelectInput
												{...props}
												defaultValue={data.occupation || otherChoice}
												label={t('specify_job')}
												options={occupations}
											/>
										)}
									/>
								</Grid>
								{occupation?.id === 0 && (
									<Grid item={true} xs={12} md={8}>
										<Controller
											name='occupationOther'
											control={control}
											render={(props) => (
												<FormInput
													{...props}
													required
													defaultValue={data.occupationOther || null}
													label={t('specify_job1')}
												/>
											)}
										/>
									</Grid>
								)}
							</Grid>

							<Grid
								item={true}
								xs={12}
								container
								alignItems='center'
								spacing={1}
							>
								<Grid item={true} xs={12} md={8}>
									<Controller
										control={control}
										name='jobTitle'
										render={(props) => (
											<SelectInput
												{...props}
												defaultValue={data.jobTitle || otherChoice}
												required
												label={t('current_emp_status')}
												options={jobTitles}
											/>
										)}
									/>
								</Grid>
								{jobTitle?.id === 0 && (
									<Grid item={true} xs={12} md={8}>
										<Controller
											name='jobTitleOther'
											control={control}
											render={(props) => (
												<FormInput
													{...props}
													defaultValue={data.jobTitleOther || null}
													required
													label={t('other_work_status')}
												/>
											)}
										/>
									</Grid>
								)}
							</Grid>

							<Grid item xs={12} container alignItems='center' spacing={1}>
								<Grid item xs={12} md={8}>
									<Controller
										name='companyName'
										control={control}
										render={(props) => (
											<FormInput
												margin='dense'
												defaultValue={data.companyName || null}
												{...props}
												label={t('current_job_title')}
											/>
										)}
									/>
								</Grid>
							</Grid>
						</Grid>
					</ComponentWrapper>
					<Container style={{ marginTop: 24 }}>
						<Grid container justifyContent='flex-end'>
							<MainButton color='primary' type='submit'>
								{t('update')}
							</MainButton>
						</Grid>
					</Container>
				</form>
			)}
		</>
	);
};
