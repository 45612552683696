import axios from 'axios';

class SarfModuleService {
	getList() {
		return axios.get('/lookup/getAllSarfModules');
	}

	getById(id) {
		return axios.get('/lookup/getSarfModuleById/' + id);
	}

	save(row) {
		return axios.post('/lookup/saveNewSarfModule', row);
	}

	update(row) {
		return axios.post('/lookup/updateSarfModule', row);
	}
	delete(id) {
		return axios.delete(`/lookup/deleteSarfModuleById/${id}`);
	}
}

export default new SarfModuleService();
