import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import PageTitle from './../components/PageTitle';
import moment from 'moment';
import 'moment/locale/tr';
import { Divider, Grid, Typography } from '@material-ui/core';
import { MTableToolbar } from 'material-table';
import Popconfirm from '../components/common/Popconfirm';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { confirmPopup } from 'primereact/confirmpopup';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { withTranslation } from 'react-i18next';
import AlertDialog from '../components/DialogComp';
import CreateNewAppointment from '../components/CreateNewAppointment';
import DialogComponent from '../components/common/DialogComponent';
import { Link } from 'react-router-dom';
import AuthenticationService from '../service/AuthenticationService';

const AutoReportText = ({ t }) => {
  const [appointments, setAppointments] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [appoint, setAppoint] = useState([]);
  const toast = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dateChangeLang, setDateChangeLang] = useState('tr-TR');
  const [autoReportText, setAutoReportText] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    autoReportTextId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    language: { value: null, matchMode: FilterMatchMode.CONTAINS },
    text: { value: null, matchMode: FilterMatchMode.CONTAINS },
    validUntilDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    surname: { value: null, matchMode: FilterMatchMode.CONTAINS },
    type: { value: null, matchMode: FilterMatchMode.CONTAINS },
    stationFullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    completedYesNo: { value: null, matchMode: FilterMatchMode.CONTAINS },
    start: { value: null, matchMode: FilterMatchMode.CONTAINS },
    end: { value: null, matchMode: FilterMatchMode.CONTAINS },
    date: { value: null, matchMode: FilterMatchMode.CONTAINS },
    isSummary: { value: null, matchMode: FilterMatchMode.CONTAINS },
    operatorId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    operatorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    paragraphTypeId: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [filteredAutoReportText, setFilteredAutoReportText] = useState([]);

  const langValue = localStorage.getItem('i18nextLng') ?? 'tr';

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const accept1 = (id) => {
    cancelReportText(id);
  };

  const reject1 = () => {
    toast.current.show({
      severity: 'warn',
      summary: `${t('appointment_alert_messages.denied')}`,
      detail: `${t('appointment_alert_messages.action_declined')}`,
      life: 3000,
    });
  };

  const cancelReportText = (id) => {
    axios
      .get(`/dashboard/${id}`)
      .then((res) => {
        fetchAutoReportText();
        toast.current.show({
          severity: 'info',
          summary: 'Deletion',
          detail: t('Deleted successfully'),
          life: 3000,
        });
      })
      .catch((err) => {
        error(err);
      });
  };

  const error = (err) => {
    toast.current.show({
      severity: 'error',
      summary: 'Hata mesajı',
      detail: `${err.response.data}`,
      life: 3000,
    });
  };
  const deleteParagraph = (id) => {
    confirmDialog({
      message: `${id} ${t('Delete Paragraph')}`,
      header: `${t('appointment_alert_messages.confirmation')}`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: `${t('yes')}`,
      rejectLabel: `${t('no')}`,
      accept: () => accept1(id),
      reject: () => reject1(),
    });
  };

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(filteredAutoReportText);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      saveAsExcelFile(excelBuffer, 'files');
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const renderHeader = () => {
    return (
      <div className='clearfix'>
        <div className='float-right flex justify-content-between p-2'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              value={globalFilterValue}
              className='p-inputtext-sm'
              onChange={onGlobalFilterChange}
              placeholder={t('search...')}
            />
          </span>
          <div className='flex align-items-right export-buttons pl-2'>
            {AuthenticationService.isAdmin() && (
              <Button
                type='button'
                icon='pi pi-file-excel'
                onClick={exportExcel}
                className='p-button-success mr-2'
                data-pr-tooltip='XLS'
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const deleteSvg = (
    <svg
      fill='#EC0044'
      height='32'
      viewBox='0 0 20 20'
      width='32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.5 4C11.5 3.17157 10.8284 2.5 10 2.5C9.17157 2.5 8.5 3.17157 8.5 4H7.5C7.5 2.61929 8.61929 1.5 10 1.5C11.3807 1.5 12.5 2.61929 12.5 4H17C17.2761 4 17.5 4.22386 17.5 4.5C17.5 4.77614 17.2761 5 17 5H16.446L15.1499 16.2292C15.0335 17.2384 14.179 18 13.1631 18H6.83688C5.821 18 4.9665 17.2384 4.85006 16.2292L3.553 5H3C2.75454 5 2.55039 4.82312 2.50806 4.58988L2.5 4.5C2.5 4.22386 2.72386 4 3 4H11.5ZM15.438 5H4.561L5.84347 16.1146C5.90169 16.6192 6.32894 17 6.83688 17H13.1631C13.6711 17 14.0983 16.6192 14.1565 16.1146L15.438 5ZM8.5 7.5C8.74546 7.5 8.94961 7.65477 8.99194 7.85886L9 7.9375V14.0625C9 14.3041 8.77614 14.5 8.5 14.5C8.25454 14.5 8.05039 14.3452 8.00806 14.1411L8 14.0625V7.9375C8 7.69588 8.22386 7.5 8.5 7.5ZM11.5 7.5C11.7455 7.5 11.9496 7.65477 11.9919 7.85886L12 7.9375V14.0625C12 14.3041 11.7761 14.5 11.5 14.5C11.2545 14.5 11.0504 14.3452 11.0081 14.1411L11 14.0625V7.9375C11 7.69588 11.2239 7.5 11.5 7.5Z'
        fill='#EC0044'
      />
    </svg>
  );

  const editSvg = (
    <svg
      fill='#0099F7'
      height='32'
      viewBox='0 0 20 20'
      width='32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7 7.5C7 7.22386 7.22386 7 7.5 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H7.5C7.22386 8 7 7.77614 7 7.5ZM7.5 10C7.22386 10 7 10.2239 7 10.5C7 10.7761 7.22386 11 7.5 11H12.5C12.7761 11 13 10.7761 13 10.5C13 10.2239 12.7761 10 12.5 10H7.5ZM7 13.5C7 13.2239 7.22386 13 7.5 13H9.5C9.77614 13 10 13.2239 10 13.5C10 13.7761 9.77614 14 9.5 14H7.5C7.22386 14 7 13.7761 7 13.5ZM6 2.5C6 2.22386 6.22386 2 6.5 2C6.77614 2 7 2.22386 7 2.5V3H9.5V2.5C9.5 2.22386 9.72386 2 10 2C10.2761 2 10.5 2.22386 10.5 2.5V3H13V2.5C13 2.22386 13.2239 2 13.5 2C13.7761 2 14 2.22386 14 2.5V3H14.5C15.3284 3 16 3.67157 16 4.5V9.23163C15.6738 9.37139 15.3683 9.5744 15.102 9.84065L15 9.94266V4.5C15 4.22386 14.7761 4 14.5 4H5.5C5.22386 4 5 4.22386 5 4.5V16.5C5 16.7761 5.22386 17 5.5 17H9.22047L9.05716 17.6532C9.02795 17.7701 9.01015 17.8859 9.00286 18H5.5C4.67157 18 4 17.3284 4 16.5V4.5C4 3.67157 4.67157 3 5.5 3H6V2.5ZM15.8092 10.5478L10.9798 15.3772C10.6982 15.6588 10.4984 16.0116 10.4018 16.3979L10.0274 17.8958C9.86451 18.5472 10.4546 19.1372 11.106 18.9744L12.6039 18.5999C12.9902 18.5033 13.343 18.3036 13.6246 18.022L18.454 13.1926C19.1843 12.4622 19.1843 11.2781 18.454 10.5478C17.7237 9.81741 16.5395 9.81741 15.8092 10.5478Z'
        fill='#0099F7'
      />
    </svg>
  );

  const reload = () => {
    setVisible(!visible);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <AlertDialog
          textInfo={rowData.autoReportTextId}
          fetchAutoReportText={fetchAutoReportText}
          textData={rowData}
        >
          <Button className='p-button-text' tooltip={t('Edit Paragraph')}>
            {editSvg}
          </Button>
        </AlertDialog>

        <Button
          className='p-button-text'
          tooltip={t('delete_paragraph')}
          onClick={() => deleteParagraph(rowData.id)}
        >
          {deleteSvg}
        </Button>
      </div>
    );
  };

  const fetchAutoReportText = () => {
    setLoading(true);
    axios.get('/dashboard/getAutoReportTexts').then((res) => {
      let total = [];
      if (res.data != null) {
        for (let data of res.data) {
          data.language =
            data.languageId === 'tr' ? t('Turkish') : t('English');
          data.paragraphTypeId =
            data.textType === 1 ? t('Sentence') : t('Conjuction');
          if (data.isSummary) {
            data.isSummary = t('yes');
          } else {
            data.isSummary = t('no');
          }
        }
        const filteredAutoReportText = res.data;

        for (let filter of filteredAutoReportText) {
          total.push({
            autoReportTextId: filter.autoReportTextId,
            text: filter.text,
            paragraphTypeId: filter.paragraphTypeId,
            validUntilDate: filter.validUntilDate,
            language: filter.language,
            isSummary: filter.isSummary,
          });
        }
      }
      setAutoReportText(res.data);
      setFilteredAutoReportText(total);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchAutoReportText();
    if (langValue === 'en') {
      setDateChangeLang('en-US');
    } else {
      setDateChangeLang('tr-TR');
    }
  }, [langValue]);

  return (
    <>
      <ConfirmDialog />
      <PageTitle>{t('report_texts')}</PageTitle>
      <Toast ref={toast} />
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>{t('paragraph_list')}</h3>
          </div>
          <div className='card-toolbar'>
            <AlertDialog
              width='md'
              createReport
              fetchAutoReportText={fetchAutoReportText}
            >
              <Button
                type='button'
                icon='pi pi-plus'
                label={t('add_new_paragraph')}
                className='p-button-raised p-button-rounded p-button-outlined mr-3'
              />
            </AlertDialog>
          </div>
        </div>
        <div className='card-body'>
          <DataTable
            value={autoReportText}
            paginator
            showGridlines
            responsiveLayout='scroll'
            header={renderHeader}
            rows={10}
            paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
            rowsPerPageOptions={[10, 25, 50]}
            dataKey='id'
            rowHover
            onValueChange={(filteredAutoReportText) =>
              setFilteredAutoReportText(filteredAutoReportText)
            }
            filters={filters}
            filterDisplay='row'
            loading={loading}
            globalFilterFields={[
              'language',
              'text',
              'autoReportTextId',
              'paragraphTypeId',
              'isSummary',
            ]}
            emptyMessage={t('no_records_found')}
            currentPageReportTemplate={t('currentPageReportTemplate')}
          >
            <Column
              headerStyle={{ width: '6rem', textAlign: 'center' }}
              header={t('action')}
              body={actionBodyTemplate}
              className='actionBody'
              bodyStyle={{ textAlign: 'center' }}
            />

            <Column
              header={t('text_id')}
              sortable={true}
              field='autoReportTextId'
              filter
              style={{ maxWidth: '100px' }}
            ></Column>
            <Column
              header={t('auto_report_text')}
              sortable={true}
              field='text'
              filter
              style={{ minWidth: '100px' }}
            ></Column>
            <Column
              header={t('Paragraph Type')}
              sortable={true}
              field='paragraphTypeId'
              filter
              style={{ minWidth: '100px' }}
            ></Column>
            <Column
              header={t('isSummary')}
              sortable={true}
              field='isSummary'
              filter
              style={{ minWidth: '100px' }}
            ></Column>
            <Column
              header={t('valid_untile_date')}
              sortable={true}
              field='validUntilDate'
              body={(rowData) => {
                let d = rowData.validUntilDate;
                let date = new Date(d);
                let formattedDate = date.toLocaleDateString(dateChangeLang);
                return formattedDate;
              }}
              filter
              style={{ minWidth: '100px' }}
            ></Column>
            <Column
              header={t('language')}
              sortable={true}
              field='language'
              filter
              style={{ maxWidth: '100px' }}
            ></Column>
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(AutoReportText);
