/* eslint-disable no-undef */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import UserService from '../service/UserService';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import parsePhoneNumber from 'libphonenumber-js';
import { DropzoneDialog } from 'material-ui-dropzone';
import { Checkbox } from 'primereact/checkbox';
import Spinner from '../components/common/Spinner';

import { classNames } from 'primereact/utils';

import {
	Button,
	CssBaseline,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Box,
	Grid,
	Tooltip,
} from '@material-ui/core';
import axios from 'axios';
import * as Yup from 'yup';
import 'yup-phone';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { MainButton } from '../components/common/MainButton';
import FormInput from '../components/common/FormInput';
import PhoneInput from '../components/common/PhoneInput';
import SelectInput from './common/SelectInput';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import './userInfo.css';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { IconFlagTR, IconFlagDE, IconFlagUS } from 'material-ui-flags';
import IconButton from '@material-ui/core/IconButton';
import CustomerService from '../service/CustomerService';
import FormInputGrid from './common/FormInputGrid';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import PhoneInputEn from './common/PhoneInputEn';
import StationService from '../service/StationService';
import AuthenticationService from '../service/AuthenticationService';
const useStyles = makeStyles((theme) => ({
	card: {
		padding: 20,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	commChannels: {
		marginTop: theme.spacing(2),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
		marginLeft: '35px',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	register: {
		'&:hover': {
			textDecoration: 'underline',
		},
		textTransform: 'none',
	},
}));

export const EditUser = ({ userInfo, onClose, fetchUsers }) => {
	const { t } = useTranslation();
	const selected = localStorage.getItem('i18nextLng') || 'en';

	const Schema = Yup.object().shape({
		name: Yup.string().required(`${t('schema_shape.not_empty')}`),
		surname: Yup.string().required(`${t('schema_shape.not_empty')}`),
		email: Yup.string()
			.email('Lütfen geçerli bir email adresi girin')
			.required(`${t('schema_shape.not_empty')}`),
		// commChannels: Yup.array()
		//   .transform((o, obj) => Object.keys(obj).filter((k) => obj[k]))
		//   .min(1, 'Lütfen birini seçiniz'),
		mobilePhone: Yup.string().required(`${t('schema_shape.not_empty')}`),
		// .phone('TR', true, `${t('schema_shape.valid_phone')}`),
		// userRole: Yup.string().required(`${t('schema_shape.not_empty')}`),
		stationaryPhone: Yup.string().notRequired().nullable(),
		emailRepeat: Yup.string()
			.email(`${t('schema_shape.valid_email')}`)
			.required(`${t('schema_shape.not_empty')}`)
			.oneOf([Yup.ref('email')], `${t('email_doesnt_match')}`),
		webUILoginNotAllowed: Yup.boolean().notRequired().nullable(),
	});
	const getFormErrorMessage = (name) => {
		return errors[name] ? (
			<small className='p-error'>{errors[name].message}</small>
		) : (
			<small className='p-error'>&nbsp;</small>
		);
	};
	const userId = userInfo;
	const toast = useRef(null);
	// let {path} = useRouteMatch();
	const { enqueueSnackbar } = useSnackbar();

	let history = useHistory();
	const classes = useStyles();
	const {
		control,
		handleSubmit,
		reset,
		watch,
		clearErrors,
		setError,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(Schema),
	});

	const options = [
		{ label: 'Email', value: 'e' },
		{ label: 'SMS', value: 's' },
		{ label: 'Mobil Telefon', value: 'm' },
		{ label: 'Sabit Hat', value: 'l' },
		{ label: 'Fax', value: 'f' },
	];

	const [commChannels, setCommChannels] = useState([]);
	const [open, setOpen] = useState(false);
	const [userRoles, setUserRoles] = useState([]);
	const [customers, setCustomers] = useState([]);
	const [customer, setCustomer] = useState({});
	const [file, setFile] = useState(null);
	const [role, setRole] = useState([]);
	const [isDoctor, setIsDoctor] = useState(false);
	const [user, setUser] = useState({});
	const [testPackages, setTestPackages] = useState([]);
	const [testPackage, setTestPackage] = useState({});
	const [testPackageTypes, setTestPackageTypes] = useState([]);
	const [testPackageType, setTestPackageType] = useState({});
	const [selectedTestPackage, setSelectedTestPackage] = useState({});
	const [selectedTestPackageType, setSelectedTestPackageType] = useState({});
	const [webUILoginNotAllowed, setWebUILoginNotAllowed] = useState(false);
	const [lang, setLang] = useState({});
	const [station, setStation] = useState();
	const [stations, setStations] = useState([]);
	const [email, setEmail] = useState();
	const [loading, setLoading] = useState(false);
	const updateDoctor = () => setIsDoctor(!isDoctor);

	const userLoginId = JSON.parse(localStorage.authenticatedUser);

	const onSubmit = async (updateForm) => {
		setLoading(true);
		delete updateForm['image'];
		updateForm['role'] = role;
		// delete updateForm['email'];
		updateForm['commChannels'] = commChannels.join();
		let phoneNumber = '+' + updateForm['mobilePhone'].replace(/null/g, '');
		let formatsPhoneNumber = parsePhoneNumber(phoneNumber);

		if (formatsPhoneNumber) {
			updateForm['mobilePrefix'] = formatsPhoneNumber.countryCallingCode;
			updateForm['mobilePhone'] = formatsPhoneNumber.nationalNumber;
		} else {
			updateForm['mobilePrefix'] = phoneNumber;
			updateForm['mobilePhone'] = '';
		}

		const stationaryPhone = updateForm['stationaryPhone'];
		if (stationaryPhone == null || stationaryPhone === '') {
			delete updateForm['stationaryPhone'];
		}

		updateForm['assessmentPackage'] = testPackage;
		updateForm['preferredLanguage'] = lang;
		updateForm['customerId'] = customer.id;

		updateForm['locationId'] = station.location
			? station.location.id
			: station.id;

		if (!updateForm.assessmentPackage?.id) {
			enqueueSnackbar(`${t('enqueueSnackbar_message.please_select_package')}`, {
				variant: 'error',
			});
			return;
		}
		const updateFormData = JSON.stringify(updateForm);
		axios
			.post('users/updateUser', updateFormData, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((res) => {
				enqueueSnackbar(`${t('enqueueSnackbar_message.user_update_success')}`, {
					variant: 'success',
					autoHideDuration: 2500,
				});
				onClose();
				fetchUsers();
				setLoading(false);
			})
			.catch((err) => {
				if (err.response.data.LOCATION_ADMIN_ERROR) {
					enqueueSnackbar(
						`${t('the_user')} ${email} ${t('location_admin_role_error')}`,
						{
							variant: 'error',
							autoHideDuration: 8000,
						}
					);
					setLoading(false);
				} else if (err.response.data.LOCATION_OPERATOR_ERROR) {
					enqueueSnackbar(
						`${t('the_user')} ${email} ${t('location_operator_role_error')}`,
						{
							variant: 'error',
							autoHideDuration: 8000,
						}
					);
					setLoading(false);
				} else {
					enqueueSnackbar(`${t('enqueueSnackbar_message.user_update_error')}`, {
						variant: 'error',
					});
					setLoading(false);
				}
			});
	};

	useEffect(() => {
		getAllTestPackageTypes();
		CustomerService.getCustomerList().then((r) => {
			setCustomers(r.data);
		});
		if (AuthenticationService.isLocationAdmin()) {
			getStationByLocationAdminList();
		} else {
			getStationList();
		}
	}, []);

	const getStationList = () => {
		StationService.getStationList().then((resp) => {
			var data = resp.data;
			if (data != null) {
				for (const i of data) {
					i.fullName =
						i.location?.country.name +
						'/' +
						i.location?.city.name +
						'/' +
						i.location?.name;
				}
			}
			setStations(data);
		});
	};

	const getStationByLocationAdminList = () => {
		StationService.getStationByLocationAdminList(userLoginId.userId).then(
			(resp) => {
				var data = resp.data;
				if (data != null) {
					for (const i of data) {
						i.fullName =
							i.location.country.name +
							'/' +
							i.location.city.name +
							'/' +
							i.location.name +
							'/' +
							i.name;
					}
				}
				setStations(data);
			}
		);
	};

	// let matchedPackage = testPackages.filter(
	// 	(item) => item.id === testPackage?.id
	// );

	// useEffect(() => {
	// 	setSelectedTestPackage(matchedPackage[0]);
	// }, [matchedPackage]);

	React.useEffect(() => {
		const subscription = watch((value) => {
			if (value.email === value.emailRepeat) {
				clearErrors('emailRepeat');
			} else {
				setError('emailRepeat', {
					type: 'oneOf',
					message: `${t('email_doesnt_match')}`,
				});
			}
		});
		return () => subscription.unsubscribe();
	}, [clearErrors, setError, watch, errors]);

	function handleSelect(checkedName) {
		const newNames = commChannels?.includes(checkedName)
			? commChannels?.filter((name) => name !== checkedName)
			: [...(commChannels ?? []), checkedName];
		setCommChannels(newNames);
		return newNames;
	}

	function handleChange(e) {
		setRole(e.target.value);
	}
	const getUser = (userId) => {
		setLoading(true);
		UserService.getUserById(userId)
			.then(({ data }) => {
				setUser({
					userId: data?.userId,
					name: data?.name,
					surname: data?.surname,
					roles: data?.roles[0],
					email: data?.email,
					mobilePhone: data?.mobilePrefix + data?.mobilePhone,
					stationaryPhone: data?.stationaryPhone,
					webUILoginNotAllowed: data?.webUILoginNotAllowed,
					commChannels:
						typeof data.commChannels === 'string'
							? data?.commChannels?.split(',').map((str) => str)
							: data?.commChannels,
					emailRepeat: data?.email,
				});
				setEmail(data?.email);
				setRole(data?.roles[0]);

				setTestPackageType(data?.assessmentPackage?.assessmentPackageType);
				getAllTestPackagesByTypes(
					data?.assessmentPackage?.assessmentPackageType?.id
				);

				setTestPackage(data?.assessmentPackage);
				const object = {
					companySizes: data?.customer?.companySizes?.id,
					companyType: data?.customer?.companyType,
					customerHeadQuarterLocations:
						data?.customer?.customerHeadQuarterLocations?.id,
					id: data?.customer?.id,
					name: data?.customer?.name,
				};
				setCustomer(object);
				setLang(data?.preferredLanguage);
				data.location.fullName =
					data?.location?.country?.name +
					'/' +
					data?.location?.city?.name +
					'/' +
					data?.location?.name;
				setStation(data?.location);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};
	useEffect(() => {
		if (user.commChannels && user.commChannels.length > 0) {
			setCommChannels(user.commChannels);
		}
		reset(user);
	}, [user]);

	useEffect(() => {
		getUser(userId);

		if (AuthenticationService.isAdmin()) {
			var object = [
				{ id: 'ADMIN', name: `${t('user_role.ADMIN')}` },
				{ id: 'OPERATOR', name: `${t('user_role.OPERATOR')}` },
				{
					id: 'USER',
					name: `${t('user_role.USER')}`,
				},
				{ id: 'DOCTOR', name: `${t('user_role.DOCTOR')}` },
				{ id: 'EXPERT', name: `${t('user_role.EXPERT')}` },
				{ id: 'LOCATION_ADMIN', name: `${t('user_role.LOCATION_ADMIN')}` },
			];
			setUserRoles(object);
		} else {
			var object = [
				{ id: 'OPERATOR', name: `${t('user_role.OPERATOR')}` },
				{
					id: 'USER',
					name: `${t('user_role.USER')}`,
				},
			];
			setUserRoles(object);
		}
	}, []);

	const handleChangeTestPackageType = (e) => {
		setTestPackageType(e.target.value);
		setSelectedTestPackage(null);
		getAllTestPackagesByTypes(e.target.value.id);
	};

	const handleSetTestPackage = (e) => {
		setTestPackage(e.target.value);
	};

	const getAllTestPackageTypes = async () => {
		try {
			const { data } = await axios.get('/users/getAllAssesmentPackageTypes');

			setTestPackageTypes(data);
		} catch (error) {
			console.log(error);
		}
	};
	const getAllTestPackagesByTypes = async (packageId) => {
		try {
			const { data } = await axios.get(
				'/users/getAssessmentPackagesByAssessmentTypeId/' + packageId
			);
			setTestPackages(data);
		} catch (error) {
			console.log(error);
		}
	};
	const handleOnClick = useCallback(
		() => history.push('/panel/kullanicilar'),
		[history]
	);

	const languageItems = [
		{ label: 'English', value: 'en', code: 'US' },
		{ label: 'Türkçe', value: 'tr', code: 'TR' },
	];
	const onLangChange = (e) => {
		setLang(e.value);
	};

	const selectedCountryTemplate = (option, props) => {
		if (option) {
			return (
				<div className='country-item country-item-value'>
					{option.value === 'en' ? (
						<IconButton className={classes.muiIcon}>
							<IconFlagUS />
						</IconButton>
					) : (
						<IconButton className={classes.muiIcon}>
							<IconFlagTR />
						</IconButton>
					)}
					<div>{option.label}</div>
				</div>
			);
		}
	};

	const countryOptionTemplate = (option) => {
		return (
			<div className='country-item'>
				{option.value === 'en' ? (
					<IconButton className={classes.muiIcon}>
						<IconFlagUS />
					</IconButton>
				) : (
					<IconButton className={classes.muiIcon}>
						<IconFlagTR />
					</IconButton>
				)}
				<div>{option.label}</div>
			</div>
		);
	};

	const confirm = async (registerForm) => {
		const isValidEmails = await validateEmail(registerForm.email);
		if (isValidEmails) {
			onSubmit(registerForm);
		} else {
			confirmDialog({
				message: `${t('enqueueSnackbar_message.english_charachter_valid')}`,
				header: `${t('appointment_alert_messages.confirmation')}`,
				icon: 'pi pi-exclamation-triangle',
				acceptLabel: `${t('yes')}`,
				rejectLabel: `${t('no')}`,
				accept: () => onSubmit(registerForm),
				reject: () => reject(),
			});
		}
	};

	const reject = () => {};

	const validateEmail = async (email) => {
		const re =
			/^([a-zA-Z0-9]+[._]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[._]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
		return re.test(String(email).toLowerCase());
	};

	const handleChangeEmail = (value) => {
		return value.toLowerCase();
	};

	return (
		<>
			{/* <ConfirmDialog /> */}
			<Toast ref={toast} />
			<Spinner loading={loading} />

			<div className='card card-custom gutter-b' style={{ width: '100%' }}>
				<div className='card-body' style={{ width: '100%' }}>
					<div className={classes.paper}>
						<Grid item={true} xs={12} sm={12} md={12} lg={12}>
							<form
								className={classes.form}
								noValidate
								onSubmit={handleSubmit(confirm)}
								encType='multipart/form-data'
							>
								<div>
									<div style={{ display: 'flex', width: '91%' }}>
										<div style={{ width: '44.4%' }}>
											<Controller
												name='name'
												control={control}
												render={(props) => (
													<div style={{ marginTop: '5px' }}>
														<label
															style={{ position: 'relative', top: '0.5em' }}
														>
															{t('name')}
														</label>
														<FormInput
															{...props}
															margin='dense'
															required
															defaultValue={user ? user.name : ' '}
														/>
													</div>
												)}
											/>
											<Controller
												name='email'
												control={control}
												label={t('email_repeat')}
												render={(props) => (
													<>
														<div style={{ marginTop: '5px' }}>
															<label
																style={{ position: 'relative', top: '0.8em' }}
															>
																{t('email')}
															</label>
															<FormInputGrid
																placeholder={t('email') + '*:'}
																{...props}
																margin='dense'
																required
																handleChangeEmail={handleChangeEmail}
															/>
														</div>
													</>
												)}
											/>

											<Controller
												control={control}
												id='autocomplete-off'
												name='customer'
												autocomplete='off'
												render={(props) => (
													<div style={{ marginTop: '10px' }}>
														<label>{t('customer')}</label>
														<Dropdown
															style={{ width: '100%' }}
															options={customers}
															appendTo='self'
															value={customer}
															onChange={(e) => {
																setCustomer(e.value);
															}}
															optionLabel='name'
															placeholder=''
														/>
													</div>
												)}
											/>
											<Controller
												control={control}
												id='autocomplete-off'
												name='name'
												autocomplete='off'
												render={(props) => (
													<div style={{ marginTop: '10px' }}>
														<label>{t('test_package_type')}</label>
														<Dropdown
															style={{ width: '100%' }}
															options={testPackageTypes}
															appendTo='self'
															value={testPackageType}
															onChange={handleChangeTestPackageType}
															optionLabel='name'
															optionValue=''
															placeholder=''
														/>
													</div>
												)}
											/>
											<Controller
												control={control}
												id='autocomplete-off'
												name='assessmentPackages'
												autocomplete='off'
												render={(props) => (
													<div style={{ marginTop: '10px' }}>
														<label>{t('test_package')}</label>
														<Dropdown
															style={{ width: '100%' }}
															options={testPackages}
															appendTo='self'
															value={testPackage}
															onChange={handleSetTestPackage}
															optionLabel='packageName'
															placeholder={selectedTestPackage?.packageName}
														/>
													</div>
												)}
											/>
										</div>
										<div style={{ marginLeft: '50px', width: '44.4%' }}>
											<Controller
												name='surname'
												control={control}
												render={(props) => (
													<div style={{ marginTop: '5px' }}>
														<label
															style={{ position: 'relative', top: '0.5em' }}
														>
															{t('surname')}
														</label>

														<FormInput
															{...props}
															margin='dense'
															required
															defaultValue={user ? user.surname : ' '}
														/>
													</div>
												)}
											/>

											<Controller
												name='emailRepeat'
												control={control}
												render={(props) => (
													<>
														<div style={{ marginTop: '5px' }}>
															<label
																style={{ position: 'relative', top: '0.5em' }}
															>
																{t('email_repeat')}
															</label>
															<FormInputGrid
																placeholder={t('email_repeat') + '*:'}
																{...props}
																margin='dense'
																required
																handleChangeEmail={handleChangeEmail}
															/>
														</div>
													</>
												)}
											/>

											<Controller
												control={control}
												id='autocomplete-off'
												name='role'
												autocomplete='off'
												render={(props) => (
													<div style={{ marginTop: '10px' }}>
														<label>{t('user_roles')}</label>
														<Dropdown
															style={{ width: '100%' }}
															options={userRoles}
															appendTo='self'
															value={role}
															onChange={(e) => {
																handleChange(e);
															}}
															optionLabel='name'
															optionValue='id'
															placeholder=''
														/>
													</div>
												)}
											/>
											<Controller
												control={control}
												id='autocomplete-off'
												name='preferredLanguage'
												autocomplete='off'
												render={(props) => (
													<div
														className='countryFlagLocation'
														style={{ marginTop: '10px' }}
													>
														<label>{t('preferred_language')}</label>
														<Dropdown
															style={{ width: '100%', height: '45px' }}
															appendTo='self'
															value={lang}
															options={languageItems}
															onChange={onLangChange}
															optionLabel='preferredLanguage'
															valueTemplate={selectedCountryTemplate}
															itemTemplate={countryOptionTemplate}
														/>
													</div>
												)}
											/>
										</div>
									</div>
									<label>{t('hub')}</label>
									<div>
										<Dropdown
											style={{ marginBottom: '10px', width: '87.2%' }}
											value={station}
											className='p-inputtext-sm'
											filter={true}
											appendTo='self'
											onChange={(e) => {
												setStation(e.value);
											}}
											options={stations}
											optionLabel='fullName'
											placeholder={station?.fullName}
										/>
									</div>
									<div style={{ display: 'flex', width: '90%' }}>
										<div style={{ width: '45%', marginRight: '20px' }}>
											{selected === 'tr' ? (
												<Controller
													name='mobilePhone'
													control={control}
													render={(props) => (
														<PhoneInput
															placeholder={t('mobile_phone') + '*:'}
															{...props}
														/>
													)}
												/>
											) : (
												<Controller
													name='mobilePhone'
													control={control}
													render={(props) => (
														<PhoneInputEn
															placeholder={t('mobile_phone') + '*:'}
															{...props}
														/>
													)}
												/>
											)}
										</div>
										<div style={{ width: '45%', marginLeft: '30px' }}>
											{selected === 'tr' ? (
												<Controller
													name='stationaryPhone'
													control={control}
													render={(props) => (
														<PhoneInput
															required
															placeholder={t('stationary_phone') + ':'}
															{...props}
														/>
													)}
												/>
											) : (
												<Controller
													name='stationaryPhone'
													control={control}
													render={(props) => (
														<PhoneInputEn
															required
															placeholder={t('stationary_phone') + ':'}
															{...props}
														/>
													)}
												/>
											)}
										</div>
									</div>
									<div
										style={{
											width: '87%',
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
										}}
									>
										<div>
											<Controller
												name='webUILoginNotAllowed'
												control={control}
												rules={{ required: 'Accept is required.' }}
												render={({ field }) => (
													<>
														<Checkbox
															inputId={field.name}
															checked={field.value || false}
															className={classNames({
																'p-invalid': errors.webUILoginNotAllowed,
															})}
															onChange={(e) => field.onChange(e.checked)}
														/>
														<label
															htmlFor={field.name}
															className={classNames({
																'p-error': errors.webUILoginNotAllowed,
															})}
														>
															<span className='mr-2'>
																{' '}
																{t('web_ui_login_not_allowed_label')}{' '}
															</span>
														</label>
													</>
												)}
											/>
										</div>
										<div>
											<Controller
												name='image'
												control={control}
												render={() => (
													<Box marginTop={2} marginBottom={2}>
														<Grid container justifyContent='center'>
															<Tooltip title={t('optional_photos_message')}>
																<Button
																	variant='contained'
																	color='secondary'
																	onClick={() => setOpen(true)}
																>
																	{file == null
																		? `${t('add_photo')}`
																		: `${t('change_photo')}`}
																</Button>
															</Tooltip>
														</Grid>

														<DropzoneDialog
															acceptedFiles={['image/*']}
															cancelButtonText={`${t('cancel')}`}
															submitButtonText={`${t('upload')}`}
															dropzoneText={`${t('drag_and_drop_file')}`}
															previewText={`${t('preview: ')}`}
															dialogTitle={`${t('file_upload')}`}
															maxFileSize={5000000}
															filesLimit={1}
															open={open}
															onClose={() => setOpen(false)}
															onSave={(files) => {
																enqueueSnackbar(
																	`${t('photo_upload_success')}`,
																	{
																		variant: 'success',
																	}
																);
																setFile(files[0]);
																setOpen(false);
															}}
															showAlerts={false}
															showPreviews={true}
															showFileNamesInPreview={true}
														/>
													</Box>
												)}
											/>
										</div>
									</div>

									<div className='d-flex justify-content-around'>
										<MainButton
											type='submit'
											variant='contained'
											color='primary'
											className={classes.submit}
										>
											{t('UPDATE')}
										</MainButton>
										<MainButton
											type='button'
											variant='contained'
											color='primary'
											className={classes.submit}
											onClick={onClose}
										>
											{t('GIVE_UP')}
										</MainButton>
									</div>
								</div>
							</form>
						</Grid>
					</div>
				</div>
			</div>
		</>
	);
};
