import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import NoPermission from '../pages/NoPermission';
import AuthenticationService from './AuthenticationService';

class AdminRoute extends Component {
	componentDidMount() {
		AuthenticationService.setupAxiosInterceptors();
	}

	render() {
		if (
			AuthenticationService.isUserLoggedIn() &&
			(AuthenticationService.isAdmin() ||
				AuthenticationService.isOperator() ||
				AuthenticationService.isExpert() ||
				AuthenticationService.isLocationAdmin())
		) {
			return <Route {...this.props} />;
		} else {
			return <NoPermission />;
		}
	}
}

export default AdminRoute;
