import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Slash } from './Slash';
import { AppBar, Grid, Toolbar } from '@material-ui/core';
import { Dropdown } from 'primereact/dropdown';
import i18next, { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { IconFlagTR, IconFlagDE, IconFlagUS } from 'material-ui-flags';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
const SmallText = styled.span`
	font-size: 24px;
	font-weight: bold;
	color: var(--text-color-secondary-dark);

	@media (max-width: 600px) {
		font-size: 14px;
	}

	@media (max-width: 960px) {
		font-size: 20px;
	}
`;

const Image = styled.img`
	width: 200px;
	@media (max-width: 600px) {
		width: 100px;
	}

	@media (max-width: 960px) {
		width: 150px;
	}
`;

const Logo = styled.div`
	float: left;
	width: 200px;
	height: 100%;
	margin-left: 50px;
	margin-right: 50px;

	@media (max-width: 600px) {
		margin-left: 10px;
		margin-right: 10px;
	}
`;

const useStyles = makeStyles((theme) => ({
	muiIcon: {
		padding: 2,
	},
}));

const SmallHeader = ({ title, preferredLanguage }) => {
	const classes = useStyles();
	const selected = localStorage.getItem('i18nextLng') || 'en';
	const [lang, setLang] = useState(selected);

	const languageItems = [
		{ label: 'English', value: 'en', code: 'US', flag: `${IconFlagUS}` },
		{ label: 'Türkçe', value: 'tr', code: 'TR', flag: `${IconFlagTR}` },
	];

	useEffect(() => {
		if (typeof preferredLanguage === 'string') {
			setLang(preferredLanguage);
			i18next.changeLanguage(preferredLanguage);
		}
	}, [preferredLanguage]);
	const onLangChange = (e) => {
		setLang(e.value);
		i18next.changeLanguage(e.value);
	};
	const countryOptionTemplate = (option) => {
		return (
			<div className='country-item'>
				{option.value === 'en' ? (
					<IconButton className={classes.muiIcon}>
						<IconFlagUS />
					</IconButton>
				) : (
					<IconButton className={classes.muiIcon}>
						<IconFlagTR />
					</IconButton>
				)}
				<div>{option.label}</div>
			</div>
		);
	};
	const selectedCountryTemplate = (option, props) => {
		if (option) {
			return (
				<div className='country-item country-item-value'>
					{option.value === 'en' ? (
						<IconButton className={classes.muiIcon}>
							<IconFlagUS />
						</IconButton>
					) : (
						<IconButton className={classes.muiIcon}>
							<IconFlagTR />
						</IconButton>
					)}
					<div>{option.label}</div>
				</div>
			);
		}
	};
	return (
		<AppBar position='static' style={{ backgroundColor: '#001529' }}>
			<Toolbar>
				<Grid container justifyContent='center' alignItems='center'>
					<Logo>
						<Image id='company-logo' alt='NeoAuvra logo' src='/logo.png' />
					</Logo>

					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Slash compact />
						<SmallText>{title}</SmallText>
					</div>
				</Grid>
				<div style={{ float: 'right' }} className='country'>
					<Dropdown
						className='country'
						appendTo='self'
						value={lang}
						options={languageItems}
						onChange={onLangChange}
						optionLabel='label'
						placeholder={t('select_language')}
						valueTemplate={selectedCountryTemplate}
						itemTemplate={countryOptionTemplate}
					/>
				</div>
			</Toolbar>
		</AppBar>
	);
};
export default withTranslation()(SmallHeader);
