import axios from 'axios';

class IndustryCategoryService {
	getList() {
		return axios.get('/lookup/getAllIndustryCategories');
	}

	getById(id) {
		return axios.get('/lookup/getIndustryCategoriesById/' + id);
	}

	save(row) {
		return axios.post('/lookup/saveIndustryCategories', row);
	}

	update(row) {
		return axios.post('/lookup/updateIndustryCategories', row);
	}
	delete(id) {
		return axios.delete(`/lookup/deleteIndustryCategoriesById/${id}`);
	}
}

export default new IndustryCategoryService();
