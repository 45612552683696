import React, { useEffect, useState } from 'react';
import LookupService from '../service/LookupService';
import axios from 'axios';
import { MainButton } from './common/MainButton';
import {
	Box,
	Button,
	Grid,
	Card,
	CardContent,
	Typography,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import LargeSelectInput from './../components/common/LargeSelectInput';
import { useSnackbar } from 'notistack';

import ComponentWrapper from './ComponentWrapper';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from './common/Spinner';
import Divider from './common/Divider';
import SelectInput from './common/SelectInput';
import { Alert, AlertTitle } from '@material-ui/lab';
import { withTranslation } from 'react-i18next';

const AGE_RANGES = [
	{ id: 0, name: '< 29', range: [null, 29] },
	{ id: 1, name: '30 - 39', range: [30, 39] },
	{ id: 2, name: '40 - 49', range: [40, 49] },
	{ id: 3, name: '50 - 59', range: [50, 59] },
	{ id: 4, name: '60 - 69', range: [60, 69] },
	{ id: 5, name: '>= 70', range: [70, null] },
];

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		zIndex: 0,
	},
}));

const GeneralSearch = ({ t }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { handleSubmit, control } = useForm();
	const classes = useStyles();

	const [notFound, setNotFound] = useState(false);
	const [downloadUrl, setDownloadUrl] = useState(null);
	const [dataReady, setDataReady] = useState(false);

	const [loading, setLoading] = useState(false);

	const [countries, setCountries] = useState([]);
	const [genders, setGenders] = useState([]);
	const [jobTitles, setJobTitles] = useState([]);
	const [occupations, setOccupations] = useState([]);
	const [ethnicities, setEthnicities] = useState([]);
	const [maritalStatuses, setMaritalStatuses] = useState([]);
	const [childStatuses, setChildStatuses] = useState([]);
	const [educations, setEducations] = useState([]);
	const [workStatuses, setWorkStatuses] = useState([]);
	const [annualIncomes, setAnnualIncomes] = useState([]);
	const [domains, setDomains] = useState([]);
	const [subDomains, setSubDomains] = useState([]);
	const [metrics, setMetrics] = useState([]);

	const languageId = localStorage.getItem('i18nextLng');
	useEffect(() => {
		setLoading(true);
		LookupService.fetchDashboardLookups(languageId).then((responses) => {
			setJobTitles(responses[0].data);
			setGenders(responses[1].data);
			setEthnicities(responses[2].data);
			setMaritalStatuses(responses[3].data);
			setChildStatuses(responses[4].data);
			setEducations(responses[5].data);
			setAnnualIncomes(responses[6].data);
			setCountries(responses[7].data);
			setOccupations(responses[8].data);
			setWorkStatuses(responses[9].data);
			setDomains(responses[10].data);
			setSubDomains(responses[11].data);
			setMetrics(responses[12].data);
			setLoading(false);
		});
	}, [languageId]);

	const onFinish = (searchForm) => {
		setLoading(true);

		if (searchForm['ageRanges']) {
			let ageRanges = [];

			searchForm['ageRanges'].forEach((ageRange) => {
				ageRanges.push({
					startAge: ageRange.range[0],
					endAge: ageRange.range[1],
				});
			});
			searchForm['ageRanges'] = ageRanges;
		}

		axios
			.post('/dashboard/generalSearch', searchForm, { responseType: 'blob' })
			.then((res) => {
				const blob = new Blob([res.data]);
				if (res.data.type === 'application/json') {
					setNotFound(true);
				} else {
					const downloadUrl = window.URL.createObjectURL(blob);
					setDownloadUrl(downloadUrl);
				}
				setDataReady(true);
			})
			.catch((error) => {
				setLoading(false);
				enqueueSnackbar(`${t('no_records_found')}`, {
					variant: 'error',
					autoHideDuration: 1000,
				});
			});
	};

	const onDownload = () => {
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', `General-Report.xlsx`);
		document.body.appendChild(link);
		link.click();
		link.remove();
	};

	const onSearchAgain = () => {
		setDownloadUrl(null);
		setNotFound(false);
		setLoading(false);
		setDataReady(false);
	};
	return (
		<div className={classes.root}>
			{dataReady ? (
				<>
					<Grid container justifyContent='center' alignItems='center'>
						<Grid item={true} xs={6} container justifyContent='center'>
							<Card>
								<CardContent>
									{notFound ? (
										<Alert severity='warning'>
											<AlertTitle>{t('no_users_selected_criteria')}</AlertTitle>
										</Alert>
									) : (
										<>
											<Alert severity='success'>
												<AlertTitle>
													{t('Genel kullanıcı raporu oluşturuldu!')}
												</AlertTitle>
												{t('download_xlsx_message')}
											</Alert>
											<Box marginTop={2} textAlign='center'>
												<Button
													variant='contained'
													color='primary'
													onClick={() => onDownload()}
												>
													{t('Download')}
												</Button>
											</Box>
										</>
									)}
								</CardContent>
							</Card>
						</Grid>
					</Grid>
					<Button
						color='secondary'
						onClick={() => onSearchAgain()}
						style={{
							marginTop: 20,
							textDecoration: 'none',
							textTransform: 'none',
						}}
					>
						{t('another_search')}
					</Button>
				</>
			) : (
				<>
					<form noValidate onSubmit={handleSubmit(onFinish)}>
						<Spinner loading={loading} />
						<ComponentWrapper subtitle={t('general_search')}>
							<Grid item={true} xs={12} md={8} container spacing={3}>
								<Grid item={true} xs={12} s={6}>
									<Divider spacing={1}>
										<Typography variant='h6'>
											{t('select_user_search_criteira')}
										</Typography>
									</Divider>
								</Grid>
								<Grid item={true} xs={6}>
									<Controller
										control={control}
										name='countries'
										render={(props) => (
											<LargeSelectInput
												{...props}
												multiple
												limitTags={3}
												dontIncludeOther
												label={t('country')}
												options={countries}
											/>
										)}
									/>
								</Grid>
								<Grid item={true} xs={6}>
									<Controller
										control={control}
										name='jobTitles'
										render={(props) => (
											<SelectInput
												{...props}
												multiple
												limitTags={3}
												label={t('job_titles')}
												options={jobTitles}
											/>
										)}
									/>
								</Grid>
								<Grid item={true} xs={6}>
									<Controller
										control={control}
										name='occupations'
										render={(props) => (
											<LargeSelectInput
												{...props}
												multiple
												limitTags={3}
												dontIncludeOther
												label={t('occupations')}
												options={occupations}
											/>
										)}
									/>
								</Grid>
								<Grid item={true} xs={6}>
									<Controller
										control={control}
										name='genders'
										render={(props) => (
											<SelectInput
												{...props}
												multiple
												limitTags={3}
												label={t('genders')}
												options={genders}
											/>
										)}
									/>
								</Grid>
								<Grid item={true} xs={6}>
									<Controller
										control={control}
										name='ethnicities'
										render={(props) => (
											<SelectInput
												{...props}
												multiple
												limitTags={3}
												label={t('ethnicities')}
												options={ethnicities}
											/>
										)}
									/>
								</Grid>
								<Grid item={true} xs={6}>
									<Controller
										control={control}
										name='maritalStatuses'
										render={(props) => (
											<SelectInput
												{...props}
												multiple
												limitTags={3}
												label={t('marital_statuses')}
												options={maritalStatuses}
											/>
										)}
									/>
								</Grid>
								<Grid item={true} xs={6}>
									<Controller
										control={control}
										name='childStatuses'
										render={(props) => (
											<SelectInput
												{...props}
												multiple
												limitTags={3}
												label={t('child_statuses')}
												options={childStatuses}
											/>
										)}
									/>
								</Grid>
								<Grid item={true} xs={6}>
									<Controller
										control={control}
										name='educations'
										render={(props) => (
											<SelectInput
												{...props}
												multiple
												limitTags={3}
												label={t('educations')}
												options={educations}
											/>
										)}
									/>
								</Grid>
								<Grid item={true} xs={6}>
									<Controller
										control={control}
										name='workStatuses'
										render={(props) => (
											<SelectInput
												{...props}
												multiple
												limitTags={3}
												label={t('work_statuses')}
												options={workStatuses}
											/>
										)}
									/>
								</Grid>
								<Grid item={true} xs={6}>
									<Controller
										control={control}
										name='annualIncomes'
										render={(props) => (
											<SelectInput
												{...props}
												multiple
												limitTags={3}
												label={t('annual_incomes')}
												options={annualIncomes}
											/>
										)}
									/>
								</Grid>
								<Grid item={true} xs={6}>
									<Controller
										control={control}
										name='ageRanges'
										render={(props) => (
											<SelectInput
												{...props}
												multiple
												limitTags={3}
												dontIncludeOther
												label={t('age_ranges')}
												options={AGE_RANGES}
											/>
										)}
									/>
								</Grid>

								<Grid item={true} xs={12}>
									<Divider spacing={1}>
										<Typography variant='h6'>
											{t('select_desired_options_report')}
										</Typography>
									</Divider>
								</Grid>
								<Grid item={true} xs={12}>
									<Controller
										control={control}
										name='domains'
										render={(props) => (
											<SelectInput
												{...props}
												multiple
												limitTags={3}
												label={t('domains')}
												options={domains}
											/>
										)}
									/>
								</Grid>
								<Grid item={true} xs={12}>
									<Controller
										control={control}
										name='subDomains'
										render={(props) => (
											<SelectInput
												{...props}
												multiple
												limitTags={3}
												label={t('sub_domains')}
												options={subDomains}
											/>
										)}
									/>
								</Grid>
								<Grid item={true} xs={12}>
									<Controller
										control={control}
										name='metrics'
										render={(props) => (
											<SelectInput
												{...props}
												multiple
												limitTags={3}
												label={t('metrics')}
												options={metrics}
											/>
										)}
									/>
								</Grid>
							</Grid>
						</ComponentWrapper>
						<Box marginTop={2}>
							<MainButton color='primary' disabled={loading} type='submit'>
								{t('search')}
							</MainButton>
						</Box>
					</form>
				</>
			)}
		</div>
	);
};

export default withTranslation()(GeneralSearch);
