import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import PeopleIcon from '@material-ui/icons/People';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventIcon from '@material-ui/icons/Event';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';

import Menu from '@material-ui/core/Menu';
import AuthenticationService from '../service/AuthenticationService';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import store from './../Store';
import Avatar from '@material-ui/core/Avatar';
import axios from 'axios';
import './navbar.css';
import { Dropdown } from 'primereact/dropdown';
import i18next, { t } from 'i18next';
import { useTranslation, withTranslation } from 'react-i18next';

import { IconFlagTR, IconFlagDE, IconFlagUS } from 'material-ui-flags';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  drawer: {
    // background: '#001529',
    background: '#0A2365',
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    // backgroundColor: '#001529',
    backgroundColor: '#0A2365',

    [theme.breakpoints.down('md')]: {
      // paddingInline: 250,

      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('lg')]: {},
    [theme.breakpoints.up('xl')]: {},
    [theme.breakpoints.up('xxl')]: {},
  },
  image: {
    width: 200,
    [theme.breakpoints.down('md')]: {
      width: 100,
    },
    [theme.breakpoints.down('lg')]: {
      width: 150,
    },
  },
  logoWrapper: {
    float: 'left',
    width: 200,
    height: '100%',
    marginLeft: 50,
    marginRight: 50,

    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginLeft: 10,
      marginRight: 10,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      float: 'left',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    // background: '#001529',
    background: '#0A2365',
    color: 'white',
  },
  drawerProfile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    background: theme.palette.primary.dark,
  },
  // necessary for content to be below app bar
  toolbar: {
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    width: '100%',
    maxWidth: '1755px',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      paddingRight: '69px !important',
    },
  },
  muiIcon: {
    padding: 2,
  },
  title: { flexGrow: 1 },
  button: { textTransform: 'none' },
  tab: { textTransform: 'none', fontSize: theme.typography.h6.fontSize },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

// const NavBarItem = {
//   '/user-score-detail/': 0,
// };

const UserCustomNavbar = (props) => {
  const { t } = useTranslation();
  let location = useLocation();
  const { window } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(true);
  const [userAvatar, setUserAvatar] = React.useState(null);
  const open = Boolean(anchorEl);
  const selected = localStorage.getItem('i18nextLng') || 'en';
  const [lang, setLang] = useState(selected);

  const languageItems = [
    { label: 'English', value: 'en', code: 'US', flag: `${IconFlagUS}` },
    { label: 'Türkçe', value: 'tr', code: 'TR', flag: `${IconFlagTR}` },
  ];

  let history = useHistory();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onLangChange = (e) => {
    setLang(e.value);
    i18next.changeLanguage(e.value);
    history.go(0);
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        {option.value === 'en' ? (
          <IconButton className={classes.muiIcon}>
            <IconFlagUS />
          </IconButton>
        ) : (
          <IconButton className={classes.muiIcon}>
            <IconFlagTR />
          </IconButton>
        )}
        <div>{option.label}</div>
      </div>
    );
  };
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          {option.value === 'en' ? (
            <IconButton className={classes.muiIcon}>
              <IconFlagUS />
            </IconButton>
          ) : (
            <IconButton className={classes.muiIcon}>
              <IconFlagTR />
            </IconButton>
          )}

          <div>{option.label}</div>
        </div>
      );
    }
  };

  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <>
          <AppBar className={classes.appBar}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Toolbar className={classes.toolbar}>
              <div className={classes.logoWrapper}>
                <img
                  id="company-logo"
                  alt="NeoAuvra logo"
                  src="/logo.png"
                  className={classes.image}
                />
              </div>
              {props.IK ? (
                <Hidden smDown>
                  <Tabs value={0}>
                    <Tab
                      className={classes.tab}
                      component={Link}
                      to={'/ViewSharedReport/' + props.id + '?guid'}
                      label={t('home_page')}
                    />
                  </Tabs>
                  <div className={classes.root} />
                </Hidden>
              ) : (
                <Hidden smDown>
                  <Tabs value={0}>
                    <Tab
                      className={classes.tab}
                      component={Link}
                      to={'/appointment/' + props.id}
                      label={t('home_page')}
                    />
                  </Tabs>
                  <div className={classes.root} />
                </Hidden>
              )}

              <div className="country">
                <Dropdown
                  appendTo="self"
                  value={lang}
                  options={languageItems}
                  onChange={onLangChange}
                  optionLabel="label"
                  placeholder={t('select_language')}
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                />
              </div>
            </Toolbar>
          </AppBar>
        </>
      </ElevationScroll>
    </>
  );
};

export default UserCustomNavbar;
