/* eslint-disable no-useless-concat */
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useParams } from 'react-router-dom';
import OverviewService from '../service/OverviewService';
import Spinner from './common/Spinner';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Ripple } from 'primereact/ripple';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Tooltip } from 'primereact/tooltip';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import './appointment.css';
import AuthenticationService from '../service/AuthenticationService';
import UserService from '../service/UserService';
import moment from 'moment';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const AppointmentResults = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const userID = location.state;
  const params = useParams();
  const appointmentId = params.id;
  const toast = useRef(null);
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [date, setDate] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [first1, setFirst1] = useState(0);
  const [rows1, setRows1] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const lang = localStorage.getItem('i18nextLng');
  const [pageInputTooltip, setPageInputTooltip] = useState(
    t('press_enter_message')
  );
  const history = useHistory();

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    moduleName: { value: '', matchMode: 'contains' },
    domainName: { value: '', matchMode: 'contains' },
    subdomainName: { value: '', matchMode: 'contains' },
    metricName: { value: '', matchMode: 'contains' },
    resultUnit: { value: '', matchMode: 'contains' },
    referenceValue: { value: '', matchMode: 'contains' },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  useEffect(() => {
    setLoading(true);
    fetchModules();
  }, [appointmentId, lang]);

  useEffect(() => {
    OverviewService.getAppointmentById(appointmentId).then((res) => {
      let date = res.data.date;
      date = moment(date).format('DD.MM.YYYY');
      setDate(date);
    });
  }, [appointmentId]);

  const fetchModules = () => {
    OverviewService.getDomainModulScore(appointmentId).then((res) => {
      if (res != null && res.data != null) {
        let result = res.data.modules;
        UserService.getUserById(userID).then(({ data }) => setUser(data));
        let total = [];
        for (let i = 0; i < result.length; i++) {
          let domains = result[i].domains;
          for (let j = 0; j < domains.length; j++) {
            let subdomains = domains[j].subdomains;
            for (let k = 0; k < subdomains.length; k++) {
              let metrics = subdomains[k].metrics;
              for (let l = 0; l < metrics.length; l++) {
                total.push({
                  moduleName: result[i].moduleName,
                  moduleId: result[i].moduleId,
                  moduleScore: result[i].moduleScore,
                  forcedTreeColor: result[i].forcedTreeColor,
                  domainId: domains[j].domainId,
                  domainName: domains[j].domainName,
                  domainScore: domains[j].domainScore,
                  subdomainId: subdomains[k].subdomainId,
                  subdomainName: subdomains[k].subdomainName,
                  subdomainScore: subdomains[k].subdomainScore,
                  metricId: metrics[l].metricId,
                  metricResult: `${Number(metrics[l]?.metricResult).toFixed(
                    2
                  )}`,
                  metricScore: metrics[l]?.metricScore,
                  metricName: metrics[l]?.userFriendlyName,
                  resultUnit: metrics[l]?.metricUnit ?? '',
                  referenceValue: `${metrics[l]?.referanceMin ?? ''} - ${
                    metrics[l].referanceMax ?? ''
                  }`,
                });
              }
            }
          }
        }
        setData(total);
        setLoading(false);
      }
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters };
    _filters1['global'].value = value;

    setFilters(_filters1);
    setGlobalFilterValue(value);
  };
  const cols = [
    { field: 'moduleName', header: 'Module' },
    { field: 'domainName', header: 'Domain' },
    { field: 'subdomainName', header: 'Subdomain' },
    { field: 'metricName', header: 'Metric Name' },
    { field: 'metricResult', header: 'Result' },
    { field: 'resultUnit', header: 'Result Unit' },
    { field: 'referenceValue', header: 'Reference Value' },
  ];
  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  function convertUTF8(text) {
    var trMap = {
      ç: 'c',
      ğ: 'g',
      ş: 's',
      ü: 'u',
      ı: 'i',
      ö: 'o',
      Ç: 'C',
      Ğ: 'G',
      Ş: 'S',
      İ: 'I',
      Ö: 'O',
    };
    for (var key in trMap) {
      text = text.replace(new RegExp('[' + key + ']', 'g'), trMap[key]);
    }
    return text
      .replace(/[^-a-zA-Z0-9\s]+/gi, '')
      .replace(/\s/gi, '-')
      .replace(/[-]+/gi, '-');
  }
  const generatePDF = () => {
    // initialize jsPDF
    const doc = new jsPDF();

    // define the columns we want and their titles

    const tableColumn = [
      'Module',
      'Domain',
      'Subdomain',
      'Metric',
      'Result',
      'Result Unit',
      'Reference Value',
    ];
    // define an empty array of rows
    const tableRows = [];

    // for each ticket pass all its data into an array
    data.forEach((ticket) => {
      const ticketData = [
        ticket.moduleName,
        ticket.domainName,
        ticket.subdomainName,
        ticket.metricName,
        ticket.metricResult,
        ticket.resultUnit,
        ticket.referenceValue,
        // called date-fns to format the date on the ticket
      ];

      // push each tickcet's info into a row
      tableRows.push(ticketData);
    });
    doc.setFontSize(12);
    doc.text(
      5,
      10,
      `Name Surname : ${convertUTF8(user.name)} ${convertUTF8(user.surname)}`
    );
    doc.text(5, 20, `Age                     : ${user.age}`);
    doc.text(5, 30, `Gender         : ${userGender(user.gender)}`);
    doc.text(140, 10, `Appointment ID  : ${appointmentId}`);
    doc.text(140, 20, `Appointment Date: ${date}`);
    doc.setFontSize(16);
    doc.setTextColor(150);
    doc.text(75, 40, 'METRIC RESULT');
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, {
      startY: 60,
      styles: {
        fontSize: 8,
        width: 1200,
        margin: 0,
        padding: 0,
        overflow: 'linebreak',
      },
      columnStyles: {
        columnWidth: 100,
        fontStyle: 'bold',
      },
    });
    // const date = Date().split(' ')
    // we use a date string to generate our filename.
    const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
    // ticket title. and margin-top + margin-left

    // we define the name of our PDF file.
    doc.save(`report_${dateStr}.pdf`);
  };

  const exportPdf = () => {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, data, {
          styles: {
            fontSize: 8,
            width: 1200,
            margin: 0,
            padding: 0,
            overflow: 'linebreak',
          },
          columnStyles: {
            columnWidth: 100,
            fontStyle: 'bold',
          },
        });
        doc.save('products.pdf');
      });
    });
  };

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      saveAsExcelFile(excelBuffer, 'files');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const headerTemplate = (data) => {
    return (
      <React.Fragment>
        <span style={{ fontWeight: 'bold' }}>{data.moduleName}</span>
      </React.Fragment>
    );
  };

  const showText = (text) => {
    text = text?.length > 22 ? text?.slice(0, 25) + '...' : text;
    return text;
  };

  const domainBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData.domainName}</span>
      </React.Fragment>
    );
  };

  const metricBodyTemplate = (rowData) => {
    return (
      <>
        <Tooltip
          target='.metricName'
          mouseTrack
          mouseTrackLeft={10}
          showDelay={100}
        />
        <span
          data-pr-tooltip={rowData?.metricName}
          className='metricName'
        >
          {showText(rowData?.metricName)}
        </span>
      </>
    );
  };

  const onPageInputKeyDown = (event, options) => {
    if (event.key === 'Enter') {
      const page = parseInt(currentPage);
      if (page < 1 || page > options.totalPages) {
        setPageInputTooltip(
          `${t('value_must_between')} ${options.totalPages}.`
        );
      } else {
        const first = currentPage ? options.rows * (page - 1) : 0;

        setFirst1(first);
        setPageInputTooltip(`${t('press_enter_message')}`);
      }
    }
  };

  const onPageInputChange = (event) => {
    setCurrentPage(event.target.value);
  };

  const renderHeader = () => {
    return (
      <div className='flex justify-content-end'>
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder={t('Keyword Search')}
          />
        </span>
      </div>
    );
  };

  const template1 = {
    layout:
      'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
    PrevPageLink: (options) => {
      return (
        <button
          type='button'
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className='p-3'>{t('messages.previous')}</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type='button'
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className='p-3'>{t('messages.next')}</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span
            className={className}
            style={{ userSelect: 'none' }}
          >
            ...
          </span>
        );
      }

      return (
        <button
          type='button'
          className={options.className}
          onClick={options.onClick}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 'All', value: options.totalRecords },
      ];

      return (
        <Dropdown
          value={options.value}
          options={dropdownOptions}
          onChange={options.onChange}
        />
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          className='mx-3'
          style={{ color: 'var(--text-color)', userSelect: 'none' }}
        >
          {t('Go to')}
          <InputText
            size='2'
            className='ml-1'
            value={currentPage}
            tooltip={pageInputTooltip}
            onKeyDown={(e) => onPageInputKeyDown(e, options)}
            onChange={onPageInputChange}
          />
        </span>
      );
    },
  };
  const onCustomPage1 = (event) => {
    setFirst1(event.first);
    setRows1(event.rows);
    setCurrentPage(event.page + 1);
  };

  const header = renderHeader();
  const Header = () => {
    return (
      <div className='flex align-items-center justify-content-end export-buttons'>
        <Button
          type='button'
          icon='pi pi-file-pdf'
          onClick={generatePDF}
          className='p-button-danger mr-2'
          data-pr-tooltip='PDF'
          tooltip='Save'
          tooltipOptions={{
            position: 'bottom',
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
        />
        <Button
          type='button'
          icon='pi pi-file-excel'
          onClick={exportExcel}
          className='p-button-success mr-2'
          data-pr-tooltip='XLS'
          tooltip='Save'
          tooltipOptions={{
            position: 'bottom',
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
        />
      </div>
    );
  };
  const userGender = (gender) => {
    if (gender === 'Erkek') {
      return `, ${t('Male')}`;
    } else if (gender === 'Kadın') {
      return `, ${t('Female')}`;
    } else {
      return '';
    }
  };
  return (
    <>
      <Spinner loading={loading} />
      <div className='datatable-rowgroup-demo'>
        <div className='card'>
          <div
            class='grid p-4'
            style={{ alignItems: 'self-end' }}
          >
            <div class='col-5'>
              <div className='grid'>
                <div className='col-4'>
                  <span style={{ fontWeight: 'bold' }}>
                    {user.name} {user.surname}
                  </span>
                </div>
                <div className='col-5'>
                  {t('Appointment_ID')} :{' '}
                  <span style={{ fontWeight: 'bold' }}>{appointmentId}</span>
                </div>
                <div className='col-offset-2'></div>
                <div className='col-4'>
                  {(user.age ? user.age : '') + userGender(user.gender)}
                </div>
                <div className='col-6'>
                  {t('Apponintment_Date')}:{' '}
                  <span style={{ fontWeight: 'bold' }}> {date}</span>
                </div>
                <div className='col-offset-1'></div>
              </div>
            </div>
            <div class='col-4'>
              <h1
                style={{
                  fontWeight: 'bolder',
                  fontSize: '30px',
                  textAlign: 'left',
                  color: '#000',
                }}
              >
                {t('METRIC_RESULTS')}
              </h1>
            </div>
            <div
              class='col-3'
              style={{ textAlign: 'right' }}
            >
              <Header />
            </div>
            <div
              className='card-toolbar'
              style={{ position: 'absolute', right: 0, top: 10 }}
            >
              <ul className='ust_menu'>
                <li
                  tooltip={t('turn_back')}
                  onClick={() => history.go(-1)}
                >
                  <i className='pi pi-arrow-left' />
                </li>
              </ul>
            </div>
          </div>
          <DataTable
            showGridlines
            header={header}
            value={data}
            paginator
            paginatorTemplate={template1}
            rowGroupMode='subheader'
            groupRowsBy='moduleName'
            sortMode='multiple'
            sortOrder={1}
            scrollable
            scrollHeight='500px'
            rowGroupHeaderTemplate={headerTemplate}
            responsiveLayout='scroll'
            stripedRows
            onPage={onCustomPage1}
            first={first1}
            rows={rows1}
            filters={filters}
            filterDisplay='row'
            globalFilterFields={[
              'moduleName',
              'domainName',
              'subdomainName',
              'metricName',
              'metricResult',
              'resultUnit',
              'referenceValue',
            ]}
            emptyMessage={t('no_records_found')}
          >
            <Column
              field='moduleName'
              header={t('appointment_results_key.Module')}
              style={{ minWidth: '200px' }}
              sortable
              filter
              filterPlaceholder=''
              filterField='moduleName'
            ></Column>
            <Column
              field='domainName'
              header={t('appointment_results_key.Domain')}
              body={domainBodyTemplate}
              style={{ minWidth: '200px' }}
              sortable
              filter
              filterPlaceholder=''
            ></Column>
            <Column
              field='subdomainName'
              header={t('appointment_results_key.Subdomain')}
              style={{ minWidth: '200px' }}
              sortable
              filter
              filterPlaceholder=''
            ></Column>
            <Column
              field='metricName'
              header={t('appointment_results_key.Metric_Name')}
              body={metricBodyTemplate}
              style={{ minWidth: '200px' }}
              sortable
              filter
              filterPlaceholder=''
            ></Column>
            <Column
              field='metricResult'
              header={t('appointment_results_key.Result')}
              style={{ minWidth: '200px' }}
              sortable
              filter
              filterPlaceholder=''
              className='result'
            ></Column>
            <Column
              field='resultUnit'
              header={t('appointment_results_key.Result_Unit')}
              style={{ minWidth: '200px' }}
              sortable
              filter
              filterPlaceholder=''
              className='resultUnit'
            ></Column>
            <Column
              field='referenceValue'
              header={t('appointment_results_key.Reference_Value')}
              style={{ minWidth: '200px' }}
              sortable
              filter
              filterPlaceholder=''
              className='referenceValue'
            ></Column>
          </DataTable>
        </div>
      </div>
    </>
  );
};
