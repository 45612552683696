import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1000,
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(0,0,0,0.75)',
  },
}));

const Spinner = ({ loading, style, text, t }) => {
  const classes = useStyles();
  return (
    <Backdrop style={{ style }} className={classes.backdrop} open={loading}>
      <CircularProgress color='#fff' />
      {text && (
        <span style={{ marginLeft: 18, color: '#fff' }}>
          {t(`enqueueSnackbar_message.${text}`)}
        </span>
      )}
    </Backdrop>
  );
};

export default withTranslation()(Spinner);
