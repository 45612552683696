import axios from 'axios';

class SarfCompetencyDomainService {
	getAllDomains() {
		return axios.get('lookup/getActiveDomains');
	}

	getSubDomainsByDomainId(id) {
		return axios.get(`/lookup/getSubdomainsByDomainId/${id}`);
	}

	getList() {
		return axios.get('/lookup/getAllSarfCompetencyDomains');
	}

	getById(id) {
		return axios.get('/lookup/getSarfCompetencyDomainById/' + id);
	}

	save(row) {
		return axios.post('/lookup/saveSarfCompetencyDomain', row);
	}

	update(row) {
		return axios.post('/lookup/updateSarfCompetencyDomain', row);
	}
	delete(id) {
		return axios.delete(`/lookup/deleteSarfCompetencyDomainById/${id}`);
	}
}

export default new SarfCompetencyDomainService();
