import React, { useEffect, useCallback } from 'react';
import { StylesProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import trLocale from 'date-fns/locale/tr';
import { ThemeProvider } from 'styled-components';
import { baseTheme } from './baseTheme';
import { MuiThemeProvider, Slide } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import Routing from './routing';
import axios from 'axios';
import AuthenticationService from './service/AuthenticationService';

const App = () => {
  useEffect(() => {
    window.process = { ...window.process };
  }, []);

  axios.interceptors.response.use(
    undefined,
    function axiosRetryInterceptor(err) {
      if (err.response.status === 401) {
        AuthenticationService.logout();
        window.location = '/giris';
      }
      return Promise.reject(err);
    }
  );
  return (
    <StylesProvider injectFirst>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={trLocale}>
        <MuiThemeProvider theme={baseTheme}>
          <ThemeProvider theme={baseTheme}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              autoHideDuration={1500}
              TransitionComponent={Slide}
            >
              <Routing />
            </SnackbarProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  );
};

export default App;
