import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthenticationService from './AuthenticationService';

class AuthenticatedRoute extends Component {
  componentDidMount() {
    AuthenticationService.setupAxiosInterceptors();
  }

  render() {
    if (AuthenticationService.isUserLoggedIn()) {
      return <Route {...this.props} />;
    } else {
      return <Redirect to="/giris" />;
    }
  }
}

export default AuthenticatedRoute;
