import React, { useEffect, useState, useRef } from 'react';
import {
	Calendar,
	momentLocalizer,
	Views,
	dateFnsLocalizer,
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { parse, startOfWeek, getDay, format, getDate } from 'date-fns';
import './calendar.css';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../components/common/Spinner';
import axios from 'axios';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import StationService from '../service/StationService';
import LocationService from '../service/LocationService';
// eslint-disable-next-line no-unused-vars
import { Button, Dialog, MenuItem, Paper } from '@material-ui/core';
import {
	bindHover,
	bindMenu,
	usePopupState,
} from 'material-ui-popup-state/hooks';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import Swal from 'sweetalert2';
import { withTranslation } from 'react-i18next';
// import enUS from 'date-fns/locale/en-US';
// import tr from 'date-fns/locale/tr';
import 'moment/locale/tr';
import 'moment/locale/en-gb';
import AuthenticationService from '../service/AuthenticationService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSnackbar } from 'notistack';
import { Toast } from 'primereact/toast';
import AppointmentCalendarDetail from '../components/AppointmentCalenderDetail';

// const locales = {
//   tr: tr,
// };
// console.log(locales);
// const localizer = dateFnsLocalizer({
//   format,
//   parse,
//   startOfWeek,
//   getDay,
//   locales,
// });

// const locales = {
//   tr: require('date-fns/locale/tr'),
// };

// const localizer = dateFnsLocalizer({
//   format,
//   parse,
//   startOfWeek,
//   getDay,
//   locales,
// });
// moment.tz.setDefault('en-gb');

const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme) => ({
	root: {},
	paper: {
		padding: theme.spacing(3),
		width: '100%',
	},
	calendar: {
		overflowX: 'scroll',
		width: '100%',
		fontSize: '0.77em',
	},
}));

const AppointmentCalendar = ({ userId, t }) => {
	const [events1, setEvents1] = useState([]);
	const [events2, setEvents2] = useState([]);
	const [events3, setEvents3] = useState([]);
	const [loading, setLoading] = useState(false);
	const [stations, setStations] = useState([]);
	const [station1, setStation1] = useState();
	const [station2, setStation2] = useState();
	const [station3, setStation3] = useState();
	const [currentUserId, setCurrentUserId] = useState(userId);
	const [countries, setCountries] = useState([]);
	const [dateRange1, setDateRange1] = useState([null, null]);
	const [dateRange2, setDateRange2] = useState([null, null]);
	const [dateRange3, setDateRange3] = useState([null, null]);
	const [startDate1, endDate1] = dateRange1;
	const [startDate2, endDate2] = dateRange2;
	const [startDate3, endDate3] = dateRange3;

	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const toast = useRef(null);
	const user = AuthenticationService.getLoggedInUser();
	useEffect(() => {
		setDateRange1([new Date(), new Date()]);
		setDateRange2([new Date(), new Date()]);
		setDateRange3([new Date(), new Date()]);

		if (!currentUserId) {
			let user = AuthenticationService.getLoggedInUser();
			setCurrentUserId(user.userId);
		}
		if (AuthenticationService.isOperator()) {
			getStationListForOperator();
		} else if (AuthenticationService.isLocationAdmin()) {
			getStationListForLocationAdmin();
		} else {
			getStationList();
		}
		LocationService.getCountries().then((res) => {
			setCountries(res.data);
		});
	}, [currentUserId]);

	const getStationList = () => {
		StationService.getStationList().then((resp) => {
			var data = resp.data;
			if (data != null) {
				for (const i of data) {
					i.fullName =
						i.location.country.name +
						'/' +
						i.location.city.name +
						'/' +
						i.location.name +
						'/' +
						i.name;
				}
			}
			setStations(data);
		});
	};
	const getStationListForOperator = () => {
		StationService.getStationListForOperator(user.userId).then((resp) => {
			var data = resp.data;
			if (data != null) {
				for (const i of data) {
					i.fullName =
						i.location.country.name +
						'/' +
						i.location.city.name +
						'/' +
						i.location.name +
						'/' +
						i.name;
				}
			}
			setStations(data);
		});
	};
	const getStationListForLocationAdmin = () => {
		StationService.getStationByLocationAdminList(user.userId).then((resp) => {
			var data = resp.data;
			if (data != null) {
				for (const i of data) {
					i.fullName =
						i.location.country.name +
						'/' +
						i.location.city.name +
						'/' +
						i.location.name +
						'/' +
						i.name;
				}
			}
			setStations(data);
		});
	};

	const appointmentByStationAndDate = (
		startingDate,
		endingDate,
		selectedStation
	) => {
		setLoading(true);
		if (selectedStation === undefined || selectedStation === null) {
			enqueueSnackbar(`${t('enqueueSnackbar_message.select_hub')}`, {
				variant: 'warning',
				autoHideDuration: 3000,
			});
			setLoading(false);
			return;
		} else if (startingDate === null || endingDate === null) {
			enqueueSnackbar(`${t('enqueueSnackbar_message.select_date_range')}`, {
				variant: 'warning',
				autoHideDuration: 3000,
			});
			setLoading(false);
			return;
		}
		if (user.roles.includes('ADMIN') || user.roles.includes('LOCATION_ADMIN')) {
			const appointmentByStationAndDateDTO = {
				endDate: moment(endingDate).format('YYYY-MM-DD'),
				startDate: moment(startingDate).format('YYYY-MM-DD'),
				stationId: selectedStation.id,
			};
			axios
				.get('/appointments/getApprovedAppointmentByStationIdAndDate', {
					params: { ...appointmentByStationAndDateDTO },
				})
				.then((res) => {
					const appointments = res.data;
					if (res.data.length === 0) {
						enqueueSnackbar(
							`${t('enqueueSnackbar_message.between_date_range')}`,
							{
								variant: 'warning',
								autoHideDuration: 3000,
							}
						);
						setLoading(false);
						return;
					}
					let events = [];
					appointments.forEach((appointment) => {
						const start = moment(appointment.date)
							.add(moment(appointment.start, 'HH:mm').hours(), 'hours')
							.add(moment(appointment.start, 'HH:mm').minutes(), 'minutes')
							.toDate();

						const end = moment(appointment.date)
							.add(moment(appointment.end, 'HH:mm').hours(), 'hours')
							.add(moment(appointment.end, 'HH:mm').minutes(), 'minutes')
							.toDate();

						events.push({
							start: start,
							end: end,
							data: appointment,
						});
					});
					if (selectedStation === station1) {
						setEvents1(events);
					}
					if (selectedStation === station2) {
						setEvents2(events);
					}
					if (selectedStation === station3) {
						setEvents3(events);
					}

					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					toast.current.show({
						severity: 'error',
						summary: 'Error',
						detail: err.message,
					});
				});
		} else if (user.roles.includes('OPERATOR')) {
			const appointmentByStationAndDateForOperatorDTO = {
				endDate: moment(endingDate).format('YYYY-MM-DD'),
				startDate: moment(startingDate).format('YYYY-MM-DD'),
				stationId: selectedStation.id,
				operatorId: user.userId,
			};
			axios
				.get(
					'/appointments/getApprovedAppointmentByStationIdAndDateForOperator',
					{
						params: { ...appointmentByStationAndDateForOperatorDTO },
					}
				)
				.then((res) => {
					const appointments = res.data;
					if (res.data.length === 0) {
						enqueueSnackbar(
							`${t('enqueueSnackbar_message.between_date_range')}`,
							{
								variant: 'warning',
								autoHideDuration: 3000,
							}
						);
					}
					let events = [];
					appointments.forEach((appointment) => {
						const start = moment(appointment.date)
							.add(moment(appointment.start, 'HH:mm').hours(), 'hours')
							.add(moment(appointment.start, 'HH:mm').minutes(), 'minutes')
							.toDate();

						const end = moment(appointment.date)
							.add(moment(appointment.end, 'HH:mm').hours(), 'hours')
							.add(moment(appointment.end, 'HH:mm').minutes(), 'minutes')
							.toDate();

						events.push({
							start: start,
							end: end,
							data: appointment,
						});
					});
					if (selectedStation === station1) {
						setEvents1(events);
					}
					if (selectedStation === station2) {
						setEvents2(events);
					}
					if (selectedStation === station3) {
						setEvents3(events);
					}

					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					toast.current.show({
						severity: 'error',
						summary: 'Error',
						detail: err.message,
					});
				});
		}
		return;
	};

	const handleAppointmentDeletion = (appointmentId) => {
		axios
			.delete('/appointments/cancel', {
				params: { appointmentId },
			})
			.then(() => {
				Swal.fire({
					title: 'İptal edildi',
					icon: 'success',
					timer: 2500,
					timerProgressBar: true,
					showConfirmButton: false,
					footer: '',
				});
				appointmentByStationAndDate();
				setLoading(true);
			});
	};
	const messages = {
		date: `${t('messages.date')}`,
		time: `${t('messages.time')}`,
		event: `${t('messages.event')}`,
		allDay: `${t('messages.allDay')}`,
		week: `${t('messages.week')}`,
		work_week: `${t('messages.work_week')}`,
		day: `${t('messages.day')}`,
		month: `${t('messages.month')}`,
		previous: `${t('messages.previous')}`,
		next: `${t('messages.next')}`,
		yesterday: `${t('messages.yesterday')}`,
		tomorrow: `${t('messages.tomorrow')}`,
		today: `${t('messages.today')}`,
		agenda: `${t('messages.agenda')}`,
	};

	const Event = ({ event }) => {
		const { data } = event;

		const popupState = usePopupState({
			variant: 'popover',
			popupId: 'demoMenu',
		});

		return (
			<>
				<span {...bindHover(popupState)}>
					{AuthenticationService.isAdmin() ? (
						<strong>
							{data.start} {data.end}
						</strong>
					) : (
						<strong>
							{data.start} {data.end}
						</strong>
					)}

					<HoverMenu
						{...bindMenu(popupState)}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
						transformOrigin={{ vertical: 'top', horizontal: 'left' }}
					>
						<MenuItem
						// 	onClick={() => {
						// 		popupState.close();
						// 		Swal.fire({
						// 			title: `${t(
						// 				'appointment_alert_messages.sure_cancel_appointment'
						// 			)}`,
						// 			icon: 'warning',
						// 			showCancelButton: true,
						// 			confirmButtonColor: '#EC0044',
						// 			cancelButtonColor: '#929ea8',
						// 			confirmButtonText: `${t(
						// 				'appointment_alert_messages.cancel_appointment'
						// 			)}`,
						// 			cancelButtonText: `${t(
						// 				'appointment_alert_messages.give_up'
						// 			)}`,
						// 		}).then((result) => {
						// 			if (result.isConfirmed) {
						// 				handleAppointmentDeletion(data.appointmentId);
						// 			}
						// 		});
						// 	}}
						>
							{data.name} {data.surname} {data.userId} -{' '}
							{data.assessmentPackageName}
						</MenuItem>
					</HoverMenu>
				</span>
			</>
		);
	};

	const components = {
		event: Event,
	};

	// useEffect(() => {
	//   fetchAppointments();
	// }, []);

	// const fetchAppointments = () => {
	//   axios.get('/appointments/approvedAppointments').then((res) => {
	//     const appointments = res.data;
	//     let events = [];
	//     appointments.forEach((appointment) => {
	//       const start = moment(appointment.date)
	//         .add(moment(appointment.start, 'HH:mm').hours(), 'hours')
	//         .add(moment(appointment.start, 'HH:mm').minutes(), 'minutes')
	//         .toDate();

	//       const end = moment(appointment.date)
	//         .add(moment(appointment.end, 'HH:mm').hours(), 'hours')
	//         .add(moment(appointment.end, 'HH:mm').minutes(), 'minutes')
	//         .toDate();

	//       events.push({
	//         start: start,
	//         end: end,
	//         data: appointment,
	//       });
	//     });
	//     setEvents(events);
	//   });
	// };

	const localLang = localStorage.getItem('i18nextLng') ?? 'tr';
	return (
		<>
			<Toast ref={toast}></Toast>
			<Spinner loading={loading} />
			<div style={{ width: '100%', display: 'flex' }}>
				<AppointmentCalendarDetail
					station={station1}
					stations={stations}
					startDate={startDate1}
					endDate={endDate1}
					setDateRange={setDateRange1}
					stationId={station1}
					localizer={localizer}
					messages={messages}
					events={events1}
					components={components}
					setStation={setStation1}
					t={t}
					appointmentByStationAndDate={appointmentByStationAndDate}
				></AppointmentCalendarDetail>
				<AppointmentCalendarDetail
					station={station2}
					stations={stations}
					startDate={startDate2}
					endDate={endDate2}
					setDateRange={setDateRange2}
					stationId={station2}
					localizer={localizer}
					messages={messages}
					events={events2}
					components={components}
					setStation={setStation2}
					t={t}
					appointmentByStationAndDate={appointmentByStationAndDate}
				></AppointmentCalendarDetail>
				<AppointmentCalendarDetail
					station={station3}
					stations={stations}
					startDate={startDate3}
					endDate={endDate3}
					setDateRange={setDateRange3}
					stationId={station3}
					localizer={localizer}
					messages={messages}
					events={events3}
					components={components}
					setStation={setStation3}
					t={t}
					appointmentByStationAndDate={appointmentByStationAndDate}
				></AppointmentCalendarDetail>
			</div>
		</>
	);
};

export default withTranslation()(AppointmentCalendar);
