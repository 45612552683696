import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, CssBaseline, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CardLayout } from '../components/CardLayout';
import { MainButton } from '../components/common/MainButton';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useHistory } from 'react-router-dom';
import FormInput from '../components/common/FormInput';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { withTranslation, useTranslation } from 'react-i18next';
import FormInputGrid from '../components/common/FormInputGrid';

const useStyles = makeStyles((theme) => ({
	card: {
		padding: 20,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	register: {
		'&:hover': {
			textDecoration: 'underline',
		},
		textTransform: 'none',
	},
}));

const ForgotPassword = ({ t }) => {
	const Schema = Yup.object().shape({
		email: Yup.string()
			.email(`${t('schema_shape.valid_email')}`)
			.required(`${t('schema_shape.not_empty')}`),
	});

	const [displayResponsive, setDisplayResponsive] = useState(false);
	const [dialogMessage, setDialogMessage] = useState('');
	const [open, setOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { i18n } = useTranslation();
	const locale = i18n.language;
	const classes = useStyles();
	const { handleSubmit, control } = useForm({
		resolver: yupResolver(Schema),
	});
	let history = useHistory();

	const onSubmit = (email) => {
		axios
			.post('/users/resetPassword', email)
			.then(() => {
				setDialogMessage(t('refresh_pass_message'));
				setDisplayResponsive(true);
			})
			.catch((err) => {
				if (err?.response?.data?.WEB_UI_BLOCK) {
					setDialogMessage(
						t('web_ui_blocked_user', {
							customerName: `${err?.response?.data?.CUSTOMER_NAME?.toLocaleUpperCase(
								locale
							)}`,
						})
					);
					setDisplayResponsive(true);
				} else {
					enqueueSnackbar(
						`${t('enqueueSnackbar_message.not_invalid_email_address')}`,
						{ variant: 'warning' }
					);
				}
			});
	};
	const styles = {
		header: {
			textAlign: 'center',
		},
	};
	const ForgotDialog = () => {
		return (
			<Dialog
				header={t('information')}
				headerStyle={{ textAlign: 'center' }}
				headerClassName={styles.header}
				footer={renderFooter}
				visible={displayResponsive}
				breakpoints={{ '960px': '75vw', '640px': '100vw' }}
				style={{ width: '50vw' }}
				onHide={() => {
					setDisplayResponsive(false);
					setDialogMessage('');
				}}
			>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					{' '}
					<p style={{ textAlign: 'justify' }}>{dialogMessage}</p>
				</div>
			</Dialog>
		);
	};

	const historyPush = () => {
		setDisplayResponsive(false);
		history.push({
			pathname: '/giris',
			// state: { verificationPending: true },
		});
	};

	const handleChangeEmail = (value) => {
		return value.toLowerCase();
	};

	const renderFooter = () => {
		return (
			<div style={{ textAlign: 'center' }}>
				<MainButton
					type='submit'
					variant='contained'
					color='primary'
					className={classes.submit}
					onClick={() => historyPush()}
					autoFocus
				>
					{t('ok')}
				</MainButton>
			</div>
		);
	};

	return (
		<CardLayout title={t('forgot_password')}>
			<CssBaseline>
				<ForgotDialog />
				<div className={classes.paper}>
					<Grid container item={true} xs={12} sm={10} md={8} lg={6}>
						<form
							className={classes.form}
							noValidate
							onSubmit={handleSubmit(onSubmit)}
						>
							<Grid>
								<Controller
									name='email'
									control={control}
									render={(props) => (
										<FormInputGrid
											{...props}
											margin='dense'
											required
											label={t('email')}
											handleChangeEmail={handleChangeEmail}
										/>
									)}
								/>
							</Grid>

							<MainButton
								type='submit'
								fullWidth
								variant='contained'
								color='primary'
								className={classes.submit}
							>
								{t('send')}
							</MainButton>
							<Grid container justifyContent='flex-end'>
								<Grid item>
									<Button
										component={Link}
										to='/giris'
										color='secondary'
										className={classes.register}
									>
										{t('back_to_home')}
									</Button>
								</Grid>
							</Grid>
						</form>
					</Grid>
				</div>
			</CssBaseline>
		</CardLayout>
	);
};

export default withTranslation()(ForgotPassword);
