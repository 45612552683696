import React, { useEffect, useState, useRef } from 'react';
import { Knob } from 'primereact/knob';
import { Button } from 'primereact/button';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Card } from 'primereact/card';
import { Message } from 'primereact/message';
import { MainButton } from './common/MainButton';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import 'yup-phone';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import FormInput from './common/FormInput';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { PinInput } from 'react-input-pin-code';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { StatefulPinInput } from 'react-input-pin-code';
import Spinner from './common/Spinner';
const useStyles = makeStyles((theme) => ({
  card: {
    padding: 20,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  commChannels: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  register: {
    '&:hover': {
      textDecoration: 'underline',
    },
    textTransform: 'none',
  },
}));

const minuteSeconds = 120;

const timerProps = {
  size: 130,
  strokeWidth: 8,
};
const getTimeSeconds = (time) => (minuteSeconds - time) | 0;

const header = (
  <div className='text-center d-flex justify-content-center flex-wrap m-5'>
    <img
      id='logo'
      alt='NeoAuvra logo'
      src='/logo-black.png'
      style={{ width: '200px' }}
    />
  </div>
);
export const SmsVeritification = () => {
  const selected = localStorage.getItem('i18nextLng') || 'en';
  const { t } = useTranslation();
  const schema = Yup.object({
    pin: Yup.string()
      .required(`${t('schema_shape.valid_code_empty')}`)
      .min(6, `${t('schema_shape.valid_code_max')}`)
      .max(6, `${t('schema_shape.valid_code_max')}`),
  }).required();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [disable, setDisable] = useState(true);
  const [disablePin, setDisablePin] = useState(true);
  const [duration, setDuration] = useState(120);
  const [key, setKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const ref = useRef([]);
  const location = useLocation();
  const state = location.state;
  const history = useHistory();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [values, setValues] = useState(['', '', '', '', '', '']);
  const onSubmit = () => {
    setLoading(true);
    const smsCode = values.join('');
    const json = {
      smsCode: smsCode,
      token: state.token,
      password: state.password,
    };
    axios
      .post('/users/preRegisterBySms', JSON.stringify(json), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        enqueueSnackbar(
          `${t('enqueueSnackbar_message.verify_success_redirect_login')}`,
          {
            variant: 'success',
            autoHideDuration: 2500,
          }
        );
        setLoading(false);
        setTimeout(() => {
          history.push('/');
        }, 1500);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response.data.errorCode === 'EMAIL_ALREADY_EXISTS') {
          redirectLogin();
        } else {
          enqueueSnackbar(`${t('enqueueSnackbar_message.Invalid_Sms_Code')}`, {
            variant: 'error',
          });
        }
      });
  };

  const sendSms = () => {
    try {
      if (state) {
        axios
          .get('/users/sendSmsActivationCode', {
            params: { token: state.token },
          })
          .then()
          .catch((err) => {
            enqueueSnackbar(
              `${t('Could not send SMS. Please try again later')}`,
              {
                variant: 'error',
              }
            );
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    sendSms();
  }, []);

  const sendAgainCode = () => {
    enqueueSnackbar(
      `${t('enqueueSnackbar_message.sms_confirm_code_sent_again')}`,
      {
        variant: 'success',
        autoHideDuration: 2000,
      }
    );
    setKey((prevKey) => prevKey + 1);
    sendSms();
    setDisable(true);
  };

  const redirectLogin = () => {
    confirmDialog({
      message: `${t('enqueueSnackbar_message.user_already_exists')}`,
      header: `${t('error_detail')}`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: `${t('ok')}`,
      rejectClassName: 'd-none',
      accept: () => accept(),
    });
  };

  const accept = () => {
    history.push('/');
  };

  const renderTime = (dimension, time) => {
    if (time === 0) {
      setDisable(false);
    }
    return (
      <div className='time-wrapper'>
        <div className='time'>{time}</div>
        <div>{dimension}</div>
      </div>
    );
  };
  const inputValid = (index, value) => {
    if (index === 5 && value.toString() !== '') {
      setDisablePin(false);
    } else {
      setDisablePin(true);
    }
  };
  return (
    <>
      <ConfirmDialog />
      <Spinner loading={loading} />
      <div className='card text-center'>
        <Card
          title={t('user_create_proces')}
          subTitle=''
          style={{ width: '25em', margin: '0 auto' }}
          header={header}
        >
          <div
            className='d-flex justify-content-center flex-wrap'
            style={{ marginBottom: '10px' }}
          >
            <Message
              severity='info'
              text={t('please_confirm_phone_code')}
              className='mb-4'
            />
            <CountdownCircleTimer
              key={key}
              {...timerProps}
              colors={['#004777', '#F7B801', '#A30000', '#A30000']}
              colorsTime={[7, 5, 2, 0]}
              duration={duration}
              isPlaying
              onComplete={() => {
                // do your stuff here
                return { shouldRepeat: false, delay: 1.5 }; // repeat animation in 1.5 seconds
              }}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime(`${t('second')}`, getTimeSeconds(elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <PinInput
              containerStyle={{ justifyContent: 'center', margin: '16px 0' }}
              values={values}
              onChange={(value, index, values) => {
                setValues(values);
                inputValid(index, value);
              }}
              placeholder=''
              mask={true}
            />

            <MainButton
              type='submit'
              variant='contained'
              color='primary'
              className={classes.submit}
              autoFocus
              style={{ display: 'block', margin: '0 auto', width: '165px' }}
              disabled={disablePin}
              onClick={onSubmit}
            >
              {t('verify')}
            </MainButton>
            <Button
              disabled={disable}
              label={t('resend_confirm_code')}
              className='p-button-raised p-button-text '
              style={{ float: 'right', margin: '30px 0 25px 0' }}
              onClick={sendAgainCode}
            />
          </form>
        </Card>
      </div>
    </>
  );
};
