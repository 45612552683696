import React from 'react';
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';

import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import AuthenticatedRoute from './service/AuthenticatedRoute';
import { DemographicsForm } from './pages/DemographicsForm';
import NotFound404 from './pages/NotFound404';
import { UserDemographics } from './pages/UserDemographics';
import UserAppointments from './pages/UserAppointments';
import DoctorRoute from './service/DoctorRoute';
import WelcomeDoctor from './pages/WelcomeDoctor';
import ExpertReview from './pages/ExpertReview';
import ExpertPoints from './pages/ExpertPoints';
import ExpertPhysio from './pages/ExpertPhysio';
import { DoctorDemographics } from './pages/DoctorDemographics';
import DoctorOrAdminRoute from './service/DoctorOrAdminRoute';
import AdminRoute from './service/AdminRoute';
import UserRoute from './service/UserRoute';
import HomeUser from './pages/HomeUser';
import Overview from './pages/expert_reviews/Overview';
import PreRegister from './pages/PreRegister';
import { SmsVeritification } from './components/SmsVeritification';
import UserScoreDetail from './pages/UserScoreDetail';
import ReportShares from './pages/ReportShares';
import CustomTestReport from './pages/CustomTestReport';
import { BarTest } from './pages/BarTest';
const Routing = () => {
  const DemographicsForm = React.lazy(() => import('./pages/DemographicsForm'));
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/kayit' component={Register} />
        <Route path='/giris' component={Login} />
        <Route path='/parola-unuttum' component={ForgotPassword} />
        <Route path='/parola-degistir' component={ChangePassword} />
        <Route path='/onkayit/sms/:id' component={PreRegister} />
        <Route path='/bartest' component={BarTest} />
        <Route
          path={`/sms-dogrulama`}
          render={(props) => <SmsVeritification {...props} />}
        />
        <Route
          path='/custom-test-report/:category/:id'
          exact
          component={CustomTestReport}
        />
        {/*<AuthenticatedRoute path="/overview" component={Overview}/>*/}

        {/*<AuthenticatedRoute path="/overview" component={Overview}>*/}
        {/*    <Route path="/parola-unuttum" component={ForgotPassword}/>*/}
        {/*</AuthenticatedRoute>*/}
        {/*<Route path="/overview" element={<Overview/>}>*/}
        {/*    <Route path="parola-unuttum" element={<ForgotPassword/>}/>*/}
        {/*</Route>*/}
        {/*<Route path="/overview" component={Overview}>*/}
        {/*    <Route path="/parola-unuttum" component={ForgotPassword}/>*/}
        {/*</Route>*/}
        <UserRoute exact path='/' component={HomeUser} />
        <AuthenticatedRoute path='/appointment/:id' component={HomeUser} />
        <AuthenticatedRoute path='/ViewSharedReport/:id' component={HomeUser} />
        <UserRoute path='/overview/:id'>
          <Route component={Overview} />
        </UserRoute>
        <AuthenticatedRoute path='/overview'>
          <Route component={Overview} />
        </AuthenticatedRoute>

        <AdminRoute path='/overview/:id'>
          <Route index={true} component={Overview} />
        </AdminRoute>
        <DoctorRoute exact path='/' component={Home} />
        <AuthenticatedRoute exact path='/' component={Home} />
        <AuthenticatedRoute
          path='/randevu-olustur'
          component={UserAppointments}
        />
        <UserRoute exact path='/rapor-paylasimlari' component={ReportShares} />
        <AuthenticatedRoute path='/uzman-gorusu' component={ExpertReview} />
        <AuthenticatedRoute path='/uzman-puani' component={ExpertPoints} />
        <AuthenticatedRoute path='/expert-physio' component={ExpertPhysio} />
        {/*<AuthenticatedRoute*/}
        {/*    path="/demografik-form"*/}
        {/*    component={DemographicsForm}*/}
        {/*/>*/}
        <AuthenticatedRoute
          path='/user-score-detail/:id'
          component={UserScoreDetail}
        />
        <AuthenticatedRoute
          path='/demografik-form'
          render={(props) => <DemographicsForm {...props} />}
        />
        <AuthenticatedRoute
          path='/onizlem-formu'
          render={(props) => <Home {...props} />}
        />

        <DoctorRoute
          path='/doktor-demografik-bilgiler'
          render={(props) => <DoctorDemographics {...props} />}
        />
        <DoctorOrAdminRoute path='/panel'>
          <Dashboard />
        </DoctorOrAdminRoute>
        <DoctorRoute path='/doktor-karsilama'>
          <WelcomeDoctor />
        </DoctorRoute>
        <AdminRoute exact path='/panel'>
          <Dashboard />
        </AdminRoute>

        <Route component={NotFound404} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routing;
