import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5radar from '@amcharts/amcharts5/radar';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import OverviewService from '../service/OverviewService';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';

function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    return <Component {...props} params={params} />;
  }
  return ComponentWithRouter;
}
class CustomReportComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accordionData: [],
      moduleAvarageScores: [],
      headerColor: '',
    };
  }

  async componentDidMount() {
    const { id, category } = this.props.params;
    const t = this.props.t;
    const response = await OverviewService.getDomainModulScore(id);
    const avarageScores = await axios.get('/scores/getScoresAverages/2');

    if (response != null && avarageScores != null) {
      let modules = response.data.modules.find(
        (m) => m.moduleName.toLowerCase() === category.toLowerCase()
      );
      this.setState({ headerColor: modules.forcedTreeColor });
      this.setState({ accordionData: modules.domains });
      this.setState({ moduleAvarageScores: avarageScores.data });
    }
    if (this.state.accordionData.length > 0) {
      let value = this.state.headerColor;
      am5.addLicense('AM5C361499357');
      var root = am5.Root.new('chartdiv');

      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/radar-chart/
      var chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
        })
      );

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
      var cursor = chart.set(
        'cursor',
        am5radar.RadarCursor.new(root, {
          behavior: 'zoomX',
        })
      );

      cursor.lineY.set('visible', false);

      // Create axes and their renderers
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
      var xRenderer = am5radar.AxisRendererCircular.new(root, {
        minGridDistance: 30,
      });

      xRenderer.labels.template.setAll({
        radius: 40,
        fill: am5.color('#fff'),
        fontWeight: 'bold',
      });

      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0,
          categoryField: 'name',
          renderer: xRenderer,

          // tooltip: am5.Tooltip.new(root, {}),
        })
      );

      xRenderer.grid.template.setAll({
        location: 0.5,
        strokeDasharray: [2, 2],
      });

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5radar.AxisRendererRadial.new(root, {
            minGridDistance: 20,
          }),
          max: 1000,
          min: 0,
          calculateTotals: true,
        })
      );

      let yRenderer = yAxis.get('renderer');
      yRenderer.labels.template.setAll({
        fill: am5.color('#fff'),
      });

      // Create series
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
      var series = chart.series.push(
        am5radar.RadarLineSeries.new(root, {
          name: 'Series',
          stroke: value,
          xAxis: xAxis,
          yAxis: yAxis,
          stacked: false,
          valueYField: 'score',
          categoryXField: 'name',
          fill: am5.color(value),
          tooltip: am5.Tooltip.new(root, {
            labelText: `${t('You')}: {score}`,
          }),
        })
      );

      // series2
      var series2 = chart.series.push(
        am5radar.RadarLineSeries.new(root, {
          name: 'Cash held in US',
          xAxis: xAxis,
          stroke: am5.color('#95c11f'),
          yAxis: yAxis,
          valueYField: 'score2',
          categoryXField: 'name',
          stacked: false,
          fill: am5.color('#95c11f'),
          tooltip: am5.Tooltip.new(root, {
            labelText: `${t('Average')}: {score2}`,
          }),
        })
      );

      series.strokes.template.setAll({
        strokeWidth: 2,
        strokeOpacity: 1,
      });
      series.fills.template.setAll({
        visible: true,
        fillOpacity: 0.3,
      });

      series2.strokes.template.setAll({
        strokeWidth: 2,
        strokeOpacity: 1,
      });

      series2.fills.template.setAll({
        visible: true,
        fillOpacity: 0.1,
      });

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 3,
            // fill: am5.color(headerColor?.firstColor),
            fill: am5.color(value),
          }),
        });
      });
      series2.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 3,
            fill: am5.color('#95c11f'),
          }),
        });
      });

      var data = [];
      const newData = this.state.accordionData.filter(
        (item) => item.domainId !== 15
      );

      let matchedArray = this.state.moduleAvarageScores
        .filter((item1) =>
          newData.some((item2) => item1.domainId === item2.domainId)
        )
        .sort((a, b) => a.domainId - b.domainId);
      for (let i = 0; i < newData.length; i++) {
        data.push({
          name: `${t(newData[i]?.domainName)}`,
          score: newData[i]?.domainScore ?? Math.random(),
          score2: parseInt(matchedArray[i]?.avgScore),
        });
      }
      series.data.setAll(data);
      series2.data.setAll(data);
      xAxis.data.setAll(data);
      function fnExport() {
        let exporting = am5plugins_exporting.Exporting.new(root, {
          menu: am5plugins_exporting.ExportingMenu.new(root, {}),
          pngOptions: {
            quality: 0.8,
            maintainPixelRatio: true,
            minWidth: 1000,
            maxHeight: '100%',
          },
        });
        setTimeout(() => {
          Promise.all([exporting.export('png')]).then((res) => {
            let pdfMake = res[0];
            var image = new Image();
            image.src = pdfMake;
            document.body.appendChild(image);
            let chartdiv = document.getElementById('chartdiv');
            chartdiv.style.display = 'none';
            image.style.width = '100%';
          });
        }, 500);
      }
      fnExport();
    }
  }
  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render() {
    return (
      <div className="container-md">
        <div style={{ backgroundColor: '#4A5760' }} id="chartdiv"></div>
      </div>
    );
  }
}

const HOCTaskDetail = withRouter(CustomReportComponent);
export default withTranslation()(HOCTaskDetail);
