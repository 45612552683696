import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Card,
  Container,
  DialogContent,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Button } from 'primereact/button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import LayoutComponent from '../components/LayoutComponent';
import moment from 'moment';
import axios from 'axios';
import AuthenticationService from '../service/AuthenticationService';
import Swal from 'sweetalert2';
import CreateNewAppointment from '../components/CreateNewAppointment';
import Box from '@mui/material/Box';
import { LocationOn } from '@material-ui/icons';
import { Numbers } from '@mui/icons-material';
import { MainButton } from '../components/common/MainButton';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  wrapper: {
    maxWidth: 100,
    width: '100%',
  },
  cardPending: {
    marginBottom: theme.spacing(1),
    borderTopStyle: 'solid',
    borderTopWidth: 5,
    borderColor: '#ffb300',
  },
  cardApproved: {
    marginBottom: theme.spacing(1),
    borderTopStyle: 'solid',
    borderTopWidth: 5,
    borderColor: '#4caf50',
  },
  dialogHeader: {
    padding: '0 !important',
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const UserAppointments = (callback, deps) => {
  const { t } = useTranslation();
  const [nonApprovedAppointments, setNonApprovedAppointments] = useState([]);
  const [approvedAppointments, setApprovedAppointments] = useState([]);

  const [visible, setVisible] = useState(false);
  const [displayResponsive, setDisplayResponsive] = useState(false);

  const classes = useStyles();

  const user = AuthenticationService.getLoggedInUser();

  const fetchUserAppointments = useCallback(() => {
    axios
      .get('/appointments/userAppointments', {
        params: { userId: user.userId },
      })
      .then(({ data }) => {
        const approvedAppointments = data.filter(
          (appointment) => appointment.approved
        );
        const nonApprovedAppointments = data.filter(
          (appointment) => !appointment.approved
        );

        setApprovedAppointments(approvedAppointments);
        setNonApprovedAppointments(nonApprovedAppointments);
      });
  }, [user.userId]);

  useEffect(() => {
    fetchUserAppointments();
  }, [fetchUserAppointments]);

  const cancelAppointment = (appointmentId) => {
    axios
      .delete('/appointments/cancel', {
        params: { appointmentId },
      })
      .then(() => {
        Swal.fire({
          title: `${t('appointment_alert_messages.appointment_canncelled')}`,
          icon: 'success',
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: false,
          footer: '',
        });
        reload();
      });
  };

  const reload = () => {
    setVisible(false);
    fetchUserAppointments();
  };

  const compareValue = () => {
    const value = localStorage.getItem('createAppointmentValue');
    if (value === '2' && value !== null) {
      setDisplayResponsive(true);
    } else {
      setVisible(true);
    }
  };

  return (
    <LayoutComponent>
      <Container classes={classes.container} maxWidth={false}>
        <Grid container direction="row" justifyContent="center">
          <Grid item={true} xs={12} md={4}>
            <>
              <Typography style={{ marginTop: 5 }} variant="h5">
                {t('pending_approval_message')}
              </Typography>
              <Grid>
                <List>
                  {nonApprovedAppointments &&
                  nonApprovedAppointments.length > 0 ? (
                    nonApprovedAppointments.map((appoint) => (
                      <Card
                        style={{ marginTop: 10 }}
                        key={appoint.appointmentId}
                        className={classes.cardPending}
                      >
                        <Box
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ px: 2, py: 0, bgcolor: 'background.default' }}
                        >
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <LocationOn />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${
                                appoint.station?.location?.city?.name ?? ''
                              },${
                                appoint.station?.location?.country?.name ?? ''
                              } ${appoint.station?.location?.name ?? ''}`}
                              secondary={`${appoint.station?.name ?? ''}`}
                            />
                          </ListItem>
                        </Box>
                        <Divider />
                        <Box
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ px: 2, py: 0, bgcolor: 'background.default' }}
                        >
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <EventAvailableIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${appoint.start} - ${appoint.end}`}
                              secondary={moment(appoint.date).format(
                                'Do MMMM YYYY'
                              )}
                            />
                          </ListItem>
                        </Box>
                        <Divider />
                        <Box
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ px: 2, py: 0, bgcolor: 'background.default' }}
                        >
                          <Tooltip
                            title={t(
                              'appointment_alert_messages.can_appointment_canncelled'
                            )}
                          >
                            <IconButton
                              onClick={() => {
                                Swal.fire({
                                  title: `${t(
                                    'appointment_alert_messages.sure_cancel_appointment'
                                  )}`,
                                  icon: 'warning',
                                  showCancelButton: true,
                                  confirmButtonColor: '#EC0044',
                                  cancelButtonColor: '#929ea8',
                                  confirmButtonText: `${t(
                                    'appointment_alert_messages.can_appointment_canncelled'
                                  )}`,
                                  cancelButtonText: 'Vazgeç',
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    cancelAppointment(appoint.appointmentId);
                                  }
                                });
                              }}
                              color="primary"
                              aria-label="delete"
                            >
                              <CancelIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Card>
                    ))
                  ) : (
                    <Card className={classes.card}>
                      <ListItem>
                        <ListItemText
                          primary={t('not_found_appointment_message')}
                        />
                      </ListItem>
                    </Card>
                  )}
                </List>
              </Grid>
            </>
          </Grid>
          <Grid
            item={true}
            container
            direction="row"
            xs={12}
            md={7}
            justifyContent="space-evenly"
          >
            <Grid item={true} xs={12} md={4}>
              <>
                <Typography style={{ marginTop: 5 }} variant="h5">
                  {t('confirmed_appointmen_message')}
                </Typography>
                <Grid>
                  <List>
                    {approvedAppointments && approvedAppointments.length > 0 ? (
                      approvedAppointments.map((appoint, index) => (
                        <Card key={index} style={{ marginTop: 10 }}>
                          <Box
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ px: 2, py: 0, bgcolor: 'background.default' }}
                          >
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar>
                                  <Numbers />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={t('appointment_number')}
                                secondary={`${appoint.appointmentId ?? ''}`}
                              />
                            </ListItem>
                          </Box>
                          <Divider />
                          <Box
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ px: 2, py: 0, bgcolor: 'background.default' }}
                          >
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar>
                                  <LocationOn />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={`${
                                  appoint.station?.location?.city?.name ?? ''
                                },${
                                  appoint.station?.location?.country?.name ?? ''
                                } ${appoint.station?.location?.name ?? ''}`}
                                secondary={`${appoint.station?.name ?? ''}`}
                              />
                            </ListItem>
                          </Box>
                          <Divider />
                          <Box
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ px: 2, py: 0, bgcolor: 'background.default' }}
                          >
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar>
                                  <EventAvailableIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={`${appoint.start} - ${appoint.end}`}
                                secondary={moment(appoint.date).format(
                                  'Do MMMM YYYY'
                                )}
                              />
                            </ListItem>
                          </Box>
                        </Card>
                      ))
                    ) : (
                      <Card className={classes.card}>
                        <ListItem>
                          <ListItemText
                            primary={t('not_found_appointment_message')}
                          />
                        </ListItem>
                      </Card>
                    )}
                  </List>
                </Grid>
              </>
            </Grid>
            <Grid item={true} xs={12} md={3}>
              <Button
                style={{ minWidth: 140, margin: 5 }}
                variant="outlined"
                className="p-button-danger"
                onClick={() => compareValue()}
                label={t('new_create_appointment')}
              />
              <Dialog
                open={displayResponsive}
                fullWidth={false}
                maxWidth="lg"
                onClose={() => setDisplayResponsive(false)}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle
                  className={classes.dialogHeader}
                  id="form-dialog-title"
                  onClose={() => setDisplayResponsive(false)}
                >
                  <h3 className="text-center">{t('information')}</h3>
                </DialogTitle>

                <DialogContent style={{ minHeight: 50 }}>
                  <p>{t('appointment_email_message')}</p>
                </DialogContent>
                <DialogActions>
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <MainButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => setDisplayResponsive(false)}
                      autoFocus
                    >
                      {t('ok')}
                    </MainButton>
                  </div>
                </DialogActions>
              </Dialog>
              <Dialog
                open={visible}
                fullWidth={true}
                maxWidth="lg"
                onClose={() => setVisible(false)}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle
                  id="form-dialog-title"
                  onClose={() => setVisible(false)}
                >
                  {t('new_create_appointment')}
                </DialogTitle>

                <DialogContent style={{ minHeight: 400 }}>
                  <CreateNewAppointment
                    onCreate={() => reload()}
                    userId={user.id}
                  />
                </DialogContent>
              </Dialog>
              {/*<Button color="primary"></Button>*/}
            </Grid>
          </Grid>

          {/* <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            fullWidth={true}
            maxWidth="sm"
            open={open}
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Randevu saatini seçiniz
            </DialogTitle>
            <Grid item xs={12}>
              <div className={classes.slotWrapper}>
                {slots &&
                  slots.map((slot, idx) => (
                    <Button
                      className={classes.slotButton}
                      key={idx}
                      style={{
                        width: "100%",
                        marginBottom: 10,
                      }}
                      variant="contained"
                      color="secondary"
                      onClick={() => onNewAppointment(date, slot)}
                      disabled={!slot.available}
                    >
                      {slot.start} - {slot.end}
                    </Button>
                  ))}
              </div>
            </Grid>
          </Dialog> */}
        </Grid>
      </Container>
    </LayoutComponent>
  );
};

export default UserAppointments;
