import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import { Button, Paper } from '@material-ui/core';
import { Button as PrimeButton } from 'primereact/button';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@mui/material/Zoom';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';

import {
	Calendar,
	momentLocalizer,
	Views,
	dateFnsLocalizer,
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import AuthenticationService from '../service/AuthenticationService';
import { Link } from 'react-router-dom';
import Spinner from '../components/common/Spinner';
import { InputText } from 'primereact/inputtext';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { TryRounded } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
	root: {},
	paper: {
		padding: theme.spacing(3),
		width: '100%',
	},
	calendar: {
		overflowX: 'scroll',
		width: '100%',
		fontSize: '0.77em',
	},
}));

const MailLogs = ({ t }) => {
	const classes = useStyles();
	const langValue = localStorage.getItem('i18nextLng') ?? 'tr';

	const [logs, setlogs] = useState([]);
	const [filteredData1, setFilteredData1] = useState([]);
	const [filteredData2, setFilteredData2] = useState([]);
	const [loading, setLoading] = useState(false);
	const [mailLogVisible, setMailLogVisible] = useState(false);

	const { enqueueSnackbar } = useSnackbar();
	const [filters1, setFilters1] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
		email: { value: null, matchMode: FilterMatchMode.CONTAINS },
		processType: { value: null, matchMode: FilterMatchMode.CONTAINS },

		statusCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [filters2, setFilters2] = useState({
		Comment: { value: null, matchMode: FilterMatchMode.CONTAINS },
		EventAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
		EventType: { value: null, matchMode: FilterMatchMode.CONTAINS },
		State: { value: null, matchMode: FilterMatchMode.CONTAINS },
		Useragent: { value: null, matchMode: FilterMatchMode.CONTAINS },
		UseragentID: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [mailLogs, setMailLogs] = useState([]);
	const [first, setFirst] = useState(0); // Add this line
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState(null);
	const [startDate, setStartDate] = useState('');
	const [endTime, setEndTime] = useState('');
	const [startTime, setStartTime] = useState('');
	const [rows, setRows] = useState(10);
	const [mailLogRowData, setMailLogRowData] = useState();
	const [mailHistoryData, setMailHistoryData] = useState([]);

	const fetchAllMailLogs = async (
		first,
		rows,
		sortField,
		sortOrder,
		filters
	) => {
		setLoading(true);
		let page = Math.floor(first / rows) ? Math.floor(first / rows) : 0;
		let formattedFilters = formatParams(filters);
		const params = {
			page: page,
			size: rows,
			sortOrder: sortOrder === 1 ? 'asc' : 'desc',
			...formattedFilters,
			sortBy: sortField,
		};

		try {
			const { data } = await axios.get('/dashboard/getAllMailLogs', { params });

			const result = data?.mailjetEmailLogList;

			for (let i = 0; i < result.length; i++) {
				result[i].createdAt = formatDate(result[i]?.createdAt);
				result[i].updatedAt = formatDate(result[i]?.updatedAt);
			}

			setMailLogs(result);
			setTotalRecords(data?.totalRecords);
			setFilteredData1(result);
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	const getMailHistoryByMessageId = async (messageId) => {
		if (
			messageId &&
			(messageId !== undefined || messageId !== null || messageId !== '')
		) {
			try {
				const { data } = await axios.get(
					'/dashboard/getMailHistoryByMessageId',
					{
						headers: {
							messageId: messageId,
						},
					}
				);
				const result = data;
				for (let i = 0; i < result.length; i++) {
					result[i].EventAt = formatDate(result[i]?.EventAt);
				}
				setMailHistoryData(result);
				setFilteredData2(result);
			} catch (err) {
				console.log(err);
			}
		} else return;
	};

	useEffect(() => {
		fetchAllMailLogs(first, rows, sortField, sortOrder, filters1);
	}, [langValue, first, rows, sortField, sortOrder, filters1]);

	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};
	const formatParams = (params) => {
		let formattedParams = {};

		if (params) {
			for (let field in params) {
				if (params[field].value !== undefined && params[field].value !== null) {
					formattedParams[field] = params[field].value;
				}
			}
		}

		return formattedParams;
	};

	const onSort = (event) => {
		setSortField(event.sortField);
		setSortOrder(event.sortOrder);
	};
	const exportExcel = (filtredData) => {
		setLoading(true);

		import('xlsx').then((xlsx) => {
			const worksheet = xlsx.utils.json_to_sheet(filtredData);
			const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
			const excelBuffer = xlsx.write(workbook, {
				bookType: 'xlsx',
				type: 'array',
			});
			saveAsExcelFile(excelBuffer, 'files');
		});
	};
	const saveAsExcelFile = (buffer, fileName) => {
		import('file-saver').then((module) => {
			if (module && module.default) {
				let EXCEL_TYPE =
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				let EXCEL_EXTENSION = '.xlsx';
				const data = new Blob([buffer], {
					type: EXCEL_TYPE,
				});

				module.default.saveAs(
					data,
					fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
				);
			}
			setLoading(false);
		});
	};
	const handleClick = async (rowData) => {
		setMailLogRowData(rowData);

		setLoading(true);

		await getMailHistoryByMessageId(rowData?.messageId);

		setLoading(false);
		setMailLogVisible(true);
	};

	const actionBodyTemplate = (rowData) => {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Tooltip TransitionComponent={Zoom} title={t('detail')} placement='top'>
					<Button
						className='p-button-text'
						type='button'
						color='secondary'
						onClick={() => {
							handleClick(rowData);
						}}
					>
						<svg
							fill='#0099F7'
							height='35'
							viewBox='0 0 20 20'
							width='35'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M8 2C6.89543 2 6 2.89543 6 4V9.17476C6.34971 9.28379 6.68693 9.44385 7 9.65493V4C7 3.44772 7.44772 3 8 3H12V6.5C12 7.32843 12.6716 8 13.5 8H17V16C17 16.5523 16.5523 17 16 17H10.4505L9.45051 18H16C17.1046 18 18 17.1046 18 16V7.41421C18 7.01639 17.842 6.63486 17.5607 6.35355L13.6464 2.43934C13.3651 2.15804 12.9836 2 12.5858 2H8ZM16.7929 7H13.5C13.2239 7 13 6.77614 13 6.5V3.20711L16.7929 7ZM3.542 11.542C4.26467 10.8193 5.43635 10.8193 6.15903 11.542L6.64898 12.032C6.84424 12.2272 7.16082 12.2272 7.35609 12.032L7.84604 11.542C8.56871 10.8193 9.74039 10.8193 10.4631 11.542C10.8624 11.9414 11.0411 12.4779 10.9991 13H12.0012C12.0418 12.2215 11.7648 11.4295 11.1702 10.8349C10.057 9.7217 8.25213 9.7217 7.13893 10.8349L7.00253 10.9713L6.86613 10.8349C5.75294 9.7217 3.94809 9.7217 2.8349 10.8349C2.24027 11.4295 1.96327 12.2215 2.0039 13H3.00601C2.96395 12.4779 3.14262 11.9414 3.542 11.542ZM5.38298 16H3.96876L6.64896 18.6802C6.72018 18.7514 6.80754 18.7967 6.89927 18.8159C6.96472 18.8297 7.03239 18.8302 7.09801 18.8175C7.19257 18.7992 7.28287 18.7534 7.3561 18.6802L10.0363 16H8.62209L7.00253 17.6196L5.38298 16ZM5.94966 12.2764C5.86496 12.107 5.69183 12 5.50244 12C5.31305 12 5.13992 12.107 5.05523 12.2764L4.19342 14L3 14C2.72386 14 2.5 14.2239 2.5 14.5C2.5 14.7761 2.72385 15 3 15L4.50244 15C4.69183 15 4.86496 14.893 4.94966 14.7236L5.50244 13.618L6.55523 15.7236C6.63272 15.8786 6.78495 15.9824 6.95754 15.998C7.13013 16.0135 7.29847 15.9386 7.40244 15.8L8.55654 14.2612L9.14889 14.8536C9.24265 14.9473 9.36983 15 9.50244 15H11C11.2761 15 11.5 14.7762 11.5 14.5C11.5 14.2239 11.2761 14 11 14H9.70955L8.856 13.1464C8.7535 13.044 8.61158 12.991 8.467 13.0013C8.32242 13.0115 8.18941 13.084 8.10244 13.2L7.08785 14.5528L5.94966 12.2764Z'
								fill='#0099F7'
							/>
						</svg>
					</Button>
				</Tooltip>
			</div>
		);
	};

	const handleCloseLogDetailDialog = () => {
		setMailLogVisible(false);
	};

	const renderHeader1 = () => {
		return (
			<div className='clearfix'>
				<div className='float-right p-2'>
					<div className='flex align-items-center export-buttons'>
						{AuthenticationService.isAdmin() && (
							<PrimeButton
								type='button'
								icon='pi pi-file-excel'
								onClick={exportExcel}
								className='p-button-success mr-2'
								data-pr-tooltip='XLS'
							/>
						)}
					</div>
				</div>
			</div>
		);
	};

	const MailLogDetailDialog = () => {
		return (
			<>
				<Dialog
					visible={mailLogVisible}
					header={t('maillog_detail')}
					onHide={handleCloseLogDetailDialog}
				>
					<div className='card-body p-0'>
						<DataTable
							value={mailHistoryData}
							// header={renderHeader1}
							responsiveLayout='scroll'
							paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
							rowsPerPageOptions={[10, 25, 50]}
							dataKey='id'
							rows={10}
							paginator
							showGridlines
							rowHover
							// onValueChange={(filteredData2) => setFilteredData2(filteredData2)}
							filters={filters2}
							filterDisplay='row'
							loading={loading}
							emptyMessage={t('no_records_found')}
							currentPageReportTemplate={t('currentPageReportTemplate')}
						>
							<Column
								style={{
									textAlign: 'center',
								}}
								header={t('sequence_no')}
								body={(data, props) => <div>{props.rowIndex + 1}</div>}
							></Column>
							<Column
								header={t('UseragentID')}
								sortable={true}
								field='UseragentID'
								filter
								style={{
									maxWidth: '120px',
									textAlign: 'center',
									textDecoration: 'none',
									cursor: 'default',
								}}
							></Column>
							<Column
								header={t('Useragent')}
								sortable={true}
								field='Useragent'
								filter
								style={{
									maxWidth: '100px',
									textAlign: 'center',
									textDecoration: 'none',
									cursor: 'default',
								}}
							></Column>

							<Column
								header={t('mail_comment')}
								sortable={true}
								field='Comment'
								filter
								style={{ minWidth: '100px', textAlign: 'center' }}
							></Column>

							<Column
								header={t('mail_event_at')}
								sortable={true}
								field='EventAt'
								filter
								style={{ minWidth: '100px', textAlign: 'center' }}
							></Column>

							<Column
								header={t('mail_event_type')}
								sortable={true}
								field='EventType'
								filter
								style={{ minWidth: '100px', textAlign: 'center' }}
								// body={isCompletedForm}
							></Column>
							<Column
								header={t('mail_state')}
								sortable={true}
								field='State'
								filter
								paginator
								style={{ minWidth: '100px', textAlign: 'center' }}
								// body={isCompletedForm}
							></Column>
						</DataTable>
					</div>
				</Dialog>
			</>
		);
	};

	const renderHeader = () => {
		return (
			<div className='clearfix'>
				<div className='float-right p-2'>
					<div className='flex align-items-center export-buttons'>
						{AuthenticationService.isAdmin() && (
							<PrimeButton
								type='button'
								icon='pi pi-file-excel'
								onClick={exportExcel}
								className='p-button-success mr-2'
								data-pr-tooltip='XLS'
							/>
						)}
					</div>
				</div>
			</div>
		);
	};

	const formatDate = (day) => {
		const date = moment(new Date(day * 1000));
		let time =
			langValue === 'tr'
				? date.locale(`${t(langValue)}`).format('DD MMMM YYYY HH:mm:ss')
				: date.locale(`${t(langValue)}`).format('MMMM DD YYYY HH:mm:ss');
		return time;
	};
	const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
		<InputText
			style={{
				padding: '.5rem',
				borderRadius: '4px',
				borderColor: '#ddd',
				width: '100%',
			}}
			type='text'
			className='form-control'
			onClick={onClick}
			value={value}
			ref={ref}
		/>
	));
	const numberofpages = Math.ceil(totalRecords / rows);
	return (
		<>
			<Spinner loading={loading} />
			<MailLogDetailDialog></MailLogDetailDialog>
			<div className='card card-custom gutter-b'>
				<div className='card-header'>
					<div className='card-title'>
						<h3 className='card-label'>{t('LogList')}</h3>
					</div>
				</div>
				<div className='card-body p-0'>
					<DataTable
						value={mailLogs}
						totalRecords={totalRecords}
						first={first}
						rows={rows}
						paginator
						onPage={onPageChange}
						showGridlines
						onSort={onSort}
						sortField={sortField}
						sortOrder={sortOrder}
						responsiveLayout='scroll'
						header={renderHeader}
						paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
						rowsPerPageOptions={[10, 25, 50]}
						dataKey='id'
						rowHover
						lazy='true'
						sortMode='single'
						globalFilterFields={[
							'createdAt',
							'email',
							'statusCode',
							'processType',
						]}
						onValueChange={(filteredData1) => setFilteredData1(filteredData1)}
						filters={filters1}
						filterDisplay='row'
						loading={loading}
						emptyMessage={t('no_records_found')}
						currentPageReportTemplate={t('currentPageReportTemplate')}
						numberofpages={numberofpages}
					>
						<Column
							header={t('action')}
							body={actionBodyTemplate}
							style={{
								minWidth: '100px',
								textDecoration: 'none',
								cursor: 'default',
							}}
						></Column>
						<Column
							style={{
								textAlign: 'center',
							}}
							header={t('sequence_no')}
							body={(data, props) => <div>{props.rowIndex + 1}</div>}
						></Column>

						<Column
							style={{ minWidth: '100px' }}
							header={t('email')}
							sortable={true}
							field='email'
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (e.target.value.length === 0 && e.key === ' ') {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 2 ||
											e.target.value.trim().length === 0
										) {
											setFilters1({
												...filters1,
												email: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
						></Column>

						<Column
							header={t('mail_status_code')}
							sortable={true}
							field='statusCode'
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (
											!/\d/.test(e.key) ||
											(e.target.value.length === 0 && e.key === ' ')
										) {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 1 ||
											e.target.value.trim().length === 0
										) {
											setFilters1({
												...filters1,
												statusCode: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							style={{ minWidth: '100px', textAlign: 'center' }}
						></Column>
						<Column
							style={{ width: 'object-fit' }}
							header={t('created_at')}
							sortable={true}
							field='createdAt'
							filter
							filterElement={
								<DatePicker
									dateFormat={langValue === 'tr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
									key={localStorage.getItem('i18nextLng')}
									locale={
										localStorage.getItem('i18nextLng') === 'tr' ? 'tr' : 'en'
									}
									selected={startDate}
									showMonthDropdown
									showYearDropdown
									yearDropdownItemNumber={478}
									scrollableYearDropdown
									scrollableMonthDropdown
									popperPlacement='bottom-start'
									dropdownMode='scroll'
									customInput={<CustomInput />}
									isClearable={true}
									onKeyDown={(e) => e.preventDefault()}
									onChange={(date) => {
										const formattedDate = moment(date).format('YYYY-MM-DD');

										if (date === 'Invalid date') {
											return;
										}
										setFirst(0);
										setFilters1(
											date
												? {
														...filters1,
														createdAt: {
															value: formattedDate,
															matchMode: FilterMatchMode.EQUALS,
														},
												  }
												: {
														...filters1,
														createdAt: {
															value: null,
															matchMode: FilterMatchMode.EQUALS,
														},
												  }
										);
										setStartDate(date);
									}}
								/>
							}
						></Column>

						<Column
							header={t('mail_process_type')}
							sortable={true}
							field='processType'
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (e.target.value.length === 0 && e.key === ' ') {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 2 ||
											e.target.value.trim().length === 0
										) {
											setFilters1({
												...filters1,
												processType: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							style={{ minWidth: '100px' }}
							// body={isCompletedForm}
						></Column>
					</DataTable>
				</div>
			</div>
		</>
	);
};
export default withTranslation()(MailLogs);
