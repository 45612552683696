import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import FormInput from './common/FormInput';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { MainButton } from './common/MainButton';
import { Controller, useForm } from 'react-hook-form';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const UpdateForm = ({ visible, onCreate, onCancel }) => {
  const { t } = useTranslation();

  const Schema = Yup.object().shape({
    updateDescription: Yup.string().required(`${t('schema_shape.not_empty')}`),
  });

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(Schema),
  });

  return (
    <Dialog
      open={visible}
      fullWidth={true}
      maxWidth="sm"
      onClose={onCancel}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <form noValidate onSubmit={handleSubmit(onCreate)}>
        <DialogTitle id="customized-dialog-title" onClose={onCancel}>
          {t('preview_form_update')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <Controller
            name="updateDescription"
            control={control}
            render={(props) => (
              <FormInput
                {...props}
                margin="dense"
                required
                label={t('update_desc')}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end">
            <MainButton
              style={{ marginInline: 20 }}
              type="submit"
              color="primary"
              autoFocus
            >
              {t('save')}
            </MainButton>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateForm;
