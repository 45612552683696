import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import StationService from '../service/StationService';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import LocationService from '../service/LocationService';
import { withTranslation } from 'react-i18next';

const StationInfo = ({ t }) => {
  const toast = useRef(null);
  const [station, setStation] = useState({
    id: '',
    countryId: null,
    cityId: null,
    locationId: null,
    location: {},
    name: '',
    productNumber: '',
  });
  const [locations, setLocations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  // let {path} = useRouteMatch();
  const params = useParams();
  var stationId = params.id;

  // console.log('path:', path, ' - params:', params);
  const onSubmit = () => {
    // e.preventDefault();
    // console.log('formData::::::::::::::::::>>>>>', e);
    if (station.location.id == null || station.location.id === 0) {
      toast.current.show({
        severity: 'error',
        summary: `${t('appointment_alert_messages.transaction_status')}`,
        detail: `${t('appointment_alert_messages.change_location')}`,
      });
      return;
    }

    if (station.name == null || station.name === '') {
      toast.current.show({
        severity: 'error',
        summary: `${t('appointment_alert_messages.transaction_status')}`,
        detail: `${t('appointment_alert_messages.enter_hub_name')}`,
      });
      return;
    }

    if (station.id == null || station.id.length < 1) {
      StationService.saveStation(station).then((res) => {
        toast.current.show({
          severity: 'success',
          summary: `${t('appointment_alert_messages.transaction_status')}`,
          detail: `${t('appointment_alert_messages.transaction_successful')}`,
        });
        setTimeout(() => {
          handleOnClick();
        }, 1000);
      });
    } else {
      StationService.updateStation(station).then((res) => {
        toast.current.show({
          severity: 'success',
          summary: `${t('appointment_alert_messages.transaction_status')}`,
          detail: `${t('appointment_alert_messages.transaction_successful')}`,
        });
        // setTimeout(() => {
        //     handleOnClick();
        // }, 1000)
      });
    }
  };

  useEffect(() => {
    //console.log(stationId);
    get(stationId);
    // getCountries();
  }, [stationId]);

  const get = (stationId) => {
    if (stationId != null && stationId.length > 0) {
      StationService.getStationInfoById(stationId).then((res) => {
        // console.log('data:::::::::::', res.data);
        setStation({
          ...res.data,
          countryId: res.data.location.country.id,
          cityId: res.data.location.city.id,
        });
        getCities(res.data.location.country.id);
        getLocationListByCountryIdAndCityId(
          res.data.location.country.id,
          res.data.location.city.id
        );
      });
    }
    LocationService.getCountries().then((res) => {
      // console.log('data:::::::::::', res.data);
      setCountries(res.data);
    });
    // LocationService.getLocationList().then((res) => {
    // console.log('data:::::::::::', res.data);
    //         setLocations(res.data);
    //     }
    // );
  };
  const getLocationListByCountryIdAndCityId = (countryId, cityId) => {
    LocationService.getLocationListByCountryIdAndCityId(countryId, cityId).then(
      (resp) => {
        // console.log('data:::::::::::', resp.data);
        setLocations(resp.data);
      }
    );
  };
  const getCities = (id) => {
    LocationService.getCitiesByCountryId(id).then((resp) => {
      // console.log('data:::::::::::', resp.data);
      setCities(resp.data);
    });
  };
  const history = useHistory();
  const handleOnClick = useCallback(
    () => history.push('/panel/istasyonlar'),
    [history]
  );

  return (
    <>
      <Toast ref={toast} />
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">{t('hub_detail')}</h3>
          </div>
          <div className="card-toolbar">
            <ul className="ust_menu">
              <li tooltip={t('turn_back')} onClick={handleOnClick}>
                <i className="pi pi-arrow-left" />
              </li>
              <li tooltip={t('save_infos')} onClick={onSubmit}>
                <i className="pi pi-save " />
              </li>
            </ul>
          </div>
        </div>
        <div className="card-body">
          <form className="p-fluid grid">
            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">{t('country')}</label>
              <Dropdown
                value={station.countryId}
                className="p-inputtext-sm dropdownIndex"
                filter={true}
                tabIndex={1}
                filterInputAutoFocus={true}
                showFilterClear={true}
                filterBy="name"
                appendTo="self"
                onChange={(e) => {
                  setStation({
                    ...station,
                    countryId: e.value,
                    locationId: null,
                  });
                  setLocations([]);
                  getCities(e.value);
                }}
                options={countries}
                optionValue="id"
                optionLabel="name"
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">{t('city')}</label>
              <Dropdown
                value={station.cityId}
                className="p-inputtext-sm"
                filter={true}
                appendTo="self"
                onChange={(e) => {
                  setStation({ ...station, cityId: e.value, locationId: null });
                  setLocations([]);
                  getLocationListByCountryIdAndCityId(
                    station.countryId,
                    e.value
                  );
                }}
                options={cities}
                optionValue="id"
                optionLabel="name"
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">{t('location')}</label>
              <Dropdown
                value={station.location}
                className="p-inputtext-sm"
                filter={true}
                onChange={(e) => {
                  // field.onChange(e.value);
                  setStation({
                    ...station,
                    locationId: e.value.id,
                    location: e.value,
                  });
                }}
                options={locations}
                optionLabel="name"
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="inputtext">{t('product_no')}</label>
              <InputText
                id="inputtext"
                value={station.productNumber}
                onChange={(e) =>
                  setStation({ ...station, productNumber: e.target.value })
                }
                className="p-inputtext-sm"
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="inputtext">{t('hub_name')}</label>
              <InputText
                id="inputtext"
                value={station.name}
                onChange={(e) =>
                  setStation({ ...station, name: e.target.value })
                }
                className="p-inputtext-sm"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default withTranslation()(StationInfo);
