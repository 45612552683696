import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins_forceDirected from '@amcharts/amcharts4/plugins/forceDirected';
import './Reports.css';
import LayoutComponent from '../../components/LayoutComponent';
import { Card, Divider, Grid } from '@material-ui/core';
import Box from '@mui/material/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { Numbers } from '@mui/icons-material';
import ListItemText from '@material-ui/core/ListItemText';
import { LocationOn } from '@material-ui/icons';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import TreeView from '@material-ui/lab/TreeView';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring'; // web.cjs is required for IE 11 support
import '../../components/common/treeview.css';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import PhysioPhysical from './PhysioPhysical';
import PhysicalModuleMeasures from './physio-physical/PhysicalModuleMeasures';
import SocioEmotional from './SocioEmotional';
import Cognitive from './Cognitive';
import { ColorPicker } from 'primereact/colorpicker';
import moment from 'moment';
import OverviewService from '../../service/OverviewService';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import AuthenticationService from '../../service/AuthenticationService';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Spinner from '../../components/common/Spinner';
import NotFoundImage from '../../assets/images/no-data-gorseli.png';
import { withTranslation } from 'react-i18next';
function TransitionComponent(props) {
  // console.log(props)
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 20 : 20}px,5,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  in: PropTypes.bool,
};
const StyledTreeItem = withStyles((theme) => ({
  group: {
    marginLeft: 7,
    paddingLeft: 18,
  },
}))((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

const Overview = ({ t }) => {
  const [score, setScore] = useState(0);
  const [showDetail, setShowDetail] = useState(false);
  const [data, setData] = useState({});
  const [detailInfo, setDetailInfo] = useState({
    name: '',
    value: '',
    subdomainId: 0,
    domain: {},
    contentList: [],
  });
  const [appointment, setAppointment] = useState({});
  const [approvedAppointments, setApprovedAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);

  const chart = useRef(null);
  const classes = useStyles();

  let history = useHistory();
  let { path, url } = useRouteMatch();
  const params = useParams();
  const appointmentId = parseInt(params.id);
  const user = AuthenticationService.getLoggedInUser();

  const getChart = (data) => {
    /**
     * ---------------------------------------
     * This demo was created using amCharts 4.
     *
     * For more information visit:
     * https://www.amcharts.com/
     *
     * Documentation is available at:
     * https://www.amcharts.com/docs/v4/
     * ---------------------------------------
     */

    //am4core.useTheme(am4themes_animated);

    let colorsList = [];
    if (data?.modules != null)
      for (let i = 0; i < data.modules.length; i++) {
        colorsList.push(am4core.color(data.modules[i].forcedTreeColor));
        data.modules[i].color = am4core.color(data.modules[i].forcedTreeColor);
        data.modules[i].name = data.modules[i].moduleName;
        data.modules[i].fullname = data.modules[i].moduleName;
        data.modules[i].score =
          data.modules[i].moduleScore ?? `${t('value_not_found')}`;
        data.modules[i].value = 3500;
        data.modules[i].children = data.modules[i].domains;
        data.modules[i].age = data.age;
        data.modules[i].appointmentId = data.appointmentId;
        data.modules[i].gender = data.gender;

        // delete data.modules[i].forcedTreeColor;
        // delete data.modules[i].moduleName;
        // delete data.modules[i].moduleScore;
        // delete  data.modules[i].domains;

        if (data.modules[i].children != null)
          for (var j = 0; j < data.modules[i].children.length; j++) {
            data.modules[i].children[j].name =
              data.modules[i].children[j].domainName;
            data.modules[i].children[j].fullname =
              data.modules[i].children[j].domainName;
            let name = data.modules[i].children[j].domainName;
            if (name?.length > 15) {
              name = name.substr(0, 15) + '...';
              data.modules[i].children[j].name = name;
            }

            data.modules[i].children[j].score =
              data.modules[i].children[j].domainScore ??
              `${t('value_not_found')}`;
            data.modules[i].children[j].value = 2500;
            data.modules[i].children[j].appointmentId = data.appointmentId;
            data.modules[i].children[j].moduleId = data.modules[i].moduleId;
            data.modules[i].children[j].domains = data.modules[i].domains;

            data.modules[i].children[j].children =
              data.modules[i].children[j].subdomains;

            if (data.modules[i].children[j].children != null)
              for (
                var k = 0;
                k < data.modules[i].children[j].children.length;
                k++
              ) {
                data.modules[i].children[j].children[k].name =
                  data.modules[i].children[j].children[k].subdomainName;
                data.modules[i].children[j].children[k].fullname =
                  data.modules[i].children[j].children[k].subdomainName;

                let name =
                  data.modules[i].children[j].children[k].subdomainName;
                if (name?.length > 11) {
                  name = name.substr(0, 10) + '...';
                  data.modules[i].children[j].children[k].name = name;
                }

                data.modules[i].children[j].children[k].score =
                  data.modules[i].children[j].children[k].subdomainScore ??
                  `${t('value_not_found')}`;
                data.modules[i].children[j].children[k].value = 2000;
                data.modules[i].children[j].children[k].appointmentId =
                  data.appointmentId;
                data.modules[i].children[j].children[k].moduleId =
                  data.modules[i].moduleId;
                data.modules[i].children[j].children[k].domainId =
                  data.modules[i].children[j].domainId;
                data.modules[i].children[j].children[k].domains =
                  data.modules[i].domains;
              }
          }
      }

    // Create chart
    var chart = am4core.create(
      'chartdiv',
      am4plugins_forceDirected.ForceDirectedTree
    );

    // Create series
    var series = chart.series.push(
      new am4plugins_forceDirected.ForceDirectedSeries()
    );
    //series.nodes.template.togglable = true;

    // series.colors.list = colorsList;
    // series.colors.wrap = false;
    //console.log(series.colors.list);
    // series.colors.list = [
    //     am4core.color("#F42B03"),
    //     am4core.color("#D84A05"),
    //     am4core.color("#EC7505")
    // ];
    // Set up data fields
    series.dataFields.value = 'value';
    series.dataFields.score = 'score';
    series.dataFields.name = 'name';
    series.dataFields.fullname = 'fullname';
    series.dataFields.children = 'children';
    series.dataFields.color = 'color';
    series.nodes.template.tooltipText = '{fullname}: [bold]{score}[/]';

    if (data?.modules != null) series.data = [...data.modules];
    // Add labels
    series.nodes.template.label.text = '{name}';
    series.fontSize = 9;
    series.minRadius = 15;
    series.maxRadius = 45;
    // series.nodes.template.outerCircle.filters.push(new am4core.DropShadowFilter());

    series.centerStrength = 0.5;
    series.manyBodyStrength = -30;
    series.links.template.distance = 1;
    series.links.template.strength = 1;

    // series.nodes.template.events.onAll(function(ev) {
    //     console.log("something happened ", ev);
    // }, this);
    series.maxLevels = 1;

    series.nodes.template.events.on(
      'hit',
      function (ev) {
        // console.log('clicked on ', ev)
        // console.log('clicked on ', ev.target)
        const node = ev.target;
        // const name = node.dataItem.name
        // console.log('name:  ', name)
        // console.log('dataItem:  ', node.dataItem)
        // console.log('_dataContext:  ', node.dataItem.dataContext)
        // console.log(
        //     'node.dataItem.dataContext.domainId:  ',
        //     node.dataItem.dataContext.domainId
        // )
        if (node.dataItem.dataContext.domainId == null) {
          setShowDetail(false);
          return;
        }
        setShowDetail(true);
        // var value = node.dataItem.value;
        // console.log("value: ", value);

        var co = {
          appointmentId: node.dataItem.dataContext.appointmentId,
          moduleId: node.dataItem.dataContext.moduleId,
          domainId: node.dataItem.dataContext.domainId,
          subdomainId: node.dataItem.dataContext.subdomainId,
          languageId: localStorage.getItem('i18nextLng'),
        };
        setScore(
          isNumeric(node.dataItem.dataContext.score)
            ? node.dataItem.dataContext.score
            : 0
        );

        // console.log('node.dataItem.dataContext.score', node.dataItem.dataContext.score);
        let domainItem = node.dataItem.dataContext.domains.filter(
          (f) => f.domainId === node.dataItem.dataContext.domainId
        )[0];
        if (domainItem == null) domainItem = {};

        OverviewService.createAutoReport(co).then(
          (r) => {
            //console.log('istek sonucu: Detay Sayfası: ', r);
            setDetailInfo({
              ...detailInfo,
              subdomainId: node.dataItem.dataContext.subdomainId ?? 0,
              domain: domainItem,
              contentList: r.data,
            });
            // console.log('detailInfo success', detailInfo);
          },
          (error) => {
            setDetailInfo({
              ...detailInfo,
              subdomainId: node.dataItem.dataContext.subdomainId ?? 0,
              domain: domainItem,
              contentList: [],
            });
            // console.log('detailInfo error', detailInfo);
          }
        );
      },
      this
    );

    // function myFunctionOn(ev) {
    //     console.log("clicked on myFunctionOn ", ev.target);
    // }
    // function myFunctionOff(ev) {
    //     console.log("clicked on myFunctionOff", ev.target);
    // }
    // series.nodes.template.events.on("hit", myFunctionOn, this);
    // series.nodes.template.events.off("hit", myFunctionOff, this);
    // return () => {
    //     x.dispose();
    // };
  };
  const getContent = (data, mainOrSub) => {
    let subdomainId = null;
    let appointmentId = null;
    let moduleId = null;
    let domainId = null;

    if (mainOrSub == 'MAIN') {
      appointmentId = data.domain.appointmentId;
      moduleId = data.domain.moduleId;
      domainId = data.domain.domainId;
      subdomainId = data.domain.subdomainId;

      setScore(
        isNumeric(detailInfo.domain.score) ? detailInfo.domain.score ?? 0 : 0
      );
    } else {
      appointmentId = data.appointmentId;
      moduleId = data.moduleId;
      domainId = data.domainId;
      subdomainId = data.subdomainId;
      setScore(isNumeric(data.score) ? data.score ?? 0 : 0);
    }
    var co = {
      appointmentId: appointmentId,
      moduleId: moduleId,
      domainId: domainId,
      subdomainId: subdomainId,
    };

    let domainItem =
      mainOrSub == 'MAIN'
        ? data.domain
        : data?.domains.filter((f) => f.domainId === data.domainId)[0];
    if (domainItem == null) domainItem = {};

    OverviewService.createAutoReport(co).then(
      (r) => {
        //console.log('istek sonucu: Detay Sayfası: ', r);
        setDetailInfo({
          ...detailInfo,
          subdomainId: subdomainId ?? 0,
          domain: domainItem,
          contentList: r.data,
        });
      },
      (error) => {
        setDetailInfo({
          ...detailInfo,
          subdomainId: subdomainId ?? 0,
          domain: domainItem,
          contentList: [],
        });
      }
    );
  };
  const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoading(true);
      axios
        .get('/appointments/userAppointments', {
          params: { userId: user.userId },
        })
        .then((res) => {
          const approvedAppointments = res.data?.filter(
            (appointment) => appointment.approved
          );
          // if (approvedAppointments != null && approvedAppointments.length > 0) {
          //   setAppointment(approvedAppointments[0].appointmentId)
          // }
          approvedAppointments?.map((item) => {
            item.startTime = item.start;
            item.endTime = item.end;
            item.fullApp =
              item.appointmentId +
              ' - ' +
              moment(item.date).format('Do MMMM YYYY') +
              ' - ' +
              item.start +
              ' / ' +
              item.end;
          });
          const app = approvedAppointments?.filter(
            (f) => f.appointmentId === appointmentId
          )[0];
          setAppointment(app);
          // console.log(app)
          // console.log(approvedAppointments)
          setApprovedAppointments(approvedAppointments);
        });
    }, 250);
    setTimeout(() => {
      OverviewService.getDomainModulScore(appointmentId)
        .then((res) => {
          // setAppointment(res.data);
          // console.log('getDomainModulScore:::::::::::::::::::::>>>', res.data);

          if (res.data != null) {
            setData(res.data);
            setLoading(false);
            getChart(res.data);
          } else {
            setDisplay(true);
            setLoading(false);
          }
        })
        .catch((e) => {
          setDisplay(true);
          setLoading(false);
        });

      // OverviewService.getAppointmentById(appointmentId).then((res) => {
      //   setAppointment(res.data)
      //   // console.log('setAppointment:::::::::::::::::::::>>>', res.data);
      // })
    }, 250);
  }, []);
  const onClick = (nodeId) => {
    if (nodeId === 100) {
      history.push('/overview/' + appointmentId);

      setTimeout(() => {
        getChart(data);
      }, 250);
    } else if (nodeId === 3) {
      history.push('/overview/' + appointmentId + '/socio-emotional');
    } else if (nodeId === 2) {
      history.push('/overview/' + appointmentId + '/physio-physical');
    } else if (nodeId === 1) {
      history.push('/overview/' + appointmentId + '/cognitive');
    } else if (nodeId === 9) {
      history.push('/overview/' + appointmentId + '/physical-module-measures');
    } else {
      history.push('/overview/' + appointmentId);
    }
  };

  const Image = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <img style={{ maxWidth: '600px' }} src={NotFoundImage} alt="" />
        <h1 style={{ fontWeight: 'bold' }}>{t('data_not_found')}.</h1>
      </div>
    );
  };
  const getCalendarDropdown = () => {
    return (
      <Dropdown
        style={{ width: '100%' }}
        value={appointment}
        options={approvedAppointments}
        appendTo={document.body}
        onChange={(e) => {
          setAppointment(e.value);
          history.push('/overview/' + e.value.appointmentId);
          window.location.reload();
        }}
        optionLabel="fullApp"
        placeholder={t('select_appointment')}
      />
    );
  };
  const getCalendar = getCalendarDropdown();

  return (
    <LayoutComponent>
      <Spinner loading={loading} />
      <div className="container">
        <div className="sidebar">
          <div className="user-infos" style={{ marginTop: 20 }}>
            <TreeView className={classes.root} defaultExpanded={['1']}>
              <StyledTreeItem
                nodeId="100"
                onClick={() => {
                  onClick(100);
                  setShowDetail(false);
                }}
                label={t('overall_score')}
              />
              <StyledTreeItem
                nodeId="3"
                onClick={() => onClick(3)}
                label={t('expert-review-socio-emotional')}
              />
              <StyledTreeItem
                nodeId="1"
                onClick={() => onClick(1)}
                label={t('expert-review-cognitive')}
              />
              <StyledTreeItem
                nodeId="2"
                onClick={() => onClick(2)}
                label={t('expert-review-physio')}
              >
                <StyledTreeItem
                  nodeId="9"
                  onClick={() => onClick(9)}
                  label={t('physical_module_measures')}
                />
              </StyledTreeItem>
            </TreeView>
          </div>

          <div className="user-infos" style={{ padding: '20px 0' }}>
            <Grid>
              <Card style={{ marginTop: 10 }}>
                <Box
                  style={{ margin: '10px 0', boxShadow: 'none !important' }}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ px: 0, py: 0, bgcolor: 'background.default' }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Numbers />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('appointment_number')}
                      secondary={`${appointmentId}`}
                    />
                  </ListItem>
                </Box>
                <Divider />
                <Box
                  style={{ margin: '10px 0' }}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ px: 0, py: 0, bgcolor: 'background.default' }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <LocationOn />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${
                        appointment?.station?.location?.city?.name ?? ''
                      } ${
                        appointment?.station?.location?.country?.name ?? ''
                      } ${appointment?.station?.location?.name ?? ''}-`}
                      secondary={`${appointment?.station?.name ?? ''}` ?? ''}
                    />
                  </ListItem>
                </Box>
                <Divider />
                <Box
                  style={{ margin: '10px 0' }}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ px: 0, py: 0, bgcolor: 'none' }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <EventAvailableIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${appointment?.startTime ?? ''} - ${
                        appointment?.endTime ?? ''
                      } `}
                      secondary={moment(appointment?.date).format(
                        'Do MMMM YYYY'
                      )}
                    />
                  </ListItem>
                </Box>
                <Divider />
                <Box
                  style={{ margin: '10px 0' }}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ px: 0, py: 0, bgcolor: 'none' }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <AssignmentIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      className="border_none"
                      primary={getCalendar}
                    />
                  </ListItem>
                </Box>
              </Card>
            </Grid>
          </div>
        </div>

        <Switch>
          <Route exact path={path}>
            <div className="reviews" style={{ width: '100%' }}>
              {display && <Image />}
              <div id="chartdiv" />
            </div>
            <div className="info-details flex"></div>
          </Route>

          <Route path={`${path}/socio-emotional`}>
            <div className="reviews">
              <SocioEmotional />
            </div>
          </Route>
          <Route path={`${path}/cognitive`}>
            <div className="reviews">
              <Cognitive />
            </div>
          </Route>
          <Route path={`${path}/physio-physical`}>
            <div className="reviews">
              <PhysioPhysical />
            </div>
          </Route>
          <Route path={`${path}/physical-module-measures`}>
            <div className="reviews">
              <PhysicalModuleMeasures />
            </div>
          </Route>
        </Switch>
      </div>
      <Switch>
        <Route exact path={path}>
          {showDetail ? (
            <div className="container mt-2">
              <div
                className="reviews"
                style={{ paddingLeft: 10, minHeight: 500 }}
              >
                <Grid container spacing={4}>
                  <Grid item={true} md={3}>
                    <div style={{ height: 250, overflow: 'auto' }}>
                      <Grid container>
                        <Grid
                          item={true}
                          md={9}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            // console.log(
                            //     'sub menu detailInfo on click =========>>',
                            //     detailInfo
                            // )
                            // setScore(isNumeric(detailInfo.domain.score) ? detailInfo.domain.score ?? 0 : 0);
                            getContent(detailInfo, 'MAIN');
                          }}
                          className={
                            'p-2 ' +
                            (detailInfo.subdomainId === 0 ? ' bold' : 'gray')
                          }
                        >
                          {detailInfo.domain.fullname}
                        </Grid>
                        <Grid
                          item={true}
                          md={3}
                          className={
                            'p-2 ' +
                            (detailInfo.subdomainId === 0 ? ' bold' : ' gray')
                          }
                        >
                          {detailInfo.domain.score}
                        </Grid>
                        {detailInfo.domain?.children?.map((item, index) => {
                          return (
                            <>
                              <Grid
                                key={index}
                                item={true}
                                md={9}
                                style={{ cursor: 'pointer' }}
                                className={
                                  'p-2 pl-5' +
                                  (item.subdomainId ===
                                    detailInfo.subdomainId &&
                                  detailInfo.subdomainId != null
                                    ? ' bold'
                                    : ' gray')
                                }
                                onClick={() => {
                                  // console.log('isNumeric(item.score)=========>>', isNumeric(item.score));
                                  // console.log('sub menu on clickk =========>>', item);
                                  getContent(item, 'SUB');
                                  // setScore(isNumeric(item.score) ? item.score : 0);
                                }}
                              >
                                {item.fullname}
                              </Grid>

                              <Grid
                                item={true}
                                md={3}
                                className={
                                  'p-2' +
                                  (item.subdomainId === detailInfo.subdomainId
                                    ? ' bold'
                                    : ' gray')
                                }
                              >
                                {item.score}
                              </Grid>
                            </>
                          );
                        })}
                      </Grid>
                    </div>
                    <Grid item={true} md={12}>
                      <div style={{ width: 300 }}>
                        <div>
                          <div
                            style={{ position: 'absolute', left: 50 }}
                            className={
                              score >= 150 && score < 300 ? 'bold' : 'gray'
                            }
                          >
                            {t('overview_titles.POOR')}
                          </div>
                          <div
                            style={{ position: 'absolute', left: 95 }}
                            className={
                              score >= 450 && score < 600 ? 'bold' : 'gray'
                            }
                          >
                            {t('overview_titles.SATISFACTORY')}
                          </div>
                          <div
                            style={{ position: 'absolute', left: 191 }}
                            className={
                              score >= 750 && score < 900 ? 'bold' : 'gray'
                            }
                          >
                            {t('overview_titles.PROFESSIONAL')}
                          </div>
                        </div>
                        <div style={{ width: 300 }}>
                          <div
                            style={{
                              marginLeft: 10,
                              width: 1,
                              position: 'absolute',
                              height: 43,
                              borderLeft: '2px solid black',
                              left: (score * 300) / 1000,
                              top: 286,
                            }}
                          ></div>
                          <img
                            src="../../assets/renk.jpg"
                            style={{ width: 300, marginTop: '20px' }}
                          />
                        </div>
                        <div
                          style={{ position: 'absolute', left: 15 }}
                          className={score > 0 && score < 150 ? 'bold' : 'gray'}
                        >
                          {t('overview_titles.NUI')}
                        </div>
                        <div
                          style={{ position: 'absolute', left: 65 }}
                          className={
                            score >= 300 && score < 450 ? 'bold' : 'gray'
                          }
                        >
                          {t('overview_titles.MEDIOCRE')}
                        </div>
                        <div
                          style={{ position: 'absolute', left: 160 }}
                          className={
                            score >= 600 && score < 750 ? 'bold' : 'gray'
                          }
                        >
                          {t('overview_titles.GOOD')}
                        </div>
                        <div
                          style={{ position: 'absolute', left: 275 }}
                          className={score >= 900 ? 'bold' : 'gray'}
                        >
                          {t('overview_titles.ELITE')}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item={true} md={9}>
                    {/*<p>{detailInfo.name} / {detailInfo.value} / {detailInfo.deger}</p>*/}
                    {detailInfo.contentList.map((item, index) => {
                      return <p key={'content' + index}>{item.text}</p>;
                    })}
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            ''
          )}
        </Route>
      </Switch>
    </LayoutComponent>
  );
};

export default withTranslation()(Overview);
