import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import 'moment/locale/tr';
import 'react-svg-radar-chart/build/css/index.css';
import './report.css';
import { Link, useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import Overview from './expert_reviews/Overview';
import LayoutComponent from '../components/LayoutComponent';
import { Container } from '@material-ui/core';
import { useStyles } from '@material-ui/pickers/views/Year/Year';
import AuthenticationService from '../service/AuthenticationService';
import { Dropdown } from 'primereact/dropdown';
import { Card, Divider, Grid } from '@material-ui/core';
import Box from '@mui/material/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { Numbers } from '@mui/icons-material';
import ListItemText from '@material-ui/core/ListItemText';
import { LocationOn } from '@material-ui/icons';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import moment from 'moment';
import OverviewService from '../service/OverviewService';
import logoImage from '../assets/images/neOAUVRALogo.png';
import AssignmentIcon from '@mui/icons-material/Assignment';
import './homeuser.css';
import { withTranslation } from 'react-i18next';
import ScoreModules from '../components/ScoreModules';
import * as am5 from '@amcharts/amcharts5/index';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import UserInfoCard from '../components/UserScoreDetails/UserInfoCard';
import SelectAppointmentCard from '../components/UserScoreDetails/SelectAppointmentCard';
import ScoreDonutChart from '../components/UserScoreDetails/ScoreDonutChart';
import Spinner from '../components/common/Spinner';
import { Toast } from 'primereact/toast';
import { useSnackbar } from 'notistack';

const colors = [
  { forcedTreeColor: '#46c5e9' },
  { forcedTreeColor: '#657079' },
  { forcedTreeColor: '#de2348' },
];

const HomeUser = ({ t }) => {
  const classes = useStyles();
  let initialState = {
    user: { firstname: '', lastname: '', gender: '', age: 0 },
    score: 0,
    modules: [
      { name: '', description: '', score: 0, domains: [{ subdomains: [{}] }] },
    ],
  };
  let history = useHistory();
  const toast = useRef(null);

  const params = useParams();
  const appointmentId = params.id;
  const [data, setData] = useState(initialState);
  const [module, setModule] = useState({});
  const [scores, setScores] = useState({
    cognitiveScore: 0,
    physicalScore: 0,
    socioScore: 0,
  });
  const [showOverview, setShowOverview] = useState(false);
  const [selectedOverviewModule, setSelectedOverviewModule] = useState(null);
  const [open, setOpen] = useState(true);
  const [approvedAppointments, setApprovedAppointments] = useState([]);
  const [appointment, setAppointment] = useState(null);
  const getUserInfo = AuthenticationService.getLoggedInUser();
  const [userInfo, setUserInfo] = useState({});
  const [selectedApppointment, setSelectedAppointment] = useState({});
  const [loading, setLoading] = useState(false);
  const lang = localStorage.getItem('i18nextLng');
  const [isIk, setIsIk] = useState(false);
  const [isDisplayDonutChart, setIsDisplayDonutChart] = useState(true);
  const [sarfReportList, setSarfReportList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setUserInfo(getUserInfo);
    axios
      .get('/reports/data')
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getUserInfo.userId]);

  useEffect(() => {
    if (history.location.search.includes('guid')) {
      setIsIk(true);
    } else if (
      !history.location.search.includes('guid') &&
      AuthenticationService.isUser() &&
      history.location.pathname.includes('/ViewSharedReport/')
    ) {
      history.push('/404');
    }
  }, [history.location.search]);

  useEffect(() => {
    if (!isNaN(appointmentId)) {
      appointmentInfo(appointmentId);
      getModules(appointmentId);
      fetchUserInfo();
    } else {
      getAppointmentId();
    }
  }, [appointmentId]);

  useEffect(() => {
    if (appointment || appointmentId) {
      axios
        .get('/lookup/getSarfReportListByAppointmentId', {
          headers: {
            appointmentId: appointmentId ?? appointment,
          },
        })
        .then((res) => {
          setSarfReportList([...new Set(res.data)]);
        })
        .catch((err) => {
          console.log('err :>> ', err);
        });
    }
  }, [appointment, appointmentId]);

  const fetchUserInfo = async () => {
    let response = await axios.get(
      '/users/getUserByAppointmentId/' + appointmentId
    );
    response.data.body.fullName =
      strUpperCase(response.data.body.name) +
      ' ' +
      strUpperCase(response.data.body.surname);
    setUserInfo(response.data.body);
  };

  const strUpperCase = (text) => {
    let wordToArray = text.split(' ');
    wordToArray = wordToArray.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return wordToArray.join(' ');
  };

  const getAppointmentId = async () => {
    const response = await axios.get('/appointments/userAppointments', {
      params: { userId: getUserInfo.userId },
    });

    if (response.data) {
      const approvedAppointments = response.data.filter(
        (appointment) => appointment.approved
      );

      if (AuthenticationService.isUser() || isIk) {
        setIsDisplayDonutChart(approvedAppointments[0]?.expertApproved);
      }
      if (approvedAppointments != null && approvedAppointments.length > 0) {
        appointmentInfo(approvedAppointments[0].appointmentId);
        setAppointment(approvedAppointments[0].appointmentId);
        await getModules(approvedAppointments[0].appointmentId);
      }
      approvedAppointments?.map((item) => {
        item.fullApp =
          item.appointmentId +
          ' - ' +
          moment(item.date).lang(lang).format('L') +
          ' - ' +
          item.start +
          ' / ' +
          item.end;
      });
      setApprovedAppointments(approvedAppointments);
    }
  };

  const appointmentInfo = (id) => {
    OverviewService.getAppointmentById(id).then((res) => {
      const approvedAppointments = res.data;
      approvedAppointments.date = moment(approvedAppointments.date)
        .lang(lang)
        .format('L');
      approvedAppointments.fullApp =
        approvedAppointments.id +
          ' - ' +
          approvedAppointments.date +
          ' - ' +
          approvedAppointments.startTime ??
        '' + ' / ' + approvedAppointments.endTime ??
        '';
      setSelectedAppointment(approvedAppointments);
      if (AuthenticationService.isUser() || isIk) {
        setIsDisplayDonutChart(approvedAppointments.expertApproved);
      }
    });
  };

  const getModules = async (appointmentId) => {
    const response = await OverviewService.getDomainModulScore(appointmentId);
    const domainAvg = await axios.get('/scores/getScoresAverages/2');
    const subDomainAvg = await axios.get('/scores/getScoresAverages/1');
    if (response.data != null) {
      for (let item of response.data.modules) {
        for (let domain of item.domains) {
          const avarageScores = domainAvg.data.find(
            (f) => f.domainId == domain.domainId && f.moduleId == item.moduleId
          );
          if (avarageScores != null) {
            domain.avarageScore = parseInt(avarageScores.avgScore);
          }

          for (let subdomain of domain.subdomains) {
            const points = subDomainAvg.data.find(
              (f) =>
                f.subdomainId == subdomain.subdomainId &&
                f.domainId == domain.domainId &&
                f.moduleId == item.moduleId
            );
            if (points != null) {
              subdomain.avgScore = parseInt(points.avgScore);
            }
          }
        }
      }
      setModule(response.data);
      var cognitiveScore =
        response.data?.modules.filter((f) => f.moduleId === 1)[0]
          ?.moduleScore ?? 0;
      var physicalScore =
        response.data?.modules.filter((f) => f.moduleId === 2)[0]
          ?.moduleScore ?? 0;
      var socioScore =
        response.data?.modules.filter((f) => f.moduleId === 3)[0]
          ?.moduleScore ?? 0;
      setScores({ cognitiveScore, physicalScore, socioScore });
    }
  };
  const getCalendarDropdown = () => {
    return (
      <Dropdown
        value={appointment || appointmentId}
        options={approvedAppointments}
        appendTo={document.body}
        onChange={(e) => {
          setAppointment(e.value);
          getModules(e.value);
        }}
        optionValue='appointmentId'
        optionLabel='fullApp'
        placeholder={t('select_appointment')}
      />
    );
  };
  const getCalendar = getCalendarDropdown();

  const showError = () => {
    toast.current.show({
      severity: 'error',
      summary: 'Error Message',
      detail: `${t('enqueueSnackbar_message.user_update_error')}`,
      life: 3000,
    });
  };

  const showWarning = () => {
    enqueueSnackbar(`${t('enqueueSnackbar_message.please_radio_select')}`, {
      variant: 'warning',
    });
  };

  const serverError = () => {
    toast.current.show({
      severity: 'error',
      summary: 'Server Error',
      detail: `${t('Unable to connect to the server, please try again later')}`,
      life: 3000,
    });
  };
  const showSuccess = () => {
    toast.current.show({
      severity: 'success',
      summary: 'Success Message',
      detail: `${t(
        'Your report request has been received. It will be prepared and sent to your registered e-mail address as a password protected pdf file within 2 hours at most. In addition, the password required to open the report will be sent to your registered mobile phone number as an SMS.'
      )}`,
      life: 3000,
    });
  };

  const Summary = () => (
    <>
      <Toast
        ref={toast}
        baseZIndex={0}
      />

      <Spinner
        text={t('Please wait while your report is being prepared')}
        loading={loading}
      />
      <LayoutComponent
        navbar={!isNaN(appointmentId) ? appointmentId : ''}
        IKNavbar={isIk}
      >
        <div className='container-md'>
          <div className='sidebar-user'>
            <UserInfoCard user={userInfo} />
            <SelectAppointmentCard
              showSuccess={showSuccess}
              showWarning={showWarning}
              showError={showError}
              serverError={serverError}
              appointment={!isNaN(appointmentId) ? appointmentId : appointment}
              getCalendar={getCalendar}
              selectedApppointment={selectedApppointment}
              isAdmin={AuthenticationService.isAdmin() ? 'isAdmin' : ''}
              isIk={isIk}
              isUser={isNaN(appointmentId) ? 'isUser' : ''}
              setLoading={setLoading}
              isDisplayDonutChart={isDisplayDonutChart}
              sarfReportList={sarfReportList}
            />
          </div>
          <div className='center-page'>
            <ScoreDonutChart
              module={module}
              appointment={!isNaN(appointmentId) ? appointmentId : appointment}
              notUser={!isNaN(appointmentId) ? 'user' : ''}
              isDisplayDonutChart={isDisplayDonutChart}
            />
          </div>
          <ScoreModules
            appointmentId={!isNaN(appointmentId) ? appointmentId : appointment}
            module={module}
            scores={!isDisplayDonutChart ? {} : scores}
            notUser={!isNaN(appointmentId) ? 'user' : ''}
            isDisplayDonutChart={isDisplayDonutChart}
          />
        </div>
      </LayoutComponent>
    </>
  );

  return showOverview ? <Overview /> : <Summary />;
};
export default withTranslation()(HomeUser);
