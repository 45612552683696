import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Toast } from 'primereact/toast';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import PeopleIcon from '@material-ui/icons/People';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventIcon from '@material-ui/icons/Event';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Menu from '@material-ui/core/Menu';
import AuthenticationService from '../service/AuthenticationService';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import store from './../Store';
import Avatar from '@material-ui/core/Avatar';
import axios from 'axios';
import './navbar.css';
import { Dropdown } from 'primereact/dropdown';
import i18next, { t } from 'i18next';
import { useTranslation, withTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { IconFlagTR, IconFlagDE, IconFlagUS } from 'material-ui-flags';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import FormInput from './common/FormInput';
import { MainButton } from './common/MainButton';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	drawer: {
		// background: '#001529',
		background: '#0A2365',
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: {
		// backgroundColor: '#001529',
		backgroundColor: '#0A2365',

		[theme.breakpoints.down('md')]: {
			// paddingInline: 250,

			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
		},
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.up('lg')]: {},
		[theme.breakpoints.up('xl')]: {},
		[theme.breakpoints.up('xxl')]: {},
	},
	image: {
		width: 200,
		[theme.breakpoints.down('md')]: {
			width: 100,
		},
		[theme.breakpoints.down('lg')]: {
			width: 150,
		},
	},
	logoWrapper: {
		float: 'left',
		width: 200,
		height: '100%',
		marginLeft: 50,
		marginRight: 50,

		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
			marginLeft: 10,
			marginRight: 10,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		display: 'inline-block',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
		[theme.breakpoints.down('lg')]: {
			float: 'left',
		},
	},
	drawerPaper: {
		width: drawerWidth,
		// background: '#001529',
		background: '#0A2365',
		color: 'white',
	},
	drawerProfile: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: theme.spacing(2),
		background: theme.palette.primary.dark,
	},
	// necessary for content to be below app bar
	toolbar: {
		[theme.breakpoints.down('lg')]: {
			flexGrow: 1,
			alignItems: 'center',
			justifyContent: 'center',
		},
		width: '100%',
		maxWidth: '1755px',
		margin: '0 auto',
		[theme.breakpoints.up('lg')]: {
			paddingRight: '69px !important',
		},
	},
	muiIcon: {
		padding: 2,
	},
	title: { flexGrow: 1 },
	button: { textTransform: 'none' },
	tab: { textTransform: 'none', fontSize: theme.typography.h6.fontSize },
}));

function ElevationScroll(props) {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window() : undefined,
	});

	return React.cloneElement(children, {
		elevation: trigger ? 4 : 0,
	});
}

const NavBarItem = {
	'/': 0,
	'/randevu-olustur': 1,
	'/onizlem-formu': 2,
	'/rapor-paylasimlari': 3,
};

const Navbar = (props) => {
	const { t } = useTranslation();
	let location = useLocation();
	const { window } = props;
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileOpen, setMobileOpen] = React.useState(true);
	const [userAvatar, setUserAvatar] = React.useState(null);
	const [passwordModal, setPasswordModal] = useState(false);
	const [value, setValue] = React.useState(NavBarItem[location.pathname]);
	const snapshot = useSnapshot(store);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const menuOpen = Boolean(anchorEl);
	const open = Boolean(anchorEl);
	let history = useHistory();

	const Schema = Yup.object().shape({
		password: Yup.string()
			.required(`${t('schema_shape.empty_password')}`)
			.min(8, `${t('schema_shape.min_password')}`),
		confirmPassword: Yup.string()
			.required(`${t('schema_shape.empty_password')}`)
			.oneOf(
				[Yup.ref('password'), null],
				`${t('schema_shape.match_password')}`
			),
	});
	const { enqueueSnackbar } = useSnackbar();

	const { handleSubmit, control } = useForm({
		resolver: yupResolver(Schema),
	});

	const user = AuthenticationService.getLoggedInUser();
	const fullName = `${user.fullName}`;
	const toast = useRef(null);

	// useEffect(() => {
	//   try {
	//     AuthenticationService.getImageUrlByUserId(user.userId)
	//       .then((res) => {
	//         setUserAvatar(
	//           axios.defaults.baseURL + `/users/images/${user.userId}`
	//         );
	//       })
	//       .catch((err) => {});
	//   } catch {}
	// }, []);

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const confirm = (id) => {
		confirmDialog({
			message: `${t('user_delete_question')}`,
			header: `${t('appointment_alert_messages.confirmation')}`,
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
			accept: () => accept(id),
			reject: () => reject(),
		});
	};

	const accept = (id) => {
		deleteUser(id);
		setTimeout(() => {
			AuthenticationService.logout();
			history.push('/giris');
		}, 3000);
	};

	const reject = (id) => {
		toast.current.show({
			severity: 'warn',
			summary: `${t('appointment_alert_messages.denied')}`,
			detail: `${t('appointment_alert_messages.action_declined')}`,
			life: 3000,
		});
	};
	const error = (err) => {
		toast.current.show({
			severity: 'error',
			summary: `${t('appointment_alert_messages.short_error')}`,
			detail: `${err.response.data}`,
			life: 3000,
		});
	};
	const deleteUser = (id) => {
		axios
			.delete(`/users/deleteUserHimSelf/${id}`)
			.then(() => {
				toast.current.show({
					severity: 'info',
					summary: `${t('appointment_alert_messages.congratulations')}`,
					detail: `${t('appointment_alert_messages.delete_user_success')}`,
					life: 3000,
				});
			})
			.catch((err) => error(err));
	};

	const handleLogout = () => {
		AuthenticationService.logout();
		history.push('/giris');
	};

	const handleChange = (newValue, _) => {
		setValue(newValue);
	};

	useEffect(() => {
		let items = 0;
		if (value === undefined) {
			setValue(items);
		}
	}, [value]);

	const drawer = (
		<div>
			<div className={classes.drawerProfile}>
				{userAvatar ? (
					<Avatar alt='image' src={userAvatar} />
				) : (
					<Avatar style={{ textTransform: 'uppercase' }}>{userAvatar}</Avatar>
				)}
				<Typography variant='h6'>{fullName}</Typography>
				<Typography
					variant='body2'
					gutterBottom
					style={{ textAlign: 'center' }}
				>
					{user.email}
				</Typography>
			</div>

			<Divider />
			<List>
				{AuthenticationService.isAdmin() && (
					<ListItem component={Link} to='/' button key='home'>
						<ListItemIcon>
							<HomeIcon color='primary' />
						</ListItemIcon>
						<ListItemText primary={t('home_page')} />
					</ListItem>
				)}
				{AuthenticationService.isAdmin() && (
					<ListItem component={Link} to='/panel' button key='Panel'>
						<ListItemIcon>
							<DashboardIcon color='primary' />
						</ListItemIcon>

						<ListItemText primary={t('dashboard')} />
					</ListItem>
				)}

				<ListItem
					component={Link}
					to='/onizlem-formu'
					button
					key='demographics'
				>
					<ListItemIcon>
						<PeopleIcon color='primary' />
					</ListItemIcon>
					<ListItemText primary={t('preview_form')} />
				</ListItem>
				<ListItem component={Link} to='/uzman-gorusu' button key='expertreview'>
					<ListItemIcon>
						<EventIcon color='primary' />
					</ListItemIcon>
					<ListItemText primary={t('messages.event')} />
				</ListItem>
			</List>
			<Divider />
			<div
				style={{
					position: 'fixed',
					bottom: 0,
					width: drawerWidth,
					textAlign: 'center',
					paddingBottom: 10,
				}}
			>
				<List>
					<ListItem onClick={handleLogout} button key='logout'>
						<ListItemIcon>
							<ExitToAppIcon color='primary' />
						</ListItemIcon>
						<ListItemText primary={t('log_out')} />
					</ListItem>
				</List>
			</div>
		</div>
	);
	const selected = localStorage.getItem('i18nextLng') || 'en';
	const [lang, setLang] = useState(selected);

	const languageItems = [
		{ label: 'English', value: 'en', code: 'US', flag: `${IconFlagUS}` },
		{ label: 'Türkçe', value: 'tr', code: 'TR', flag: `${IconFlagTR}` },
	];
	const onLangChange = (e) => {
		setLang(e.value);
		i18next.changeLanguage(e.value);
		history.go(0);
	};

	const countryOptionTemplate = (option) => {
		return (
			<div className='country-item'>
				{option.value === 'en' ? (
					<IconButton className={classes.muiIcon}>
						<IconFlagUS />
					</IconButton>
				) : (
					<IconButton className={classes.muiIcon}>
						<IconFlagTR />
					</IconButton>
				)}
				<div>{option.label}</div>
			</div>
		);
	};
	const selectedCountryTemplate = (option, props) => {
		if (option) {
			return (
				<div className='country-item country-item-value'>
					{option.value === 'en' ? (
						<IconButton className={classes.muiIcon}>
							<IconFlagUS />
						</IconButton>
					) : (
						<IconButton className={classes.muiIcon}>
							<IconFlagTR />
						</IconButton>
					)}

					<div>{option.label}</div>
				</div>
			);
		}
	};

	const passwordModalOpen = () => {
		setPasswordModal(true);
		setAnchorEl(null);
	};

	const PasswordChangeFormModal = () => {
		const handleClosePasswordModal = () => {
			setPasswordModal(false);
		};

		const changePassword = (data) => {
			const payload = {
				password: data.password,
				userId: user.userId,
			};
			axios
				.post('/users/changePassword', payload)
				.then(() => {
					enqueueSnackbar(`${t('enqueueSnackbar_message.change_password')}`, {
						variant: 'success',
					});
				})
				.catch((err) =>
					enqueueSnackbar(err?.response?.data?.message, {
						variant: 'error',
					})
				);
		};

		return (
			<div>
				<Dialog open={passwordModal} onClose={handleClosePasswordModal}>
					<DialogTitle style={{ textAlign: 'center' }}>
						{t('changed_password')}
					</DialogTitle>
					<DialogContent>
						<form
							className={classes.form}
							noValidate
							onSubmit={handleSubmit(changePassword)}
						>
							<Controller
								name='password'
								control={control}
								render={(props) => (
									<FormInput
										{...props}
										margin='dense'
										type='password'
										required
										label={t('password')}
									/>
								)}
							/>
							<Controller
								name='confirmPassword'
								control={control}
								render={(props) => (
									<FormInput
										{...props}
										type='password'
										margin='dense'
										required
										label={t('repeat_password')}
									/>
								)}
							/>
							<div className='d-flex justify-content-around mt-5'>
								<MainButton
									type='submit'
									variant='contained'
									color='primary'
									className={classes.submit}
								>
									{t('UPDATE')}
								</MainButton>
								<MainButton
									type='button'
									variant='contained'
									color='primary'
									className={classes.submit}
									onClick={handleClosePasswordModal}
								>
									{t('GIVE_UP')}
								</MainButton>
							</div>
						</form>
					</DialogContent>
				</Dialog>
			</div>
		);
	};
	const container =
		window !== undefined ? () => window().document.body : undefined;
	return (
		<>
			<Toast ref={toast} />
			<ConfirmDialog id='removeUser' />
			<CssBaseline />
			<PasswordChangeFormModal />
			<ElevationScroll {...props}>
				<>
					<AppBar className={classes.appBar}>
						<IconButton
							color='inherit'
							aria-label='open drawer'
							edge='start'
							onClick={handleDrawerToggle}
							className={classes.menuButton}
						>
							<MenuIcon />
						</IconButton>
						<Toolbar className={classes.toolbar}>
							<div className={classes.logoWrapper}>
								<img
									id='company-logo'
									alt='NeoAuvra logo'
									src='/logo.png'
									className={classes.image}
								/>
							</div>

							<Hidden smDown>
								<Tabs value={value} onChange={handleChange}>
									{!AuthenticationService.isAdmin() && (
										<Tab
											className={classes.tab}
											component={Link}
											to='/'
											label={t('home_page')}
										/>
									)}
									{AuthenticationService.isDoctor() && (
										<Tab
											className={classes.tab}
											component={Link}
											to='/uzman-gorusu'
											label={t('expert_opinion')}
										/>
									)}
									{AuthenticationService.isAdmin() && (
										<Tab
											className={classes.tab}
											component={Link}
											to='/panel'
											label={t('dashboard')}
										/>
									)}
									{/*{AuthenticationService.isDoctor() &&*/}
									{/*  !AuthenticationService.isAdmin() && (*/}
									{/*    <Tab*/}
									{/*      className={classes.tab}*/}
									{/*      component={Link}*/}
									{/*      to="/panel"*/}
									{/*      label="Randevu"*/}
									{/*    />*/}
									{/*  )}*/}

									{/*{!AuthenticationService.isDoctor() &&*/}
									{/*  !AuthenticationService.isAdmin() && (*/}
									{/*    <Tab*/}
									{/*      className={classes.tab}*/}
									{/*      component={Link}*/}
									{/*      to="/expert-physio"*/}
									{/*      label="Physio"*/}
									{/*    />*/}
									{/*  )}*/}
									{AuthenticationService.isDoctor() && (
										<Tab
											className={classes.tab}
											component={Link}
											to='/doktor-demografik-bilgiler'
											label={t('doctor_demographic_information')}
										/>
									)}
									{AuthenticationService.isUser() && (
										<Tab
											className={classes.tab}
											component={Link}
											to='/randevu-olustur'
											label={t('messages.event')}
										/>
									)}
									{AuthenticationService.isUserLoggedIn() && (
										<Tab
											className={classes.tab}
											component={Link}
											to='/onizlem-formu'
											label={t('preview_form')}
										/>
									)}
									{AuthenticationService.isUser() && (
										<Tab
											className={classes.tab}
											component={Link}
											to='/rapor-paylasimlari'
											label={t('Report Sharing Lists')}
										/>
									)}
								</Tabs>
								<div className={classes.root} />

								<div>
									<Button
										color='inherit'
										size='large'
										onClick={handleMenu}
										className={classes.button}
										startIcon={
											userAvatar ? (
												<Avatar alt='image' src={userAvatar} />
											) : (
												<Avatar style={{ textTransform: 'uppercase' }}>
													{user?.name[0] + user?.surname[0]}
												</Avatar>
											)
										}
										endIcon={<ArrowDropDownRoundedIcon />}
									>
										{fullName}
									</Button>
									<Menu
										id='menu-appbar'
										getContentAnchorEl={null}
										anchorEl={anchorEl}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left',
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'left',
										}}
										open={open}
										onClose={handleClose}
									>
										<MenuItem onClick={passwordModalOpen}>
											{t('change_password')}
										</MenuItem>
										<MenuItem onClick={() => confirm(user.userId)}>
											{t('remove_my_user')}
										</MenuItem>
										<MenuItem onClick={handleLogout}>{t('log_out')}</MenuItem>
									</Menu>
								</div>
							</Hidden>
							<div className='country'>
								<Dropdown
									appendTo='self'
									value={lang}
									options={languageItems}
									onChange={onLangChange}
									optionLabel='label'
									placeholder={t('select_language')}
									valueTemplate={selectedCountryTemplate}
									itemTemplate={countryOptionTemplate}
								/>
							</div>
						</Toolbar>
					</AppBar>
					<nav className={classes.drawer}>
						<Hidden smUp implementation='css'>
							<Drawer
								container={container}
								variant='temporary'
								anchor='left'
								open={!mobileOpen}
								onClose={handleDrawerToggle}
								classes={{
									paper: classes.drawerPaper,
								}}
								ModalProps={{
									keepMounted: true, // Better open performance on mobile.
								}}
							>
								{drawer}
							</Drawer>
						</Hidden>
					</nav>
				</>
			</ElevationScroll>
		</>
	);
};
export default withTranslation()(Navbar);
