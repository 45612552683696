import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import UserService from '../service/UserService';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import parsePhoneNumber from 'libphonenumber-js';
import { DropzoneDialog } from 'material-ui-dropzone';
import { Checkbox } from 'primereact/checkbox';
import Spinner from '../components/common/Spinner';

import { classNames } from 'primereact/utils';
import {
	Button,
	CssBaseline,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Box,
	Grid,
	Tooltip,
} from '@material-ui/core';
import axios from 'axios';
import * as Yup from 'yup';
import 'yup-phone';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { MainButton } from '../components/common/MainButton';
import FormInput from '../components/common/FormInput';
import PhoneInput from '../components/common/PhoneInput';
import SelectInput from './common/SelectInput';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import './userInfo.css';
import { useTranslation } from 'react-i18next';
import { ErrorSharp } from '@mui/icons-material';
import i18next from 'i18next';
import { IconFlagTR, IconFlagDE, IconFlagUS } from 'material-ui-flags';
import IconButton from '@material-ui/core/IconButton';
import CustomerService from '../service/CustomerService';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import FormInputGrid from './common/FormInputGrid';
import PhoneInputEn from './common/PhoneInputEn';
import StationService from '../service/StationService';
import AuthenticationService from '../service/AuthenticationService';
const useStyles = makeStyles((theme) => ({
	card: {
		padding: 20,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	commChannels: {
		marginTop: theme.spacing(2),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	register: {
		'&:hover': {
			textDecoration: 'underline',
		},
		textTransform: 'none',
	},
}));

export const UserPreInfo = () => {
	const toast = useRef(null);
	const { t } = useTranslation();
	const [user, setUser] = useState({});
	const [customers, setCustomers] = useState([]);
	const [customer, setCustomer] = useState({});

	const params = useParams();
	var userById = params.id;
	const { enqueueSnackbar } = useSnackbar();
	let history = useHistory();
	const classes = useStyles();

	const selected = localStorage.getItem('i18nextLng') || 'en';
	const [open, setOpen] = useState(false);
	const [open1, setOpen1] = useState(false);
	const [userRoles, setUserRoles] = useState([]);
	const [file, setFile] = useState(null);
	const [role, setRole] = useState({});
	const [isDoctor, setIsDoctor] = useState(false);
	const [testPackages, setTestPackages] = useState([]);
	const [testPackage, setTestPackage] = useState({});
	const [testPackageTypes, setTestPackageTypes] = useState([]);
	const [testPackageType, setTestPackageType] = useState({});
	const [displayResponsive, setDisplayResponsive] = useState(false);
	const [lang, setLang] = useState(selected);
	const [station, setStation] = useState([]);
	const [stations, setStations] = useState([]);
	const [loading, setLoading] = useState(false);
	const updateDoctor = () => setIsDoctor(!isDoctor);

	const userInfo = JSON.parse(localStorage.authenticatedUser);

	const Schema = Yup.object().shape({
		name: Yup.string().required(`${t('schema_shape.not_empty')}`),
		surname: Yup.string().required(`${t('schema_shape.not_empty')}`),
		email: Yup.string()
			.email(`${t('schema_shape.valid_email')}`)
			.required(`${t('schema_shape.not_empty')}`),
		mobilePhone: Yup.string().required(`${t('schema_shape.not_empty')}`),
		stationaryPhone: Yup.string().notRequired().nullable(),
		emailRepeat: Yup.string()
			.email(`${t('schema_shape.valid_email')}`)
			.required(`${t('schema_shape.not_empty')}`)
			.oneOf([Yup.ref('email')], `${t('email_doesnt_match')}`),
		webUILoginNotAllowed: Yup.boolean().notRequired().nullable(),
	});

	const {
		handleSubmit,
		control,
		register,
		formState: { errors },
		clearErrors,
		watch,
		setError,
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(Schema),
	});

	const onSubmit = (registerForm) => {
		setLoading(true);
		const formData = new FormData();

		const image = file;
		delete registerForm['image'];

		const phoneNumber = parsePhoneNumber('+' + registerForm['mobilePhone']);
		if (phoneNumber) {
			registerForm['mobilePrefix'] = parseInt(phoneNumber.countryCallingCode);
			registerForm['mobilePhone'] = phoneNumber.nationalNumber;
		}

		if (registerForm['stationaryPhone']) {
			const stationaryPhone = parsePhoneNumber(
				'+' + registerForm['stationaryPhone']
			);
			if (stationaryPhone) {
				registerForm['mobilePhone'] = parseInt(stationaryPhone.nationalNumber);
			}
		}
		registerForm['assessmentPackages'] = testPackage;
		registerForm['preferredLanguage'] = lang;
		// registerForm['customerId'] = customer.id;
		// registerForm['customer'] = customer;
		registerForm['customer'] = { id: customer.id };
		registerForm['name'] =
			registerForm['name'].charAt(0).toUpperCase() +
			registerForm['name'].slice(1).toLowerCase();
		registerForm['surname'] =
			registerForm['surname'].charAt(0).toUpperCase() +
			registerForm['surname'].slice(1).toLowerCase();

		if (station.location === null || station.location === undefined) {
			setLoading(false);
			enqueueSnackbar(`${t('enqueueSnackbar_message.select_hub')}`, {
				variant: 'error',
			});
			return;
		} else {
			registerForm['location'] = station.location;
		}

		const registerFormData = new Blob([JSON.stringify(registerForm)], {
			type: 'application/json',
		});
		if (!registerForm.assessmentPackages.id) {
			setLoading(false);
			enqueueSnackbar(`${t('enqueueSnackbar_message.please_select_package')}`, {
				variant: 'error',
			});
			return;
		}

		axios
			.post('/users/preRegisterForAdmin', registerFormData, {
				headers: { 'Content-Type': 'application/json' },
			})
			.then(() => {
				enqueueSnackbar(
					`${t('enqueueSnackbar_message.user_pre_register_success')}`,
					{
						variant: 'success',
						autoHideDuration: 2500,
					}
				);
				setTimeout(() => {
					setLoading(false);
					history.push({
						pathname: '/panel/onkayitli-kullanicilar',
						state: { verificationPending: true },
					});
				}, 1000);
			})
			.catch((err) => {
				setLoading(false);
				enqueueSnackbar(err?.response?.data?.message, {
					variant: 'error',
				});
			});
	};

	const pStyle = {
		fontSize: '15px',
		textAlign: 'center',
	};

	const get = (userById) => {
		if (userById != null && userById.length > 0) {
			UserService.getUserById(userById).then((res) => {
				// console.log('data:::::::::::', res.data);
				setUser(res.data);
			});
		}
	};

	useEffect(() => {
		getAllTestPackageTypes();
		CustomerService.getCustomerList().then((r) => {
			setCustomers(r.data);
		});
	}, []);

	React.useEffect(() => {
		const subscription = watch((value) => {
			if (value.email === value.emailRepeat) {
				clearErrors('emailRepeat');
			} else {
				setError('emailRepeat', {
					type: 'oneOf',
					message: `${t('email_doesnt_match')}`,
				});
			}
		});
		return () => subscription.unsubscribe();
	}, [clearErrors, setError, watch, errors]);

	const handleOnClick = useCallback(
		() => history.push('/panel/onkayitli-kullanicilar'),
		[history]
	);

	const handleChangeTestPackageType = (e) => {
		setTestPackageType(e.target.value);
		getAllTestPackagesByTypes(e.target.value.id);
	};

	const handleSetTestPackage = (e) => {
		setTestPackage(e.target.value);
	};

	const getAllTestPackageTypes = async () => {
		try {
			const { data } = await axios.get('/users/getAllAssesmentPackageTypes');

			setTestPackageTypes(data);
		} catch (error) {
			console.log(error);
		}
	};
	const getAllTestPackagesByTypes = async (packageId) => {
		try {
			const { data } = await axios.get(
				'/users/getAssessmentPackagesByAssessmentTypeId/' + packageId
			);
			setTestPackages(data);
		} catch (error) {
			console.log(error);
		}
	};

	const languageItems = [
		{ label: 'English', value: 'en', code: 'US' },
		{ label: 'Türkçe', value: 'tr', code: 'TR' },
	];
	const onLangChange = (e) => {
		setLang(e.value);
	};

	const selectedCountryTemplate = (option, props) => {
		if (option) {
			return (
				<div className='country-item country-item-value'>
					{option.value === 'en' ? (
						<IconButton className={classes.muiIcon}>
							<IconFlagUS />
						</IconButton>
					) : (
						<IconButton className={classes.muiIcon}>
							<IconFlagTR />
						</IconButton>
					)}
					<div>{option.label}</div>
				</div>
			);
		}
	};
	const getStationList = () => {
		StationService.getStationList().then((resp) => {
			var data = resp.data;
			if (data != null) {
				for (const i of data) {
					i.fullName =
						i.location.country.name +
						'/' +
						i.location.city.name +
						'/' +
						i.location.name +
						'/' +
						i.name;
				}
			}
			setStations(data);
		});
	};

	const getStationByLocationAdminList = () => {
		StationService.getStationByLocationAdminList(userInfo.userId).then(
			(resp) => {
				var data = resp.data;
				if (data != null) {
					for (const i of data) {
						i.fullName =
							i.location.country.name +
							'/' +
							i.location.city.name +
							'/' +
							i.location.name +
							'/' +
							i.name;
					}
				}
				setStations(data);
			}
		);
	};

	useEffect(() => {
		if (AuthenticationService.isLocationAdmin()) {
			getStationByLocationAdminList();
		} else {
			getStationList();
		}
	}, []);

	const countryOptionTemplate = (option) => {
		return (
			<div className='country-item'>
				{option.value === 'en' ? (
					<IconButton className={classes.muiIcon}>
						<IconFlagUS />
					</IconButton>
				) : (
					<IconButton className={classes.muiIcon}>
						<IconFlagTR />
					</IconButton>
				)}
				<div>{option.label}</div>
			</div>
		);
	};

	const confirm = async (registerForm) => {
		const isValidEmails = await validateEmail(registerForm.email);
		if (isValidEmails) {
			onSubmit(registerForm);
		} else {
			confirmDialog({
				message: `${t('enqueueSnackbar_message.english_charachter_valid')}`,
				header: `${t('appointment_alert_messages.confirmation')}`,
				icon: 'pi pi-exclamation-triangle',
				acceptLabel: `${t('yes')}`,
				rejectLabel: `${t('no')}`,
				accept: () => onSubmit(registerForm),
				reject: () => reject(),
			});
		}
	};

	const reject = () => {};

	const validateEmail = async (email) => {
		const re =
			/^([a-zA-Z0-9]+[._]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[._]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
		return re.test(String(email).toLowerCase());
	};

	const handleChangeEmail = (value) => {
		return value.toLowerCase();
	};

	return (
		<>
			<ConfirmDialog />
			<Toast ref={toast} />
			<Spinner loading={loading} />
			<div className='card card-custom gutter-b'>
				<div className='card-header'>
					<div className='card-title'>
						<h3 className='card-label'>{t('user_detail')}</h3>
					</div>
					<div className='card-toolbar'>
						<ul className='ust_menu'>
							<li tooltip={t('turn_back')} onClick={handleOnClick}>
								<i className='pi pi-arrow-left' />
							</li>
							{/*<li tooltip="Bilgileri Kaydet" onClick={onSubmit}>*/}
							{/*    <i className="pi pi-save "/>*/}
							{/*</li>*/}
						</ul>
					</div>
				</div>
				<div className='card-body'>
					<div className={classes.paper}>
						<Grid item={true} xs={12} sm={10} md={8} lg={6}>
							<form
								autoComplete='off'
								className={classes.form}
								noValidate
								onSubmit={handleSubmit(confirm)}
								encType='multipart/form-data'
							>
								<div
									style={{
										display: 'flex',
										position: 'relative',
										bottom: '5px',
									}}
								>
									<div style={{ width: '60%' }}>
										<Controller
											name='name'
											control={control}
											render={(props) => (
												<div style={{ marginTop: '5px' }}>
													<label style={{ position: 'relative', top: '0.5em' }}>
														{t('name')}
													</label>
													<FormInput
														{...props}
														margin='dense'
														required
														label={t('name')}
													/>
												</div>
											)}
										/>
										<Controller
											name='email'
											control={control}
											render={(props) => (
												<div style={{ marginTop: '5px' }}>
													<label style={{ position: 'relative', top: '0.5em' }}>
														{t('email')}
													</label>
													<FormInputGrid
														placeholder={t('email') + '*:'}
														{...props}
														margin='dense'
														required
														label={t('email')}
														handleChangeEmail={handleChangeEmail}
													/>
												</div>
											)}
										/>
										<Controller
											control={control}
											id='autocomplete-off'
											name='customer'
											autocomplete='off'
											render={(props) => (
												<div style={{ marginTop: '10px' }}>
													<label>{t('customer')}</label>
													<Dropdown
														{...register}
														style={{ width: '100%' }}
														options={customers}
														appendTo='self'
														value={customer}
														onChange={(e) => {
															setCustomer(e.value);
														}}
														optionLabel='name'
														placeholder=''
													/>
												</div>
											)}
										/>

										<Controller
											control={control}
											id='autocomplete-off'
											name='assessmentPackages'
											autocomplete='off'
											render={(props) => (
												<div style={{ marginTop: '10px' }}>
													<label>{t('test_package')}</label>
													<Dropdown
														style={{ width: '100%' }}
														options={testPackages}
														appendTo='self'
														value={testPackage}
														onChange={handleSetTestPackage}
														optionLabel='packageName'
														optionValue=''
														placeholder=''
													/>
												</div>
											)}
										/>
									</div>
									<div style={{ marginLeft: '30px', width: '60%' }}>
										<Controller
											name='surname'
											control={control}
											render={(props) => (
												<div style={{ marginTop: '5px' }}>
													<label style={{ position: 'relative', top: '0.5em' }}>
														{t('surname')}
													</label>
													<FormInput
														{...props}
														margin='dense'
														required
														label={t('surname')}
													/>
												</div>
											)}
										/>

										<Controller
											name='emailRepeat'
											control={control}
											render={(props) => (
												<>
													<div style={{ marginTop: '5px' }}>
														<label
															style={{ position: 'relative', top: '0.5em' }}
														>
															{t('email_repeat')}
														</label>
														<FormInputGrid
															placeholder={t('email_repeat') + '*:'}
															{...props}
															margin='dense'
															required
															label={t('email_repeat')}
															handleChangeEmail={handleChangeEmail}
														/>
													</div>
												</>
											)}
										/>

										<Controller
											control={control}
											id='autocomplete-off'
											name='name'
											autocomplete='off'
											render={(props) => (
												<div style={{ marginTop: '10px' }}>
													<label>{t('test_package_type')}</label>
													<Dropdown
														style={{ width: '100%' }}
														options={testPackageTypes}
														appendTo='self'
														value={testPackageType}
														onChange={handleChangeTestPackageType}
														optionLabel='name'
														optionValue=''
														placeholder=''
													/>
												</div>
											)}
										/>

										{/* {errors.assessmentPackages && (
                  <p
                    style={{
                      color: '#f44336',
                      fontSize: '0.75rem',
                      margin: '0 14px',
                      lineHeight: '1.77',
                    }}
                  >
                    {errors.assessmentPackages.message}
                  </p>
                )} */}
									</div>
								</div>
								<label>{t('hub')}</label>
								<div className='col-6' style={{ padding: '0 20px 0 0' }}>
									<Dropdown
										style={{
											marginBottom: '5px',
											width: '215%',
										}}
										value={station}
										className='p-inputtext-sm'
										filter={true}
										appendTo='self'
										onChange={(e) => {
											setStation(e.value);
										}}
										options={stations}
										optionLabel='fullName'
										placeholder={t('select_hub')}
									/>
								</div>
								<div style={{ display: 'flex' }}>
									<div style={{ width: '58%' }}>
										<Controller
											control={control}
											id='autocomplete-off'
											name='preferredLanguage'
											autocomplete='off'
											render={(props) => (
												<div
													className='countryFlagLocation'
													style={{ marginTop: '23px' }}
												>
													<label>{t('preferred_language')}</label>
													<Dropdown
														style={{
															width: '100%',
															height: '45px',
														}}
														appendTo='self'
														value={lang}
														options={languageItems}
														onChange={onLangChange}
														optionLabel='label'
														placeholder={t('select_language')}
														valueTemplate={selectedCountryTemplate}
														itemTemplate={countryOptionTemplate}
													/>
												</div>
											)}
										/>
									</div>
									<div
										style={{
											marginTop: '1.2em',
											marginLeft: '20px',
											width: '60%',
										}}
									>
										{selected === 'tr' ? (
											<Controller
												name='mobilePhone'
												control={control}
												render={(props) => (
													<PhoneInput
														placeholder={t('mobile_phone') + '*:'}
														{...props}
													/>
												)}
											/>
										) : (
											<Controller
												name='mobilePhone'
												control={control}
												render={(props) => (
													<PhoneInputEn
														placeholder={t('mobile_phone') + '*:'}
														{...props}
													/>
												)}
											/>
										)}
									</div>
								</div>
								<div className='mt-2'>
									<Controller
										name='webUILoginNotAllowed'
										control={control}
										rules={{ required: 'Accept is required.' }}
										render={({ field }) => (
											<>
												<Checkbox
													inputId={field.name}
													checked={field.value || false}
													className={classNames({
														'p-invalid': errors.webUILoginNotAllowed,
													})}
													onChange={(e) => field.onChange(e.checked)}
												/>
												<label
													htmlFor={field.name}
													className={classNames({
														'p-error': errors.webUILoginNotAllowed,
													})}
												>
													<span className='mr-2'>
														{' '}
														{t('web_ui_login_not_allowed_label')}{' '}
													</span>
												</label>
											</>
										)}
									/>
								</div>
								<MainButton
									type='submit'
									fullWidth
									variant='contained'
									color='primary'
									className={classes.submit}
								>
									{t('save')}
								</MainButton>
							</form>
						</Grid>
					</div>
				</div>
			</div>
		</>
	);
};
