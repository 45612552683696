import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/tr';
import PageTitle from './../components/PageTitle';
import Datatable from './../components/common/Datatable';
import { Divider, Grid, Typography } from '@material-ui/core';
import DialogComponent from '../components/common/DialogComponent';
import { DemographicsInfo } from './../components/DemographicsInfo';
import Popconfirm from './../components/common/Popconfirm';
import { useSnackbar } from 'notistack';
import { MTableToolbar } from 'material-table';
import Spinner from '../components/common/Spinner';
import { withTranslation } from 'react-i18next';

const Users = ({ t }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const DemographicsInfo = React.lazy(() =>
    import('./../components/DemographicsInfo')
  );
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    axios
      .get('/users/pendingDoctorComms')
      .then((res) => {
        setUsers(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const deleteUser = (id) => {
    axios
      .delete(`/users/${id}`)
      .then(() => {
        enqueueSnackbar(`${t('enqueueSnackbar_message.delete_user')}`, {
          variant: 'success',
        });
        fetchUsers();
      })
      .catch((err) => console.log(err));
  };

  const approveDoctor = (userId) => {
    axios
      .post('/users/approveDoctor', {}, { params: { userId } })
      .then(() => {
        enqueueSnackbar(`${t('enqueueSnackbar_message.confirmed_doctor')}`, {
          variant: 'success',
        });
        fetchUsers();
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      field: 'user',
      title: `${t('user_id')}`,
      render: (rowData) => rowData.user.id,
      customSort: (a, b) => a.user.id - b.user.id,
    },
    { field: 'name', title: `${t('name')}` },
    { field: 'surname', title: `${t('surname')}` },
    { field: 'email', title: `${t('email')}` },
    { field: 'mobilePhone', title: `${t('mobile_phone')}` },
    {
      field: 'createdAt',
      title: `${t('register_date')}`,
      defaultSort: 'asc',
      render: (rowData) => {
        return moment.unix(rowData.createdAt).format('DD.MM.YYYY');
      },
    },
  ];

  return (
    <div>
      <Spinner loading={loading} />
      <PageTitle>{t('users')}</PageTitle>
      <Datatable
        style={{ padding: 10 }}
        options={{
          showTitle: false,
          pageSize: 10,
          sorting: true,
          searchFieldVariant: 'outlined',
          searchFieldAlignment: 'right',
          pageSizeOptions: [10, 20, 50],
          actionsColumnIndex: -1,
        }}
        data={users}
        columns={columns}
        actions={[
          {
            icon: 'save',
            tooltip: 'Save User',
          },
          {
            icon: 'view',
            tooltip: 'View User',
          },
          {
            icon: 'delete',
            tooltip: 'Delete User',
          },
        ]}
        components={{
          Toolbar: (props) => {
            // This will let you use your own Title while keeping the search
            const propsCopy = { ...props };
            // Hide default title
            propsCopy.showTitle = false;
            return (
              <div>
                <MTableToolbar {...propsCopy} />
                <Grid style={{ padding: 10 }}>
                  <Typography align='right' variant='body1'>
                    {t('total_users')} : {props.data.length}
                  </Typography>
                </Grid>
                <Divider />
              </div>
            );
          },
          Action: (props) => {
            if (props.action.icon === 'save') {
              return (
                <Popconfirm
                  style={{ margin: 5 }}
                  onConfirm={() => approveDoctor(props.data.user.id)}
                  title={t('confirm_doctor')}
                  description={t('confirm_doctor_question')}
                  buttonText={t('approve')}
                  okText={t('approve')}
                  cancelText={t('cancel')}
                />
              );
            }
            if (props.action.icon === 'view') {
              return (
                <DialogComponent
                  title={t('demographic_information')}
                  buttonText={t('detail')}
                  buttonColor='secondary'
                >
                  <DemographicsInfo userId={props.data.user.id} />
                </DialogComponent>
              );
            }
            if (props.action.icon === 'delete') {
              return (
                <Popconfirm
                  style={{ margin: 5 }}
                  onConfirm={() => deleteUser(props.data.user.id)}
                  title={t('delete_user')}
                  description={t('delete_user_question')}
                  buttonText={t('delete')}
                  okText={t('delete')}
                  cancelText={t('cancel')}
                />
              );
            }
          },
        }}
      />
    </div>
  );
};

export default withTranslation()(Users);
