import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      padding: 20,
      xAlign: 'left',
      yAlign: 'top',
      footerMarginBottom: 12,
      caretPadding: 8,
      bodyAlign: 'left',
      bodySpacing: 10,
      position: 'average',
      boxPadding: 8,
    },
  },
  layout: {
    height: 100,
  },
  scales: {
    xAlign: {
      display: false, // Hide Y axis labels
    },
    yAlign: {
      display: false, // Hide Y axis labels
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
const labels2 = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
];
const data = {
  labels: labels2,
  datasets: [
    {
      label: 'Dataset 2',
      data: [123, 123, 213, 545, 123, 21312, 12],
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};
const data2 = {
  labels2,
  datasets: [
    {
      label: 'Dataset 1',
      data: [123, 123, 213, 545, 123, 21312, 12],
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: [123, 123, 213, 545, 123, 21312, 12],
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export function BarTest() {
  console.log('data :>> ', data);
  return (
    <div style={{ width: 400 }}>
      <Bar options={options} data={data} />
      <br />
      <br />
      <br />
      <br />
      <Bar options={options} data={data} />
    </div>
  );
}
