import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  createRef,
} from 'react';

import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5hierarchy from '@amcharts/amcharts5/hierarchy';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5radar from '@amcharts/amcharts5/radar';
import * as am5percent from '@amcharts/amcharts5/percent';
import './Reports.css';
import { styled } from '@mui/material/styles';
import LayoutComponent from '../../components/LayoutComponent';
import { Card, Divider, Grid } from '@material-ui/core';
import Box from '@mui/material/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { Numbers } from '@mui/icons-material';
import ListItemText from '@material-ui/core/ListItemText';
import { LocationOn } from '@material-ui/icons';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import TreeView from '@material-ui/lab/TreeView';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring'; // web.cjs is required for IE 11 support
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
import '../../components/common/treeview.css';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import PhysioPhysical from './PhysioPhysical';
import PhysicalModuleMeasures from './physio-physical/PhysicalModuleMeasures';
import SocioEmotional from './SocioEmotional';
import Cognitive from './Cognitive';
import { ColorPicker } from 'primereact/colorpicker';
import moment from 'moment';
import OverviewService from '../../service/OverviewService';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import AuthenticationService from '../../service/AuthenticationService';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Spinner from '../../components/common/Spinner';
import NotFoundImage from '../../assets/images/no-data-gorseli.png';
import { withTranslation } from 'react-i18next';
import { PanelMenu } from 'primereact/panelmenu';
import ScoreModules from '../../components/ScoreModules';
import { Accordion, AccordionTab } from 'primereact/accordion';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { Button } from 'primereact/button';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import ExpertReviewAppointmentService from '../../service/ExpertReviewAppointmentService';
import BestOfList from '../../components/BestOfList';
import { ProgressSpinner } from 'primereact/progressspinner';
import MiniDonutChart from '../../components/UserScoreDetails/MiniDonutChart';

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 20 : 20}px,5,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  in: PropTypes.bool,
};
const StyledTreeItem = withStyles((theme) => ({
  group: {
    marginLeft: 7,
    paddingLeft: 18,
  },
}))((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

const ReactToolTip = ({ title, children, position, containerClass, theme }) => {
  return (
    <div className={`tooltip ${containerClass}`}>
      {children}
      <div
        className={`tooltiptext ${
          theme === 'dark' ? `dark` : `light`
        } tooltip-${position}`}
      >
        {title}
        <span className='arrow'></span>
      </div>
    </div>
  );
};

const chartColors = [
  {
    moduleId: 1,
    color: ['#929ea8', '#929ea8', '#929ea8'],
  },
  {
    moduleId: 2,
    color: ['#0099f7', '#0099f7', '#0099f7'],
  },
  {
    moduleId: 3,
    color: ['#de2348', '#de2348', '#de2348'],
  },
];
const getcolors = [
  { forcedTreeColor: '#929ea8' },
  { forcedTreeColor: '#0099f7' },
  { forcedTreeColor: '#de2348' },
];
const headerColors = [
  {
    id: 1,
    firstColor: '#929ea8',
    secondColor: '#bec5cb',
  },
  {
    id: 2,
    firstColor: '#0099f7',
    secondColor: '#8bdff4',
  },
  {
    id: 3,
    firstColor: '#de2348',
    secondColor: '#ffbfcb',
  },
];

const Overview = ({ t }) => {
  const color = [
    {
      name: '#be1622',
      text: t('Improve Immediately'),
    },
    {
      name: '#e94e1b',
      text: t('Poor'),
    },
    {
      name: '#f9b233',
      text: t('Below Average'),
    },
    {
      name: '#95c11f',
      text: t('Average'),
    },
    {
      name: '#006633',
      text: t('Good'),
    },
    {
      name: '#12bfcc',
      text: t('Very Good'),
    },
    {
      name: '#002469',
      text: t('Excellent'),
    },
  ];
  const [score, setScore] = useState(0);
  const [showDetail, setShowDetail] = useState(false);
  const [data, setData] = useState({});
  const [detailInfo, setDetailInfo] = useState({
    name: '',
    value: '',
    subdomainId: 0,
    domain: {},
    contentList: [],
  });
  const params = useParams();
  const lang = localStorage.getItem('i18nextLng');
  const [appointment, setAppointment] = useState({});
  const [appointmentId, setAppointmentId] = useState(parseInt(params.id));
  const [approvedAppointments, setApprovedAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [display, setDisplay] = useState(false);
  const [displayAccordion, setDisplayAccordion] = useState(false);
  const [displayRadar, setDisplayRadar] = useState(false);
  const [activeIndex, setActiveIndex] = useState([]);
  const [activeIndex2, setActiveIndex2] = useState(null);
  const [activeIndex3, setActiveIndex3] = useState(null);
  // const [activeIndex4, setActiveIndex4] = useState(null);
  const [menuData, setMenuData] = useState([]);
  const [showChart, setShowChart] = useState(true);
  const [chartData, setChartData] = useState();
  let history = useHistory();
  let { path, url } = useRouteMatch();
  const user = AuthenticationService.getLoggedInUser();
  const chartRef = useRef(null);
  const scrollRef = useRef(null);
  const radarChartRef = useRef(null);
  const [accordionData, setAccordionData] = useState([]);
  const [accordionTitle, setAccordionTitle] = useState({});
  const [autoReportText, setAutoReportText] = useState([]);
  const [autoReportSubText, setAutoReportSubText] = useState([]);
  const [detail, setDetail] = useState([]);
  const [autoReportTextObj, setAutoReportTextObj] = useState({});
  const [module, setModule] = useState({});
  const [legendColors, setLegendColors] = useState([]);
  const [colorChart, setColorChart] = useState(getcolors);
  const [reverse, setReverse] = useState(false);
  const [scoreAvaregesDomain, setScoreAvaregesDomain] = useState([]);
  const [scoreAvaregesSubDomain, setScoreAvaregesSubDomain] = useState([]);
  const [borderColor, setBorderColor] = useState(null);
  const [headerColor, setHeaderColor] = useState({});
  const [moduleAvarageScores, setModuleAvarageScores] = useState([]);
  const [metricDetail, setMetricDetail] = useState([]);
  const [index, setIndex] = useState(null);
  const [activeModule, setActiveModule] = useState();
  const [allAutoReportText, setAllAutoReportText] = useState([]);
  const [getUser, setGetUser] = useState({});
  const [domainChild, setDomainChild] = useState({});
  const [shadow, setShadow] = useState(false);
  const myRef = useRef(null);
  const divRef = useRef();
  const [urlSearch, setUrlSearch] = useState(
    history.location.search.includes('guid')
  );

  const [autoActiveAccordion, setAutoActiveAccordion] = useState(
    history.location.state?.data
  );

  const useStyles = makeStyles({
    root: {
      height: 264,
      flexGrow: 1,
      maxWidth: 400,
    },
    topAccordion: {
      color: 'rgb(0, 52, 113)',
      fontWeight: 'bold',
      fontSize: 18,
      '&:before': {
        content: '""',
        position: 'absolute',
        width: '6px',
        backgroundColor: borderColor,
        height: '100%',
        top: '0px',
        left: '0px',
      },
    },
  });
  const classes = useStyles();

  //Url'de guid varsa appointmentIdyi alıp set ederiz. Ardından fonksiyonu çalıştırırız.
  useEffect(() => {
    const matchAppointmentId = () => {
      if (urlSearch) {
        let searchKey = history.location.search;
        const index = searchKey.indexOf('=') + 1;
        const key = parseInt(searchKey.slice(index));
        setAppointmentId(key);
      }
    };
    matchAppointmentId();
  }, []);

  //URL Parametresinde appointmentId varsa bu bölüm çalışıyor.
  useEffect(() => {
    setLoading(true);
    if (!isNaN(appointmentId)) {
      const fetchUserAppointments = async () => {
        const response = await axios.get('/appointments/userAppointments', {
          params: { userId: user.userId },
        });
        if (response.data) {
          const approvedAppointments = response.data?.filter(
            (appointment) => appointment.approved
          );
          approvedAppointments?.map((item) => {
            item.startTime = item.start;
            item.endTime = item.end;
            item.fullApp =
              item.appointmentId +
              ' - ' +
              moment(item.date).format('Do MMMM YYYY') +
              ' - ' +
              item.start +
              ' / ' +
              item.end;
          });
          const app = approvedAppointments?.filter(
            (f) => f.appointmentId === appointmentId
          )[0];
          setAppointment(app);
          setApprovedAppointments(approvedAppointments);
          // Bu aşağıdaki fonksiyonlar sırasıyla çalışır. Birbirini beklerler.
          await fetchSubdomainAvarageScores();
          await fetchdomainAvarageScores();
          await fetchAllAutoReport();
        }
      };

      const fetchSubdomainAvarageScores = async () => {
        const response = await OverviewService.getDomainModulScore(
          appointmentId
        );
        const subDomainAvg = await axios.get('/scores/getScoresAverages/1');
        const domainAvg = await axios.get('/scores/getScoresAverages/2');
        const descriptionsResponse = await axios.get(
          'dashboard/getAllModuleDomainSubdomainDescription/' + lang
        );

        if (response.data != null) {
          for (let i = 0; i < response.data.modules.length; i++) {
            const moduleText = descriptionsResponse.data.find(
              (t) => t.moduleId === response.data.modules[i].moduleId
            );
            response.data.modules[i].descriptions = moduleText;
            if (response.data.modules[i].domains != null) {
              for (
                let j = 0;
                j < response.data.modules[i].domains.length;
                j++
              ) {
                const avarageScores = domainAvg.data.find(
                  (f) =>
                    f.domainId ==
                      response.data.modules[i].domains[j].domainId &&
                    f.moduleId == response.data.modules[i].moduleId
                );
                const domainText = descriptionsResponse.data.find(
                  (t) =>
                    t.domainId ===
                      response.data.modules[i].domains[j].domainId &&
                    t.subdomainId === null
                );
                if (domainText != null) {
                  response.data.modules[i].domains[j].description = domainText;
                }
                if (avarageScores != null) {
                  response.data.modules[i].domains[j].avarageScore = parseInt(
                    avarageScores.avgScore
                  );
                }
                if (response.data.modules[i].domains[j].subdomains != null) {
                  for (
                    let k = 0;
                    k < response.data.modules[i].domains[j].subdomains.length;
                    k++
                  ) {
                    const subdomainText = descriptionsResponse.data.find(
                      (t) =>
                        t.subdomainId ===
                        response.data.modules[i].domains[j].subdomains[k]
                          .subdomainId
                    );
                    if (subdomainText != null) {
                      response.data.modules[i].domains[j].subdomains[
                        k
                      ].description = subdomainText;
                    }
                    const points = subDomainAvg.data.find(
                      (f) =>
                        f.subdomainId ==
                          response.data.modules[i].domains[j].subdomains[k]
                            .subdomainId &&
                        f.domainId ==
                          response.data.modules[i].domains[j].domainId &&
                        f.moduleId == response.data.modules[i].moduleId
                    );
                    if (points != null) {
                      response.data.modules[i].domains[j].subdomains[
                        k
                      ].avgScore = parseInt(points.avgScore);
                    }
                  }
                }
              }
            }
          }
          let modules = response.data.modules.filter((m) => m.moduleId === 2);
          let domains = modules[0]?.domains;
          if (domains != null) {
            setMetricDetail(domains);
          }
          setData(response.data);
          setLoading(false);
        } else {
          setDisplay(true);
          setLoading(false);
        }
      };

      const fetchdomainAvarageScores = async () => {
        const response = await axios.get('/scores/getScoresAverages/2');
        if (response.data) {
          setModuleAvarageScores(response.data);
        }
      };
      fetchUserAppointments();
    }
  }, [appointmentId]);

  //Auto report textlerimizi almak için çağırdığımız fonksiyon.
  const fetchAllAutoReport = async () => {
    setLoading1(true);
    const payload = {
      appointmentId,
      moduleId: activeModule,
      languageId: lang,
    };
    const response = await axios.post('dashboard/createAllAutoReport', payload);
    setAllAutoReportText(response.data);
    setLoading1(false);
  };

  //Eğer önceki sayfadan link ile gönderdiğim state propunun içinde `data` propertysi varsa;
  // Bu aşamadaki settings fonksiyonu ve state işlemleri yapılır.
  useEffect(() => {
    if (history.location.state?.data) {
      routeToAccordionMenu();
      setDomainChild(history.location.state?.data);
      setActiveModule(history.location.state?.data.moduleId);
      setBorderColor(history.location.state?.data.forcedTreeColor);
    }
  }, []);

  // Ana gövdedeki chart görünür değilse ve accordion menü görünür durumda ise ?? true
  // Bu kısımda onInıtAccordion ile sayfanın başındaki title kısmı ve skor kısmı için bilgileri state yazıyoruz.

  useEffect(() => {
    if (!showChart && displayAccordion) {
      onInıtAccordion(domainChild);
    }
  }, [showChart, displayAccordion, domainChild]);

  useEffect(() => {
    setLegendColors([
      {
        name: '#be1622',
        text: t('Improve Immediately'),
      },
      {
        name: '#e94e1b',
        text: t('Poor'),
      },
      {
        name: '#f9b233',
        text: t('Below Average'),
      },
      {
        name: '#95c11f',
        text: t('Average'),
      },
      {
        name: '#006633',
        text: t('Good'),
      },
      {
        name: '#12bfcc',
        text: t('Very Good'),
      },
      {
        name: '#002469',
        text: t('Excellent'),
      },
    ]);
  }, []);

  //Burada title bilgilerini set ettikten sonra accordion menüde döndüğümüz arrayi set ediyoruz.
  const onInıtAccordion = (parentModule) => {
    setAccordionTitle({
      id: parentModule.moduleId,
      name: t(parentModule.moduleName),
      score: parentModule.moduleScore,
      color: parentModule.forcedTreeColor,
    });
    setAccordionData(parentModule.domains);
  };

  // mODULE Değiştiği zaman auto report textler tekrar çağrılır.
  useEffect(() => {
    let unmounted = false;
    fetchAllAutoReport();
    return () => {
      unmounted = true;
    };
  }, [activeModule]);

  //Sayfa yüklenirken domainlerin ortalama skorları çağırırız ve set ederiz.

  useEffect(() => {
    let unmounted = false;

    axios.get('scores/getScoresAverages/2').then((res) => {
      setScoreAvaregesDomain(res.data);
    });
    //Memory leak hatasından kaçmak için state temizliği !
    return () => {
      unmounted = true;
    };
  }, []);

  const Image = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <img style={{ maxWidth: '600px' }} src={NotFoundImage} alt='' />
        <h1 style={{ fontWeight: 'bold' }}>{t('data_not_found')}.</h1>
      </div>
    );
  };

  // Accordion menüdeki yatay progress barlar.
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 24,
    width: '320px',
    borderRadius: 0,
    // transform: 'skew(-20deg)',
    backgroundColor: '#fff !important',

    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundImage:
        theme.palette.mode === 'light'
          ? 'linear-gradient(to right, rgba(8,18,37,0), rgba(8,18,37,1))'
          : '#308fe8',
      backgroundColor: 'inherit',
    },
  }));

  //Uzman görüşü verilerini almak için apiyi çağırıyoruz.
  const getExpertReview = async (appointmentId) => {
    const response = await ExpertReviewAppointmentService.getDetail(
      appointmentId
    );
    if (response != null && response.data != null) {
      const item = response.data.find(
        (f) => f.metricModule?.id === accordionTitle.id
      );
      if (item != null) {
        setDetail(item);
      }
    }
  };

  //Uzman görüşü verilerini almak için apiyi tetiklediğimiz bölüm.
  // Eğer appointmentId yoksa tetiklenmez.
  useEffect(() => {
    if (!isNaN(appointmentId)) getExpertReview(appointmentId);
  }, [appointmentId, accordionTitle]);

  // Önemsiz sağ üst köşedeki renk bar için yapılmıştı fakat css ile halledildi.
  const upDownItem = (index) => {
    let item = parseInt(index + 2);
    let result = (item %= 2);
    if (result === 0) {
      return 'column';
    }
    return 'column-reverse';
  };

  //Accordion componentteki dataların ortalama skorlarına göre renk değişimini gösterir.
  const setColor = (value) => {
    if (value <= 150) {
      return '#be1622';
    } else if (value >= 150 && value < 300) {
      return '#e94e1b';
    } else if (value >= 300 && value < 450) {
      return '#f9b233';
    } else if (value >= 450 && value < 600) {
      return '#95c11f';
    } else if (value >= 600 && value < 750) {
      return '#006633';
    } else if (value >= 750 && value < 900) {
      return '#12bfcc';
    } else if (value >= 900) {
      return '#002469';
    }
    return;
  };

  // Accordion componentteki subdomainlerdeki progress barın yeşil okun nerede olması gerektiğini gösterir.
  const onDisplayPosition = (value) => {
    const avgScore = value;
    let calculatePosition = parseInt((avgScore * 320) / 1000 + 16) + 'px';
    return calculatePosition;
  };

  // Eğer önceki sayfadan gelen state propunda state.data varsa chart gizlenir accordion gelir.
  const routeToAccordionMenu = () => {
    setShowChart(false);
    setDisplayAccordion(true);
  };

  const onHandleModuleSettings = async (e) => {
    const id = e.item.id;
    setActiveModule(id);
    const activeColor = headerColors.find((item) => item.id === id);
    setHeaderColor(activeColor);
    setBorderColor(activeColor.firstColor);
    setDisplayAccordion(false);
    setAccordionTitle({
      ...accordionTitle,
      name: t(e.item.label),
    });
    if (e.item.parentMenu) {
      setShowChart(true);
      setDisplayAccordion(false);
      sunburstChart({ id: id, name: e.item.label });
    }
  };

  // Sol taraftaki açılır kapanır menünün hazır bir yapı olması dolayısıyla o formata uygun veri manipülasyonu yaptığımız yer. (<PanelMenu />)
  const panelMenuFormat = (s) => {
    const total = [];
    const mes = s.modules;

    for (let i = 0; i < mes.length; i++) {
      const modules = mes[i].domains;
      const moduleName = `${t(mes[i].moduleName)}`;
      let data = {
        label: t(moduleName),
        key: mes[i].moduleId,
        id: mes[i].moduleId,
        command: (e) => {
          onHandleModuleSettings(e);
        },
        description: mes[i]?.descriptions?.description,
        // Custom içerik oluşturma fırsatı veriyor.
        // Her bölümün başlığının üzerine gelince tooltip vermek için dinamik class ekleyip css ile düzenleme yaptık.
        template: (item, options) => {
          return (
            <a
              style={{ backgroundColor: mes[i].forcedTreeColor }}
              className={options.className + ' tooltipStyleChange' + i}
              target={item.target}
              onClick={options.onClick}
            >
              <span className='p-panelmenu-icon pi pi-chevron-right'></span>
              <span className={'p-menuitem-text tooltipStyleChange' + i}>
                {`${t(item.label)}`}
              </span>
              <Tooltip
                className='customTooltip'
                target={'.tooltipStyleChange' + i}
              >
                <div
                  className='flex  flex-column'
                  style={{ maxWidth: '400px', padding: 10 }}
                >
                  <span style={{ minWidth: '5rem' }}>{item.label}</span>
                  <p>{item.description}</p>
                </div>
              </Tooltip>
            </a>
          );
        },
        // className: 'tooltipStyleChange' + i,
        parentMenu: true,
        expanded: true,
        expandedKeys: {},
        stateful: true,
        items: [],
      };

      for (let j = 0; j < modules.length; j++) {
        data.items.push({
          label: `${t(modules[j].domainName)}`,
          command: (e) => {
            setActiveModule(e?.item?.parent.moduleId);
            routeToAccordionMenu();
            setDomainChild(e?.item?.parent);
            scrollAnimationToAccordion({ j });
          },
          parent: mes[i],
          index: j,
          id: modules[j].domainId,
        });
      }
      // Datayı istediğimiz formata getirdik ve set ettik.
      total.push(data);
    }
    setMenuData(total);
  };

  // Burası soldaki menüde herhangi bir alt başlığa tıklayınca accordion menüdeki scroll hareketini yapar
  const scrollAnimationToAccordion = ({ j }) => {
    const parentTabs = document.querySelector('.tab' + j);
    if (parentTabs != null) {
      parentTabs.parentNode.focus();
      setTimeout(() => {
        parentTabs.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }, 500);
    }
  };

  // Accordion datadaki fiziksel bölümün en altındaki ölçümler alanının html formatı
  const metricDetailsComponent = metricDetail.map((subdomain, index) => {
    return (
      <>
        <Spinner loading={loading} />
        <div className='container-metric-module' key={index}>
          {subdomain.subdomains
            .map((item) =>
              item.metrics.filter((m) => m.showOnPyhsicalMeasurePage === true)
            )
            .find((t) => t.length > 0) && (
            <h3 style={{ fontSize: '18px' }} className='subdomainName'>
              {t(subdomain.domainName)}
            </h3>
          )}

          <ul className='domainList'>
            {subdomain.subdomains.map(
              (item) =>
                item.metrics
                  .filter((m) => m.showOnPyhsicalMeasurePage === true)
                  .map((metric) => (
                    <div className='metric-flexItems'>
                      <li className='friendlyName'>
                        {metric?.userFriendlyName == null
                          ? 'İsim belirtilmemiş'
                          : t(metric?.userFriendlyName)}
                      </li>
                      <li className='metricResult'>
                        {metric?.metricResult.toFixed(1)} {metric?.metricUnit}
                      </li>
                    </div>
                  ))
              // item.metrics.map((metric) => (
              //   <div className="metric-flexItems">
              //     <li className="friendlyName">
              //       {metric?.userFriendlyName == null
              //         ? 'İsim belirtilmemiş'
              //         : t(metric?.userFriendlyName)}
              //     </li>
              //     <li className="metricResult">
              //       {metric?.metricResult.toFixed(1)} {metric?.metricUnit}
              //     </li>
              //   </div>
              // ))
            )}
          </ul>
        </div>
      </>
    );
  });

  // Sol paneldeki üst bilgileri almak için name age vs;
  useEffect(() => {
    const getModules = (appointmentId) => {
      OverviewService.getDomainModulScore(appointmentId).then((res) => {
        if (res.data != null) {
          setModule(res.data);
        }
      });
    };

    const strUpperCase = (text) => {
      let wordToArray = text.split(' ');
      wordToArray = wordToArray.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      return wordToArray.join(' ');
    };

    const fetchUserInfo = async () => {
      const response = await axios.get(
        '/users/getUserByAppointmentId/' + appointmentId
      );
      response.data.body.fullName =
        strUpperCase(response.data.body.name) +
        ' ' +
        strUpperCase(response.data.body.surname);
      setGetUser(response.data.body);
    };
    if (!isNaN(appointmentId)) {
      getModules(appointmentId);
      fetchUserInfo();
    }
  }, [appointmentId]);

  useLayoutEffect(() => {
    if (history.location.state?.data) {
      return;
    }
    const state = history.location.state;
    sunburstChart(state);
  }, [data.modules]);

  useEffect(() => {
    if (data.modules) {
      panelMenuFormat(data);
    }
  }, [data]);

  //Donut chartın kapanıp temizlenmesi sağlayan method;
  const maybeDisposeRoot = (divId) => {
    am5.array.each(am5.registry.rootElements, function (root) {
      if (root.dom.id === divId) {
        root.dispose();
      }
    });
  };

  // Genel görünüm bölümünün içindeki radar chartı tetikler.
  const getRadarChart = () => {
    setTimeout(() => {
      am5.addLicense('AM5C361499357');
      var root = am5.Root.new('chartdiv');

      root.setThemes([am5themes_Animated.new(root)]);
      var chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
        })
      );
      var cursor = chart.set(
        'cursor',
        am5radar.RadarCursor.new(root, {
          behavior: 'zoomX',
        })
      );

      cursor.lineY.set('visible', false);
      var xRenderer = am5radar.AxisRendererCircular.new(root, {
        minGridDistance: 30,
      });

      xRenderer.labels.template.setAll({
        radius: 40,
        fill: am5.color('#0A2365'),
        fontWeight: 'bold',
      });
      var yRenderer = am5radar.AxisRendererRadial.new(root, {
        minGridDistance: 30,
      });

      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0,
          categoryField: 'name',
          renderer: xRenderer,
          // tooltip: am5.Tooltip.new(root, {}),
        })
      );

      xRenderer.grid.template.setAll({
        location: 0.5,
        strokeDasharray: [2, 2],
      });

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5radar.AxisRendererRadial.new(root, {
            minGridDistance: 20,
          }),
          max: 1000,
          min: 0,
          calculateTotals: true,
        })
      );
      var series = chart.series.push(
        am5radar.RadarLineSeries.new(root, {
          name: 'Series',
          stroke: accordionTitle?.color,
          xAxis: xAxis,
          yAxis: yAxis,
          stacked: false,
          valueYField: 'score',
          categoryXField: 'name',
          fill: am5.color(accordionTitle?.color),
          tooltip: am5.Tooltip.new(root, {
            labelText: `${t('You')}: {score}`,
          }),
        })
      );

      // series2
      var series2 = chart.series.push(
        am5radar.RadarLineSeries.new(root, {
          name: 'Cash held in US',
          xAxis: xAxis,
          stroke: am5.color('#95c11f'),
          yAxis: yAxis,
          valueYField: 'score2',
          categoryXField: 'name',
          stacked: false,
          fill: am5.color('#95c11f'),
          tooltip: am5.Tooltip.new(root, {
            labelText: `${t('Average')}: {score2}`,
          }),
        })
      );

      series.strokes.template.setAll({
        strokeWidth: 2,
        strokeOpacity: 1,
      });
      series.fills.template.setAll({
        visible: true,
        fillOpacity: 0.3,
      });

      series2.strokes.template.setAll({
        strokeWidth: 2,
        strokeOpacity: 1,
      });

      series2.fills.template.setAll({
        visible: true,
        fillOpacity: 0.1,
      });

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 3,
            fill: am5.color(accordionTitle?.color),
          }),
        });
      });
      series2.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 3,
            fill: am5.color('#95c11f'),
          }),
        });
      });

      var data = [];
      const newData = accordionData.filter((item) => item.domainId !== 15);

      let matchedArray = moduleAvarageScores
        .filter((item1) =>
          newData.some((item2) => item1.domainId === item2.domainId)
        )
        .sort((a, b) => a.domainId - b.domainId);
      for (let i = 0; i < newData.length; i++) {
        data.push({
          name: `${t(newData[i]?.domainName)}`,
          score: newData[i]?.domainScore ?? Math.random(),
          score2: parseInt(matchedArray[i]?.avgScore),
        });
      }
      series.data.setAll(data);
      series2.data.setAll(data);
      xAxis.data.setAll(data);

      series.appear(1000);
      series2.appear(1000);
      chart.appear(1000, 100);
    }, 100);

    maybeDisposeRoot('chartdiv');
  };
  // Chart açılmadan önce çalışan fonksiyon. Chartı çalıştırıp içerisini doldururuz.
  const sunburstChart = (field) => {
    maybeDisposeRoot('chartdiv');
    if (module) {
      setTimeout(() => {
        if (field) {
          setAccordionTitle({
            name: t(field.name),
            id: field.id,
          });
          if (data.modules) {
            const matchedModule = data.modules?.filter(
              (module) => module?.moduleId == field?.id
            );
            if (matchedModule != null) {
              // const matchedColor = chartColors.filter(
              //   (item) => item.moduleId === matchedModule[0]?.moduleId
              // );
              const matchedColor = matchedModule[0]?.forcedTreeColor;
              am5.addLicense('AM5C361499357');
              let root = am5.Root.new('chartdiv');

              root.setThemes([am5themes_Animated.new(root)]);

              let chart = root.container.children.push(
                am5.Container.new(root, {
                  width: am5.percent(100),
                  height: am5.percent(100),
                  layout: root.verticalLayout,
                })
              );
              var series = chart.children.push(
                am5hierarchy.Sunburst.new(root, {
                  valueField: 'value',
                  categoryField: 'name',
                  childDataField: 'children',
                  initialDepth: 2,
                  centerY: 50,
                })
              );

              series.labels.template.setAll({
                text: `[fontSize: 14px]${t('{category}')}\n{myvalue}`,
                oversizedBehavior: 'wrap',
              });

              series.nodes.template.set('tooltipText', '{category}: {myvalue}');
              series.slices.template.setAll({
                templateField: 'nodeSettings',
                fillOpacity: 1,
                calculateAggregates: false,
              });
              const total = [];

              let datas = {
                name: `${t(matchedModule[0]?.moduleName)}`,
                id: matchedModule[0]?.moduleId,
                myvalue: matchedModule[0]?.moduleScore,
                children: [],
                nodeSettings: {
                  fill: am5.Color.fromAny(matchedColor),
                  opacity: 1,
                },
              };
              let domains = matchedModule[0]?.domains.filter(
                (item) => item.domainScore != null && item.domainScore !== 0
              );

              for (let i = 0; i < matchedModule[0]?.domains.length; i++) {
                datas.children.push({
                  name: `${t(domains[i]?.domainName)}`,
                  // value: 100,
                  id: domains[i]?.domainId,
                  myvalue: domains[i]?.domainScore,
                  children: [],
                  nodeSettings: {
                    fill: am5.Color.fromAny(matchedColor),
                    opacity: 0.85,
                    fontSize: 50,
                  },
                });

                const childs = datas?.children[i].children;
                for (let j = 0; j < domains[i]?.subdomains?.length; j++) {
                  childs.push({
                    id: domains[i].subdomains[j].subdomainId,
                    name: `${t(domains[i].subdomains[j].subdomainName)}`,
                    value: 1000,
                    myvalue: domains[i].subdomains[j].subdomainScore,
                    nodeSettings: {
                      fill: am5.Color.fromAny(matchedColor),
                      opacity: 0.65,
                    },
                  });
                }
              }
              total.push(datas);

              series.data.setAll(total);

              series.set('selectedDataItem', series.dataItems[0]);

              series.appear(1000, 100);
            }
          }
        }
      }, 100);
    }
  };

  return (
    <LayoutComponent navbar={urlSearch ? appointmentId : ''}>
      <Spinner loading={loading} />

      <div className='container'>
        <div className='sidebar'>
          <div
            className='user-infos'
            style={{
              maxHeight: '100% !important',
              height: 'auto',
            }}
          >
            <h1 className='user-infos-name  bold  customClassName'>
              {getUser?.fullName} <br /> {getUser?.age}{' '}
              {t(
                getUser?.gender === 'Erkek'
                  ? t('Male')
                  : getUser?.gender === 'Kadın'
                  ? t('Female')
                  : t('no_gender')
              )}
            </h1>
            <PanelMenu
              model={menuData}
              style={{ width: '16rem', margin: '0 auto' }}
            />
          </div>
          <div className='user-infos' style={{ marginTop: 7, height: 250 }}>
            <img src='/logo-black.png' style={{ width: '100%' }} alt='' />
            <MiniDonutChart module={module} onHandle={onHandleModuleSettings} />
          </div>
        </div>

        {displayAccordion && (
          <>
            <Spinner
              text={t('Please wait while your report is being prepared')}
              loading={loading1}
            />
            <div className='overviewAccordion' ref={myRef}>
              <div className='mainHeader'>
                <div className='point-table'>
                  <div
                    className='title'
                    style={{ backgroundColor: accordionTitle?.color }}
                  >
                    <h3>{t(accordionTitle?.name)}</h3>
                  </div>
                  <div
                    className='score'
                    style={{ background: headerColor?.secondColor }}
                  >
                    <div>
                      <span>{t('score')}</span>
                      <span className='strong'>{accordionTitle?.score}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    // 'colorsRow ' + reverse ? 'colorsRow reverse' : 'colorsRow'
                    'colorsRow reverse'
                  }
                >
                  {legendColors?.map((item, index) => (
                    <div className={'colorItem ' + upDownItem(index)}>
                      <div
                        key={index}
                        style={{ backgroundColor: item.name }}
                        className={'color'}
                      ></div>
                      <span className='text'>{t(item.text)}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className='topAccordionTitle'>
                <h2>{t('Overview')}</h2>
              </div>
              <Accordion
                style={{ marginBottom: 70 }}
                activeIndex={activeIndex3}
                onTabOpen={(e) => {
                  if (e.index === 0) {
                    getRadarChart();
                  }
                  return;
                }}
              >
                <AccordionTab
                  className='topAccordionHeader'
                  headerTemplate={
                    <div
                      className={
                        'p-accordion-header-text-0 ' + classes.topAccordion
                      }
                    >
                      {t('General Overview')}
                    </div>
                  }
                >
                  <div
                    style={{
                      width: '600px',
                      height: '600px',
                      margin: '0 auto',
                    }}
                    id='chartdiv'
                  ></div>
                </AccordionTab>

                {detail?.reviewText ? (
                  <AccordionTab
                    className={
                      'topAccordionHeader expertReview border' +
                      accordionTitle?.id
                    }
                    headerTemplate={
                      <div
                        className={
                          'p-accordion-header-text-0 ' + classes.topAccordion
                        }
                      >
                        {t('expert_opinion')}
                      </div>
                    }
                  >
                    <div className='accordion-text'>
                      <div
                        dangerouslySetInnerHTML={{ __html: detail.reviewText }}
                      />
                    </div>
                  </AccordionTab>
                ) : (
                  ''
                )}
              </Accordion>
              <div className='bottomAccordionTitle'>
                <h2>
                  {t(accordionTitle.name)} {t('Module Report')}
                </h2>
              </div>
              <Accordion multiple activeIndex={activeIndex}>
                {accordionData?.map((item, index) => (
                  <AccordionTab
                    key={item.domainId}
                    headerTemplate={
                      <div
                        className={
                          'p-accordion-header-text flex justify-content-between w-100 inside tab' +
                          index
                        }
                        style={{ width: '100%' }}
                      >
                        <span
                          style={{
                            color: '#DE2348',
                            fontWeight: 'bold',
                            fontSize: 18,
                          }}
                        >
                          A.{index + 1}
                          <span
                            style={{
                              color: '#003471',
                              fontWeight: 'bold',
                              fontSize: 18,
                              display: 'inline-block',
                              marginLeft: 4,
                            }}
                          >
                            {`${t(item.domainName)}`}
                          </span>
                        </span>

                        <div
                          className='d-flex align-items-end'
                          style={{ gap: 8 }}
                        >
                          {item.domainName === 'Anthropometrics' ||
                          'Antropometri' ? (
                            ''
                          ) : (
                            <span
                              id='triangle'
                              className={'avarageTooltip' + item.domainId}
                              style={{
                                left: onDisplayPosition(item.avarageScore),
                              }}
                            ></span>
                          )}

                          <Tooltip
                            className='avarageTooltip'
                            target={'.avarageTooltip' + item.domainId}
                          >
                            <div
                              className='flex  flex-column'
                              style={{ maxWidth: '400px', padding: 10 }}
                            >
                              <span style={{ minWidth: '5rem' }}>
                                {t('Average Score')}: {item.avarageScore}
                              </span>
                            </div>
                          </Tooltip>

                          {item.domainId == 15 ? (
                            ''
                          ) : (
                            <BorderLinearProgress
                              variant='determinate'
                              value={item.domainScore / 10}
                            />
                          )}
                          <div
                            className='score-box'
                            style={{
                              backgroundColor: setColor(item.domainScore),
                              color: '#fff',
                              // transform: 'skew(-20deg)',
                              fontSize: 20,
                              fontWeight: 'bold',
                              minWidth: '67px',
                              textAlign: 'center',
                            }}
                          >
                            <span
                              style={{
                                // transform: 'skew(20deg)',
                                display: 'block',
                                padding: '0 12px',
                              }}
                            >
                              {item.domainScore ?? ''}
                            </span>
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <p>{item?.description?.description}</p>
                    {allAutoReportText?.find(
                      (t) =>
                        t.domainId === item.domainId && t.subdomainId === -1
                    )
                      ? allAutoReportText
                          .find(
                            (t) =>
                              t.domainId === item.domainId &&
                              t.subdomainId === -1
                          )
                          .paragraphs?.map((data) => <p>{data?.text}</p>)
                      : ''}
                    {item.subdomains ? (
                      <Accordion multiple activeIndex={activeIndex2}>
                        {item.subdomains.map((subItem, i) => (
                          <AccordionTab
                            style={{ padding: '0.6rem !important' }}
                            key={i}
                            headerTemplate={
                              <div
                                className='flex justify-content-between w-100 inside'
                                style={{
                                  width: '100%',
                                  padding: '0.6rem !important',
                                }}
                              >
                                <span
                                  style={{
                                    color: '#DE2348',
                                    fontWeight: 'normal',
                                    fontSize: 18,
                                  }}
                                >
                                  A.{index + 1}.{i + 1}
                                  <span
                                    style={{
                                      color: '#003471',
                                      fontWeight: 'normal',
                                      fontSize: 17,
                                      display: 'inline-block',
                                      marginLeft: 4,
                                    }}
                                  >
                                    {`${t(subItem.subdomainName)}`}
                                  </span>
                                </span>

                                <div
                                  className='d-flex align-items-end'
                                  style={{ gap: 8 }}
                                >
                                  {subItem.subdomainId == 40 ? (
                                    ''
                                  ) : (
                                    <span
                                      id='triangle'
                                      className={
                                        'avarageTooltip' + subItem.subdomainId
                                      }
                                      style={{
                                        left: onDisplayPosition(
                                          subItem.avgScore
                                        ),
                                      }}
                                    ></span>
                                  )}

                                  <Tooltip
                                    className='avarageTooltip'
                                    target={
                                      '.avarageTooltip' + subItem.subdomainId
                                    }
                                  >
                                    <div
                                      className='flex  flex-column'
                                      style={{ maxWidth: '400px', padding: 10 }}
                                    >
                                      <span style={{ minWidth: '5rem' }}>
                                        {t('Average Score')}: {subItem.avgScore}
                                      </span>
                                    </div>
                                  </Tooltip>
                                  {subItem.subdomainId == 40 ? (
                                    ''
                                  ) : (
                                    <BorderLinearProgress
                                      variant='determinate'
                                      value={subItem.subdomainScore / 10}
                                    />
                                  )}
                                  <div
                                    className='score-box'
                                    style={{
                                      backgroundColor: setColor(
                                        subItem.subdomainScore
                                      ),
                                      color: '#fff',
                                      // transform: 'skew(-20deg)',
                                      fontSize: 20,
                                      fontWeight: 'bold',
                                      minWidth: '67px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <span
                                      style={{
                                        // transform: 'skew(20deg)',
                                        display: 'block',
                                        padding: '0 12px',
                                      }}
                                    >
                                      {subItem.subdomainScore ?? ''}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            }
                          >
                            <p>{subItem?.description?.description}</p>
                            {allAutoReportText?.find(
                              (t) => t.subdomainId === subItem.subdomainId
                            )
                              ? allAutoReportText
                                  .find(
                                    (t) => t.subdomainId === subItem.subdomainId
                                  )
                                  .paragraphs?.map((data) => (
                                    <p>{data?.text}</p>
                                  ))
                              : ''}
                          </AccordionTab>
                        ))}
                      </Accordion>
                    ) : (
                      ''
                    )}
                  </AccordionTab>
                ))}
              </Accordion>
              {activeModule === 2 && (
                <Accordion style={{ marginBottom: 70 }} onTabOpen={(e) => {}}>
                  <AccordionTab
                    className='topAccordionHeader'
                    headerTemplate={
                      <div
                        className={
                          'p-accordion-header-text-0 ' + classes.topAccordion
                        }
                      >
                        {t('Metrics')} *
                      </div>
                    }
                  >
                    <p className='metric-warning'>
                      *{' '}
                      {t(
                        'The measurement results here are shared for the purpose of interpretation by the professionals of the field.'
                      )}
                    </p>
                    {metricDetailsComponent}
                  </AccordionTab>
                </Accordion>
              )}
            </div>
          </>
        )}
        {!displayAccordion && (
          <>
            <div className='reviews' style={{ width: '100%' }}>
              {display && <Image />}
              <h4 className='chartTitle'>
                {t(accordionTitle?.name)} {t('overview')}
              </h4>
              <div
                id='chartdiv'
                style={{
                  width: '630px',
                  height: '630px',
                  margin: '0 auto',
                }}
              />
            </div>
            {data && <BestOfList setColor={setColor} module={data} />}
          </>
        )}
      </div>
    </LayoutComponent>
  );
};

export default withTranslation()(Overview);
