import { useState, useEffect } from 'react';
import LayoutComponent from '../components/LayoutComponent';
import ScoreModules from '../components/ScoreModules';
import SelectAppointmentCard from '../components/UserScoreDetails/SelectAppointmentCard';
import UserInfoCard from '../components/UserScoreDetails/UserInfoCard';
import { useHistory } from 'react-router-dom';
import ScoreDonutChart from '../components/UserScoreDetails/ScoreDonutChart';

const UserScoreDetail = () => {
  const [approvedAppointments, setApprovedAppointments] = useState([]);
  const [appointment, setAppointment] = useState(0);
  const [module, setModule] = useState({});
  const [scores, setScores] = useState({
    cognitiveScore: 0,
    physicalScore: 0,
    socioScore: 0,
  });

  const history = useHistory();
  return (
    <div>
      <LayoutComponent navbar={'custom'}>
        <div className="container-md">
          <div className="sidebar-user">
            <UserInfoCard user={history.location.user} />
            {/* <SelectAppointmentCard /> */}
          </div>
          <div className="center-page">
            <ScoreDonutChart />
          </div>
          <ScoreModules
            // appointmentId={appointment}
            modules={module}
            // score={scores}
          />
        </div>
      </LayoutComponent>
    </div>
  );
};

export default UserScoreDetail;
