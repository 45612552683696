import axios from 'axios';

class CustomerSarfReportUserService {
	getAllSarfReportUsers() {
		return axios.get('/lookup/getAllSarfReportUsers');
	}
	getCompletedAppointmentsByCustomerId(row) {
		return axios.get(
			'/lookup/getCompletedAppointmentsByCustomerIdAndFilteredBySarfReportIdList',
			row
		);
	}
	getCompletedAppointmentByReportId(id) {
		return axios.get('/lookup/getAppointmentsBySarfReportId/' + id);
	}

	getById(id) {
		return axios.get('/lookup/getSarfReportUserById/' + id);
	}

	save(row) {
		return axios.post('/lookup/saveSarfReportUser', row);
	}

	update(id, row) {
		return axios.post(
			'/lookup/updateSarfReportUserListBySarfReportId/' + id,
			row
		);
	}
	delete(id) {
		return axios.delete(`/lookup/deleteSarfReportUserById/${id}`);
	}
}

export default new CustomerSarfReportUserService();
