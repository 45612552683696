import axios from 'axios';


class DemographicsService {
  getDemographicsInfo() {
    return axios.get('/users/demographics');
  }

  getDemographicsInfoByUserId(userId) {
    // console.log("getDemographicsInfoByUserId =========================>>", userId)
    return axios.get('/users/demographicsByUserId', {
      headers: {
       'languageId': localStorage.getItem('i18nextLng'),
      },
      params: { userId },
      });
  }
}

export default new DemographicsService();
