import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/tr';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import PageTitle from '../components/PageTitle';
import { useSnackbar } from 'notistack';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import '../components/appointment.css';
import { withTranslation } from 'react-i18next';
import AlertDialog from '../components/DialogComp';
import AuthenticationService from '../service/AuthenticationService';
import { Tooltip } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ButtonMaterial from '@mui/material/Button';
import Spinner from '../components/common/Spinner';
const style = {
	border: '1px solid black',
	width: '200px',
	height: '200px',
};

const CompletedAppointments = ({ t }) => {
	const [appointments, setAppointments] = useState([]);
	const [dateChangeLang, setDateChangeLang] = useState('tr-TR');
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		appointmentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		parentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		userId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		name: { value: null, matchMode: FilterMatchMode.CONTAINS },
		surname: { value: null, matchMode: FilterMatchMode.CONTAINS },
		type: { value: null, matchMode: FilterMatchMode.CONTAINS },
		stationFullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
		completedYesNo: { value: null, matchMode: FilterMatchMode.CONTAINS },
		start: { value: null, matchMode: FilterMatchMode.CONTAINS },
		end: { value: null, matchMode: FilterMatchMode.CONTAINS },
		date: { value: null, matchMode: FilterMatchMode.CONTAINS },
		operatorId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		operatorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
		assesmentPackageName: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const langValue = localStorage.getItem('i18nextLng') ?? 'tr';
	const user = JSON.parse(localStorage.authenticatedUser);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [loading, setLoading] = useState(true);
	const [loadingPdf, setLoadingPdf] = useState(false);
	const [pdfOpen, setPdfOpen] = useState(false);
	const [downloadIsValid, setDownloadIsValid] = useState(false);
	const [pdfValue, setPdfValue] = useState('1');
	const toast = useRef(null);
	const [selectedRowData, setSelectedRowData] = useState({});
	const [selectedSarfReportId, setSelectedSarfReportId] = useState(null);
	const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
	const [sarfReportList, setSarfReportList] = useState([]);
	const [sarfReportListAdmin, setSarfReportListAdmin] = useState([]);
	const [sarfReportDialog, setSarfReportDialog] = useState(false);
	const [sarfReportDialogAdmin, setSarfReportDialogAdmin] = useState(false);
	const [loadingSarfReport, setLoadingSarfReport] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		setLoading(true);
		if (AuthenticationService.isAdmin()) {
			axios.get('/appointments/getCompleted').then((res) => {
				let result = res.data;
				for (let i = 0; i < result.length; i++) {
					let completed = result[i].completed;
					let expertApproved = result[i].expertApproved;
					result[i].completedYesNo = completed ? `${t('yes')}` : `${t('no')}`;
					result[i].stationName = result[i].station?.name;
					result[i].cityName = result[i].station?.location?.city?.name;
					result[i].expertApproved = expertApproved
						? `${t('yes')}`
						: `${t('no')}`;
					result[i].countryName = result[i].station?.location?.country?.name;
					result[i].locationName = result[i].station?.location?.name;
					result[i].stationFullName = `${result[i].stationName ?? ''} / ${
						result[i].cityName ?? ''
					} / ${result[i].countryName ?? ''} / ${result[i].locationName ?? ''}`;
					result[i].date = moment(result[i].date).format('YYYY.MM.DD');
				}

				setAppointments(res.data);
				setLoading(false);
			});
		} else if (AuthenticationService.isOperator()) {
			axios
				.get('/appointments/getCompletedForOperator/' + user.userId)
				.then((res) => {
					let result = res.data;
					for (let i = 0; i < result.length; i++) {
						let completed = result[i].completed;
						result[i].completedYesNo = completed ? `${t('yes')}` : `${t('no')}`;
						result[i].stationName = result[i].station?.name;
						result[i].cityName = result[i].station?.location?.city?.name;
						result[i].countryName = result[i].station?.location?.country?.name;
						result[i].locationName = result[i].station?.location?.name;
						result[i].stationFullName = `${result[i].stationName ?? ''} / ${
							result[i].cityName ?? ''
						} / ${result[i].countryName ?? ''} / ${
							result[i].locationName ?? ''
						}`;
						result[i].date = moment(result[i].date).format('YYYY.MM.DD');
					}
					setAppointments(res.data);

					setLoading(false);
				});
		} else if (AuthenticationService.isExpert()) {
			axios.get('/appointments/getCompletedForExpert/').then((res) => {
				let result = res.data;
				for (let i = 0; i < result.length; i++) {
					let completed = result[i].completed;
					let expertApproved = result[i].expertApproved;
					result[i].completedYesNo = completed ? `${t('yes')}` : `${t('no')}`;
					result[i].expertApproved =
						expertApproved == true ? `${t('yes')}` : `${t('no')}`;
					result[i].stationName = result[i].station?.name;
					result[i].date = moment(result[i].date).format('YYYY.MM.DD');
				}
				setAppointments(res.data);
				setLoading(false);
			});
		} else {
			axios
				.get('/appointments/getCompletedForLocation/' + user.userId)
				.then((res) => {
					let result = res.data;
					for (let i = 0; i < result.length; i++) {
						let completed = result[i].completed;
						let expertApproved = result[i].expertApproved;
						result[i].completedYesNo = completed ? `${t('yes')}` : `${t('no')}`;
						result[i].expertApproved =
							expertApproved == true ? `${t('yes')}` : `${t('no')}`;
						result[i].stationName = result[i].station?.name;
						result[i].date = moment(result[i].date).format('YYYY.MM.DD');
						result[i].fullName = result[i].name + ' ' + result[i].surname;
					}
					setAppointments(result);
					setLoading(false);
				});
		}
	}, [langValue]);

	useEffect(() => {
		if (langValue === 'en') {
			setDateChangeLang('en-US');
		} else {
			setDateChangeLang('tr-TR');
		}
	}, [langValue]);

	const fetchSarfReportList = async (appointmentId) => {
		setLoading(true);
		try {
			const response = await axios.get(
				'/lookup/getSarfReportListByAppointmentId',
				{
					headers: {
						appointmentId,
					},
				}
			);
			setSarfReportList(response.data);
			setLoading(false);
			if (response.data.length > 0) {
				return true;
			} else return false;
		} catch (error) {
			setLoading(false);
			return false;
		}
	};

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };
		_filters['global'].value = value;
		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const acceptProcess = (appointmentId) => {
		setLoading(true);
		axios
			.get('/rawData/dataCalculatePyhton/' + appointmentId)
			.then((res) => {
				if (!res) {
					toast.current.show({
						severity: 'info',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.please_wait_report')}`,
						life: 3000,
					});
				} else {
					setLoading(false);
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.operation_success')}`,
						life: 3000,
					});
				}
			})
			.catch(function (error) {
				if (error.response) {
					setLoading(false);
					toast.current.show({
						severity: 'error',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.operation_failed')}`,
						life: 3000,
					});
				} else if (error.request) {
				} else {
				}
			});
	};

	const dataCalculatePyhton = (event, appointmentId) => {
		confirmPopup({
			target: event.currentTarget,
			message:
				appointmentId +
				` ${t('appointment_alert_messages.continue_report_appointment')}`,
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: () => acceptProcess(appointmentId),
			reject,
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
		});
	};

	const reject = () => {
		toast.current.show({
			severity: 'info',
			summary: `${t('appointment_alert_messages.transaction_status')}`,
			detail: `${t('appointment_alert_messages.oparation_cancel')}`,
			life: 3000,
		});
	};

	const queuingUpConfirm = (rowData) => {
		confirmDialog({
			message: `${t('queue_confirm_message_completed_appointment')}`,
			header: `${t('process_status')}`,
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
			accept: () => queuingUpAccept(rowData),
			reject: () => queuingUpReject(),
		});
	};

	const queuingUpAccept = (rowData) => {
		setLoading(true);

		const postData = {
			userId: rowData.userId,
			appointmentId: rowData.appointmentId,
		};
		axios.post('/reports/createReportQueueFromWeb', postData).then((res) => {
			toast.current.show({
				severity: 'info',
				summary: `${t('process_status')}`,
				detail: `${t('process_successfully')}`,
				life: 3000,
			});

			setLoading(false);
		});
	};

	const queuingUpReject = (err) => {
		toast.current.show({
			severity: 'warn',
			summary: `${t('appointment_alert_messages.denied')}`,
			detail: `${t('appointment_alert_messages.action_declined')}`,
			life: 3000,
		});
	};

	const shareReportIcon = (
		<svg
			fill='none'
			height='32'
			viewBox='0 0 20 20'
			width='32'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M3.25909 11.6021C3.94254 8.32689 6.79437 6 10 6C13.2057 6 16.0574 8.32688 16.7409 11.6021C16.7974 11.8725 17.0622 12.0459 17.3325 11.9895C17.6029 11.933 17.7763 11.6682 17.7199 11.3979C16.9425 7.67312 13.6934 5 10 5C6.3066 5 3.05742 7.67311 2.28017 11.3979C2.22377 11.6682 2.39718 11.933 2.6675 11.9895C2.93782 12.0459 3.20268 11.8725 3.25909 11.6021Z'
				fill='#0099F7'
			/>
			<path
				d='M10 8C8.067 8 6.5 9.567 6.5 11.5C6.5 13.433 8.067 15 10 15C11.933 15 13.5 13.433 13.5 11.5C13.5 9.567 11.933 8 10 8ZM7.5 11.5C7.5 10.1193 8.61929 9 10 9C11.3807 9 12.5 10.1193 12.5 11.5C12.5 12.8807 11.3807 14 10 14C8.61929 14 7.5 12.8807 7.5 11.5Z'
				fill='#0099F7'
			/>
		</svg>
	);
	const handleGoUserReportPage = (id) => {
		const url = `/ViewSharedReport/${id}?guid`;
		window.open(url);
	};

	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				{(AuthenticationService.isAdmin() ||
					AuthenticationService.isExpert()) && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
						}}
					>
						<Button
							type='button'
							tooltip={t('repeat_queue_appointment')}
							onClick={(event) => queuingUpConfirm(rowData)}
							className='p-button-text'
						>
							<svg
								fill='#0099F7'
								height='35'
								viewBox='0 0 20 20'
								width='35'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M6 2C4.89543 2 4 2.89543 4 4V9.20703C4.32228 9.11588 4.65659 9.05337 5 9.02242V4C5 3.44772 5.44772 3 6 3H10V6.5C10 7.32843 10.6716 8 11.5 8H15V16C15 16.5523 14.5523 17 14 17H10.4003C10.2174 17.3578 9.99647 17.6929 9.74284 18H14C15.1046 18 16 17.1046 16 16V7.41421C16 7.01639 15.842 6.63486 15.5607 6.35355L11.6464 2.43934C11.3651 2.15804 10.9836 2 10.5858 2H6ZM14.7929 7H11.5C11.2239 7 11 6.77614 11 6.5V3.20711L14.7929 7ZM5.5 19C7.98528 19 10 16.9853 10 14.5C10 12.0147 7.98528 10 5.5 10C3.01472 10 1 12.0147 1 14.5C1 16.9853 3.01472 19 5.5 19ZM5.85355 16.8536C5.65829 17.0488 5.34171 17.0488 5.14645 16.8536C4.95118 16.6583 4.95118 16.3417 5.14645 16.1464L6.29289 15H3.5C3.22386 15 3 14.7761 3 14.5C3 14.2239 3.22386 14 3.5 14H6.29289L5.14645 12.8536C4.95118 12.6583 4.95118 12.3417 5.14645 12.1464C5.34171 11.9512 5.65829 11.9512 5.85355 12.1464L7.85355 14.1464C7.90149 14.1944 7.93766 14.2496 7.96206 14.3086C7.98615 14.3667 7.9996 14.4303 7.99999 14.497L8 14.5L7.99999 14.503C7.9996 14.5697 7.98615 14.6333 7.96206 14.6914C7.93802 14.7495 7.90256 14.804 7.85567 14.8514L7.85316 14.854L5.85355 16.8536Z'
									fill='#0099F7'
								/>
							</svg>
						</Button>

						<Link
							to={{
								pathname: `/panel/tamamlanmis-randevular/${rowData.appointmentId}`,
								state: { user: rowData },
							}}
						>
							<Button
								type='button'
								tooltip={t('expert_opinion')}
								style={{ color: '#EC0044' }}
								className='p-button-text'
							>
								<svg
									fill='#0099F7'
									height='35'
									viewBox='0 0 20 20'
									width='35'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M10.5 12C11.3284 12 12 12.6716 12 13.5V14C12 15.9714 10.1405 18 7 18C3.85951 18 2 15.9714 2 14V13.5C2 12.6716 2.67157 12 3.5 12H10.5ZM10.5 13H3.5C3.22386 13 3 13.2239 3 13.5V14C3 15.4376 4.43216 17 7 17C9.56784 17 11 15.4376 11 14V13.5C11 13.2239 10.7761 13 10.5 13ZM7 5.5C8.51878 5.5 9.75 6.73122 9.75 8.25C9.75 9.76878 8.51878 11 7 11C5.48122 11 4.25 9.76878 4.25 8.25C4.25 6.73122 5.48122 5.5 7 5.5ZM16 2C17.0544 2 17.9182 2.81588 17.9945 3.85074L18 4V6C18 7.05436 17.1841 7.91817 16.1493 7.99451L16 8H14.499L13.301 9.59979C12.7716 10.306 11.6965 10.0199 11.5243 9.22426L11.5066 9.11264L11.5008 9L11.5 7.935L11.4224 7.91532C10.697 7.69685 10.1452 7.07753 10.0246 6.3148L10.0055 6.14926L10 6V4C10 2.94564 10.8159 2.08183 11.8507 2.00549L12 2H16ZM7 6.5C6.0335 6.5 5.25 7.2835 5.25 8.25C5.25 9.2165 6.0335 10 7 10C7.9665 10 8.75 9.2165 8.75 8.25C8.75 7.2835 7.9665 6.5 7 6.5ZM16 3H12C11.4872 3 11.0645 3.38604 11.0067 3.88338L11 4V6C11 6.51284 11.386 6.93551 11.8834 6.99327L12 7H12.5008V9L14 7H16C16.5128 7 16.9355 6.61396 16.9933 6.11662L17 6V4C17 3.48716 16.614 3.06449 16.1166 3.00673L16 3Z'
										fill='#0099F7'
									/>
								</svg>
							</Button>
						</Link>
						<Button
							type='button'
							tooltip={t('calculate_competencies')}
							style={{ color: '#001529' }}
							className='p-button-text p-button-secondary'
							onClick={() =>
								getSarfReportListFromSarfReportUsersByAppointmentId(
									rowData.appointmentId
								)
							}
						>
							<svg
								fill='#0099F7'
								height='35'
								viewBox='0 0 20 20'
								width='35'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M17 5.5C17 4.11929 15.8807 3 14.5 3H5.5C4.11929 3 3 4.11929 3 5.5V14.5C3 15.8807 4.11929 17 5.5 17H9.59971C9.43777 16.6832 9.30564 16.3486 9.20703 16H5.5C4.67157 16 4 15.3284 4 14.5V7H16V9.20703C16.3486 9.30564 16.6832 9.43777 17 9.59971V5.5ZM5.5 4H14.5C15.3284 4 16 4.67157 16 5.5V6H4V5.5C4 4.67157 4.67157 4 5.5 4Z'
									fill='#0099F7'
								/>
								<path
									d='M19 14.5C19 16.9853 16.9853 19 14.5 19C12.0147 19 10 16.9853 10 14.5C10 12.0147 12.0147 10 14.5 10C16.9853 10 19 12.0147 19 14.5ZM16.8532 14.854L16.8557 14.8514C16.9026 14.804 16.938 14.7495 16.9621 14.6914C16.9861 14.6333 16.9996 14.5697 17 14.503L17 14.5L17 14.497C16.9996 14.4303 16.9861 14.3667 16.9621 14.3086C16.9377 14.2496 16.9015 14.1944 16.8536 14.1464L14.8536 12.1464C14.6583 11.9512 14.3417 11.9512 14.1464 12.1464C13.9512 12.3417 13.9512 12.6583 14.1464 12.8536L15.2929 14H12.5C12.2239 14 12 14.2239 12 14.5C12 14.7761 12.2239 15 12.5 15H15.2929L14.1464 16.1464C13.9512 16.3417 13.9512 16.6583 14.1464 16.8536C14.3417 17.0488 14.6583 17.0488 14.8536 16.8536L16.8532 14.854Z'
									fill='#0099F7'
								/>
							</svg>
						</Button>
					</div>
				)}
				{AuthenticationService.isOperator() && (
					<Tooltip title={<>{t('download_pdf')}</>} placement='right-start'>
						<ButtonMaterial onClick={() => isCheckPdfDialog(rowData)}>
							<svg
								fill='none'
								height='34'
								viewBox='0 0 20 20'
								width='34'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M15.5 16.9988C15.7761 16.9988 16 17.2226 16 17.4988C16 17.7442 15.8231 17.9484 15.5899 17.9907L15.5 17.9988H4.5C4.22386 17.9988 4 17.7749 4 17.4988C4 17.2533 4.17688 17.0492 4.41012 17.0068L4.5 16.9988H15.5ZM10.0001 2.00098C10.2456 2.00098 10.4497 2.17798 10.492 2.41124L10.5 2.50112L10.496 14.295L14.1414 10.6466C14.3148 10.4729 14.5842 10.4534 14.7792 10.5882L14.8485 10.646C15.0222 10.8194 15.0418 11.0888 14.907 11.2838L14.8492 11.3531L10.3574 15.8531C10.285 15.9257 10.1957 15.9714 10.1021 15.9901L9.99608 15.9999C9.83511 15.9999 9.69192 15.9237 9.60051 15.8056L5.14386 11.3537C4.94846 11.1586 4.94823 10.842 5.14336 10.6466C5.3168 10.4729 5.58621 10.4534 5.78117 10.5883L5.85046 10.6461L9.496 14.287L9.5 2.50083C9.50008 2.22469 9.724 2.00098 10.0001 2.00098Z'
									fill='#2196F3'
								/>
							</svg>
						</ButtonMaterial>
					</Tooltip>
				)}
				{AuthenticationService.isLocationAdmin() && (
					<Button
						className='p-button-text'
						style={{ color: '#2196F3' }}
						type='button'
						tooltip={t('browse_report')}
						onClick={() => handleGoUserReportPage(rowData.appointmentId)}
					>
						{shareReportIcon}
					</Button>
				)}
			</React.Fragment>
		);
	};
	const renderHeader = () => {
		return (
			<div className='clearfix'>
				<div className='float-right p-2'>
					<span className='p-input-icon-left'>
						<i className='pi pi-search' />
						<InputText
							value={globalFilterValue}
							className='p-inputtext-sm'
							onChange={onGlobalFilterChange}
							placeholder={t('search...')}
						/>
					</span>
				</div>
			</div>
		);
	};

	const actionOperatorEdit = (rowData) => {
		return (
			<>
				<AlertDialog
					operatorInfo={rowData.operatorId}
					selected={rowData.operatorName}
				></AlertDialog>
			</>
		);
	};

	const openPdfModal = (data) => {
		setSelectedRowData(data);
		setPdfOpen(true);
	};
	const handleClosePdfModal = () => {
		setPdfOpen(false);
	};

	const handleChangePdfValue = (e) => {
		setPdfValue(e.target.value);
	};
	const downloadPdf = async () => {
		setLoadingPdf(true);
		const individualReportWithPasswordDto = {
			languageId: langValue,
			operatorId: selectedRowData.operatorId,
			appointmentId: selectedRowData.appointmentId,
			reportType: pdfValue,
			sarfReportId: selectedSarfReportId,
		};
		try {
			axios({
				method: 'post',
				url: '/reports/generateIndividualReportWithPassword',
				responseType: 'blob',
				data: {
					...individualReportWithPasswordDto,
				},
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/pdf',
				},
			})
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', selectedRowData.appointmentId + '.pdf');
					document.body.appendChild(link);

					link.click();
					link.remove();
					setLoadingPdf(false);
					setDownloadIsValid(false);
					setSarfReportList([]);
					setSarfReportListAdmin([]);
					showSuccess();
				})
				.catch((error) => {
					setLoadingPdf(false);
					setDownloadIsValid(false);
					showError();
				});
		} catch (error) {
			setLoadingPdf(false);
			setDownloadIsValid(false);
			showError();
		}
	};

	const isCheckPdfDialog = async (data) => {
		setSelectedRowData(data);
		await fetchSarfReportList(data.appointmentId).then((res) => {
			if (res) {
				setSarfReportDialog(true);
			} else {
				openPdfModal(data);
			}
		});
	};

	const createSarfReportForOneAppointment = async () => {
		setSarfReportDialogAdmin(false);
		setLoadingSarfReport(true);
		try {
			const response = await axios.get(
				'/sarfReport/createSarfReportForOneAppointment',
				{
					headers: {
						appointmentID: selectedAppointmentId,
						sarfReportId: selectedSarfReportId,
					},
				}
			);
			setLoadingSarfReport(false);
			enqueueSnackbar(
				`${t('enqueueSnackbar_message.competencies_successfully_calculated')}`,
				{
					variant: 'success',
				}
			);
			setSelectedAppointmentId(null);
			setSelectedSarfReportId(null);
		} catch (error) {
			if (error.response.data.includes('Demographic data is missing')) {
				enqueueSnackbar(
					`${t('enqueueSnackbar_message.Demographic_data_missing')}`,
					{
						variant: 'error',
					}
				);
			} else {
				enqueueSnackbar(
					`${t('enqueueSnackbar_message.failed_competencies_calculate')}`,
					{
						variant: 'error',
					}
				);
			}
			setLoadingSarfReport(false);
			setSelectedAppointmentId(null);
			setSelectedSarfReportId(null);
		}
	};

	const getSarfReportListFromSarfReportUsersByAppointmentId = async (id) => {
		try {
			const response = await axios.get(
				'/lookup/getSarfReportListFromSarfReportUsersByAppointmentId',
				{
					headers: { appointmentId: id },
				}
			);
			setSarfReportListAdmin(response.data);
			setSelectedAppointmentId(id);
			setSarfReportDialogAdmin(true);
		} catch (error) {
			enqueueSnackbar(`${t('enqueueSnackbar_message.please_radio_select')}`, {
				variant: 'error',
			});
		}
	};

	const passPdfDialog = () => {
		if (selectedSarfReportId) {
			setSarfReportDialog(false);
			setPdfOpen(true);
		} else {
			showWarning();
		}
	};

	const directPassPdfDialog = () => {
		setSelectedSarfReportId(null);
		setSarfReportDialog(false);
		setPdfOpen(true);
	};

	const handleCloseSarfModal = () => {
		setSarfReportDialog(false);
	};
	const handleCloseSarfModalAdmin = () => {
		setSarfReportDialogAdmin(false);
	};

	const radioGroupElement = ({ sarfReportId }) => {
		const handleChangeSarfReportId = (e) => {
			setSelectedSarfReportId(e.target.value);
		};
		return (
			<Radio
				checked={selectedSarfReportId === sarfReportId}
				onChange={handleChangeSarfReportId}
				value={sarfReportId}
				inputProps={{ 'aria-label': sarfReportId }}
			/>
		);
	};

	const showError = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error Message',
			detail: `${t('appointment_alert_messages.canceled_pdf')}`,
			life: 5000,
		});
	};

	const serverError = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Server Error',
			detail: `${t('Unable to connect to the server, please try again later')}`,
			life: 3000,
		});
	};
	const showSuccess = () => {
		toast.current.show({
			severity: 'success',
			summary: 'Success Message',
			detail: `${t('appointment_alert_messages.operation_success')}`,
			life: 3000,
		});
	};

	const showWarning = () => {
		enqueueSnackbar(`${t('enqueueSnackbar_message.please_radio_select')}`, {
			variant: 'warning',
		});
	};

	const confirmSarfReportPopup = () => {
		const findName = sarfReportListAdmin.find(
			(t) => t.sarfReportId === selectedSarfReportId
		);
		confirmDialog({
			message: `${t(`appointment_alert_messages.competency_score_name`, {
				name: findName?.sarfReportName,
			})}`,
			header: `${t('appointment_alert_messages.confirmation')}`,
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
			accept: () => createSarfReportForOneAppointment(),
			reject: () => reject(),
		});
	};

	return (
		<>
			<ConfirmDialog />
			<ConfirmPopup />
			<Spinner text={t('please_wait')} loading={loadingSarfReport} />
			<Spinner
				text={t('Please wait while your report is being prepared')}
				loading={loadingPdf}
			/>
			<PageTitle>{t('completed_appointments')}</PageTitle>
			<Toast ref={toast} />
			<div className='card card-custom gutter-b'>
				<div className='card-body'>
					<DataTable
						value={appointments}
						paginator
						showGridlines
						responsiveLayout='scroll'
						header={renderHeader}
						rows={10}
						paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
						rowsPerPageOptions={[10, 25, 50]}
						dataKey='id'
						rowHover
						filters={filters}
						filterDisplay='row'
						loading={loading}
						globalFilterFields={[
							'location.name',
							'name',
							'surname',
							'appointmentId',
							'name',
						]}
						emptyMessage={t('no_records_found')}
						currentPageReportTemplate={t('currentPageReportTemplate')}
					>
						{(AuthenticationService.isAdmin() ||
							AuthenticationService.isExpert() ||
							AuthenticationService.isOperator() ||
							AuthenticationService.isLocationAdmin()) && (
							<Column
								headerStyle={{
									width: '12rem',
									textAlign: 'center',
								}}
								style={{ minWidth: '150px' }}
								header={t('action')}
								body={actionBodyTemplate}
								className='actionBody'
								bodyStyle={{ textAlign: 'center' }}
							/>
						)}
						{AuthenticationService.isLocationAdmin() && (
							<Column
								header={t('name_surname')}
								sortable={true}
								field='fullName'
								filter
								style={{ maxWidth: '100px' }}
							></Column>
						)}

						<Column
							header={t('appointment_id')}
							sortable={true}
							field='appointmentId'
							filter
							style={{ maxWidth: '100px' }}
						></Column>
						<Column
							header={t('parent_id')}
							sortable={true}
							field='parentId'
							filter
							style={{ maxWidth: '100px' }}
						></Column>
						<Column
							header={t('user_id')}
							sortable={true}
							field='userId'
							filter
							style={{ maxWidth: '100px' }}
						></Column>
						{AuthenticationService.isAdmin() && (
							<Column
								header={t('name')}
								sortable={true}
								field='name'
								filter
								style={{ minWidth: '100px' }}
							></Column>
						)}
						{AuthenticationService.isAdmin() && (
							<Column
								header={t('surname')}
								sortable={true}
								field='surname'
								filter
								style={{ minWidth: '100px' }}
							></Column>
						)}
						<Column
							header={t('assessment_package')}
							field='assessmentPackageName'
							sortable={true}
							filter
							style={{ maxWidth: '120px' }}
						></Column>
						{(AuthenticationService.isAdmin() ||
							AuthenticationService.isExpert()) && (
							<Column
								header={t('expert_approved')}
								sortable={true}
								field='expertApproved'
								filter
								style={{ minWidth: '100px' }}
							></Column>
						)}
						<Column
							header={t('type')}
							field='type'
							sortable={true}
							filter
							style={{ maxWidth: '100px' }}
						></Column>
						{(AuthenticationService.isAdmin() ||
							AuthenticationService.isOperator()) && (
							<Column
								header={t('hub')}
								sortable={true}
								field='stationFullName'
								filter
								style={{ minWidth: '100px' }}
							></Column>
						)}
						<Column
							header={t('is_completed')}
							sortable={true}
							field='completedYesNo'
							filter
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('start_time')}
							sortable={true}
							field='start'
							filter
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('end_time')}
							sortable={true}
							field='end'
							filter
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('date')}
							sortable={true}
							field='date'
							body={(rowData) => {
								let d = rowData.date;
								let date = new Date(d);
								let formattedDate = date.toLocaleDateString(dateChangeLang);
								return formattedDate;
							}}
							filter
							style={{ minWidth: '100px' }}
						></Column>
						{(AuthenticationService.isAdmin() ||
							AuthenticationService.isOperator()) && (
							<Column
								header={t('operatorId')}
								sortable={true}
								field='operatorId'
								// body={actionOperatorEdit}
								filter
								style={{
									maxWidth: '100px',
									cursor: 'pointer',
								}}
							></Column>
						)}
						{AuthenticationService.isExpert() && (
							<Column
								header={t('operatorId')}
								sortable={true}
								field='operatorId'
								filter
								style={{
									maxWidth: '100px',
									cursor: 'pointer',
								}}
							></Column>
						)}
						<Column
							header={t('operatorName')}
							sortable={true}
							field='operatorName'
							filter
							style={{ minWidth: '100px' }}
						></Column>
					</DataTable>
				</div>
				<Dialog open={pdfOpen} onClose={handleClosePdfModal}>
					<DialogTitle>Pdf {t('Download')}</DialogTitle>
					<DialogContent style={{ width: 400 }}>
						<DialogContentText>
							{t('appointment_alert_messages.choose_select_report_type')}
						</DialogContentText>
						<FormControl>
							<RadioGroup
								aria-labelledby='demo-radio-buttons-group-label'
								name='radio-buttons-group'
								value={pdfValue}
								onChange={handleChangePdfValue}
							>
								<FormControlLabel
									value='1'
									control={<Radio />}
									label={t('detailed_report')}
								/>
								<FormControlLabel
									value='2'
									control={<Radio />}
									label={t('summary_report')}
								/>
							</RadioGroup>
						</FormControl>
					</DialogContent>
					<DialogActions style={{ justifyContent: 'space-between' }}>
						<ButtonMaterial
							className='ml-3'
							onClick={() => {
								setDownloadIsValid(true);
								handleClosePdfModal();
							}}
						>
							{t('ok')}
						</ButtonMaterial>
						<ButtonMaterial className='mr-3' onClick={handleClosePdfModal}>
							{t('Cancel')}
						</ButtonMaterial>
					</DialogActions>
				</Dialog>
				<Dialog
					open={downloadIsValid}
					onClose={() => setDownloadIsValid(false)}
				>
					<DialogContent style={{ width: 400 }}>
						<DialogContentText>
							{t('appointment_alert_messages.sms_send_to_pdf')}
						</DialogContentText>
					</DialogContent>
					<DialogActions style={{ justifyContent: 'space-between' }}>
						<ButtonMaterial className='ml-3' onClick={() => downloadPdf()}>
							{t('ok')}
						</ButtonMaterial>
						<ButtonMaterial
							className='mr-3'
							onClick={() => setDownloadIsValid(false)}
						>
							{t('Cancel')}
						</ButtonMaterial>
					</DialogActions>
				</Dialog>

				<Dialog open={sarfReportDialog} onClose={handleCloseSarfModal}>
					<DialogContent style={{ width: 600 }}>
						<DialogContentText style={{ color: '#000', marginBottom: 8 }}>
							{t('select_competency_report')}
						</DialogContentText>
						<DataTable
							value={sarfReportList}
							paginator
							rows={2}
							tableStyle={{ minWidth: '20rem', width: '100%' }}
							dataKey='sarfReportId'
						>
							<Column
								field='sarfReportId'
								header='Seçim'
								style={{ width: '50%', textAlign: 'center' }}
								body={radioGroupElement}
							></Column>
							<Column
								field='sarfReportName'
								header='SARF Rapor Adı'
								style={{ width: '50%' }}
							></Column>
						</DataTable>
					</DialogContent>
					<DialogActions style={{ justifyContent: 'space-between' }}>
						<ButtonMaterial variant='contained' onClick={() => passPdfDialog()}>
							{t('continue')}
						</ButtonMaterial>
						<ButtonMaterial onClick={() => directPassPdfDialog()}>
							{t('continue_without')}
						</ButtonMaterial>
						<ButtonMaterial onClick={() => handleCloseSarfModal()}>
							{t('Cancel')}
						</ButtonMaterial>
					</DialogActions>
				</Dialog>

				<Dialog
					open={sarfReportDialogAdmin}
					onClose={handleCloseSarfModalAdmin}
				>
					<DialogContent style={{ width: 600 }}>
						<DialogContentText style={{ color: '#000', marginBottom: 8 }}>
							{t('select_competency_report')}
						</DialogContentText>
						<DataTable
							value={sarfReportListAdmin}
							paginator
							rows={2}
							tableStyle={{ minWidth: '20rem', width: '100%' }}
							dataKey='sarfReportId'
						>
							<Column
								field='sarfReportId'
								header={t('choise')}
								style={{ width: '50%', textAlign: 'center' }}
								body={radioGroupElement}
							></Column>
							<Column
								field='sarfReportName'
								header={t('sarfReportName')}
								style={{ width: '50%' }}
							></Column>
						</DataTable>
					</DialogContent>
					<DialogActions style={{ justifyContent: 'space-between' }}>
						<ButtonMaterial
							variant='contained'
							onClick={() => confirmSarfReportPopup()}
						>
							{t('Calculate')}
						</ButtonMaterial>

						<ButtonMaterial onClick={() => handleCloseSarfModalAdmin()}>
							{t('Cancel')}
						</ButtonMaterial>
					</DialogActions>
				</Dialog>
			</div>
		</>
	);
};

export default withTranslation()(CompletedAppointments);
