import React, { useEffect, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LookupService from '../service/LookupService';
import { MainButton } from '../components/common/MainButton';
import store from '../Store';
import ComponentWrapper from './../components/ComponentWrapper';
import {
	Container,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import FormInput from './../components/common/FormInput';
import LargeSelectInput from './../components/common/LargeSelectInput';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { baseTheme } from './../baseTheme';
import SelectInput from './../components/common/SelectInput';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const otherRequired = (other, _) => other?.id === 0;

const datePickerTheme = createTheme({
	...baseTheme,
	palette: {
		primary: {
			main: '#0099F7',
		},
	},
});

const getObjectFromId = (list, id) => {
	return list.find((item) => item.id === id);
};
export const DoctorDemographicsForm = () => {
	const { t } = useTranslation();

	const Schema = Yup.object().shape({
		birthDate: Yup.string()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		gender: Yup.string()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		genderOther: Yup.string().when('gender', {
			is: (value, _) => value === '0',
			then: Yup.string().required(`${t('schema_shape.not_empty')}`),
			otherwise: Yup.string(),
		}),
		country: Yup.object()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		countryResidenceOther: Yup.string().when('country', {
			is: otherRequired,
			then: Yup.string().required(`${t('schema_shape.not_empty')}`),
			otherwise: Yup.string(),
		}),
		occupation: Yup.object()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		occupationOther: Yup.string().when('occupation', {
			is: otherRequired,
			then: Yup.string().required(`${t('schema_shape.not_empty')}`),
			otherwise: Yup.string(),
		}),
		jobTitle: Yup.object()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		jobTitleOther: Yup.string().when('jobTitle', {
			is: otherRequired,
			then: Yup.string().required(`${t('schema_shape.not_empty')}`),
			otherwise: Yup.string(),
		}),
	});
	const { enqueueSnackbar } = useSnackbar();

	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);

	const [jobTitles, setJobTitles] = useState([]);
	const [genders, setGenders] = useState([]);
	const [occupations, setOccupations] = useState([]);
	const [countries, setCountries] = useState([]);

	const { handleSubmit, watch, control, formState } = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		resolver: yupResolver(Schema),
	});

	const languageId = localStorage.getItem('i18nextLng');
	useEffect(() => {
		setLoading(true);
		LookupService.fetchDemographicLookups(languageId).then((responses) => {
			setJobTitles(responses[0].data);
			setGenders(responses[1].data);
			setCountries(responses[7].data);
			setOccupations(responses[8].data);

			setLoading(false);
		});
	}, [languageId]);

	const onFinish = (doctorDemographicsForm) => {
		// console.log('DoctorDemographicsForm.js formState.errors',formState.errors);
		if (!formState.isValid && Object.keys(formState.errors).length > 0) {
			enqueueSnackbar(`${t('demographic_form_popup.control_form_info')}`, {
				variant: 'error',
			});
			return;
		}

		doctorDemographicsForm['birthDate'] = new Date(
			doctorDemographicsForm['birthDate']
		);
		const genderId = doctorDemographicsForm['gender'];
		if (genderId === 0) {
			//Other selected
			doctorDemographicsForm['gender'] = null;
		} else {
			let genderObj = getObjectFromId(genders, parseInt(genderId));
			doctorDemographicsForm['gender'] = genderObj;
		}

		if (doctorDemographicsForm['countryResidenceOther']) {
			doctorDemographicsForm['country'] = null;
		}
		if (doctorDemographicsForm['occupationOther']) {
			doctorDemographicsForm['occupation'] = null;
		}
		if (doctorDemographicsForm['jobTitleOther']) {
			doctorDemographicsForm['jobTitle'] = null;
		}
		if (
			doctorDemographicsForm['jobTitle'] != null &&
			doctorDemographicsForm['jobTitle'].id === 0 &&
			(doctorDemographicsForm['jobTitleOther'] == null ||
				doctorDemographicsForm['jobTitleOther'] == '')
		) {
			doctorDemographicsForm['jobTitle'] = null;
		}
		axios
			.post('/users/doctorInfo', doctorDemographicsForm)
			.then(() => {
				enqueueSnackbar(`${t('demographic_form_popup.success_add')}`, {
					variant: 'success',
				});
				store.steps.current = 1;
			})
			.catch(function () {
				enqueueSnackbar(`${t('demographic_form_popup.error_add')}`, {
					variant: 'error',
				});
			});
	};

	const showFormError = () => {
		if (
			!formState.isValid &&
			formState.isSubmitting &&
			Object.keys(formState.errors).length > 0
		) {
			enqueueSnackbar(`${t('demographic_form_popup.control_form_info')}`, {
				variant: 'error',
			});
		}
	};

	const gender = watch('gender');
	const occupation = watch('occupation');
	const country = watch('country');
	const jobTitle = watch('jobTitle');

	return (
		<>
			<form noValidate onSubmit={handleSubmit(onFinish)}>
				{formState.isSubmitted && showFormError()}
				<ComponentWrapper subtitle={t('demographic_info_form')}>
					<Grid container spacing={1}>
						<Grid item={true} xs={12}>
							<Controller
								name='birthDate'
								control={control}
								defaultValue={null}
								render={({ field, fieldState: { error } }) => (
									<ThemeProvider theme={datePickerTheme}>
										<KeyboardDatePicker
											views={['year']}
											error={!!error}
											inputVariant='outlined'
											size='small'
											margin='dense'
											helperText={error?.message}
											minDate={new Date('1930')}
											maxDate={new Date()}
											label={t('year_of_birth')}
											{...field}
											renderInput={(props) => <TextField {...props} />}
										/>
									</ThemeProvider>
								)}
							/>
						</Grid>
						<Grid item={true} xs={12}>
							<Controller
								control={control}
								name='gender'
								defaultValue=''
								render={({ field, fieldState: { error } }) => (
									<FormControl error={!!error}>
										<FormLabel color='secondary' component='legend'>
											{t('gender_identify')}
										</FormLabel>
										<RadioGroup {...field}>
											{genders.map((gender) => (
												<FormControlLabel
													key={gender.id}
													value={String(gender.id)}
													control={<Radio />}
													label={gender.name}
												/>
											))}
											{/*<FormControlLabel*/}
											{/*  key={0}*/}
											{/*  value="0"*/}
											{/*  control={<Radio />}*/}
											{/*  label={t('other')}*/}
											{/*/>*/}
											<FormHelperText>{error?.message}</FormHelperText>
										</RadioGroup>
									</FormControl>
								)}
							/>
						</Grid>
						{gender && gender === '0' && (
							<Grid
								item={true}
								xs={12}
								container
								alignItems='center'
								spacing={1}
							>
								<Grid item={true} xs={12} md={8}>
									<Controller
										name='genderOther'
										defaultValue=''
										control={control}
										render={(props) => (
											<FormInput
												{...props}
												required
												label={t('other_gender')}
											/>
										)}
									/>
								</Grid>
							</Grid>
						)}

						<Grid item={true} xs={12} container alignItems='center' spacing={1}>
							<Grid item={true} xs={12} md={8}>
								<Controller
									control={control}
									name='country'
									render={(props) => (
										<SelectInput
											{...props}
											required
											label={t('indicate_country')}
											options={countries}
										/>
									)}
								/>
							</Grid>
							{country?.id === 0 && (
								<Grid item={true} xs={12} md={8}>
									<Controller
										name='countryResidenceOther'
										control={control}
										render={(props) => (
											<FormInput
												{...props}
												required
												label={t('indicate_country1')}
											/>
										)}
									/>
								</Grid>
							)}
						</Grid>
						<Grid item={true} xs={12} container alignItems='center' spacing={1}>
							<Grid item={true} xs={12} md={8}>
								<Controller
									control={control}
									name='occupation'
									render={(props) => (
										<LargeSelectInput
											{...props}
											label={t('specify_job')}
											options={occupations}
										/>
									)}
								/>
							</Grid>
							{occupation?.id === 0 && (
								<Grid item={true} xs={12} md={8}>
									<Controller
										name='occupationOther'
										control={control}
										render={(props) => (
											<FormInput
												{...props}
												required
												label={t('specify_job1')}
											/>
										)}
									/>
								</Grid>
							)}
						</Grid>

						<Grid item={true} xs={12} container alignItems='center' spacing={1}>
							<Grid item={true} xs={12} md={8}>
								<Controller
									control={control}
									name='jobTitle'
									render={(props) => (
										<SelectInput
											{...props}
											required
											label={t('current_emp_status')}
											options={jobTitles}
										/>
									)}
								/>
							</Grid>
							{jobTitle?.id === 0 && (
								<Grid item={true} xs={12} md={8}>
									<Controller
										name='jobTitleOther'
										control={control}
										render={(props) => (
											<FormInput
												{...props}
												required
												label={t('other_work_status')}
											/>
										)}
									/>
								</Grid>
							)}
						</Grid>

						<Grid item={true} xs={12} container alignItems='center' spacing={1}>
							<Grid item={true} xs={12} md={8}>
								<Controller
									name='companyName'
									control={control}
									render={(props) => (
										<FormInput
											margin='dense'
											{...props}
											label={t('current_job_title')}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</Grid>
				</ComponentWrapper>
				<Container style={{ marginTop: 24 }}>
					<Grid container justifyContent='flex-end'>
						<MainButton color='primary' type='submit'>
							{t('COMPLETE')}
						</MainButton>
					</Grid>
				</Container>
			</form>
		</>
	);
};
