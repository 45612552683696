import axios from 'axios';

class SarfPackageService {
	getList() {
		return axios.get('/lookup/getAllSarfPackage');
	}

	getById(id) {
		return axios.get('/lookup/getSarfPackageById/' + id);
	}

	save(row) {
		return axios.post('/lookup/saveNewSarfPackage', row);
	}

	update(row) {
		return axios.post('/lookup/updateSarfPackage', row);
	}
	delete(id) {
		return axios.delete(`/lookup/deleteSarfPackageById/${id}`);
	}
}

export default new SarfPackageService();
