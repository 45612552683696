import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/tr';
import DialogComponent from '../components/common/DialogComponent';
import { useSnackbar } from 'notistack';
import Spinner from '../components/common/Spinner';
import CreateNewAppointment from '../components/CreateNewAppointment';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import AlertDialog from '../components/DialogComp';
import { Dialog } from 'primereact/dialog';
import { MainButton } from '../components/common/MainButton';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import HomeAdmin from './HomeAdmin';
import { IconFlagTR, IconFlagDE, IconFlagUS } from 'material-ui-flags';
import IconButton from '@material-ui/core/IconButton';
import AuthenticationService from '../service/AuthenticationService';

const useStyles = makeStyles((theme) => ({
	card: {
		padding: 20,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	commChannels: {
		marginTop: theme.spacing(2),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	register: {
		'&:hover': {
			textDecoration: 'underline',
		},
		textTransform: 'none',
	},
}));

const PreRegisteredUsers = ({ t }) => {
	const [users, setUsers] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const user = AuthenticationService.getLoggedInUser();

	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		id: { value: null, matchMode: FilterMatchMode.CONTAINS },
		name: { value: null, matchMode: FilterMatchMode.CONTAINS },
		surname: { value: null, matchMode: FilterMatchMode.CONTAINS },
		customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
		email: { value: null, matchMode: FilterMatchMode.CONTAINS },
		mobilePhone: { value: null, matchMode: FilterMatchMode.CONTAINS },
		createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
		active: { value: null, matchMode: FilterMatchMode.CONTAINS },
		preferredLanguage: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});

	const toast = useRef(null);
	const classes = useStyles();

	useEffect(() => {
		fetchPreRegisteredUsers();
	}, []);

	const exportExcel = () => {
		import('xlsx').then((xlsx) => {
			const worksheet = xlsx.utils.json_to_sheet(filteredData);
			const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
			const excelBuffer = xlsx.write(workbook, {
				bookType: 'xlsx',
				type: 'array',
			});
			saveAsExcelFile(excelBuffer, 'files');
		});
	};

	const saveAsExcelFile = (buffer, fileName) => {
		import('file-saver').then((module) => {
			if (module && module.default) {
				let EXCEL_TYPE =
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				let EXCEL_EXTENSION = '.xlsx';
				const data = new Blob([buffer], {
					type: EXCEL_TYPE,
				});

				module.default.saveAs(
					data,
					fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
				);
			}
		});
	};

	const fetchPreRegisteredUsers = () => {
		setLoading(true);
		if (AuthenticationService.isLocationAdmin()) {
			axios
				.get('/users/getPreRegistrationUsersForLocationAdmin/' + user.userId)
				.then((res) => {
					fetchedUserDataFormatter(res.data);
				})
				.catch((err) => setLoading(false));
		} else {
			axios
				.get('/users/getPreRegistrationUsers')
				.then((res) => {
					fetchedUserDataFormatter(res.data);
				})
				.catch((err) => setLoading(false));
		}
	};

	const fetchedUserDataFormatter = (data) => {
		let result = data;
		let total = [];
		for (let i = 0; i < result.length; i++) {
			total.push({
				email: result[i].email,
				name: result[i].name,
				surname: result[i].surname,
				id: result[i].id,
				mobilePhone: '+' + result[i].mobilePrefix + ' ' + result[i].mobilePhone,
				assessmentPackages: result[i].assessmentPackages,
				preferredLanguage: result[i].preferredLanguage,
				customer: result[i].customer,
				customerName: result[i].customer?.name,
				location: result[i].location,
				webUILoginNotAllowed: result[i].webUILoginNotAllowed,
			});
		}
		setUsers(total);
		setFilteredData(total);
		setLoading(false);
	};

	const renderHeader = () => {
		return (
			<div className='clearfix'>
				<div className='float-right p-2'>
					<div className='flex align-items-center export-buttons'>
						{AuthenticationService.isAdmin() && (
							<Button
								type='button'
								icon='pi pi-file-excel'
								onClick={exportExcel}
								className='p-button-success mr-2'
								data-pr-tooltip='XLS'
							/>
						)}
					</div>
				</div>
			</div>
		);
	};
	const detailSvg = (
		<svg
			fill={'#0099F7'}
			height='32'
			viewBox='0 0 20 20'
			width='32'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7.5 7C7.22386 7 7 7.22385 7 7.5C7 7.77614 7.22386 8 7.5 8H12.5C12.7761 8 13 7.77614 13 7.5C13 7.22385 12.7761 7 12.5 7H7.5ZM7 10.5C7 10.2239 7.22386 9.99999 7.5 9.99999H12.1707C12.0602 10.3128 12 10.6494 12 11H7.5C7.22386 11 7 10.7761 7 10.5ZM15 4.5V8C15.3497 8 15.6866 8.0602 16 8.171V4.5C16 3.67157 15.3284 3 14.5 3H14V2.5C14 2.22386 13.7761 2 13.5 2C13.2239 2 13 2.22386 13 2.5V3H10.5V2.5C10.5 2.22386 10.2761 2 10 2C9.72386 2 9.5 2.22386 9.5 2.5V3H7V2.5C7 2.22386 6.77614 2 6.5 2C6.22386 2 6 2.22386 6 2.5V3H5.5C4.67157 3 4 3.67157 4 4.5V16.5C4 17.3284 4.67157 18 5.5 18H10.7509C10.6273 17.8209 10.5169 17.6308 10.4213 17.4302C10.3549 17.2909 10.296 17.1474 10.2449 17H5.5C5.22386 17 5 16.7761 5 16.5V4.5C5 4.22386 5.22386 4 5.5 4H14.5C14.7761 4 15 4.22386 15 4.5ZM7.5 13C7.22386 13 7 13.2238 7 13.5C7 13.7761 7.22386 14 7.5 14H9.5C9.77614 14 10 13.7761 10 13.5C10 13.2238 9.77614 13 9.5 13H7.5ZM17 11C17 10.2597 16.5978 9.61337 16 9.26756C15.7058 9.09739 15.3643 8.99999 15 8.99999C13.8954 8.99999 13 9.89542 13 11C13 12.1046 13.8954 13 15 13C15.3643 13 15.7058 12.9026 16 12.7324C16.5978 12.3866 17 11.7403 17 11ZM11 15.5C11 15.672 11.0109 15.8388 11.0321 16C11.0797 16.3621 11.1793 16.6964 11.324 17C11.5329 17.4383 11.8358 17.8127 12.2121 18.1148C12.9534 18.7098 13.9469 19 15 19C16.0531 19 17.0466 18.7098 17.7879 18.1148C18.541 17.5103 19 16.6161 19 15.5C19 14.6716 18.3284 14 17.5 14L12.5 14C11.6716 14 11 14.6716 11 15.5Z'
				fill={'#0099F7'}
			/>
		</svg>
	);

	const deleteSvg = (
		<svg
			fill='#EC0044'
			height='32'
			viewBox='0 0 20 20'
			width='32'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M11.5 4C11.5 3.17157 10.8284 2.5 10 2.5C9.17157 2.5 8.5 3.17157 8.5 4H7.5C7.5 2.61929 8.61929 1.5 10 1.5C11.3807 1.5 12.5 2.61929 12.5 4H17C17.2761 4 17.5 4.22386 17.5 4.5C17.5 4.77614 17.2761 5 17 5H16.446L15.1499 16.2292C15.0335 17.2384 14.179 18 13.1631 18H6.83688C5.821 18 4.9665 17.2384 4.85006 16.2292L3.553 5H3C2.75454 5 2.55039 4.82312 2.50806 4.58988L2.5 4.5C2.5 4.22386 2.72386 4 3 4H11.5ZM15.438 5H4.561L5.84347 16.1146C5.90169 16.6192 6.32894 17 6.83688 17H13.1631C13.6711 17 14.0983 16.6192 14.1565 16.1146L15.438 5ZM8.5 7.5C8.74546 7.5 8.94961 7.65477 8.99194 7.85886L9 7.9375V14.0625C9 14.3041 8.77614 14.5 8.5 14.5C8.25454 14.5 8.05039 14.3452 8.00806 14.1411L8 14.0625V7.9375C8 7.69588 8.22386 7.5 8.5 7.5ZM11.5 7.5C11.7455 7.5 11.9496 7.65477 11.9919 7.85886L12 7.9375V14.0625C12 14.3041 11.7761 14.5 11.5 14.5C11.2545 14.5 11.0504 14.3452 11.0081 14.1411L11 14.0625V7.9375C11 7.69588 11.2239 7.5 11.5 7.5Z'
				fill='#EC0044'
			/>
		</svg>
	);
	const confirm = (id) => {
		confirmDialog({
			message: `${id} ${t('appointment_alert_messages.delete_user_question')}`,
			header: `${t('appointment_alert_messages.confirmation')}`,
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
			accept: () => accept(id),
			reject: () => reject(),
		});
	};
	const accept = (id) => {
		deleteUser(id);
	};
	const reject = (id) => {
		toast.current.show({
			severity: 'warn',
			summary: `${t('appointment_alert_messages.denied')}`,
			detail: `${t('appointment_alert_messages.action_declined')}`,
			life: 3000,
		});
	};
	const deleteUser = (id) => {
		axios
			.delete(`/users/deletePreRegistration/${id}`)
			.then(() => {
				fetchPreRegisteredUsers();
				toast.current.show({
					severity: 'info',
					summary: `${t('appointment_alert_messages.congratulations')}`,
					detail: `${t('appointment_alert_messages.delete_user_success')}`,
					life: 3000,
				});
			})
			.catch((err) => error(err));
	};
	const error = (err) => {
		toast.current.show({
			severity: 'error',
			summary: `${t('appointment_alert_messages.short_error')}`,
			detail: `${err.response.data}`,
			life: 3000,
		});
	};

	const actionBodyTemplate = (rowData, props) => {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-around',
					alignItems: 'center',
				}}
			>
				<Button
					className='p-button-text'
					onClick={() => confirm(rowData.id)}
					tooltip={t('delete_user')}
				>
					{deleteSvg}
				</Button>
			</div>
		);
	};

	const selectedCountryTemplate = (rowData, option, props) => {
		if (option) {
			return (
				<div
					className='country-item country-item-value'
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-around',
						alignItems: 'center',
						paddingRight: '20px',
					}}
				>
					{rowData.preferredLanguage === 'en' ? (
						<IconButton className={classes.muiIcon}>
							<IconFlagUS />
						</IconButton>
					) : (
						<IconButton className={classes.muiIcon}>
							<IconFlagTR />
						</IconButton>
					)}
					<div>{rowData.preferredLanguage}</div>
				</div>
			);
		}
	};
	const customerTemplate = (rowData, option, props) => {
		if (rowData) {
			return <div>{rowData.customer?.name}</div>;
		}
	};

	const actionUserEdit = (rowData) => {
		console.log('rowData :>> ', rowData);
		return (
			<AlertDialog
				tooltip={t('delete_user')}
				preUserInfo={rowData}
				fetchPreRegisteredUsers={fetchPreRegisteredUsers}
			></AlertDialog>
		);
	};

	return (
		<div>
			<ConfirmDialog />
			<Spinner loading={loading} />
			<Toast ref={toast} />
			<div className='card card-custom gutter-b'>
				<div className='card-header'>
					<div className='card-title'>
						<h3 className='card-label'>{t('menu.pre_registered_users')}</h3>
					</div>
					<div className='card-toolbar'>
						<Link to='/panel/kullanici-on-kayit/0'>
							<Button
								type='button'
								icon='pi pi-plus'
								label={t('add_new_pre_register')}
								className='p-button-raised p-button-rounded p-button-outlined mr-3'
							/>
						</Link>
					</div>
				</div>
				<div className='card-body p-0'>
					<DataTable
						value={users}
						paginator
						showGridlines
						responsiveLayout='scroll'
						header={renderHeader}
						rows={10}
						onValueChange={(filteredData) => setFilteredData(filteredData)}
						paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
						rowsPerPageOptions={[10, 25, 50]}
						dataKey='id'
						rowHover
						filters={filters}
						filterDisplay='row'
						loading={loading}
						globalFilterFields={[
							'id',
							'name',
							'surname',
							'email',
							'mobilePhone',
							'createdAt',
						]}
						emptyMessage={t('no_records_found')}
						currentPageReportTemplate={t('currentPageReportTemplate')}
					>
						<Column
							headerStyle={{ width: '12rem', textAlign: 'center' }}
							header={t('action')}
							body={actionBodyTemplate}
							className='actionBody'
							bodyStyle={{ textAlign: 'center' }}
						/>
						<Column
							style={{
								maxWidth: '100px',
								textDecoration: 'underline',
								cursor: 'pointer',
							}}
							header={t('pre_register_no')}
							sortable={true}
							field='id'
							body={actionUserEdit}
							filter
						></Column>
						<Column
							header={t('name')}
							sortable={true}
							field='name'
							filter
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('surname')}
							sortable={true}
							field='surname'
							filter
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('email')}
							field='email'
							sortable={true}
							filter
							style={{ maxWidth: '100px' }}
						></Column>
						<Column
							header={t('mobile_phone')}
							sortable={true}
							field='mobilePhone'
							filter
							style={{ minWidth: '160px' }}
						></Column>
						<Column
							header={t('customer')}
							sortable={true}
							field='customerName'
							filter
							style={{ maxWidth: '110px' }}
							// body={customerTemplate}
							bodyStyle={{ textAlign: 'center' }}
						></Column>
						<Column
							header={t('preferred_language')}
							sortable={true}
							field='preferredLanguage'
							filter
							style={{ maxWidth: '110px' }}
							body={selectedCountryTemplate}
							bodyStyle={{ textAlign: 'center' }}
						></Column>
					</DataTable>
				</div>
			</div>
		</div>
	);
};

export default withTranslation()(PreRegisteredUsers);
