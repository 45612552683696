import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import Spinner from '../../common/Spinner';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import ButtonMaterial from '@mui/material/Button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
// import CustomerSarfModuleParametersService from '../../service/CustomerSarfModuleParametersService';
import SarfModuleCompetencyService from '../../../service/CustomerSarfModuleCompetencyService';
import SarfCompetencyService from '../../../service/SarfCompetencyService';
const UpdateParameterCompetencyModal = ({
	getAllCompetenciesByModulId,
	customerId,
	parameterTemplateId,
	selectedModuleCompetency,
	handleCloseUpdateModulCompetencyModal,
	updateCompetencyModalOpen,
	getDomainsBySarfReportParametersTemplateId,
	t,
	selectedSarfTemplateModul,
	setLoading,
	getCompetenciesBySarfReportParametersTemplateId,
}) => {
	const [weight, setWeight] = useState();
	const [selectedCompetency, setSelectedCompetency] = useState(null);
	const [competencies, setCompetencies] = useState([]);
	const toast = useRef(null);
	const toastInside = useRef(null);

	const getAllCompetencies = () => {
		SarfCompetencyService.getList()
			.then((res) => {
				setCompetencies(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getCompetencyById = (id) => {
		SarfModuleCompetencyService.getById(id)
			.then((res) => {
				setSelectedCompetency(res?.data?.sarfCompetencies);
				setWeight(res?.data?.weight);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		let unmounted = false;
		if (
			selectedModuleCompetency != null ||
			(selectedModuleCompetency != undefined && updateCompetencyModalOpen)
		) {
			getAllCompetencies();

			getCompetencyById(selectedModuleCompetency?.id);
		} else {
			return;
		}
		return () => {
			unmounted = true;
		};
	}, [updateCompetencyModalOpen]);

	const updateSarfTemplateModule = () => {
		if (customerId == null || customerId == undefined) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('appointment_alert_messages.transaction_error')}`,
				life: 3000,
			});

			return;
		}
		if (selectedCompetency == null || selectedCompetency == undefined) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_competency_info')}`,
				life: 3000,
			});

			return;
		}

		if (weight == '' || weight == null || weight == undefined) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_sarf_competency_weight')}`,
				life: 3000,
			});
			return;
		}

		const dataSent = {
			id: selectedModuleCompetency?.id,
			sarfCompetencyId: selectedCompetency?.id,
			sarfReportModuleParameterId: selectedSarfTemplateModul?.id,
			weight: weight,
		};
		setLoading(true);
		SarfModuleCompetencyService.update(dataSent)
			.then((res) => {
				setTimeout(() => {
					handleCloseUpdateModulCompetencyModal();
					setLoading(false);
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
						life: 3000,
					});
					setSelectedCompetency(null);
					setWeight(null);
				}, 2000);
				getAllCompetenciesByModulId(selectedSarfTemplateModul?.id);
				getCompetenciesBySarfReportParametersTemplateId(parameterTemplateId);
				getDomainsBySarfReportParametersTemplateId(parameterTemplateId);
			})
			.catch((error) => {
				setLoading(false);
				toastInside.current.show({
					severity: 'error',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: error.response.data,
					life: 3000,
				});
			});
	};

	return (
		<>
			<Toast ref={toast} />

			<Dialog
				open={updateCompetencyModalOpen}
				fullWidth={true}
				maxWidth={'sm'}
				onClose={() => {
					handleCloseUpdateModulCompetencyModal();
					setWeight(null);
					setSelectedCompetency(null);
				}}
			>
				<Toast ref={toastInside} />

				<DialogContent
					style={{
						overflowY: 'visible',
						display: 'flex',
						justifyContent: 'start',
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<DialogContentText
						style={{
							marginBottom: '30px',
							textAlign: 'center',
							fontWeight: 'bold',
						}}
					>
						{selectedSarfTemplateModul?.sarfModules?.name +
							' - ' +
							t('update_sarf_modul_competency')}
					</DialogContentText>

					<FormControl>
						<div style={{ width: '100%' }}>
							<label htmlFor=''>{t('sarf_competency')}</label>

							<Dropdown
								value={selectedCompetency}
								scrollHeight={'150px'}
								style={{
									width: '100%',
									marginTop: '10px',
									marginBottom: '10px',
								}}
								appendTo='self'
								className='p-inputtext-sm'
								onChange={(e) => {
									setSelectedCompetency(e.value);
								}}
								options={competencies}
								optionLabel='name'
							/>
						</div>

						<div style={{ width: '18%' }}>
							<label htmlFor='inputtext'>
								{t('contribution_weight') + ' (%) '}
							</label>
							<InputNumber
								id='inputtext'
								style={{
									width: '100%',
									marginTop: '10px',
									marginBottom: '10px',
								}}
								min={0}
								maxFractionDigits={0}
								value={weight}
								onValueChange={(e) => setWeight(e.value)}
							/>
						</div>
						<DialogActions style={{ paddingRight: '0' }}>
							<ButtonMaterial
								onClick={() => {
									handleCloseUpdateModulCompetencyModal();
								}}
							>
								{' '}
								{t('Cancel')}
							</ButtonMaterial>
							<ButtonMaterial
								variant='contained'
								onClick={() => {
									updateSarfTemplateModule();
								}}
							>
								{' '}
								{t('update')}
							</ButtonMaterial>
						</DialogActions>
					</FormControl>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default withTranslation()(UpdateParameterCompetencyModal);
