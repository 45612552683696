import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocationService from '../service/LocationService';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import Spinner from './common/Spinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact';
import { debounce } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Button } from 'primereact';
import { MainButton } from './common/MainButton';
import { Autocomplete, TextField } from '@mui/material';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { useSnackbar } from 'notistack';

const LocationInfo = ({ locationId, fetchLocations, t }) => {
	const [location, setLocation] = useState({
		id: '',
		country: {},
		city: {},
		name: '',
	});
	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);
	const [loading, setLoading] = useState(true);
	const [displayOperatorUserDialog, setDisplayOperatorUserDialog] =
		useState(false);
	const [displayLocationAdminUserDialog, setDisplayLocationAdminUserDialog] =
		useState(false);
	const [operatorByIncludingUsers, setOperatorByIncludingUsers] = useState([]);
	const [operatorTableData, setOperatorTableData] = useState([]);
	const [selectedOperator, setSelectedOperator] = useState(null);
	const [locationAdminByIncludingUsers, setLocationAdminByIncludingUsers] =
		useState([]);
	const [locationAdminTableData, setLocationAdminTableData] = useState([]);
	const [selectedLocationAdmin, setSelectedLocationAdmin] = useState(null);
	const languageId = localStorage.getItem('i18nextLng');
	const params = useParams();
	const toast = useRef(null);
	const previousController = useRef();
	locationId = parseInt(params.id);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (languageId != undefined || languageId != null) {
			getCountriesByLanguageId(languageId);
		} else {
			return;
		}
		getOperatorByLocation();
		getLocationAdminByLocation();

		if (
			locationId != null &&
			locationId !== 0 &&
			languageId != undefined &&
			languageId != null
		) {
			getList(locationId, languageId);
		} else {
			return;
		}
	}, [languageId, locationId]);

	const onSubmit = () => {
		if (
			location?.country?.id == null ||
			location?.country?.id === 0 ||
			location?.country?.id == undefined
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('select_country')}`,
			});
			return;
		}
		if (
			location?.city?.id == null ||
			location?.city?.id == 0 ||
			location?.city?.id == undefined
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('select_city')}`,
			});
			return;
		}
		location.name = location.name ? location.name.trim() : '';
		if (
			location?.name == null ||
			location?.name === '' ||
			location?.name == undefined
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_location_name')}`,
			});
			return;
		}
		if (location.id == null || location.id.length < 1) {
			LocationService.save(location)
				.then((res) => {
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
					});
					setTimeout(() => {
						handleOnClick();
					}, 1000);
				})
				.catch((err) => {
					toast.current.show({
						severity: 'error',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: err.response.data,
					});
				});
		} else {
			LocationService.update(location)
				.then((res) => {
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
					});
				})
				.catch((err) => {
					toast.current.show({
						severity: 'error',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: err.response.data,
					});
					console.log(err);
				});
		}
	};

	const getCities = (id) => {
		LocationService.getCitiesByCountryId(id)
			.then((resp) => {
				setCities(resp.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getCountriesByLanguageId = (languageId) => {
		LocationService.getCountriesByLanguageId(languageId)
			.then((res) => {
				setCountries(res.data);
				setTimeout(() => {
					setLoading(false);
				}, 1000);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};

	const getList = async (locationId, languageId) => {
		if (
			locationId != null &&
			locationId !== 0 &&
			languageId != undefined &&
			languageId != null
		) {
			const locationInfo = await LocationService.getById(
				locationId,
				languageId
			);
			getCities(locationInfo?.data?.country?.id);

			const countryInfo = await LocationService.getCountriesByLanguageId(
				languageId
			);

			if (
				countryInfo?.data != null &&
				countryInfo?.data != undefined &&
				countryInfo?.data?.length > 0
			) {
				const result = countryInfo?.data?.filter(
					(item) => item.id == locationInfo?.data?.country?.id
				);

				setLocation({ ...locationInfo?.data, country: result[0] });
				setTimeout(() => {
					setLoading(false);
				}, 2000);
			}
		}
	};

	// Operator Start

	const getOperatorByLocation = async () => {
		const { data } = await LocationService.getOperatorByLocation(locationId);
		for (let index of data) {
			index.fullName = index.name + ' ' + index.surname;
		}
		setOperatorTableData(data);
	};

	const saveOperatorForLocation = async (e) => {
		const payload = { locationId, operatorId: selectedOperator?.user?.id };
		LocationService.saveOperatorForLocation(payload)
			.then(() => {
				getOperatorByLocation();
				toast.current.show({
					severity: 'success',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: `${t('appointment_alert_messages.transaction_successful')}`,
				});
				setDisplayOperatorUserDialog(false);
				setOperatorByIncludingUsers([]);
				setSelectedOperator();
			})
			.catch((err) => {
				enqueueSnackbar(
					`${t(
						'enqueueSnackbar_message.Operator already exists this Locations'
					)}`,
					{
						variant: 'error',
					}
				);
			});
	};

	const searchOperatorByIncludingNameOrSurname = async (text) => {
		const { data } =
			await LocationService.searchOperatorByIncludingNameOrSurname(text);
		for (let index of data) {
			index.fullName = index.name + ' ' + index.surname;
		}
		setOperatorByIncludingUsers(data);
	};

	const searchForOperator = debounce((value) => {
		if (previousController.current) {
			previousController.current.abort();
		}
		const controller = new AbortController();
		const signal = controller.signal;
		previousController.current = controller;

		if (value.length >= 3) {
			searchOperatorByIncludingNameOrSurname(value);
		}
	}, 500);

	const onInputChange = (event, value, reason) => {
		if (value && event?._reactName !== 'onClick') {
			searchForOperator(value);
		} else if (value && event?._reactName == 'onClick') {
			return;
		}
	};

	const closeModal = () => {
		setDisplayOperatorUserDialog(false);
		setOperatorByIncludingUsers([]);
		setSelectedOperator();
	};

	const renderFooter = () => {
		return (
			<div style={{ textAlign: 'end' }}>
				<Button
					label={t('close')}
					className='p-button-raised p-button-info p-button-text'
					onClick={() => closeModal()}
				/>
				<Button
					label={t('ok')}
					className='p-button-info'
					onClick={saveOperatorForLocation}
				/>
			</div>
		);
	};

	const deleteSvg = (
		<svg
			fill='#EC0044'
			height='32'
			viewBox='0 0 20 20'
			width='32'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M11.5 4C11.5 3.17157 10.8284 2.5 10 2.5C9.17157 2.5 8.5 3.17157 8.5 4H7.5C7.5 2.61929 8.61929 1.5 10 1.5C11.3807 1.5 12.5 2.61929 12.5 4H17C17.2761 4 17.5 4.22386 17.5 4.5C17.5 4.77614 17.2761 5 17 5H16.446L15.1499 16.2292C15.0335 17.2384 14.179 18 13.1631 18H6.83688C5.821 18 4.9665 17.2384 4.85006 16.2292L3.553 5H3C2.75454 5 2.55039 4.82312 2.50806 4.58988L2.5 4.5C2.5 4.22386 2.72386 4 3 4H11.5ZM15.438 5H4.561L5.84347 16.1146C5.90169 16.6192 6.32894 17 6.83688 17H13.1631C13.6711 17 14.0983 16.6192 14.1565 16.1146L15.438 5ZM8.5 7.5C8.74546 7.5 8.94961 7.65477 8.99194 7.85886L9 7.9375V14.0625C9 14.3041 8.77614 14.5 8.5 14.5C8.25454 14.5 8.05039 14.3452 8.00806 14.1411L8 14.0625V7.9375C8 7.69588 8.22386 7.5 8.5 7.5ZM11.5 7.5C11.7455 7.5 11.9496 7.65477 11.9919 7.85886L12 7.9375V14.0625C12 14.3041 11.7761 14.5 11.5 14.5C11.2545 14.5 11.0504 14.3452 11.0081 14.1411L11 14.0625V7.9375C11 7.69588 11.2239 7.5 11.5 7.5Z'
				fill='#EC0044'
			/>
		</svg>
	);

	const confirmDelete = (event, rowData) => {
		confirmPopup({
			target: event.currentTarget,
			message: `${t('appointment_alert_messages.want_delete_register')}`,
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: () => acceptProcess(rowData),
			reject,
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
		});
	};

	const reject = () => {
		toast.current.show({
			severity: 'info',
			summary: `${t('appointment_alert_messages.transaction_status')}`,
			detail: `${t('appointment_alert_messages.oparation_cancel')}`,
			life: 3000,
		});
	};

	const acceptProcess = (rowData) => {
		LocationService.deleteOperatorForLocation(rowData.operatorLocationId).then(
			() => {
				getOperatorByLocation();
				toast.current.show({
					severity: 'success',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: `${t('appointment_alert_messages.transaction_successful')}`,
				});
			}
		);
	};

	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<div className='d-flex'>
					<Button
						type='button'
						tooltip={t('delete')}
						onClick={(event) => {
							confirmDelete(event, rowData);
						}}
						className='p-button-text'
					>
						{deleteSvg}
					</Button>
				</div>
			</React.Fragment>
		);
	};
	// Operator End

	// LocationAdmin Start
	const getLocationAdminByLocation = async () => {
		const { data } = await LocationService.getLocationAdminByLocation(
			locationId
		);
		for (let index of data) {
			index.fullName = index.name + ' ' + index.surname;
		}
		setLocationAdminTableData(data);
	};

	const saveLocationAdminForLocation = async (e) => {
		const payload = {
			locationId,
			locationAdminId: selectedLocationAdmin?.user?.id,
		};
		LocationService.saveLocationAdminForLocation(payload)
			.then(() => {
				getLocationAdminByLocation();
				toast.current.show({
					severity: 'success',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: `${t('appointment_alert_messages.transaction_successful')}`,
				});
				setDisplayLocationAdminUserDialog(false);
				setLocationAdminByIncludingUsers([]);
				setSelectedLocationAdmin();
			})
			.catch((err) => {
				enqueueSnackbar(
					`${t(
						'enqueueSnackbar_message.Operator already exists this Locations'
					)}`,
					{
						variant: 'error',
					}
				);
			});
	};

	const searchLocationAdminByIncludingNameOrSurname = async (text) => {
		const { data } =
			await LocationService.searchLocationAdminByIncludingNameOrSurname(text);
		for (let index of data) {
			index.fullName = index.name + ' ' + index.surname;
		}
		setLocationAdminByIncludingUsers(data);
	};

	const searchForLocationAdmin = debounce((value) => {
		if (previousController.current) {
			previousController.current.abort();
		}
		const controller = new AbortController();
		const signal = controller.signal;
		previousController.current = controller;

		if (value.length >= 3) {
			searchLocationAdminByIncludingNameOrSurname(value);
		}
	}, 500);

	const onInputChangeLocationAdmin = (event, value, reason) => {
		if (value && event?._reactName !== 'onClick') {
			searchForLocationAdmin(value);
		} else if (value && event?._reactName == 'onClick') {
			return;
		}
	};

	const closeLocationAdminModal = () => {
		setDisplayLocationAdminUserDialog(false);
		setLocationAdminByIncludingUsers([]);
		setSelectedLocationAdmin();
	};

	const renderFooterForLocationAdmin = () => {
		return (
			<div style={{ textAlign: 'end' }}>
				<Button
					label={t('close')}
					className='p-button-raised p-button-info p-button-text'
					onClick={() => closeLocationAdminModal()}
				/>
				<Button
					label={t('ok')}
					className='p-button-info'
					onClick={saveLocationAdminForLocation}
				/>
			</div>
		);
	};

	const confirmDeleteLocationAdmin = (event, rowData) => {
		confirmPopup({
			target: event.currentTarget,
			message: `${t('appointment_alert_messages.want_delete_register')}`,
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: () => acceptProcessLocationAdmin(rowData),
			rejectLocationAdmin,
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
		});
	};

	const rejectLocationAdmin = () => {
		toast.current.show({
			severity: 'info',
			summary: `${t('appointment_alert_messages.transaction_status')}`,
			detail: `${t('appointment_alert_messages.oparation_cancel')}`,
			life: 3000,
		});
	};

	const acceptProcessLocationAdmin = (rowData) => {
		LocationService.deleteLocationAdminForLocation(rowData.id).then(() => {
			getLocationAdminByLocation();
			toast.current.show({
				severity: 'success',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('appointment_alert_messages.transaction_successful')}`,
			});
		});
	};

	const actionBodyTemplateLocationAdmin = (rowData) => {
		return (
			<React.Fragment>
				<div className='d-flex'>
					<Button
						type='button'
						tooltip={t('delete')}
						onClick={(event) => {
							confirmDeleteLocationAdmin(event, rowData);
						}}
						className='p-button-text'
					>
						{deleteSvg}
					</Button>
				</div>
			</React.Fragment>
		);
	};

	// LocationAdmin End

	const history = useHistory();
	const handleOnClick = useCallback(
		() => history.push('/panel/lokasyonlar'),
		[history]
	);

	return (
		<>
			<ConfirmPopup />
			<Toast ref={toast} />
			<Spinner loading={loading}></Spinner>
			<div className='card card-custom gutter-b'>
				<div className='card-header'>
					<div className='card-title'>
						<h3 className='card-label'>{t('location_detail')}</h3>
					</div>
					<div className='card-toolbar'>
						<ul className='ust_menu'>
							<li tooltip={t('turn_back')} onClick={handleOnClick}>
								<i className='pi pi-arrow-left' />
							</li>
							<li tooltip={t('save_infos')} onClick={onSubmit}>
								<i className='pi pi-save ' />
							</li>
						</ul>
					</div>
				</div>
				<div className='card-body'>
					<form className='p-fluid grid'>
						<div className='field col-12 md:col-4'>
							<label htmlFor='dropdown'>{t('country')}</label>
							<Dropdown
								value={location.country}
								className='p-inputtext-sm'
								filter={true}
								onChange={(e) => {
									setLocation({ ...location, country: e.value, city: {} });
									getCities(e.value.id);
								}}
								options={countries}
								optionLabel='name'
							/>
						</div>

						<div className='field col-12 md:col-4'>
							<label htmlFor='dropdown'>{t('city')}</label>
							<Dropdown
								value={location.city}
								className='p-inputtext-sm'
								filter={true}
								onChange={(e) => setLocation({ ...location, city: e.value })}
								options={cities}
								optionLabel='name'
							/>
						</div>

						<div className='field col-12 md:col-4'>
							<label htmlFor='inputtext'>{t('location_name')}</label>
							<InputText
								id='inputtext'
								value={location.name}
								onChange={(e) =>
									setLocation({ ...location, name: e.target.value })
								}
								className='p-inputtext-sm'
							/>
						</div>
					</form>
				</div>
				<div style={{ display: 'flex' }}>
					{locationId !== 0 && (
						<>
							<div style={{ width: '50%' }}>
								<div
									className='card-header flex align-items-center'
									style={{ gap: 66 }}
								>
									<div className='card-title mb-0'>
										<h3 className='card-label'>
											{t('list_of_location_admins')}
										</h3>
									</div>
									<div className='card-toolbar'>
										<Button
											style={{
												position: 'absolute',
												top: '265px',
												left: '33%',
											}}
											type='button'
											icon='pi pi-plus'
											label={t('add_location_admin')}
											className='p-button-raised p-button-rounded p-button-outlined mr-3'
											onClick={() => setDisplayLocationAdminUserDialog(true)}
										/>
									</div>
								</div>
								<div className='card-body'>
									<DataTable
										value={locationAdminTableData}
										paginator
										showGridlines
										responsiveLayout='scroll'
										rows={10}
										paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
										rowsPerPageOptions={[10, 25, 50]}
										dataKey='id'
										rowHover
										loading={loading}
										emptyMessage={t('no_records_found')}
										currentPageReportTemplate={t('currentPageReportTemplate')}
									>
										<Column
											headerStyle={{ width: '8rem', textAlign: 'center' }}
											bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
											header={t('action')}
											body={actionBodyTemplateLocationAdmin}
										/>

										<Column
											field='locationAdminId'
											header={t('locationAdminId')}
											sortable
											style={{ minWidth: '14rem' }}
										/>

										<Column
											field='fullName'
											header={t('admin_name_surname')}
											sortable
											style={{ minWidth: '14rem' }}
										/>
									</DataTable>
								</div>
							</div>
						</>
					)}

					{locationId !== 0 && (
						<>
							<div style={{ width: '50%' }}>
								<div
									className='card-header flex align-items-center'
									style={{ gap: 66 }}
								>
									<div className='card-title mb-0'>
										<h3 className='card-label'>{t('duty_operator_list')}</h3>
									</div>
									<div className='card-toolbar'>
										<Button
											style={{
												position: 'absolute',
												top: '265px',
												left: '87%',
											}}
											type='button'
											icon='pi pi-plus'
											label={t('add_operator')}
											className='p-button-raised p-button-rounded p-button-outlined mr-3'
											onClick={() => setDisplayOperatorUserDialog(true)}
										/>
									</div>
								</div>
								<div className='card-body'>
									<DataTable
										value={operatorTableData}
										paginator
										showGridlines
										responsiveLayout='scroll'
										rows={10}
										paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
										rowsPerPageOptions={[10, 25, 50]}
										dataKey='id'
										rowHover
										loading={loading}
										emptyMessage={t('no_records_found')}
										currentPageReportTemplate={t('currentPageReportTemplate')}
									>
										<Column
											headerStyle={{ width: '8rem', textAlign: 'center' }}
											bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
											header={t('action')}
											body={actionBodyTemplate}
										/>

										<Column
											field='operatorId'
											header={t('user_id')}
											sortable
											style={{ minWidth: '14rem' }}
										/>

										<Column
											field='fullName'
											header={t('operatorName')}
											sortable
											style={{ minWidth: '14rem' }}
										/>
									</DataTable>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
			<Dialog
				header={t('enqueueSnackbar_message.please_choose_location_admin')}
				headerStyle={{ textAlign: 'center' }}
				footer={renderFooterForLocationAdmin}
				visible={displayLocationAdminUserDialog}
				breakpoints={{ '960px': '75vw', '640px': '100vw' }}
				style={{ width: '50vw' }}
				onHide={() => setDisplayLocationAdminUserDialog(false)}
			>
				<Autocomplete
					className='mt-2'
					id='combo-box-demo'
					options={locationAdminByIncludingUsers}
					onInputChange={onInputChangeLocationAdmin}
					getOptionLabel={(option) => {
						return `${option?.user?.id} - ${option?.fullName}`;
					}}
					onChange={(event, newValue) => {
						setSelectedLocationAdmin(newValue);
					}}
					value={selectedLocationAdmin}
					style={{ width: '100%' }}
					renderInput={(params) => (
						<TextField
							{...params}
							label={t('search_location_admin')}
							variant='outlined'
						/>
					)}
				/>
			</Dialog>
			<Dialog
				header={t('enqueueSnackbar_message.please_select_operator')}
				headerStyle={{ textAlign: 'center' }}
				footer={renderFooter}
				visible={displayOperatorUserDialog}
				breakpoints={{ '960px': '75vw', '640px': '100vw' }}
				style={{ width: '50vw' }}
				onHide={() => setDisplayOperatorUserDialog(false)}
			>
				<Autocomplete
					className='mt-2'
					id='combo-box-demo'
					options={operatorByIncludingUsers}
					onInputChange={onInputChange}
					getOptionLabel={(option) => {
						return `${option?.user?.id} - ${option?.fullName}`;
					}}
					onChange={(event, newValue) => {
						setSelectedOperator(newValue);
					}}
					value={selectedOperator}
					style={{ width: '100%' }}
					renderInput={(params) => (
						<TextField
							{...params}
							label={t('search_operator')}
							variant='outlined'
						/>
					)}
				/>
			</Dialog>
		</>
	);
};

export default withTranslation()(LocationInfo);
