import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import PageTitle from './../components/PageTitle';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { withTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PhoneInput from '../components/common/PhoneInput';
import { Button } from '@material-ui/core';
import PhoneInputEn from '../components/common/PhoneInputEn';
import './userIdquerry.css';

const UserIdQuery = ({ t }) => {
	const toast = useRef(null);
	const selected = localStorage.getItem('i18nextLng') || 'en';

	const { enqueueSnackbar } = useSnackbar();

	const Schema = Yup.object().shape({
		mobilePhone: Yup.string().required(`${t('schema_shape.not_empty')}`),
	});

	const { handleSubmit, control } = useForm({
		resolver: yupResolver(Schema),
	});

	const onSubmit = ({ mobilePhone }) => {
		axios
			.post(
				'/users/sendUserIdSMSToUserByGsmNumber' + '?gsmNumber=' + mobilePhone
			)
			.then((res) => {
				if (res.status === 200) {
					enqueueSnackbar(
						`${t('enqueueSnackbar_message.sms_sent_successfully')}`,
						{
							variant: 'success',
						}
					);
				}
			})
			.catch((error) => {
				if (error.response.status === 400) {
					toast.current.show({
						severity: 'error',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('entered_phone')}`,
					});
				}
			});
	};

	return (
		<>
			<ConfirmDialog />
			<PageTitle>{t('user_id_query')}</PageTitle>
			<Toast ref={toast} />
			<div className='card card-custom gutter-b' style={{ height: '600px' }}>
				<div>
					<label className='card-label'>
						<div
							style={{
								maxWidth: '750px',
								paddingTop: '40px',
								paddingLeft: '40px',
								fontWeight: 'bold',
								fontSize: '16px',
							}}
						>
							{t('send_sms_user_no')}
						</div>
					</label>
				</div>
				<div
					style={{
						width: '300px',
						fontSize: '15px',
						paddingTop: '30px',
						paddingLeft: '40px',
					}}
				>
					<label style={{ fontWeight: 'bold' }}>{t('mobile_number')}</label>
				</div>
				<form noValidate onSubmit={handleSubmit(onSubmit)}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'start',
							alignItems: 'center',
						}}
					>
						<div
							className='unique-phone-input-en'
							style={{
								width: '300px',
								position: 'relative',
								left: '0px',
								marginLeft: '-40px',
								marginTop: '2px',
							}}
						>
							{selected === 'tr' ? (
								<Controller
									name='mobilePhone'
									control={control}
									render={(props) => <PhoneInput {...props} />}
								/>
							) : (
								<Controller
									name='mobilePhone'
									control={control}
									render={(props) => <PhoneInputEn {...props} />}
								/>
							)}
						</div>
						<div>
							<Button
								setLoading={true}
								variant='contained'
								color='primary'
								style={{
									height: '2.76em',
									// width: '10em',
									marginLeft: '1em',
								}}
								type='submit'
							>
								{t('send_sms')}
							</Button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default withTranslation()(UserIdQuery);
