import React from 'react';
import AccessDenied from '../assets/images/accessDenied.png';
import { Box, Button, Container, Typography } from '@material-ui/core';
import LayoutComponent from '../components/LayoutComponent';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const NoPermission = ({ t }) => {
  let history = useHistory();

  return (
    <LayoutComponent>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="md">
          <Box sx={{ textAlign: 'center' }}>
            <img
              alt="Access denied"
              src={AccessDenied}
              style={{
                marginBottom: 50,
                display: 'inline-block',
                maxWidth: '100%',
                width: 560,
              }}
            />
          </Box>
          <Typography align="center" color="textPrimary" variant="h4">
            403: {t('no_permission')}
          </Typography>
          <Box
            marginTop="20px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push('/')}
            >
              {t('home_page')}
            </Button>
          </Box>
        </Container>
      </Box>
    </LayoutComponent>
  );
};

export default withTranslation()(NoPermission);
