import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { makeStyles } from '@material-ui/core/styles'
import './tabpanel.css'
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const useStyle = makeStyles({
  indicator: {
    display: 'none',
  },
  flexContainer: {
    maxWidth: '500px',
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  root: {
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: '500',
    color: '#000000 !important',
    textDecoration: 'underline !important',
  },
  selected: {
    color: '#cecece !important',
    textDecorationColor: '#cecece !important',
  },
})

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

export default function VerticalTabs2() {
  const [value, setValue] = React.useState(0)
  const classes = useStyle()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        height: 'auto',
        flexWrap: 'wrap',
      }}
    >
      <Tabs
        classes={{
          indicator: classes.indicator,
          flexContainer: classes.flexContainer,
        }}
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider', flex: 1 }}
        textColor="secondary"
      >
        <Tab
          label="Item One"
          {...a11yProps(0)}
          classes={{ root: classes.root, selected: classes.selected }}
        />

        <Tab
          classes={{ root: classes.root }}
          label="Item Two"
          {...a11yProps(1)}
        />
        <Tab
          classes={{ root: classes.root }}
          label="Item Three"
          {...a11yProps(2)}
        />
        <Tab
          classes={{ root: classes.root }}
          label="Item Four"
          {...a11yProps(3)}
        />
        <Tab
          classes={{ root: classes.root }}
          label="Item Five"
          {...a11yProps(4)}
        />
        <Tab
          classes={{ root: classes.root }}
          label="Item Six"
          {...a11yProps(5)}
        />
        <Tab
          classes={{ root: classes.root }}
          label="Item Seven"
          {...a11yProps(6)}
        />
      </Tabs>
      <div className="tabpanels">
        <TabPanel value={value} index={0}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint natus,
          fugiat corrupti soluta iste dolore cum, perspiciatis ex aliquid maxime
          minus dolor! Dicta nostrum quo eum! Hic facilis provident quasi.
        </TabPanel>
        <TabPanel value={value} index={1}>
          Item Two
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
        <TabPanel value={value} index={3}>
          Item Four
        </TabPanel>
        <TabPanel value={value} index={4}>
          Item Five
        </TabPanel>
        <TabPanel value={value} index={5}>
          Item Six
        </TabPanel>
        <TabPanel value={value} index={6}>
          Item Seven
        </TabPanel>
      </div>
    </Box>
  )
}
