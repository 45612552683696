import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LookupService from '../service/LookupService';
import DemographicsService from '../service/DemographicsService';

import { MainButton } from '../components/common/MainButton';
import store from '../Store';
import ComponentWrapper from '../components/ComponentWrapper';
import {
	Container,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import FormInput from '../components/common/FormInput';
import LargeSelectInput from '../components/common/LargeSelectInput';
import axios from 'axios';
import SelectInput from '../components/common/SelectInput';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const otherRequired = (other, _) => other?.id === 0;

const otherChoice = { id: 0, name: 'Diğer' };
const radioOtherChoice = '{ "id": 0, "name": "Diğer" }';

const DemographicsUpdateForm = ({ userId }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const Schema = Yup.object().shape({
		birthDate: Yup.string()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		gender: Yup.object()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		country: Yup.object()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		countryResidenceOther: Yup.string().when('country', {
			is: otherRequired,
			then: Yup.string().required(`${t('schema_shape.not_empty')}`),
			otherwise: Yup.string(),
		}),
		occupation: Yup.object().nullable(),
		// .required(`${t('schema_shape.not_empty')}`),
		occupationOther: Yup.string().when('occupation', {
			is: otherRequired,
			then: Yup.string(), //required(`${t('schema_shape.not_empty')}`),
			otherwise: Yup.string(),
		}),
		workStatus: Yup.object().nullable(),
		// .required(`${t('schema_shape.not_empty')}`),
		workStatusOther: Yup.string().when('workStatus', {
			is: otherRequired,
			then: Yup.string(), //required(`${t('schema_shape.not_empty')}`),
			otherwise: Yup.string(),
		}),
		// jobTitle: Yup.object().nullable().required(`${t('schema_shape.not_empty')}`),
		// jobTitleOther: Yup.string().when('jobTitle', {
		//   is: otherRequired,
		//   then: Yup.string().required(`${t('schema_shape.not_empty')}`),
		//   otherwise: Yup.string(),
		// }),
		// ethnicity: Yup.object()
		//     .nullable()
		//     .required(`${t('schema_shape.not_empty')}`),
		// ethnicityOther: Yup.string().when('ethnicity', {
		//     is: otherRequired,
		//     then: Yup.string().required(`${t('schema_shape.not_empty')}`),
		//     otherwise: Yup.string(),
		// }),
		// maritalStatus: Yup.object().nullable().required(`${t('schema_shape.not_empty')}`),
		// maritalStatusOther: Yup.string().when('maritalStatus', {
		//   is: otherRequired,
		//   then: Yup.string().required(`${t('schema_shape.not_empty')}`),
		//   otherwise: Yup.string(),
		// }),
		// childStatus: Yup.object().nullable().required(`${t('schema_shape.not_empty')}`),
		education: Yup.object()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		// annualIncome: Yup.object().nullable().required(`${t('schema_shape.not_empty')}`),
	});

	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [jobTitles, setJobTitles] = useState([]);
	const [genders, setGenders] = useState([]);
	const [ethnicities, setEthnicities] = useState([]);
	const [maritalStatuses, setMaritalStatuses] = useState([]);
	const [childStatuses, setChildStatuses] = useState([]);
	const [educations, setEducations] = useState([]);
	const [annualIncomes, setAnnualIncomes] = useState([]);
	const [occupations, setOccupations] = useState([]);
	const [countries, setCountries] = useState([]);
	const [workStatuses, setWorkStatuses] = useState([]);

	const { handleSubmit, watch, setValue, control, formState } = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		resolver: yupResolver(Schema),
	});

	const languageId = localStorage.getItem('i18nextLng');
	useEffect(() => {
		setLoading(true);

		if (userId) {
			DemographicsService.getDemographicsInfoByUserId(userId).then((res) => {
				let data = res.data;
				setData(data);

				setValue('groupName', data.groupName);
				setValue('companyName', data.companyName);
				setValue('birthDate', new Date(data.birthDate));
				setValue('country', data.country ? data.country : otherChoice);
				if (!data.country) {
					setValue('countryResidenceOther', data.countryResidenceOther);
				}

				setValue('jobTitle', data.jobTitle ? data.jobTitle : otherChoice);
				if (!data.jobTitle) {
					setValue('jobTitleOther', data.jobTitleOther);
				}

				setValue('occupation', data.occupation ? data.occupation : otherChoice);
				if (!data.occupation) {
					setValue('occupationOther', data.occupationOther);
				}
				//
				// setValue('ethnicity', data.ethnicity ? data.ethnicity : otherChoice);
				// if (!data.ethnicity) {
				//     setValue('ethnicityOther', data.ethnicityOther);
				// }

				setValue(
					'maritalStatus',
					data.maritalStatus ? data.maritalStatus : otherChoice
				);
				if (!data.maritalStatus) {
					setValue('maritalStatusOther', data.maritalStatusOther);
				}

				setValue('gender', data.gender ? data.gender : otherChoice);
				if (!data.gender) {
					setValue('genderOther', data.genderOther);
				}

				setValue(
					'workStatus',
					data.workStatus ? JSON.stringify(data.workStatus) : radioOtherChoice
				);
				if (!data.workStatus) {
					setValue('workStatusOther', data.workStatusOther);
				}

				setValue('childStatus', data.childStatus);
				setValue('annualIncome', data.annualIncome);
				setValue('education', data.education);
			});
		}

		LookupService.fetchDemographicLookups(languageId).then((responses) => {
			// if(responses[0].data!=null){
			//   let list = [{"id":"","name":" "}];
			//
			//   for (const item of responses[0].data) {
			//     list.push(item);
			//   }
			//   setJobTitles(list);
			// }

			setJobTitles(responses[0].data);
			setGenders(responses[1].data);
			setEthnicities(responses[2].data);
			setMaritalStatuses(responses[3].data);
			setChildStatuses(responses[4].data);
			setEducations(responses[5].data);
			setAnnualIncomes(responses[6].data);
			setCountries(responses[7].data);
			setOccupations(responses[8].data);
			setWorkStatuses(responses[9].data);
			setLoading(false);
		});
	}, [setValue, userId, languageId]);

	const onFinish = (demographicsForm) => {
		if (!formState.isValid && Object.keys(formState.errors).length > 0) {
			enqueueSnackbar(`${t('demographic_form_popup.control_form_info')}`, {
				variant: 'error',
			});
			return;
		}

		demographicsForm['birthDate'] = new Date(demographicsForm['birthDate']);
		if (demographicsForm['genderOther']) {
			demographicsForm['gender'] = null;
		}
		if (demographicsForm['countryResidenceOther']) {
			demographicsForm['country'] = null;
		}
		if (demographicsForm['occupationOther']) {
			demographicsForm['occupation'] = null;
		}
		if (demographicsForm['workStatusOther']) {
			demographicsForm['workStatus'] = null;
		} else {
			demographicsForm['workStatusOther'] = null;
		}
		if (demographicsForm['jobTitleOther']) {
			demographicsForm['jobTitle'] = null;
		}
		if (
			demographicsForm['jobTitle'] != null &&
			demographicsForm['jobTitle'].id === 0 &&
			(demographicsForm['jobTitleOther'] == null ||
				demographicsForm['jobTitleOther'] == '')
		) {
			demographicsForm['jobTitle'] = null;
		}
		// console.log('jobTitle', demographicsForm['jobTitle']);
		// console.log('jobTitleOther', demographicsForm['jobTitleOther']);

		// if (demographicsForm['ethnicityOther']) {
		//     demographicsForm['ethnicity'] = null;
		// }
		if (demographicsForm['maritalStatusOther']) {
			demographicsForm['maritalStatus'] = null;
		}
		if (
			demographicsForm['maritalStatus'] != null &&
			demographicsForm['maritalStatus'].id === 0 &&
			(demographicsForm['maritalStatusOther'] == null ||
				demographicsForm['maritalStatusOther'] == '')
		) {
			demographicsForm['maritalStatus'] = null;
		}
		axios
			.post('/users/demographics', demographicsForm)
			.then(() => {
				enqueueSnackbar(`${t('demographic_form_popup.success_update')}`, {
					variant: 'success',
				});
				store.steps.current = 1;
				store.isEditMode = false;
			})
			.catch(function () {
				enqueueSnackbar(`${t('demographic_form_popup.error_update')}`, {
					variant: 'error',
				});
			});
	};

	const gender = watch('gender');
	const occupation = watch('occupation');
	const workStatus = watch('workStatus');
	const country = watch('country');
	// const ethnicity = watch('ethnicity');
	const jobTitle = watch('jobTitle');
	const maritalStatus = watch('maritalStatus');

	if (workStatus && JSON.parse(workStatus).id !== 0) {
		setValue('workStatusOther', '');
	}

	const showFormError = () => {
		if (
			!formState.isValid &&
			formState.isSubmitting &&
			Object.keys(formState.errors).length > 0
		) {
			enqueueSnackbar(`${t('demographic_form_popup.control_form_info')}`, {
				variant: 'error',
			});
		}
	};

	return (
		<>
			{data && (
				<form noValidate onSubmit={handleSubmit(onFinish)}>
					{formState.isSubmitted && showFormError()}
					<ComponentWrapper subtitle={t('demographic_info_update')}>
						<Grid container spacing={2}>
							<Grid item={true} xs={12}>
								<TextField
									label={t('birth_day')}
									variant='outlined'
									size='small'
									disabled={true}
									defaultValue={moment(data.birthDate).format('YYYY')}
									InputProps={{ readOnly: true }}
								/>
							</Grid>
							<Grid item={true} xs={12}>
								<Controller
									control={control}
									name='gender'
									render={({ field, fieldState: { error } }) => (
										<FormControl error={!!error}>
											<FormLabel color='secondary' component='legend'>
												{t('gender_identify')} *
											</FormLabel>
											<RadioGroup
												defaultValue={
													data.gender
														? JSON.stringify(data.gender)
														: radioOtherChoice
												}
												{...field}
											>
												{genders.map((gender) => (
													<FormControlLabel
														key={gender.id}
														value={JSON.stringify(gender)}
														disabled={true}
														control={<Radio />}
														label={gender.name}
													/>
												))}
												{/*<FormControlLabel*/}
												{/*    key={0}*/}
												{/*    value={radioOtherChoice}*/}
												{/*    disabled={true}*/}
												{/*    control={<Radio/>}*/}
												{/*    label={t('other')}*/}
												{/*/>*/}
												<FormHelperText>{error?.message}</FormHelperText>
											</RadioGroup>
										</FormControl>
									)}
								/>
							</Grid>
							{gender && gender.id === 0 && (
								<Grid
									item={true}
									xs={12}
									container
									alignItems='center'
									spacing={1}
								>
									<Grid item={true} xs={12} md={8}>
										<Controller
											name='genderOther'
											control={control}
											render={(props) => (
												<FormInput
													{...props}
													disabled={true}
													defaultValue={data.genderOther || null}
													required
													label={t('other_gender')}
												/>
											)}
										/>
									</Grid>
								</Grid>
							)}

							<Grid
								item={true}
								xs={12}
								container
								alignItems='center'
								spacing={1}
							>
								<Grid item={true} xs={12} md={8}>
									<Controller
										control={control}
										name='country'
										render={(props) => (
											<SelectInput
												{...props}
												defaultValue={data.country || otherChoice}
												required
												label={t('indicate_country')}
												options={countries}
											/>
										)}
									/>
								</Grid>
								{country?.id === 0 && (
									<Grid item={true} xs={12} md={8}>
										<Controller
											name='countryResidenceOther'
											control={control}
											render={(props) => (
												<FormInput
													{...props}
													required
													defaultValue={data.countryResidenceOther || null}
													label={t('indicate_country1')}
												/>
											)}
										/>
									</Grid>
								)}
							</Grid>
							<Grid
								item={true}
								xs={12}
								container
								alignItems='center'
								spacing={1}
							>
								<Grid item={true} xs={12} md={8}>
									<Controller
										control={control}
										name='occupation'
										render={(props) => (
											<LargeSelectInput
												{...props}
												defaultValue={data.occupation || otherChoice}
												label={t('specify_job')}
												options={occupations}
											/>
										)}
									/>
								</Grid>
								{occupation?.id === 0 && (
									<Grid item={true} xs={12} md={8}>
										<Controller
											name='occupationOther'
											control={control}
											render={(props) => (
												<FormInput
													{...props}
													required
													defaultValue={data.occupationOther || null}
													label={t('specify_job1')}
												/>
											)}
										/>
									</Grid>
								)}
							</Grid>

							<Grid item={true} xs={12}>
								<Controller
									control={control}
									name='workStatus'
									render={({ field, fieldState: { error } }) => (
										<FormControl error={!!error}>
											<FormLabel component='legend'>
												{t('current_emp_status')} *
											</FormLabel>
											<RadioGroup
												defaultValue={
													data.workStatus
														? JSON.stringify(data.workStatus)
														: radioOtherChoice
												}
												error={error}
												{...field}
											>
												{workStatuses.map((workStatus) => (
													<FormControlLabel
														key={workStatus.id}
														value={JSON.stringify(workStatus)}
														control={<Radio />}
														label={workStatus.name}
													/>
												))}
												<FormControlLabel
													key={0}
													value={radioOtherChoice}
													control={<Radio />}
													label={t('other')}
												/>
												<FormHelperText>{error?.message}</FormHelperText>
											</RadioGroup>
										</FormControl>
									)}
								/>
							</Grid>
							{workStatus && JSON.parse(workStatus).id === 0 && (
								<Grid
									item={true}
									xs={12}
									container
									alignItems='center'
									spacing={1}
								>
									<Grid item={true} xs={12} md={8}>
										<Controller
											name='workStatusOther'
											control={control}
											render={(props) => (
												<FormInput
													{...props}
													defaultValue={data.workStatusOther || null}
													required
													label={t('other_work_status')}
												/>
											)}
										/>
									</Grid>
								</Grid>
							)}

							<Grid
								item={true}
								xs={12}
								container
								alignItems='center'
								spacing={1}
							>
								<Grid item={true} xs={12} md={8}>
									<Controller
										control={control}
										name='jobTitle'
										render={(props) => (
											<SelectInput
												{...props}
												defaultValue={
													data.jobTitle ||
													(data.jobTitleOther == null ||
													data.jobTitleOther == ''
														? null
														: otherChoice)
												}
												label={t('current_job_title')}
												options={jobTitles}
											/>
										)}
									/>
								</Grid>
								{jobTitle?.id === 0 && (
									<Grid item={true} xs={12} md={8}>
										<Controller
											name='jobTitleOther'
											control={control}
											render={(props) => (
												<FormInput
													{...props}
													defaultValue={data.jobTitleOther || null}
													label={t('other_job_title')}
												/>
											)}
										/>
									</Grid>
								)}
							</Grid>

							{/*<Grid*/}
							{/*    item={true}*/}
							{/*    xs={12}*/}
							{/*    container*/}
							{/*    alignItems="center"*/}
							{/*    spacing={1}*/}
							{/*>*/}
							{/*    <Grid item={true} xs={12} md={8}>*/}
							{/*        <Controller*/}
							{/*            control={control}*/}
							{/*            name="ethnicity"*/}
							{/*            render={(props) => (*/}
							{/*                <SelectInput*/}
							{/*                    {...props}*/}
							{/*                    required*/}
							{/*                    disabled={true}*/}
							{/*                    defaultValue={data.ethnicity || otherChoice}*/}
							{/*                    label={t('indicate_ethnic_group')}*/}
							{/*                    options={ethnicities}*/}
							{/*                />*/}
							{/*            )}*/}
							{/*        />*/}
							{/*    </Grid>*/}
							{/*    {ethnicity?.id === 0 && (*/}
							{/*        <Grid item xs={12} md={8}>*/}
							{/*            <Controller*/}
							{/*                name="ethnicityOther"*/}
							{/*                control={control}*/}
							{/*                render={(props) => (*/}
							{/*                    <FormInput*/}
							{/*                        {...props}*/}
							{/*                        defaultValue={data.ethnicityOther || null}*/}
							{/*                        required*/}
							{/*                        label={t('other_ethnic_group')}*/}
							{/*                    />*/}
							{/*                )}*/}
							{/*            />*/}
							{/*        </Grid>*/}
							{/*    )}*/}
							{/*</Grid>*/}

							<Grid
								item={true}
								xs={12}
								container
								alignItems='center'
								spacing={1}
							>
								<Grid item={true} xs={12} md={8}>
									<Controller
										control={control}
										name='maritalStatus'
										render={(props) => (
											<SelectInput
												{...props}
												defaultValue={
													data.maritalStatus ||
													(data.maritalStatusOther == null ||
													data.maritalStatusOther == ''
														? null
														: otherChoice)
												}
												label={t('marital_status')}
												options={maritalStatuses}
											/>
										)}
									/>
								</Grid>
								{maritalStatus?.id === 0 && (
									<Grid item={true} xs={12} md={8}>
										<Controller
											name='maritalStatusOther'
											control={control}
											render={(props) => (
												<FormInput
													{...props}
													defaultValue={data.maritalStatusOther || null}
													label={t('other_marital_status')}
												/>
											)}
										/>
									</Grid>
								)}
							</Grid>

							<Grid
								item={true}
								xs={12}
								container
								alignItems='center'
								spacing={1}
							>
								<Grid item={true} xs={12} md={8}>
									<Controller
										control={control}
										name='childStatus'
										render={(props) => (
											<SelectInput
												{...props}
												dontIncludeOther
												defaultValue={data.childStatus || null}
												label={t('have_children')}
												options={childStatuses}
											/>
										)}
									/>
								</Grid>
							</Grid>

							<Grid
								item={true}
								xs={12}
								container
								alignItems='center'
								spacing={1}
							>
								<Grid item={true} xs={12} md={8}>
									<Controller
										control={control}
										name='education'
										render={(props) => (
											<SelectInput
												{...props}
												dontIncludeOther
												defaultValue={data.education || null}
												required
												label={t('education_degree')}
												options={educations}
											/>
										)}
									/>
								</Grid>
							</Grid>

							<Grid
								item={true}
								xs={12}
								container
								alignItems='center'
								spacing={1}
							>
								<Grid item={true} xs={12} md={8}>
									<Controller
										control={control}
										name='annualIncome'
										render={(props) => (
											<SelectInput
												{...props}
												dontIncludeOther
												defaultValue={data.annualIncome || null}
												label={t('current_annual_incomes')}
												options={annualIncomes}
											/>
										)}
									/>
								</Grid>
							</Grid>

							<Grid
								item={true}
								xs={12}
								container
								alignItems='center'
								spacing={1}
							>
								<Grid item={true} xs={12} md={8}>
									<Controller
										name='companyName'
										control={control}
										render={(props) => (
											<FormInput
												margin='dense'
												defaultValue={data.companyName || null}
												{...props}
												label={t('current_work_company')}
											/>
										)}
									/>
								</Grid>
							</Grid>

							{/*<Grid*/}
							{/*    item={true}*/}
							{/*    xs={12}*/}
							{/*    container*/}
							{/*    alignItems="center"*/}
							{/*    spacing={1}*/}
							{/*>*/}
							{/*    <Grid item={true} xs={12} md={8}>*/}
							{/*        <Controller*/}
							{/*            name="groupName"*/}
							{/*            control={control}*/}
							{/*            render={(props) => (*/}
							{/*                <FormInput*/}
							{/*                    margin="dense"*/}
							{/*                    defaultValue={data.groupName || null}*/}
							{/*                    {...props}*/}
							{/*                    label={t('as_group')}*/}
							{/*                />*/}
							{/*            )}*/}
							{/*        />*/}
							{/*    </Grid>*/}
							{/*</Grid>*/}
						</Grid>
					</ComponentWrapper>
					<Container style={{ marginTop: 24 }}>
						<Grid container justifyContent='flex-end'>
							<MainButton color='secondary' type='submit'>
								{t('update')}
							</MainButton>
							<MainButton
								onClick={() => {
									store.steps.current = 1;
									store.isEditMode = false;
								}}
								color='primary'
								type='submit'
								className='ml-2'
							>
								{t('NEXT')}
							</MainButton>
						</Grid>
					</Container>
				</form>
			)}
		</>
	);
};
export default DemographicsUpdateForm;
