import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { withTranslation } from 'react-i18next';
import CompanySizeService from '../service/CompanySizeService';

const CompanySizeInfo = ({ companySizeId, fetchCompanySizes, t }) => {
	const toast = useRef(null);
	const [companySize, setCompanySize] = useState({
		id: '',
		name: '',
	});

	const params = useParams();
	companySizeId = params.id;

	const onSubmit = () => {
		companySize.name = companySize.name.trim();
		if (companySize.name.length > 255) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_category_length')}`,
			});
			return;
		}
		if (companySize.name == null || companySize.name === '') {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_size_info')}`,
			});
			return;
		}
		if (companySize.id == 0) {
			CompanySizeService.save(companySize)
				.then((res) => {
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
					});
					setTimeout(() => {
						handleOnClick();
					}, 1000);
				})
				.catch((error) => {
					toast.current.show({
						severity: 'error',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: error.response.data,
					});
				});
		} else {
			CompanySizeService.update(companySize)
				.then((res) => {
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
					});
					setTimeout(() => {
						handleOnClick();
					}, 1000);
				})
				.catch((error) => {
					toast.current.show({
						severity: 'error',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: error.response.data,
					});
				});
		}
	};

	useEffect(() => {
		if (companySizeId != 0) {
			getList(companySizeId);
		} else {
			return;
		}
	}, [companySizeId]);

	const getList = (companySizeId) => {
		if (companySizeId != null && companySizeId !== 0) {
			CompanySizeService.getById(companySizeId).then((res) => {
				setCompanySize(res.data);
			});
		}
	};
	const history = useHistory();
	const handleOnClick = useCallback(
		() => history.push('/panel/sirket-buyuklukleri'),
		[history]
	);

	return (
		<>
			<Toast ref={toast} />
			<div className='card card-custom gutter-b'>
				<div className='card-header'>
					<div className='card-title'>
						<h3 className='card-label'>{t('company_size_detail')}</h3>
					</div>
					<div className='card-toolbar'>
						<ul className='ust_menu'>
							<li tooltip={t('turn_back')} onClick={handleOnClick}>
								<i className='pi pi-arrow-left' />
							</li>
							<li tooltip={t('save_infos')} onClick={onSubmit}>
								<i className='pi pi-save ' />
							</li>
						</ul>
					</div>
				</div>
				<div className='card-body'>
					<form className='p-fluid grid'>
						<div className='field col-12 md:col-4'>
							<label htmlFor='inputtext'>{t('company_size')}</label>
							<InputText
								id='inputtext'
								style={{ width: '600px' }}
								value={companySize.name}
								onChange={(e) =>
									setCompanySize({ ...companySize, name: e.target.value })
								}
								className='p-inputtext-sm'
							/>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};
export default withTranslation()(CompanySizeInfo);
