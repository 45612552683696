import React from 'react';
import {
	Card,
	Container,
	CssBaseline,
	Grid,
	Hidden,
	useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Footer from './Footer';
import SmallHeader from './SmallHeader';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 40,
		paddingBottom: 40,
		minHeight: '91vh',
		[theme.breakpoints.down('sm')]: {
			paddingTop: 0,
			paddingBottom: 0,
		},
	},
	container: {
		padding: 20,
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export const CardLayout = ({ children, title, preferredLanguage }) => {
	const classes = useStyles();
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<div className='card-layout-background'>
			<Hidden mdUp>
				<SmallHeader title={title} preferredLanguage={preferredLanguage} />
			</Hidden>
			<Grid
				container
				spacing={0}
				direction='row'
				alignItems={mobile ? 'center' : 'flex-start'}
				className={classes.root}
				justifyContent='center'
			>
				<CssBaseline />
				<Grid container item={true} xs={12} sm={10} md={6}>
					<Hidden smDown>
						<SmallHeader title={title} preferredLanguage={preferredLanguage} />
					</Hidden>
					<Card style={{ width: '100%' }}>
						<Container className={classes.container}>{children}</Container>
					</Card>
				</Grid>
			</Grid>
			<Footer />
		</div>
	);
};
