import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
	Calendar,
	momentLocalizer,
	Views,
	dateFnsLocalizer,
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const useStyles = makeStyles((theme) => ({
	root: {},
	paper: {
		padding: theme.spacing(3),
		width: '100%',
	},
	calendar: {
		overflowX: 'scroll',
		width: '100%',
		fontSize: '0.77em',
	},
}));

const AppointmentCalendarDetail = ({
	station,
	stations,
	setStation,
	startDate,
	endDate,
	setDateRange,
	stationId,
	t,
	localizer,
	messages,
	events,
	components,
	appointmentByStationAndDate,
}) => {
	const classes = useStyles();
	const localLang = localStorage.getItem('i18nextLng') ?? 'tr';
	return (
		<>
			<div
				className={classes.root}
				style={{ width: '32%', marginLeft: '30px' }}
			>
				<div style={{ display: 'block', marginTop: '0.4em' }}>
					<label htmlFor='dropdown' style={{ fontSize: '1.75em' }}>
						{t('hub')}
					</label>
				</div>
				<div>
					<div style={{ width: '45.5%', marginTop: '0.4em' }}>
						<Dropdown
							style={{ width: '220%', height: '40px', marginBottom: '2rem' }}
							value={station}
							filter={true}
							disabled={stations.length === 0}
							appendTo='self'
							onChange={(e) => {
								setStation(e.value);
							}}
							options={stations}
							optionLabel='fullName'
							placeholder={t('select_hub')}
						/>
					</div>
					<div style={{ display: 'flex' }}>
						<DatePicker
							// disabled={true}
							dateFormat={localLang === 'tr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
							selectsRange={true}
							startDate={startDate}
							endDate={endDate}
							onChange={(update) => {
								setDateRange(update);
							}}
							locale={localLang}
							isClearable={true}
							wrapperClassName='date-picker'
						/>
						<Button
							setLoading={true}
							variant='contained'
							color='primary'
							style={{
								height: '2.7em',
								width: '10em',
								marginBottom: '2.5em',
								marginTop: '0.6em',
							}}
							onClick={() => {
								appointmentByStationAndDate(startDate, endDate, stationId);
							}}
						>
							{t('list')}
						</Button>
					</div>
				</div>
				<Paper className={classes.paper}>
					<Calendar
						culture={localLang}
						className={classes.calendar}
						selectable
						localizer={localizer}
						messages={messages}
						events={events}
						step={60}
						timeslots={1}
						views={[Views.WEEK, Views.DAY, Views.AGENDA]}
						components={components}
						min={moment('09:00', 'HH:mm').toDate()}
						max={moment('19:00', 'HH:mm').toDate()}
						defaultView={Views.WEEK}
						scrollToTime={new Date()}
						defaultDate={new Date()}
					/>
				</Paper>
			</div>
		</>
	);
};
export default AppointmentCalendarDetail;
