import ChevronLeft from '@material-ui/icons/ChevronLeft';
import axios from 'axios';
import { tr } from 'date-fns/locale';

export const USER_SESSION_ATTRIBUTE_NAME = 'authenticatedUser';
export const USER_TOKEN = 'USER_TOKEN';
export const CREATE_APPOINTMENT_VALUE = 'createAppointmentValue';

class AuthenticationService {
  executeJwtAuthenticationService(credentials) {
    return axios.post('/auth/authenticate', credentials);
  }

  registerSuccessfulLoginForJwt(email, token) {
    localStorage.setItem(USER_TOKEN, token);
    this.setupAxiosInterceptors();

    const userDetailsRequest = {
      email,
      searchType: 'EMAIL',
    };
    return axios.post('/users/getUser', userDetailsRequest, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  getProjectSettings() {
    axios.get('dashboard/getProjectSettings').then((res) => {
      const value = res.data[0].value;
      localStorage.setItem('createAppointmentValue', value);
    });
  }

  createJWTToken(token) {
    return 'Bearer ' + token;
  }

  logout() {
    localStorage.removeItem(USER_SESSION_ATTRIBUTE_NAME);
    localStorage.removeItem(USER_TOKEN);
    localStorage.removeItem(CREATE_APPOINTMENT_VALUE);
  }

  isUserLoggedIn() {
    let user = localStorage.getItem(USER_SESSION_ATTRIBUTE_NAME);
    if (user === null) return false;
    return true;
  }

  getLoggedInUser() {
    let user = localStorage.getItem(USER_SESSION_ATTRIBUTE_NAME);
    if (user === null) return '';
    return JSON.parse(user);
  }
  isUser() {
    let isUser = false;
    const user = this.getLoggedInUser();
    if (user) {
      if (user.roles.includes('USER')) isUser = true;
    }
    return isUser;
  }
  isAdmin() {
    let isAdmin = false;
    const user = this.getLoggedInUser();
    if (user) {
      if (user.roles.includes('ADMIN')) isAdmin = true;
    }
    return isAdmin;
  }

  isOperator() {
    let isOperator = false;
    const user = this.getLoggedInUser();
    if (user) {
      if (user.roles.includes('OPERATOR')) isOperator = true;
    }
    return isOperator;
  }

  isLocationAdmin() {
    let isLocationAdmin = false;
    const user = this.getLoggedInUser();
    if (user) {
      if (user.roles.includes('LOCATION_ADMIN')) isLocationAdmin = true;
    }

    return isLocationAdmin;
  }

  isExpert() {
    let isExpert = false;
    const user = this.getLoggedInUser();
    if (user) {
      if (user.roles.includes('EXPERT')) isExpert = true;
    }
    return isExpert;
  }

  isDoctor() {
    let isDoctor = false;
    const user = this.getLoggedInUser();
    if (user) {
      if (user.roles.includes('DOCTOR')) isDoctor = true;
    }

    return isDoctor;
  }

  isDoctorOrAdmin() {
    let isDoctorOrAdmin = false;
    const user = this.getLoggedInUser();
    if (user) {
      if (user.roles.includes('DOCTOR')) isDoctorOrAdmin = true;
      if (user.roles.includes('ADMIN')) isDoctorOrAdmin = true;
      if (user.roles.includes('OPERATOR')) isDoctorOrAdmin = true;
      if (user.roles.includes('EXPERT')) isDoctorOrAdmin = true;
    }
    return isDoctorOrAdmin;
  }

  setupAxiosInterceptors() {
    return axios.interceptors.request.use((config) => {
      if (this.isUserLoggedIn()) {
        config.headers['Access-Control-Allow-Origin'] = '*';
        config.headers.Authorization = this.createJWTToken(
          localStorage.getItem(USER_TOKEN)
        );
      }
      return config;
    });
  }

  getImageUrlByUserId(userId) {
    return axios.get(`/users/images/${userId}`);
  }
}

export default new AuthenticationService();
