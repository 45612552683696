import axios from 'axios';

class CustomerSarfCompetencyDomainParametersService {
	getAllSarfDomainParameters() {
		return axios.get('/lookup/getAllSarfReportCompetencyDomainsParameters');
	}

	getDomainsByParameterTemplateId(id) {
		return axios.get(
			'/lookup/getSarfReportCompetencyDomainsParametersBySarfReportParametersTemplateId/' +
				id
		);
	}

	getDomainsByCompetencyParameterId(id) {
		return axios.get(
			'/lookup/getSarfCompetencyDomainsByCompetencyParameterId/' + id
		);
	}

	getById(id) {
		return axios.get(
			'/lookup/getSarfReportCompetencyDomainsParameterById/' + id
		);
	}

	save(row) {
		return axios.post('/lookup/saveSarfReportCompetencyDomainsParameter', row);
	}

	update(row) {
		return axios.post(
			'/lookup/updateSarfReportCompetencyDomainsParameter',
			row
		);
	}
	delete(id) {
		return axios.delete(
			'/lookup/deleteSarfReportCompetencyDomainsParameterById/' + id
		);
	}
}

export default new CustomerSarfCompetencyDomainParametersService();
