import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Spinner from './common/Spinner';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';

import CustomerDetailInfo from './CustomerDetail/CustomerDetailInfo';

const CustomerInfo = ({ customerId, t }) => {
	const [loading, setLoading] = useState(true);
	const langValue = localStorage.getItem('i18nextLng') || 'en';

	const params = useParams();
	customerId = params.id;

	return (
		<>
			<ConfirmDialog />
			<ConfirmPopup />
			<Spinner loading={loading}></Spinner>
			<CustomerDetailInfo
				setLoading={setLoading}
				customerId={customerId}
				langValue={langValue}
				t={t}
			></CustomerDetailInfo>
		</>
	);
};
export default withTranslation()(CustomerInfo);
