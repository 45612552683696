import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { EditUser } from './EditUser';
import { EditOperator } from './EditOperator';
import { CreateText } from './AutoReportText/CreateText';
import { EditText } from './AutoReportText/EditText';
import { SarfReportCreateText } from './AutoReportText/SarfReportCreateText';
import { Button as Buttons } from 'primereact/button';
import { PreRegisteredUserEdit } from './PreRegisteredUserEdit';
import { SarfReportEditText } from './AutoReportText/SarfReportEditText';

function AlertDialog({
	userInfo,
	fetchUsers,
	fetchPreRegisteredUsers,
	operatorInfo,
	selected,
	autoReportTextId,
	createReport,
	createSarfReport,
	sarfReportEdit,
	children,
	textInfo,
	sarfTextInfo,
	textData,
	fetchAutoReportText,
	width,
	preUserInfo,
	tooltip,
}) {
	const [open, setOpen] = React.useState(false);
	const [maxWidth, setMaxWidth] = React.useState('md');
	const userID = userInfo;
	const operatorId = operatorInfo;
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			{preUserInfo && (
				<Button onClick={handleClickOpen}>{preUserInfo.id}</Button>
			)}
			{fetchUsers && userInfo && (
				<Button onClick={handleClickOpen}>{userID}</Button>
			)}

			{operatorInfo && <Button onClick={handleClickOpen}>{operatorId}</Button>}
			{createReport && <div onClick={handleClickOpen}>{children}</div>}
			{createSarfReport && <div onClick={handleClickOpen}>{children}</div>}
			{textInfo && <div onClick={handleClickOpen}> {children} </div>}

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={maxWidth}
			>
				{fetchUsers && userInfo && (
					<EditUser
						fetchUsers={fetchUsers}
						onClose={handleClose}
						userInfo={userID}
					/>
				)}

				{operatorInfo && (
					<EditOperator
						onClose={handleClose}
						operatorInfo={operatorId}
						selected={selected}
					/>
				)}
			</Dialog>
			{preUserInfo && (
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
					maxWidth={maxWidth}
				>
					{preUserInfo && (
						<PreRegisteredUserEdit
							fetchPreRegisteredUsers={fetchPreRegisteredUsers}
							onClose={handleClose}
							userInfo={preUserInfo}
						/>
					)}
				</Dialog>
			)}

			{textInfo && (
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
					maxWidth={maxWidth}
					fullWidth={true}
					// fullScreen={fullScreen}
				>
					{textInfo && (
						<EditText
							onClose={handleClose}
							textId={textInfo}
							textData={textData}
							fetchAutoReportText={fetchAutoReportText}
						/>
					)}
				</Dialog>
			)}

			{textInfo && sarfReportEdit && (
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
					maxWidth={maxWidth}
					fullWidth={true}
					// fullScreen={fullScreen}
				>
					{textInfo && (
						<SarfReportEditText
							onClose={handleClose}
							textId={textInfo}
							textData={textData}
							fetchAutoReportText={fetchAutoReportText}
						/>
					)}
				</Dialog>
			)}

			{createReport && (
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
					maxWidth={maxWidth}
					fullWidth={true}
				>
					{createReport && (
						<CreateText
							fetchAutoReportText={fetchAutoReportText}
							onClose={handleClose}
						/>
					)}
				</Dialog>
			)}

			{createSarfReport && (
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
					maxWidth={maxWidth}
					fullWidth={true}
				>
					{createSarfReport && (
						<SarfReportCreateText
							fetchAutoReportText={fetchAutoReportText}
							onClose={handleClose}
						/>
					)}
				</Dialog>
			)}
		</div>
	);
}
export default React.memo(AlertDialog);
