import React, { useEffect, useState } from 'react';
import {
	Box,
	Container,
	Grid,
	makeStyles,
	TextField,
	Typography,
} from '@material-ui/core';
import ComponentWrapper from './ComponentWrapper';
import AuthenticationService from '../service/AuthenticationService';
import moment from 'moment';
import { MainButton } from './common/MainButton';
import store from './../Store';
import DemographicsService from '../service/DemographicsService';
import UserService from '../service/UserService';
import Spinner from './common/Spinner';
import { useTranslation } from 'react-i18next';

const inputProps = { readOnly: true };

const useStyles = makeStyles((theme) => ({
	input: {
		width: '100%',
	},
}));
const DemographicsInfo = ({ isAdminPanel, userId, demographicsExists }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [demographics, setDemographics] = useState(null);
	const [userData, setUserData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		name_surname: '',
		email: '',
		birthDate: '',
		gender: '',
		country: '',
		profession: '',
		jobTitle: '',
		workStatus: '',
		martStatus: '',
		numOfChild: '',
		eduStatus: '',
		annualIncome: '',
		company: '',
	});
	const user = AuthenticationService.getLoggedInUser();

	// Define this function outside the useEffect
	const createFormData = (data, userData) => {
		let formData = {};

		formData.name_surname = `${userData.name} ${userData.surname}` || '';
		formData.email = userData.email || '';
		formData.birthDate = data.birthDate
			? moment(data.birthDate).format('YYYY')
			: '';
		formData.gender = data.gender?.name || '';
		formData.country = data.country?.name || '';
		formData.profession = data.occupation?.name || '';
		formData.jobTitle = data.jobTitle?.name || data.jobTitleOther || '';
		formData.martStatus =
			data.maritalStatus?.name || data.maritalStatusOther || '';
		formData.workStatus = data.workStatus?.name || data.workStatusOther || '';
		formData.numOfChild = data.childStatus?.name || '';
		formData.eduStatus = data.education?.name || '';
		formData.annualIncome = data.annualIncome?.name || '';
		formData.company = data.companyName || '';

		return formData;
	};

	// Then within your useEffect
	useEffect(() => {
		setLoading(true);

		if (!userId) {
			UserService.getUserById(user.userId)
				.then((res) => {
					setUserData(res.data);
					return res.data;
				})
				.then((response) => {
					DemographicsService.getDemographicsInfoByUserId(user.userId)
						.then((res) => {
							setDemographics(res.data);
							setFormData(createFormData(res.data, response));
							setLoading(false);
						})
						.catch((err) => setLoading(false));
				});
		} else {
			UserService.getUserById(userId)
				.then((res) => {
					setUserData(res.data);
					return res.data;
				})
				.then((response) => {
					DemographicsService.getDemographicsInfoByUserId(userId)
						.then((res) => {
							setDemographics(res.data);
							setFormData(createFormData(res.data, response));
							setLoading(false);
						})
						.catch((err) => setLoading(false));
				});
		}
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	return (
		<>
			<Spinner loading={loading} />
			{(isAdminPanel && userData && demographics) || demographicsExists ? (
				<ComponentWrapper subtitle={t('demographicsInfo')}>
					<Grid container spacing={2}>
						<Grid item={true} xs={12} md={6}>
							{AuthenticationService.isAdmin() && (
								<TextField
									label={t('name_surname')}
									size='small'
									name='name_surname'
									className={classes.input}
									value={formData.name_surname}
									InputProps={inputProps}
									onChange={handleInputChange}
								/>
							)}
						</Grid>
						<Grid item={true} xs={12} md={6}>
							{AuthenticationService.isAdmin() && (
								<TextField
									label={t('email')}
									name='email'
									size='small'
									className={classes.input}
									value={formData.email}
									InputProps={inputProps}
									onChange={handleInputChange}
								/>
							)}
						</Grid>
						<Grid item={true} xs={12} md={6}>
							<TextField
								label={t('year_of_birth')}
								size='small'
								name='birthDate'
								className={classes.input}
								InputProps={inputProps}
								// value={moment(demographics?.birthDate).format('YYYY')}
								value={formData.birthDate}
								onChange={handleInputChange}
							/>
						</Grid>
						<Grid item={true} xs={12} md={6}>
							<TextField
								label={t('genders')}
								size='small'
								className={classes.input}
								InputProps={inputProps}
								value={formData.gender}
								name='gender'
								onChange={handleInputChange}
							/>
						</Grid>
						<Grid item={true} xs={12} md={6}>
							<TextField
								label={t('country')}
								size='small'
								className={classes.input}
								value={formData.country}
								name='country'
								onChange={handleInputChange}
								InputProps={inputProps}
							/>
						</Grid>
						<Grid item={true} xs={12} md={6}>
							<TextField
								label={t('occupations')}
								size='small'
								value={formData.profession}
								name='profession'
								onChange={handleInputChange}
								InputProps={inputProps}
							/>
						</Grid>
						<Grid item={true} xs={12} md={6}>
							<TextField
								label={t('job_title')}
								size='small'
								className={classes.input}
								value={formData.jobTitle}
								name='jobTitle'
								onChange={handleInputChange}
								InputProps={inputProps}
							/>
						</Grid>
						<Grid item={true} xs={12} md={6}>
							<TextField
								label={t('work_statuses')}
								size='small'
								className={classes.input}
								value={formData.workStatus}
								name='workStatus'
								onChange={handleInputChange}
								InputProps={inputProps}
							/>
						</Grid>
						<Grid item={true} xs={12} md={6}>
							<TextField
								label={t('marital_status_short')}
								size='small'
								className={classes.input}
								value={formData.martStatus}
								name='martStatus'
								onChange={handleInputChange}
								InputProps={inputProps}
							/>
						</Grid>

						<Grid item={true} xs={12} md={6}>
							<TextField
								label={t('child_statuses')}
								size='small'
								className={classes.input}
								value={formData.numOfChild}
								name='numOfChild'
								onChange={handleInputChange}
								InputProps={inputProps}
							/>
						</Grid>
						<Grid item={true} xs={12} md={6}>
							<TextField
								label={t('educations')}
								size='small'
								className={classes.input}
								value={formData.eduStatus}
								name='eduStatus'
								onChange={handleInputChange}
								InputProps={inputProps}
							/>
						</Grid>
						<Grid item={true} xs={12} md={6}>
							<TextField
								label={t('annual_income')}
								size='small'
								className={classes.input}
								value={formData.annualIncome}
								name='annualIncome'
								onChange={handleInputChange}
								InputProps={inputProps}
							/>
						</Grid>
						<Grid item={true} xs={12} md={6}>
							<TextField
								label={t('company_worked')}
								size='small'
								className={classes.input}
								value={formData.company}
								name='company'
								onChange={handleInputChange}
								InputProps={inputProps}
							/>
						</Grid>
						{/* <Grid item={true} xs={12} md={6}>
              <TextField
                label="Grup İsmi"
                variant="outlined"
                size="small"
                className={classes.input}
                defaultValue={
                  demographics.groupName ? demographics.groupName : '-'
                }
                InputProps={inputProps}
              />
            </Grid> */}
					</Grid>
				</ComponentWrapper>
			) : (
				<Grid container justifyContent='center' alignItems='center'>
					{!loading && (
						<Box marginTop={15}>
							<Typography variant='h5'>
								{t('user_demographic_info_empty')}
							</Typography>
						</Box>
					)}
				</Grid>
			)}
			{!userId && !isAdminPanel && (
				<Container style={{ marginTop: 24 }}>
					<Grid container justifyContent='flex-end'>
						<MainButton
							onClick={() => (store.isEditMode = true)}
							style={{ marginRight: 10 }}
							color='secondary'
						>
							{t('infos_update')}
						</MainButton>
						<MainButton
							onClick={() => (store.steps.current = 1)}
							color='primary'
							type='submit'
							className='ml-2'
						>
							{t('NEXT')}
						</MainButton>
					</Grid>
				</Container>
			)}
			{/* {!loading && isAdminPanel && (
        <DialogComponent
          style={{ marginRight: 15 }}
          title={t('demographicsInfo')}
          buttonText={t('demographicsInfo')}
          buttonColor='secondary'
        >
          <DemographicsInfo userId={user.userId} />
        </DialogComponent>
      )} */}
		</>
	);
};
export default DemographicsInfo;
