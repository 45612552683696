import React, { useState, createContext } from 'react';

const DialogComponentContext = createContext();

export const DialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const value = {
    open,
    setOpen,
  };

  return (
    <DialogComponentContext.Provider value={value}>
      {children}
    </DialogComponentContext.Provider>
  );
};
export default DialogComponentContext;
