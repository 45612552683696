import axios from 'axios';
import AuthenticationService from './AuthenticationService';

class ExpertReviewAppointmentService {
  /**
   *
   * 20 (appointmentId)
   *
   * api/v1/lookup/getMetricModules --Sol kategorileri verr
   * api/v1/lookup/getMetricDetail/{id} --alt kategorileri getirir
   *
   * api/v1/lookup/saveExpertReview
   * api/v1/lookup/updateExpertReview
   * api/v1/lookup/deleteExpertReview
   */

  getDetail(id) {
    return axios.get(`/lookup/getExpertReviews/${id}`);
  }

  getMetricModules() {
    //--Sol kategorileri verir
    return axios.get('/lookup/getMetricModules');
  }
  getMetricModulesAndExpertReviewsByAppointmentId(appId) {
    //--Sol kategorileri verir
    if (AuthenticationService.isAdmin()) {
      return axios.get(
        '/lookup/getMetricModulesAndExpertReviewsByAppointmentId/' + appId
      );
    } else if (AuthenticationService.isExpert()) {
      return axios.get(
        '/lookup/getMetricModulesAndExpertReviewsByAppointmentId/' + appId
      );
    }
  }
  getMetricDetail(id) {
    // --alt kategorileri getirir
    return axios.get(`/lookup/getMetricDetail/${id}`);
  }

  save(row) {
    return axios.post('/lookup/saveExpertReview', row);
  }

  update(row) {
    return axios.post('/lookup/updateExpertReview', row);
  }

  delete(id) {
    return axios.delete(`/lookup/deleteExpertReview/${id}`);
  }
}

export default new ExpertReviewAppointmentService();
