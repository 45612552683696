import React, { useEffect, useState } from 'react';
import * as Survey from 'survey-react';
import 'survey-react/survey.css';
import './survey.css';
import surveyJson from './survey.json';
import surveyEn from './surveyEn.json';
import axios from 'axios';
import UpdateForm from './../components/UpdateForm';

import store from './../Store';
import { useSnackbar } from 'notistack';
import { useSnapshot } from 'valtio';
import { Container, Grid } from '@material-ui/core';
import { SecondaryButton } from './../components/common/SecondaryButton';
import AuthenticationService from '../service/AuthenticationService';
import { withTranslation } from 'react-i18next';

const replaceNoneString = (surveyData) => {
  if (surveyData.hhq1 && surveyData.hhq1.includes('none')) {
    surveyData.hhq1 = [];
  }
  if (surveyData.hhq2 && surveyData.hhq2.includes('none')) {
    surveyData.hhq2 = [];
  }
  if (surveyData.hhq3 && surveyData.hhq3.includes('none')) {
    surveyData.hhq3 = [];
  }
  if (surveyData.hhq4 && surveyData.hhq4.includes('none')) {
    surveyData.hhq4 = [];
  }
  return surveyData;
};

const PrescreeningFormSummary = ({ isAdminPanel, userId, t }) => {
  const lang = localStorage.getItem('i18nextLng');
  const { enqueueSnackbar } = useSnackbar();
  const snapshot = useSnapshot(store);

  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [survey, setSurvey] = useState(
    new Survey.Model(lang === 'tr' ? surveyJson : surveyEn)
  );
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  useEffect(() => {
    //const user = AuthenticationService.getLoggedInUser();
    axios
      .get('/users/prescreening', {
        params: {
          userId: userId,
        },
      })
      .then((res) => {
        survey.data = replaceEmptyArrayWithNone(JSON.parse(res.data.data));
        setIsFirstPage(survey.isFirstPage);
      });
  }, [survey, userId]);

  const replaceEmptyArrayWithNone = (surveyData) => {
    if (surveyData?.hhq1?.length === 0) {
      surveyData.hhq1 = ['none'];
    }
    if (surveyData?.hhq2?.length === 0) {
      surveyData.hhq2 = ['none'];
    }
    if (surveyData?.hhq3?.length === 0) {
      surveyData.hhq3 = ['none'];
    }
    if (surveyData?.hhq4?.length === 0) {
      surveyData.hhq4 = ['none'];
    }
    return surveyData;
  };

  const onDescriptionSave = ({ updateDescription }) => {
    let data = JSON.parse(JSON.stringify(snapshot.updatedSurveyData));
    const cleanData = replaceNoneString(data);
    cleanData['updateDescription'] = updateDescription;

    axios
      .post('/users/prescreening', cleanData)
      .then(() => {
        enqueueSnackbar(`${t('preview_update_message')}`, {
          variant: 'success',
          autoHideDuration: 1000,
        });
        setUpdateModalVisible(false);
        store.surveyReadOnlyModalVisible = false;
      })
      .catch(() => {});
  };

  const onUpdate = ({ data }) => {
    store.updatedSurveyData = data;
    setUpdateModalVisible(true);
    survey.clear();
  };

  return (
    <>
      <Survey.Survey model={survey} onComplete={onUpdate} />
      <Container style={{ marginTop: 5 }}>
        <Grid container justifyContent='flex-end' spacing={2}>
          {!isFirstPage && (
            <Grid item={true}>
              <SecondaryButton
                onClick={() => {
                  survey.prevPage();
                  setIsFirstPage(survey.isFirstPage);
                  setIsLastPage(survey.isLastPage);
                }}
              >
                {t('BEFORE')}
              </SecondaryButton>
            </Grid>
          )}
          {!isLastPage && (
            <Grid item={true}>
              <SecondaryButton
                color='primary'
                variant='contained'
                onClick={() => {
                  survey.nextPage();
                  setIsLastPage(survey.isLastPage);
                  setIsFirstPage(survey.isFirstPage);
                }}
              >
                {t('NEXT')}
              </SecondaryButton>
            </Grid>
          )}
        </Grid>
        <Grid container justifyContent='flex-end' style={{ marginTop: 20 }}>
          {!isAdminPanel && (
            <>
              <SecondaryButton
                color='secondary'
                variant='contained'
                onClick={() => survey.completeLastPage()}
              >
                {t('update')}
              </SecondaryButton>

              <UpdateForm
                visible={updateModalVisible}
                onCreate={onDescriptionSave}
                onCancel={() => setUpdateModalVisible(false)}
              />
            </>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default withTranslation()(PrescreeningFormSummary);
