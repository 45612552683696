import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CssBaseline, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CardLayout } from './../components/CardLayout';
import { MainButton } from '../components/common/MainButton';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import FormInput from '../components/common/FormInput';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 20,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  register: {
    textDecoration: 'none',
    textTransform: 'none',
  },
}));

const ChangePassword = ({ t }) => {
  const Schema = Yup.object().shape({
    password: Yup.string()
      .required(`${t('schema_shape.empty_password')}`)
      .min(8, `${t('schema_shape.min_password')}`),
    confirmPassword: Yup.string()
      .required(`${t('schema_shape.empty_password')}`)
      .oneOf(
        [Yup.ref('password'), null],
        `${t('schema_shape.match_password')}`
      ),
  });
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(Schema),
  });
  let history = useHistory();

  const onSubmit = (credentials) => {
    credentials.token = token;

    axios
      .post('/users/savePassword', credentials)
      .then(() => {
        enqueueSnackbar(`${t('enqueueSnackbar_message.change_password')}`, {
          variant: 'success',
        });
        history.push({
          pathname: '/giris',
        });
      })
      .catch((err) =>
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
        })
      );
  };

  return (
    <CardLayout title={t('refresh_password')}>
      <CssBaseline>
        <div className={classes.paper}>
          <Grid container item={true} xs={12} sm={10} md={8} lg={6}>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid>
                <Controller
                  name="password"
                  control={control}
                  render={(props) => (
                    <FormInput
                      {...props}
                      margin="dense"
                      type="password"
                      required
                      label={t('password')}
                    />
                  )}
                />
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={(props) => (
                    <FormInput
                      {...props}
                      type="password"
                      margin="dense"
                      required
                      label={t('repeat_password')}
                    />
                  )}
                />
              </Grid>

              <MainButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {t('change_password')}
              </MainButton>
            </form>
          </Grid>
        </div>
      </CssBaseline>
    </CardLayout>
  );
};

export default withTranslation()(ChangePassword);
