import axios from 'axios';

class LocationService {
  getLocationList(languageId) {
    return axios.get(`/lookup/locations/${languageId}`);
  }
  getLocationListByCountryIdAndCityId(countryId, cityId) {
    return axios.get(`/lookup/getLocation/${countryId}/${cityId}`);
  }

  getCountries() {
    return axios.get('/lookup/countries');
  }

  getCitiesByCountryId(id) {
    return axios.get('/lookup/cities/' + id);
  }

  getCountriesByLanguageId(languageId) {
    return axios.get('/lookup/allCountriesByLanguageId/' + languageId);
  }

  getById(id, languageId) {
    return axios.get(`/lookup/detailLocation/${id}/${languageId}`);
  }
  getOperatorByLocation(id) {
    return axios.get(`/dashboard/getOperatorByLocation/${id}`);
  }
  saveOperatorForLocation(body) {
    return axios.post(`/dashboard/saveOperatorForLocation`, body);
  }
  deleteOperatorForLocation(id) {
    return axios.delete(`/dashboard/deleteOperatorForLocation/${id}`);
  }
  searchOperatorByIncludingNameOrSurname(text) {
    return axios.get(
      `/dashboard/searchOperatorByIncludingNameOrSurname/${text}`
    );
  }
  getLocationAdminByLocation(id) {
    return axios.get(`/dashboard/getLocationAdminsByLocationId/${id}`);
  }
  saveLocationAdminForLocation(body) {
    return axios.post(`/dashboard/saveLocationAdminForLocation`, body);
  }
  deleteLocationAdminForLocation(id) {
    return axios.delete(`/dashboard/deleteLocationAdminForLocationById/${id}`);
  }
  searchLocationAdminByIncludingNameOrSurname(text) {
    return axios.get(
      `/dashboard/searchLocationAdminByIncludingNameOrSurname/${text}`
    );
  }
  getLocationsByLocationAdminNameOrSurname(text) {
    return axios.get(
      `/dashboard/getLocationsByLocationAdminNameOrSurname/${text}`
    );
  }
  getLocationsByLocationAdminId(id) {
    return axios.get(`/dashboard/getLocationsByLocationAdminId/${id}`);
  }
  deleteLocationAdminForLocationById(id) {
    return axios.delete(`/dashboard/deleteLocationAdminForLocationById/${id}`);
  }
  save(row) {
    return axios.post('/lookup/saveLocation', row);
  }

  update(row) {
    return axios.post('/lookup/updateLocation', row);
  }
  delete(id) {
    return axios.delete(`/lookup/deleteLocation/${id}`);
  }
}

export default new LocationService();
