import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './Reports.css';
import LayoutComponent from '../../components/LayoutComponent';
import { Card, Divider, Grid, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { Numbers } from '@mui/icons-material';
import ListItemText from '@material-ui/core/ListItemText';
import { LocationOn } from '@material-ui/icons';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import TreeView from '@material-ui/lab/TreeView';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import NotFoundImage from '../../assets/images/no-data-gorseli.png';

import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import ExpertReviewAppointmentService from '../../service/ExpertReviewAppointmentService';
import Spinner from '../../components/common/Spinner';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

const PhysioPhysical = ({ t }) => {
  const chart = useRef(null);
  const classes = useStyles();
  let history = useHistory();
  let { path, url } = useRouteMatch();

  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);

  const params = useParams();
  const appointmentId = parseInt(params.id);
  useLayoutEffect(() => {
    setLoading(true);
    ExpertReviewAppointmentService.getDetail(appointmentId).then((res) => {
      if (res != null && res?.data != null) {
        let item = res.data.filter((f) => f.metricModule?.id === 2)[0];
        if (item != null) {
          setDetail(item);
          setLoading(false);
        } else {
          setDisplay(true);
          setLoading(false);
        }
      }
    });
  }, []);

  const Image = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <img style={{ maxWidth: '600px' }} src={NotFoundImage} alt="" />
        <h1 style={{ fontWeight: 'bold' }}>{t('data_not_found')}</h1>
      </div>
    );
  };
  return (
    <div>
      <Spinner loading={loading} />
      <h3 style={{ fontSize: '20px', fontWeight: 'bold' }}>
        {t('physio_physical')}
      </h3>
      <div dangerouslySetInnerHTML={{ __html: detail.reviewText }} />
      {display ? <Image /> : null}
    </div>
  );
};

export default withTranslation()(PhysioPhysical);
