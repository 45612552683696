import axios from 'axios';

class CustomerSarfReportParametersTemplateService {
	getAllSarfTemplates() {
		return axios.get('/lookup/getAllSarfReportParametersTemplates');
	}

	getSarfTemplatesByCustomerId(customerId) {
		return axios.get(
			'/lookup/getSarfReportParametersTemplateByCustomerId/' + customerId
		);
	}

	getById(id) {
		return axios.get('/lookup/getSarfReportParametersTemplateById/' + id);
	}

	save(row) {
		return axios.post('/lookup/saveSarfReportParametersTemplate', row);
	}

	update(row) {
		return axios.post('/lookup/updateSarfReportParametersTemplate', row);
	}
	delete(id) {
		return axios.delete(`/lookup/deleteSarfReportParametersTemplateById/${id}`);
	}
}

export default new CustomerSarfReportParametersTemplateService();
