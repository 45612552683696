import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import ComponentWrapper from './ComponentWrapper';
import AuthenticationService from '../service/AuthenticationService';
import moment from 'moment';
import { MainButton } from './common/MainButton';
import store from '../Store';
import UserService from '../service/UserService';
import DoctorDemographicsService from '../service/DoctorDemographicsService';
import { useTranslation } from 'react-i18next';

const inputProps = { readOnly: true };

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
}));

export const DoctorDemographicsInfo = ({ userId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [doctorDemographics, setDoctorDemographics] = useState(null);
  const [userData, setUserData] = useState(
    AuthenticationService.getLoggedInUser()
  );

  useEffect(() => {
    if (userId) {
      DoctorDemographicsService.getDoctorDemographicsInfoByUserId(userId).then(
        (res) => setDoctorDemographics(res.data)
      );
      UserService.getUserById(userId).then(({ data }) => setUserData(data));
    } else {
      DoctorDemographicsService.getDoctorDemographicsInfoByUserId(
        userData.userId
      ).then((res) => setDoctorDemographics(res.data));
    }
  }, [userData.userId, userId]);

  return (
    <>
      {doctorDemographics ? (
        <ComponentWrapper subtitle={t('demographicsInfo')}>
          <Grid container spacing={2}>
            <Grid item={true} xs={12} md={6}>
              <TextField
                label={t('name_surname')}
                variant="outlined"
                size="small"
                className={classes.input}
                defaultValue={
                  userData && userData.name + ' ' + userData.surname
                }
                InputProps={inputProps}
              />
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <TextField
                label={t('email')}
                variant="outlined"
                size="small"
                className={classes.input}
                defaultValue={userData && userData.email}
                InputProps={inputProps}
              />
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <TextField
                label={t('birth_day')}
                variant="outlined"
                size="small"
                className={classes.input}
                defaultValue={moment(doctorDemographics.birthDate).format(
                  'YYYY'
                )}
                InputProps={inputProps}
              />
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <TextField
                label={t('genders')}
                variant="outlined"
                size="small"
                className={classes.input}
                defaultValue={
                  doctorDemographics.genderOther ||
                  doctorDemographics.gender.name
                }
                InputProps={inputProps}
              />
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <TextField
                label={t('country')}
                variant="outlined"
                size="small"
                className={classes.input}
                defaultValue={
                  doctorDemographics.countryResidenceOther ||
                  doctorDemographics.country.name
                }
                InputProps={inputProps}
              />
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <TextField
                label={t('occupations')}
                variant="outlined"
                size="small"
                className={classes.input}
                defaultValue={
                  doctorDemographics.occupationOther ||
                  doctorDemographics.occupation.name
                }
                InputProps={inputProps}
              />
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <TextField
                label={t('job_title')}
                variant="outlined"
                size="small"
                className={classes.input}
                defaultValue={
                  doctorDemographics.jobTitleOther ||
                  doctorDemographics.jobTitle.name
                }
                InputProps={inputProps}
              />
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <TextField
                label={t('company_worked')}
                variant="outlined"
                size="small"
                className={classes.input}
                defaultValue={
                  doctorDemographics.companyName
                    ? doctorDemographics.companyName
                    : '-'
                }
                InputProps={inputProps}
              />
            </Grid>
          </Grid>
        </ComponentWrapper>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <Box marginTop={15}>
            <Typography variant="h5">
              {t('user_demographic_info_empty')}
            </Typography>
          </Box>
        </Grid>
      )}
      {!userId && (
        <Container style={{ marginTop: 24 }}>
          <Grid container justifyContent="flex-end">
            <MainButton
              onClick={() => (store.isEditMode = true)}
              style={{ marginRight: 10 }}
              color="secondary"
            >
              {t('infos_update')}
            </MainButton>
            <MainButton
              color="primary"
              variant="contained"
              onClick={() => (store.steps.current = 1)}
            >
              {t('COMPLETE')}
            </MainButton>
          </Grid>
        </Container>
      )}
    </>
  );
};
