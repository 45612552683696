/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import StationService from '../service/StationService';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import LocationService from '../service/LocationService';
import moment from 'moment';
import './AppointmentList.css';
import axios from 'axios';
import Spinner from './common/Spinner';
import { withTranslation } from 'react-i18next';
const AppointmentList = ({ t }) => {
  const toast = useRef(null);
  const [station, setStation] = useState({
    id: '',
    countryId: null,
    cityId: null,
    locationId: null,
    location: {},
    name: '',
    productNumber: '',
  });
  const [dateState, setDateState] = useState(new Date());
  const [number, setNumber] = useState(3);
  const [start, setStart] = useState(0);
  const [appointmentList, setAppointmentList] = useState([]);
  const [loading, setLoading] = useState(false);
  // let {path} = useRouteMatch();
  const params = useParams();
  var stationId = params.id;
  useEffect(() => {
    get(stationId);
    const i1 = setInterval(() => setDateState(new Date()), 15000);
    const i2 = setInterval(() => getAppointmentByStationAndDate(), 60000);
    const i3 = setInterval(() => loopingData(), 15000);
    return () => clearInterval(i1, i2, i3);
  }, []);

  useEffect(() => {
    if (number > appointmentList.length + 2) {
      setNumber(3);
      setStart(0);
    }
  }, [number, appointmentList]);

  const loopingData = () => {
    setNumber((number) => number + 3);
    setStart((start) => start + 3);
  };

  const get = (stationId) => {
    if (stationId != null && stationId.length > 0) {
      StationService.getStationInfoById(stationId).then((res) => {
        setStation({
          ...res.data,
          countryId: res.data.location.country.id,
          cityId: res.data.location.city.id,
        });
      });
      getAppointmentByStationAndDate();
    }
  };

  const getAppointmentByStationAndDate = () => {
    setLoading(true);
    axios
      .post('/appointments/getAppointmentByStationAndDate', {
        startDate: moment(Date.now()).format('YYYY-MM-DD'),
        startTime: moment(Date.now()).format('HH:00'),
        stationId: stationId,
      })
      .then((res) => {
        const data = res.data;
        setAppointmentList(data);
        setLoading(false);
      });
  };

  const history = useHistory();
  const handleOnClick = useCallback(
    () => history.push('/panel/istasyonlar'),
    [history]
  );
  return (
    <>
      <Spinner loading={loading} />
      <div className="appointment-list">
        <Toast ref={toast} />
        <div className="title-items d-flex">
          <div className="page-title">
            <h1>{t('appointment_list')}</h1>
          </div>
          <div className="logo">
            <img
              id="logo"
              alt="NeoAuvra logo"
              src="/logo-black.png"
              style={{ width: '200px' }}
            />
          </div>
        </div>
        <div className="container">
          <div className="sideBar">
            <div className="sideBar-items">
              <div className="d-flex flex-column">
                <div className="date">
                  <span>
                    {' '}
                    {moment(Date.now())
                      .locale(`${t('tr')}`)
                      .format('Do MMMM YYYY')}
                  </span>
                </div>
                <div className="clock">
                  <span>
                    {dateState.toLocaleString('tr-TR', {
                      hour: 'numeric',
                      minute: 'numeric',
                      hour24: true,
                    })}
                  </span>
                </div>
                <div className="location">
                  <div className="d-flex">
                    <div className="image">
                      <img src="/pin.png" alt="" />
                    </div>
                    <div className="location-infos">
                      <span>{station?.location?.name}</span>
                      <small>
                        {station?.location?.city?.name},{' '}
                        {station?.location?.country?.name}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="appointment-items d-flex flex-column flex-1">
            {appointmentList.slice(start, number).map((item, index) => {
              return (
                <div key={index} className="appointment-item-card">
                  <div className="d-flex justify-content-between wrap">
                    <div className="patients-name">
                      <div className="d-flex flex-column">
                        <div className="title">{t('patients_name')}</div>
                        <div className="content">
                          <span>{item.name}</span>
                          <span>{item.surname}</span>
                        </div>
                      </div>
                    </div>
                    <div className="idNumber">
                      <div className="d-flex flex-column">
                        <div className="title">{t('id_number')}</div>
                        <div className="content">
                          <span>{item.appointmentId}</span>
                        </div>
                      </div>
                    </div>
                    <div className="appointment-time">
                      <div className="d-flex flex-column">
                        <div className="title">{t('appointment_time')}</div>
                        <div className="content">
                          <span>
                            {item.start}-{item.end}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="hubs">
                      <div className="d-flex flex-column">
                        <div className="title">{t('hubs')}</div>
                        <div className="content d-flex">
                          <div className="hubs-item">
                            <span>{t('cognitive')}</span>
                            <div className="" style={{ textIndent: '7px' }}>
                              <i className="pi pi-check-circle cognitive c-cog"></i>
                            </div>
                          </div>
                          <div className="hubs-item">
                            <span>{t('socio_emotional')}</span>
                            <div className="" style={{ textIndent: '7px' }}>
                              <i className="pi pi-check-circle emotional c-emo"></i>
                            </div>
                          </div>
                          <div className="hubs-item">
                            <span>{t('cognitive')}</span>
                            <div className="" style={{ textIndent: '7px' }}>
                              <i className="pi pi-check-circle physical c-phy"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(AppointmentList);
