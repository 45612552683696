import React, { useState, useEffect, useRef } from 'react';
import { Card, Divider, Grid } from '@material-ui/core';
import Box from '@mui/material/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import { Numbers } from '@mui/icons-material';
import { LocationOn } from '@material-ui/icons';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AssignmentIcon from '@mui/icons-material/Assignment';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import OverviewService from '../../service/OverviewService';
import IconButton from '@mui/material/IconButton';
import IosShareIcon from '@mui/icons-material/IosShare';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import AuthenticationService from '../../service/AuthenticationService';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { jsPDF } from 'jspdf';
import Spinner from '../common/Spinner';
import { MainButton } from '../common/MainButton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ButtonMaterial from '@mui/material/Button';
import { useSnackbar } from 'notistack';
const SelectAppointmentCard = ({
  t,
  appointment,
  getCalendar,
  selectedApppointment,
  isIk,
  setLoading,
  isAdmin,
  isUser,
  showSuccess,
  showWarning,
  showError,
  serverError,
  isDisplayDonutChart,
  sarfReportList,
}) => {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [sarfReportDialog, setSarfReportDialog] = useState(false);
  const [sarfReportDialogUser, setSarfReportDialogUser] = useState(false);
  const [pdfValue, setPdfValue] = useState('detail');
  const [date, setDate] = React.useState('');
  const user = AuthenticationService.getLoggedInUser();
  const [appointmentId, setAppointmentId] = useState(appointment);
  const toast = useRef(null);
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
  });
  const [expiryDate, setExpiryDate] = useState('');
  const [selectedSarfReportId, setSelectedSarfReportId] = useState(null);
  const lang = localStorage.getItem('i18nextLng');
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const value = event.target.value;
    setDate(value);
    switch (value) {
      case 'Indefinite':
        setExpiryDate(value);
        break;
      case '1 month':
        setExpiryDate(
          moment(new Date()).add(1, 'M').format('YYYY-MM-DD HH:mm:ss.SSS')
        );
        break;
      case '1 week':
        setExpiryDate(
          moment(new Date()).add(1, 'W').format('YYYY-MM-DD HH:mm:ss.SSS')
        );
        break;
      case '1 day':
        setExpiryDate(
          moment(new Date()).add(1, 'day').format('YYYY-MM-DD HH:mm:ss.SSS')
        );
        break;
      case '6 hours':
        setExpiryDate(
          moment(new Date()).add(6, 'hours').format('YYYY-MM-DD HH:mm:ss.SSS')
        );
        break;
      case '1 hour':
        setExpiryDate(
          moment(new Date()).add(1, 'hour').format('YYYY-MM-DD HH:mm:ss.SSS')
        );
        break;
      default:
        break;
    }
  };

  const handleChangePdfValue = (e) => {
    setPdfValue(e.target.value);
  };

  const shareReportFromWeb = async () => {
    const payload = {
      appointmentId: appointmentId,
      name: inputs.name,
      email: inputs.email,
      expiryDate: expiryDate,
      userId: user.userId,
    };
    try {
      const response = await axios.post('/users/shareReportFromWeb', payload);
      if (response.status === 200) {
        setOpen(false);
        toast.current.show({
          severity: 'success',
          summary: `${t('enqueueSnackbar_message.congratulations')}`,
          detail: `${t('enqueueSnackbar_message.report')} ${inputs.email} ${t(
            'enqueueSnackbar_message.report_continuation'
          )}`,
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: `${inputs.email} ${t(
          'enqueueSnackbar_message.user_not_found'
        )}`,
        life: 3000,
      });
    }
  };

  const downloadPdf = async () => {
    setLoading(true);
    try {
      if (pdfValue === 'detail') {
        const response = await axios.get('/reports/generate-report', {
          responseType: 'blob',
          params: {
            appointmentId: appointmentId,
            sarfReportId: selectedSarfReportId,
          },
          headers: {
            'Content-Type': 'application/pdf',
            'Accept-Language': lang,
            Accept: 'application/pdf',
          },
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', appointmentId + '.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoading(false);
      } else {
        const response = await axios.get('/reports/generate-summaryReport', {
          responseType: 'blob',
          params: {
            appointmentId: appointmentId,
            sarfReportId: selectedSarfReportId,
          },
          headers: {
            'Content-Type': 'application/pdf',
            'Accept-Language': lang,
            Accept: 'application/pdf',
          },
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', appointmentId + '.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      serverError();
    }
  };

  const passPdfDialog = () => {
    if (selectedSarfReportId) {
      setSarfReportDialog(false);
      setPdfOpen(true);
    } else {
      showWarning();
    }
  };

  const passPdfDialogUser = () => {
    setOpen2(false);
    if (selectedSarfReportId) {
      setSarfReportDialogUser(false);
      sendEmailByPdf(selectedSarfReportId);
    } else {
      showWarning();
    }
  };

  const isCheckUserSarfReportList = () => {
    if (sarfReportList.length > 0) {
      setSarfReportDialogUser(true);
    } else {
      sendEmailByPdf(selectedSarfReportId);
    }
  };

  const isCheckPdfDialog = () => {
    if (sarfReportList.length > 0) {
      setSarfReportDialog(true);
    } else {
      setPdfOpen(true);
    }
  };
  const directPassPdfDialog = () => {
    setOpen2(false);
    setSelectedSarfReportId(null);
    setSarfReportDialogUser(false);
    setPdfOpen(true);
  };

  const directPassPdfDialogUser = React.useCallback(
    (selectedSarfReportId) => {
      setSelectedSarfReportId(null);
      setSarfReportDialogUser(false);
      setOpen2(false);
      sendEmailByPdf(selectedSarfReportId);
    },
    [selectedSarfReportId]
  );

  const sendEmailByPdf = async (reportId) => {
    try {
      await axios.get(
        '/dashboard/createReportPdfWithPassword/' + appointmentId,
        {
          headers: {
            'Accept-Language': lang,
          },
          params: { sarfReportId: reportId },
        }
      );
      setOpen2(false);
      showSuccess();
    } catch (error) {
      setLoading(false);
      showError();
    }
  };

  const isCheckEmailDialog = () => {
    if (sarfReportList.length > 0) {
      setSarfReportDialog(true);
    } else {
      setOpen2(true);
    }
  };

  const radioGroupElement = ({ sarfReportId }) => {
    const handleChangeSarfReportId = (e) => {
      setSelectedSarfReportId(e.target.value);
    };
    return (
      <Radio
        checked={selectedSarfReportId === sarfReportId}
        onChange={handleChangeSarfReportId}
        value={sarfReportId}
        inputProps={{ 'aria-label': sarfReportId }}
      />
    );
  };

  const handleInputsChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setInputs({
      ...inputs,
      [e.target.name]: value,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClosePdfModal = () => {
    setPdfOpen(false);
  };

  const handleCloseSarfModal = () => {
    setSarfReportDialog(false);
    setSelectedSarfReportId(null);
  };

  const handleCloseSarfModalUser = () => {
    setSarfReportDialogUser(false);
    setSelectedSarfReportId(null);
  };

  return (
    <>
      <Toast
        ref={toast}
        baseZIndex={0}
      />
      <div className='sidebar-bottom'>
        <Grid>
          <Card
            style={{
              borderRadius: '20px',
            }}
          >
            {appointmentId ? (
              <>
                <Box
                  style={{ margin: '10px 0' }}
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{ px: 2, py: 0, bgcolor: 'background.default' }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Numbers />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('appointment_number')}
                      secondary={
                        <p style={{ paddingTop: '8px' }}>
                          {selectedApppointment.id}
                        </p>
                      }
                    />
                    {isUser === 'isUser' && (
                      <div className='appointmentIconAction'>
                        <Tooltip
                          onClick={() => setOpen(true)}
                          title={<>{t('share_report')}</>}
                          placement='right-start'
                        >
                          <Button>
                            <svg
                              fill='none'
                              height='34'
                              viewBox='0 0 20 20'
                              width='34'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M13.3298 12.8377L17.8269 8.41512L17.8841 8.34968C18.0558 8.12058 18.0367 7.78916 17.8268 7.58283L13.3297 3.16165L13.268 3.10864C12.9078 2.83964 12.3776 3.09805 12.3776 3.57782V5.70757L12.153 5.72268C8.59039 6.00547 6.50344 8.25989 6.00472 12.3501C5.94067 12.8754 6.54318 13.2042 6.93261 12.8564C8.36448 11.5779 9.84382 10.784 11.3776 10.4657C11.6236 10.4147 11.871 10.3759 12.1199 10.3493L12.3776 10.3261V12.4216L12.3829 12.5039C12.4429 12.9566 12.992 13.1699 13.3298 12.8377ZM12.2263 6.72L13.3776 6.64256V4.61104L16.8238 7.99905L13.3776 11.3882V9.23223L12.022 9.35403L12.0136 9.35493C10.3113 9.53692 8.70337 10.2189 7.18674 11.3555C7.48493 10.0174 7.99417 9.01008 8.6632 8.28852C9.49235 7.39426 10.6526 6.84605 12.2263 6.72Z'
                                fill='#2196F3'
                              />
                              <path
                                d='M5.5 4C4.11929 4 3 5.11929 3 6.5V14.5C3 15.8807 4.11929 17 5.5 17H13.5C14.8807 17 16 15.8807 16 14.5V13.5C16 13.2239 15.7761 13 15.5 13C15.2239 13 15 13.2239 15 13.5V14.5C15 15.3284 14.3284 16 13.5 16H5.5C4.67157 16 4 15.3284 4 14.5V6.5C4 5.67157 4.67157 5 5.5 5H8.5C8.77614 5 9 4.77614 9 4.5C9 4.22386 8.77614 4 8.5 4H5.5Z'
                                fill='#2196F3'
                              />
                            </svg>
                          </Button>
                        </Tooltip>
                        {isDisplayDonutChart && (
                          <Tooltip
                            title={<>{t('sendPdf')}</>}
                            placement='right-start'
                          >
                            <Button onClick={() => setOpen2(true)}>
                              <svg
                                fill='none'
                                height='34'
                                viewBox='0 0 20 20'
                                width='34'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M10.7661 10.4691L10 10.9199L3 6.80198V6.49998C3 5.67156 3.67157 4.99998 4.5 4.99998H11.8762L12.8745 3.99998H4.5C3.11929 3.99998 2 5.11927 2 6.49998V14.5C2 15.8807 3.11929 17 4.5 17H15.5C16.8807 17 18 15.8807 18 14.5V9.12273L17 10.1192V14.5C17 15.3284 16.3284 16 15.5 16H4.5C3.67157 16 3 15.3284 3 14.5V7.96298L9.74649 11.931L9.82751 11.9693C9.96661 12.0204 10.1231 12.0077 10.2535 11.931L11.0817 11.4436C10.9092 11.1393 10.804 10.8073 10.7661 10.4691ZM15.2261 4.47565C15.8607 3.84117 16.8895 3.84121 17.524 4.47577C18.1586 5.11037 18.1586 6.13926 17.524 6.77387L17.514 6.78415L13.6564 10.6281L13.6455 10.6387C13.4268 10.8574 13.0722 10.8574 12.8536 10.6387C12.6349 10.42 12.6349 10.0654 12.8536 9.8467C12.8579 9.84238 12.8621 9.838 12.8662 9.83357L16.3527 6.35391C16.5482 6.15884 16.5485 5.84225 16.3534 5.6468C16.1584 5.45135 15.8418 5.45103 15.6463 5.6461L12.1463 9.13923C12.1408 9.14476 12.1354 9.1504 12.1302 9.15613C11.5373 9.76656 11.5427 10.742 12.1464 11.3458C12.7517 11.951 13.7306 11.955 14.3407 11.3575L14.3524 11.3463L18.2309 7.48154C18.2352 7.4773 18.2393 7.473 18.2434 7.46865C19.2563 6.44257 19.2522 4.78969 18.2312 3.76866C17.206 2.74354 15.544 2.74354 14.5188 3.76866L10.9509 7.34201C10.7558 7.53742 10.756 7.854 10.9514 8.04912C11.1468 8.24423 11.4634 8.24399 11.6585 8.04858L15.2261 4.47565Z'
                                  fill='#2196F3'
                                />
                              </svg>
                            </Button>
                          </Tooltip>
                        )}
                        <Dialog
                          open={open2}
                          onClose={handleClose2}
                        >
                          <DialogTitle>{t('NeoAuvra Report')}</DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              {t(
                                'Your report will be sent as a pdf by email. Do you want to continue?'
                              )}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions
                            style={{ justifyContent: 'space-between' }}
                          >
                            <Button
                              className='ml-3'
                              onClick={() => isCheckUserSarfReportList()}
                            >
                              {t('yes')}
                            </Button>
                            <Button
                              className='mr-3'
                              onClick={handleClose2}
                            >
                              {t('no')}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    )}

                    {isAdmin === 'isAdmin' && (
                      <div className='appointmentIconAction'>
                        <Tooltip
                          title={<>{t('download_pdf')}</>}
                          placement='right-start'
                        >
                          <Button onClick={() => isCheckPdfDialog()}>
                            <svg
                              fill='none'
                              height='34'
                              viewBox='0 0 20 20'
                              width='34'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.5 16.9988C15.7761 16.9988 16 17.2226 16 17.4988C16 17.7442 15.8231 17.9484 15.5899 17.9907L15.5 17.9988H4.5C4.22386 17.9988 4 17.7749 4 17.4988C4 17.2533 4.17688 17.0492 4.41012 17.0068L4.5 16.9988H15.5ZM10.0001 2.00098C10.2456 2.00098 10.4497 2.17798 10.492 2.41124L10.5 2.50112L10.496 14.295L14.1414 10.6466C14.3148 10.4729 14.5842 10.4534 14.7792 10.5882L14.8485 10.646C15.0222 10.8194 15.0418 11.0888 14.907 11.2838L14.8492 11.3531L10.3574 15.8531C10.285 15.9257 10.1957 15.9714 10.1021 15.9901L9.99608 15.9999C9.83511 15.9999 9.69192 15.9237 9.60051 15.8056L5.14386 11.3537C4.94846 11.1586 4.94823 10.842 5.14336 10.6466C5.3168 10.4729 5.58621 10.4534 5.78117 10.5883L5.85046 10.6461L9.496 14.287L9.5 2.50083C9.50008 2.22469 9.724 2.00098 10.0001 2.00098Z'
                                fill='#2196F3'
                              />
                            </svg>
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                    {isIk && (
                      <div className='appointmentIconAction'>
                        {isDisplayDonutChart && (
                          <Tooltip
                            title={<>{t('download_pdf')}</>}
                            placement='right-start'
                          >
                            <Button onClick={() => isCheckPdfDialog()}>
                              <svg
                                fill='none'
                                height='34'
                                viewBox='0 0 20 20'
                                width='34'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5 16.9988C15.7761 16.9988 16 17.2226 16 17.4988C16 17.7442 15.8231 17.9484 15.5899 17.9907L15.5 17.9988H4.5C4.22386 17.9988 4 17.7749 4 17.4988C4 17.2533 4.17688 17.0492 4.41012 17.0068L4.5 16.9988H15.5ZM10.0001 2.00098C10.2456 2.00098 10.4497 2.17798 10.492 2.41124L10.5 2.50112L10.496 14.295L14.1414 10.6466C14.3148 10.4729 14.5842 10.4534 14.7792 10.5882L14.8485 10.646C15.0222 10.8194 15.0418 11.0888 14.907 11.2838L14.8492 11.3531L10.3574 15.8531C10.285 15.9257 10.1957 15.9714 10.1021 15.9901L9.99608 15.9999C9.83511 15.9999 9.69192 15.9237 9.60051 15.8056L5.14386 11.3537C4.94846 11.1586 4.94823 10.842 5.14336 10.6466C5.3168 10.4729 5.58621 10.4534 5.78117 10.5883L5.85046 10.6461L9.496 14.287L9.5 2.50083C9.50008 2.22469 9.724 2.00098 10.0001 2.00098Z'
                                  fill='#2196F3'
                                />
                              </svg>
                            </Button>
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </ListItem>
                </Box>
                <Divider />
                <Box
                  style={{ margin: '10px 0' }}
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{ px: 2, py: 0, bgcolor: 'background.default' }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <LocationOn />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${
                        selectedApppointment.station?.location?.city?.name ?? ''
                      },${
                        selectedApppointment.station?.location?.country?.name ??
                        ''
                      }, ${selectedApppointment.station?.location?.name ?? ''}`}
                      secondary={
                        <p style={{ paddingTop: '8px' }}>
                          {selectedApppointment.station?.name ?? ''}
                        </p>
                      }
                    />
                  </ListItem>
                </Box>
                <Divider />
                <Box
                  style={{ margin: '10px 0' }}
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{ px: 2, py: 0, bgcolor: 'background.default' }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <EventAvailableIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${
                        selectedApppointment.startTime === null
                          ? ' '
                          : selectedApppointment.startTime
                      } - ${
                        selectedApppointment.endTime === null
                          ? ' '
                          : selectedApppointment.endTime
                      } `}
                      secondary={
                        <p style={{ paddingTop: '8px' }}>
                          {selectedApppointment.date}
                        </p>
                      }
                    />
                  </ListItem>
                </Box>
                <Divider />
                <Box
                  style={{ margin: '10px 0' }}
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{ px: 0, py: 0, bgcolor: 'none' }}
                >
                  <ListItem>
                    <ListItemAvatar style={{ marginLeft: '20px' }}>
                      <Avatar>
                        <AssignmentIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      className='border_none'
                      primary={
                        isUser === 'isUser'
                          ? getCalendar
                          : selectedApppointment.fullApp
                      }
                    />
                  </ListItem>
                </Box>
              </>
            ) : (
              <h2
                style={{
                  color: '#c80136',
                  fontWeight: 600,
                  marginLeft: '70px',
                }}
              >
                {t('not_have_appointment')}
              </h2>
            )}
          </Card>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>{t('Share')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t(
                'Please specify the email address of the user you want to share your report with and for how long you want to share it and press the share button.'
              )}
            </DialogContentText>
            <TextField
              className='mb-2'
              autoFocus
              margin='dense'
              id='name'
              name='name'
              label={t('Enter a Description')}
              type='text'
              fullWidth
              variant='standard'
              onChange={handleInputsChange}
            />{' '}
            <TextField
              className='mb-2'
              margin='dense'
              id='name'
              name='email'
              label={t('Email to Share')}
              type='email'
              fullWidth
              variant='standard'
              onChange={handleInputsChange}
            />
            <FormControl
              fullWidth
              className='mt-5'
            >
              <InputLabel id='demo-simple-select-label'>
                {t('Duration')}
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={date}
                label='Date'
                onChange={handleChange}
              >
                <MenuItem value={'Indefinite'}>{t('Indefinite')}</MenuItem>
                <MenuItem value={'1 month'}>{t('1 month')}</MenuItem>
                <MenuItem value={'1 week'}>{t('1 week')}</MenuItem>
                <MenuItem value={'1 day'}>{t('1 day')}</MenuItem>
                <MenuItem value={'6 hours'}>{t('6 hours')}</MenuItem>
                <MenuItem value={'1 hour'}>{t('1 hour')}</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              className='ml-3'
              onClick={() => shareReportFromWeb()}
            >
              {t('Share')}
            </Button>
            <Button
              className='mr-3'
              onClick={handleClose}
            >
              {t('Cancel')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={pdfOpen}
          onClose={handleClosePdfModal}
        >
          <DialogTitle>Pdf {t('Download')}</DialogTitle>
          <DialogContent style={{ width: 400 }}>
            <DialogContentText>
              {t('appointment_alert_messages.choose_select_report_type')}
            </DialogContentText>
            <FormControl>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                name='radio-buttons-group'
                value={pdfValue}
                onChange={handleChangePdfValue}
              >
                <FormControlLabel
                  value='detail'
                  control={<Radio />}
                  label={t('detailed_report')}
                />
                <FormControlLabel
                  value='summary'
                  control={<Radio />}
                  label={t('summary_report')}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              className='ml-3'
              onClick={() => downloadPdf()}
            >
              {t('ok')}
            </Button>
            <Button
              className='mr-3'
              onClick={handleClosePdfModal}
            >
              {t('Cancel')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={sarfReportDialog}
          onClose={handleCloseSarfModal}
        >
          <DialogContent style={{ width: 600 }}>
            <DialogContentText style={{ color: '#000', marginBottom: 8 }}>
              {t('select_competency_report')}
            </DialogContentText>
            <DataTable
              value={sarfReportList}
              paginator
              rows={2}
              tableStyle={{ minWidth: '20rem', width: '100%' }}
              dataKey='sarfReportId'
            >
              <Column
                field='sarfReportId'
                header={t('choise')}
                style={{ width: '50%', textAlign: 'center' }}
                body={radioGroupElement}
              ></Column>
              <Column
                field='sarfReportName'
                header={t('sarf_report_name')}
                style={{ width: '50%' }}
              ></Column>
            </DataTable>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <ButtonMaterial
              variant='contained'
              onClick={() => passPdfDialog()}
            >
              {t('continue')}
            </ButtonMaterial>
            <ButtonMaterial onClick={() => directPassPdfDialog()}>
              {t('continue_without')}
            </ButtonMaterial>
            <ButtonMaterial onClick={() => handleCloseSarfModal()}>
              {t('Cancel')}
            </ButtonMaterial>
          </DialogActions>
        </Dialog>
        <Dialog
          open={sarfReportDialogUser}
          onClose={handleCloseSarfModalUser}
        >
          <DialogContent style={{ width: 600 }}>
            <DialogContentText style={{ color: '#000', marginBottom: 8 }}>
              {t('select_competency_report')}
            </DialogContentText>
            <DataTable
              value={sarfReportList}
              paginator
              rows={2}
              tableStyle={{ minWidth: '20rem', width: '100%' }}
              dataKey='sarfReportId'
            >
              <Column
                field='sarfReportId'
                header={t('choise')}
                style={{ width: '50%', textAlign: 'center' }}
                body={radioGroupElement}
              ></Column>
              <Column
                field='sarfReportName'
                header={t('sarf_report_name')}
                style={{ width: '50%' }}
              ></Column>
            </DataTable>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <ButtonMaterial
              variant='contained'
              onClick={() => passPdfDialogUser()}
            >
              {t('continue')}
            </ButtonMaterial>
            <ButtonMaterial onClick={() => directPassPdfDialogUser()}>
              {t('continue_without')}
            </ButtonMaterial>
            <ButtonMaterial onClick={() => handleCloseSarfModalUser()}>
              {t('Cancel')}
            </ButtonMaterial>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default withTranslation()(SelectAppointmentCard);
