import axios from 'axios';

class CompanyCenterLocationService {
	getLocations() {
		return axios.get('/lookup/getAllCustomerHeadQuarterLocations');
	}

	getById(id) {
		return axios.get('/lookup/getCustomerHeadQuarterLocationById/' + id);
	}

	save(row) {
		return axios.post('/lookup/saveCustomerHeadQuarterLocations', row);
	}

	update(row) {
		return axios.post('/lookup/updateCustomerHeadQuarterLocation', row);
	}
	delete(id) {
		return axios.delete(`/lookup/deleteCustomerHeadQuarterLocationById/${id}`);
	}
}

export default new CompanyCenterLocationService();
