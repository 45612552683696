import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { IconButton, InputAdornment, MenuItem } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { baseTheme } from './../../baseTheme';

const textFieldTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: '#0099F7',
    },
  },
});

const FormInput = (props) => {
  const {
    field,
    fieldState: { error },
    type,
    options,
    select,
    readOnly,
    multiline,
  } = props;
  const [showPassword, setShowPassword] = useState(true);
  const [inputType, setInputType] = useState(type);

  return (
    <ThemeProvider theme={textFieldTheme}>
      <TextField
        error={!!error}
        helperText={error?.message}
        size="small"
        variant="outlined"
        fullWidth
        select={select}
        minRows={5}
        default
        def={props.def}
        {...field}
        defaultValue={props.defaultValue}
        margin={props.margin}
        label={props.label}
        required={props.required}
        multiline={props.multiline}
        type={inputType}
        readOnly={props.readOnly}
        disabled={props.disabled}
        inputProps={{ readOnly: props.readOnly }}
        InputProps={
          type === 'password' && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowPassword(!showPassword);
                    setInputType(showPassword ? 'text' : 'password');
                  }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }
        }
      >
        {select &&
          options &&
          options.map((option) => (
            <MenuItem key={option.id} value={option}>
              {option.name}
            </MenuItem>
          ))}
      </TextField>
    </ThemeProvider>
  );
};

export default FormInput;
