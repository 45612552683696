import React, { useEffect, useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, CssBaseline, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CardLayout } from './../components/CardLayout';
import { MainButton } from '../components/common/MainButton';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Route, useHistory } from 'react-router-dom';
import AuthenticationService from '../service/AuthenticationService';
import store from './../Store';
import FormInput from '../components/common/FormInput';
import { useSnackbar } from 'notistack';
import Spinner from '../components/common/Spinner';
import { withTranslation, useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import i18next from 'i18next';
import { Dialog } from 'primereact/dialog';
import FormInputGrid from '../components/common/FormInputGrid';

const useStyles = makeStyles((theme) => ({
	card: {
		padding: 20,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	register: {
		'&:hover': {
			textDecoration: 'underline',
		},

		textTransform: 'none',
	},
	muiIcon: {
		padding: 2,
	},
}));
const user = localStorage.getItem('authenticatedUser');
const Login = ({ t }) => {
	const Schema = Yup.object().shape({
		email: Yup.string()
			.email(`${t('schema_shape.valid_email')}`)
			.required(`${t('schema_shape.not_empty')}`),
		password: Yup.string().required(`${t('schema_shape.empty_password')}`),
	});

	const _isMounted = useRef(true);
	const selected = localStorage.getItem('i18nextLng') || 'en';
	const [lang, setLang] = useState(selected);
	const [displayResponsive, setDisplayResponsive] = useState(false);
	const [dialogMessage, setDialogMessage] = useState('');
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const { handleSubmit, control, register } = useForm({
		resolver: yupResolver(Schema),
	});
	const [loading, setLoading] = useState(false);
	const { i18n } = useTranslation();
	const locale = i18n.language;
	// const languageItems = [
	//   { label: 'English', value: 'en', code: 'US' },
	//   { label: 'Türkçe', value: 'tr', code: 'TR' },
	// ];
	// const onLangChange = (e) => {
	//   setLang(e.value);
	//   i18next.changeLanguage(e.value);
	// };

	useEffect(() => {
		return () => {
			_isMounted.current = false;
		};
	}, []);

	let history = useHistory();

	const handleChangeEmail = (value) => {
		return value.toLowerCase();
	};

	const onSubmit = (credentials) => {
		setLoading(true);
		AuthenticationService.executeJwtAuthenticationService(credentials)
			.then((res) => {
				AuthenticationService.registerSuccessfulLoginForJwt(
					credentials['email'],
					res.data.token
				)
					.then((resp) => {
						const name = resp.data.name + ' ' + resp.data.surname;
						const stringToArray = name
							.split(' ')
							.map(
								(item) => item.substring(0, 1).toUpperCase() + item.substring(1)
							)
							.join('-');

						resp.data.fullName = stringToArray;
						store.activeTabIndex = 0;
						localStorage.setItem(
							'authenticatedUser',
							JSON.stringify(resp.data)
						);

						if (AuthenticationService.isUser()) {
							history.push('/');
						} else if (
							AuthenticationService.isAdmin() ||
							AuthenticationService.isLocationAdmin()
						) {
							history.push('/panel');
						} else if (AuthenticationService.isOperator()) {
							history.push('/panel/tüm-randevular');
						} else if (AuthenticationService.isExpert()) {
							history.push('/panel/tamamlanmis-randevular');
						}
						AuthenticationService.getProjectSettings();
						setLoading(false);
					})
					.catch((err) => {
						console.log(err);
					});
			})
			.catch((err) => {
				if (err?.response?.data?.reason === 'VERIFY_EMAIL') {
					enqueueSnackbar(`${t('enqueueSnackbar_message.verify_mail')}`, {
						variant: 'warning',
					});
				} else if (err?.response?.data?.reason === 'INVALID_CREDENTIALS') {
					enqueueSnackbar(`${t('enqueueSnackbar_message.login_failed')}`, {
						variant: 'error',
					});
				} else if (err?.response?.data?.WEB_UI_BLOCK) {
					setDialogMessage(
						t('web_ui_blocked_user', {
							customerName: `${err?.response?.data?.CUSTOMER_NAME?.toLocaleUpperCase(
								locale
							)}`,
						})
					);
					setDisplayResponsive(true);
				} else {
					enqueueSnackbar(
						`${t(
							'enqueueSnackbar_message.unable_to_connect_to_the_server_please_try_again_later'
						)}`,
						{
							variant: 'error',
						}
					);
				}

				setLoading(false);
			});
	};
	const ForgotDialog = () => {
		return (
			<Dialog
				header={t('information')}
				headerStyle={{ textAlign: 'center' }}
				headerClassName={styles.header}
				footer={renderFooter}
				visible={displayResponsive}
				breakpoints={{ '960px': '75vw', '640px': '100vw' }}
				style={{ width: '50vw' }}
				onHide={() => {
					setDisplayResponsive(false);
					setDialogMessage('');
				}}
			>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					{' '}
					<p style={{ textAlign: 'justify' }}>{dialogMessage}</p>
				</div>
			</Dialog>
		);
	};

	const renderFooter = () => {
		return (
			<div style={{ textAlign: 'center' }}>
				<MainButton
					type='submit'
					variant='contained'
					color='primary'
					className={classes.submit}
					onClick={() => {
						setDisplayResponsive(false);
						setDialogMessage('');
					}}
					autoFocus
				>
					{t('ok')}
				</MainButton>
			</div>
		);
	};
	const styles = {
		header: {
			textAlign: 'center',
		},
	};

	return (
		<>
			<Spinner loading={loading} />
			<CardLayout title={t('log_in')}>
				<ForgotDialog></ForgotDialog>
				<CssBaseline />
				<div className={classes.paper}>
					<Grid container item={true} xs={12} sm={10} md={8} lg={6}>
						<form
							className={classes.form}
							noValidate
							onSubmit={handleSubmit(onSubmit)}
						>
							<Grid>
								<Controller
									name='email'
									control={control}
									render={(props) => (
										<FormInputGrid
											{...props}
											margin='dense'
											required
											label={t('email')}
											name='Email'
											{...register('name')}
											handleChangeEmail={handleChangeEmail}
										/>
									)}
								/>
								<Controller
									name='password'
									control={control}
									render={(props) => (
										<FormInput
											{...props}
											margin='dense'
											type='password'
											required
											label={t('password')}
											name='password'
											{...register('password')}
										/>
									)}
								/>
							</Grid>

							<MainButton
								type='submit'
								fullWidth
								variant='contained'
								color='primary'
								className={classes.submit}
							>
								{t('login')}
							</MainButton>
							<Grid container justifyContent='space-between'>
								<Grid item={true} xs={6}>
									<Button
										type='button'
										component={Link}
										to='/parola-unuttum'
										color='secondary'
										className={classes.register}
									>
										{t('forgot_password')}
									</Button>
								</Grid>
								<Grid item={true} sm={6}>
									<Button
										component={Link}
										to='/kayit'
										color='secondary'
										className={classes.register}
										style={{ float: 'right' }}
									>
										{t('registerMessage')}
									</Button>
								</Grid>
							</Grid>
						</form>
					</Grid>
				</div>
			</CardLayout>
		</>
	);
};

export default withTranslation()(Login);
