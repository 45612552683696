import axios from 'axios';
// endpointler gelince güncellenecek
class PcListService {
	getComputers() {
		return axios.get('/dashboard/getComputers');
	}

	getById(id) {
		return axios.get('/dashboard/getComputerByPcFixedAssetNo/' + id);
	}

	save(row) {
		return axios.post('/dashboard/saveComputer', row);
	}

	update(row) {
		return axios.post('/dashboard/updateComputer', row);
	}
	delete(id) {
		return axios.delete(`/dashboard/deleteComputerById/${id}`);
	}
}

export default new PcListService();
