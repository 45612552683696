import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import PageTitle from './../components/PageTitle';
import moment from 'moment';
import styled from 'styled-components';
import 'moment/locale/tr';
import './appointment_datepicker_override.css';
// import Datatable from './../components/common/Datatable'
import { Button } from '@material-ui/core';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { withTranslation } from 'react-i18next';
import AlertDialog from '../components/DialogComp';
import AuthenticationService from '../service/AuthenticationService';
import DatePicker, { registerLocale } from 'react-datepicker';

const CustomDropdown = styled(Dropdown)`
	/* Target the dropdown input */
	.p-dropdown-label {
		padding: 0.36rem;
		/* Add more styles here */
	}
`;
const Appointments = ({ t }) => {
	const [appointments, setAppointments] = useState([]);
	const [dateChangeLang, setDateChangeLang] = useState('tr-TR');

	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		isApproved: { value: null, matchMode: FilterMatchMode.EQUALS },
		appointmentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		parentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		userId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
		userSurname: { value: null, matchMode: FilterMatchMode.CONTAINS },
		fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
		type: { value: null, matchMode: FilterMatchMode.CONTAINS },
		hub: { value: null, matchMode: FilterMatchMode.CONTAINS },
		isCompleted: { value: null, matchMode: FilterMatchMode.CONTAINS },
		startTime: { value: null, matchMode: FilterMatchMode.CONTAINS },
		endTime: { value: null, matchMode: FilterMatchMode.CONTAINS },
		date: { value: null, matchMode: FilterMatchMode.EQUALS },
		operatorId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		operatorFullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
		assessmentPackageName: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [loading, setLoading] = useState(true);
	const langValue = localStorage.getItem('i18nextLng') ?? 'tr';
	const [first, setFirst] = useState(0); // Add this line
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState(null);
	const [startDate, setStartDate] = useState('');
	const [endTime, setEndTime] = useState('');
	const [startTime, setStartTime] = useState('');
	const [rows, setRows] = useState(10);
	const completedOptions = [
		{ label: t('yes'), value: true },
		{ label: t('no'), value: false },
	];
	const appointmentTypes = [
		{
			type: 'A',
			label: 'A',
			description: `${t('create_appointment_types.desc_A')}`,
		},
		{
			type: 'B',
			label: 'B',
			description: `${t('create_appointment_types.desc_B')}`,
		},
		{
			type: 'C',
			label: 'C',
			description: `${t('create_appointment_types.desc_C')}`,
		},
		{
			type: 'X',
			label: 'GEN',
			description: `${t('create_appointment_types.desc_X')}`,
		},
		{
			type: 'Z',
			label: `${t('gen_managers')}`,
			description: `${t('create_appointment_types.desc_Z')}`,
		},
	];

	const approvedOptions = [
		{ label: t('approved'), value: true },
		{ label: t('pending_approve'), value: false },
	];

	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [assessmentPackages, setAssesmentPackages] = useState([]);
	const [checkIsAdminFetchUrl, setCheckIsAdminFetchUrl] = useState();
	const toast = useRef(null);
	const user = JSON.parse(localStorage.authenticatedUser);
	const datePicker = useRef(null);
	const onGlobalFilterChange = (e) => {
		const value = e.target.value.trim();
		let _filters = { ...filters };
		_filters['global'].value = value;

		if (value.length > 2 || value.length === 0) {
			setFilters(_filters);
		}

		setGlobalFilterValue(value);
	};
	const fetchAllAppointments = async (
		first,
		rows,
		sortField,
		sortOrder,
		filters
	) => {
		setLoading(true);
		let page = Math.floor(first / rows) ? Math.floor(first / rows) : 0;
		let formattedFilters = formatParams(filters);
		const payload = {
			page: page,
			size: rows,
			sortOrder: sortOrder === 1 ? 'asc' : 'desc',
			...formattedFilters,
			sortBy: sortField,
		};
		if (AuthenticationService.isAdmin()) {
			try {
				const { data } = await axios.get(
					'/appointments/getAppointmentsPagination',
					{ params: payload }
				);
				setFetchedData(data?.appointmentDTOList);
				setTotalRecords(data?.totalRecords);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		} else if (AuthenticationService.isOperator()) {
			try {
				const { data } = await axios.get(
					'/appointments/getAppointmentsPagination',
					{
						params: {
							...payload,
							operatorId: user.userId,
							isOperatorPanel: true,
						},
					}
				);
				setFetchedData(data?.appointmentDTOList);
				setTotalRecords(data?.totalRecords);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		} else {
			try {
				const { data } = await axios.get(
					'/appointments/getAppointmentsPagination',
					{
						params: {
							...payload,
							locationAdminId: user?.userId,
						},
					}
				);
				setFetchedData(data?.appointmentDTOList);
				setTotalRecords(data?.totalRecords);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		}
	};

	const getAssesmentPackages = async () => {
		try {
			const result = await axios.get('/users/getAssessmentPackages');

			setAssesmentPackages(result.data);
		} catch (err) {
			console.log(err);
		}
	};

	const setFetchedData = (response) => {
		for (let data of response) {
			const location = data.station?.location;
			data.hub =
				location?.country?.name +
				'/' +
				location?.city?.name +
				'/' +
				location?.name +
				'/' +
				data.station?.name;
			data.isCompleted = data.completed ? `${t('yes')}` : `${t('no')}`;
			data.isApproved = data.approved
				? `${t('approved')}`
				: `${t('pending_approve')}`;

			data.userName = data.name; // assign the value of data.name to data.userName
			data.userSurname = data.surname; // assign the value of data.surname to data.userSurname
			data.startTime = data.start;
			data.endTime = data.end;
			data.operatorFullName = data.operatorName;
			delete data.name; // remove the name property from data
			delete data.surname; // remove the surname property from data
			delete data.start; // remove the start property from data
			delete data.end; // remove the end property from data
			delete data.operatorName; // remove the operatorName property from data
			data.fullName = data?.userName + ' ' + data?.userSurname; // Use data.userName instead of data.name
		}
		setAppointments(response);
		setLoading(false);
	};

	useEffect(() => {
		if (langValue === 'en') {
			setDateChangeLang('en-US');
		} else {
			setDateChangeLang('tr-TR');
		}
	}, [langValue]);

	const renderHeader = () => {
		return (
			<div className='clearfix'>
				<div className='float-right p-2'>
					<span className='p-input-icon-left'>
						<i className='pi pi-search' />
						<InputText
							value={globalFilterValue}
							className='p-inputtext-sm'
							onKeyPress={(e) => {
								if (e.target.value.length === 0 && e.key === ' ') {
									e.preventDefault();
								}
							}}
							onChange={onGlobalFilterChange}
							placeholder={t('search...')}
						/>
					</span>
				</div>
			</div>
		);
	};
	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};
	const formatParams = (params) => {
		let formattedParams = {};

		if (params) {
			for (let field in params) {
				if (params[field].value !== undefined && params[field].value !== null) {
					formattedParams[field] = params[field].value;
				}
			}
		}

		return formattedParams;
	};
	const onSort = (event) => {
		setSortField(event.sortField);
		setSortOrder(event.sortOrder);
	};
	useEffect(() => {
		getAssesmentPackages();
		fetchAllAppointments(first, rows, sortField, sortOrder, filters);
	}, [langValue, first, rows, sortField, sortOrder, filters]);

	const actionOperatorEdit = (rowData) => {
		return (
			<>
				<AlertDialog
					operatorInfo={rowData.operatorId}
					selected={rowData.operatorName}
				></AlertDialog>
			</>
		);
	};
	const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
		<InputText
			style={{ padding: '.5rem', borderRadius: '4px', borderColor: '#ddd' }} // your custom styles
			type='text'
			className='form-control'
			onClick={onClick}
			value={value}
			ref={ref}
		/>
	));

	const numberofpages = Math.ceil(totalRecords / rows);

	return (
		<>
			<PageTitle>{t('all_appointments')}</PageTitle>
			<Toast ref={toast} />
			<div className='card card-custom gutter-b'>
				<div className='card-body'>
					<DataTable
						totalRecords={totalRecords}
						value={appointments}
						first={first}
						rows={rows}
						paginator
						onPage={onPageChange}
						showGridlines
						onSort={onSort}
						sortField={sortField}
						sortOrder={sortOrder}
						responsiveLayout='scroll'
						header={renderHeader}
						paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
						rowsPerPageOptions={[10, 25, 50]}
						dataKey='id'
						rowHover
						lazy='true'
						sortMode='single'
						filters={filters}
						filterDisplay='row'
						loading={loading}
						globalFilterFields={[
							'location.name',
							'name',
							'surname',
							'appointmentId',
							'name',
						]}
						emptyMessage={t('no_records_found')}
						currentPageReportTemplate={t('currentPageReportTemplate')}
						numberofpages={numberofpages}
					>
						{AuthenticationService.isLocationAdmin() && (
							<Column
								header={t('name_surname')}
								sortable={true}
								field='fullName'
								filter
								filterElement={
									<InputText
										onKeyPress={(e) => {
											if (e.target.value.length === 0 && e.key === ' ') {
												e.preventDefault();
											}
										}}
										onChange={(e) => {
											if (
												e.target.value.trim().length > 2 ||
												e.target.value.trim().length === 0
											) {
												setFilters({
													...filters,
													name: {
														value: e.target.value.trim(),
														matchMode: FilterMatchMode.CONTAINS,
													},
												});
											}
										}}
									/>
								}
								style={{ maxWidth: '100px' }}
							></Column>
						)}

						<Column
							field='isApproved'
							header={t('confirmation_status')}
							bodyClassName='text-center'
							style={{ minWidth: '100px' }}
							filter
							filterElement={
								<CustomDropdown
									options={approvedOptions?.map((option) => ({
										label: option?.label,
										value: option?.value,
									}))}
									showClear={true}
									value={filters?.isApproved?.value}
									onChange={(e) => {
										setFilters({
											...filters,
											isApproved: {
												value: e.value,
												matchMode: FilterMatchMode.CONTAINS,
											},
										});
									}}
								/>
							}
							sortable={true}
						/>

						<Column
							header={t('appointment_id')}
							sortable={true}
							field='appointmentId'
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (
											!/\d/.test(e.key) ||
											(e.target.value.length === 0 && e.key === ' ')
										) {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 0 ||
											e.target.value.trim().length === 0
										) {
											setFilters({
												...filters,
												appointmentId: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							style={{ maxWidth: '100px' }}
						></Column>
						{AuthenticationService.isAdmin() && (
							<Column
								header={t('parent_id')}
								sortable={true}
								field='parentId'
								filter
								style={{ maxWidth: '100px' }}
							></Column>
						)}
						<Column
							header={t('user_id')}
							sortable={true}
							field='userId'
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (
											!/\d/.test(e.key) ||
											(e.target.value.length === 0 && e.key === ' ')
										) {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 0 ||
											e.target.value.trim().length === 0
										) {
											setFilters({
												...filters,
												userId: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							style={{ maxWidth: '100px' }}
						></Column>
						{AuthenticationService.isAdmin() && (
							<Column
								header={t('name')}
								sortable={true}
								field='userName'
								filter
								filterElement={
									<InputText
										onKeyPress={(e) => {
											if (e.target.value.length === 0 && e.key === ' ') {
												e.preventDefault();
											}
										}}
										onChange={(e) => {
											if (
												e.target.value.trim().length > 2 ||
												e.target.value.trim().length === 0
											) {
												setFilters({
													...filters,
													userName: {
														value: e.target.value.trim(),
														matchMode: FilterMatchMode.CONTAINS,
													},
												});
											}
										}}
									/>
								}
								style={{ minWidth: '100px' }}
							></Column>
						)}
						{AuthenticationService.isAdmin() && (
							<Column
								header={t('surname')}
								sortable={true}
								field='userSurname'
								filter
								filterElement={
									<InputText
										onKeyPress={(e) => {
											if (e.target.value.length === 0 && e.key === ' ') {
												e.preventDefault();
											}
										}}
										onChange={(e) => {
											if (
												e.target.value.trim().length > 2 ||
												e.target.value.trim().length === 0
											) {
												setFilters({
													...filters,
													userSurname: {
														value: e.target.value.trim(),
														matchMode: FilterMatchMode.CONTAINS,
													},
												});
											}
										}}
									/>
								}
								style={{ minWidth: '100px' }}
							></Column>
						)}
						<Column
							header={t('assessment_package')}
							field='assessmentPackageName'
							sortable={true}
							filter
							filterElement={
								<CustomDropdown
									options={assessmentPackages?.map((option) => ({
										label: option?.packageName,
										value: option?.packageName,
									}))}
									showClear={true}
									value={filters?.assessmentPackageName?.value}
									onChange={(e) => {
										setFilters({
											...filters,
											assessmentPackageName: {
												value: e.value,
												matchMode: FilterMatchMode.CONTAINS,
											},
										});
									}}
								/>
							}
							style={{ maxWidth: '120px' }}
						></Column>
						<Column
							header={t('type')}
							field='type'
							sortable={true}
							filter
							filterElement={
								<CustomDropdown
									options={appointmentTypes?.map((option) => ({
										label: option?.type,
										value: option?.type,
									}))}
									showClear={true}
									value={filters?.type?.value}
									onChange={(e) => {
										setFilters({
											...filters,
											type: {
												value: e.value,
												matchMode: FilterMatchMode.CONTAINS,
											},
										});
									}}
								/>
							}
							style={{ maxWidth: '100px' }}
						></Column>

						<Column
							header={t('hub')}
							sortable={true}
							field='hub'
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (e.target.value.length === 0 && e.key === ' ') {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 2 ||
											e.target.value.trim().length === 0
										) {
											setFilters({
												...filters,
												hub: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('is_completed')}
							sortable={true}
							field='isCompleted'
							filter
							filterElement={
								<CustomDropdown
									options={completedOptions.map((option) => ({
										label: t(option.label),
										value: option.value,
									}))}
									showClear={true}
									value={filters.isCompleted?.value}
									onChange={(e) => {
										setFilters({
											...filters,
											isCompleted: {
												value: e.value,
												matchMode: FilterMatchMode.EQUALS,
											},
										});
									}}
								/>
							}
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('start_time')}
							sortable={true}
							field='startTime'
							filter
							filterElement={
								<div className='appointment-start-time'>
									<DatePicker
										selected={startTime}
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={1}
										key={localStorage.getItem('i18nextLng')}
										locale={
											localStorage.getItem('i18nextLng') === 'tr' ? 'tr' : 'en'
										}
										customInput={<CustomInput />}
										timeCaption={t('start_time')}
										isClearable={'true'}
										dateFormat='HH:mm'
										onKeyDown={(e) => e.preventDefault()}
										onChange={(date) => {
											const formattedDate = moment(date).format('HH:mm');

											if (date === 'Invalid date') {
												return;
											}
											setFirst(0);
											setFilters(
												date
													? {
															...filters,
															startTime: {
																value: formattedDate,
																matchMode: FilterMatchMode.EQUALS,
															},
													  }
													: {
															...filters,
															startTime: {
																value: null,
																matchMode: FilterMatchMode.EQUALS,
															},
													  }
											);
											setStartTime(date);
										}}
									/>
								</div>
							}
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('end_time')}
							sortable={true}
							field='endTime'
							filter
							filterElement={
								<div className='appointment-start-time'>
									{' '}
									<DatePicker
										selected={endTime}
										showTimeSelect
										showTimeSelectOnly
										key={localStorage.getItem('i18nextLng')}
										locale={
											localStorage.getItem('i18nextLng') === 'tr' ? 'tr' : 'en'
										}
										customInput={<CustomInput />}
										timeCaption={t('end_time')}
										isClearable={'true'}
										dateFormat='HH:mm'
										timeIntervals={1}
										onKeyDown={(e) => e.preventDefault()}
										onChange={(date) => {
											const formattedDate = moment(date).format('HH:mm');

											if (date === 'Invalid date') {
												return;
											}
											setFirst(0);
											setFilters(
												date
													? {
															...filters,
															endTime: {
																value: formattedDate,
																matchMode: FilterMatchMode.EQUALS,
															},
													  }
													: {
															...filters,
															endTime: {
																value: null,
																matchMode: FilterMatchMode.EQUALS,
															},
													  }
											);
											setEndTime(date);
										}}
									/>{' '}
								</div>
							}
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('date')}
							sortable={true}
							field='date'
							body={(rowData) => {
								let d = rowData.date;
								let date = new Date(d);
								let formattedDate = date.toLocaleDateString(dateChangeLang);
								return formattedDate;
							}}
							filter
							filterElement={
								<DatePicker
									dateFormat={langValue === 'tr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
									ref={datePicker}
									key={localStorage.getItem('i18nextLng')}
									locale={
										localStorage.getItem('i18nextLng') === 'tr' ? 'tr' : 'en'
									}
									selected={startDate}
									showMonthDropdown
									showYearDropdown
									yearDropdownItemNumber={478}
									scrollableYearDropdown
									scrollableMonthDropdown
									popperPlacement='bottom-start'
									dropdownMode='scroll'
									customInput={<CustomInput />}
									isClearable={true}
									onKeyDown={(e) => e.preventDefault()}
									onChange={(date) => {
										const formattedDate = moment(date).format('YYYY-MM-DD');

										if (date === 'Invalid date') {
											return;
										}
										setFirst(0);
										setFilters(
											date
												? {
														...filters,
														date: {
															value: formattedDate,
															matchMode: FilterMatchMode.EQUALS,
														},
												  }
												: {
														...filters,
														date: {
															value: null,
															matchMode: FilterMatchMode.EQUALS,
														},
												  }
										);
										setStartDate(date);
									}}
								/>
							}
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('operatorId')}
							sortable={true}
							field='operatorId'
							// body={actionOperatorEdit}
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (
											!/\d/.test(e.key) ||
											(e.target.value.length === 0 && e.key === ' ')
										) {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 0 ||
											e.target.value.trim().length === 0
										) {
											setFilters({
												...filters,
												operatorId: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							style={{
								maxWidth: '100px',
								cursor: 'pointer',
							}}
						></Column>
						<Column
							header={t('operatorName')}
							sortable={true}
							field='operatorFullName'
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (e.target.value.length === 0 && e.key === ' ') {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 2 ||
											e.target.value.trim().length === 0
										) {
											setFilters({
												...filters,
												operatorFullName: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							style={{ minWidth: '100px' }}
						></Column>
					</DataTable>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(Appointments);
