import React, { useState, useEffect, useRef } from 'react';
import LayoutComponent from '../components/LayoutComponent';
import { Container } from '@material-ui/core';
import { TabView, TabPanel } from 'primereact/tabview';
import styled from '@emotion/styled';
import axios from 'axios';
import PageTitle from './../components/PageTitle';
import moment from 'moment';
import 'moment/locale/tr';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { withTranslation } from 'react-i18next';
import { confirmPopup } from 'primereact/confirmpopup';
import AuthenticationService from '../service/AuthenticationService';
import { useHistory } from 'react-router-dom';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
const ReportShares = ({ t }) => {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    appointmentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    expiryDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    sharedDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    sharedWithUserEmail: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const toast = useRef(null);
  const [sharedList, setSharedList] = useState([]);
  const [yourSharedList, setYourSharedList] = useState([]);
  const [qrCode, setQrCode] = useState();
  const userInfo = AuthenticationService.getLoggedInUser();
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [appointment, setAppointment] = useState();
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
  });
  const history = useHistory();
  useEffect(() => {
    setLoading(true);
    const getQrCodeSharedWithMeList = async () => {
      try {
        const response = await axios.get(
          '/users/getQrCodeSharedWithMeList/' + userInfo.userId
        );
        for (let item of response.data) {
          item.fullName =
            item.sharedWithUserFirstname + ' ' + item.sharedWithUserLastname;
          item.expiryDate = moment(item.expiryDate).format(
            'DD-MM-YYYY HH:mm:ss'
          );
          item.sharedDate = moment(item.sharedDate).format(
            'DD-MM-YYYY HH:mm:ss'
          );
        }
        setSharedList(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getQrCodeSharingList();
    getQrCodeSharedWithMeList();
  }, []);

  const getQrCodeSharingList = async () => {
    try {
      const response = await axios.get(
        '/users/getQrCodeSharingListByUserId/' + userInfo.userId
      );
      for (let item of response.data) {
        item.fullName =
          item.sharedWithUserFirstname + ' ' + item.sharedWithUserLastname;
      }
      setYourSharedList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (event) => {
    const value = event.target.value;
    setDate(value);
    switch (value) {
      case '50 years':
        setExpiryDate(
          moment(new Date()).add(50, 'years').format('YYYY-MM-DD HH:mm:ss.SSS')
        );
        break;
      case '1 month':
        setExpiryDate(
          moment(new Date()).add(1, 'M').format('YYYY-MM-DD HH:mm:ss.SSS')
        );
        break;
      case '1 week':
        setExpiryDate(
          moment(new Date()).add(1, 'W').format('YYYY-MM-DD HH:mm:ss.SSS')
        );
        break;
      case '1 day':
        setExpiryDate(
          moment(new Date()).add(1, 'day').format('YYYY-MM-DD HH:mm:ss.SSS')
        );
        break;
      case '6 hours':
        setExpiryDate(
          moment(new Date()).add(6, 'hours').format('YYYY-MM-DD HH:mm:ss.SSS')
        );
        break;
      case '1 hour':
        setExpiryDate(
          moment(new Date()).add(1, 'hour').format('YYYY-MM-DD HH:mm:ss.SSS')
        );
        break;
      default:
        break;
    }
  };
  const shareReportFromWeb = async () => {
    const payload = {
      appointmentId: appointment,
      name: inputs.name,
      email: inputs.email,
      expiryDate: expiryDate,
      userId: userInfo.userId,
    };
    try {
      const response = await axios.post('/users/shareReportFromWeb', payload);
      if (response.status === 200) {
        setOpen(false);
        getQrCodeSharingList();
        toast.current.show({
          severity: 'success',
          summary: `${t('enqueueSnackbar_message.congratulations')}`,
          detail: `${t('enqueueSnackbar_message.report')} ${inputs.email} ${t(
            'enqueueSnackbar_message.report_continuation'
          )}`,
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: `${t('enqueueSnackbar_message.error')}`,
        detail: `${t('enqueueSnackbar_message.you_have_entered')} ${
          inputs.email
        } ${t('enqueueSnackbar_message.user_not_found')}`,
        life: 3000,
      });
    }
  };

  const stopSharingQrCode = async (data) => {
    try {
      const payload = {
        qrCode: data.qrCode,
        email: data.sharedWithUserEmail,
      };
      const response = await axios.post('/users/stopSharingQrCode', payload);
      if (response.status === 200) {
        getQrCodeSharingList();
        toast.current.show({
          severity: 'success',
          summary: `${t('enqueueSnackbar_message.congratulations')}`,
          detail: `${t('enqueueSnackbar_message.report_authorization')}`,
          life: 3000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const confirm = (data) => {
    confirmDialog({
      message: `${t('deauthorization')}`,
      header: `${t('appointment_alert_messages.confirmation')}`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: `${t('appointment_alert_messages.yes')}`,
      rejectLabel: `${t('appointment_alert_messages.no')}`,
      accept: () => stopSharingQrCode(data),
      reject: () => reject(),
    });
  };
  const reject = () => {
    toast.current.show({
      severity: 'warn',
      summary: `${t('appointment_alert_messages.denied')}`,
      detail: `${t('appointment_alert_messages.action_declined')}`,
      life: 3000,
    });
  };

  const handleInputsChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setInputs({
      ...inputs,
      [e.target.name]: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAppointmentId = async () => {
    const response = await axios.get('/appointments/userAppointments', {
      params: { userId: userInfo.userId },
    });
    if (response.data) {
      const approvedAppointments = response.data.filter(
        (appointment) => appointment.approved
      );
      if (approvedAppointments != null && approvedAppointments.length > 0) {
        setAppointment(approvedAppointments[0].appointmentId);
      }
    }
  };

  useEffect(() => {
    getAppointmentId();
  }, []);
  const renderHeader = () => {
    return (
      <div className='clearfix'>
        <div className='float-right p-2'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              value={globalFilterValue}
              className='p-inputtext-sm'
              onChange={onGlobalFilterChange}
              placeholder={t('search...')}
            />
          </span>
        </div>
      </div>
    );
  };
  const renderHeader1 = () => {
    return (
      <div className='d-flex justify-content-between'>
        <Button
          type='button'
          icon='pi pi-plus'
          label={t('share_report')}
          onClick={() => setOpen(true)}
          className='p-button-raised p-button-rounded p-button-outlined mr-3'
        />
        <div className='float-right p-2'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              value={globalFilterValue}
              className='p-inputtext-sm'
              onChange={onGlobalFilterChange}
              placeholder={t('search...')}
            />
          </span>
        </div>
      </div>
    );
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleHistory = (id) => {
    const url = `/ViewSharedReport/${id}?guid`;
    window.open(url);
  };

  const shareReportIcon = (
    <svg
      fill='none'
      height='32'
      viewBox='0 0 20 20'
      width='32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.25909 11.6021C3.94254 8.32689 6.79437 6 10 6C13.2057 6 16.0574 8.32688 16.7409 11.6021C16.7974 11.8725 17.0622 12.0459 17.3325 11.9895C17.6029 11.933 17.7763 11.6682 17.7199 11.3979C16.9425 7.67312 13.6934 5 10 5C6.3066 5 3.05742 7.67311 2.28017 11.3979C2.22377 11.6682 2.39718 11.933 2.6675 11.9895C2.93782 12.0459 3.20268 11.8725 3.25909 11.6021Z'
        fill='#0099F7'
      />
      <path
        d='M10 8C8.067 8 6.5 9.567 6.5 11.5C6.5 13.433 8.067 15 10 15C11.933 15 13.5 13.433 13.5 11.5C13.5 9.567 11.933 8 10 8ZM7.5 11.5C7.5 10.1193 8.61929 9 10 9C11.3807 9 12.5 10.1193 12.5 11.5C12.5 12.8807 11.3807 14 10 14C8.61929 14 7.5 12.8807 7.5 11.5Z'
        fill='#0099F7'
      />
    </svg>
  );

  const deleteSvg = (
    <svg
      fill='#EC0044'
      height='32'
      viewBox='0 0 20 20'
      width='32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.5 4C11.5 3.17157 10.8284 2.5 10 2.5C9.17157 2.5 8.5 3.17157 8.5 4H7.5C7.5 2.61929 8.61929 1.5 10 1.5C11.3807 1.5 12.5 2.61929 12.5 4H17C17.2761 4 17.5 4.22386 17.5 4.5C17.5 4.77614 17.2761 5 17 5H16.446L15.1499 16.2292C15.0335 17.2384 14.179 18 13.1631 18H6.83688C5.821 18 4.9665 17.2384 4.85006 16.2292L3.553 5H3C2.75454 5 2.55039 4.82312 2.50806 4.58988L2.5 4.5C2.5 4.22386 2.72386 4 3 4H11.5ZM15.438 5H4.561L5.84347 16.1146C5.90169 16.6192 6.32894 17 6.83688 17H13.1631C13.6711 17 14.0983 16.6192 14.1565 16.1146L15.438 5ZM8.5 7.5C8.74546 7.5 8.94961 7.65477 8.99194 7.85886L9 7.9375V14.0625C9 14.3041 8.77614 14.5 8.5 14.5C8.25454 14.5 8.05039 14.3452 8.00806 14.1411L8 14.0625V7.9375C8 7.69588 8.22386 7.5 8.5 7.5ZM11.5 7.5C11.7455 7.5 11.9496 7.65477 11.9919 7.85886L12 7.9375V14.0625C12 14.3041 11.7761 14.5 11.5 14.5C11.2545 14.5 11.0504 14.3452 11.0081 14.1411L11 14.0625V7.9375C11 7.69588 11.2239 7.5 11.5 7.5Z'
        fill='#EC0044'
      />
    </svg>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          className='p-button-text'
          style={{ color: '#000' }}
          type='button'
          onClick={() => handleHistory(rowData.appointmentId)}
          tooltip={t('browse_report')}
        >
          {shareReportIcon}
        </Button>
      </div>
    );
  };
  const actionBodyTemplate1 = (rowData) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          className='p-button-text'
          style={{ color: '#000' }}
          type='button'
          onClick={() => confirm(rowData)}
          tooltip={t('deauthorize')}
        >
          {/* <RemoveCircleOutlineOutlinedIcon
            sx={{
              opacity: 1,
              marginLeft: 4,
              float: 'right',
              color: '#1199f7',
              cursor: 'pointer',
            }}
          /> */}
          {deleteSvg}
        </Button>
      </div>
    );
  };
  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} />
      <LayoutComponent>
        <Container>
          <TabView
            panelContainerClassName='justify-content-center'
            style={{ justifyContent: 'center' }}
          >
            <TabPanel header={t('Reports Shared With You')}>
              <DataTable
                value={sharedList}
                paginator
                showGridlines
                responsiveLayout='scroll'
                header={renderHeader}
                rows={10}
                paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                rowsPerPageOptions={[10, 25, 50]}
                dataKey='id'
                rowHover
                filters={filters}
                filterDisplay='row'
                loading={loading}
                globalFilterFields={['fullName', 'sharedDate', 'expiryDate']}
                emptyMessage={t('no_records_found')}
                currentPageReportTemplate={t('currentPageReportTemplate')}
              >
                <Column
                  header={t('Reports Owner Name Surname')}
                  sortable={true}
                  field='fullName'
                  filter
                ></Column>
                <Column
                  header={t('Authorization Date')}
                  sortable={true}
                  field='sharedDate'
                  filter
                ></Column>
                <Column
                  header={t('Valid Until')}
                  sortable={true}
                  field='expiryDate'
                  filter
                ></Column>
                <Column
                  headerStyle={{ textAlign: 'center' }}
                  header={t('action')}
                  body={actionBodyTemplate}
                  className='actionBody'
                  bodyStyle={{ textAlign: 'center' }}
                />
              </DataTable>
            </TabPanel>
            <TabPanel header={t('Your Shared Reports')}>
              <DataTable
                value={yourSharedList}
                paginator
                showGridlines
                responsiveLayout='scroll'
                header={renderHeader1}
                rows={10}
                paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                rowsPerPageOptions={[10, 25, 50]}
                dataKey='id'
                rowHover
                filters={filters}
                filterDisplay='row'
                loading={loading}
                globalFilterFields={[
                  'name',
                  'appointmentId',
                  'fullName',
                  'sharedWithUserEmail',
                  'expiryDate',
                ]}
                emptyMessage={t('no_records_found')}
                currentPageReportTemplate={t('currentPageReportTemplate')}
              >
                <Column
                  header={t('Description')}
                  sortable={true}
                  field='name'
                  filter
                ></Column>
                <Column
                  header={t('appointment_id')}
                  sortable={true}
                  field='appointmentId'
                  filter
                ></Column>
                {/* <Column
                  header={t('Reports Owner Name Surname')}
                  sortable={true}
                  field="fullName"
                  filter
                ></Column> */}
                <Column
                  header='Email'
                  sortable={true}
                  field='sharedWithUserEmail'
                  filter
                ></Column>
                <Column
                  header={t('Valid Until')}
                  sortable={true}
                  field='expiryDate'
                  filter
                ></Column>
                <Column
                  headerStyle={{ textAlign: 'center' }}
                  header={t('action')}
                  body={actionBodyTemplate1}
                  className='actionBody'
                  bodyStyle={{ textAlign: 'center' }}
                />
              </DataTable>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t('Share')}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t(
                      'Please specify the email address of the user you want to share your report with and for how long you want to share it and press the share button.'
                    )}
                  </DialogContentText>
                  <TextField
                    className='mb-2'
                    autoFocus
                    margin='dense'
                    id='name'
                    name='name'
                    label={t('Enter a Description')}
                    type='text'
                    fullWidth
                    variant='standard'
                    onChange={handleInputsChange}
                  />{' '}
                  <TextField
                    className='mb-2'
                    margin='dense'
                    id='name'
                    name='email'
                    label={t('Email to Share')}
                    type='email'
                    fullWidth
                    variant='standard'
                    onChange={handleInputsChange}
                  />
                  <FormControl fullWidth className='mt-5'>
                    <InputLabel id='demo-simple-select-label'>
                      {t('Duration')}
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={date}
                      label='Date'
                      onChange={handleChange}
                    >
                      <MenuItem value={'50 years'}>{t('Indefinite')}</MenuItem>
                      <MenuItem value={'1 month'}>{t('1 month')}</MenuItem>
                      <MenuItem value={'1 week'}>{t('1 week')}</MenuItem>
                      <MenuItem value={'1 day'}>{t('1 day')}</MenuItem>
                      <MenuItem value={'6 hours'}>{t('6 hours')}</MenuItem>
                      <MenuItem value={'1 hour'}>{t('1 hour')}</MenuItem>
                    </Select>
                  </FormControl>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                  <Button className='ml-3' onClick={() => shareReportFromWeb()}>
                    {t('Share')}
                  </Button>
                  <Button className='mr-3' onClick={handleClose}>
                    {t('Cancel')}
                  </Button>
                </DialogActions>
              </Dialog>
            </TabPanel>
          </TabView>
        </Container>
      </LayoutComponent>
    </>
  );
};

export default withTranslation()(ReportShares);
