import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import ButtonMaterial from '@mui/material/Button';
import SarfCompetencyDomainService from '../../../service/SarfCompetencyDomainService';
import CustomerSarfCompetencyDomainParametersService from '../../../service/CustomerSarfCompetencyDomainParametersService';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const UpdateParameterDomainModal = ({
	selectedSarfTemplateCompetency,
	setLoading,
	getCompetenciesBySarfReportParametersTemplateId,
	handleCloseUpdateDomainModal,
	selectedTemplateDomain,
	updateParameterDomainModalOpen,
	getAllDomainsByCompetencyId,
	customerId,
	getDomainsBySarfReportParametersTemplateId,
	parameterTemplateId,
	t,
}) => {
	const [domains, setDomains] = useState([]);
	const toast = useRef(null);
	const toastInside = useRef(null);

	const schema = yup.object().shape({
		domain: yup.object().nullable().required(t('enter_sarf_domain')),
		weight: yup
			.string()
			.required(t('enter_sarf_competency_weight'))
			.nullable(true)
			.matches(/^[0-9]+$/, 'Weight must be a number')
			.test('is-between-range', 'Weight must be between 0 and 100', (value) => {
				if (!value) return true;
				const weightValue = parseInt(value, 10);
				return weightValue >= 0 && weightValue <= 100;
			}),
	});
	const {
		handleSubmit,
		control,
		setValue,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		let unmounted = false;

		if (updateParameterDomainModalOpen) {
			getAllDomains();
			getDomainById(selectedTemplateDomain?.id);
		} else {
			return;
		}
		return () => {
			unmounted = true;
		};
	}, [updateParameterDomainModalOpen]);

	const getAllDomains = async () => {
		try {
			const res = await SarfCompetencyDomainService.getList();

			res.data.sort((item1, item2) => item1.name.localeCompare(item2.name));
			setDomains(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const getDomainById = async (id) => {
		try {
			const res = await CustomerSarfCompetencyDomainParametersService.getById(
				id
			);
			setValue('domain', res?.data?.sarfCompetencyDomains);
			setValue('weight', res?.data?.weight);
		} catch (err) {
			console.error(err);
		}
	};

	const updateSarfTemplateDomain = async (data) => {
		const dataSent = {
			id: selectedTemplateDomain?.id,
			sarfCompetencyDomainsId: data.domain?.id,
			sarfReportCompetencyParameterId: selectedSarfTemplateCompetency?.id,
			weight: data.weight,
		};

		setLoading(true);

		try {
			const res = await CustomerSarfCompetencyDomainParametersService.update(
				dataSent
			);
			setTimeout(() => {
				handleCloseUpdateDomainModal();
				toast.current.show({
					severity: 'success',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: `${t('appointment_alert_messages.transaction_successful')}`,
					life: 3000,
				});
				setLoading(false);
				reset();
			}, 2000);

			if (
				selectedSarfTemplateCompetency != null ||
				selectedSarfTemplateCompetency != undefined
			) {
				getAllDomainsByCompetencyId(selectedSarfTemplateCompetency?.id);
				getDomainsBySarfReportParametersTemplateId(parameterTemplateId);
			}
		} catch (error) {
			setLoading(false);
			const errorMessage =
				typeof error.response.data === 'object'
					? JSON.stringify(error.response.data)
					: error.response.data;

			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: errorMessage,
			});
		}
	};

	const handleClose = () => {
		handleCloseUpdateDomainModal();
		reset();
	};

	return (
		<>
			<Toast ref={toast} />

			<Dialog
				open={updateParameterDomainModalOpen}
				fullWidth={true}
				maxWidth={'sm'}
				onClose={handleClose}
			>
				<Toast ref={toastInside} />

				<DialogContent
					style={{
						overflowY: 'visible',
						display: 'flex',
						justifyContent: 'start',
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<DialogContentText
						style={{
							marginBottom: '30px',
							textAlign: 'center',
							fontWeight: 'bold',
						}}
					>
						{selectedSarfTemplateCompetency?.sarfReportModuleParameters
							?.sarfModules?.name +
							' - ' +
							selectedSarfTemplateCompetency?.sarfCompetencies?.name +
							' - ' +
							t('create_sarf_template_domain')}
					</DialogContentText>

					<FormControl>
						<form onSubmit={handleSubmit(updateSarfTemplateDomain)}>
							<div style={{ width: '100%' }}>
								<label htmlFor=''>{t('sarf_domain')}</label>

								<Controller
									name='domain'
									control={control}
									defaultValue={null}
									render={({ field }) => (
										<Dropdown
											value={field.value}
											scrollHeight={'150px'}
											style={{
												width: '100%',
												marginTop: '10px',
												marginBottom: '10px',
											}}
											filter={true}
											appendTo='self'
											className='p-inputtext-sm'
											onChange={(e) => {
												field.onChange(e.value);
											}}
											options={domains}
											optionLabel='name'
										/>
									)}
								/>
								{errors.domain && (
									<span className='p-error'>{errors.domain.message}</span>
								)}
							</div>

							<div>
								<label htmlFor='inputtext'>
									{t('contribution_weight') + ' (%) '}
								</label>
								<div style={{ width: '15%' }}>
									<Controller
										name='weight'
										control={control}
										defaultValue={null}
										render={({ field }) => (
											<InputNumber
												id='inputtext'
												style={{
													width: '100%',
													marginTop: '10px',
													marginBottom: '10px',
												}}
												min={0}
												maxFractionDigits={0}
												value={field.value}
												onValueChange={(e) => field.onChange(e.value)}
											/>
										)}
									/>
								</div>

								{errors.weight && (
									<span className='p-error'>{errors.weight.message}</span>
								)}
							</div>
							<DialogActions style={{ paddingRight: '0' }}>
								<ButtonMaterial onClick={handleClose}>
									{t('Cancel')}
								</ButtonMaterial>
								<ButtonMaterial variant='contained' type='submit'>
									{t('update')}
								</ButtonMaterial>
							</DialogActions>
						</form>
					</FormControl>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default withTranslation()(UpdateParameterDomainModal);
