import { createTheme } from '@material-ui/core';
import { trTR } from '@material-ui/core/locale';

export const baseTheme = createTheme(
  {
    palette: {
      primary: {
        main: '#EC0044',
        dark: '#EC0044C7',
      },
      secondary: {
        main: '#0099F7',
      },
      default: {
        main: '#929ea8',
      },
      nested_primary: {
        main: '#0099F7',
      },
      contrastThreshold: 2.8,
      tonalOffset: 0,
    },
    shape: {
      borderRadius: 2,
    },
    typography: {
      fontFamily: ['PT Sans Pro', 'sans-serif'].join(','),
    },
  },

  trTR
);
