import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { baseTheme } from './../../baseTheme';
import { useTranslation } from 'react-i18next';

const textFieldTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: '#0099F7',
    },
  },
});

const SelectInput = ({
  options,
  dontIncludeOther,
  onHandleCountryEvent,
  label,
  ...props
}) => {
  const { field, fieldState } = props;
  const { t } = useTranslation();

  const isCheckOnChangeCountryEvent = (data) => {
    if (onHandleCountryEvent) {
      onHandleCountryEvent(data);
    }
  };
  return (
    <ThemeProvider theme={textFieldTheme}>
      <Autocomplete
        {...props}
        autoComplete='off'
        options={
          dontIncludeOther
            ? options
            : [{ id: 0, name: `${t('other')}` }, ...options]
        }
        noOptionsText={`${t('no_options')}`}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.name, inputValue);
          const parts = parse(option.name, matches);

          return (
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              required={props.required}
              {...params}
              autoComplete='off'
              size='small'
              margin='dense'
              variant='outlined'
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label={label}
            />
          );
        }}
        onChange={(_, data) => {
          field.onChange(data);
          isCheckOnChangeCountryEvent(data);
        }}
      />
    </ThemeProvider>
  );
};

export default SelectInput;
