import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Paper } from '@material-ui/core';
import { Button as PrimeButton } from 'primereact/button';
import { makeStyles } from '@material-ui/core/styles';
import {
  Calendar,
  momentLocalizer,
  Views,
  dateFnsLocalizer,
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import AuthenticationService from '../service/AuthenticationService';
import { Link } from 'react-router-dom';
import Spinner from '../components/common/Spinner';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(3),
    width: '100%',
  },
  calendar: {
    overflowX: 'scroll',
    width: '100%',
    fontSize: '0.77em',
  },
}));

const SystemLogs = ({ t }) => {
  const classes = useStyles();
  const localLang = localStorage.getItem('i18nextLng') ?? 'tr';
  const [dateRange, setDateRange] = useState([null, null]);
  const [logs, setlogs] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, endDate] = dateRange;
  const langValue = localStorage.getItem('i18nextLng') ?? 'tr';
  const { enqueueSnackbar } = useSnackbar();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    userId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    userRole: { value: null, matchMode: FilterMatchMode.CONTAINS },
    clientIpAddress: { value: null, matchMode: FilterMatchMode.CONTAINS },
    methodName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    parameters: { value: null, matchMode: FilterMatchMode.CONTAINS },
    responseBody: { value: null, matchMode: FilterMatchMode.CONTAINS },
    responseStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
    serviceName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    query: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
    updatedAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const getSystemLogsForDateRange = async () => {
    if (!endDate) {
      enqueueSnackbar(`${t('enqueueSnackbar_message.select_date_range')}`, {
        variant: 'error',
      });
      return;
    }
    setLoading(true);
    const payload = {
      endDate: moment(endDate).format('YYYY-MM-DD'),
      startDate: moment(startDate).format('YYYY-MM-DD'),
    };
    const response = await axios.get('/dashboard/getLogByDateRange', {
      params: { ...payload },
    });
    const data = response.data;
    let resultData = [];
    for (let item of data) {
      resultData.push({
        userId: item?.userId,
        userRole: item?.userRole,
        clientIpAddress: item?.clientIpAddress,
        methodName: item?.methodName,
        parameters: item?.parameters,
        responseBody: item?.responseBody,
        responseStatus: item?.responseStatus,
        serviceName: item?.serviceName,
        query: item?.query,
        createdAt: formatDate(item?.createdAt),
        updatedAt: formatDate(item?.updatedAt),
      });
    }
    setlogs(resultData);
    setFilteredData(resultData);
    setLoading(false);
  };

  const exportExcel = () => {
    setLoading(true);
    console.log('filteredData :>> ', filteredData);
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(filteredData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      saveAsExcelFile(excelBuffer, 'files');
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
        );
      }
      setLoading(false);
    });
  };

  const renderHeader = () => {
    return (
      <div className='clearfix'>
        <div className='float-right p-2'>
          <div className='flex align-items-center export-buttons'>
            {AuthenticationService.isAdmin() && (
              <PrimeButton
                type='button'
                icon='pi pi-file-excel'
                onClick={exportExcel}
                className='p-button-success mr-2'
                data-pr-tooltip='XLS'
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const formatDate = (day) => {
    const date = moment(new Date(day * 1000));
    let time =
      langValue === 'tr'
        ? date.locale(`${t(langValue)}`).format('DD MMMM YYYY HH:mm:ss')
        : date.locale(`${t(langValue)}`).format('MMMM DD YYYY HH:mm:ss');
    return time;
  };
  return (
    <>
      <Spinner loading={loading} />
      <div className={classes.root} style={{ width: '32%' }}>
        <div style={{ display: 'block', marginTop: '0.4em' }}>
          <label htmlFor='dropdown' style={{ fontSize: '1.25em' }}>
            {t('select_date_range')}
          </label>
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <DatePicker
              // disabled={true}
              dateFormat={localLang === 'tr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              locale={localLang}
              isClearable={true}
              wrapperClassName='date-picker'
            />
            <Button
              setLoading={true}
              variant='contained'
              color='primary'
              style={{
                height: '2.7em',
                width: '10em',
                marginBottom: '2.5em',
                marginTop: '0.6em',
              }}
              onClick={() => {
                getSystemLogsForDateRange();
              }}
            >
              {t('list')}
            </Button>
          </div>
        </div>
      </div>
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>{t('LogList')}</h3>
          </div>
        </div>
        <div className='card-body p-0'>
          <DataTable
            value={logs}
            paginator
            showGridlines
            responsiveLayout='scroll'
            header={renderHeader}
            rows={10}
            paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
            rowsPerPageOptions={[10, 25, 50]}
            dataKey='id'
            rowHover
            onValueChange={(filteredData) => setFilteredData(filteredData)}
            filters={filters}
            filterDisplay='row'
            loading={loading}
            emptyMessage={t('no_records_found')}
            currentPageReportTemplate={t('currentPageReportTemplate')}
          >
            <Column
              header={t('user_id')}
              sortable={true}
              field='userId'
              filter
              style={{
                maxWidth: '100px',
                textDecoration: 'none',
                cursor: 'default',
              }}
            ></Column>

            <Column
              header={t('userRole')}
              sortable={true}
              field='userRole'
              filter
              style={{ minWidth: '100px' }}
            ></Column>

            <Column
              header={t('clientIpAddress')}
              sortable={true}
              field='clientIpAddress'
              filter
              style={{ minWidth: '100px' }}
            ></Column>

            <Column
              header={t('methodName')}
              sortable={true}
              field='methodName'
              filter
              style={{ minWidth: '100px' }}
              // body={isCompletedForm}
            ></Column>

            <Column
              header={t('parameters')}
              field='parameters'
              sortable={true}
              filter
              style={{ maxWidth: '100px' }}
            ></Column>

            <Column
              header={t('query')}
              sortable={true}
              field='query'
              filter
              style={{ maxWidth: '100px' }}
            ></Column>

            <Column
              header={t('responseBody')}
              sortable={true}
              field='responseBody'
              filter
              style={{
                maxWidth: '100px',
              }}
            ></Column>

            <Column
              header={t('responseStatus')}
              sortable={true}
              field='responseStatus'
              filter
              style={{ maxWidth: '70px' }}
              bodyStyle={{ textAlign: 'center' }}
            ></Column>

            <Column
              header={t('serviceName')}
              sortable={true}
              field='serviceName'
              filter
              style={{ maxWidth: '120px' }}
            ></Column>

            <Column
              header={t('register_date')}
              sortable={true}
              field='createdAt'
              filter
              style={{ minWidth: '100px' }}
            ></Column>
            <Column
              header={t('updatedAt')}
              sortable={true}
              field='updatedAt'
              filter
              style={{ minWidth: '100px' }}
            ></Column>
          </DataTable>
        </div>
      </div>
    </>
  );
};
export default withTranslation()(SystemLogs);
