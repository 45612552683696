import React, { useEffect, useState } from 'react';
import UserService from '../service/UserService';
import { Typography, Box } from '@material-ui/core';
import PageTitle from './../components/PageTitle';
import AuthenticationService from '../service/AuthenticationService';
import { useTranslation } from 'react-i18next';

const DashboardHome = () => {
  const { t, i18n } = useTranslation();
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    UserService.getUserCount().then((res) => setUserCount(res.data));
  }, []);

  return (
    <>
      {AuthenticationService.isAdmin() && (
        <PageTitle>{t('active_users')}</PageTitle>
      )}
      {AuthenticationService.isAdmin() && (
        <Typography variant="h6" gutterBottom>
          <Box color="red">
            <span>{userCount}</span>
          </Box>
        </Typography>
      )}
      {AuthenticationService.isDoctor() && (
        <Typography variant="h6" gutterBottom>
          <Box color="red">
            <span>{t('hello_doctor')}</span>
          </Box>
        </Typography>
      )}
    </>
  );
};

export default DashboardHome;
