import axios from 'axios';

class CompanySizeService {
	getList() {
		return axios.get('/lookup/getAllCompanySizes');
	}

	getById(id) {
		return axios.get('/lookup/getCompanySizesById/' + id);
	}

	save(row) {
		return axios.post('/lookup/saveCompanySizes', row);
	}

	update(row) {
		return axios.post('/lookup/updateCompanySizes', row);
	}
	delete(id) {
		return axios.delete(`/lookup/deleteCompanySizesById/${id}`);
	}
}

export default new CompanySizeService();
