import axios from "axios";

class DoctorDemographicsService {
    getDoctorDemographicsInfo() {
        return axios.get("/users/doctorInfo");
    }

    getDoctorDemographicsInfoByUserId(userId) {
        return axios.get("/users/doctorDemographicsByUserId", { params: { userId } });
    }
}

export default new DoctorDemographicsService();