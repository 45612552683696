import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ListboxComponent from './ListboxComponent';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { baseTheme } from './../../baseTheme';

const textFieldTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: '#0099F7',
    },
  },
});

const LargeSelectInput = ({ options, dontIncludeOther, label, ...props }) => {
  const { field, fieldState, multiple } = props;

  const getDefaultValue = () => {
    if (props.defaultValue) {
      return props.defaulValue;
    } else if (multiple) {
      return [];
    }
    return field.value || '';
  };

  const defaultValue = getDefaultValue();

  return (
    <ThemeProvider theme={textFieldTheme}>
      <Autocomplete
        freeSolo
        getOptionLabel={(option) => (option ? option.name : '')}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={(_, data) => field.onChange(data)}
        defaultValue={defaultValue}
        ListboxComponent={ListboxComponent}
        value={field.value}
        {...props}
        options={[...options]}
        renderInput={(params) => {
          return (
            <TextField
              size="small"
              margin="dense"
              variant="outlined"
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              required={props.required}
              label={label}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'disabled', // disable autocomplete and autofill
              }}
              {...params}
            />
          );
        }}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.name, inputValue);
          const parts = parse(option.name, matches);

          return (
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    </ThemeProvider>
  );
};

export default LargeSelectInput;
