import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import store from './../Store';
import { useSnapshot } from 'valtio';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  datePickerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    backgroundColor: '#eeeeee',
  },
  cardAction: {
    justifyContent: 'center',
  },
  listItem: {
    marginBottom: 10,
    borderRadius: 5,
  },
  listItemText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body1.fontSize,
  },
  listItemSelected: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  matchItem: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  paper: {
    maxWidth: '50%',
    borderRadius: 5,
    transition: '0.2s',
  },
  paperHovered: {
    '&:hover': {
      cursor: 'pointer',

      transform: 'scale(1.1)',
    },
  },
  itemDisabled: {
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  slotWrapper: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(3),
    },
  },
  slotButton: {
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));

export default function SelectAppointmentDateTime({
  title,
  onSlotSelected,
  appointments,
  type,
  activeStep,
  selectDate,
  selectedTime,
}) {
  const snapshot = useSnapshot(store);
  const classes = useStyles();
  const [slots, setSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment(new Date()));
  const [value1, setValue1] = useState();
  const [value2, setValue2] = useState();
  const [color, setColor] = useState(false);
  const [matchSlot, setMatchSlot] = useState([]);

  useEffect(() => {
    if (selectedTime) {
      setValue1(selectedTime?.startTime);
      setValue2(selectedTime?.endTime);
    }
  }, [selectedTime]);

  const getCurrentSelectedSlot = (selectedDate) => {
    let app;
    if (activeStep === 1) {
      app = snapshot.firstSelectedAppointment;
    } else {
      app = snapshot.secondSelectedAppointment;
    }

    if (!app) return null;

    if (selectedDate === app.selectedDate) {
      return app.slot;
    }
    return null;
  };
  const [selectedSlot, setSelectedSlot] = useState(getCurrentSelectedSlot());
  const { t } = useTranslation();
  useEffect(() => {
    const date = moment(selectedDate).format('YYYY-MM-DD');
    if (appointments.size < 1) return;

    let slots = appointments.get(date);
    setSlots(slots);
  }, [appointments, selectedDate]);

  useEffect(() => {
    if (selectDate) setSelectedDate(new Date(selectDate));
  }, [selectDate]);

  const updateSlots = () => {
    const date = moment(selectedDate).format('YYYY-MM-DD');
    let slots = appointments.get(date);

    if (type !== 'C') {
      /* let otherAppointment = snapshot.firstSelectedAppointment;
              if(otherAppointment) {
                  let idx = findOverlappingSlotIndex(otherAppointment, date, slots);
                  if (idx !== -1) {
                      slots[idx].available = false;
                  }
              }; */
    } else {
      if (activeStep === 1) {
        let idx = findOverlappingSlotIndex(
          snapshot.firstSelectedAppointment,
          date,
          slots
        );
        if (idx !== -1) slots[idx].available = true;

        let otherAppointment = snapshot.secondSelectedAppointment;
        if (otherAppointment) {
          let idx = findOverlappingSlotIndex(otherAppointment, date, slots);
          if (idx !== -1) {
            slots[idx].available = false;
          }
        }
      } else {
        let idx = findOverlappingSlotIndex(
          snapshot.secondSelectedAppointment,
          date,
          slots
        );
        if (idx !== -1) slots[idx].available = true;

        let otherAppointment = snapshot.firstSelectedAppointment;
        if (otherAppointment) {
          let idx = findOverlappingSlotIndex(otherAppointment, date, slots);
          if (idx !== -1) {
            slots[idx].available = false;
          }
        }
      }
    }
  };

  const findOverlappingSlotIndex = (existingAppointment, date, slots) => {
    if (existingAppointment && existingAppointment.selectedDate === date) {
      return slots.findIndex((slot) =>
        isSlotEqual(existingAppointment.slot, slot)
      );
    }

    return -1;
  };

  const onDateChanged = (date) => {
    const slots = appointments.get(date);
    setSlots(slots);
    setSelectedDate(moment(date));
    setSelectedSlot(getCurrentSelectedSlot(date));
    onSlotSelected(activeStep, moment(date).format('YYYY-MM-DD'), {
      start: value1,
      end: value2,
    });
  };

  useEffect(() => {
    if (Array.isArray(slots) && slots.length > 0) {
      const match = slots.filter(
        (item) => item.start === value1 && item.end === value2
      );
      setMatchSlot(match);
    }
  }, [slots, value1, value2]);

  useEffect(() => {
    onSlotSelected(activeStep, moment(selectedDate).format('YYYY-MM-DD'), {
      start: value1,
      end: value2,
    });
  }, [value1, setValue1]);

  const isSlotEqual = (slotA, slotB) => {
    if (!slotA || !slotB) return false;
    return slotA.start === slotB.start;
  };

  const isSlotSelectedForDate = (date) => {
    let selected = false;
    if (activeStep === 1) {
      let selectedAppointment = snapshot.firstSelectedAppointment;
      if (selectedAppointment && selectedAppointment.selectedDate === date) {
        selected = true;
      }
    } else {
      let selectedAppointment = snapshot.secondSelectedAppointment;
      if (selectedAppointment && selectedAppointment.selectedDate === date) {
        selected = true;
      }
    }
    return selected;
  };

  updateSlots();

  const sloteMatch = (slotA, slotB) => {
    if (!slotA || !slotB) return false;

    return slotA[0]?.start === slotB.start;
  };

  const endHandleChange = (e) => {
    onSlotSelected(activeStep, moment(selectedDate).format('YYYY-MM-DD'), {
      start: value1,
      end: e.currentTarget.value,
    });
  };
  const startHandleChange = (e) => {
    onSlotSelected(activeStep, moment(selectedDate).format('YYYY-MM-DD'), {
      start: e.currentTarget.value,
      end: value2,
    });
  };

  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Grid container className='appointment' justifyContent='space-evenly'>
            <Grid item={true} md={4}>
              <div className={classes.datePickerWrapper}>
                <Typography variant='h6' component='h2'>
                  {title}
                </Typography>
                <DatePicker
                  autoOk
                  variant='static'
                  openTo='date'
                  minDate={moment(new Date()).subtract(2, 'd')}
                  maxDate={moment(new Date()).add(appointments.size, 'days')}
                  // disableFuture={
                  //   type === 'Z'
                  //     ? appointments.size == null || appointments.size < 1
                  //     : slots == null || slots.length < 1
                  // }
                  value={selectedDate}
                  onChange={(newDate) =>
                    onDateChanged(moment(newDate).format('YYYY-MM-DD'))
                  }
                />
              </div>
            </Grid>
            <Grid item={true} md={4}>
              <div className={classes.slotWrapper}>
                {type === 'Z' && (
                  <div>
                    <Typography variant='h6' component='h2'>
                      {t('booked_appointments')}
                    </Typography>
                  </div>
                )}
                <ListItemAvatar>
                  {slots &&
                    slots.map((slot, idx) => (
                      <Paper
                        key={idx}
                        className={clsx(
                          classes.paper,
                          slot.available && classes.paperHovered,
                          !slot.available && classes.itemDisabled
                        )}
                      >
                        <ListItem
                          onClick={() => {
                            if (slot.available) {
                              setSelectedSlot(slot);
                              onSlotSelected(
                                activeStep,
                                moment(selectedDate).format('YYYY-MM-DD'),
                                slot
                              );
                            }
                            return;
                          }}
                          ContainerComponent='div'
                          disabled={
                            !isSlotEqual(selectedSlot, slot) && !slot.available
                          }
                          className={clsx(
                            classes.listItem,
                            isSlotSelectedForDate(
                              moment(selectedDate).format('YYYY-MM-DD')
                            ) && selectedSlot
                              ? isSlotEqual(selectedSlot, slot) &&
                                  classes.listItemSelected
                              : sloteMatch(matchSlot, slot) && classes.matchItem

                            // isSlotSelectActive(value1, value2, slot) &&
                          )}
                        >
                          <ListItemText
                            disableTypography
                            className={classes.listItemText}
                            primary={`${slot.start} - ${slot.end}`}
                          />
                          <ListItemSecondaryAction>
                            <IconButton edge='end'>
                              <CheckCircleOutlineRoundedIcon
                                style={{ color: 'white' }}
                              />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </Paper>
                    ))}
                </ListItemAvatar>
              </div>
            </Grid>
            {type === 'Z' && (
              <Grid item={true} md={4}>
                <Typography variant='h6' component='h2' className='mb-2'>
                  {t('new_appointment_start_end_time')}
                </Typography>
                <TextField
                  id='time'
                  label={t('start')}
                  type='time'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: 150 }}
                  style={{ background: '#fff', marginRight: '12px' }}
                  value={value1}
                  onChange={(e) => {
                    startHandleChange(e);
                    setValue1(e.currentTarget.value);
                  }}
                />
                <TextField
                  error={color}
                  id='time'
                  label={t('end')}
                  type='time'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: 150 }}
                  style={{ background: '#fff' }}
                  value={value2}
                  onChange={(e) => {
                    endHandleChange(e);
                    setValue2(e.currentTarget.value);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
