import axios from 'axios';

class ExperienceLevelService {
	getList() {
		return axios.get('/lookup/getAllSeniorityLevel');
	}

	getById(id) {
		return axios.get('/lookup/getSeniorityLevelById/' + id);
	}

	save(row) {
		return axios.post('/lookup/saveNewSeniorityLevel', row);
	}

	update(row) {
		return axios.post('/lookup/updateSeniorityLevel', row);
	}
	delete(id) {
		return axios.delete(`/lookup/deleteSeniorityLevelById/${id}`);
	}
}

export default new ExperienceLevelService();
