import { TramRounded } from '@mui/icons-material';
import React, { useState, useEffect, Suspense } from 'react';
import TestComponent from '../components/customReportComponent';

const CustomTestReport = () => {
  return (
    <div>
      <TestComponent />
    </div>
  );
};

export default CustomTestReport;
