import axios from 'axios';

class CustomerService {
	getCustomerList() {
		return axios.get('/dashboard/getCustomers');
	}

	getById(id) {
		return axios.get('/dashboard/getCustomerById/' + id);
	}

	save(row) {
		return axios.post('/dashboard/saveCustomer', row);
	}

	update(row) {
		return axios.post('/dashboard/updateCustomer', row);
	}
	delete(id) {
		return axios.delete(`/dashboard/deleteCustomerById/${id}`);
	}
}

export default new CustomerService();
