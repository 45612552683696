/* eslint-disable no-undef */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import parsePhoneNumber from 'libphonenumber-js';
import { DropzoneDialog } from 'material-ui-dropzone';
import {
	Button,
	Checkbox,
	CssBaseline,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Box,
	Grid,
	Tooltip,
	TextareaAutosize,
	TextField,
} from '@material-ui/core';
import axios from 'axios';
import * as Yup from 'yup';
import 'yup-phone';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { MainButton } from '../common/MainButton';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import '../userInfo.css';
import { useTranslation } from 'react-i18next';
import { tr, enUS } from 'date-fns/locale';
import FormInput from '../common/FormInput';
import UserService from '../../service/UserService';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	card: {
		padding: 20,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	commChannels: {
		marginTop: theme.spacing(2),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	register: {
		'&:hover': {
			textDecoration: 'underline',
		},
		textTransform: 'none',
	},
}));
registerLocale('tr', tr);
registerLocale('enUS', enUS);

export const SarfReportCreateText = ({ onClose, fetchAutoReportText }) => {
	const { t } = useTranslation();

	const Schema = Yup.object().shape({
		autoReportTextId: Yup.string().required(`${t('schema_shape.not_empty')}`),
		validUntilDate: Yup.string().required(`${t('schema_shape.not_empty')}`),
		languageId: Yup.string().required(`${t('schema_shape.not_empty')}`),
		text: Yup.string().required(`${t('schema_shape.not_empty')}`),
	});

	const toast = useRef(null);
	// let {path} = useRouteMatch();
	const { enqueueSnackbar } = useSnackbar();

	let history = useHistory();
	const classes = useStyles();
	const { control, handleSubmit } = useForm({
		defaultValues: {
			isSummary: false,
		},
	});
	const [isDoctor, setIsDoctor] = useState(false);
	const updateDoctor = () => setIsDoctor(!isDoctor);
	const [languages, setLanguages] = useState([]);
	const [languageId, setLanguageId] = useState(null);
	const [startDate, setStartDate] = useState('');
	const [paragraphTypes, setParagraphTypes] = useState([]);
	const [paragraphTypeId, setParagraphTypeId] = useState();
	const datePicker = useRef(null);
	const language = [
		{ label: t('Turkish'), value: 'tr' },
		{ label: t('English'), value: 'en' },
	];
	const paragraphType = [
		{ label: t('Sentence'), value: 1 },
		{ label: t('Conjuction'), value: 2 },
	];

	useEffect(() => {
		setStartDate(() => {
			return new Date('12-31-2500');
		});
		setParagraphTypes(paragraphType);
		setLanguages(language);
	}, []);
	// Request in order to save data

	const saveSarfReportAutoReportText = (payload) => {
		axios
			.post('/lookup/saveSarfReportAutoReportText', payload)
			.then((response) => {
				enqueueSnackbar(t('Saved successfully'), {
					variant: 'success',
				});
				onClose();
				fetchAutoReportText();
			})
			.catch((err) => {
				if (err.response.status === 400) {
					enqueueSnackbar(
						`${t(
							'enqueueSnackbar_message.AutoReportTextId and LanguageId must be unique'
						)}`,
						{
							variant: 'error',
						}
					);
				} else {
					enqueueSnackbar(`${t('enqueueSnackbar_message.user_update_error')}`, {
						variant: 'error',
					});
				}
			});
	};

	// formdata saving to payload and validations conditons
	const onFinish = (formData) => {
		formData['languageId'] = languageId;
		let date = moment(startDate).format('YYYY-MM-DD');
		formData['validUntilDate'] = date;
		formData['paragraphTypeId'] = paragraphTypeId;
		const payload = {
			autoReportTextId: formData.autoReportTextId,
			languageId: formData.languageId,
			text: formData.text,
			validUntilDate: formData.validUntilDate,
			textType: formData.paragraphTypeId,
			isSummary: formData.isSummary,
		};

		if (
			payload.autoReportTextId === '' ||
			payload.autoReportTextId === undefined ||
			payload.autoReportTextId === null
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('autoreporttextmessages.autoreporttextId_missing')}`,
			});
			return;
		}
		if (
			payload.text === '' ||
			payload.text === undefined ||
			payload.text === null
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('autoreporttextmessages.text_missing')}`,
			});
			return;
		}

		if (
			payload.languageId === '' ||
			payload.languageId === undefined ||
			payload.languageId === null
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('autoreporttextmessages.language_id_missing')}`,
			});
			return;
		}

		if (
			payload.validUntilDate === '' ||
			payload.validUntilDate === undefined ||
			payload.validUntilDate === null
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('autoreporttextmessages.valid_until_date_missing')}`,
			});
			return;
		} else {
			saveSarfReportAutoReportText(payload);
		}
	};

	const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
		<TextField
			className={classes.formControl}
			variant='outlined'
			size='small'
			margin='dense'
			placeholder=''
			value={value}
			onClick={onClick}
			ref={ref}
		/>
	));

	return (
		<>
			<Toast ref={toast} />

			<div className='card card-custom gutter-b'>
				<div className='card-body'>
					<div className={classes.paper}>
						<form
							className={classes.form}
							noValidate
							onSubmit={handleSubmit(onFinish)}
							encType='multipart/form-data'
						>
							<label>{t('Paragraph')} ID</label>
							<Controller
								name='autoReportTextId'
								control={control}
								render={(props) => (
									<FormInput {...props} margin='dense' required />
								)}
							/>
							<label>{t('Paragraph')}</label>
							<Controller
								name='text'
								control={control}
								render={(props) => (
									<FormInput
										{...props}
										margin='dense'
										required
										multiline={true}
									/>
								)}
							/>
							<div
								className='d-flex justify-content-between align-items-center'
								style={{ width: '100%' }}
							>
								<div className='flex-1'>
									<label>{t('Language')}</label>
									<Controller
										control={control}
										name='languageId'
										autocomplete='off'
										render={(props) => (
											<div style={{ marginTop: '10px' }}>
												<Dropdown
													style={{ width: '100%' }}
													options={languages}
													value={languageId}
													appendTo='self'
													onChange={(e) => {
														setLanguageId(e.value);
													}}
													{...props}
												/>
											</div>
										)}
									/>
								</div>
								<div className='flex-1' style={{ marginTop: 5, marginLeft: 5 }}>
									<label>{t('valid_untile_date')}</label>
									<Controller
										control={control}
										name='validUntilDate'
										defaultValue=''
										render={(props) => (
											<>
												<DatePicker
													ref={datePicker}
													{...props}
													locale={
														localStorage.getItem('i18nextLng') === 'tr'
															? 'tr'
															: 'enUS'
													}
													selected={startDate}
													showMonthDropdown
													showYearDropdown
													yearDropdownItemNumber={478}
													scrollableYearDropdown
													scrollableMonthDropdown
													popperPlacement='top-start'
													dropdownMode='scroll'
													onChange={(date) => {
														setStartDate(date);
													}}
													customInput={<ExampleCustomInput />}
												/>
											</>
										)}
									/>
								</div>
							</div>

							<div className='d-flex justify-content-around'>
								<MainButton
									type='submit'
									variant='contained'
									color='primary'
									className={classes.submit}
								>
									{t('save')}
								</MainButton>
								<MainButton
									type='button'
									variant='contained'
									color='primary'
									className={classes.submit}
									onClick={onClose}
								>
									{t('GIVE_UP')}
								</MainButton>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};
