import React, { useEffect, useState } from 'react'
import axios from 'axios'
import LayoutComponent from '../components/LayoutComponent'
import CustomizedTreeView from '../components/common/CustomizedTreeView'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import './review.css'
import './points.css'

const ExpertPoints = () => {
  let initialState = {
    user: { firstname: '', lastname: '', gender: '', age: 0 },
    score: 0,
    modules: [
      {
        name: '',
        description: '',
        score: 0,
        domains: [{ subdomains: [{}] }],
      },
    ],
  }

  const [data, setData] = useState(initialState)
  const [open, setOpen] = useState(true)

  useEffect(() => {
    axios
      .get('/reports/data')
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
      })
  }, [])

  return (
    <LayoutComponent>
      <div className="container custom">
        <div className="sidebar">
          <div className="user-infos">
            <ul className="user-info">
              <li className="userName" onClick={() => setOpen(!open)}>
                {data.user.firstname} {data.user.lastname}
                Mehmet Ak
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    opacity: 1,
                    transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                    transition: '0.2s',
                    float: 'right',
                    marginTop: '35px',
                    marginRight: '50px',
                    color: '#1199f7',
                    cursor: 'pointer',
                  }}
                />
              </li>
              {!open && (
                <>
                  <li>{data.user.gender}</li>
                  <li>{data.user.age}</li>
                </>
              )}
            </ul>
          </div>

          <div className="user-infos second">
            <CustomizedTreeView className="treeview" />
          </div>
        </div>
        <div className="center_box_items">
          <div className="box_item">
            <div className="top_box_content">
              <h2 className="top_title">neOAUVRA Puanı: 1000-600</h2>
              <div className="list_items">
                <div className="item">
                  <span className="right_item">Systolic Blood Pressure</span>
                  <span className="left_item">123 mmHg</span>
                </div>
              </div>
              <h2 className="bottom_title">Flexibility</h2>
              <div className="list_items">
                <div className="item">
                  <span className="right_item">Systolic Blood Pressure</span>
                  <span className="left_item">123 </span>
                </div>
                <div className="item">
                  <span className="right_item">Systolic Blood Pressure</span>
                  <span className="left_item">123 </span>
                </div>
                <div className="item">
                  <span className="right_item">Systolic Blood Pressure</span>
                  <span className="left_item">123 </span>
                </div>
              </div>
            </div>
          </div>
          <div className="box_item">
            <div className="bottom_box_content">
              <h2 className="top_title">neOAUVRA Puanı: 400-0</h2>
              <div className="list_items">
                <div className="item">
                  <span className="right_item">Systolic Blood Pressure</span>
                  <span className="left_item">123 mmHg</span>
                </div>
              </div>
              <h2 className="bottom_title">Flexibility</h2>
              <div className="list_items">
                <div className="item">
                  <span className="right_item">Systolic Blood Pressure</span>
                  <span className="left_item">123 </span>
                </div>
                <div className="item">
                  <span className="right_item">Systolic Blood Pressure</span>
                  <span className="left_item">123 </span>
                </div>
                <div className="item">
                  <span className="right_item">Systolic Blood Pressure</span>
                  <span className="left_item">123 </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right_box_item">
          <div className="box_item">
            <div className="right_box_content">
              <h2 className="top_title">neOAUVRA Puanı: 400-0</h2>
              <div className="list_items">
                <div className="item">
                  <span className="right_item">Systolic Blood Pressure</span>
                  <span className="left_item">123 mmHg</span>
                </div>
              </div>
              <h2 className="bottom_title">Flexibility</h2>
              <div className="list_items">
                <div className="item">
                  <span className="right_item">Systolic Blood Pressure</span>
                  <span className="left_item">123 mmHg</span>
                </div>
                <div className="item">
                  <span className="right_item">Systolic Blood Pressure</span>
                  <span className="left_item">123 mmHg</span>
                </div>
                <div className="item">
                  <span className="right_item">Systolic Blood Pressure</span>
                  <span className="left_item">123 mmHg</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutComponent>
  )
}
export default ExpertPoints
