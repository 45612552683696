import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocationService from '../service/LocationService';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import Spinner from '../components/common/Spinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact';
import { debounce } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Button } from 'primereact';
import { MainButton } from '../components/common/MainButton';
import { Autocomplete, TextField } from '@mui/material';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormInputGrid from '../components/common/FormInputGrid';
import FormInput from '../components/common/FormInput';
import { Input } from '@material-ui/core';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import axios from 'axios';

const LocationAdmins = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [displayOperatorUserDialog, setDisplayOperatorUserDialog] =
    useState(false);
  const [locationAdminSearchName, setLocationAdminSearchName] = useState('');
  const [locationAdminSearchId, setLocationAdminSearchId] = useState('');
  const [operatorTableData, setOperatorTableData] = useState([]);
  const [locationAdminByIncludingUsers, setLocationAdminByIncludingUsers] =
    useState([]);
  const [
    autoCompleteSelectedLocationAdminNo,
    setautoCompleteSelectedLocationAdminNo,
  ] = useState(null);
  const [options, setOptions] = useState([]);
  const [locationAdmin, setLocationAdmin] = useState([]);
  const [locationAdminIdx, setlocationAdminIdx] = useState(null);
  const languageId = localStorage.getItem('i18nextLng');
  const params = useParams();
  const toast = useRef(null);
  const previousController = useRef();
  const { handleSubmit, control, register } = useForm({});
  const { enqueueSnackbar } = useSnackbar();

  const onSubmitForText = async (e) => {
    e.preventDefault();
    if (!locationAdminSearchName) {
      toast.current.show({
        severity: 'warn',
        summary: `${t('WARNING')}`,
        detail: `${t('please_enter_value')}`,
        life: 3000,
      });
      return;
    }
    try {
      setLoading(true);
      const response =
        await LocationService.getLocationsByLocationAdminNameOrSurname(
          locationAdminSearchName
        );
      for (let index of response.data) {
        index.fullName = index.name + ' ' + index.surname;
        index.fullLocation =
          index.location.country.name +
          '/' +
          index.location.city.name +
          '/' +
          index.location.name;
      }
      setLocationAdminByIncludingUsers(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onInputChange = (event, value, reason) => {
    if (value && event._reactName !== 'onClick') {
      search(value);
    } else if (value && event._reactName == 'onClick') {
      return;
    } else {
      setOptions([]);
    }
  };

  const search = debounce((value) => {
    let filterTimeout;
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    clearTimeout(filterTimeout);
    if (value.length >= 3) {
      setLoading(true);
      axios
        .get(`/dashboard/getLocationsByLocationAdminId/${value}`)
        .then((res) => {
          let data = res.data;

          if (data != null) {
            for (let index of data) {
              index.fullName = index.name + ' ' + index.surname;
              index.fullLocation =
                index.location.country.name +
                '/' +
                index.location.city.name +
                '/' +
                index.location.name;
            }
            setLocationAdminByIncludingUsers(data);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setLoading(false);
            toast.current.show({
              severity: 'warn',
              summary: `${t('appointment_alert_messages.transaction_status')}`,
              detail: `${t('number_only')}`,
            });
          }
        });
    }
    if (!value) return setOptions([]);
  }, 500);

  const handleCallback = (data) => {
    setautoCompleteSelectedLocationAdminNo(data.fullName);
    setLocationAdmin(data);
    setlocationAdminIdx(data.user.id);
  };

  const deleteSvg = (
    <svg
      fill='#EC0044'
      height='32'
      viewBox='0 0 20 20'
      width='32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.5 4C11.5 3.17157 10.8284 2.5 10 2.5C9.17157 2.5 8.5 3.17157 8.5 4H7.5C7.5 2.61929 8.61929 1.5 10 1.5C11.3807 1.5 12.5 2.61929 12.5 4H17C17.2761 4 17.5 4.22386 17.5 4.5C17.5 4.77614 17.2761 5 17 5H16.446L15.1499 16.2292C15.0335 17.2384 14.179 18 13.1631 18H6.83688C5.821 18 4.9665 17.2384 4.85006 16.2292L3.553 5H3C2.75454 5 2.55039 4.82312 2.50806 4.58988L2.5 4.5C2.5 4.22386 2.72386 4 3 4H11.5ZM15.438 5H4.561L5.84347 16.1146C5.90169 16.6192 6.32894 17 6.83688 17H13.1631C13.6711 17 14.0983 16.6192 14.1565 16.1146L15.438 5ZM8.5 7.5C8.74546 7.5 8.94961 7.65477 8.99194 7.85886L9 7.9375V14.0625C9 14.3041 8.77614 14.5 8.5 14.5C8.25454 14.5 8.05039 14.3452 8.00806 14.1411L8 14.0625V7.9375C8 7.69588 8.22386 7.5 8.5 7.5ZM11.5 7.5C11.7455 7.5 11.9496 7.65477 11.9919 7.85886L12 7.9375V14.0625C12 14.3041 11.7761 14.5 11.5 14.5C11.2545 14.5 11.0504 14.3452 11.0081 14.1411L11 14.0625V7.9375C11 7.69588 11.2239 7.5 11.5 7.5Z'
        fill='#EC0044'
      />
    </svg>
  );

  const confirm = (data) => {
    confirmDialog({
      message: `${t('delete_location_admin_list', {
        name: data.fullName,
        location: data.fullLocation,
      })} `,
      header: `${t('appointment_alert_messages.confirmation')}`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: `${t('yes')}`,
      rejectLabel: `${t('no')}`,
      accept: () => accept(data.id),
      reject: () => reject(),
    });
  };
  const accept = (id) => {
    deleteLocation(id);
  };
  const reject = (id) => {
    toast.current.show({
      severity: 'warn',
      summary: `${t('appointment_alert_messages.denied')}`,
      detail: `${t('appointment_alert_messages.action_declined')}`,
      life: 3000,
    });
  };

  const deleteLocation = async (id) => {
    try {
      await LocationService.deleteLocationAdminForLocationById(id);
      const filteredData = locationAdminByIncludingUsers.filter(
        (item) => item.id !== id
      );
      setLocationAdminByIncludingUsers(filteredData);

      toast.current.show({
        severity: 'info',
        summary: `${t('appointment_alert_messages.congratulations')}`,
        detail: `${t('appointment_alert_messages.delete_user_success')}`,
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: `${t('appointment_alert_messages.short_error')}`,
        detail: `${error.response.data}`,
        life: 3000,
      });
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className='d-flex'>
          <Button
            type='button'
            tooltip={t('delete')}
            onClick={(event) => {
              confirm(rowData);
            }}
            className='p-button-text'
          >
            {deleteSvg}
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} />
      <Spinner loading={loading} />
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>{t('menu.location_admin_list')}</h3>
          </div>
        </div>
        <div className='card-body'>
          <div className='flex' style={{ gap: 36 }}>
            <form>
              <div>
                <label className='block'>{t('locationAdmin_userId')}</label>
                <div
                  className='flex align-items-baseline '
                  style={{ maxWidth: 200, gap: 12 }}
                >
                  <Autocomplete
                    className='mt-2'
                    id='combo-box-demo'
                    filterOptions={(x) => x}
                    options={options}
                    onInputChange={onInputChange}
                    getOptionLabel={(option) => option.fullName}
                    onChange={(event, newValue) => {
                      handleCallback(newValue);
                    }}
                    style={{
                      width: 300,
                      height: '1rem',
                      marginTop: '0.5rem',
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        className='selam'
                      />
                    )}
                  />
                </div>
              </div>
            </form>
            <form onSubmit={onSubmitForText}>
              <div>
                <label className='block'>{t('locationAdmin_userName')}</label>
                <div
                  className='flex align-items-baseline '
                  style={{ maxWidth: 400, gap: 12, marginTop: 5 }}
                >
                  <InputText
                    value={locationAdminSearchName}
                    onChange={(e) => setLocationAdminSearchName(e.target.value)}
                  />
                  <MainButton
                    setLoading={true}
                    variant='contained'
                    color='primary'
                    type='submit'
                    style={{
                      height: '2.7em',
                      width: '6em',
                      marginBottom: '2.5em',
                      marginTop: '0.6em',
                    }}
                  >
                    {t('search')}
                  </MainButton>
                </div>
              </div>
            </form>
          </div>

          <div className='card card-custom gutter-b'>
            <div className='card-body'>
              <DataTable
                value={locationAdminByIncludingUsers}
                paginator
                showGridlines
                responsiveLayout='scroll'
                rows={10}
                paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                rowsPerPageOptions={[10, 25, 50]}
                dataKey='id'
                rowHover
                loading={loading}
                emptyMessage={t('no_records_found')}
                currentPageReportTemplate={t('currentPageReportTemplate')}
              >
                <Column
                  headerStyle={{ width: '8rem', textAlign: 'center' }}
                  bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                  header={t('action')}
                  body={actionBodyTemplate}
                />
                <Column
                  field='fullLocation'
                  header={t('location')}
                  sortable
                  style={{ minWidth: '14rem' }}
                />
                <Column
                  field='fullName'
                  header={t('name_surname')}
                  sortable
                  style={{ minWidth: '14rem' }}
                />
                <Column
                  field='userId'
                  header={t('user_id')}
                  sortable
                  style={{ minWidth: '14rem' }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(LocationAdmins);
