import axios from 'axios';

class GenerationService {
	getGenerationList() {
		return axios.get('/lookup/getAllGenerations');
	}

	getById(id) {
		return axios.get('/lookup/getGenerationsById/' + id);
	}

	save(row) {
		return axios.post('/lookup/saveNewGenerations', row);
	}

	update(row) {
		return axios.post('/lookup/updateGenerations', row);
	}
	delete(id) {
		return axios.delete(`/lookup/deleteGenerationsById/${id}`);
	}
}

export default new GenerationService();
