import axios from 'axios';
export const GENDER_URL = '/lookup/gendersByLanguageId/';
export const JOBTITLE_URL = '/lookup/jobTitlesByLanguageId/';
export const ETHNICITY_URL = '/lookup/ethnicitiesByLanguageId/';
export const MARITALSTATUS_URL = '/lookup/maritalStatusesByLanguageId/';
export const CHILDSTATUS_URL = '/lookup/childStatusesByLanguageId/';
export const EDUCATION_URL = '/lookup/educationsByLanguageId/';
export const ANNUALINCOME_URL = 'lookup/annualIncomes';
// export const ANNUALINCOME_URL = 'lookup/annualIncomesByLanguageId';
export const COUNTRIES_URL = '/lookup/allCountriesByLanguageId/';
export const OCCUPATIONS_URL = '/lookup/occupationsByLanguageId/';
export const WORKSTATUSES_URL = '/lookup/workStatusesByLanguageId/';
export const TESTS_URL = '/lookup/tests';
export const DOMAINS_URL = '/lookup/domains';
export const SUBDOMAINS_URL = '/lookup/subDomains';
export const METRICS_URL = '/lookup/metrics';

class LookupService {
	fetchDemographicLookups(id) {
		return new Promise((resolve, reject) => {
			axios
				.all([
					axios.get(JOBTITLE_URL + id),
					axios.get(GENDER_URL + id),
					axios.get(ETHNICITY_URL + id),
					axios.get(MARITALSTATUS_URL + id),
					axios.get(CHILDSTATUS_URL + id),
					axios.get(EDUCATION_URL + id),
					axios.get(ANNUALINCOME_URL),
					// axios.get(ANNUALINCOME_URL, { headers: { CountryId } }),
					axios.get(COUNTRIES_URL + id),
					axios.get(OCCUPATIONS_URL + id),
					axios.get(WORKSTATUSES_URL + id),
				])
				.then(
					axios.spread((...responses) => {
						resolve(responses);
					})
				);
		});
	}

	fetchDashboardLookups(id) {
		return new Promise((resolve, reject) => {
			axios
				.all([
					axios.get(JOBTITLE_URL + id),
					axios.get(GENDER_URL + id),
					axios.get(ETHNICITY_URL + id),
					axios.get(MARITALSTATUS_URL + id),
					axios.get(CHILDSTATUS_URL + id),
					axios.get(EDUCATION_URL + id),
					axios.get(ANNUALINCOME_URL),
					axios.get(COUNTRIES_URL + id),
					axios.get(OCCUPATIONS_URL + id),
					axios.get(WORKSTATUSES_URL + id),
					axios.get(DOMAINS_URL),
					axios.get(SUBDOMAINS_URL),
					axios.get(METRICS_URL),
				])
				.then(
					axios.spread((...responses) => {
						resolve(responses);
					})
				);
		});
	}

	fetchAnnualIncomesForCountryId(CountryId, languageId) {
		return axios.get('/lookup/annualIncomesByLanguageId/' + languageId, {
			headers: { CountryId },
		});
	}
}

export default new LookupService();
