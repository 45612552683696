import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import ButtonMaterial from '@mui/material/Button';

import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ConfirmPopup } from 'primereact/confirmpopup';
import CompetencyNewDefinitionService from '../service/CompetencyNewDefinitionService';
import { InputTextarea } from 'primereact/inputtextarea';

const AddNewCompetencyDefinition = ({
	competencyId,
	t,
	addCompetencyDefinitionModalOpen,
	getCompetencyDefinitions,
	handleCloseAddCompetencyModal,
	setLoading,
	getDefinitionsByCompetencyId,
	organitizationalImportance,
}) => {
	const toast = useRef(null);
	const toastInside = useRef(null);
	const [competencyDefinition, setCompetencyDefinition] = useState({
		behavioralPatternDescriptionText: '',
		definition: '',
		id: '',
		languageId: '',
		organitizationalImportance: '',
		sarfCompetencyId: '',
	});
	const [languages, setLanguages] = useState([
		{ languageId: 'tr' },
		{ languageId: 'en' },
	]);

	const saveCompetencyDefinition = () => {
		competencyDefinition.sarfCompetencyId = competencyId;
		competencyDefinition.languageId =
			competencyDefinition?.languageId?.languageId;

		setLoading(true);

		CompetencyNewDefinitionService.save(competencyDefinition)
			.then((res) => {
				setTimeout(() => {
					handleCloseAddCompetencyModal();
					setLoading(false);
					setCompetencyDefinition({
						behavioralPatternDescriptionText: '',
						definition: '',
						id: '',
						languageId: '',
						organitizationalImportance: '',
						sarfCompetencyId: '',
					});
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
						life: 3000,
					});
				}, 2000);
				getDefinitionsByCompetencyId(competencyId);
			})
			.catch((error) => {
				setLoading(false);

				toastInside.current.show({
					severity: 'error',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: error.response.data.message || 'An error occurred', // Pass a string here
					life: 3000,
				});
			});
	};

	return (
		<>
			<Toast ref={toast} />

			<Dialog
				open={addCompetencyDefinitionModalOpen}
				fullWidth={true}
				maxWidth={'sm'}
				onClose={() => {
					handleCloseAddCompetencyModal();
					setCompetencyDefinition({
						behavioralPatternDescriptionText: '',
						definition: '',
						id: '',
						languageId: '',
						organitizationalImportance: '',
						sarfCompetencyId: '',
					});
				}}
			>
				<Toast ref={toastInside} />

				<DialogContent
					style={{
						overflowY: 'visible',
						display: 'flex',
						justifyContent: 'start',
						flexDirection: 'column',
						height: '300px',
					}}
				>
					<FormControl bodyStyle={{ width: '400px' }}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginRight: '20px',
									width: '45%',
								}}
							>
								<label>{t('language')}</label>
								<Dropdown
									style={{ height: 'auto', marginBottom: '33px' }}
									appendTo='self'
									autoZIndex={false}
									value={competencyDefinition?.languageId}
									scrollHeight={'150px'}
									onChange={(e) => {
										setCompetencyDefinition({
											...competencyDefinition,
											languageId: e.value,
										});
									}}
									options={languages}
									optionLabel='languageId'
								/>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '55%',
								}}
							>
								<label>{t('definition')}</label>
								<InputTextarea
									style={{ height: '4.8em' }}
									onChange={(e) => {
										setCompetencyDefinition({
											...competencyDefinition,
											definition: e.target.value,
										});
									}}
								/>
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
								marginTop: '20px',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginRight: '20px',
									width: '45%',
								}}
							>
								<label>{t('statement_regarding_organization_value')}</label>
								<InputTextarea
									style={{ height: '4.8em' }}
									value={organitizationalImportance}
									onChange={(e) => {
										setCompetencyDefinition({
											...competencyDefinition,
											organitizationalImportance: e.target.value,
										});
									}}
								/>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '55%',
								}}
							>
								<label>{t('behavior_model_description_text')}</label>
								<InputTextarea
									style={{ height: '4.8em' }}
									onChange={(e) => {
										setCompetencyDefinition({
											...competencyDefinition,
											behavioralPatternDescriptionText: e.target.value,
										});
									}}
								/>
							</div>
						</div>
					</FormControl>
				</DialogContent>
				<DialogActions style={{ marginBottom: '1rem', marginRight: '1rem' }}>
					<ButtonMaterial
						onClick={() => {
							handleCloseAddCompetencyModal();
							setCompetencyDefinition({
								behavioralPatternDescriptionText: '',
								definition: '',
								id: '',
								languageId: '',
								organitizationalImportance: '',
								sarfCompetencyId: '',
							});
						}}
					>
						{' '}
						{t('Cancel')}
					</ButtonMaterial>
					<ButtonMaterial
						variant='contained'
						onClick={() => {
							saveCompetencyDefinition();
						}}
					>
						{' '}
						{t('save')}
					</ButtonMaterial>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default withTranslation()(AddNewCompetencyDefinition);
