import axios from 'axios';

class SarfModuleCompetencyService {
	getAllSarfCompetencyParameters() {
		return axios.get('/lookup/getAllSarfReportCompetencyParameters');
	}

	getCompetencyParametersModuleById(id) {
		return axios.get(
			'/lookup/getSarfReportCompetenciesByModuleParameterId/' + id
		);
	}

	getCompetenciesBySarfReportParametersTemplateId(id) {
		return axios.get(
			'/lookup/getSarfReportCompetencyParametersBySarfReportParametersTemplateId/' +
				id
		);
	}

	getById(id) {
		return axios.get('/lookup/getSarfReportCompetencyParameterById/' + id);
	}

	save(row) {
		return axios.post('/lookup/saveSarfReportCompetencyParameter', row);
	}

	update(row) {
		return axios.post('/lookup/updateSarfReportCompetencyParameter', row);
	}
	delete(id) {
		return axios.delete(
			'/lookup/deleteSarfReportCompetencyParameterById/' + id
		);
	}
}

export default new SarfModuleCompetencyService();
