import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PageTitle from './../components/PageTitle';
import moment from 'moment';
import 'moment/locale/tr';
import Datatable from './../components/common/Datatable';
import DialogComponent from '../components/common/DialogComponent';
import { DemographicsInfo } from './../components/DemographicsInfo';
import Spinner from '../components/common/Spinner';
import { withTranslation } from 'react-i18next';

const Scores = ({ t }) => {
  const [domainScores, setDomainScores] = useState([]);
  const [loading, setLoading] = useState(false);
  const DemographicsInfo = React.lazy(() =>
    import('./../components/DemographicsInfo')
  );
  const columns = [
    {
      field: 'user',
      title: `${t('user_id')}`,
      render: (rowData) => rowData.user.id,
      customSort: (a, b) => a.user.id - b.user.id,
    },
    {
      field: 'domain',
      title: `${t('domain')}`,
      render: (rowData) => rowData.domain.name,
    },
    { field: 'score', title: 'Skor' },
    {
      field: 'updatedAt',
      title: `${t('calculated_date')}`,
      defaultSort: 'desc',
      render: (rowData) => {
        return moment.unix(rowData.updatedAt).format('DD.MM.YYYY');
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    axios
      .get('/scores/getScores')
      .then((res) => {
        setDomainScores(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div>
      <Spinner loading={loading} />
      <PageTitle>Skorlar</PageTitle>
      <Datatable
        style={{ padding: 10 }}
        options={{
          showTitle: false,
          pageSize: 10,
          sorting: true,
          searchFieldVariant: 'outlined',
          searchFieldAlignment: 'right',
          pageSizeOptions: [10, 20, 50],
        }}
        actionText={t('action')}
        data={domainScores}
        actions={[
          {
            icon: 'view',
            tooltip: 'View User',
            onClick: (event, rowData) => {},
          },
        ]}
        components={{
          Action: (props) => {
            if (props.action.icon === 'view') {
              return (
                <DialogComponent
                  title={t('demographicsInfo')}
                  buttonText={t('detail')}
                  buttonColor='secondary'
                >
                  <DemographicsInfo userId={props.data.user.id} />
                </DialogComponent>
              );
            }
          },
        }}
        columns={columns}
      />
    </div>
  );
};

export default withTranslation()(Scores);
