import axios from 'axios';
class OverviewService {
  getAppointmentById(appointmentId) {
    return axios.get('/appointments/getAppointmentById/' + appointmentId);
    // if (AuthenticationService.isAdmin()) {
    //   return axios.get('/appointments/getAppointmentById/' + appointmentId);
    // } else if (AuthenticationService.isExpert()) {
    //   return axios.get(
    //     '/appointments/getAppointmentByIdForExpert/' + appointmentId
    //   );
    // }
  }
  getDomainModulScore(appointmentId) {
    return axios.get('/rawData/getDomainModulScore/' + appointmentId, {
      headers: {
        'Accept-Language': localStorage.getItem('i18nextLng'),
      },
    });
  }

  createAutoReport(co) {
    return axios.post('/dashboard/createAutoReport', co);
  }
}

export default new OverviewService();
