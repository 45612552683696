import axios from 'axios';

class StationService {
	getStationList() {
		return axios.get('/dashboard/getStations');
	}

	getStationListForOperator(operatorId) {
		return axios.get(`/dashboard/getOperatorsStations/${operatorId}`);
	}

	getStationListByLocationId(locationId) {
		return axios.get('/dashboard/getStation/' + locationId);
	}

	getStationByLocationAdminList(id) {
		return axios.get(
			'/dashboard/getStationsForLocationAdminByLocationAdminId/' + id
		);
	}
	getStationInfoById(stationId) {
		return axios.get('/dashboard/detailStation/' + stationId);
	}

	saveStation(station) {
		return axios.post('/dashboard/saveStation', station);
	}

	updateStation(station) {
		return axios.post('/dashboard/updateStation', station);
	}

	deleteStation(id) {
		return axios.delete(`/dashboard/deleteStationById/${id}`);
	}
}

export default new StationService();
