import React, { useState, useEffect } from 'react';
import 'moment/locale/tr';
import 'react-svg-radar-chart/build/css/index.css';
import './report.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
// import ReportBg from '../assets/svg/report-bg.svg'
import Overview from './expert_reviews/Overview-last';
import VerticalTabs from '../components/TabPanel';
import VerticalTabs2 from '../components/TapPanelCognitive';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { withTranslation } from 'react-i18next';

const Reports = ({ t }) => {
  let initialState = {
    user: { firstname: '', lastname: '', gender: '', age: 0 },
    score: 0,
    modules: [
      { name: '', description: '', score: 0, domains: [{ subdomains: [{}] }] },
    ],
  };

  const [data, setData] = useState(initialState);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedDomain, setSelectDomain] = useState(null);
  const [showOverview, setShowOverview] = useState(false);
  const [selectedOverviewModule, setSelectedOverviewModule] = useState(null);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    axios
      .get('/reports/data')
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const graphData = [
    300, 600, 590, 890, 760, 302, 423, 452, 567, 302, 823, 452, 723,
  ];

  const Summary = () => (
    <>
      <div className="report">
        <ul className="user-info">
          <li onClick={() => setOpen(!open)}>
            {data.user.firstname} {data.user.lastname}
            <KeyboardArrowDown
              sx={{
                mr: -1,
                opacity: 1,
                transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                transition: '0.2s',
                float: 'right',
                marginTop: '35px',
                marginRight: '50px',
                color: '#1199f7',
                cursor: 'pointer',
              }}
            />
          </li>
          {!open && (
            <>
              <li>{data.user.gender}</li>
              <li>{data.user.age}</li>
            </>
          )}
        </ul>
        <div className="neo-auvra-score">
          <h1>{t('neo_auvra_score')}</h1>
          <b>{data.score}</b>
        </div>
        <div className="explanation">
          <h3>{t('understand')}</h3>
          <p>{t('reports_text')}</p>
          <ul>
            {data.modules.map((module, key) => (
              <li key={key}>{module.name}</li>
            ))}
          </ul>
        </div>
        <div className="personal-results">
          <h3>{t('personal_results')}</h3>
          <time dateTime="2022-02-02">02 February 2022</time>
          <Link
            to="/#"
            onClick={(e) => {
              setShowOverview(true);
              setSelectedOverviewModule(data.modules[0]);
              e.preventDefault();
            }}
          >
            {t('OVERVIEW')}
          </Link>
        </div>
        {data.modules.map((module, key) =>
          module === selectedModule ? (
            <div key={key} className={'module' + (key + 1)}>
              <h3>
                <Link to="/#" onClick={() => setSelectedModule(module)}>
                  {module.name}
                </Link>{' '}
                <span>1.6%</span>
              </h3>

              <svg
                id="Group_49"
                data-name="Group 49"
                xmlns="http://www.w3.org/2000/svg"
                width="247.987"
                height="149.707"
                viewBox="0 0 247.987 149.707"
              >
                <defs>
                  <clipPath id="clip-path">
                    <rect
                      id="Rectangle_39"
                      data-name="Rectangle 39"
                      width="247.987"
                      height="149.707"
                      fill="none"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Group_48"
                  data-name="Group 48"
                  clip-path="url(#clip-path)"
                >
                  <line
                    id="Line_512"
                    data-name="Line 512"
                    y2="135.865"
                    transform="translate(123.993 12.342)"
                    fill="none"
                    stroke="#003167"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                  <line
                    id="Line_513"
                    data-name="Line 513"
                    y2="86.8"
                    transform="translate(205.654 61.407)"
                    fill="none"
                    stroke="#0e265d"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                  <line
                    id="Line_514"
                    data-name="Line 514"
                    y2="66.8"
                    transform="translate(171.899 81.407)"
                    fill="none"
                    stroke="#0b0e18"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                  <line
                    id="Line_515"
                    data-name="Line 515"
                    x2="244.986"
                    transform="translate(1.5 143.307)"
                    fill="none"
                    stroke="#1a1818"
                    stroke-miterlimit="10"
                    stroke-width="2"
                  />
                  <path
                    id="Path_8355"
                    data-name="Path 8355"
                    d="M246.487,123.993c-16.33,0-13.071-62-36.629-78.631-18.223-12.8-34.863,32.482-45.034,12.545C150.069,28.979,140.062,1.5,124,1.5S97.925,28.975,83.163,57.9c-10.174,19.936-22.61,40.55-40.836,53.341l.005.008A69.165,69.165,0,0,1,1.5,123.993"
                    fill="none"
                    stroke="#1a1818"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                </g>
              </svg>
            </div>
          ) : (
            <div key={key} className={'module' + (key + 1)}>
              <h3>
                <Link to="/#" onClick={() => setSelectedModule(module)}>
                  {module.name}
                </Link>{' '}
                <span>1.6%</span>
              </h3>
              <span className="score">{module.score}</span>
              <span className="max"> / 1000</span>
              <svg
                id="kareler"
                xmlns="http://www.w3.org/2000/svg"
                width="161.414"
                height="17.935"
                viewBox="0 0 161.414 17.935"
              >
                <svg
                  id="kareler"
                  xmlns="http://www.w3.org/2000/svg"
                  width="161.414"
                  height="17.935"
                  viewBox="0 0 161.414 17.935"
                >
                  {graphData.map((x, k) => (
                    <rect
                      key={k}
                      width="6.898"
                      height={(x / 1000) * 17.935}
                      transform={
                        'translate(' +
                        k * 11.037 +
                        ' ' +
                        (17.935 - (x / 1000) * 17.935) +
                        ')'
                      }
                      fill="#ccc"
                    />
                  ))}
                </svg>
              </svg>
            </div>
          )
        )}
        {selectedModule !== null ? (
          <div className={'detail ' + selectedModule.name.toLowerCase()}>
            <h3>{selectedModule.name.toUpperCase()}</h3>
            {selectedModule.description}
            <br />
            <div className="domains">
              <ul>
                {selectedModule.domains.map((domain, key) => (
                  <li
                    key={key}
                    className={domain === selectedDomain ? 'selected' : ''}
                    onClick={() => setSelectDomain(domain)}
                  >
                    {domain.name}
                  </li>
                ))}
              </ul>
              {selectedDomain !== null ? (
                <div className="domain-description">
                  {selectedDomain.description}
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        ) : null}
      </div>
      <div className="emotional">
        <div className="emotionalTabs">
          <h3>{t('EMOTIONAL')}</h3>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. </p>
          <VerticalTabs />
        </div>
      </div>
      <div className="emotional">
        <div className="emotionalTabs">
          <h3>{t('COGNİTİVE')}</h3>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. </p>
          <VerticalTabs2 />
        </div>
      </div>
    </>
  );

  return showOverview ? <Overview /> : <Summary />;
};
export default withTranslation()(Reports);
