import React, { useEffect, useState } from 'react';
import * as Survey from 'survey-react';
import axios from 'axios';
import 'survey-react/survey.css';
import './survey.css';
import surveyJson from './survey.json';
import surveyEn from './surveyEn.json';
import store from '../Store';
import { MainButton } from '../components/common/MainButton';
import ComponentWrapper from '../components/ComponentWrapper';
import { useSnapshot } from 'valtio';
import PrescreeningFormSummary from './PrescreeningFormSummary';
import {
  Container,
  Grid,
  IconButton,
  Typography,
  withStyles,
  Box,
  Button,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { SecondaryButton } from './../components/common/SecondaryButton';
import AuthenticationService from '../service/AuthenticationService';
import { Link } from 'react-router-dom';
import DialogComponent from '../components/common/DialogComponent';
import { DemographicsInfo } from '../components/DemographicsInfo';
import { withTranslation } from 'react-i18next';
import Spinner from '../components/common/Spinner';
import { isObjectEmpty } from '../utils/isObjectEmpty';
const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const PrescreeningForm = ({
  isAdminPanel,
  user,
  t,
  prescreening,
  demographicsExists,
}) => {
  const snapshot = useSnapshot(store);

  const lang = localStorage.getItem('i18nextLng');
  const [isLastPage, setIsLastPage] = useState(false);
  const [completedForDemographicsForm, setCompletedForDemographicsForm] =
    useState(false);
  const [completed, setCompleted] = useState(false);
  const [survey, setSurvey] = useState(
    new Survey.Model(lang === 'tr' ? surveyJson : surveyEn)
  );
  const [loading, setLoading] = useState(false);
  user = user ?? AuthenticationService.getLoggedInUser();

  const handleCancel = () => {
    store.surveyReadOnlyModalVisible = false;
  };

  const DemographicsInfo = React.lazy(() =>
    import('../components/DemographicsInfo')
  );

  const pageTitles = {
    0: `${t('life_style')}`,
    1: `${t('daily_func')}`,
    2: `${t('health_history')}`,
    3: `${t('individual_assessment')}`,
    4: `${t('physical_activity')}`,
    5: `${t('physical_activity') + '+'}`,
  };
  useEffect(() => {
    setLoading(true);
    //const user = AuthenticationService.getLoggedInUser();
    survey.locale = lang;
    survey.completedHtml = isAdminPanel
      ? `<h3>${t('THANKS_PREVIEW_FORM_ADMIN')}</h3>`
      : `<h3>${t('thanks_preview_form')}</h3>`;
    if (demographicsExists) {
      setCompletedForDemographicsForm(true);
    }
    if (!!prescreening.data) {
      setCompleted(true);
      store.pageTitle = `${t('preview_form')}`;
      survey.doComplete();
      survey.data = JSON.parse(prescreening.data);
      setSurvey(survey);
      store.steps.current = 6;
      // store.pageTitle = pageTitles[survey.currentPageNo];
    } else {
      store.pageTitle = pageTitles[survey.currentPageNo];
    }
    setLoading(false);

    // return () => {
    //   store.steps.current = 0;
    // };
  }, [prescreening, demographicsExists]);

  const onPageChanged = () => {
    store.pageTitle = pageTitles[survey.currentPageNo];
    store.steps.current = survey.currentPageNo + 1;
    const lastPage = survey.visiblePageCount - 1 === survey.currentPageNo;
    setIsLastPage(lastPage);

    if (survey.visiblePageCount === 5) {
      store.steps.parqPlusVisible = false;
    } else if (survey.visiblePageCount === 6) {
      store.steps.parqPlusVisible = true;
    }
  };

  const onValueChanged = () => {
    const lastPage = survey.visiblePageCount - 1 === survey.currentPageNo;
    setIsLastPage(lastPage);

    // if (survey.visiblePageCount === 5) {
    //   store.steps.parqPlusVisible = false;
    // } else if (survey.visiblePageCount === 6) {
    //   store.steps.parqPlusVisible = true;
    // }
  };

  const onComplete = (survey) => {
    if (completed || store.steps.current == 0) return;
    const cleanData = replaceNoneString(survey.data);
    if (!isObjectEmpty(cleanData)) {
      axios
        .post('/users/prescreening', cleanData)
        .then(() => {
          setCompleted(true);
          store.pageTitle = `${t('preview_form')}`;
        })
        .catch((err) => console.log(err));
    }
  };

  const replaceNoneString = (surveyData) => {
    if (surveyData.hhq1 && surveyData.hhq1.includes('none')) {
      surveyData.hhq1 = [];
    }
    if (surveyData.hhq2 && surveyData.hhq2.includes('none')) {
      surveyData.hhq2 = [];
    }
    if (surveyData.hhq3 && surveyData.hhq3.includes('none')) {
      surveyData.hhq3 = [];
    }
    if (surveyData.hhq4 && surveyData.hhq4.includes('none')) {
      surveyData.hhq4 = [];
    }
    return surveyData;
  };

  // const arr = (lang==='tr'? surveyJson.pages.map((item) => item.questions) : surveyEn.pages.map((item) => item.questions));
  // const selectQuestion = arr[1][0].rows;
  // let q = survey.getQuestionByName('dailyFunctioning');
  // const mapRows = selectQuestion.map((item) => item.value);
  // q.isAllRowRequired = mapRows;

  return (
    <>
      <Spinner isLoading={loading} />

      {!loading && (
        <>
          <ComponentWrapper
            compact
            subtitle={
              completed ? snapshot.pageTitle : pageTitles[survey.currentPageNo]
            }
          >
            <Grid container>
              <Survey.Survey
                model={survey}
                onComplete={(e) => onComplete(e)}
                onValueChanged={onValueChanged}
                onCurrentPageChanged={onPageChanged}
              />
            </Grid>
          </ComponentWrapper>
          {isAdminPanel ? (
            <>
              {completed ? (
                <Container style={{ marginTop: 24 }}>
                  <Grid container justifyContent='flex-end'>
                    <DialogComponent
                      style={{ marginRight: 15 }}
                      title={t('demographicsInfo')}
                      buttonText={t('demographicsInfo')}
                      buttonColor='secondary'
                    >
                      <DemographicsInfo
                        isAdminPanel={true}
                        userId={user.userId}
                      />
                    </DialogComponent>

                    <MainButton
                      style={{ marginLeft: 20 }}
                      color='primary'
                      onClick={() => (store.surveyReadOnlyModalVisible = true)}
                    >
                      {isAdminPanel ? t('SHOW_ANSWER_ADMIN') : t('show_answer')}
                    </MainButton>
                  </Grid>

                  <Dialog
                    open={snapshot.surveyReadOnlyModalVisible}
                    fullWidth={true}
                    maxWidth='lg'
                    onClose={handleCancel}
                    scroll='paper'
                    aria-labelledby='scroll-dialog-title'
                    aria-describedby='scroll-dialog-description'
                  >
                    <DialogTitle
                      id='scroll-dialog-title'
                      onClose={handleCancel}
                    >
                      {t('update_preview_form')}
                    </DialogTitle>

                    <DialogContent dividers={true}>
                      {survey.data && (
                        <PrescreeningFormSummary
                          isAdminPanel={isAdminPanel}
                          userId={user.userId}
                          surveyData={survey.data}
                        />
                      )}
                    </DialogContent>
                  </Dialog>
                </Container>
              ) : (
                <Container style={{ marginTop: 24 }}>
                  <Grid container justifyContent='flex-end'>
                    <Box marginRight={2}>
                      {survey.isFirstPage ? (
                        <DialogComponent
                          style={{ marginRight: 15 }}
                          title={t('demographicsInfo')}
                          buttonText={t('demographicsInfo')}
                          buttonColor='secondary'
                        >
                          <DemographicsInfo
                            isAdminPanel={true}
                            userId={user.userId}
                          />
                        </DialogComponent>
                      ) : (
                        <MainButton
                          onClick={() => {
                            store.steps.current = 0;
                            store.isEditMode = true;
                          }}
                          style={{ marginRight: 10 }}
                          color='secondary'
                        >
                          {t('infos_update')}
                        </MainButton>
                      )}
                    </Box>
                    {isLastPage && (
                      <SecondaryButton
                        color='primary'
                        variant='contained'
                        onClick={() => survey.completeLastPage()}
                      >
                        {t('COMPLETE')}
                      </SecondaryButton>
                    )}
                    {!isLastPage && (
                      <SecondaryButton
                        color='primary'
                        variant='contained'
                        onClick={() => survey.nextPage()}
                      >
                        {t('NEXT')}
                      </SecondaryButton>
                    )}
                  </Grid>
                </Container>
              )}
            </>
          ) : (
            <>
              {completed ? (
                <Container style={{ marginTop: 24 }}>
                  <Grid container justifyContent='flex-end'>
                    {/* <DialogComponent
                      style={{ marginRight: 15 }}
                      title={t('demographicsInfo')}
                      buttonText={t('demographicsInfo')}
                      buttonColor='secondary'
                    >
                      <DemographicsInfo
                        isAdminPanel={true}
                        userId={user.userId}
                      />
                    </DialogComponent> */}

                    <SecondaryButton
                      color='secondary'
                      variant='contained'
                      onClick={() => {
                        store.isEditMode = true;
                        store.steps.current = 0;
                      }}
                    >
                      {t('demographicsInfo')}
                    </SecondaryButton>

                    <MainButton
                      style={{ marginLeft: 20 }}
                      color='primary'
                      onClick={() => (store.surveyReadOnlyModalVisible = true)}
                    >
                      {isAdminPanel ? t('SHOW_ANSWER_ADMIN') : t('show_answer')}
                    </MainButton>
                  </Grid>

                  <Dialog
                    open={snapshot.surveyReadOnlyModalVisible}
                    fullWidth={true}
                    maxWidth='lg'
                    onClose={handleCancel}
                    scroll='paper'
                    aria-labelledby='scroll-dialog-title'
                    aria-describedby='scroll-dialog-description'
                  >
                    <DialogTitle
                      id='scroll-dialog-title'
                      onClose={handleCancel}
                    >
                      {t('update_preview_form')}
                    </DialogTitle>

                    <DialogContent dividers={true}>
                      {survey.data && (
                        <PrescreeningFormSummary
                          isAdminPanel={isAdminPanel}
                          userId={user.userId}
                          surveyData={survey.data}
                        />
                      )}
                    </DialogContent>
                  </Dialog>
                </Container>
              ) : (
                <Container style={{ marginTop: 24 }}>
                  <Grid container justifyContent='flex-end'>
                    {completedForDemographicsForm && survey.isFirstPage && (
                      <SecondaryButton
                        color='secondary'
                        variant='contained'
                        onClick={() => {
                          store.isEditMode = true;
                          store.steps.current = 0;
                        }}
                        className='mr-2'
                      >
                        {t('demographicsInfo')}
                      </SecondaryButton>
                    )}
                    {!survey.isFirstPage && (
                      <SecondaryButton
                        onClick={() => {
                          survey.prevPage();
                        }}
                      >
                        {t('BEFORE')}
                      </SecondaryButton>
                    )}

                    {!isLastPage && (
                      <SecondaryButton
                        color='primary'
                        variant='contained'
                        onClick={() => survey.nextPage()}
                        className='ml-2'
                      >
                        {t('NEXT')}
                      </SecondaryButton>
                    )}

                    {isLastPage && (
                      <SecondaryButton
                        color='primary'
                        variant='contained'
                        onClick={() => survey.completeLastPage()}
                      >
                        {t('COMPLETE')}
                      </SecondaryButton>
                    )}
                  </Grid>
                </Container>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default withTranslation()(PrescreeningForm);
