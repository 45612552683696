import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Toast } from 'primereact/toast';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import ButtonMaterial from '@mui/material/Button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import Spinner from '../common/Spinner';

import CustomerSarfReportUserService from '../../service/CustomerSarfReportUserService';
import { PickList } from 'primereact/picklist';

const ConfigureSarfReportUserModal = ({
	customerId,
	t,
	configureSarfReportUserOpen,
	handleCloseConfigureSarfReportUser,
	getAllSarfReports,
	selectedSarfReport,
	setLoading,
}) => {
	const toast = useRef(null);
	const [source, setSource] = useState([]);
	const [target, setTarget] = useState([]);
	const [checkTarget, setCheckTarget] = useState([]);

	useEffect(() => {
		let unmounted = false;

		if (
			(selectedSarfReport != null || selectedSarfReport != undefined) &&
			configureSarfReportUserOpen
		) {
			if (customerId != 0 || customerId != null || customerId != undefined) {
				setLoading(true);
				getAllAppointmentByCustomerId();
			}

			getAllAppointmentBySarfReportId(selectedSarfReport);
		} else {
			return;
		}
		return () => {
			unmounted = true;
		};
	}, [selectedSarfReport, customerId, configureSarfReportUserOpen]);
	const onChange = (event) => {
		setSource(event.source);
		setTarget(event.target);
	};

	const getAllAppointmentByCustomerId = () => {
		CustomerSarfReportUserService.getCompletedAppointmentsByCustomerId({
			headers: {
				customerId: customerId,
				sarfReportId: selectedSarfReport?.id,
			},
		})
			.then((res) => {
				let data = res.data;

				data.forEach((item) => {
					item.sarfReportId = selectedSarfReport?.id;
				});
				setSource(data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};

	const getAllAppointmentBySarfReportId = (selectedSarfReport) => {
		CustomerSarfReportUserService.getCompletedAppointmentByReportId(
			selectedSarfReport.id
		).then((res) => {
			setCheckTarget(res?.data);

			let data = res?.data;

			data.forEach((item) => {
				item.sarfReportId = selectedSarfReport?.id;
			});
			setTarget(data);
		}).catch((err) => {
			console.log(err);
		});
	};

	const onSubmitUserConfiguration = () => {
		const data = target;

		const dataSent = data.map((item) => {
			return {
				sarfReportId: item?.sarfReportId,
				appointmentId: item?.appointmentId,
			};
		});

		if (
			(checkTarget?.length < 1) &
			(checkTarget != null || checkTarget != undefined)
		) {
			setLoading(true);
			CustomerSarfReportUserService.save(dataSent)
				.then((res) => {
					setTimeout(() => {
						handleCloseConfigureSarfReportUser();
						setLoading(false);
						setTarget([]);
						setSource([]);
						toast.current.show({
							severity: 'success',
							summary: `${t('appointment_alert_messages.transaction_status')}`,
							detail: `${t(
								'appointment_alert_messages.transaction_successful'
							)}`,
							life: 3000,
						});
					}, 2000);
					getAllSarfReports(customerId);
				})
				.catch((error) => {
					setLoading(false);
					toast.current.show({
						severity: 'error',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.oparation_cancel')}`,
						life: 3000,
					});
				});
		} else {
			setLoading(true);
			CustomerSarfReportUserService.update(selectedSarfReport?.id, dataSent)
				.then((res) => {
					setTimeout(() => {
						handleCloseConfigureSarfReportUser();

						setLoading(false);
						setTarget([]);
						setSource([]);
						toast.current.show({
							severity: 'success',
							summary: `${t('appointment_alert_messages.transaction_status')}`,
							detail: `${t(
								'appointment_alert_messages.transaction_successful'
							)}`,
							life: 3000,
						});
					}, 2000);
					getAllSarfReports(customerId);
				})
				.catch((error) => {
					setLoading(false);
					toast.current.show({
						severity: 'error',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.oparation_cancel')}`,
						life: 3000,
					});
				});
		}
	};
	const itemTemplate = (item) => {
		return (
			<div className='flex flex-wrap p-2 align-items-center gap-3'>
				<div className='flex-1 flex flex-column gap-2'>
					<div className='flex align-items-center gap-2'>
						<span>{item.appointmentId}</span> -
						<span>
							{item.name} {item.surname}
						</span>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			<Toast ref={toast} />
			<Dialog
				open={configureSarfReportUserOpen}
				fullWidth={true}
				maxWidth={'md'}
				onClose={() => {
					handleCloseConfigureSarfReportUser();
					setTarget([]);
					setSource([]);
				}}
			>
				<DialogContent
					style={{
						overflowY: 'visible',
						display: 'flex',
						justifyContent: 'start',
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<DialogContentText
						style={{ marginBottom: '30px', textAlign: 'center' }}
					>
						{t('report_users')}
					</DialogContentText>

					<FormControl>
						<div className='card'>
							<PickList
								filter
								filterBy='name,surname'
								filterMatchMode='contains'
								sourceFilterPlaceholder='Search by name'
								targetFilterPlaceholder='Search by name'
								source={source}
								target={target}
								onChange={onChange}
								itemTemplate={itemTemplate}
								breakpoint='1400px'
								sourceHeader='All Users'
								onMoveToSource={false}
								targetHeader='Selected Users'
								sourceStyle={{ height: '30rem' }}
								targetStyle={{ height: '30rem' }}
							/>
						</div>

						<DialogActions style={{ paddingRight: '0', marginTop: '0.5rem' }}>
							<ButtonMaterial
								className='ml-3'
								onClick={() => {
									handleCloseConfigureSarfReportUser(false);
									setTarget([]);
									setSource([]);
								}}
							>
								{' '}
								{t('Cancel')}
							</ButtonMaterial>
							<ButtonMaterial
								variant='contained'
								className='ml-3'
								onClick={() => {
									onSubmitUserConfiguration();
								}}
							>
								{' '}
								{checkTarget?.length < 1 ? t('save') : t('update')}
							</ButtonMaterial>
						</DialogActions>
					</FormControl>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default withTranslation()(ConfigureSarfReportUserModal);
