import React, {
	useContext,
	useEffect,
	useLayoutEffect,
	useState,
	useRef,
	useCallback,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AuthenticationService from '../service/AuthenticationService';
import axios from 'axios';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Box, Button, Dialog, Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import FancyCard from './common/FancyCard';
import PersonalTraining from '../assets/svg/PersonalTraining.svg';
import ProcessingThoughts from '../assets/svg/ProcessingThoughts.svg';
import ProgressTracking from '../assets/svg/ProgressTracking.svg';
import SelectAppointmentDateTime from './SelectAppointmentDateTime';
import store from './../Store';
import { useSnapshot } from 'valtio';
import { MainButton } from './common/MainButton';
import LocationService from '../service/LocationService';
import { Dropdown } from 'primereact/dropdown';
import Autocomplete from '@mui/material/Autocomplete';
import { InputText } from 'primereact/inputtext';
import TextField from '@mui/material/TextField';
// import { debounce } from '@mui/material/utils';
import './CreateNewAppointment.css';
import { Controller, useForm } from 'react-hook-form';
import StationService from '../service/StationService';
import './appointment.css';
import { withTranslation } from 'react-i18next';
import { SurveyTriggerVisible } from 'survey-react';
import Spinner from './common/Spinner';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		height: '100%',
	},

	card: {
		backgroundColor: '#eeeeee',
	},
	cardAction: {
		justifyContent: 'center',
	},
}));

const CreateNewAppointment = ({
	userId,
	onCreate,
	t,
	appointmentId,
	firstOperatorName,
	fetchPendingAppointments,
	cancelAppointment,
	first,
	rows,
	sortField,
	sortOrder,
	filters,
	userEmail,
	fullName,
}) => {
	const appointmentTypes = [
		{
			type: 'A',
			label: 'A',
			description: `${t('create_appointment_types.desc_A')}`,
			image: ProgressTracking,
		},
		{
			type: 'B',
			label: 'B',
			description: `${t('create_appointment_types.desc_B')}`,
			image: ProcessingThoughts,
		},
		{
			type: 'C',
			label: 'C',
			description: `${t('create_appointment_types.desc_C')}`,
			image: PersonalTraining,
		},
		{
			type: 'X',
			label: 'GEN',
			description: `${t('create_appointment_types.desc_X')}`,
			image: PersonalTraining,
		},
		{
			type: 'Z',
			label: `${t('gen_managers')}`,
			description: `${t('create_appointment_types.desc_Z')}`,
			image: PersonalTraining,
		},
	];

	const ChooseTypeStep = ({ handleNext }) => {
		return (
			<Grid
				className='card-block'
				container
				spacing={2}
				justifyContent='center'
				alignItems='center'
			>
				{appointmentTypes.map((appointmentType) => (
					<Grid
						item={true}
						md={3}
						xs={12}
						key={appointmentType.type}
						onClick={() => handleNext(appointmentType.type)}
					>
						<FancyCard
							title={appointmentType.label}
							subtitle={appointmentType.description}
							image={appointmentType.image}
						></FancyCard>
					</Grid>
				))}
			</Grid>
		);
	};

	const initialSteps = [
		{
			title: `${t('initialSteps.type')}`,
		},
		{
			title: `${t('initialSteps.location')}`,
		},
	];

	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const snapshot = useSnapshot(store);
	const [steps, setSteps] = useState(initialSteps);
	const [type, setType] = useState('Z');
	const [currentUserId, setCurrentUserId] = useState(userId);
	const [activeStep, setActiveStep] = React.useState(1);
	const [options, setOptions] = useState([]);
	const [selectedAppointment, setSelectedAppointment] = useState([]);
	const [users, setUsers] = useState([]);
	const [user, setUser] = useState([]);
	const [selectedType, setSelectedType] = useState('');
	const [userIdx, setUserIdx] = useState(null);
	const [selectedDate, setSelectedDate] = useState();
	const [time, setTime] = useState();
	const [testPackages, setTestPackages] = useState([]);
	const [testPackage, setTestPackage] = useState({});
	const [testPackageTypes, setTestPackageTypes] = useState([]);
	const [testPackageType, setTestPackageType] = useState({});
	const [selectedTestPackage, setSelectedTestPackage] = useState({});
	const [stillOperator, setStillOperator] = useState(false);
	const [userFullName, setUserFullName] = useState();
	const [availableAppointments, setAvailableAppointments] = useState(new Map());
	const [open, setOpen] = useState(false);
	const { handleSubmit, watch, setValue, control, formState, reset } = useForm({
		mode: 'onSubmit',
	});
	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);
	const [locations, setLocations] = useState([]);
	const [stations, setStations] = useState([]);
	const [station, setStation] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedStation, setSelectedStation] = useState([]);
	const [operators, setOperators] = useState([]);
	const [operator, setOperator] = useState([]);
	const [selectedOperator, setSelectedOperator] = useState(null);
	const [autoCompleteSelectedUser, setAutoCompleteSelectedUser] =
		useState(null);
	const previousController = useRef();
	const [location, setLocation] = useState({
		countryId: null,
		cityId: null,
		locationId: null,
		stationId: null,
	});

	const userLoginId = JSON.parse(localStorage?.authenticatedUser);

	useEffect(() => {
		if (appointmentId) {
			setActiveStep(1);
			setUserFullName(fullName);
			getAppointmentDetailsByAppointmentId();
		}
	}, [appointmentId]);

	useEffect(() => {
		if (userEmail > 0) {
			getUserInformation();
		}
	}, [userEmail]);

	const getAppointmentDetailsByAppointmentId = async () => {
		setLoading(true);
		try {
			const res = await axios.get(
				`/appointments/getAppointmentById/${appointmentId}`
			);
			let data = res?.data;
			let fullName =
				data?.station?.location?.country?.name +
				'/' +
				data?.station?.location?.city?.name +
				'/' +
				data?.station?.location?.name +
				'/' +
				data?.station?.name;
			let time = {
				startTime: data?.startTime,
				endTime: data?.endTime,
			};
			data = { ...data, fullName, time };
			setSelectedAppointment(data);
			setType(data?.type);
			setUserIdx(data?.user?.id);
			getAppointmensByStationId(data?.station?.id);
			getOperatorList(data?.station?.location?.id);
			setStation(data?.station);
			setOperator(data?.operator);
			setSelectedDate(data?.date);
			setTime(data?.time);
			setTestPackageType(data?.assessmentPackages.assessmentPackageType);
			getAllTestPackagesByTypes(
				data?.assessmentPackages.assessmentPackageType.id
			);
			setTestPackage(data?.assessmentPackages);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	const getUserInformation = async () => {
		try {
			const payload = {
				email: userEmail,
				searchType: 'EMAIL',
			};

			const { data } = await axios.post('/users/getUser', payload);
			setTestPackageType(data?.assessmentPackages.assessmentPackageType);
			getAllTestPackagesByTypes(
				data?.assessmentPackages.assessmentPackageType.id
			);
			setTestPackage(data?.assessmentPackages);
		} catch (error) {
			console.log(error);
		}
	};
	const handleChangeTestPackageType = (e) => {
		setTestPackageType(e.target.value);
		getAllTestPackagesByTypes(e.target.value.id);
	};

	const handleSetTestPackage = (e) => {
		setTestPackage(e.target.value);
	};

	const getAllTestPackageTypes = async () => {
		try {
			const { data } = await axios.get('/users/getAllAssesmentPackageTypes');

			setTestPackageTypes(data);
		} catch (error) {
			console.log(error);
		}
	};

	const getAllTestPackagesByTypes = async (packageId) => {
		try {
			const { data } = await axios.get(
				'/users/getAssessmentPackagesByAssessmentTypeId/' + packageId
			);
			setTestPackages(data);
		} catch (error) {
			console.log(error);
		}
	};

	// useEffect(() => {
	//   if (users.length > 0) {
	//     let matchedUser = users.filter((item) => item.userIdx === currentUserId);
	//     setUser(matchedUser[0]);
	//   }
	// }, [currentUserId, users]);

	let matchedOperator = operators?.filter(
		(item) => item?.operatorId === operator?.id
	);
	// let matchedPackage = testPackages.filter(
	// 	(item) => item.id === testPackage?.id
	// );
	// useEffect(() => {
	// 	setSelectedTestPackage(matchedPackage[0]);
	// }, [matchedPackage]);

	useEffect(() => {
		setSelectedOperator(matchedOperator[0]);
	}, [matchedOperator]);

	useEffect(() => {
		if (!currentUserId) {
			let user = AuthenticationService.getLoggedInUser();
			setCurrentUserId(user.userId);
		}
		if (AuthenticationService.isLocationAdmin()) {
			getStationListForLocationAdmin();
		} else if (AuthenticationService.isOperator()) {
			getStationListForOperator();
		} else {
			getStationList();
		}
		// getOperatorList();
		// LocationService.getCountries().then((res) => {
		//   setCountries(res.data);
		// });
	}, [currentUserId]);

	useEffect(() => {
		if (type) getAppointmensByStationId(selectedAppointment?.station?.id);
	}, [type]);

	useEffect(() => {
		getAllTestPackageTypes();
	}, []);

	const getStationList = () => {
		StationService.getStationList()
			.then((resp) => {
				var data = resp?.data;
				if (data != null) {
					for (const i of data) {
						i.fullName =
							i?.location?.country?.name +
							'/' +
							i?.location?.city?.name +
							'/' +
							i?.location?.name +
							'/' +
							i?.name;
					}
				}
				setStations(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getStationListForOperator = () => {
		StationService.getStationListForOperator(userLoginId?.userId)
			.then((resp) => {
				var data = resp?.data;
				if (data != null) {
					for (const i of data) {
						i.fullName =
							i?.location?.country?.name +
							'/' +
							i?.location?.city?.name +
							'/' +
							i?.location?.name +
							'/' +
							i?.name;
					}
				}
				setStations(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getStationListForLocationAdmin = () => {
		StationService.getStationByLocationAdminList(userLoginId.userId)
			.then((resp) => {
				var data = resp?.data;
				if (data != null) {
					for (const i of data) {
						i.fullName =
							i?.location?.country?.name +
							'/' +
							i?.location?.city?.name +
							'/' +
							i?.location?.name +
							'/' +
							i?.name;
					}
				}
				setStations(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getOperatorList = (id) => {
		axios
			.get(`/dashboard/getOperatorByLocation/${id}`)
			.then((res) => {
				let data = res.data;
				if (data != null) {
					let allFirstName = [];
					for (const i of data) {
						i.fullName = i.name + ' ' + i.surname;
						allFirstName.push(i.fullName);
					}
					const containsSearchOperatorValue = allFirstName.some((item) => {
						return item === firstOperatorName;
					});

					if (containsSearchOperatorValue) {
						setStillOperator(true);
					} else {
						if (firstOperatorName !== undefined) {
							confirmDialog({
								message: `${t('chosen_operator')} ${firstOperatorName} ${t(
									'operator_unavailable'
								)}`,
								header: `${t('WARNING')}`,
								icon: 'pi pi-exclamation-triangle',
								accept,
								reject,
								acceptLabel: `${t('ok')}`,
								rejectLabel: ' ',
								style: { width: '600px' },
							});
						}
					}
				}
				setOperators(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (e) => {
		setOpen(false);
	};
	const handleNext = (appointmentId) => {
		if (type === 'C' && activeStep === steps.length - 1) {
			createNewAppointment(appointmentId);
			return;
		} else {
			if (activeStep === steps.length - 1) {
				createNewAppointment(appointmentId);
				return;
			}
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const onSlotSelected = (step, date, selectedSlot) => {
		let appointment = { selectedDate: date, slot: selectedSlot };
		if (step === 1) {
			store.firstSelectedAppointment = appointment;
		} else {
			store.secondSelectedAppointment = appointment;
		}
	};

	const createNewAppointment = async (appointmentId) => {
		const firstAppointment = snapshot?.firstSelectedAppointment;
		const secondAppointment = snapshot?.secondSelectedAppointment;
		if (station == null || station?.id == null) {
			enqueueSnackbar(`${t('create_appointment_types.select_hub')}`, {
				variant: 'error',
			});
			return;
		}
		let appObj = {
			selectedDate: firstAppointment?.selectedDate,
			start: firstAppointment?.slot.start,
			end: firstAppointment?.slot.end,
		};
		let dateTimeInfo = [appObj];
		const payload = {
			assessmentPackageDto: testPackage,
			dateTimeInfo: dateTimeInfo,
			operatorId: operator?.operatorId ?? selectedOperator?.operatorId,
			station: station,
			type: type,
			userId: user?.userIdx ?? userId,
		};

		if (!payload.operatorId) {
			enqueueSnackbar(
				`${t('enqueueSnackbar_message.please_select_operator')}`,
				{
					variant: 'error',
				}
			);
			return;
		}

		if (!payload.assessmentPackageDto?.id) {
			enqueueSnackbar(`${t('enqueueSnackbar_message.please_select_package')}`, {
				variant: 'error',
			});
			return;
		}

		if (appointmentId > 0 && payload?.operatorId == null) {
			payload['operatorId'] = selectedOperator?.user?.id;
		}
		if (!appointmentId && !payload?.operatorId) {
			enqueueSnackbar(
				`${t('enqueueSnackbar_message.please_select_operator')}`,
				{
					variant: 'error',
				}
			);
			return;
		}

		if (!firstAppointment?.selectedDate || !firstAppointment?.slot) {
			enqueueSnackbar(`${t('create_appointment_types.question_first')}`, {
				variant: 'error',
			});
			return;
		}

		if (appObj?.start > appObj?.end) {
			enqueueSnackbar(
				`${t('enqueueSnackbar_message.start_time_cannot_be_greater')}`,
				{
					variant: 'error',
				}
			);
			return;
		} else if (appObj.start === appObj?.end) {
			enqueueSnackbar(
				`${t('enqueueSnackbar_message.start_time_cannot_be_equal')}`,
				{
					variant: 'error',
				}
			);
			return;
		}

		if (type === 'C') {
			if (!secondAppointment?.selectedDate || !secondAppointment?.slot) {
				enqueueSnackbar(`${t('create_appointment_types.question_second')}`, {
					variant: 'error',
				});
				return;
			}

			let appObj = {
				selectedDate: secondAppointment?.selectedDate,
				start: secondAppointment?.slot?.start,
				end: secondAppointment?.slot?.end,
			};
			dateTimeInfo = [...dateTimeInfo, appObj];
		}

		setLoading(true);

		try {
			if (appointmentId > 0) {
				await deleteAppointment(appointmentId);
			}
			const response = await axios.post(
				'/appointments/newAppointment',
				payload
			);
			if (response.status === 200) {
				onCreate();
				enqueueSnackbar(`${t('create_appointment_types.success_message')}`, {
					variant: 'success',
				});

				await fetchPendingAppointments(
					first,
					rows,
					sortField,
					sortOrder,
					filters
				);
				setLoading(false);
			} else {
				setLoading(false);
				enqueueSnackbar(
					`${t('enqueueSnackbar_message.something_went_wrong')}`,
					{
						variant: 'error',
					}
				);
			}
		} catch (error) {
			if (
				error.response.status === 400 &&
				error.response.data === 'Date info cannot contain null variables.'
			) {
				enqueueSnackbar(
					`${t('enqueueSnackbar_message.date_cannnot_be_empty')}`,
					{
						variant: 'warning',
						autoHideDuration: 2500,
					}
				);
				setLoading(false);
			} else {
				enqueueSnackbar(
					`${t('enqueueSnackbar_message.something_went_wrong')}`,
					{
						variant: 'error',
					}
				);
				setLoading(false);
			}
		}
	};

	const accept = () => {};

	const reject = () => {};

	const deleteAppointment = async (id) => {
		try {
			const response = await axios.delete(`/appointments/cancel`, {
				params: { appointmentId },
			});
		} catch (error) {
			console.log(error);
		}
	};

	const fetchAvailableAppointmentsByStationId = (type, stationId) => {
		return axios.get('/appointments/available', {
			params: { type, stationId },
		});
	};

	const getAppointmensByStationId = (stationId) => {
		if (stationId) {
			fetchAvailableAppointmentsByStationId(type, stationId).then(
				({ data }) => {
					const map = new Map();
					data?.availableAppointments?.forEach((appoint) => {
						let slots = appoint?.slots;
						map.set(appoint?.date, slots);
					});
					setAvailableAppointments(map);
					// setActiveStep((prevActiveStep) => prevActiveStep + 1)
				}
			);
		}
	};
	const handleTypeSelection = (type) => {
		store.firstSelectedAppointment = null;
		store.secondSelectedAppointment = null;

		if (type === 'C') {
			steps[1].title = `${t('create_appointment_types.select_one')}`;
			setSteps([
				...steps,
				{
					title: `${t('create_appointment_types.select_two')}`,
				},
			]);
		}
		setType(type);

		// fetchAvailableAppointments(type).then(({data}) => {
		//     const map = new Map()
		//     data.availableAppointments.forEach((appoint) => {
		//         let slots = appoint.slots
		//         map.set(appoint.date, slots)
		//     })
		//     setAvailableAppointments(map)
		//     setActiveStep((prevActiveStep) => prevActiveStep + 1)
		// })
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const search = debounce((value) => {
		let filterTimeout;
		if (previousController?.current) {
			previousController?.current?.abort();
		}
		const controller = new AbortController();
		const signal = controller?.signal;
		previousController.current = controller;
		clearTimeout(filterTimeout);
		if (value.length >= 3) {
			axios
				.get(`/dashboard/searchUsersByIncludingNameOrSurname/${value}`)
				.then((res) => {
					let data = res?.data;
					if (data != null) {
						for (const i of data) {
							i.fullName = i?.name + ' ' + i?.surname + ' -' + i?.user?.id;
							i.userIdx = i?.user?.id;
						}
					}
					setOptions(data);
				})
				.catch((err) => console.log(err));
		}
		if (!value) return setOptions([]);
	}, 500);

	const onInputChange = (event, value, reason) => {
		if (value && event._reactName !== 'onClick') {
			search(value);
		} else if (value && event._reactName == 'onClick') {
			return;
		} else {
			setOptions([]);
		}
	};

	const handleCallback = (data) => {
		setAutoCompleteSelectedUser(data?.fullName);
		setUser(data);
		setUserIdx(data?.user?.id);
	};

	const renderStep = () => {
		switch (activeStep) {
			case 0:
				return <ChooseTypeStep handleNext={handleTypeSelection} />;
			case 1:
				return (
					availableAppointments && (
						<>
							<Spinner style={{ zIndex: 9999 }} loading={loading} />

							<div className='p-fluid grid newAppointment'>
								<div className='field col-12'>
									{appointmentId && !AuthenticationService.isOperator() && (
										<div className='d-flex justify-content-evenly align-items-center'>
											<div className='col-9' style={{ padding: '0 0 0 0' }}>
												<label htmlFor='dropdown'>{t('users')}</label>
												<div style={{ display: 'flex' }}>
													<InputText
														style={{ height: '2.2rem', marginTop: '0.5rem' }}
														value={userFullName}
														readOnly
													/>
													<Button
														style={{
															marginLeft: '20px',
															marginRight: '155px',
															width: '220px',
															height: '36px',
															marginTop: '0.5rem',
															position: 'relative',
															left: 4,
														}}
														color='primary'
														variant='contained'
														onClick={handleClickOpen}
													>
														{t('user_choice')}
													</Button>
													<Dialog open={open} onClose={handleClose}>
														<DialogTitle style={{ textAlign: 'center' }}>
															{t('user_choice')}
														</DialogTitle>
														<DialogContent style={{ height: '180px' }}>
															<Autocomplete
																className='mt-2'
																id='combo-box-demo'
																filterOptions={(x) => x}
																options={options}
																onInputChange={onInputChange}
																getOptionLabel={(option) => option.fullName}
																onChange={(event, newValue) => {
																	handleCallback(newValue);
																}}
																style={{
																	width: 300,
																	height: '1rem',
																	marginTop: '0.5rem',
																}}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		label={t('user_choice')}
																		variant='outlined'
																		className='selam'
																	/>
																)}
															/>
														</DialogContent>
														<DialogActions>
															<div
																style={{
																	marginRight: '70px',
																	marginBottom: '10px',
																}}
															>
																<Button
																	style={{ width: '5rem' }}
																	onClick={() => {
																		setUserFullName(autoCompleteSelectedUser);
																		handleClose();
																	}}
																	color='primary'
																	variant='contained'
																>
																	{t('ok')}
																</Button>
																<Button
																	style={{ marginLeft: '35px', width: '5rem' }}
																	onClick={handleClose}
																	color='primary'
																	variant='contained'
																>
																	{t('close')}
																</Button>
															</div>
														</DialogActions>
													</Dialog>
												</div>
											</div>
											<div className='col-3' style={{ padding: '0 0 0 20px' }}>
												<label htmlFor='dropdown'>
													{' '}
													{t('appointment_type')}
												</label>
												<Dropdown
													value={type}
													className='p-inputtext-sm'
													filter={true}
													appendTo='self'
													onChange={(e) => {
														setType(e.value.type);
													}}
													options={appointmentTypes}
													optionLabel='type'
													placeholder={type}
												/>
											</div>
										</div>
									)}
									<div
										className='d-flex justify-content-evenly align-items-center'
										style={{ marginBottom: 5 }}
									>
										<div className='col-5' style={{ padding: '0 20px 0 0' }}>
											<label htmlFor='dropdown'>{t('hub')}</label>
											<Dropdown
												value={station}
												className='p-inputtext-sm'
												filter={true}
												disabled={stations.length === 0}
												appendTo='self'
												onChange={(e) => {
													setStation(e.value);
													getAppointmensByStationId(e.value.id);
													getOperatorList(e.value.location.id);
												}}
												options={stations}
												optionLabel='fullName'
												placeholder={selectedAppointment?.fullName}
											/>
										</div>
										<div
											className='col-3 p-0'
											style={{ padding: '0 !important' }}
										>
											<label htmlFor='dropdown'>{t('operators')}</label>
											<Dropdown
												value={operator}
												className='p-inputtext-sm'
												filter={true}
												// disabled={operators.length === 0}
												appendTo='self'
												onChange={(e) => {
													setOperator(e.value);
												}}
												options={operators}
												optionLabel='fullName'
												placeholder={selectedOperator?.fullName}
												disabled={!station}
											/>
										</div>

										<div className='col-2' style={{ padding: '0 0 0 20px' }}>
											<Controller
												control={control}
												id='autocomplete-off'
												name='name'
												autocomplete='off'
												render={(props) => (
													<div>
														<label>{t('test_package_type')}</label>
														<Dropdown
															options={testPackageTypes}
															appendTo='self'
															className='p-inputtext-sm'
															value={testPackageType}
															onChange={handleChangeTestPackageType}
															optionLabel='name'
															optionValue=''
															placeholder=''
														/>
													</div>
												)}
											/>
										</div>

										<div className='col-2' style={{ padding: '0 0 0 20px' }}>
											<label>{t('test_package')}</label>
											<Dropdown
												value={testPackage}
												className='p-inputtext-sm'
												appendTo='self'
												onChange={handleSetTestPackage}
												options={testPackages}
												optionLabel='packageName'
												placeholder=''
											/>
										</div>
									</div>
									<span>
										{t('actual_date_time_appointment')}
										<strong>
											{selectedDate} / {time?.startTime} : {time?.endTime}
										</strong>
									</span>
								</div>
							</div>
							<SelectAppointmentDateTime
								key={1}
								title={steps[activeStep].title}
								appointments={availableAppointments}
								onSlotSelected={onSlotSelected}
								type={type}
								activeStep={activeStep}
								selectDate={selectedDate}
								selectedTime={time}
							/>
						</>
					)
				);
			case 2:
				return (
					availableAppointments && (
						<SelectAppointmentDateTime
							key={2}
							title={steps[activeStep].title}
							appointments={availableAppointments}
							onSlotSelected={onSlotSelected}
							type={type}
							activeStep={activeStep}
							selectDate={selectedDate}
							selectedTime={time}
						/>
					)
				);

			default:
		}
	};
	return (
		<div className={classes.root}>
			<Stepper activeStep={activeStep}>
				{steps.map((step, idx) => (
					<Step key={idx}>
						<StepLabel>{step.title}</StepLabel>
					</Step>
				))}
			</Stepper>

			{renderStep()}
			<Box marginTop={1} display='flex' justifyContent='flex-end'>
				{activeStep !== 0 && (
					<>
						{type === 'C' && (
							<MainButton
								disabled={activeStep === 1}
								color='secondary'
								onClick={handleBack}
								className={classes.backButton}
							>
								{t('prev')}
							</MainButton>
						)}
						{appointmentId ? (
							<MainButton
								style={{ marginLeft: 20 }}
								color='primary'
								onClick={() => handleNext(appointmentId)}
							>
								{t('update')}
							</MainButton>
						) : (
							<MainButton
								style={{ marginLeft: 20 }}
								color='primary'
								onClick={handleNext}
							>
								{activeStep === steps.length - 1
									? `${t('complete')}`
									: `${t('next')}`}
							</MainButton>
						)}
					</>
				)}
			</Box>
		</div>
	);
};
export default withTranslation()(CreateNewAppointment);
