import React, { useCallback, useEffect, useRef, useState } from 'react';
import Spinner from '../common/Spinner';

import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import ButtonMaterial from '@mui/material/Button';
import CustomerSarfReportParametersTemplateService from '../../service/CustomerSarfReportParametersTemplateService';

const AddSarfParameterTemplateModal = ({
	customerId,
	getAllSarfReports,
	t,
	addSarfParameterTemplateOpen,
	handleCloseAddSarfParameterTemplateModal,
	getAllSarfReportParametersTemplates,
	langValue,
	setLoading,
}) => {
	const toast = useRef(null);
	const toastInside = useRef(null);
	const [description, setDescription] = useState('');
	const [paramaterTemplateName, setParamaterTemplateName] = useState('');

	const [sarfTemplateType, setSarfTemplateType] = useState();
	const [selectedSarfTemplateType, setSelectedSarfTemplateType] = useState();

	useEffect(() => {
		let unmounted = false;
		setSarfTemplateType([
			{ id: 1, name: `${t('neo_standard_general')}` },
			{ id: 2, name: `${t('neo_standard_other')}` },
			{ id: 3, name: `${t('neo_special_for_customer')}` },
		]);

		return () => {
			unmounted = true;
		};
	}, [langValue]);

	const saveSarfParameterTemplate = () => {
		if (
			paramaterTemplateName == '' ||
			paramaterTemplateName == null ||
			paramaterTemplateName == undefined
		) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_sarf_parameter_template_name')}`,
				life: 3000,
			});
			return;
		}
		if (paramaterTemplateName.trim() == '') {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_sarf_parameter_template_name')}`,
				life: 3000,
			});
			return;
		}

		if (paramaterTemplateName.trim().length > 255) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_category_length')}`,
				life: 3000,
			});
			return;
		}
		if (customerId == null || customerId == undefined) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('appointment_alert_messages.transaction_error')}`,
				life: 3000,
			});

			return;
		}

		if (
			selectedSarfTemplateType == null ||
			selectedSarfTemplateType == undefined
		) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_sarf_parameter_template_type')}`,
				life: 3000,
			});

			return;
		}
		const trimmedDescription = description ? description.trim() : '';

		const dataSent = {
			customer: { id: customerId },
			description: trimmedDescription,
			name: paramaterTemplateName.trim(),
			templateType: selectedSarfTemplateType?.id,
		};
		setLoading(true);
		CustomerSarfReportParametersTemplateService.save(dataSent)
			.then((res) => {
				setTimeout(() => {
					handleCloseAddSarfParameterTemplateModal();

					setLoading(false);
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
						life: 3000,
					});
					setDescription(null);
					setSelectedSarfTemplateType(null);

					setParamaterTemplateName(null);
				}, 2000);
				getAllSarfReportParametersTemplates(customerId);
				getAllSarfReports(customerId);
			})
			.catch((error) => {
				setLoading(false);
				toastInside.current.show({
					severity: 'error',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: error.response.data,
					life: 3000,
				});
			});
	};

	return (
		<>
			<Toast ref={toast} />

			<Dialog
				open={addSarfParameterTemplateOpen}
				fullWidth={true}
				maxWidth={'sm'}
				onClose={() => {
					handleCloseAddSarfParameterTemplateModal();
					setDescription(null);
					setParamaterTemplateName(null);
					setSelectedSarfTemplateType(null);
				}}
			>
				<Toast ref={toastInside} />

				<DialogContent
					style={{
						overflowY: 'visible',
						display: 'flex',
						justifyContent: 'start',
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<DialogContentText
						style={{
							marginBottom: '30px',
							textAlign: 'center',
							fontWeight: 'bold',
						}}
					>
						{t('sarf_report_parameter_template_definition')}
					</DialogContentText>

					<FormControl>
						<div
							style={{
								width: '100%',
							}}
						>
							<label htmlFor='inputtext'>
								{t('sarf_parameter_template_name')}
							</label>
							<InputText
								id='inputtext'
								style={{
									width: '100%',
									marginTop: '10px',
									marginBottom: '10px',
								}}
								value={paramaterTemplateName}
								onChange={(e) => setParamaterTemplateName(e.target.value)}
							/>
						</div>
						<div style={{ width: '100%' }}>
							<label htmlFor=''>{t('sarf_report_template_type')}</label>
							<Dropdown
								value={selectedSarfTemplateType}
								style={{
									width: '100%',
									marginTop: '10px',
									marginBottom: '10px',
								}}
								appendTo='self'
								className='p-inputtext-sm'
								onChange={(e) => {
									setSelectedSarfTemplateType(e.value);
								}}
								options={sarfTemplateType}
								optionLabel='name'
							/>
						</div>

						<div style={{ width: '100%' }}>
							<label htmlFor='inputtext'>{t('description')}</label>
							<InputText
								id='inputtext'
								style={{
									width: '100%',
									marginTop: '10px',
									marginBottom: '10px',
								}}
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
						</div>
						<DialogActions style={{ paddingRight: '0' }}>
							<ButtonMaterial
								onClick={() => {
									handleCloseAddSarfParameterTemplateModal();
									setDescription(null);
									setParamaterTemplateName(null);
									setSelectedSarfTemplateType(null);
								}}
							>
								{' '}
								{t('Cancel')}
							</ButtonMaterial>
							<ButtonMaterial
								variant='contained'
								onClick={() => {
									saveSarfParameterTemplate();
								}}
							>
								{' '}
								{t('save')}
							</ButtonMaterial>
						</DialogActions>
					</FormControl>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default withTranslation()(AddSarfParameterTemplateModal);
