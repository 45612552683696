/* eslint-disable no-undef */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import UserService from '../service/UserService';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import parsePhoneNumber from 'libphonenumber-js';
import { DropzoneDialog } from 'material-ui-dropzone';
import { Checkbox } from 'primereact/checkbox';
import Spinner from '../components/common/Spinner';

import { classNames } from 'primereact/utils';
import {
	Button,
	CssBaseline,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Box,
	Grid,
	Tooltip,
} from '@material-ui/core';
import axios from 'axios';
import * as Yup from 'yup';
import 'yup-phone';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { MainButton } from '../components/common/MainButton';
import FormInput from '../components/common/FormInput';
import PhoneInput from '../components/common/PhoneInput';
import SelectInput from './common/SelectInput';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import './userInfo.css';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { IconFlagTR, IconFlagDE, IconFlagUS } from 'material-ui-flags';
import IconButton from '@material-ui/core/IconButton';
import CustomerService from '../service/CustomerService';
import FormInputGrid from './common/FormInputGrid';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import PhoneInputEn from './common/PhoneInputEn';
import StationService from '../service/StationService';
import AuthenticationService from '../service/AuthenticationService';
const useStyles = makeStyles((theme) => ({
	card: {
		padding: 20,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	commChannels: {
		marginTop: theme.spacing(2),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	register: {
		'&:hover': {
			textDecoration: 'underline',
		},
		textTransform: 'none',
	},
}));

export const PreRegisteredUserEdit = ({
	userInfo,
	onClose,
	fetchPreRegisteredUsers,
}) => {
	const { t } = useTranslation();
	const Schema = Yup.object().shape({
		name: Yup.string().required(`${t('schema_shape.not_empty')}`),
		surname: Yup.string().required(`${t('schema_shape.not_empty')}`),
		email: Yup.string()
			.email('Lütfen geçerli bir email adresi girin')
			.required(`${t('schema_shape.not_empty')}`),
		// commChannels: Yup.array()
		//   .transform((o, obj) => Object.keys(obj).filter((k) => obj[k]))
		//   .min(1, 'Lütfen birini seçiniz'),
		mobilePhone: Yup.string().required(`${t('schema_shape.not_empty')}`),
		// userRole: Yup.string().required(`${t('schema_shape.not_empty')}`),
		stationaryPhone: Yup.string().notRequired().nullable(),
		emailRepeat: Yup.string()
			.email(`${t('schema_shape.valid_email')}`)
			.required(`${t('schema_shape.not_empty')}`)
			.oneOf([Yup.ref('email')], `${t('email_doesnt_match')}`),
		webUILoginNotAllowed: Yup.boolean().notRequired().nullable(),
	});
	const toast = useRef(null);
	// let {path} = useRouteMatch();
	const { enqueueSnackbar } = useSnackbar();
	const selected = localStorage.getItem('i18nextLng') || 'en';
	let history = useHistory();
	const classes = useStyles();
	const {
		control,
		handleSubmit,
		reset,
		watch,
		clearErrors,
		setError,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(Schema),
	});
	const [visible, setVisible] = useState(false);
	const [commChannels, setCommChannels] = useState([]);
	const [open, setOpen] = useState(false);
	const [userRoles, setUserRoles] = useState([]);
	const [file, setFile] = useState(null);
	const [role, setRole] = useState([]);
	const [preRegisterUser, setPreRegisterUser] = useState({});
	const [isDoctor, setIsDoctor] = useState(false);
	const [user, setUser] = useState({});
	const [testPackages, setTestPackages] = useState([]);
	const [testPackage, setTestPackage] = useState({});
	const [testPackageTypes, setTestPackageTypes] = useState([]);
	const [testPackageType, setTestPackageType] = useState({});
	const [selectedTestPackage, setSelectedTestPackage] = useState({});
	const [selectedTestPackageType, setSelectedTestPackageType] = useState({});
	const [lang, setLang] = useState({});
	const [station, setStation] = useState();
	const [stations, setStations] = useState([]);
	const [loading, setLoading] = useState(false);
	const [webUILoginNotAllowed, setWebUILoginNotAllowed] = useState(false);
	const updateDoctor = () => setIsDoctor(!isDoctor);

	const [customers, setCustomers] = useState([]);
	const [customer, setCustomer] = useState({});

	const userLoginId = JSON.parse(localStorage.authenticatedUser);

	const onSubmit = async (updateForm) => {
		setLoading(true);
		let phoneNumber = updateForm['mobilePhone'].replace(/null\s/g, '');
		let formatsPhoneNumber = parsePhoneNumber(phoneNumber);
		if (formatsPhoneNumber) {
			updateForm['mobilePrefix'] = formatsPhoneNumber.countryCallingCode;
			updateForm['mobilePhone'] = formatsPhoneNumber.nationalNumber;
		} else {
			updateForm['mobilePrefix'] = phoneNumber;
			updateForm['mobilePhone'] = '';
		}
		updateForm['assessmentPackages'] = testPackage;
		updateForm['preferredLanguage'] = lang;
		updateForm['customer'] = { id: customer };
		updateForm['location'] = station.location ? station.location : station;
		delete updateForm['customerName'];
		if (!updateForm.assessmentPackages?.id) {
			enqueueSnackbar(`${t('enqueueSnackbar_message.please_select_package')}`, {
				variant: 'error',
			});
			return;
		}
		const updateFormData = JSON.stringify(updateForm);
		axios
			.post('users/updatePreRegistration', updateFormData, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then(() => {
				enqueueSnackbar(`${t('enqueueSnackbar_message.user_update_success')}`, {
					variant: 'success',
					autoHideDuration: 2500,
				});
				onClose();
				fetchPreRegisteredUsers();
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log('err :>> ', err);
				if (err) {
					enqueueSnackbar(`${t('enqueueSnackbar_message.user_update_error')}`, {
						variant: 'error',
					});
				}
			});
	};

	useEffect(() => {
		fetchAssessmentPackagesAndCustomerList();
		getAllTestPackageTypes();
		if (AuthenticationService.isLocationAdmin()) {
			getStationByLocationAdminList();
		} else {
			getStationList();
		}
	}, []);
	const fetchAssessmentPackagesAndCustomerList = async () => {
		setLoading(true);
		try {
			const customerList = await CustomerService.getCustomerList();
			setCustomers(customerList.data);
			setLoading(false);
		} catch (error) {
			console.log('error :>> ', error);
			setLoading(false);
		}
	};

	const getStationList = () => {
		StationService.getStationList().then((resp) => {
			var data = resp.data;
			if (data != null) {
				for (const i of data) {
					i.fullName =
						i.location?.country?.name +
						'/' +
						i.location?.city?.name +
						'/' +
						i.location?.name;
				}
			}
			setStations(data);
		});
	};

	const getStationByLocationAdminList = () => {
		StationService.getStationByLocationAdminList(userLoginId.userId).then(
			(resp) => {
				var data = resp?.data;
				if (data != null) {
					for (const i of data) {
						i.fullName =
							i?.location?.country?.name +
							'/' +
							i?.location?.city?.name +
							'/' +
							i?.location?.name +
							'/' +
							i?.name;
					}
				}
				setStations(data);
			}
		);
	};

	React.useEffect(() => {
		const subscription = watch((value) => {
			if (value.email === value.emailRepeat) {
				clearErrors('emailRepeat');
			} else {
				setError('emailRepeat', {
					type: 'oneOf',
					message: `${t('email_doesnt_match')}`,
				});
			}
		});
		return () => subscription.unsubscribe();
	}, [clearErrors, setError, watch, errors]);

	function handleSelect(checkedName) {
		const newNames = commChannels?.includes(checkedName)
			? commChannels?.filter((name) => name !== checkedName)
			: [...(commChannels ?? []), checkedName];
		setCommChannels(newNames);
		return newNames;
	}

	const getUser = (userId) => {
		setLoading(true);
		const userDetailsRequest = {
			userId,
			searchType: 'USER_ID',
		};

		axios
			.post('/users/getUser', userDetailsRequest)
			.then(({ data }) => {
				setUser({
					userId: data?.userId,
					name: data?.name,
					surname: data?.surname,
					email: data?.email,
					mobilePhone: data?.mobilePrefix + data?.mobilePhone,
					stationaryPhone: data?.stationaryPhone,
					customer: data?.customer,
					commChannels:
						typeof data.commChannels === 'string'
							? data?.commChannels?.split(',')?.map((str) => str)
							: data?.commChannels,
					emailRepeat: data?.email,
					webUILoginNotAllowed: data?.webUILoginNotAllowed,
				});
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log('err :>> ', err);
			});
	};

	useEffect(() => {
		setPreRegisterUser({ ...userInfo, emailRepeat: userInfo?.email });
		setTestPackageType(userInfo?.assessmentPackages?.assessmentPackageType);
		getAllTestPackagesByTypes(
			userInfo?.assessmentPackages?.assessmentPackageType?.id
		);
		setSelectedTestPackage(userInfo.assessmentPackages);
		setCustomer(userInfo.customer.id);
		setLang(userInfo.preferredLanguage);
		let data = { ...userInfo };
		data.location.fullName =
			data?.location?.country?.name +
			'/' +
			data?.location?.city?.name +
			'/' +
			data?.location?.name;
		setStation(data?.location);

		var object = [
			{ id: 'ADMIN', name: `${t('user_role.ADMIN')}` },
			{ id: 'OPERATOR', name: `${t('user_role.OPERATOR')}` },
			{
				id: 'USER',
				name: `${t('user_role.USER')}`,
			},
			{ id: 'DOCTOR', name: `${t('user_role.DOCTOR')}` },
		];
		setUserRoles(object);
	}, []);

	useEffect(() => {
		if (user.commChannels && user.commChannels.length > 0) {
			setCommChannels(user.commChannels);
		}
		reset(preRegisterUser);
	}, [user, preRegisterUser]);

	const handleOnClick = useCallback(
		() => history.push('/panel/kullanicilar'),
		[history]
	);

	const languageItems = [
		{ label: 'English', value: 'en', code: 'US' },
		{ label: 'Türkçe', value: 'tr', code: 'TR' },
	];
	const onLangChange = (e) => {
		setLang(e.value);
	};

	const selectedCountryTemplate = (option, props) => {
		if (option) {
			return (
				<div className='country-item country-item-value'>
					{option.value === 'en' ? (
						<IconButton className={classes.muiIcon}>
							<IconFlagUS />
						</IconButton>
					) : (
						<IconButton className={classes.muiIcon}>
							<IconFlagTR />
						</IconButton>
					)}
					<div>{option.label}</div>
				</div>
			);
		}
	};

	const countryOptionTemplate = (option) => {
		return (
			<div className='country-item'>
				{option.value === 'en' ? (
					<IconButton className={classes.muiIcon}>
						<IconFlagUS />
					</IconButton>
				) : (
					<IconButton className={classes.muiIcon}>
						<IconFlagTR />
					</IconButton>
				)}
				<div>{option.label}</div>
			</div>
		);
	};
	const confirm = async (registerForm) => {
		const isValidEmails = await validateEmail(registerForm.email);
		if (isValidEmails) {
			onSubmit(registerForm);
		} else {
			confirmDialog({
				message: `${t('enqueueSnackbar_message.english_charachter_valid')}`,
				header: `${t('appointment_alert_messages.confirmation')}`,
				icon: 'pi pi-exclamation-triangle',
				acceptLabel: `${t('yes')}`,
				rejectLabel: `${t('no')}`,
				accept: () => onSubmit(registerForm),
				reject: () => reject(),
			});
		}
	};

	const reject = () => {
		setVisible(false);
	};

	const handleChangeTestPackageType = (e) => {
		setTestPackageType(e.target.value);
		setSelectedTestPackage(null);
		getAllTestPackagesByTypes(e.target.value.id);
	};

	const handleSetTestPackage = (e) => {
		setTestPackage(e.target.value);
		setSelectedTestPackage(e.target.value);
	};

	const getAllTestPackageTypes = async () => {
		try {
			const { data } = await axios.get('/users/getAllAssesmentPackageTypes');

			setTestPackageTypes(data);
		} catch (error) {
			console.log(error);
		}
	};
	const getAllTestPackagesByTypes = async (packageId) => {
		try {
			const { data } = await axios.get(
				'/users/getAssessmentPackagesByAssessmentTypeId/' + packageId
			);
			setTestPackages(data);
		} catch (error) {
			console.log(error);
		}
	};
	const validateEmail = async (email) => {
		const re =
			/^([a-zA-Z0-9]+[._]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[._]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
		return re.test(String(email).toLowerCase());
	};

	const handleChangeEmail = (value) => {
		return value.toLowerCase();
	};

	return (
		<>
			{/* <ConfirmDialog
        style={{
          zIndex: '99999 !important',
          display: visible ? 'block' : 'none',
        }}
      /> */}
			<Toast ref={toast} />
			<Spinner loading={loading} />
			<div className='card card-custom gutter-b'>
				<div className='card-body'>
					<div className={classes.paper}>
						<Grid item={true} xs={12} sm={12} md={12} lg={12}>
							<form
								className={classes.form}
								noValidate
								onSubmit={handleSubmit(confirm)}
								encType='multipart/form-data'
							>
								<div style={{ display: 'flex' }}>
									<div style={{ width: '48%' }}>
										<div>
											<label style={{ position: 'relative', top: '0.5em' }}>
												{t('name')}
											</label>
											<Controller
												name='name'
												control={control}
												render={(props) => (
													<FormInput
														{...props}
														margin='dense'
														required
														defaultValue={
															preRegisterUser ? preRegisterUser.name : ' '
														}
													/>
												)}
											/>
										</div>
										<div>
											<label style={{ position: 'relative', top: '0.5em' }}>
												{t('email')}
											</label>
											<Controller
												name='email'
												control={control}
												label={t('email_repeat')}
												render={(props) => (
													<>
														<FormInputGrid
															placeholder={t('email') + '*:'}
															{...props}
															margin='dense'
															required
															handleChangeEmail={handleChangeEmail}
															defaultValue={
																preRegisterUser ? preRegisterUser.email : ' '
															}
														/>
													</>
												)}
											/>
										</div>
										<Controller
											control={control}
											id='autocomplete-off'
											name='customer'
											autocomplete='off'
											render={(props) => (
												<div style={{ marginTop: '10px' }}>
													<label>{t('customer')}</label>
													<Dropdown
														style={{ width: '100%' }}
														options={customers}
														appendTo='self'
														value={customer}
														onChange={(e) => {
															setCustomer(e.value);
														}}
														optionLabel='name'
														optionValue='id'
														placeholder=''
													/>
												</div>
											)}
										/>
										<Controller
											control={control}
											id='autocomplete-off'
											name='name'
											autocomplete='off'
											render={(props) => (
												<div style={{ marginTop: '10px' }}>
													<label>{t('test_package_type')}</label>
													<Dropdown
														style={{ width: '100%' }}
														options={testPackageTypes}
														appendTo='self'
														value={testPackageType}
														onChange={handleChangeTestPackageType}
														optionLabel='name'
														optionValue=''
														placeholder=''
													/>
												</div>
											)}
										/>
									</div>
									<div style={{ marginLeft: '30px', width: '50%' }}>
										<div>
											<label style={{ position: 'relative', top: '0.5em' }}>
												{t('surname')}
											</label>
											<Controller
												name='surname'
												control={control}
												render={(props) => (
													<FormInput {...props} margin='dense' required />
												)}
											/>
										</div>

										<div>
											<label style={{ position: 'relative', top: '0.5em' }}>
												{t('email_repeat')}
											</label>
											<Controller
												name='emailRepeat'
												control={control}
												render={(props) => (
													<>
														<FormInputGrid
															placeholder={t('email_repeat') + '*:'}
															{...props}
															margin='dense'
															required
															handleChangeEmail={handleChangeEmail}
															defaultValue={
																preRegisterUser
																	? preRegisterUser.emailRepeat
																	: ' '
															}
														/>
													</>
												)}
											/>
										</div>

										<Controller
											control={control}
											id='autocomplete-off'
											name='preferredLanguage'
											autocomplete='off'
											render={(props) => (
												<div
													className='countryFlagLocation'
													style={{ marginTop: '10px' }}
												>
													<label>{t('preferred_language')}</label>
													<Dropdown
														style={{ width: '100%', height: '45px' }}
														appendTo='self'
														value={lang}
														options={languageItems}
														onChange={onLangChange}
														optionLabel='label'
														placeholder={setLang?.preferredLanguage}
														valueTemplate={selectedCountryTemplate}
														itemTemplate={countryOptionTemplate}
													/>
												</div>
											)}
										/>
										<div>
											<Controller
												control={control}
												id='autocomplete-off'
												name='assessmentPackages'
												autocomplete='off'
												render={(props) => (
													<div>
														<label>{t('test_package')}</label>
														<Dropdown
															style={{ width: '100%' }}
															options={testPackages}
															appendTo='self'
															value={testPackage}
															onChange={handleSetTestPackage}
															optionLabel='packageName'
															placeholder={selectedTestPackage?.packageName}
														/>
													</div>
												)}
											/>
										</div>
									</div>
								</div>

								<div style={{ marginTop: '10px' }}>
									<label>{t('hub')}</label>
									<Dropdown
										style={{ marginBottom: '10px', width: '100%' }}
										value={station}
										className='p-inputtext-sm'
										filter={true}
										appendTo='self'
										onChange={(e) => {
											setStation(e.value);
										}}
										options={stations}
										optionLabel='fullName'
										placeholder={station?.fullName}
									/>
								</div>
								<div style={{ display: 'flex' }}>
									<div style={{}}>
										{selected === 'tr' ? (
											<Controller
												name='mobilePhone'
												control={control}
												render={(props) => (
													<PhoneInput
														placeholder={t('mobile_phone') + '*:'}
														{...props}
													/>
												)}
											/>
										) : (
											<Controller
												name='mobilePhone'
												control={control}
												render={(props) => (
													<PhoneInputEn
														placeholder={t('mobile_phone') + '*:'}
														{...props}
													/>
												)}
											/>
										)}
									</div>
								</div>
								<div className='mt-2'>
									<Controller
										name='webUILoginNotAllowed'
										control={control}
										rules={{ required: 'Accept is required.' }}
										render={({ field }) => (
											<>
												<Checkbox
													inputId={field.name}
													checked={field.value || false}
													className={classNames({
														'p-invalid': errors.webUILoginNotAllowed,
													})}
													onChange={(e) => field.onChange(e.checked)}
												/>
												<label
													htmlFor={field.name}
													className={classNames({
														'p-error': errors.webUILoginNotAllowed,
													})}
												>
													<span className='mr-2'>
														{' '}
														{t('web_ui_login_not_allowed_label')}{' '}
													</span>
												</label>
											</>
										)}
									/>
								</div>

								<div className='d-flex justify-content-around'>
									<MainButton
										type='submit'
										variant='contained'
										color='primary'
										className={classes.submit}
									>
										{t('UPDATE')}
									</MainButton>
									<MainButton
										type='button'
										variant='contained'
										color='primary'
										className={classes.submit}
										onClick={onClose}
									>
										{t('GIVE_UP')}
									</MainButton>
								</div>
							</form>
						</Grid>
					</div>
				</div>
			</div>
		</>
	);
};
