import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import axios from 'axios'
import LayoutComponent from '../components/LayoutComponent'
import './review.css'
import CustomizedTreeView from '../components/common/CustomizedTreeView'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'

const ExpertReview = () => {
  let initialState = {
    user: { firstname: '', lastname: '', gender: '', age: 0 },
    score: 0,
    modules: [
      {
        name: '',
        description: '',
        score: 0,
        domains: [{ subdomains: [{}] }],
      },
    ],
  }
  const [data, setData] = useState(initialState)
  const [open, setOpen] = useState(true)
  useEffect(() => {
    axios
      .get('/reports/data')
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <LayoutComponent>
      <div className="container">
        <div className="sidebar">
          <div className="user-infos">
            <ul className="user-info">
              <li className="userName" onClick={() => setOpen(!open)}>
                {data.user.firstname} {data.user.lastname}
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    opacity: 1,
                    transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                    transition: '0.2s',
                    float: 'right',
                    marginTop: '35px',
                    marginRight: '50px',
                    color: '#1199f7',
                    cursor: 'pointer',
                  }}
                />
              </li>
              {!open && (
                <>
                  <li>{data.user.gender}</li>
                  <li>{data.user.age}</li>
                </>
              )}
            </ul>
          </div>
          <div className="user-infos">
            <CustomizedTreeView className="treeview" />
          </div>
        </div>
        <div className="reviews">
          <Typography>
            Lorem iTypographysum dolor sit amet consectetur, adipisicing //
            elit. Cumque, ducimus voluptas officia vel atque repellat eum //
            voluptatem mollitia magni temporibus laboriosam ab dolor //
            cupiditate corrupti ipsa totam praesentium suscipit esse. //{' '}
          </Typography>
        </div>
      </div>
    </LayoutComponent>
  )
}
export default ExpertReview
