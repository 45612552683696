import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import '../pages/homeuser.css';

const BestOfList = ({ t, module, setColor }) => {
  const [modules, setModules] = useState([]);
  const [topCognitive, setTopCognitive] = useState([]);
  const [lastCognitive, setLastCognitive] = useState([]);
  const [topPhysical, setTopPhysical] = useState([]);
  const [lastPhysical, setLastPhysical] = useState([]);
  const [topSocioEmotional, setTopSocioEmotional] = useState([]);
  const [lastSocioEmotional, setLastSocioEmotional] = useState([]);
  const useStyles = makeStyles({
    cognitive: {
      '&:before': {
        backgroundColor: topCognitive[0]?.color,
      },
    },
    physical: {
      '&:before': {
        backgroundColor: topPhysical[0]?.color,
      },
    },
    socioEmotional: {
      '&:before': {
        backgroundColor: topSocioEmotional[0]?.color,
      },
    },
  });
  const classes = useStyles();

  useEffect(() => {
    bestOfList();
    if (module.modules?.length > 0) {
      setModules(module.modules);
    }
  }, [module]);

  const bestOfList = () => {
    const cognitiveData = module?.modules?.find((item) => item.moduleId === 1);
    const physicalData = module?.modules?.find((item) => item.moduleId === 2);
    const socioEmotionalData = module?.modules?.find(
      (item) => item.moduleId === 3
    );

    let topCognitiveData = [];
    let lastCognitiveData = [];
    if (cognitiveData) {
      for (let i = 0; i < cognitiveData?.domains.length; i++) {
        let cognitiveObj = {
          name: `${cognitiveData.domains[i].domainName}`,
          score: cognitiveData.domains[i].domainScore,
          color: cognitiveData.forcedTreeColor,
        };
        topCognitiveData.push(cognitiveObj);
        lastCognitiveData.push(cognitiveObj);
      }
      topCognitiveData = topCognitiveData
        .filter((item) => item.score !== null)
        .sort((a, b) => parseInt(b.score) - parseInt(a.score))
        .slice(0, 3);

      lastCognitiveData = lastCognitiveData
        .filter((item) => item.score !== null)
        .sort((a, b) => parseInt(a.score) - parseInt(b.score))
        .slice(0, 3);
      setTopCognitive(topCognitiveData);
      setLastCognitive(lastCognitiveData);
    }
    let topPysicalData = [];
    let lastPyhsicalData = [];
    if (physicalData) {
      for (let i = 0; i < physicalData?.domains.length; i++) {
        const physicalObj = {
          name: `${physicalData.domains[i].domainName}`,
          score: physicalData.domains[i].domainScore,
          color: physicalData.forcedTreeColor,
        };
        topPysicalData.push(physicalObj);
        lastPyhsicalData.push(physicalObj);
      }
      topPysicalData = topPysicalData
        .filter((item) => item.score !== null)
        .sort((a, b) => parseInt(b.score) - parseInt(a.score))
        .slice(0, 3);

      lastPyhsicalData = lastPyhsicalData
        .filter((item) => item.score !== null)
        .sort((a, b) => parseInt(a.score) - parseInt(b.score))
        .slice(0, 3);

      setTopPhysical(topPysicalData);
      setLastPhysical(lastPyhsicalData);
    }

    let topSocioEmotionalData = [];
    let lastSocioEmotionalData = [];

    if (socioEmotionalData) {
      for (let i = 0; i < socioEmotionalData?.domains.length; i++) {
        let physicalObj = {
          name: `${socioEmotionalData.domains[i].domainName}`,
          score: socioEmotionalData.domains[i].domainScore,
          color: socioEmotionalData.forcedTreeColor,
        };
        topSocioEmotionalData.push(physicalObj);
        lastSocioEmotionalData.push(physicalObj);
      }

      topSocioEmotionalData = topSocioEmotionalData
        .filter((item) => item.score !== null)
        .sort((a, b) => parseInt(b.score) - parseInt(a.score))
        .slice(0, 3);

      lastSocioEmotionalData = lastSocioEmotionalData
        .filter((item) => item.score !== null)
        .sort((a, b) => parseInt(a.score) - parseInt(b.score))
        .slice(0, 3);
      setTopSocioEmotional(topSocioEmotionalData);
      setLastSocioEmotional(lastSocioEmotionalData);
    }
  };

  return (
    <div className="info-details flex">
      <div key={0} className={'module1 bestList'}>
        <h3 className={classes.cognitive}>
          <span className="moduleAll" style={{ position: 'relative' }}>
            {modules[0]?.moduleName ?? ''}
          </span>
        </h3>
        <h4>{t('Best')}</h4>
        <ul>
          {topCognitive?.map((item, index) => (
            <li key={index}>
              {item.name}{' '}
              <span style={{ color: setColor(item.score), fontWeight: 'bold' }}>
                {item.score}
              </span>
            </li>
          ))}
        </ul>
        <h4>{t('Worst')}</h4>
        <ul>
          {lastCognitive?.map((item, index) => (
            <li key={index}>
              {item.name}{' '}
              <span style={{ color: setColor(item.score), fontWeight: 'bold' }}>
                {item.score}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div key={1} className={'module2 bestList'}>
        <h3 className={classes.physical}>
          <span className="moduleAll">{modules[1]?.moduleName ?? ''}</span>{' '}
        </h3>
        <h4>{t('Best')}</h4>
        <ul>
          {topPhysical?.map((item, index) => (
            <li key={index}>
              {item.name}{' '}
              <span style={{ color: setColor(item.score), fontWeight: 'bold' }}>
                {item.score}
              </span>
            </li>
          ))}
        </ul>
        <h4>{t('Worst')}</h4>
        <ul>
          {lastPhysical?.map((item, index) => (
            <li key={index}>
              {item.name}{' '}
              <span style={{ color: setColor(item.score), fontWeight: 'bold' }}>
                {item.score}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <div key={2} className={'module3 bestList'}>
        <h3 className={classes.socioEmotional}>
          <span className="moduleAll">{modules[2]?.moduleName ?? ''}</span>
        </h3>
        <h4>{t('Best')}</h4>
        <ul>
          {topSocioEmotional?.map((item, index) => (
            <li key={index}>
              {item.name}{' '}
              <span style={{ color: setColor(item.score), fontWeight: 'bold' }}>
                {item.score}
              </span>
            </li>
          ))}
        </ul>
        <h4>{t('Worst')}</h4>
        <ul>
          {lastSocioEmotional?.map((item, index) => (
            <li key={index}>
              {item.name}{' '}
              <span style={{ color: setColor(item.score), fontWeight: 'bold' }}>
                {item.score}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default withTranslation()(BestOfList);
