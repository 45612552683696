import React, { Suspense, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  Container,
  Grid,
  Hidden,
  Step,
  Box,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import LayoutComponent from '../components/LayoutComponent';
import AuthenticationService from '../service/AuthenticationService';
import axios from 'axios';
import { MainButton } from '../components/common/MainButton';
import PrescreeningForm from './PrescreeningForm';
import store from '../Store';
import { useSnapshot } from 'valtio';
import Welcome from './Welcome';
import DemographicsService from '../service/DemographicsService';
import { DemographicsInfo } from '../components/DemographicsInfo';
import UserService from '../service/UserService';
import { DemographicsUpdateForm } from './DemographicsUpdateForm';
import { DoctorDemographicsInfo } from '../components/DoctorDemographicsInfo';
import WelcomeDoctor from './WelcomeDoctor';
import { DoctorDemographicsForm } from './DoctorDemographicsForm';
import DoctorDemographicsService from '../service/DoctorDemographicsService';
import { DoctorDemographicsUpdateForm } from './DoctorDemographicsUpdateForm';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Spinner from '../components/common/Spinner';

const useStyles = makeStyles((theme) => ({
  stepper: {
    backgroundColor: 'transparent',
  },
  container: {
    paddingLeft: 100,
  },
}));
const HomeAdmin = ({ userData, t }) => {
  let history = useHistory();
  const [demographicsExists, setDemographicsExists] = useState(false);
  const [doctorDemographicsExists, setDoctorDemographicsExists] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [prescreening, setPrescreening] = useState({});
  ///const user = AuthenticationService.getLoggedInUser();
  const classes = useStyles();
  //store.isEditMode = false;
  const snapshot = useSnapshot(store);
  const DemographicsInfo = React.lazy(() =>
    import('../components/DemographicsInfo')
  );
  const DemographicsUpdateForm = React.lazy(() =>
    import('./DemographicsUpdateForm')
  );
  const DemographicsForm = React.lazy(() => import('./DemographicsForm'));

  useEffect(() => {
    setLoading(true);
    store.steps.current = 0;
    store.isEditMode = false;

    DemographicsService.getDemographicsInfoByUserId(userData.userId)
      .then((res) => {
        setDemographicsExists(true);
        return res.data;
      })
      .then((response) => {
        if (userData.userId) {
          axios
            .get('/users/prescreening', {
              params: {
                userId: userData.userId,
              },
            })
            .then((res) => {
              if (!!res.data.data) {
                setDemographicsExists(true);
                setPrescreening(res.data);
                store.pageTitle = `${t('preview_form')}`;
                store.steps.current = snapshot.steps.totalCount - 1;
              } else {
                store.steps.current = 1;
              }
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const switchComponent = (step) => {
    switch (step) {
      case -1:
        return <Welcome />;
      case 0:
        store.pageTitle = `${t('demographic_information')}`;
        if (snapshot.isEditMode && !userData.roles.includes('DOCTOR')) {
          return (
            demographicsExists && (
              <Suspense>
                <DemographicsUpdateForm userId={userData.userId} />
              </Suspense>
            )
          );
        } else if (snapshot.isEditMode && userData.roles.includes('DOCTOR')) {
          return (
            doctorDemographicsExists && (
              <DoctorDemographicsUpdateForm userId={userData.userId} />
            )
          );
        } else {
          if (doctorDemographicsExists && userData.roles.includes('DOCTOR')) {
            return <DoctorDemographicsInfo />;
          } else if (userData.roles.includes('DOCTOR')) {
            return <DoctorDemographicsForm />;
          } else {
            return (
              <Suspense>
                <DemographicsInfo
                  isAdminPanel={true}
                  userId={userData.userId}
                />
              </Suspense>
            );
          }
        }
      default:
        if (userData.roles.includes('DOCTOR')) return <WelcomeDoctor />;
        else
          return (
            <Suspense>
              <PrescreeningForm
                prescreening={prescreening}
                isAdminPanel={true}
                user={userData}
              />
            </Suspense>
          );
    }
  };

  return (
    <>
      <Spinner isLoading={loading} />
      {!loading && (
        <Container classes={classes.container} maxWidth={false}>
          <Grid container justifyContent='center'>
            <Hidden mdDown>
              <Grid item md={2}>
                <Stepper
                  className={classes.stepper}
                  activeStep={snapshot.steps.current}
                  orientation='vertical'
                >
                  {/*<Step key={0}>*/}
                  {/*  <StepLabel>{t('welcome')}</StepLabel>*/}
                  {/*</Step>*/}
                  <Step key={0}>
                    <StepLabel>{t('demographicsInfo')}</StepLabel>
                  </Step>
                  {!AuthenticationService.isDoctor() && (
                    <Step key={1}>
                      <StepLabel>{t('life_style')}</StepLabel>
                    </Step>
                  )}
                  {!AuthenticationService.isDoctor() && (
                    <Step key={2}>
                      <StepLabel>{t('daily_func')}</StepLabel>
                    </Step>
                  )}
                  {!AuthenticationService.isDoctor() && (
                    <Step key={3}>
                      <StepLabel>{t('health_history')}</StepLabel>
                    </Step>
                  )}
                  {!AuthenticationService.isDoctor() && (
                    <Step key={4}>
                      <StepLabel>{t('individual_assessment')}</StepLabel>
                    </Step>
                  )}
                  {!AuthenticationService.isDoctor() && (
                    <Step key={5}>
                      <StepLabel>{t('physical_activity')}</StepLabel>
                    </Step>
                  )}
                  {!AuthenticationService.isDoctor() &&
                    snapshot.steps.parqPlusVisible && (
                      <Step key={6}>
                        <StepLabel>{t('physical_activity')}+</StepLabel>
                      </Step>
                    )}
                </Stepper>
              </Grid>
            </Hidden>
            {!loading && (
              <Grid item={true} xs={12} md={10}>
                {switchComponent(snapshot.steps.current)}
              </Grid>
            )}
          </Grid>
          <Grid container style={{ marginTop: 24 }}>
            {/*<Grid item={true} xs={12}>*/}
            {/*  {snapshot.steps.current < 1 && (*/}
            {/*    <Box textAlign="right">*/}
            {/*      <MainButton*/}
            {/*        color="primary"*/}
            {/*        disabled={store.steps.current === 0 && !snapshot.consentGiven}*/}
            {/*        onClick={() => {*/}
            {/*          if (store.steps.current === 0 && snapshot.consentGiven) {*/}
            {/*            axios*/}
            {/*              .post('/users/giveConsent')*/}
            {/*              .then(() => {*/}
            {/*                store.steps.current++;*/}
            {/*                UserService.updateUserConsent(true);*/}
            {/*              })*/}
            {/*              .catch((err) => console.log(err));*/}
            {/*          } else {*/}
            {/*            store.steps.current++;*/}
            {/*          }*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        {t('NEXT')}*/}
            {/*      </MainButton>*/}
            {/*    </Box>*/}
            {/*  )}*/}
            {/*</Grid>*/}
          </Grid>
        </Container>
      )}
    </>
  );
};

export default withTranslation()(HomeAdmin);
