import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import moment from 'moment';
import { MenuItem, Paper } from '@material-ui/core';
import {
  bindHover,
  bindMenu,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import Swal from 'sweetalert2';
import AuthenticationService from '../service/AuthenticationService';
import { withTranslation } from 'react-i18next';

moment.locale = 'tr';

const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(3),
  },
}));

const DoctorAppointmentCalendar = ({ t }) => {
  const [events, setEvents] = useState([]);

  const classes = useStyles();

  const handleAppointmentDeletion = (appointmentId) => {
    axios
      .delete('/appointments/cancel', {
        params: { appointmentId },
      })
      .then(() => {
        Swal.fire({
          title: `${t('appointment_alert_messages.cancelled')}`,
          icon: 'success',
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          footer: '',
        });
        fetchAppointments();
      });
  };

  const messages = {
    date: `${t('messages.date')}`,
    time: `${t('messages.time')}`,
    event: `${t('messages.event')}`,
    allDay: `${t('messages.allDay')}`,
    week: `${t('messages.week')}`,
    work_week: `${t('messages.work_week')}`,
    day: `${t('messages.day')}`,
    month: `${t('messages.month')}`,
    previous: `${t('messages.previous')}`,
    next: `${t('messages.next')}`,
    yesterday: `${t('messages.yesterday')}`,
    tomorrow: `${t('messages.tomorrow')}`,
    today: `${t('messages.today')}`,
    agenda: `${t('messages.agenda')}`,
  };

  const Event = ({ event }) => {
    const { data } = event;

    const popupState = usePopupState({
      variant: 'popover',
      popupId: 'demoMenu',
    });

    return (
      <>
        <span {...bindHover(popupState)}>
          <strong>
            Tip: {data.type} {data.name} {data.surname} - {data.userId}
          </strong>

          <HoverMenu
            {...bindMenu(popupState)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <MenuItem
              onClick={() => {
                popupState.close();
                Swal.fire({
                  title: `${t(
                    'appointment_alert_messages.sure_cancel_appointment'
                  )}`,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#EC0044',
                  cancelButtonColor: '#929ea8',
                  confirmButtonText: `${t(
                    'appointment_alert_messages.cancel_appointment'
                  )}`,
                  cancelButtonText: `${t(
                    'appointment_alert_messages.give_up'
                  )}`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    handleAppointmentDeletion(data.appointmentId);
                  }
                });
              }}
            >
              Sil
            </MenuItem>
          </HoverMenu>
        </span>
      </>
    );
  };

  const components = {
    event: Event,
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = () => {
    const userData = AuthenticationService.getLoggedInUser();
    const userId = userData.userId;

    axios
      .get('/appointments/approvedAppointmentsByDoctor', {
        params: { userId },
      })
      .then((res) => {
        const appointments = res.data;
        let events = [];
        appointments.forEach((appointment) => {
          const start = moment(appointment.date)
            .add(moment(appointment.start, 'HH:mm').hours(), 'hours')
            .add(moment(appointment.start, 'HH:mm').minutes(), 'minutes')
            .toDate();

          const end = moment(appointment.date)
            .add(moment(appointment.end, 'HH:mm').hours(), 'hours')
            .add(moment(appointment.end, 'HH:mm').minutes(), 'minutes')
            .toDate();

          events.push({
            start: start,
            end: end,
            data: appointment,
          });
        });
        setEvents(events);
      });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Calendar
          selectable
          localizer={localizer}
          messages={messages}
          events={events}
          step={60}
          timeslots={1}
          views={[Views.WEEK, Views.DAY, Views.AGENDA]}
          components={components}
          min={moment('09:00', 'HH:mm').toDate()}
          max={moment('19:00', 'HH:mm').toDate()}
          defaultView={Views.WEEK}
          scrollToTime={new Date()}
          defaultDate={new Date()}
        />
      </Paper>
    </div>
  );
};

export default withTranslation()(DoctorAppointmentCalendar);
