import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	Checkbox,
	CssBaseline,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Box,
	Grid,
	Tooltip,
} from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import parsePhoneNumber from 'libphonenumber-js';
import { Button } from 'primereact/button';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import 'yup-phone';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { CardLayout } from '../components/CardLayout';
import { MainButton } from '../components/common/MainButton';
import FormInput from '../components/common/FormInput';
import PhoneInput from '../components/common/PhoneInput';
import { Dialog } from 'primereact/dialog';
import { withTranslation } from 'react-i18next';
import FormInputGrid from '../components/common/FormInputGrid';
import { Dropdown } from 'primereact/dropdown';
import { IconFlagTR, IconFlagDE, IconFlagUS } from 'material-ui-flags';
import Spinner from '../components/common/Spinner';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import PhoneInputEn from '../components/common/PhoneInputEn';
const useStyles = makeStyles((theme) => ({
	card: {
		padding: 20,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	commChannels: {
		marginTop: theme.spacing(2),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	register: {
		'&:hover': {
			textDecoration: 'underline',
		},
		textTransform: 'none',
	},
}));

const Register = ({ t }) => {
	const Schema = Yup.object().shape({
		name: Yup.string().required(`${t('schema_shape.not_empty')}`),
		surname: Yup.string().required(`${t('schema_shape.not_empty')}`),
		email: Yup.string()
			.email(`${t('schema_shape.valid_email')}`)
			.required(`${t('schema_shape.not_empty')}`),
		commChannels: Yup.array()
			.transform((o, obj) => Object.keys(obj).filter((k) => obj[k]))
			.min(1, `${t('schema_shape.please_select_one')}`),
		mobilePhone: Yup.string()
			.required(`${t('schema_shape.not_empty')}`)
			.phone('TR', true, `${t('schema_shape.valid_phone')}`),
		stationaryPhone: Yup.string().notRequired().nullable(),
		password: Yup.string()
			.required(`${t('schema_shape.empty_password')}`)
			.min(8, `${t('schema_shape.min_password')}`),
		confirmPassword: Yup.string()
			.required(`${t('schema_shape.empty_password')}`)
			.oneOf(
				[Yup.ref('password'), null],
				`${t('schema_shape.match_password')}`
			),
		emailRepeat: Yup.string()
			.email(`${t('schema_shape.valid_email')}`)
			.required(`${t('schema_shape.not_empty')}`)
			.oneOf([Yup.ref('email')], `${t('email_doesnt_match')}`),
	});

	const { enqueueSnackbar } = useSnackbar();
	const selected = localStorage.getItem('i18nextLng') || 'en';

	const toast = React.useRef(null);
	let history = useHistory();
	const classes = useStyles();
	const {
		handleSubmit,
		control,
		register,
		watch,
		errors,
		clearErrors,
		setError,
	} = useForm({
		resolver: yupResolver(Schema),
	});

	const watchEmailInput = watch('email', false);

	const [commChannels, setCommChannels] = useState([]);

	const options = [
		{ label: `${t('options_email')}`, value: 'e' },
		// { label: `${t(email'options_sms')}`, value: 's' },
		// { label: `${t('options_mobile_phone')}`, value: 'm' },
		// { label: `${t('options_fixed_line')}`, value: 'l' },
		// { label: `${t('options_fax')}`, value: 'f' },
	];

	const [open, setOpen] = useState(false);
	const [file, setFile] = useState(null);
	const [isDoctor, setIsDoctor] = useState(false);
	const [displayResponsive, setDisplayResponsive] = useState(false);
	const [testPackages, setTestPackages] = useState([]);
	const [testPackage, setTestPackage] = useState({});
	const [testPackageTypes, setTestPackageTypes] = useState([]);
	const [testPackageType, setTestPackageType] = useState({});
	const [loading, setLoading] = useState(false);
	const ref = React.createRef();
	const updateDoctor = () => setIsDoctor(!isDoctor);

	const onSubmit = async (registerForm) => {
		// setLoading(true);
		const formData = new FormData();

		const image = file;
		delete registerForm['image'];

		const phoneNumber = parsePhoneNumber('+' + registerForm['mobilePhone']);
		if (phoneNumber) {
			registerForm['mobilePrefix'] = parseInt(phoneNumber.countryCallingCode);
			registerForm['mobilePhone'] = phoneNumber.nationalNumber;
		}

		if (registerForm['stationaryPhone']) {
			const stationaryPhone = parsePhoneNumber(
				'+' + registerForm['stationaryPhone']
			);
			if (stationaryPhone) {
				registerForm['mobilePhone'] = parseInt(stationaryPhone.nationalNumber);
			}
		}
		delete registerForm['password2'];
		registerForm['commChannels'] = commChannels.join();
		registerForm['assessmentPackages'] = testPackage;
		const assessmentPackage = new Blob(
			[JSON.stringify(registerForm['assessmentPackages'])],
			{
				type: 'application/json',
			}
		);

		const registerFormData = new Blob([JSON.stringify(registerForm)], {
			type: 'application/json',
		});
		formData.append('registrationComm', registerFormData);
		formData.append('assessmentPackage', assessmentPackage);
		// formData.append('file', image);
		if (!registerForm.assessmentPackages.id) {
			enqueueSnackbar(`${t('enqueueSnackbar_message.please_select_package')}`, {
				variant: 'error',
			});
			return;
		}
		axios
			.post('/users/register', formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
				params: { isDoctor },
			})
			.then(() => {
				setDisplayResponsive(true);
				setLoading(false);
			})
			.catch((err) => {
				if (err?.response?.data?.reason === 'VERIFY_EMAIL') {
					enqueueSnackbar(`${t('enqueueSnackbar_message.verify_mail')}`, {
						variant: 'warning',
					});
					setLoading(false);
				} else if (err?.response?.data?.errorCode === 'EMAIL_ALREADY_EXISTS') {
					enqueueSnackbar(
						`${t(`enqueueSnackbar_message.another_using_mail`)}`,
						{
							variant: 'error',
						}
					);
					setLoading(false);
				} else {
					enqueueSnackbar(
						`${t('Unable to connect to the server, please try again later')}`,
						{
							variant: 'error',
						}
					);
					setLoading(false);
				}
			});
	};

	React.useEffect(() => {
		const subscription = watch((value) => {
			if (value.email === value.emailRepeat) {
				clearErrors('emailRepeat');
			} else {
				setError('emailRepeat', {
					type: 'oneOf',
					message: `${t('email_doesnt_match')}`,
				});
			}
		});
		return () => subscription.unsubscribe();
	}, [clearErrors, setError, watch, errors]);

	const confirm = async (registerForm) => {
		const isValidEmails = await validateEmail(registerForm.email);
		if (isValidEmails) {
			onSubmit(registerForm);
		} else {
			confirmDialog({
				message: `${t('enqueueSnackbar_message.english_charachter_valid')}`,
				header: `${t('appointment_alert_messages.confirmation')}`,
				icon: 'pi pi-exclamation-triangle',
				acceptLabel: `${t('yes')}`,
				rejectLabel: `${t('no')}`,
				accept: () => onSubmit(registerForm),
				reject: () => reject(),
			});
		}
	};

	const reject = () => {};

	const historyPush = () => {
		setDisplayResponsive(false);
		history.push({
			pathname: '/giris',
			state: { verificationPending: true },
		});
	};

	function handleSelect(checkedName) {
		const newNames = commChannels?.includes(checkedName)
			? commChannels?.filter((name) => name !== checkedName)
			: [...(commChannels ?? []), checkedName];
		setCommChannels(newNames);
		return newNames;
	}

	const styles = {
		header: {
			textAlign: 'center',
		},
	};
	const RegisterDialog = () => {
		return (
			<Dialog
				header={t('information')}
				headerStyle={{ textAlign: 'center' }}
				headerClassName={styles.header}
				footer={renderFooter}
				visible={displayResponsive}
				breakpoints={{ '960px': '75vw', '640px': '100vw' }}
				style={{ width: '50vw' }}
			>
				{t('register_success_message')}
			</Dialog>
		);
	};

	const renderFooter = () => {
		return (
			<div style={{ textAlign: 'center' }}>
				<MainButton
					type='submit'
					variant='contained'
					color='primary'
					className={classes.submit}
					onClick={() => historyPush()}
					autoFocus
				>
					{t('ok')}
				</MainButton>
			</div>
		);
	};

	React.useEffect(() => {
		getAllTestPackageTypes();
	}, []);

	const handleChangeTestPackageType = (e) => {
		setTestPackageType(e.target.value);
		getAllTestPackagesByTypes(e.target.value.id);
	};

	const handleSetTestPackage = (e) => {
		setTestPackage(e.target.value);
	};

	const getAllTestPackageTypes = async () => {
		try {
			const { data } = await axios.get('/users/getAllAssesmentPackageTypes');

			setTestPackageTypes(data);
		} catch (error) {
			console.log(error);
		}
	};

	const getAllTestPackagesByTypes = async (packageId) => {
		try {
			const { data } = await axios.get(
				'/users/getAssessmentPackagesByAssessmentTypeId/' + packageId
			);
			setTestPackages(data);
		} catch (error) {
			console.log(error);
		}
	};

	const validateEmail = async (email) => {
		const re =
			/^([a-zA-Z0-9]+[._]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[._]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
		return re.test(String(email).toLowerCase());
	};

	const handleChangeEmail = (value) => {
		return value.toLowerCase();
	};

	return (
		<>
			<ConfirmDialog />
			<Spinner loading={loading} text={'register_wait'} />
			<Toast ref={toast} />
			<CardLayout title={t('register')}>
				<CssBaseline />
				<RegisterDialog />
				<div className={classes.paper}>
					<Grid item={true} xs={12} sm={10} md={8} lg={6}>
						<form
							className={classes.form}
							noValidate
							onSubmit={handleSubmit(confirm)}
							encType='multipart/form-data'
						>
							{/* <FormControlLabel
              control={<Checkbox checked={isDoctor} onChange={updateDoctor} />}
              label={'Doktor musunuz?'}
            /> */}

							<Controller
								name='name'
								control={control}
								render={(props) => (
									<FormInput
										{...props}
										margin='dense'
										required
										label={t('your_name')}
									/>
								)}
							/>

							<Controller
								name='surname'
								control={control}
								render={(props) => (
									<FormInput
										{...props}
										margin='dense'
										required
										label={t('your_surname')}
									/>
								)}
							/>

							{/*<Grid container>*/}
							{/*  <Controller*/}
							{/*    name="commChannels"*/}
							{/*    control={control}*/}
							{/*    render={(props) => (*/}
							{/*      <Grid*/}
							{/*        container*/}
							{/*        alignItems="center"*/}
							{/*        spacing={1}*/}
							{/*        className={classes.commChannels}*/}
							{/*      >*/}
							{/*        <Grid item={true} xs={4}>*/}
							{/*          <FormLabel>*/}
							{/*            {t('select_communication_channel')}:*/}
							{/*          </FormLabel>*/}
							{/*        </Grid>*/}
							{/*        <Grid container item={true} xs={8}>*/}
							{/*          {options.map((option, index) => (*/}
							{/*            <Grid key={index} item={true} xs={6} xl={6}>*/}
							{/*              <FormControlLabel*/}
							{/*                control={*/}
							{/*                  <Checkbox*/}
							{/*                    margin="dense"*/}
							{/*                    onChange={() =>*/}
							{/*                      props.field.onChange(*/}
							{/*                        handleSelect(option.value)*/}
							{/*                      )*/}
							{/*                    }*/}
							{/*                  />*/}
							{/*                }*/}
							{/*                label={option.label}*/}
							{/*                key={option.value}*/}
							{/*              />*/}
							{/*            </Grid>*/}
							{/*          ))}*/}

							{/*          <FormHelperText error={!!props.field.error}>*/}
							{/*            {props?.field?.error?.message}*/}
							{/*          </FormHelperText>*/}
							{/*        </Grid>*/}
							{/*      </Grid>*/}
							{/*    )}*/}
							{/*  />*/}
							{/*</Grid>*/}

							{/*<Controller*/}
							{/*  name="email"*/}
							{/*  control={control}*/}
							{/*  render={(props) => (*/}
							{/*    <FormInput*/}
							{/*      {...props}*/}
							{/*      margin="dense"*/}
							{/*      required*/}
							{/*      label={t('email')}*/}
							{/*    />*/}
							{/*  )}*/}
							{/*/>*/}
							<Controller
								name='email'
								control={control}
								rules={{
									pattern: {
										value: /^[a-z]+$/i,
										message: 'Lütfen sadece harf kullanın',
									},
								}}
								render={(props) => (
									<>
										<FormInputGrid
											placeholder={t('email') + '*:'}
											{...props}
											margin='dense'
											required
											label={t('email')}
											handleChangeEmail={handleChangeEmail}
										/>
									</>
								)}
							/>
							<Controller
								name='emailRepeat'
								control={control}
								render={(props) => (
									<>
										<FormInputGrid
											placeholder={t('email_repeat') + '*:'}
											{...props}
											margin='dense'
											required
											label={t('email_repeat')}
											handleChangeEmail={handleChangeEmail}
										/>
									</>
								)}
							/>
							<Controller
								control={control}
								id='autocomplete-off'
								name='name'
								autocomplete='off'
								render={(props) => (
									<div style={{ marginTop: '10px' }}>
										<label>{t('test_package_type')}</label>
										<Dropdown
											style={{ width: '100%' }}
											options={testPackageTypes}
											appendTo='self'
											value={testPackageType}
											onChange={handleChangeTestPackageType}
											optionLabel='name'
											optionValue=''
											placeholder=''
										/>
									</div>
								)}
							/>

							<Controller
								control={control}
								id='autocomplete-off'
								name='assessmentPackages'
								autocomplete='off'
								render={(props) => (
									<div style={{ marginTop: '10px' }}>
										<label>{t('test_package')}</label>
										<Dropdown
											style={{ width: '100%' }}
											options={testPackages}
											appendTo='self'
											value={testPackage}
											onChange={handleSetTestPackage}
											optionLabel='packageName'
											optionValue=''
											placeholder=''
										/>
									</div>
								)}
							/>

							{selected === 'tr' ? (
								<Controller
									name='mobilePhone'
									control={control}
									render={(props) => (
										<PhoneInput
											placeholder={t('mobile_phone') + '*:'}
											{...props}
										/>
									)}
								/>
							) : (
								<Controller
									name='mobilePhone'
									control={control}
									render={(props) => (
										<PhoneInputEn
											placeholder={t('mobile_phone') + '*:'}
											{...props}
										/>
									)}
								/>
							)}

							{selected === 'tr' ? (
								<Controller
									name='stationaryPhone'
									control={control}
									render={(props) => (
										<PhoneInput
											required
											placeholder={t('stationary_phone') + ':'}
											{...props}
										/>
									)}
								/>
							) : (
								<Controller
									name='stationaryPhone'
									control={control}
									render={(props) => (
										<PhoneInputEn
											required
											placeholder={t('stationary_phone') + ':'}
											{...props}
										/>
									)}
								/>
							)}

							<Controller
								name='image'
								control={control}
								render={() => (
									<Box marginTop={2} marginBottom={2}>
										<Grid container justifyContent='center'>
											<Tooltip title={t('optional_photos_message')}>
												<Button
													variant='contained'
													color='secondary'
													onClick={() => setOpen(true)}
												>
													{file == null
														? `${t('add_photo')}`
														: `${t('change_photo')}`}
												</Button>
											</Tooltip>
										</Grid>

										<DropzoneDialog
											acceptedFiles={['image/*']}
											cancelButtonText={`${t('cancel')}`}
											submitButtonText={`${t('upload')}`}
											dropzoneText={`${t('drag_and_drop_file')}`}
											previewText={`${t('preview: ')}`}
											dialogTitle={`${t('file_upload')}`}
											maxFileSize={5000000}
											filesLimit={1}
											open={open}
											onClose={() => setOpen(false)}
											onSave={(files) => {
												enqueueSnackbar(`${t('photo_upload_success')}`, {
													variant: 'success',
												});
												setFile(files[0]);
												setOpen(false);
											}}
											showAlerts={false}
											showPreviews={true}
											showFileNamesInPreview={true}
										/>
									</Box>
								)}
							/>

							<Controller
								name='password'
								control={control}
								render={(props) => (
									<>
										<span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
											{t('password_min_8')}
										</span>
										<FormInput
											{...props}
											margin='dense'
											type='password'
											required
											label={t('password')}
										/>
									</>
								)}
							/>

							<Controller
								name='confirmPassword'
								control={control}
								render={(props) => (
									<FormInput
										{...props}
										type='password'
										margin='dense'
										required
										label={t('repeat_password')}
									/>
								)}
							/>

							<MainButton
								type='submit'
								fullWidth
								variant='contained'
								color='primary'
								className={classes.submit}
							>
								{t('Register')}
							</MainButton>
							<Grid container justifyContent='flex-end'>
								<Grid item>
									<Button
										onClick={historyPush}
										component={Link}
										to='/giris'
										color='secondary'
										className={classes.register}
									>
										{t('have_account_message')}
									</Button>
								</Grid>
							</Grid>
						</form>
					</Grid>
				</div>
			</CardLayout>
		</>
	);
};

export default withTranslation()(Register);
