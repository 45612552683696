import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import ButtonMaterial from '@mui/material/Button';

import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ConfirmPopup } from 'primereact/confirmpopup';
import CompetencyNewDefinitionService from '../service/CompetencyNewDefinitionService';
import { InputTextarea } from 'primereact/inputtextarea';

const UpdateNewCompetencyDefinition = ({
	competencyId,
	t,
	updateCompetencyDefinitionModalOpen,
	getCompetencyDefinitions,
	handleCloseUpdateCompetencyModal,
	setLoading,
	getDefinitionsByCompetencyId,
	organitizationalImportance,
	selectedCompetencyDefinition,
}) => {
	const toast = useRef(null);
	const toastInside = useRef(null);
	const [competencyDefinition, setCompetencyDefinition] = useState({
		behavioralPatternDescriptionText: '',
		definition: '',
		id: '',
		languageId: '',
		organitizationalImportance: '',
		sarfCompetencyId: '',
	});
	const [languages, setLanguages] = useState([
		{ languageId: 'tr' },
		{ languageId: 'en' },
	]);

	useEffect(() => {
		if (
			selectedCompetencyDefinition != null ||
			(selectedCompetencyDefinition != undefined &&
				updateCompetencyDefinitionModalOpen)
		) {
			updateData();
		} else {
			return;
		}
	}, [selectedCompetencyDefinition]);

	const updateData = () => {
		setCompetencyDefinition({
			...selectedCompetencyDefinition,
			languageId: { languageId: selectedCompetencyDefinition?.languageId },
		});
	};
	const getCompetencyDefinitionById = (id) => {
		CompetencyNewDefinitionService.getById(id)
			.then((res) => {
				setCompetencyDefinition(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateCompetencyDefinition = () => {
		const payload = {
			definition: competencyDefinition.definition,
			id: selectedCompetencyDefinition.id,
			behavioralPatternDescriptionText:
				competencyDefinition.behavioralPatternDescriptionText,
			languageId: competencyDefinition.languageId.languageId,
			organitizationalImportance:
				competencyDefinition.organitizationalImportance,
			sarfCompetencyId: competencyId,
		};

		CompetencyNewDefinitionService.update(payload)
			.then((res) => {
				setLoading(true);
				setTimeout(() => {
					handleCloseUpdateCompetencyModal();
					setLoading(false);
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
						life: 3000,
					});
				}, 2000);
				getDefinitionsByCompetencyId(competencyId);
			})
			.catch((error) => {
				setLoading(false);

				toastInside.current.show({
					severity: 'error',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: error.response.data,
					life: 3000,
				});
			});
	};

	return (
		<>
			<Toast ref={toast} />

			<Dialog
				open={updateCompetencyDefinitionModalOpen}
				fullWidth={true}
				maxWidth={'sm'}
				onClose={() => {
					handleCloseUpdateCompetencyModal();
				}}
			>
				<Toast ref={toastInside} />

				<DialogContent
					style={{
						overflowY: 'visible',
						display: 'flex',
						justifyContent: 'start',
						flexDirection: 'column',
						height: '300px',
					}}
				>
					<DialogContentText style={{ marginBottom: '20px' }}>
						{t('select_industry_category')}
					</DialogContentText>

					<FormControl bodyStyle={{ width: '400px' }}>
						<div
							style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
						>
							<div style={{ width: '50%', paddingRight: '10px' }}>
								<label>{t('language')}</label>
								<Dropdown
									style={{ width: '100%' }}
									appendTo='self'
									autoZIndex={false}
									value={competencyDefinition?.languageId}
									scrollHeight={'150px'}
									onChange={(e) => {
										setCompetencyDefinition({
											...competencyDefinition,
											languageId: e.value,
										});
									}}
									options={languages}
									optionLabel='languageId'
								/>
							</div>
							<div style={{ width: '50%', paddingLeft: '10px' }}>
								<label>{t('definition')}</label>
								<InputTextarea
									style={{ width: '100%' }}
									value={competencyDefinition.definition}
									onChange={(e) => {
										setCompetencyDefinition({
											...competencyDefinition,
											definition: e.target.value,
										});
									}}
								/>
							</div>
						</div>
						<div
							style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
						>
							<div style={{ width: '50%', paddingRight: '10px' }}>
								<label>{t('statement_regarding_organization_value')}</label>
								<InputTextarea
									style={{ width: '100%' }}
									value={competencyDefinition.organitizationalImportance}
									onChange={(e) => {
										setCompetencyDefinition({
											...competencyDefinition,
											organitizationalImportance: e.target.value,
										});
									}}
								/>
							</div>
							<div style={{ width: '50%', paddingLeft: '10px' }}>
								<label>{t('behavior_model_description_text')}</label>
								<InputTextarea
									style={{ width: '100%' }}
									value={competencyDefinition.behavioralPatternDescriptionText}
									onChange={(e) => {
										setCompetencyDefinition({
											...competencyDefinition,
											behavioralPatternDescriptionText: e.target.value,
										});
									}}
								/>
							</div>
						</div>
					</FormControl>
				</DialogContent>
				<DialogActions style={{ marginBottom: '1rem', marginRight: '1rem' }}>
					<ButtonMaterial
						onClick={() => {
							handleCloseUpdateCompetencyModal();
						}}
					>
						{' '}
						{t('Cancel')}
					</ButtonMaterial>
					<ButtonMaterial
						variant='contained'
						onClick={() => {
							updateCompetencyDefinition();
						}}
					>
						{' '}
						{t('update')}
					</ButtonMaterial>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default withTranslation()(UpdateNewCompetencyDefinition);
