import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import OverviewService from '../../../service/OverviewService';
import Spinner from '../../../components/common/Spinner';
import './index.css';
import NotFoundImage from '../../../assets/images/no-data-gorseli.png';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

const PhysicalModuleMeasures = ({ t }) => {
  // const classes = useStyles();
  // let history = useHistory();
  // let {path, url} = useRouteMatch();

  const [detail, setDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const params = useParams();
  const appointmentId = parseInt(params.id);

  useLayoutEffect(() => {
    setLoading(true);
    OverviewService.getDomainModulScore(appointmentId).then((res) => {
      if (res != null && res?.data != null) {
        let modules = res.data.modules.filter((m) => m.moduleId === 2);
        let domains = modules[0]?.domains;
        if (domains != null) {
          setDetail(domains);
          setLoading(false);
        } else {
          setDisplay(true);
          setLoading(false);
        }
      }
    });
  }, [appointmentId]);

  const Image = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <img style={{ maxWidth: '600px' }} src={NotFoundImage} alt="" />
        <h1 style={{ fontWeight: 'bold' }}>{t('data_not_found')}</h1>
      </div>
    );
  };

  const resume = detail.map((dataIn, index) => {
    return (
      <>
        <Spinner loading={loading} />
        <div className="container-physical-module" key={index}>
          <h3 style={{ fontSize: '18px' }} className="domainName">
            {dataIn.domainName}
          </h3>
          <ul className="domainList">
            {dataIn.subdomains[0].metrics.map((metric) => (
              <div className="flexItems">
                <li className="friendlyName">
                  {metric.userFriendlyName == null
                    ? `${t('Neck_Rotation_Right')}`
                    : metric.userFriendlyName}
                </li>
                <li className="metricResult">{metric.metricResult}</li>
              </div>
            ))}
          </ul>
        </div>
      </>
    );
  });

  return (
    <>
      <h1 style={{ fontSize: '20px', fontWeight: 'bold' }}>
        {t('physical_module_measures')}
      </h1>
      {resume}
      {display && <Image />}
    </>
  );
};

export default withTranslation()(PhysicalModuleMeasures);
