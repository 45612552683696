import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import CalendarPicker from '@mui/lab/CalendarPicker'
import LayoutComponent from '../components/LayoutComponent'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import CustomizedTreeView from '../components/common/CustomizedTreeView'

const useStyles = makeStyles({
  root: {
    background: '#ffffff',
    position: 'relative',
    margin: '0 !important',
    marginBottom: '30px !important',
  },
})

const ExpertPhysio = () => {
  const classes = useStyles()
  const [date, setDate] = React.useState(new Date())
  return (
    <LayoutComponent>
      <div className="container xl">
        <div className="sidebar short">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CalendarPicker
              className={classes.root}
              date={date}
              onChange={(newDate) => setDate(newDate)}
            />
          </LocalizationProvider>
          <div className="user-infos">
            <CustomizedTreeView className="treeview" />
          </div>
        </div>
        <div className="reviews">
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cumque,
            ducimus voluptas officia vel atque repellat eum voluptatem mollitia
            magni temporibus laboriosam ab dolor cupiditate corrupti ipsa totam
            praesentium suscipit esse.
          </p>
        </div>
      </div>
    </LayoutComponent>
  )
}

export default ExpertPhysio
