import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UserCustomNavbar from './UserCustomNavbar';

const useStyles = makeStyles((theme) => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
}));

const LayoutComponent = ({ children, ...props }) => {
  const classes = useStyles();
  const customNavbarMessage = props.navbar;
  const IKManagementMessage = props.IKNavbar;
  return (
    <>
      {customNavbarMessage ? (
        <UserCustomNavbar id={customNavbarMessage} IK={IKManagementMessage} />
      ) : (
        <Navbar />
      )}

      <Box
        container
        className={classes.markdown}
        style={{ marginTop: 68, minHeight: '83.5vh' }}
      >
        {children}
      </Box>
      <Footer />
    </>
  );
};

export default LayoutComponent;
