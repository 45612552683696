import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
	Container,
	Grid,
	Hidden,
	Step,
	Box,
	StepLabel,
	Stepper,
} from '@material-ui/core';
import LayoutComponent from '../components/LayoutComponent';
import AuthenticationService from '../service/AuthenticationService';
import axios from 'axios';
import { MainButton } from '../components/common/MainButton';
import PrescreeningForm from './PrescreeningForm';
import store from '../Store';
import { useSnapshot } from 'valtio';
import DemographicsForm from './DemographicsForm';
import Welcome from './Welcome';
import DemographicsService from '../service/DemographicsService';
import { DemographicsInfo } from '../components/DemographicsInfo';
import UserService from '../service/UserService';
import { DemographicsUpdateForm } from './DemographicsUpdateForm';
import { DoctorDemographicsInfo } from '../components/DoctorDemographicsInfo';
import WelcomeDoctor from './WelcomeDoctor';
import { DoctorDemographicsForm } from './DoctorDemographicsForm';
import DoctorDemographicsService from '../service/DoctorDemographicsService';
import { DoctorDemographicsUpdateForm } from './DoctorDemographicsUpdateForm';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PrescreeningFormSummary from './PrescreeningFormSummary';
import Spinner from '../components/common/Spinner';
const useStyles = makeStyles((theme) => ({
	stepper: {
		backgroundColor: 'transparent',
	},
	container: {
		paddingLeft: 100,
	},
}));
const Home = ({ t }) => {
	let history = useHistory();
	const [demographicsExists, setDemographicsExists] = useState(false);
	const [doctorDemographicsExists, setDoctorDemographicsExists] =
		useState(false);
	const [loading, setLoading] = useState(false);
	const [prescreening, setPrescreening] = useState({});
	const user = AuthenticationService.getLoggedInUser();
	const classes = useStyles();
	const DemographicsInfo = React.lazy(() =>
		import('../components/DemographicsInfo')
	);
	const DemographicsUpdateForm = React.lazy(() =>
		import('./DemographicsUpdateForm')
	);
	const snapshot = useSnapshot(store);
	if (
		AuthenticationService.isAdmin() ||
		AuthenticationService.isOperator() ||
		AuthenticationService.isExpert()
	) {
		history.push('/panel');
	}

	useEffect(() => {
		setLoading(true);
		// store.steps.current = 0;
		// store.isEditMode = false;
		const user = AuthenticationService.getLoggedInUser();

		if (!AuthenticationService.isDoctor()) {
			DemographicsService.getDemographicsInfoByUserId(user.userId)
				.then((res) => {
					setDemographicsExists(true);
					if (!store.isEditMode) {
						store.steps.current = 1;
					}

					return res.data;
				})
				.then((response) => {
					axios
						.get('/users/prescreening', {
							params: {
								userId: user.userId,
							},
						})
						.then((res) => {
							if (!!res.data.data) {
								store.pageTitle = `${t('preview_form')}`;
								setPrescreening(res.data);
							}
							setLoading(false);
						})
						.catch((err) => {
							console.log(err);
							setLoading(false);
						});
				})
				.catch((err) => {
					if (err?.response?.status === 400) {
						store.steps.current = 0;
						setLoading(false);
					}
				});
		} else {
			DoctorDemographicsService.getDoctorDemographicsInfo().then((res) => {
				setDoctorDemographicsExists(true);
			});
		}
	}, []);
	const switchComponent = (step) => {
		switch (step) {
			case -1:
				return <Welcome />;
			case 0:
				store.pageTitle = `${t('demographic_information')}`;
				if (snapshot.isEditMode && !AuthenticationService.isDoctor()) {
					return (
						demographicsExists && (
							<DemographicsUpdateForm userId={user.userId} />
						)
					);
				} else if (snapshot.isEditMode && AuthenticationService.isDoctor()) {
					return (
						doctorDemographicsExists && (
							<DoctorDemographicsUpdateForm userId={user.userId} />
						)
					);
				} else {
					if (doctorDemographicsExists && AuthenticationService.isDoctor()) {
						return <DoctorDemographicsInfo />;
					} else if (demographicsExists) {
						return <DemographicsForm />;
					} else if (AuthenticationService.isDoctor()) {
						return <DoctorDemographicsForm />;
					} else {
						return <DemographicsForm />;
					}
				}

			default:
				if (AuthenticationService.isDoctor()) {
					return <WelcomeDoctor />;
				} else if (snapshot.isEditMode && !AuthenticationService.isDoctor()) {
					<PrescreeningFormSummary />;
				} else if (demographicsExists && step === 0) {
					return <DemographicsForm />;
				} else {
					return (
						<PrescreeningForm
							prescreening={prescreening}
							demographicsExists={demographicsExists}
							isAdminPanel={false}
							user={null}
						/>
					);
				}
		}
	};

	return (
		<>
			<Spinner loading={loading} />
			<LayoutComponent>
				{!loading && (
					<Container classes={classes.container} maxWidth={false}>
						<Grid container justifyContent='center'>
							<Hidden mdDown>
								<Grid item md={2}>
									<Stepper
										className={classes.stepper}
										activeStep={snapshot.steps.current}
										orientation='vertical'
									>
										{/*<Step key={0}>*/}
										{/*  <StepLabel>{t('welcome')}</StepLabel>*/}
										{/*</Step>*/}
										<Step key={0}>
											<StepLabel>{t('demographicsInfo')}</StepLabel>
										</Step>
										{!AuthenticationService.isDoctor() && (
											<Step key={1}>
												<StepLabel>{t('life_style')}</StepLabel>
											</Step>
										)}
										{!AuthenticationService.isDoctor() && (
											<Step key={2}>
												<StepLabel>{t('daily_func')}</StepLabel>
											</Step>
										)}
										{!AuthenticationService.isDoctor() && (
											<Step key={3}>
												<StepLabel>{t('health_history')}</StepLabel>
											</Step>
										)}
										{!AuthenticationService.isDoctor() && (
											<Step key={4}>
												<StepLabel>{t('individual_assessment')}</StepLabel>
											</Step>
										)}
										{!AuthenticationService.isDoctor() && (
											<Step key={5}>
												<StepLabel>{t('physical_activity')}</StepLabel>
											</Step>
										)}
										{!AuthenticationService.isDoctor() &&
											snapshot.steps.parqPlusVisible && (
												<Step key={6}>
													<StepLabel>{t('physical_activity')}+</StepLabel>
												</Step>
											)}
									</Stepper>
								</Grid>
							</Hidden>
							{!loading && (
								<Grid item={true} xs={12} md={10}>
									{switchComponent(snapshot.steps.current)}
								</Grid>
							)}
						</Grid>
						<Grid container style={{ marginTop: 24 }}>
							{/*<Grid item={true} xs={12}>*/}
							{/*  {snapshot.steps.current < 1 && (*/}
							{/*    <Box textAlign="right">*/}
							{/*      <MainButton*/}
							{/*        color="primary"*/}
							{/*        disabled={store.steps.current === 0 && !snapshot.consentGiven}*/}
							{/*        onClick={() => {*/}
							{/*          if (store.steps.current === 0 && snapshot.consentGiven) {*/}
							{/*            axios*/}
							{/*              .post('/users/giveConsent')*/}
							{/*              .then(() => {*/}
							{/*                store.steps.current++;*/}
							{/*                UserService.updateUserConsent(true);*/}
							{/*              })*/}
							{/*              .catch((err) => console.log(err));*/}
							{/*          } else {*/}
							{/*            store.steps.current++;*/}
							{/*          }*/}
							{/*        }}*/}
							{/*      >*/}
							{/*        {t('NEXT')}*/}
							{/*      </MainButton>*/}
							{/*    </Box>*/}
							{/*  )}*/}
							{/*</Grid>*/}
						</Grid>
					</Container>
				)}
			</LayoutComponent>
		</>
	);
};

export default withTranslation()(Home);
