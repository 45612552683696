import { proxy } from 'valtio';

const store = proxy({
  dashboardActiveLinkIndex: 0,
  activeTabIndex: 0,
  pageTitle: 'Hoşgeldiniz',
  steps: {
    parqPlusVisible: false,
    totalCount: 6,
    current: 0,
  },
  userId: null,
  surveyReadOnlyModalVisible: false,
  surveyCompleted: false,
  updatedSurveyData: null,
  consentGiven: null,
  isEditMode: false,
  isLoading: false,
  firstSelectedAppointment: null,
  secondSelectedAppointment: null,
  lang: localStorage.getItem('i18nextLng'),
});

export default store;
