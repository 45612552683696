import React, { useState, useEffect } from 'react';
import logoImage from '../../assets/images/neOAUVRALogo.png';
import { withTranslation } from 'react-i18next';
import * as am5 from '@amcharts/amcharts5/index';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useHistory } from 'react-router-dom';
const ScoreDonutChart = ({
  t,
  module,
  appointment,
  notUser,
  isDisplayDonutChart,
}) => {
  const history = useHistory();
  useEffect(() => {
    if (module.modules && isDisplayDonutChart) {
      am5.addLicense('AM5C361499357');
      var root = am5.Root.new('chartdiv');

      root.setThemes([am5themes_Animated.new(root)]);

      var chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          layout: root.verticalLayout,
          innerRadius: am5.percent(66),
        })
      );

      var series = chart.series.push(
        am5percent.PieSeries.new(root, {
          valueField: 'moduleScore2',
          categoryField: `${t('moduleName')}`,
          alignLabels: false,
        })
      );

      series.labels.template.setAll({
        textType: 'circular',
        centerX: 0,
        centerY: 0,
        radius: 32,
        inside: true,
        fill: am5.color('#ffffff'),
        fontSize: 32,
        text: `{donutText}`,
        // text: `{category} - {value}`,
      });
      series.slices.template.setAll({
        fontSize: 20,
        templateField: 'columnSettings',
      });

      series.slices.template.events.on('click', function (ev) {
        if (notUser === 'user') {
          if (ev.target.dataItem.dataContext.moduleId) {
            history.push({
              pathname: '/overview',
              state: {
                id: ev.target.dataItem.component.data.values[0].moduleId,
                name: `${t(
                  ev.target.dataItem.component.data.values[0].moduleName
                )}`,
                data: ev.target.dataItem.component.data.values[0],
              },
              search: '?guid=' + appointment,
            });
          }
        } else {
          if (ev.target.dataItem.dataContext.moduleId) {
            history.push({
              pathname: '/overview/' + appointment,
              state: {
                id: ev.target.dataItem.component.data.values[0].moduleId,
                name: `${t(
                  ev.target.dataItem.component.data.values[0].moduleName
                )}`,
                data: ev.target.dataItem.component.data.values[0],
              },
            });
          }
        }

        series.slices.each(function (slice) {
          if (slice != ev.target && slice.get('active')) {
            slice.set('active', false);
          }
        });
      });
      if (module.neoauvraScore) {
        let label = series.children.push(
          am5.Label.new(root, {
            text: module.neoauvraScore ?? '',
            fontSize: 80,
            centerX: am5.percent(60),
            centerY: am5.percent(50),
          })
        );
        let label2 = series.children.push(
          am5.Label.new(root, {
            text: '/ 1000',
            fontSize: 26,
            centerX: am5.percent(-30),
            centerY: am5.percent(-65),
            marginLeft: 30,
            marginTop: 10,
          })
        );
      } else {
        let label = series.children.push(
          am5.Label.new(root, {
            text: `${t('not_calculated')}`,
            fontSize: 40,
            centerX: am5.percent(50),
            centerY: am5.percent(50),
          })
        );
      }

      // if (!isDisplayDonutChart && isDisplayDonutChart != null) {
      //   let label = series.children.push(
      //     am5.Label.new(root, {
      //       text: `Uzman Onayı Bekleniyor`,
      //       fontSize: 40,
      //       centerX: am5.percent(50),
      //       centerY: am5.percent(50),
      //     })
      //   );
      // }

      const mes = module.modules;

      const total = [];

      if (mes.length > 0) {
        for (let i = 0; i < mes.length; i++) {
          const modules = mes[i];
          if (modules?.moduleScore) {
            let data = {
              moduleId: modules?.moduleId,
              moduleName: t(modules?.moduleName),
              moduleScore: modules?.moduleScore,
              moduleScore2: 300,
              forcedTreeColor: modules?.forcedTreeColor,
              domains: modules?.domains,
              donutText:
                `${t(modules?.moduleName)} - ${modules?.moduleScore}` ?? '',
              columnSettings: {
                fill: am5.color(modules?.forcedTreeColor),
              },
            };
            total.push(data);
          } else {
            let data = {
              // moduleName: modules?.moduleName,
              moduleId: modules?.moduleId,
              forcedTreeColor: modules?.forcedTreeColor,
              columnSettings: {
                fill: am5.color(modules?.forcedTreeColor),
              },
            };
            total.push(data);
          }
        }
      }
      series.data.setAll(total);
      series.appear(1000, 100);
    } else if (module.modules && !isDisplayDonutChart) {
      am5.addLicense('AM5C361499357');
      var root = am5.Root.new('chartdiv');

      root.setThemes([am5themes_Animated.new(root)]);

      var chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          layout: root.verticalLayout,
          innerRadius: am5.percent(66),
        })
      );

      var series = chart.series.push(
        am5percent.PieSeries.new(root, {
          valueField: 'moduleScore',
          categoryField: `${t('moduleName')}`,
          alignLabels: false,
        })
      );

      let label = series.children.push(
        am5.Label.new(root, {
          text: `${t('Waiting For Expert Approval')}`,
          fontSize: 40,
          centerX: am5.percent(50),
          centerY: am5.percent(50),
        })
      );
    }

    return () => {
      maybeDisposeRoot2('chartdiv');
    };
  }, [module]);

  const maybeDisposeRoot2 = (divId) => {
    am5.array.each(am5.registry.rootElements, function (root) {
      if (root.dom.id === divId) {
        root.dispose();
      }
    });
  };
  return (
    <div className='flex flex-column' style={{ height: '800px' }}>
      <div className='neo-score h-full'>
        <div className='flex'>
          <div className='neoLogo'>
            <img src={logoImage} alt='' />
          </div>
        </div>
        <div className='text-items'>
          <h1>{t('score')}</h1>
          {/* <b>{module?.neoauvraScore ?? 0}</b> */}
        </div>
        {module && <div id='chartdiv' className='home-chartdiv'></div>}
      </div>
    </div>
  );
};

export default withTranslation()(ScoreDonutChart);
