import axios from 'axios'
import AuthenticationService, {
  USER_SESSION_ATTRIBUTE_NAME,
} from './AuthenticationService'

class UserService {
  getUserById(userId) {
    const userDetailsRequest = {
      userId,
      searchType: 'USER_ID',
    }

    return axios.post('/users/getUser', userDetailsRequest)
  }

  getUserCount() {
    return axios.get('/users/count')
  }

  updateUserConsent(userConsent) {
    const user = AuthenticationService.getLoggedInUser()
    user.consentGiven = userConsent
    localStorage.setItem(USER_SESSION_ATTRIBUTE_NAME, JSON.stringify(user))
  }

  getPrescreeningFormData(userId) {
    return axios.get('/users/prescreening', { params: { userId } })
  }
}

export default new UserService()
