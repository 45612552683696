import React from 'react';
import { withTranslation } from 'react-i18next';

const UserInfoCard = ({ t, user }) => {
  return (
    <div className="sidebar-top">
      <ul className="user-info">
        <li>{user?.fullName} </li>
        <li className="userInfoList">{user?.gender==='Erkek' ? t('Male') : user?.gender==='Kadın' ? t('Female') : t('no_gender')}</li>
        <li className="userInfoList">{user?.age ?? t('no_age')}</li>
      </ul>
    </div>
  );
};

export default withTranslation()(UserInfoCard);
