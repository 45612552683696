import React from 'react'
import { Slash } from './Slash'
import { Box, Typography } from '@material-ui/core'

const PageTitle = ({ children, compact, theme }) => {
  return (
    <>
      <Typography variant={compact ? 'h6' : 'h4'} gutterBottom>
        <Slash compact={compact}>/</Slash>
        {theme && theme === 'dark' ? (
          <Box color="#fff">
            <Typography variant="body1" gutterBottom>
              {children}
            </Typography>
          </Box>
        ) : (
          children
        )}
      </Typography>
    </>
  )
}

export default PageTitle
