import React, { useEffect, useState, useRef, Suspense } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/tr';
import DialogComponent from '../components/common/DialogComponent';
import { useSnackbar } from 'notistack';
import Spinner from '../components/common/Spinner';
import CreateNewAppointment from '../components/CreateNewAppointment';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import AlertDialog from '../components/DialogComp';
import { Dialog } from 'primereact/dialog';
import { MainButton } from '../components/common/MainButton';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import HomeAdmin from './HomeAdmin';
import AuthenticationService from '../service/AuthenticationService';
import { IconFlagTR, IconFlagDE, IconFlagUS } from 'material-ui-flags';
import IconButton from '@material-ui/core/IconButton';
import store from '../Store';
const useStyles = makeStyles((theme) => ({
	card: {
		padding: 20,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	commChannels: {
		marginTop: theme.spacing(2),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	register: {
		'&:hover': {
			textDecoration: 'underline',
		},
		textTransform: 'none',
	},
}));

const Users = ({ t }) => {
	const [users, setUsers] = useState([]);
	const [filteredData, setFilteredData] = useState([]);

	const [loading, setLoading] = useState(false);
	const [date, setDate] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [visible, setVisible] = useState(false);
	const [lang, setLang] = useState('tr');

	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		userId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		name: { value: null, matchMode: FilterMatchMode.CONTAINS },
		surname: { value: null, matchMode: FilterMatchMode.CONTAINS },
		customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
		email: { value: null, matchMode: FilterMatchMode.CONTAINS },
		mobilePhone: { value: null, matchMode: FilterMatchMode.CONTAINS },
		createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
		active: { value: null, matchMode: FilterMatchMode.CONTAINS },
		completedYesNo: { value: null, matchMode: FilterMatchMode.CONTAINS },
		preferredLanguage: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [displayResponsive, setDisplayResponsive] = useState(false);
	const user = AuthenticationService.getLoggedInUser();
	const toast = useRef(null);
	const appointmentValue = localStorage.getItem('createAppointmentValue');
	const langValue = localStorage.getItem('i18nextLng') ?? 'tr';
	const classes = useStyles();
	const userLoginId = JSON.parse(localStorage.authenticatedUser);

	useEffect(() => {
		fetchUsers();
	}, [langValue]);

	const formatDate = (day) => {
		const date = moment(new Date(day * 1000));
		let time =
			langValue === 'tr'
				? date.locale(`${t(langValue)}`).format('DD MMMM YYYY')
				: date.locale(`${t(langValue)}`).format('MMMM DD YYYY');
		return time;
	};

	const exportExcel = () => {
		import('xlsx').then((xlsx) => {
			const worksheet = xlsx.utils.json_to_sheet(filteredData);
			const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
			const excelBuffer = xlsx.write(workbook, {
				bookType: 'xlsx',
				type: 'array',
			});
			saveAsExcelFile(excelBuffer, 'files');
		});
	};
	const saveAsExcelFile = (buffer, fileName) => {
		import('file-saver').then((module) => {
			if (module && module.default) {
				let EXCEL_TYPE =
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				let EXCEL_EXTENSION = '.xlsx';
				const data = new Blob([buffer], {
					type: EXCEL_TYPE,
				});

				module.default.saveAs(
					data,
					fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
				);
			}
		});
	};
	const fetchUsers = () => {
		setLoading(true);
		if (AuthenticationService.isAdmin()) {
			axios
				.get('/users')
				.then((res) => {
					if (res?.data !== null || res?.data !== undefined) {
						fetchedUserDataFormatter(res.data);
					} else {
						return;
					}
				})
				.catch((err) => console.log(err));
		} else if (AuthenticationService.isOperator()) {
			axios
				.get('/users/getAllUserForOperator/' + userLoginId.userId)
				.then((res) => {
					if (res?.data !== null || res?.data !== undefined) {
						fetchedUserDataFormatter(res.data);
					} else {
						return;
					}
				})
				.catch((err) => console.log(err));
		} else if (AuthenticationService.isExpert()) {
			axios
				.get('/users/getAllUserForExpert/')
				.then((res) => {
					if (res?.data !== null || res?.data !== undefined) {
						fetchedUserDataFormatter(res.data);
					} else {
						return;
					}
				})
				.catch((err) => console.log(err));
		} else {
			axios
				.get('/users/getUsersForLocationAdmin/' + user.userId)
				.then((res) => {
					fetchedUserDataFormatter(res.data);
				})
				.catch((err) => setLoading(false));
		}
	};

	const fetchedUserDataFormatter = (data) => {
		let result = data;
		let total = [];
		for (let i = 0; i < result.length; i++) {
			result[i].completedYesNo = result[i].prescreeningFormCompleted
				? `${t('yes')}`
				: `${t('no')}`;
			let roles = [];
			if (result[i].roles != null) {
				for (let j = 0; j < result[i].roles.length; j++) {
					roles.push(result[i].roles[j].name);
				}
			}
			total.push({
				email: result[i].email,
				appointmentId: result[i].id,
				mobilePhone: '+' + result[i].mobilePrefix + ' ' + result[i].mobilePhone,
				mobilePrefix: result[i].mobilePrefix,
				name: result[i].name,
				surname: result[i].surname,
				date: result[i].createdAt,
				createdAt: formatDate(result[i].createdAt),
				userId: AuthenticationService.isOperator()
					? result[i]?.userID
					: result[i]?.user?.id,
				active: result[i].user?.enabled,
				consentGiven: result[i].user?.consentGiven,
				preferredLanguage: result[i].preferredLanguage,
				completedYesNo: result[i].completedYesNo,
				roles: roles,
				prescreeningFormCompleted: result[i].prescreeningFormCompleted,
				customer: result[i].customer,
				customerName: result[i].customer?.name,
			});
		}
		setUsers(total);
		setFilteredData(total);
		setLoading(false);
	};

	const deleteUser = (id) => {
		axios
			.delete(`/users/deleteUser/${id}`)
			.then(() => {
				fetchUsers();
				toast.current.show({
					severity: 'info',
					summary: `${t('appointment_alert_messages.congratulations')}`,
					detail: `${t('appointment_alert_messages.delete_user_success')}`,
					life: 3000,
				});
			})
			.catch((err) => error(err));
	};

	const enableUser = (id) => {
		axios
			.get(`/users/activateUser/${id}`)
			.then(() => {
				fetchUsers();
			})
			.catch((err) => error(err));
	};

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };
		_filters['global'].value = value;
		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const renderHeader = () => {
		return (
			<div className='clearfix'>
				<div className='float-right p-2'>
					<div className='flex align-items-center export-buttons'>
						{AuthenticationService.isAdmin() && (
							<Button
								type='button'
								icon='pi pi-file-excel'
								onClick={exportExcel}
								className='p-button-success mr-2'
								data-pr-tooltip='XLS'
							/>
						)}
					</div>
				</div>
			</div>
		);
	};

	const accept = (id) => {
		deleteUser(id);
	};

	const reject = (id) => {
		toast.current.show({
			severity: 'warn',
			summary: `${t('appointment_alert_messages.denied')}`,
			detail: `${t('appointment_alert_messages.action_declined')}`,
			life: 3000,
		});
	};
	const error = (err) => {
		toast.current.show({
			severity: 'error',
			summary: `${t('appointment_alert_messages.short_error')}`,
			detail: `${err.response.data}`,
			life: 3000,
		});
	};
	const randevuStr = (
		<svg
			fill='#0099F7'
			height='32'
			viewBox='0 0 20 20'
			width='32'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6.5 2C5.84689 2 5.29127 2.4174 5.08535 3H3.5C2.67157 3 2 3.67157 2 4.5V16.5C2 17.3284 2.67157 18 3.5 18H10.2572C10.0035 17.6929 9.78261 17.3578 9.59971 17H3.5C3.22386 17 3 16.7761 3 16.5V4.5C3 4.22386 3.22386 4 3.5 4H5.08535C5.29127 4.5826 5.84689 5 6.5 5H9.5C10.1531 5 10.7087 4.5826 10.9146 4H12.5C12.7761 4 13 4.22386 13 4.5V9.20703C13.3223 9.11588 13.6566 9.05337 14 9.02242V4.5C14 3.67157 13.3284 3 12.5 3H10.9146C10.7087 2.4174 10.1531 2 9.5 2H6.5ZM6 3.5C6 3.22386 6.22386 3 6.5 3H9.5C9.77614 3 10 3.22386 10 3.5C10 3.77614 9.77614 4 9.5 4H6.5C6.22386 4 6 3.77614 6 3.5ZM10.8536 9.85355C11.0488 9.65829 11.0488 9.34171 10.8536 9.14645C10.6583 8.95118 10.3417 8.95118 10.1464 9.14645L7 12.2929L5.85355 11.1464C5.65829 10.9512 5.34171 10.9512 5.14645 11.1464C4.95118 11.3417 4.95118 11.6583 5.14645 11.8536L6.64645 13.3536C6.84171 13.5488 7.15829 13.5488 7.35355 13.3536L10.8536 9.85355ZM19 14.5C19 16.9853 16.9853 19 14.5 19C12.0147 19 10 16.9853 10 14.5C10 12.0147 12.0147 10 14.5 10C16.9853 10 19 12.0147 19 14.5ZM15 12.5C15 12.2239 14.7761 12 14.5 12C14.2239 12 14 12.2239 14 12.5V14H12.5C12.2239 14 12 14.2239 12 14.5C12 14.7761 12.2239 15 12.5 15H14V16.5C14 16.7761 14.2239 17 14.5 17C14.7761 17 15 16.7761 15 16.5V15H16.5C16.7761 15 17 14.7761 17 14.5C17 14.2239 16.7761 14 16.5 14H15V12.5Z'
				fill='#0099F7'
			/>
		</svg>
	);

	const detailSvg = (prescreeningFormCompleted) => {
		return (
			<svg
				fill={prescreeningFormCompleted ? '#0099F7' : '#EC0044'}
				height='32'
				viewBox='0 0 20 20'
				width='32'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M7.5 7C7.22386 7 7 7.22385 7 7.5C7 7.77614 7.22386 8 7.5 8H12.5C12.7761 8 13 7.77614 13 7.5C13 7.22385 12.7761 7 12.5 7H7.5ZM7 10.5C7 10.2239 7.22386 9.99999 7.5 9.99999H12.1707C12.0602 10.3128 12 10.6494 12 11H7.5C7.22386 11 7 10.7761 7 10.5ZM15 4.5V8C15.3497 8 15.6866 8.0602 16 8.171V4.5C16 3.67157 15.3284 3 14.5 3H14V2.5C14 2.22386 13.7761 2 13.5 2C13.2239 2 13 2.22386 13 2.5V3H10.5V2.5C10.5 2.22386 10.2761 2 10 2C9.72386 2 9.5 2.22386 9.5 2.5V3H7V2.5C7 2.22386 6.77614 2 6.5 2C6.22386 2 6 2.22386 6 2.5V3H5.5C4.67157 3 4 3.67157 4 4.5V16.5C4 17.3284 4.67157 18 5.5 18H10.7509C10.6273 17.8209 10.5169 17.6308 10.4213 17.4302C10.3549 17.2909 10.296 17.1474 10.2449 17H5.5C5.22386 17 5 16.7761 5 16.5V4.5C5 4.22386 5.22386 4 5.5 4H14.5C14.7761 4 15 4.22386 15 4.5ZM7.5 13C7.22386 13 7 13.2238 7 13.5C7 13.7761 7.22386 14 7.5 14H9.5C9.77614 14 10 13.7761 10 13.5C10 13.2238 9.77614 13 9.5 13H7.5ZM17 11C17 10.2597 16.5978 9.61337 16 9.26756C15.7058 9.09739 15.3643 8.99999 15 8.99999C13.8954 8.99999 13 9.89542 13 11C13 12.1046 13.8954 13 15 13C15.3643 13 15.7058 12.9026 16 12.7324C16.5978 12.3866 17 11.7403 17 11ZM11 15.5C11 15.672 11.0109 15.8388 11.0321 16C11.0797 16.3621 11.1793 16.6964 11.324 17C11.5329 17.4383 11.8358 17.8127 12.2121 18.1148C12.9534 18.7098 13.9469 19 15 19C16.0531 19 17.0466 18.7098 17.7879 18.1148C18.541 17.5103 19 16.6161 19 15.5C19 14.6716 18.3284 14 17.5 14L12.5 14C11.6716 14 11 14.6716 11 15.5Z'
					fill={prescreeningFormCompleted ? '#0099F7' : '#EC0044'}
				/>
			</svg>
		);
	};

	const deleteSvg = (
		<svg
			fill='#EC0044'
			height='32'
			viewBox='0 0 20 20'
			width='32'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M11.5 4C11.5 3.17157 10.8284 2.5 10 2.5C9.17157 2.5 8.5 3.17157 8.5 4H7.5C7.5 2.61929 8.61929 1.5 10 1.5C11.3807 1.5 12.5 2.61929 12.5 4H17C17.2761 4 17.5 4.22386 17.5 4.5C17.5 4.77614 17.2761 5 17 5H16.446L15.1499 16.2292C15.0335 17.2384 14.179 18 13.1631 18H6.83688C5.821 18 4.9665 17.2384 4.85006 16.2292L3.553 5H3C2.75454 5 2.55039 4.82312 2.50806 4.58988L2.5 4.5C2.5 4.22386 2.72386 4 3 4H11.5ZM15.438 5H4.561L5.84347 16.1146C5.90169 16.6192 6.32894 17 6.83688 17H13.1631C13.6711 17 14.0983 16.6192 14.1565 16.1146L15.438 5ZM8.5 7.5C8.74546 7.5 8.94961 7.65477 8.99194 7.85886L9 7.9375V14.0625C9 14.3041 8.77614 14.5 8.5 14.5C8.25454 14.5 8.05039 14.3452 8.00806 14.1411L8 14.0625V7.9375C8 7.69588 8.22386 7.5 8.5 7.5ZM11.5 7.5C11.7455 7.5 11.9496 7.65477 11.9919 7.85886L12 7.9375V14.0625C12 14.3041 11.7761 14.5 11.5 14.5C11.2545 14.5 11.0504 14.3452 11.0081 14.1411L11 14.0625V7.9375C11 7.69588 11.2239 7.5 11.5 7.5Z'
				fill='#EC0044'
			/>
		</svg>
	);

	const confirm = (id) => {
		confirmDialog({
			message: `${id} ${t('appointment_alert_messages.delete_user_question')}`,
			header: `${t('appointment_alert_messages.confirmation')}`,
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
			accept: () => accept(id),
			reject: () => reject(),
		});
	};

	const confirmEnabled = (id) => {
		confirmDialog({
			message: `${id} ${t(
				'appointment_alert_messages.activated_user_question'
			)}`,
			header: `${t('appointment_alert_messages.confirmation')}`,
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
			accept: () => accept2(id),
			reject: () => reject(),
		});
	};

	const accept2 = (id) => {
		toast.current.show({
			severity: 'info',
			summary: `${t('appointment_alert_messages.confirmation')}`,
			detail: `${t('appointment_alert_messages.activated_user_success')}`,
			life: 3000,
		});
		enableUser(id);
	};

	const actionUserEdit = (rowData) => {
		return (
			<>
				<AlertDialog
					fetchUsers={fetchUsers}
					userInfo={rowData.userId}
				></AlertDialog>
			</>
		);
	};

	const isEnabledUser = (rowData) => {
		return (
			<div className='text-center'>
				{rowData.active ? (
					<i
						className='pi pi-check '
						style={{ fontSize: '2em', color: 'green' }}
					></i>
				) : (
					<Button onClick={() => confirmEnabled(rowData.userId)}>
						{t('activate')}
					</Button>
				)}
			</div>
		);
	};

	const styles = {
		header: {
			textAlign: 'center',
		},
	};

	const RegisterDialog = () => {
		return (
			<Dialog
				onHide={() => setDisplayResponsive(false)}
				header={t('information')}
				headerStyle={{ textAlign: 'center' }}
				headerClassName={styles.header}
				footer={renderFooter}
				visible={displayResponsive}
				breakpoints={{ '960px': '75vw', '640px': '100vw' }}
				style={{ width: '50vw' }}
			>
				<p className='text-center'>
					{t('create_appointments_only_user_message')}
				</p>
			</Dialog>
		);
	};
	const reload = () => {
		setVisible(!visible);
		fetchUsers();
	};

	const renderFooter = () => {
		return (
			<div style={{ textAlign: 'center' }}>
				<MainButton
					type='submit'
					variant='contained'
					color='primary'
					className={classes.submit}
					onClick={() => setDisplayResponsive(false)}
					autoFocus
				>
					{t('ok')}
				</MainButton>
			</div>
		);
	};

	const actionBodyTemplate = (rowData, props) => {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-around',
					alignItems: 'center',
				}}
			>
				{appointmentValue === '1' ? (
					<>
						{(AuthenticationService.isAdmin() ||
							AuthenticationService.isOperator() ||
							AuthenticationService.isLocationAdmin()) && (
							<Button
								onClick={() => setDisplayResponsive(true)}
								className='p-button-text'
								tooltip={t('create_appointment')}
							>
								{randevuStr}
							</Button>
						)}
					</>
				) : (
					<>
						{(AuthenticationService.isAdmin() ||
							AuthenticationService.isOperator() ||
							AuthenticationService.isLocationAdmin()) && (
							<DialogComponent
								visible={visible}
								maxWidth={false}
								title={t('appointment_id')}
								buttonText={t('create_appointment')}
								buttonColor='secondary'
								svg={randevuStr}
							>
								<CreateNewAppointment
									userId={rowData.userId}
									userEmail={rowData.email}
									onCreate={() => reload()}
								/>
							</DialogComponent>
						)}
					</>
				)}
				{(AuthenticationService.isAdmin() ||
					AuthenticationService.isExpert() ||
					AuthenticationService.isLocationAdmin()) && (
					<DialogComponent
						visible={visible}
						title={t('demographic_information')}
						buttonText={t('detail')}
						buttonColor='secondary'
						svg={detailSvg(rowData.prescreeningFormCompleted)}
					>
						<Suspense fallback={<div>Yükleniyor</div>}>
							<HomeAdmin userData={rowData} />
						</Suspense>
					</DialogComponent>
				)}
				{(AuthenticationService.isAdmin() ||
					AuthenticationService.isLocationAdmin()) && (
					<Button
						className='p-button-text'
						onClick={() => confirm(rowData.userId)}
						tooltip={t('delete_user')}
					>
						{deleteSvg}
					</Button>
				)}
			</div>
		);
	};

	const selectedCountryTemplate = (rowData, option, props) => {
		if (option) {
			return (
				<div
					className='country-item country-item-value'
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-around',
						alignItems: 'center',
						paddingRight: '20px',
					}}
				>
					{rowData.preferredLanguage === 'en' ? (
						<IconButton className={classes.muiIcon}>
							<IconFlagUS />
						</IconButton>
					) : (
						<IconButton className={classes.muiIcon}>
							<IconFlagTR />
						</IconButton>
					)}
					<div>{rowData.preferredLanguage}</div>
				</div>
			);
		}
	};

	return (
		<div>
			<ConfirmDialog />
			<Spinner loading={loading} />
			<Toast ref={toast} />
			<RegisterDialog />
			<div className='card card-custom gutter-b'>
				<div className='card-header'>
					<div className='card-title'>
						<h3 className='card-label'>{t('user_list')}</h3>
					</div>
					{(AuthenticationService.isAdmin() ||
						AuthenticationService.isLocationAdmin()) && (
						<div className='card-toolbar'>
							<Link to='/panel/kullanicilar/0'>
								<Button
									type='button'
									icon='pi pi-plus'
									label={t('add_new_user')}
									className='p-button-raised p-button-rounded p-button-outlined mr-3'
								/>
							</Link>
						</div>
					)}
				</div>
				<div className='card-body p-0'>
					<DataTable
						value={users}
						paginator
						showGridlines
						responsiveLayout='scroll'
						header={renderHeader}
						rows={10}
						paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
						rowsPerPageOptions={[10, 25, 50]}
						dataKey='id'
						rowHover
						onValueChange={(filteredData) => setFilteredData(filteredData)}
						filters={filters}
						filterDisplay='row'
						globalFilterFields={[
							'id',
							'name',
							'surname',
							'email',
							'mobilePhone',
							'customerName',
							'createdAt',
						]}
						emptyMessage={t('no_records_found')}
						currentPageReportTemplate={t('currentPageReportTemplate')}
					>
						<Column
							headerStyle={{ width: '12rem', textAlign: 'center' }}
							header={t('action')}
							body={actionBodyTemplate}
							className='actionBody'
							bodyStyle={{ textAlign: 'center' }}
						/>

						{(AuthenticationService.isAdmin() ||
							AuthenticationService.isLocationAdmin()) && (
							<Column
								header={t('user_id')}
								sortable={true}
								field='userId'
								filter
								body={actionUserEdit}
								style={{
									maxWidth: '100px',
									textDecoration: 'underline',
									cursor: 'pointer',
								}}
							></Column>
						)}
						{AuthenticationService.isExpert() ||
							(AuthenticationService.isOperator() && (
								<Column
									header={t('user_id')}
									sortable={true}
									field='userId'
									filter
									style={{
										maxWidth: '100px',
										textDecoration: 'none',
										cursor: 'default',
									}}
								></Column>
							))}

						{(AuthenticationService.isAdmin() ||
							AuthenticationService.isLocationAdmin()) && (
							<Column
								header={t('name')}
								sortable={true}
								field='name'
								filter
								style={{ minWidth: '100px' }}
							></Column>
						)}
						{(AuthenticationService.isAdmin() ||
							AuthenticationService.isLocationAdmin()) && (
							<Column
								header={t('surname')}
								sortable={true}
								field='surname'
								filter
								style={{ minWidth: '100px' }}
							></Column>
						)}
						<Column
							header={t('is_prescreening_from_exits')}
							sortable={true}
							field='completedYesNo'
							filter
							style={{ minWidth: '100px' }}
							// body={isCompletedForm}
						></Column>
						{(AuthenticationService.isAdmin() ||
							AuthenticationService.isLocationAdmin()) && (
							<Column
								header={t('email')}
								field='email'
								sortable={true}
								filter
								style={{ maxWidth: '100px' }}
							></Column>
						)}
						{(AuthenticationService.isAdmin() ||
							AuthenticationService.isLocationAdmin()) && (
							<Column
								header={t('mobile_phone')}
								sortable={true}
								field='mobilePhone'
								filter
								style={{ minWidth: '160px' }}
							></Column>
						)}
						{AuthenticationService.isAdmin() && (
							<Column
								header={t('customer')}
								sortable={true}
								field='customerName'
								filter
								//body={customerTemplate}
								style={{
									maxWidth: '100px',
									textDecoration: 'underline',
									cursor: 'pointer',
								}}
							></Column>
						)}
						<Column
							header={t('register_date')}
							sortable={true}
							field='createdAt'
							sortField='date'
							filter
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('preferred_language')}
							sortable={true}
							field='preferredLanguage'
							filter
							style={{ maxWidth: '70px' }}
							body={selectedCountryTemplate}
							bodyStyle={{ textAlign: 'center' }}
						></Column>
						{(AuthenticationService.isAdmin() ||
							AuthenticationService.isLocationAdmin()) && (
							<Column
								header={t('is_active')}
								sortable={true}
								field='active'
								body={isEnabledUser}
								style={{ minWidth: '120px' }}
							></Column>
						)}
					</DataTable>
				</div>
			</div>
		</div>
	);
};

export default withTranslation()(Users);
