import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/tr';
import PageTitle from './../components/PageTitle';
import CalculateScoreModal from '../components/CalculateScoreModal';
import Datatable from './../components/common/Datatable';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { MTableToolbar } from 'material-table';
import AuthenticationService from '../service/AuthenticationService';
import { withTranslation } from 'react-i18next';

const DoctorsPatients = ({ t }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    const userData = AuthenticationService.getLoggedInUser();
    const userId = userData.userId;
    axios
      .get('/users/getDoctorsPatients', { params: { userId } })
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      field: 'user',
      title: `${t('user_id')}`,
      render: (rowData) => rowData.user.id,
      customSort: (a, b) => a.user.id - b.user.id,
    },
    { field: 'name', title: `${t('name')}` },
    { field: 'surname', title: `${t('surname')}` },
    { field: 'email', title: `${t('email')}` },
    { field: 'mobilePhone', title: `${t('mobile_phone')}` },
    {
      field: 'createdAt',
      title: `${t('register_date')}`,
      defaultSort: 'asc',
      render: (rowData) => {
        return moment.unix(rowData.createdAt).format('DD.MM.YYYY');
      },
    },
  ];

  return (
    <div>
      <PageTitle>{t('your_patients')}</PageTitle>
      <Datatable
        style={{ padding: 10 }}
        options={{
          showTitle: false,
          pageSize: 10,
          sorting: true,
          searchFieldVariant: 'outlined',
          searchFieldAlignment: 'right',
          pageSizeOptions: [10, 20, 50],
          actionsColumnIndex: -1,
        }}
        data={users}
        columns={columns}
        actions={[
          {
            icon: 'save',
            tooltip: 'Save User',
          },
        ]}
        components={{
          Toolbar: (props) => {
            // This will let you use your own Title while keeping the search
            const propsCopy = { ...props };
            // Hide default title
            propsCopy.showTitle = false;
            return (
              <div>
                <MTableToolbar {...propsCopy} />
                <Grid style={{ padding: 10 }}>
                  <Typography align="right" variant="body1">
                    {t('total_users')} :{' '}
                    <Box display="inline-block" fontWeight="bold">
                      {props.data.length}
                    </Box>
                  </Typography>
                </Grid>
                <Divider />
              </div>
            );
          },
          Action: (props) => {
            if (props.action.icon === 'save') {
              return <CalculateScoreModal userId={props.data.user.id} />;
            }
          },
        }}
      />
    </div>
  );
};

export default withTranslation()(DoctorsPatients);
