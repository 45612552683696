import React, { useCallback, useEffect, useRef, useState } from 'react';
import Spinner from '../common/Spinner';

import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import ButtonMaterial from '@mui/material/Button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import CustomerSarfReportParametersTemplateService from '../../service/CustomerSarfReportParametersTemplateService';

const UpdateSarfParameterTemplateModal = ({
	customerId,
	t,
	getAllSarfReports,
	updateSarfParameterTemplateOpen,
	handleCloseUpdateSarfParameterTemplateModal,
	getAllSarfReportParametersTemplates,
	langValue,
	selectedSarfReportTemplate,
	setLoading,
}) => {
	const toast = useRef(null);
	const toastInside = useRef(null);
	const [description, setDescription] = useState('');
	const [paramaterTemplateName, setParamaterTemplateName] = useState('');

	const [sarfTemplateType, setSarfTemplateType] = useState([]);
	const [selectedSarfTemplateType, setSelectedSarfTemplateType] =
		useState(null);

	useEffect(() => {
		let unmounted = false;
		if (updateSarfParameterTemplateOpen) {
			setSarfTemplateType([
				{ id: 1, name: `${t('neo_standard_general')}` },
				{ id: 2, name: `${t('neo_standard_other')}` },
				{ id: 3, name: `${t('neo_special_for_customer')}` },
			]);
		}
		return () => {
			unmounted = true;
		};
	}, [langValue, updateSarfParameterTemplateOpen]);
	useEffect(() => {
		let unmounted = false;
		if (
			(selectedSarfReportTemplate != null ||
				selectedSarfReportTemplate != undefined) &
			updateSarfParameterTemplateOpen
		) {
			getSarfParamaterTemplateById(selectedSarfReportTemplate.id);
		} else {
			return;
		}
		return () => {
			unmounted = true;
		};
	}, [selectedSarfReportTemplate, updateSarfParameterTemplateOpen]);

	const getSarfParamaterTemplateById = (id) => {
		CustomerSarfReportParametersTemplateService.getById(id)
			.then((res) => {
				setDescription(res?.data?.description);
				setParamaterTemplateName(res?.data?.name);
				if (res?.data?.templateType == 1) {
					setSelectedSarfTemplateType({
						id: 1,
						name: `${t('neo_standard_general')}`,
					});
				} else if (res?.data?.templateType == 2) {
					setSelectedSarfTemplateType({
						id: 2,
						name: `${t('neo_standard_other')}`,
					});
				} else if (res?.data?.templateType == 3) {
					setSelectedSarfTemplateType({
						id: 3,
						name: `${t('neo_special_for_customer')}`,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const updateSarfParameterTemplate = () => {
		if (
			paramaterTemplateName == '' ||
			paramaterTemplateName == null ||
			paramaterTemplateName == undefined
		) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_sarf_report_parameter_template')}`,
				life: 3000,
			});
			return;
		}
		if (paramaterTemplateName.trim() == '') {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_sarf_report_parameter_template')}`,
				life: 3000,
			});
			return;
		}

		if (paramaterTemplateName.trim().length > 255) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_category_length')}`,
				life: 3000,
			});
			return;
		}
		if (customerId == null || customerId == undefined) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('appointment_alert_messages.transaction_error')}`,
				life: 3000,
			});

			return;
		}

		if (
			selectedSarfTemplateType == null ||
			selectedSarfTemplateType == undefined
		) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_sarf_parameter_template_type')}`,
				life: 3000,
			});

			return;
		}
		const trimmedDescription = description ? description.trim() : '';

		const dataSent = {
			id: selectedSarfReportTemplate?.id,
			customer: { id: customerId },
			description: trimmedDescription,
			name: paramaterTemplateName.trim(),
			templateType: selectedSarfTemplateType?.id,
		};
		setLoading(true);
		CustomerSarfReportParametersTemplateService.update(dataSent)
			.then((res) => {
				setTimeout(() => {
					handleCloseUpdateSarfParameterTemplateModal();
					setLoading(false);
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
						life: 3000,
					});
					setDescription(null);
					setSelectedSarfTemplateType(null);
					setParamaterTemplateName(null);
				}, 2000);
				getAllSarfReportParametersTemplates(customerId);
				getAllSarfReports(customerId);
			})
			.catch((error) => {
				toast.current.show({
					severity: 'error',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: error.response.data,
					life: 3000,
				});
			});
	};

	return (
		<>
			<Toast ref={toast} />

			<Dialog
				open={updateSarfParameterTemplateOpen}
				fullWidth={true}
				maxWidth={'sm'}
				onClose={() => {
					handleCloseUpdateSarfParameterTemplateModal();
					setDescription(null);
					setParamaterTemplateName(null);
					setSelectedSarfTemplateType(null);
				}}
			>
				<Toast ref={toastInside} />

				<DialogContent
					style={{
						overflowY: 'visible',
						display: 'flex',
						justifyContent: 'start',
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<DialogContentText
						style={{
							marginBottom: '30px',
							textAlign: 'center',
							fontWeight: 'bold',
						}}
					>
						{t('sarf_report_parameter_template_definition')}
					</DialogContentText>

					<FormControl>
						<div
							style={{
								width: '100%',
							}}
						>
							<label htmlFor='inputtext'>
								{t('sarf_parameter_template_name')}
							</label>
							<InputText
								id='inputtext'
								style={{
									width: '100%',
									marginTop: '10px',
									marginBottom: '10px',
								}}
								value={paramaterTemplateName}
								onChange={(e) => setParamaterTemplateName(e.target.value)}
							/>
						</div>
						<div style={{ width: '100%' }}>
							<label htmlFor=''>{t('sarf_report_template_type')}</label>
							<Dropdown
								value={selectedSarfTemplateType}
								style={{
									width: '100%',
									marginTop: '10px',
									marginBottom: '10px',
								}}
								appendTo='self'
								className='p-inputtext-sm'
								onChange={(e) => {
									setSelectedSarfTemplateType(e.value);
								}}
								options={sarfTemplateType}
								optionLabel='name'
							/>
						</div>

						<div style={{ width: '100%' }}>
							<label htmlFor='inputtext'>{t('description')}</label>
							<InputText
								id='inputtext'
								style={{
									width: '100%',
									marginTop: '10px',
									marginBottom: '10px',
								}}
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
						</div>
						<DialogActions style={{ paddingRight: '0' }}>
							<ButtonMaterial
								onClick={() => {
									handleCloseUpdateSarfParameterTemplateModal();
									setDescription(null);
									setParamaterTemplateName(null);
									setSelectedSarfTemplateType(null);
								}}
							>
								{' '}
								{t('Cancel')}
							</ButtonMaterial>
							<ButtonMaterial
								variant='contained'
								onClick={() => {
									updateSarfParameterTemplate();
								}}
							>
								{''}
								{t('UPDATE')}
							</ButtonMaterial>
						</DialogActions>
					</FormControl>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default withTranslation()(UpdateSarfParameterTemplateModal);
