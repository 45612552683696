import axios from 'axios';

class CustomerSarfReportsService {
	getAllSarfReports() {
		return axios.get('/lookup/getAllSarfReports');
	}
	getSarfReportsByCustomerId(customerId) {
		return axios.get('lookup/getSarfReportsByCustomerId/' + customerId);
	}

	getById(id) {
		return axios.get('/lookup/getSarfReportById/' + id);
	}

	save(row) {
		return axios.post('/lookup/saveSarfReport', row);
	}

	update(row) {
		return axios.post('/lookup/updateSarfReport', row);
	}
	delete(id) {
		return axios.delete(`/lookup/deleteSarfReport/${id}`);
	}
}

export default new CustomerSarfReportsService();
