import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import StationService from '../service/StationService';
import { withTranslation } from 'react-i18next';
import PcListService from '../service/PcListService';
import Spinner from './common/Spinner';
const PcInfo = ({ t }) => {
	const toast = useRef(null);
	//assetId pc oluşturululunca backend den verilen id
	const [assetId, setAssetId] = useState('');
	const [loading, setLoading] = useState(false);
	const [stations, setStations] = useState([]);
	const [computer, setComputer] = useState({
		pcFixedAssetNo: '',
		localIp: '',
		grpcServerPort: '',
		biaConnectionPcIP: '',
		biaConnectionPcPort: '',
		station: {},
		text: '',
	});

	const params = useParams();
	const pcFixedAssetNoId = params.id;

	const onSubmit = () => {
		if (
			computer.pcFixedAssetNo === null ||
			computer.pcFixedAssetNo === '' ||
			computer.pcFixedAssetNo === undefined
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('pcdescription_alert_messages.enter_pcFixedAssetNo')}`,
			});
			return;
		}

		if (
			computer.localIp === null ||
			computer.localIp === '' ||
			computer.localIp === undefined
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('pcdescription_alert_messages.enter_localIp')}`,
			});
			return;
		}
		if (
			computer.grpcServerPort === null ||
			computer.grpcServerPort === '' ||
			computer.grpcServerPort === undefined
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('pcdescription_alert_messages.enter_grpcServerPort')}`,
			});
			return;
		}
		if (
			computer.biaConnectionPcIP === null ||
			computer.biaConnectionPcIP === '' ||
			computer.biaConnectionPcIP === undefined
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('pcdescription_alert_messages.enter_biaConnectionIp')}`,
			});

			return;
		}
		if (
			computer.biaConnectionPcPort === null ||
			computer.biaConnectionPcPort === '' ||
			computer.biaConnectionPcPort === undefined
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t(
					'pcdescription_alert_messages.enter_biaConnectionPcPort'
				)}`,
			});

			return;
		}

		if (
			computer.station.name === null ||
			computer.station.name === '' ||
			computer.station.name === undefined
		) {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('pcdescription_alert_messages.select_hub')}`,
			});
			return;
		}

		if (pcFixedAssetNoId == 0) {
			computer.pcFixedAssetNo = computer.pcFixedAssetNo.trim();
			computer.text = computer.text.trim();
			setLoading(true);
			PcListService.save(computer)
				.then((res) => {
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
					});
					setLoading(false);
					setTimeout(() => {
						handleOnClick();
					}, 1000);
				})
				.catch((error) => {
					setLoading(false);
					toast.current.show({
						severity: 'error',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t(`${error.response.data}`)}`,
					});
				});
		} else {
			const newComputerObject = Object.assign({}, computer);
			//payload'da fullName propertysi gönderilmeyeceği için object'ten siliyoruz.
			delete newComputerObject.station.fullName;
			// database tarafından verilen unique id. update için kullanılıyor.
			newComputerObject.id = assetId;
			//payload'da isDeleted istendiği için ekliyoruz.
			newComputerObject.isDeleted = false;
			newComputerObject.pcFixedAssetNo =
				newComputerObject.pcFixedAssetNo.trim();
			newComputerObject.text = newComputerObject.text.trim();
			PcListService.update(newComputerObject)
				.then((res) => {
					setLoading(false);
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
					});
					setTimeout(() => {
						handleOnClick();
					}, 1000);
				})
				.catch((error) => {
					setLoading(false);
					toast.current.show({
						severity: 'error',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t(`${error.response.data}`)}`,
					});
				});
		}
	};

	useEffect(() => {
		callServices(pcFixedAssetNoId);
	}, [pcFixedAssetNoId]);
	const callServices = (pcFixedAssetNoId) => {
		// uniqe id ile update yapmak istediğimiz pc yi çağırıyoruz

		if (pcFixedAssetNoId != 0) {
			setLoading(true);
			PcListService.getById(pcFixedAssetNoId)
				.then((res) => {
					const response = res.data;
					// pc update de kullanılıyor.
					if (pcFixedAssetNoId != 0) {
						setAssetId(response.id);
					} else {
						return;
					}

					//fullName propertiesi ekliyoruz stationa
					response.station.fullName =
						response?.station?.location?.country?.name +
						'/' +
						response?.station?.location?.city?.name +
						'/' +
						response?.station?.name;
					// response ile aldığımız değerlerle stati güncelliyoruz.
					// Böylelikle update ekranında değerler girili olarak gelecek.
					setComputer({
						pcFixedAssetNo: response.pcFixedAssetNo,
						localIp: response.localIp,
						station: response.station,
						text: response.text,
						grpcServerPort: response.grpcServerPort,
						biaConnectionPcIP: response.biaConnectionPcIP,
						biaConnectionPcPort: response.biaConnectionPcPort,
					});
					setTimeout(() => {
						setLoading(false);
					}, 500);
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
		}

		// dropdown da gösterilmesi için StationService çağrıldı
		StationService.getStationList().then((res) => {
			let data = res.data;

			const result = data.map((data) => {
				return {
					...data,
					// fullName i dropdown'da istenilen şekilde göstermek için objeyi dönüştürdük
					fullName:
						data?.location?.country?.name +
						'/' +
						data?.location?.city?.name +
						'/' +
						data?.name,
				};
			});

			setStations(result);
		});
	};

	const history = useHistory();
	const handleOnClick = useCallback(
		() => history.push('/panel/pctanimlari'),
		[history]
	);

	return (
		<>
			<Spinner loading={loading} />
			<Toast ref={toast} />
			<div className='card card-custom gutter-b'>
				<div className='card-header'>
					<div className='card-title'>
						<h3 className='card-label'>{t('pc_detail')}</h3>
					</div>
					<div className='card-toolbar'>
						<ul className='ust_menu'>
							<li tooltip={t('turn_back')} onClick={handleOnClick}>
								<i className='pi pi-arrow-left' />
							</li>
							<li tooltip={t('save_infos')} onClick={onSubmit}>
								<i className='pi pi-save ' />
							</li>
						</ul>
					</div>
				</div>
				<div className='card-body'>
					<form className='p-fluid grid align-items-center justify-content-center'>
						<div className='field col-12 md:col-4' style={{ width: '25%' }}>
							<label htmlFor='inputtext'>{t('pc_no')}</label>
							<InputText
								id='inputtext'
								value={computer.pcFixedAssetNo}
								onChange={(e) =>
									setComputer({ ...computer, pcFixedAssetNo: e.target.value })
								}
								className='p-inputtext-sm'
							/>
						</div>
						<div className='field col-12 md:col-4' style={{ width: '20%' }}>
							<label htmlFor='inputtext'>{t('local_IP')}</label>
							<InputText
								keyfilter='num'
								value={computer.localIp}
								onChange={(e) =>
									setComputer({
										...computer,
										localIp: e.target.value,
									})
								}
							></InputText>
						</div>
						<div className='field col-12 md:col-4' style={{ width: '15%' }}>
							<label htmlFor='inputtext'>{t('grpc_server_port')}</label>
							<InputText
								keyfilter='num'
								value={computer.grpcServerPort}
								onChange={(e) =>
									setComputer({
										...computer,
										grpcServerPort: e.target.value,
									})
								}
							></InputText>
						</div>
						<div className='field col-12 md:col-4  ' style={{ width: '20%' }}>
							<label htmlFor='inputtext'>
								{t('bia_connection_Ip_address')}
							</label>
							<InputText
								keyfilter='num'
								value={computer.biaConnectionPcIP}
								onChange={(e) =>
									setComputer({
										...computer,
										biaConnectionPcIP: e.target.value,
									})
								}
							></InputText>
						</div>
						<div className='field col-12 md:col-4 ' style={{ width: '20%' }}>
							<label htmlFor='inputtext'>{t('bia_connection_pc_port')}</label>
							<InputText
								keyfilter='num'
								value={computer.biaConnectionPcPort}
								onChange={(e) =>
									setComputer({
										...computer,
										biaConnectionPcPort: e.target.value,
									})
								}
							></InputText>
						</div>
						<div className='field col-12 md:col-4' style={{ width: '45%' }}>
							<label htmlFor='dropdown'>{t('hub_name')}</label>
							<Dropdown
								value={computer.station}
								className='p-inputtext-sm dropdownIndex'
								filter={true}
								tabIndex={1}
								filterInputAutoFocus={true}
								showFilterClear={true}
								defaultValue={computer.station.fullName}
								filterBy='fullName'
								appendTo='self'
								onChange={(e) => {
									setComputer({
										...computer,
										station: e.value,
									});
								}}
								options={stations}
								optionLabel='fullName'
							/>
						</div>
						<div className='field col-12 md:col-4' style={{ width: '55%' }}>
							<label htmlFor='inputtext'>{t('description')}</label>
							<InputText
								id='inputtext'
								value={computer.text}
								onChange={(e) =>
									setComputer({ ...computer, text: e.target.value })
								}
								className='p-inputtext-sm'
							/>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};
export default withTranslation()(PcInfo);
