import React, { useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Grid,
  Link,
  Radio,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { MainButton } from './common/MainButton';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/tr';
import ComponentWrapper from './ComponentWrapper';
import Spinner from './common/Spinner';
import FormInput from './common/FormInput';
import Datatable from './common/Datatable';
import IndividualSearchResult from './IndividualSearchResult';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    zIndex: 0,
  },
});

export const searchDataTypes = {
  PRESCREENING: 'PRESCREENING',
  DOMAIN_SCORES: 'DOMAIN_SCORES',
  SUBDOMAIN_SCORES: 'SUBDOMAIN_SCORES',
  METRIC_RESULTS: 'METRIC_RESULTS',
  HRV_RAWDATA: 'HRV_RAWDATA',
  CAFEGAME_RAWDATA: 'CAFEGAME_RAWDATA',
  FLEXIBILITY_METRICS: 'FLEXIBILITY_METRICS',
  EYETRACKING_RAWDATA: 'EYETRACKING_RAWDATA',
  HRV_TIMESERIES_RESULTS: 'HRV_TIMESERIES_RESULTS',
  HRV_FREQUENCY1_RESULTS: 'HRV_FREQUENCY1_RESULTS',
  HRV_FREQUENCY2_RESULTS: 'HRV_FREQUENCY2_RESULTS',
  CARDIOVASCULAR_FITNESS_RESULTS: 'CARDIOVASCULAR_FITNESS_RESULTS',
  CMJ_RESULTS: 'CMJ_RESULTS',
  DEMOGRAPHICS_MAIN: 'DEMOGRAPHICS_MAIN',
};

const IndividualSearch = ({ t }) => {
  const [showResult, setShowResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [foundUsers, setFoundUsers] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control } = useForm();
  const classes = useStyles();

  const onFinish = (formData) => {
    setLoading(true);
    axios
      .post('/dashboard/individualSearch', formData)
      .then((res) => {
        setFoundUsers(res.data.users);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar('Kullanıcı bulunamadı', {
          variant: 'error',
          autoHideDuration: 1000,
        });
      });
  };

  const columns = [
    { field: 'userId', title: `${t('user_id')}` },
    { field: 'name', title: `${t('name')}` },
    { field: 'surname', title: `${t('surname')}` },
    { field: 'email', title: `${t('email')}` },
    { field: 'mobilePhone', title: `${t('mobile_phone')}` },
    {
      field: 'createdAt',
      title: `${t('register_date')}`,
      render: (rowData) => {
        return moment.unix(rowData.createdAt).format('DD.MM.YYYY');
      },
    },
  ];

  const searchAgain = () => {
    setFoundUsers(null);
    setLoading(false);
    setShowResult(false);
  };

  const searchData = () => {
    setLoading(true);
    setShowResult(true);
  };

  return (
    <>
      <ComponentWrapper subtitle={t('individual_search')}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" onClick={() => searchAgain()}>
            {t('call')}
          </Link>
          {foundUsers && (
            <Link color="inherit" onClick={() => setShowResult(false)}>
              {t('user_found')}
            </Link>
          )}
          {showResult && (
            <Typography color="textPrimary"> {t('search_result')}</Typography>
          )}
        </Breadcrumbs>
        {foundUsers ? (
          showResult ? (
            <>
              <IndividualSearchResult
                onSearchAgain={searchAgain}
                userId={selectedUserId}
              />
            </>
          ) : (
            <>
              <Box marginY={2}>
                <MainButton
                  color="primary"
                  onClick={searchData}
                  disabled={!selectedUserId}
                >
                  {t('fetch_info')}
                </MainButton>
              </Box>
              <Grid item={true} xs={12}>
                <Box marginTop={2}>
                  <Datatable
                    elevation={0}
                    style={{ padding: 10 }}
                    options={{
                      search: false,
                      showTitle: false,
                      toolbar: false,
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 50],
                    }}
                    actions={[
                      {
                        icon: 'save',
                        title: 'Deneme',
                        tooltip: `${t('fetch_info')}`,
                        onClick: (event, rowData) =>
                          setSelectedUserId(rowData.userId),
                      },
                    ]}
                    components={{
                      Action: (props) => (
                        <Radio
                          checked={selectedUserId === props.data.userId}
                          onClick={(event) =>
                            props.action.onClick(event, props.data)
                          }
                          name="radio-button-demo"
                          inputProps={{ 'aria-label': 'A' }}
                        />
                      ),
                    }}
                    data={foundUsers}
                    columns={columns}
                  />
                </Box>
              </Grid>

              <Box marginTop={2}>
                <MainButton
                  color="secondary"
                  onClick={() => {
                    searchAgain();
                  }}
                >
                  {t('another_search')}
                </MainButton>
              </Box>
            </>
          )
        ) : (
          <form
            className={classes.root}
            noValidate
            onSubmit={handleSubmit(onFinish)}
          >
            <Spinner loading={loading} />

            <Grid xs={8} item={true} container spacing={3}>
              <Grid item={true} xs={12}>
                <Controller
                  name="userId"
                  control={control}
                  render={(props) => (
                    <FormInput
                      {...props}
                      margin="dense"
                      required
                      label={t('user_id')}
                    />
                  )}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={(props) => (
                    <FormInput
                      {...props}
                      margin="dense"
                      required
                      label={t('name')}
                    />
                  )}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Controller
                  name="surname"
                  control={control}
                  render={(props) => (
                    <FormInput
                      {...props}
                      margin="dense"
                      required
                      label={t('surname')}
                    />
                  )}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Controller
                  name="email"
                  control={control}
                  render={(props) => (
                    <FormInput
                      {...props}
                      margin="dense"
                      required
                      label={t('email')}
                    />
                  )}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Controller
                  name="mobilePhone"
                  control={control}
                  render={(props) => (
                    <FormInput
                      {...props}
                      margin="dense"
                      required
                      label={t('mobile_phone')}
                    />
                  )}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Controller
                  name="age"
                  control={control}
                  render={(props) => (
                    <FormInput
                      {...props}
                      margin="dense"
                      required
                      label={t('age')}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box marginTop={2}>
              <MainButton color="primary" disabled={loading} type="submit">
                {t('search')}
              </MainButton>
            </Box>
          </form>
        )}
      </ComponentWrapper>
    </>
  );
};

export default withTranslation()(IndividualSearch);
