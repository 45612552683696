import React, { useEffect, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LookupService from '../service/LookupService';
import { MainButton } from '../components/common/MainButton';
import store from '../Store';
import ComponentWrapper from './../components/ComponentWrapper';
import {
	Container,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import FormInput from './../components/common/FormInput';
import LargeSelectInput from './../components/common/LargeSelectInput';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { baseTheme } from './../baseTheme';
import SelectInput from './../components/common/SelectInput';
import { useSnackbar } from 'notistack';
import Spinner from '../components/common/Spinner';
import LayoutComponent from '../components/LayoutComponent';
import { useTranslation } from 'react-i18next';

const otherRequired = (other, _) => other?.id === 0;

const datePickerTheme = createTheme({
	...baseTheme,
	palette: {
		primary: {
			main: '#0099F7',
		},
	},
});

const getObjectFromId = (list, id) => {
	return list.find((item) => item.id === id);
};

const DemographicsForm = () => {
	const { t } = useTranslation();
	const Schema = Yup.object().shape({
		birthDate: Yup.string()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		gender: Yup.string()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		genderOther: Yup.string().when('gender', {
			is: (value, _) => value === '0',
			then: Yup.string().required(`${t('schema_shape.not_empty')}`),
			otherwise: Yup.string(),
		}),
		country: Yup.object()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		countryResidenceOther: Yup.string().when('country', {
			is: otherRequired,
			then: Yup.string().required(`${t('schema_shape.not_empty')}`),
			otherwise: Yup.string(),
		}),
		occupation: Yup.object().nullable(),
		// .required(`${t('schema_shape.not_empty')}`),
		occupationOther: Yup.string().when('occupation', {
			is: otherRequired,
			then: Yup.string(), //required(`${t('schema_shape.not_empty')}`),
			otherwise: Yup.string(),
		}),
		workStatus: Yup.string().nullable(),
		// .required(`${t('schema_shape.not_empty')}`),
		workStatusOther: Yup.string().when('workStatus', {
			is: (value, _) => value === '0',
			then: Yup.string(), //required(`${t('schema_shape.not_empty')}`),
			otherwise: Yup.string(),
		}),
		// jobTitle: Yup.object().nullable().required(`${t('schema_shape.not_empty')}`),
		// jobTitleOther: Yup.string().when('jobTitle', {
		//   is: otherRequired,
		//   then: Yup.string().required(`${t('schema_shape.not_empty')}`),
		//   otherwise: Yup.string(),
		// }),
		// ethnicity: Yup.object()
		//   .nullable()
		//   .required(`${t('schema_shape.not_empty')}`),
		// ethnicityOther: Yup.string().when('ethnicity', {
		//   is: otherRequired,
		//   then: Yup.string().required(`${t('schema_shape.not_empty')}`),
		//   otherwise: Yup.string(),
		// }),
		// maritalStatus: Yup.object().nullable().required(`${t('schema_shape.not_empty')}`),
		// maritalStatusOther: Yup.string().when('maritalStatus', {
		//   is: otherRequired,
		//   then: Yup.string().required(`${t('schema_shape.not_empty')}`),
		//   otherwise: Yup.string(),
		// }),
		// childStatus: Yup.object().nullable().required(`${t('schema_shape.not_empty')}`),
		education: Yup.object()
			.nullable()
			.required(`${t('schema_shape.not_empty')}`),
		// annualIncome: Yup.object().nullable().required(`${t('schema_shape.not_empty')}`),
	});
	const { enqueueSnackbar } = useSnackbar();

	// eslint-disable-next-line no-unused-vars

	const [jobTitles, setJobTitles] = useState([]);
	const [genders, setGenders] = useState([]);
	const [ethnicities, setEthnicities] = useState([]);
	const [maritalStatuses, setMaritalStatuses] = useState([]);
	const [childStatuses, setChildStatuses] = useState([]);
	const [educations, setEducations] = useState([]);
	const [annualIncomes, setAnnualIncomes] = useState([]);
	const [occupations, setOccupations] = useState([]);
	const [countries, setCountries] = useState([]);
	const [workStatuses, setWorkStatuses] = useState([]);
	const [loading, setLoading] = useState(false);
	const button = React.useRef(null);

	// eslint-disable-next-line no-unused-vars
	const { handleSubmit, watch, setValue, control, formState, register } =
		useForm({
			mode: 'onSubmit',
			reValidateMode: 'onChange',
			resolver: yupResolver(Schema),
		});

	const watchShowAge = watch('country', false);

	React.useEffect(() => {
		const subscription = watch((value, { name, type }) => {});
		return () => subscription.unsubscribe();
	}, [watch]);

	const languageId = localStorage.getItem('i18nextLng');
	useEffect(() => {
		let isMounted = true;

		setLoading(true);
		LookupService.fetchDemographicLookups(languageId).then((responses) => {
			if (isMounted) {
				setJobTitles(responses[0].data);
				setGenders(responses[1].data);
				setEthnicities(responses[2].data);
				setMaritalStatuses(responses[3].data);
				setChildStatuses(responses[4].data);
				setEducations(responses[5].data);
				// setAnnualIncomes(responses[6].data);
				setCountries(responses[7].data);
				setOccupations(responses[8].data);
				setWorkStatuses(responses[9].data);
				setLoading(false);
			}
		});
		return () => {
			isMounted = false;
		};
	}, [languageId]);

	const onFinish = (demographicsForm) => {
		// console.log('DemographicsForm.js formState.errors',formState.errors);
		if (!formState.isValid && Object.keys(formState.errors).length > 0) {
			enqueueSnackbar(`${t('demographic_form_popup.control_form_info')}`, {
				variant: 'error',
			});
			return;
		}

		demographicsForm['birthDate'] = new Date(demographicsForm['birthDate']);
		demographicsForm['birthDate'] =
			demographicsForm['birthDate'].getFullYear() + '-01-01T00:00:00.000Z';
		const genderId = demographicsForm['gender'];
		if (genderId === 0) {
			//Other selected
			demographicsForm['gender'] = null;
		} else {
			let genderObj = getObjectFromId(genders, parseInt(genderId));
			demographicsForm['gender'] = genderObj;
		}
		const workStatusId = demographicsForm['workStatus'];
		if (workStatusId === 0) {
			//Other selected
			demographicsForm['workStatus'] = null;
		} else {
			let workStatusObj = getObjectFromId(workStatuses, parseInt(workStatusId));
			demographicsForm['workStatus'] = workStatusObj;
		}

		if (demographicsForm['countryResidenceOther']) {
			demographicsForm['country'] = null;
		}
		if (demographicsForm['occupationOther']) {
			demographicsForm['occupation'] = null;
		}
		if (demographicsForm['jobTitleOther']) {
			demographicsForm['jobTitle'] = null;
		}
		if (
			demographicsForm['jobTitle'] != null &&
			demographicsForm['jobTitle'].id === 0 &&
			(demographicsForm['jobTitleOther'] == null ||
				demographicsForm['jobTitleOther'] == '')
		) {
			demographicsForm['jobTitle'] = null;
		}
		// if (demographicsForm['ethnicityOther']) {
		//   demographicsForm['ethnicity'] = null;
		// }
		if (demographicsForm['maritalStatusOther']) {
			demographicsForm['maritalStatus'] = null;
		}
		if (
			demographicsForm['maritalStatus'] != null &&
			demographicsForm['maritalStatus'].id === 0 &&
			(demographicsForm['maritalStatusOther'] == null ||
				demographicsForm['maritalStatusOther'] == '')
		) {
			demographicsForm['maritalStatus'] = null;
		}
		axios
			.post('/users/demographics', demographicsForm)
			.then(() => {
				enqueueSnackbar(`${t('demographic_form_popup.success_add')}`, {
					variant: 'success',
				});
				store.steps.current = 1;
			})
			.catch(function (error) {
				enqueueSnackbar(`${t('demographic_form_popup.error_add')}`, {
					variant: 'error',
				});
			});
	};

	const showFormError = () => {
		if (
			!formState.isValid &&
			formState.isSubmitting &&
			Object.keys(formState.errors).length > 0
		) {
			// console.log('DoctorDemographicsForm.js 216 formState.errors',formState.errors);
			enqueueSnackbar(`${t('demographic_form_popup.control_form_info')}`, {
				variant: 'error',
			});
		}
	};

	const onHandleCountryEvent = (e) => {
		if (e.id !== '' || e.id !== null || e.id !== undefined) {
			LookupService.fetchAnnualIncomesForCountryId(e.id, languageId).then(
				(response) => {
					setAnnualIncomes(response.data);
				}
			);
		}
		return;
	};

	const gender = watch('gender');
	const occupation = watch('occupation');
	const workStatus = watch('workStatus');
	const country = watch('country');
	// const ethnicity = watch('ethnicity');
	const jobTitle = watch('jobTitle');
	const maritalStatus = watch('maritalStatus');

	return (
		<>
			<Spinner loading={loading} />
			<form noValidate onSubmit={handleSubmit(onFinish)}>
				{formState.isSubmitted && showFormError()}
				<ComponentWrapper subtitle={t('demographic_info_form')}>
					<Grid container spacing={1}>
						<Grid item={true} xs={12}>
							<Controller
								name='birthDate'
								control={control}
								defaultValue={null}
								render={({ field, fieldState: { error } }) => (
									<ThemeProvider theme={datePickerTheme}>
										<KeyboardDatePicker
											views={['year']}
											error={!!error}
											inputVariant='outlined'
											size='small'
											margin='dense'
											helperText={error?.message}
											minDate={new Date('1930')}
											maxDate={new Date()}
											label={t('year_of_birth')}
											{...field}
											renderInput={(props) => <TextField {...props} />}
										/>
									</ThemeProvider>
								)}
							/>
						</Grid>
						<Grid item={true} xs={12}>
							<Controller
								control={control}
								name='gender'
								defaultValue=''
								render={({ field, fieldState: { error } }) => (
									<FormControl error={!!error}>
										<FormLabel color='secondary' component='legend'>
											{t('gender_identify')} *
										</FormLabel>
										<RadioGroup {...field}>
											{genders.map((gender) => (
												<FormControlLabel
													key={gender.id}
													required
													value={String(gender.id)}
													control={<Radio />}
													label={gender.name}
												/>
											))}
											{/*<FormControlLabel*/}
											{/*  key={0}*/}
											{/*  value="0"*/}
											{/*  control={<Radio />}*/}
											{/*  label={t('other')}*/}
											{/*/>*/}
											<FormHelperText>{error?.message}</FormHelperText>
										</RadioGroup>
									</FormControl>
								)}
							/>
						</Grid>
						{gender && gender === '0' && (
							<Grid
								item={true}
								xs={12}
								container
								alignItems='center'
								spacing={1}
							>
								<Grid item={true} xs={12} md={8}>
									<Controller
										name='genderOther'
										defaultValue=''
										control={control}
										render={(props) => (
											<FormInput
												{...props}
												required
												label={t('other_gender')}
											/>
										)}
									/>
								</Grid>
							</Grid>
						)}

						<Grid item={true} xs={12} container alignItems='center' spacing={1}>
							<Grid item={true} xs={12} md={8}>
								<Controller
									control={control}
									name='country'
									render={(props) => (
										<SelectInput
											{...props}
											required
											label={t('indicate_country')}
											options={countries}
											onHandleCountryEvent={onHandleCountryEvent}
										/>
									)}
								/>
							</Grid>
							{country?.id === 0 && (
								<Grid item={true} xs={12} md={8}>
									<Controller
										name='countryResidenceOther'
										control={control}
										render={(props) => (
											<FormInput
												{...props}
												required
												label={t('indicate_country1')}
											/>
										)}
									/>
								</Grid>
							)}
						</Grid>
						<Grid item={true} xs={12} container alignItems='center' spacing={1}>
							<Grid item={true} xs={12} md={8}>
								<Controller
									control={control}
									name='occupation'
									render={(props) => (
										<LargeSelectInput
											{...props}
											required
											label={t('specify_job')}
											options={occupations}
										/>
									)}
								/>
							</Grid>
							{occupation?.id === 0 && (
								<Grid item={true} xs={12} md={8}>
									<Controller
										name='occupationOther'
										control={control}
										render={(props) => (
											<FormInput
												{...props}
												required
												label={t('specify_job1')}
											/>
										)}
									/>
								</Grid>
							)}
						</Grid>

						<Grid item={true} xs={12}>
							<Controller
								control={control}
								name='workStatus'
								defaultValue=''
								render={({ field, fieldState: { error } }) => {
									return (
										<FormControl error={!!error}>
											<FormLabel color='secondary' component='legend'>
												{t('current_emp_status')} *
											</FormLabel>
											<RadioGroup {...field}>
												{workStatuses.map((workStatus) => (
													<FormControlLabel
														key={workStatus.id}
														value={String(workStatus.id)}
														control={<Radio />}
														label={workStatus.name}
													/>
												))}
												<FormControlLabel
													key={0}
													value='0'
													control={<Radio />}
													label={t('other')}
												/>
												<FormHelperText>{error?.message}</FormHelperText>
											</RadioGroup>
										</FormControl>
									);
								}}
							/>
						</Grid>
						{workStatus && workStatus === '0' && (
							<Grid
								item={true}
								xs={12}
								container
								alignItems='center'
								spacing={1}
							>
								<Grid item={true} xs={12} md={8}>
									<Controller
										name='workStatusOther'
										defaultValue=''
										control={control}
										render={(props) => (
											<FormInput
												{...props}
												required
												label={t('other_work_status')}
											/>
										)}
									/>
								</Grid>
							</Grid>
						)}

						<Grid item={true} xs={12} container alignItems='center' spacing={1}>
							<Grid item={true} xs={12} md={8}>
								<Controller
									control={control}
									name='jobTitle'
									render={(props) => (
										<SelectInput
											{...props}
											label={t('current_job_title')}
											options={jobTitles}
										/>
									)}
								/>
							</Grid>
							{jobTitle?.id === 0 && (
								<Grid item={true} xs={12} md={8}>
									<Controller
										name='jobTitleOther'
										control={control}
										render={(props) => (
											<FormInput {...props} label={t('other_job_title')} />
										)}
									/>
								</Grid>
							)}
						</Grid>

						{/*<Grid item={true} xs={12} container alignItems="center" spacing={1}>*/}
						{/*  <Grid item={true} xs={12} md={8}>*/}
						{/*    <Controller*/}
						{/*      control={control}*/}
						{/*      name="ethnicity"*/}
						{/*      render={(props) => (*/}
						{/*        <SelectInput*/}
						{/*          {...props}*/}
						{/*          required*/}
						{/*          label={t('indicate_ethnic_group')}*/}
						{/*          options={ethnicities}*/}
						{/*        />*/}
						{/*      )}*/}
						{/*    />*/}
						{/*  </Grid>*/}
						{/*  {ethnicity?.id === 0 && (*/}
						{/*    <Grid item={true} xs={12} md={8}>*/}
						{/*      <Controller*/}
						{/*        name="ethnicityOther"*/}
						{/*        control={control}*/}
						{/*        render={(props) => (*/}
						{/*          <FormInput*/}
						{/*            {...props}*/}
						{/*            required*/}
						{/*            label={t('other_ethnic_group')}*/}
						{/*          />*/}
						{/*        )}*/}
						{/*      />*/}
						{/*    </Grid>*/}
						{/*  )}*/}
						{/*</Grid>*/}

						<Grid item={true} xs={12} container alignItems='center' spacing={1}>
							<Grid item={true} xs={12} md={8}>
								<Controller
									control={control}
									name='maritalStatus'
									render={(props) => (
										<SelectInput
											{...props}
											label={t('marital_status')}
											options={maritalStatuses}
										/>
									)}
								/>
							</Grid>
							{maritalStatus?.id === 0 && (
								<Grid item={true} xs={12} md={8}>
									<Controller
										name='maritalStatusOther'
										control={control}
										render={(props) => (
											<FormInput {...props} label={t('other_marital_status')} />
										)}
									/>
								</Grid>
							)}
						</Grid>

						<Grid item={true} xs={12} container alignItems='center' spacing={1}>
							<Grid item={true} xs={12} md={8}>
								<Controller
									control={control}
									name='childStatus'
									render={(props) => (
										<SelectInput
											{...props}
											dontIncludeOther
											label={t('have_children')}
											options={childStatuses}
										/>
									)}
								/>
							</Grid>
						</Grid>

						<Grid item={true} xs={12} container alignItems='center' spacing={1}>
							<Grid item={true} xs={12} md={8}>
								<Controller
									control={control}
									name='education'
									render={(props) => (
										<SelectInput
											{...props}
											dontIncludeOther
											required
											label={t('education_degree')}
											options={educations}
										/>
									)}
								/>
							</Grid>
						</Grid>
						{watchShowAge && (
							<Grid
								item={true}
								xs={12}
								container
								alignItems='center'
								spacing={1}
							>
								<Grid item={true} xs={12} md={8}>
									<Controller
										control={control}
										name='annualIncome'
										render={(props) => (
											<SelectInput
												{...props}
												dontIncludeOther
												label={t('current_annual_incomes')}
												options={annualIncomes}
											/>
										)}
									/>
								</Grid>
							</Grid>
						)}

						<Grid item={true} xs={12} container alignItems='center' spacing={1}>
							<Grid item={true} xs={12} md={8}>
								<Controller
									name='companyName'
									control={control}
									render={(props) => (
										<FormInput
											margin='dense'
											{...props}
											label={t('current_work_company')}
										/>
									)}
								/>
							</Grid>
						</Grid>

						{/*<Grid item={true} xs={12} container alignItems="center" spacing={1}>*/}
						{/*  <Grid item={true} xs={12} md={8}>*/}
						{/*    <Controller*/}
						{/*      name="groupName"*/}
						{/*      control={control}*/}
						{/*      render={(props) => (*/}
						{/*        <FormInput*/}
						{/*          margin="dense"*/}
						{/*          {...props}*/}
						{/*          label={t('as_group')}*/}
						{/*        />*/}
						{/*      )}*/}
						{/*    />*/}
						{/*  </Grid>*/}
						{/*</Grid>*/}
					</Grid>
				</ComponentWrapper>
				<Container style={{ marginTop: 24 }}>
					<Grid container justifyContent='flex-end'>
						<MainButton color='primary' type='submit'>
							{t('NEXT')}
						</MainButton>
					</Grid>
				</Container>
			</form>
		</>
	);
};
export default DemographicsForm;
