import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import Collapse from '@material-ui/core/Collapse'
import { useSpring, animated } from 'react-spring' // web.cjs is required for IE 11 support
import './treeview.css'

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 20 : 20}px,5,0)`,
    },
  })

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  )
}

TransitionComponent.propTypes = {
  in: PropTypes.bool,
}

const StyledTreeItem = withStyles((theme) => ({
  group: {
    marginLeft: 7,
    paddingLeft: 18,
  },
}))((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
})

export default function CustomizedTreeView() {
  const classes = useStyles()

  return (
    <TreeView className={classes.root} defaultExpanded={['1']}>
      <StyledTreeItem nodeId="2" label="Overall Score" />
      <StyledTreeItem nodeId="6" label="Expert Review- Emotional" />
      <StyledTreeItem nodeId="7" label="Expert Review- Physical">
        <StyledTreeItem nodeId="9" label="Physical 1" />
      </StyledTreeItem>
    </TreeView>
  )
}
