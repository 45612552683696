import React, { useState } from 'react';
import OverviewService from '../service/OverviewService';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import '../pages/homeuser.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      padding: 20,
      xAlign: 'left',
      yAlign: 'top',
      footerMarginBottom: 12,
      caretPadding: 8,
      bodyAlign: 'left',
      bodySpacing: 10,
      position: 'average',
      boxPadding: 8,
    },
  },
  layout: {
    height: 100,
  },
  scales: {
    x: {
      display: false, // Hide Y axis labels
    },
    y: {
      display: false, // Hide Y axis labels
    },
  },
};

const ScoreModules = ({
  appointmentId,
  t,
  module,
  scores,
  notUser,
  isDisplayDonutChart,
}) => {
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedDomain, setSelectDomain] = useState(null);
  const [cognitive, setCognitive] = useState({});
  const [physical, setPhysical] = useState({});
  const [socioEmotional, setSocioEmotional] = useState({});
  const [cognitive2, setCognitive2] = useState({});
  const [physical2, setPhysical2] = useState({});
  const [socioEmotional2, setSocioEmotional2] = useState({});

  const useStyles = makeStyles({
    cognitive: {
      '&:before': {
        backgroundColor: cognitive2?.forcedTreeColor,
      },
    },
    physical: {
      '&:before': {
        backgroundColor: physical2?.forcedTreeColor,
      },
    },
    socioEmotional: {
      '&:before': {
        backgroundColor: socioEmotional2?.forcedTreeColor,
      },
    },
  });
  const classes = useStyles();

  React.useEffect(() => {
    if (module.modules && isDisplayDonutChart) {
      console.log('module :>> ', module);
      getBarChart(module);
    } else if (module.modules && !isDisplayDonutChart) {
      const cognitiveData = module.modules.find((item) => item.moduleId === 1);
      const physicalData = module?.modules.find((item) => item.moduleId === 2);
      const socioEmotionalData = module?.modules.find(
        (item) => item.moduleId === 3
      );
      setCognitive2(cognitiveData);
      setPhysical2(physicalData);
      setSocioEmotional2(socioEmotionalData);
    }
  }, [module]);

  const getBarChart = (module) => {
    const cognitiveData = module.modules.find((item) => item.moduleId === 1);
    const physicalData = module?.modules.find((item) => item.moduleId === 2);
    const socioEmotionalData = module?.modules.find(
      (item) => item.moduleId === 3
    );
    setCognitive2(cognitiveData);
    setPhysical2(physicalData);
    setSocioEmotional2(socioEmotionalData);
    const data = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [],
          backgroundColor: cognitiveData?.forcedTreeColor ?? '',
        },
      ],
    };
    const data1 = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [],
          backgroundColor: physicalData?.forcedTreeColor ?? '',
        },
      ],
    };
    const data2 = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [],
          backgroundColor: socioEmotionalData?.forcedTreeColor ?? '',
        },
      ],
    };
    let cogDomains = cognitiveData?.domains.filter(
      (item) =>
        item.domainId != null &&
        item.domainScore != null &&
        item.domainScore !== 0
    );

    for (let i = 0; i < cogDomains?.length; i++) {
      const name = `${cogDomains[i].domainName}`;
      const score = cogDomains[i].domainScore;
      data.labels.push(name);
      data.datasets[0].data.push(score);
    }
    let phyDomains = physicalData?.domains.filter(
      (item) =>
        item.domainId != null &&
        item.domainScore != null &&
        item.domainScore !== 0
    );

    for (let i = 0; i < phyDomains?.length; i++) {
      const name = `${phyDomains[i].domainName}`;
      const score = phyDomains[i].domainScore;
      data1.labels.push(name);
      data1.datasets[0].data.push(score);
    }
    let socioDomains = socioEmotionalData?.domains.filter(
      (item) =>
        item.domainId != null &&
        item.domainScore != null &&
        item.domainScore !== 0
    );

    for (let i = 0; i < socioDomains?.length; i++) {
      const name = `${socioDomains[i].domainName}`;
      const score = socioDomains[i].domainScore;
      data2.labels.push(name);
      data2.datasets[0].data.push(score);
    }
    setCognitive(data);
    setPhysical(data1);
    setSocioEmotional(data2);
  };

  return notUser === 'user' ? (
    <div className='info-details flex'>
      <div key={0} className={'module' + (0 + 1)}>
        <h3 className={classes.cognitive}>
          <Link
            className='moduleAll'
            style={{ position: 'relative' }}
            to={
              module?.modules?.find((item) => item.moduleId === 1) != null
                ? {
                    pathname: '/overview',
                    state: { id: '1', name: `${cognitive2?.moduleName}` },
                    search: '?guid=' + appointmentId,
                  }
                : {
                    pathname: '',
                  }
            }
            onClick={() => setSelectedModule(module)}
          >
            {cognitive2?.moduleName}
          </Link>
        </h3>
        <span className='score' style={{ color: cognitive2?.forcedTreeColor }}>
          {scores.cognitiveScore === 0 || scores.cognitiveScore == null
            ? '-'
            : scores.cognitiveScore}
        </span>
        <span className='max'> / 1000</span>
        {cognitive.labels && (
          <Bar className='barChart' options={options} data={cognitive} />
        )}
      </div>
      <div key={1} className={'module' + (1 + 1)}>
        <h3 className={classes.physical}>
          <Link
            className='moduleAll'
            to={
              module?.modules?.find((item) => item.moduleId === 2) != null
                ? {
                    pathname: '/overview',
                    state: { id: '2', name: `${physical2?.moduleName}` },
                    search: '?guid=' + appointmentId,
                  }
                : {
                    pathname: '',
                  }
            }
            onClick={() => setSelectedModule(module)}
          >
            {physical2?.moduleName}
          </Link>{' '}
        </h3>
        <span className='score' style={{ color: physical2?.forcedTreeColor }}>
          {scores.physicalScore === 0 || scores.physicalScore == null
            ? '-'
            : scores.physicalScore}
        </span>
        <span className='max'> / 1000</span>{' '}
        {physical.labels && (
          <Bar className='barChart' options={options} data={physical} />
        )}
      </div>

      <div key={2} className={'module' + (2 + 1)}>
        <h3 className={classes.socioEmotional}>
          <Link
            className='moduleAll'
            to={
              module?.modules?.find((item) => item.moduleId === 3) != null
                ? {
                    pathname: '/overview',
                    state: {
                      id: '3',
                      name: `${socioEmotional2?.moduleName}`,
                    },
                    search: '?guid=' + appointmentId,
                  }
                : {
                    pathname: '',
                  }
            }
            onClick={() => setSelectedModule(module)}
          >
            {socioEmotional2?.moduleName}
          </Link>{' '}
        </h3>
        <span
          className='score'
          style={{ color: socioEmotional2?.forcedTreeColor }}
        >
          {scores.socioScore === 0 || scores.socioScore == null
            ? '-'
            : scores.socioScore}
        </span>
        <span className='max'> / 1000</span>
        {socioEmotional.labels && (
          <Bar options={options} className='barChart' data={socioEmotional} />
        )}
      </div>
      {selectedModule !== null ? (
        <div className={'detail ' + selectedModule?.name?.toLowerCase()}>
          <h3>{selectedModule?.name?.toUpperCase()}</h3>
          {selectedModule.description}
          <br />
          <div className='domains'>
            <ul>
              {selectedModule?.domains?.map((domain, key) => (
                <li
                  key={key}
                  className={domain === selectedDomain ? 'selected' : ''}
                  onClick={() => setSelectDomain(domain)}
                >
                  {domain.name}
                </li>
              ))}
            </ul>
            {selectedDomain !== null ? (
              <div className='domain-description'>
                {selectedDomain.description}
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      ) : null}
    </div>
  ) : (
    <div className='info-details flex'>
      <div key={0} className={'module' + (0 + 1)}>
        <h3 className={classes.cognitive}>
          <Link
            className='moduleAll'
            style={{ position: 'relative' }}
            to={
              module?.modules?.find((item) => item.moduleId === 1) != null
                ? {
                    pathname: '/overview/' + appointmentId,
                    state: { id: '1', name: `${cognitive2?.moduleName}` },
                  }
                : {
                    pathname: '',
                  }
            }
            onClick={() => setSelectedModule(module)}
          >
            {cognitive2?.moduleName}
          </Link>
        </h3>
        <span className='score' style={{ color: cognitive2?.forcedTreeColor }}>
          {scores.cognitiveScore === 0 || scores.cognitiveScore == null
            ? '-'
            : scores.cognitiveScore}
        </span>
        <span className='max'> / 1000</span>
        {cognitive.labels && (
          <Bar className='barChart' options={options} data={cognitive} />
        )}
      </div>
      <div key={1} className={'module' + (1 + 1)}>
        <h3 className={classes.physical}>
          <Link
            className='moduleAll'
            to={
              module?.modules?.find((item) => item.moduleId === 2) != null
                ? {
                    pathname: '/overview/' + appointmentId,
                    state: { id: '2', name: `${physical2?.moduleName}` },
                  }
                : {
                    pathname: '',
                  }
            }
            onClick={() => setSelectedModule(module)}
          >
            {physical2?.moduleName}
          </Link>{' '}
        </h3>
        <span className='score' style={{ color: physical2?.forcedTreeColor }}>
          {scores.physicalScore === 0 || scores.physicalScore == null
            ? '-'
            : scores.physicalScore}
        </span>
        <span className='max'> / 1000</span>{' '}
        {physical.labels && (
          <Bar className='barChart' options={options} data={physical} />
        )}
      </div>

      <div key={2} className={'module' + (2 + 1)}>
        <h3 className={classes.socioEmotional}>
          <Link
            className='moduleAll'
            to={
              module?.modules?.find((item) => item.moduleId === 3) != null
                ? {
                    pathname: '/overview/' + appointmentId,
                    state: {
                      id: '3',
                      name: `${socioEmotional2?.moduleName}`,
                    },
                  }
                : {
                    pathname: '',
                  }
            }
            onClick={() => setSelectedModule(module)}
          >
            {socioEmotional2?.moduleName}
          </Link>{' '}
        </h3>
        <span
          className='score'
          style={{ color: socioEmotional2?.forcedTreeColor }}
        >
          {scores.socioScore === 0 || scores.socioScore == null
            ? '-'
            : scores.socioScore}
        </span>
        <span className='max'> / 1000</span>
        {socioEmotional.labels && (
          <Bar options={options} className='barChart' data={socioEmotional} />
        )}
      </div>
      {selectedModule !== null ? (
        <div className={'detail ' + selectedModule?.name?.toLowerCase()}>
          <h3>{selectedModule?.name?.toUpperCase()}</h3>
          {selectedModule.description}
          <br />
          <div className='domains'>
            <ul>
              {selectedModule?.domains?.map((domain, key) => (
                <li
                  key={key}
                  className={domain === selectedDomain ? 'selected' : ''}
                  onClick={() => setSelectDomain(domain)}
                >
                  {domain.name}
                </li>
              ))}
            </ul>
            {selectedDomain !== null ? (
              <div className='domain-description'>
                {selectedDomain.description}
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default withTranslation()(ScoreModules);
