import React from "react";
import styled from "styled-components";

const MySlash = styled.span`
  font-size: ${(props) => (props && props.compact ? "20px" : "36px")};
  color: ${(props) => props.color || props.theme.palette.primary.main};
  font-weight: bolder;
  padding-right: 5px;
  display: inline;
`;

export const Slash = ({ color, compact }) => {
  return (
    <MySlash compact={compact} color={color}>
      /
    </MySlash>
  );
};
