import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { TabPanel, TabView } from 'primereact/tabview';
import SettingsIcon from '@mui/icons-material/Settings';
import { Toast } from 'primereact/toast';
import { withTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';

import { confirmDialog } from 'primereact/confirmdialog';
import { confirmPopup } from 'primereact/confirmpopup';
import CustomerSarfReportService from '../../service/CustomerSarfReportService';
import CustomerSarfReportParametersTemplateService from '../../service/CustomerSarfReportParametersTemplateService';
import AddSarfReportModal from './AddSarfReportModal';
import UpdateSarfReportModal from './UpdateSarfReportModal';
import ConfigureSarfReportUsersModal from './ConfigureSarfReportUsersModal';
import AddSarfParameterTemplateModal from './AddSarfParameterTemplateModal';
import UpdateSarfParameterTemplateModal from './UpdateSarfParameterTemplateModal';
import CalculateAllAppointmentsModal from './CalculateAllAppointmentsModal';
const CustomerSarfDetails = ({ customerId, t, langValue, setLoading }) => {
	const toast = useRef(null);
	const [globalFilterValue1, setGlobalFilterValue1] = useState('');
	const [globalFilterValue2, setGlobalFilterValue2] = useState('');

	const [filters1, setFilters1] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		name: { value: null, matchMode: FilterMatchMode.CONTAINS },
		'generations.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
		description: { value: null, matchMode: FilterMatchMode.CONTAINS },
		'seniorityLevel.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'sarfPackage.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'sarfReportParametersTemplates.name': {
			value: null,
			matchMode: FilterMatchMode.CONTAINS,
		},
	});

	const [filters2, setFilters2] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		name: { value: null, matchMode: FilterMatchMode.CONTAINS },
		'templateType.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
		description: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});

	const [selectedSarfReport, setSelectedSarfReport] = useState();

	const [activeIndex, setActiveIndex] = useState(0);

	const [addSarfReportOpen, setAddSarfReportOpen] = useState(false);
	const [configureSarfReportUserOpen, setConfigureSarfReportUserOpen] =
		useState(false);
	const [updateSarfReportOpen, setUpdateSarfReportOpen] = useState(false);

	const [addSarfParameterTemplateOpen, setAddSarfParameterTemplateOpen] =
		useState(false);
	const [updateSarfParameterTemplateOpen, setUpdateSarfParameterTemplateOpen] =
		useState(false);

	const [calculateAllAppoinmentsOpen, setCalculateAllAppoinmentsOpen] =
		useState(false);
	const [sarfReports, setSarfReports] = useState([]);

	const [filteredSarfReports, setFilteredSarfReports] = useState([]);

	const [selectedSarfReportTemplate, setSelectedSarfReportTemplate] =
		useState();

	const [selectedList, setSelectedList] = useState(null);
	const [sarfReportTemplates, setSarfReportTemplates] = useState([]);
	const [filteredSarfReportTemplates, setFilteredSarfReportTemplates] =
		useState([]);

	useEffect(() => {
		let unmounted = false;

		if (
			customerId &&
			(customerId !== '' ||
				customerId !== null ||
				customerId !== undefined ||
				customerId != 0)
		) {
			getAllSarfReports(customerId);
			getAllSarfReportParametersTemplates(customerId);
		} else {
			return;
		}
		return () => {
			unmounted = true;
		};
	}, [customerId, langValue]);

	const onGlobalFilterChange1 = (e) => {
		const value = e.target.value;
		let _filters1 = { ...filters1 };
		_filters1['global'].value = value;

		setFilters1(_filters1);
		setGlobalFilterValue1(value);
	};

	const onGlobalFilterChange2 = (e) => {
		const value = e.target.value;
		let _filters2 = { ...filters2 };
		_filters2['global'].value = value;

		setFilters2(_filters2);
		setGlobalFilterValue2(value);
	};

	const acceptProcess1 = (rowData) => {
		deleteSarfReport(rowData.id);
	};
	const acceptProcess2 = (rowData) => {
		deleteSarfReportParameter(rowData.id);
	};

	const goToAddSarfReportParameter = () => {
		openAddSarfParameterModal();
	};

	const reject = () => {
		toast.current.show({
			severity: 'info',
			summary: `${t('appointment_alert_messages.transaction_status')}`,
			detail: `${t('appointment_alert_messages.oparation_cancel')}`,
			life: 3000,
		});
	};
	const confirmDelete1 = (event, rowData) => {
		confirmPopup({
			target: event.currentTarget,
			message: `${t('appointment_alert_messages.want_delete_register')}`,
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: () => acceptProcess1(rowData),
			reject,
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
		});
	};
	const confirmDelete2 = (event, rowData) => {
		confirmPopup({
			target: event.currentTarget,
			message: `${t('appointment_alert_messages.want_delete_register')}`,
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: () => acceptProcess2(rowData),
			reject,
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
		});
	};

	const confirmCheckParameterTemplates = (event, rowData) => {
		confirmDialog({
			header: `${t('appointment_alert_messages.confirmation')}`,
			message: `${t('create_sarf_report_parameter_template_message')}`,
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				goToAddSarfReportParameter();
				setActiveIndex(1);
			},
			reject,
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
		});
	};

	const deleteSarfReport = (id) => {
		setLoading(true);
		CustomerSarfReportService.delete(id)
			.then((res) => {
				getAllSarfReports(customerId);
				setTimeout(() => {
					setLoading(false);
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
						life: 3000,
					});
				}, 1000);
			})
			.catch((err) => {
				setLoading(false);
				toast.current.show({
					severity: 'error',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: `${t(err.response.data)}`,
					life: 3000,
				});
			});
	};
	const deleteSarfReportParameter = (id) => {
		setLoading(true);
		CustomerSarfReportParametersTemplateService.delete(id)
			.then((res) => {
				getAllSarfReportParametersTemplates(customerId);
				setTimeout(() => {
					setLoading(false);
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
						life: 3000,
					});
				}, 1000);
			})
			.catch((error) => {
				setLoading(false);
				toast.current.show({
					severity: 'error',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: error.response.data,
					life: 3000,
				});
			});
	};

	const getAllSarfReportParametersTemplates = (customerId) => {
		CustomerSarfReportParametersTemplateService.getSarfTemplatesByCustomerId(
			customerId
		)
			.then((res) => {
				let total = [];
				if (res.data != null) {
					for (let i = 0; i < res?.data?.length; i++) {
						if (
							(res.data[i]?.templateType == 1) &
							(res.data[i]?.templateType != null) &
							(res.data[i]?.templateType != undefined)
						) {
							res.data[i].templateType = {
								id: 1,
								name: `${t('neo_standard_general')}`,
							};
						} else if (
							(res.data[i]?.templateType == 2) &
							(res.data[i]?.templateType != null) &
							(res.data[i]?.templateType != undefined)
						) {
							res.data[i].templateType = {
								id: 2,
								name: `${t('neo_standard_other')}`,
							};
						} else if (
							(res.data[i]?.templateType == 3) &
							(res.data[i]?.templateType != null) &
							(res.data[i]?.templateType != undefined)
						) {
							res.data[i].templateType = {
								id: 3,
								name: `${t('neo_special_for_customer')}`,
							};
						}
					}

					const filteredSarfReportParameters = res.data;

					for (let filter of filteredSarfReportParameters) {
						total.push({
							SARFTemplateName: filter?.name,
							SARFTemplateType: filter?.templateType?.name,
							Description: filter?.description,
						});
					}
				}

				setFilteredSarfReportTemplates(total);
				setSarfReportTemplates(res?.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllSarfReports = (id) => {
		CustomerSarfReportService.getSarfReportsByCustomerId(id)
			.then((res) => {
				let total = [];
				setSarfReports(res?.data);

				const filteredSarfReports = res?.data;

				for (let filter of filteredSarfReports) {
					total.push({
						SARFReportName: filter?.name,
						SARFReportParameterTemplate:
							filter.sarfReportParametersTemplates?.name,
						SARFPackage: filter?.sarfPackage?.name,
						SeniorityLevel: filter?.seniorityLevel?.name,
						Generation: filter?.generations?.name,
						Description: filter?.description,
					});
				}
				setFilteredSarfReports(total);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const exportExcel = (fileName) => {
		import('xlsx').then((xlsx) => {
			const worksheet = xlsx.utils.json_to_sheet(fileName);
			const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
			const excelBuffer = xlsx.write(workbook, {
				bookType: 'xlsx',
				type: 'array',
			});
			saveAsExcelFile(excelBuffer, 'files');
		});
	};
	const saveAsExcelFile = (buffer, fileName) => {
		import('file-saver').then((module) => {
			if (module && module.default) {
				let EXCEL_TYPE =
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				let EXCEL_EXTENSION = '.xlsx';
				const data = new Blob([buffer], {
					type: EXCEL_TYPE,
				});

				module.default.saveAs(
					data,
					fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
				);
			}
		});
	};

	const handleCloseAddSarfReportModal = () => {
		setAddSarfReportOpen(false);
	};

	const handleCloseUpdateSarfReportModal = () => {
		setUpdateSarfReportOpen(false);
	};
	const handleCloseConfigureSarfReportUser = () => {
		setConfigureSarfReportUserOpen(false);
	};

	const handleCloseAddSarfParameterTemplateModal = () => {
		setAddSarfParameterTemplateOpen(false);
	};
	const handleCloseUpdateSarfParameterTemplateModal = () => {
		setUpdateSarfParameterTemplateOpen(false);
	};

	const handleCloseCalculateAllAppoinmentsModal = () => {
		setCalculateAllAppoinmentsOpen(false);
	};

	const handleOpenCalculateAllAppoinmentsModal = (rowData) => {
		openCalculateAllAppointments();

		setSelectedSarfReport(rowData);
	};
	const openUpdateSarfReportModal = () => {
		if ((customerId != 0) & (customerId != undefined) & (customerId != null)) {
			setUpdateSarfReportOpen(true);
		} else {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('Please save customer ')}`,
			});
		}
	};
	const openAddSarfReportModal = () => {
		if ((customerId != 0) & (customerId != undefined) & (customerId != null)) {
			setAddSarfReportOpen(true);
		} else {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('Please save customer ')}`,
			});
		}
	};
	const checkParameterTemplates = (event) => {
		if (
			(sarfReportTemplates?.length < 1) &
			(customerId != 0) &
			(customerId != undefined) &
			(customerId != null)
		) {
			confirmCheckParameterTemplates(event);
		} else {
			openAddSarfReportModal();
		}
	};
	const openConfigureSarfReportUserModal = () => {
		if ((customerId != 0) & (customerId != undefined) & (customerId != null)) {
			setConfigureSarfReportUserOpen(true);
		} else {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('Please save customer ')}`,
			});
		}
	};

	const openAddSarfParameterModal = () => {
		if ((customerId != 0) & (customerId != undefined) & (customerId != null)) {
			setAddSarfParameterTemplateOpen(true);
		} else {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('Please save customer ')}`,
			});
		}
	};
	const openUpdateSarfParameterModal = () => {
		if ((customerId != 0) & (customerId != undefined) & (customerId != null)) {
			setUpdateSarfParameterTemplateOpen(true);
		} else {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('Please save customer ')}`,
			});
		}
	};

	const openCalculateAllAppointments = () => {
		if ((customerId != 0) & (customerId != undefined) & (customerId != null)) {
			setCalculateAllAppoinmentsOpen(true);
		} else {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('Please save customer ')}`,
			});
		}
	};

	const deleteSvg = (
		<svg
			fill='#EC0044'
			height='32'
			viewBox='0 0 20 20'
			width='24'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M11.5 4C11.5 3.17157 10.8284 2.5 10 2.5C9.17157 2.5 8.5 3.17157 8.5 4H7.5C7.5 2.61929 8.61929 1.5 10 1.5C11.3807 1.5 12.5 2.61929 12.5 4H17C17.2761 4 17.5 4.22386 17.5 4.5C17.5 4.77614 17.2761 5 17 5H16.446L15.1499 16.2292C15.0335 17.2384 14.179 18 13.1631 18H6.83688C5.821 18 4.9665 17.2384 4.85006 16.2292L3.553 5H3C2.75454 5 2.55039 4.82312 2.50806 4.58988L2.5 4.5C2.5 4.22386 2.72386 4 3 4H11.5ZM15.438 5H4.561L5.84347 16.1146C5.90169 16.6192 6.32894 17 6.83688 17H13.1631C13.6711 17 14.0983 16.6192 14.1565 16.1146L15.438 5ZM8.5 7.5C8.74546 7.5 8.94961 7.65477 8.99194 7.85886L9 7.9375V14.0625C9 14.3041 8.77614 14.5 8.5 14.5C8.25454 14.5 8.05039 14.3452 8.00806 14.1411L8 14.0625V7.9375C8 7.69588 8.22386 7.5 8.5 7.5ZM11.5 7.5C11.7455 7.5 11.9496 7.65477 11.9919 7.85886L12 7.9375V14.0625C12 14.3041 11.7761 14.5 11.5 14.5C11.2545 14.5 11.0504 14.3452 11.0081 14.1411L11 14.0625V7.9375C11 7.69588 11.2239 7.5 11.5 7.5Z'
				fill='#EC0044'
			/>
		</svg>
	);
	const editSvg = (
		<svg
			fill='#0099F7'
			height='32'
			viewBox='0 0 20 20'
			width='24'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7 7.5C7 7.22386 7.22386 7 7.5 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H7.5C7.22386 8 7 7.77614 7 7.5ZM7.5 10C7.22386 10 7 10.2239 7 10.5C7 10.7761 7.22386 11 7.5 11H12.5C12.7761 11 13 10.7761 13 10.5C13 10.2239 12.7761 10 12.5 10H7.5ZM7 13.5C7 13.2239 7.22386 13 7.5 13H9.5C9.77614 13 10 13.2239 10 13.5C10 13.7761 9.77614 14 9.5 14H7.5C7.22386 14 7 13.7761 7 13.5ZM6 2.5C6 2.22386 6.22386 2 6.5 2C6.77614 2 7 2.22386 7 2.5V3H9.5V2.5C9.5 2.22386 9.72386 2 10 2C10.2761 2 10.5 2.22386 10.5 2.5V3H13V2.5C13 2.22386 13.2239 2 13.5 2C13.7761 2 14 2.22386 14 2.5V3H14.5C15.3284 3 16 3.67157 16 4.5V9.23163C15.6738 9.37139 15.3683 9.5744 15.102 9.84065L15 9.94266V4.5C15 4.22386 14.7761 4 14.5 4H5.5C5.22386 4 5 4.22386 5 4.5V16.5C5 16.7761 5.22386 17 5.5 17H9.22047L9.05716 17.6532C9.02795 17.7701 9.01015 17.8859 9.00286 18H5.5C4.67157 18 4 17.3284 4 16.5V4.5C4 3.67157 4.67157 3 5.5 3H6V2.5ZM15.8092 10.5478L10.9798 15.3772C10.6982 15.6588 10.4984 16.0116 10.4018 16.3979L10.0274 17.8958C9.86451 18.5472 10.4546 19.1372 11.106 18.9744L12.6039 18.5999C12.9902 18.5033 13.343 18.3036 13.6246 18.022L18.454 13.1926C19.1843 12.4622 19.1843 11.2781 18.454 10.5478C17.7237 9.81741 16.5395 9.81741 15.8092 10.5478Z'
				fill='#0099F7'
			/>
		</svg>
	);
	const actionBodyTemplate1 = (rowData) => {
		return (
			<>
				<Button
					type='button'
					tooltip={t('delete')}
					onClick={(event) => {
						confirmDelete1(event, rowData);
					}}
					className='p-button-text'
					style={{ display: 'flex', justifyContent: 'center' }}
				>
					{deleteSvg}
				</Button>
				<Button
					type='button'
					tooltip={t('edit')}
					onClick={() => {
						openUpdateSarfReportModal();

						setSelectedSarfReport(rowData);
					}}
					className='p-button-text'
					style={{ display: 'flex', justifyContent: 'center' }}
				>
					{editSvg}
				</Button>
				<Button
					type='button'
					tooltip={t('configure')}
					onClick={() => {
						setSelectedSarfReport(rowData);
						openConfigureSarfReportUserModal();
					}}
					className='p-button-text'
					style={{ display: 'flex', justifyContent: 'center' }}
				>
					<svg
						focusable='false'
						width='24'
						height='24'
						aria-hidden='true'
						viewBox='0 0 24 24'
						fill='#808080'
					>
						<path d='M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z'></path>
					</svg>
				</Button>
				<Button
					type='button'
					tooltip={t('calculate_appointments')}
					style={{ color: '#001529' }}
					className='p-button-text p-button-secondary'
					onClick={() => handleOpenCalculateAllAppoinmentsModal(rowData)}
				>
					<svg
						fill='#0099F7'
						height='28'
						viewBox='0 0 20 20'
						width='28'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M17 5.5C17 4.11929 15.8807 3 14.5 3H5.5C4.11929 3 3 4.11929 3 5.5V14.5C3 15.8807 4.11929 17 5.5 17H9.59971C9.43777 16.6832 9.30564 16.3486 9.20703 16H5.5C4.67157 16 4 15.3284 4 14.5V7H16V9.20703C16.3486 9.30564 16.6832 9.43777 17 9.59971V5.5ZM5.5 4H14.5C15.3284 4 16 4.67157 16 5.5V6H4V5.5C4 4.67157 4.67157 4 5.5 4Z'
							fill='#0099F7'
						/>
						<path
							d='M19 14.5C19 16.9853 16.9853 19 14.5 19C12.0147 19 10 16.9853 10 14.5C10 12.0147 12.0147 10 14.5 10C16.9853 10 19 12.0147 19 14.5ZM16.8532 14.854L16.8557 14.8514C16.9026 14.804 16.938 14.7495 16.9621 14.6914C16.9861 14.6333 16.9996 14.5697 17 14.503L17 14.5L17 14.497C16.9996 14.4303 16.9861 14.3667 16.9621 14.3086C16.9377 14.2496 16.9015 14.1944 16.8536 14.1464L14.8536 12.1464C14.6583 11.9512 14.3417 11.9512 14.1464 12.1464C13.9512 12.3417 13.9512 12.6583 14.1464 12.8536L15.2929 14H12.5C12.2239 14 12 14.2239 12 14.5C12 14.7761 12.2239 15 12.5 15H15.2929L14.1464 16.1464C13.9512 16.3417 13.9512 16.6583 14.1464 16.8536C14.3417 17.0488 14.6583 17.0488 14.8536 16.8536L16.8532 14.854Z'
							fill='#0099F7'
						/>
					</svg>
				</Button>
			</>
		);
	};
	const actionBodyTemplate2 = (rowData) => {
		return (
			<>
				<Button
					type='button'
					tooltip={t('delete')}
					onClick={(event) => {
						confirmDelete2(event, rowData);
					}}
					className='p-button-text'
					style={{ display: 'flex', justifyContent: 'center' }}
				>
					{deleteSvg}
				</Button>
				<Button
					type='button'
					tooltip={t('edit')}
					onClick={() => {
						setSelectedSarfReportTemplate(rowData);

						openUpdateSarfParameterModal();
					}}
					className='p-button-text'
					style={{ display: 'flex', justifyContent: 'center' }}
				>
					{editSvg}
				</Button>

				<Link to={`/panel/musteriler/${customerId}/${rowData.id}`}>
					<Button
						type='button'
						tooltip={t('configure')}
						className='p-button-text'
						style={{ display: 'flex', justifyContent: 'center' }}
					>
						<svg
							focusable='false'
							width='24'
							height='24'
							aria-hidden='true'
							viewBox='0 0 24 24'
							fill='#808080'
						>
							<path d='M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z'></path>
						</svg>
					</Button>
				</Link>
			</>
		);
	};

	return (
		<>
			<Toast ref={toast} />

			<div className='card card-custom gutter-b'>
				<div className='card'>
					<TabView
						activeIndex={activeIndex}
						onTabChange={(e) => setActiveIndex(e.index)}
					>
						<TabPanel header={t('sarf_report_list')}>
							<div style={{ width: '100%' }}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										width: '100%',
										width: '100%',
										marginLeft: '1.5%',
										paddingRight: '1.5%',
										marginBottom: '1%',
									}}
								>
									<div>
										<Button
											type='button'
											onClick={() => {
												exportExcel(filteredSarfReports);
											}}
											className='p-button-success mr-2'
											tooltip='XLS'
											icon='pi pi-file-excel'
										></Button>
									</div>
									<div>
										{' '}
										<Button
											type='button'
											icon='pi pi-plus'
											onClick={(event) => checkParameterTemplates(event)}
											className='p-button-raised p-button-rounded p-button-outlined mr-3'
											label={t('create_sarf_report')}
										></Button>
									</div>
								</div>

								<DataTable
									value={sarfReports}
									showGridlines
									scrollable
									scrollHeight='350px'
									dataKey='id'
									selection={selectedList}
									onValueChange={(e) => setSelectedList(e.value)}
									filters={filters1}
									filterDisplay='row'
									tableStyle={{
										height: '100%',
									}}
									globalFilterFields={[
										'name',
										'generations.name',
										'description',
										'seniorityLevel.name',
										'sarfPackage.name',
										'sarfReportParametersTemplates.name',
									]}
									emptyMessage={t('no_records_found')}
									currentPageReportTemplate={t('currentPageReportTemplate')}
								>
									<Column
										style={{ minWidth: '12.5rem' }}
										headerStyle={{
											minWidth: '12.5rem',
											textAlign: 'center',
											width: 'auto',
											display: 'flex',
											justifyContent: 'center',
										}}
										bodyStyle={{
											display: 'flex',
											justifyContent: 'center',
											height: '4rem',
											textAlign: 'center',
										}}
										body={actionBodyTemplate1}
										header={t('action')}
									/>
									<Column
										headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
										bodyStyle={{
											minWidth: '8rem',
											height: '4rem',
										}}
										filter
										sortable={true}
										field='name'
										header={t('sarf_report_name')}
									></Column>
									<Column
										headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
										bodyStyle={{
											minWidth: '8rem',
											height: '4rem',
										}}
										filter
										sortable={true}
										field='sarfReportParametersTemplates.name'
										header={t('sarf_report_parameter_template')}
									></Column>
									<Column
										headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
										bodyStyle={{
											minWidth: '8rem',
											height: '4rem',
										}}
										filter
										sortable={true}
										field='sarfPackage.name'
										header={t('sarf_package')}
									></Column>
									<Column
										headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
										bodyStyle={{
											minWidth: '8rem',
											height: '4rem',
										}}
										filter
										sortable={true}
										field='seniorityLevel.name'
										header={t('seniority_level')}
									></Column>
									<Column
										headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
										bodyStyle={{
											minWidth: '8rem',
											height: '4rem',
										}}
										filter
										sortable={true}
										field='generations.name'
										header={t('generation_name')}
									></Column>
									<Column
										headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
										bodyStyle={{
											minWidth: '8rem',
											height: '4rem',
										}}
										filter
										sortable={true}
										field='description'
										header={t('description')}
									></Column>
								</DataTable>
							</div>
						</TabPanel>

						<TabPanel header={t('sarf_report_parameter_template_list')}>
							<div style={{ width: '100%' }}>
								<div
									style={{
										width: '100%',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											width: '100%',
											marginLeft: '1.5%',
											paddingRight: '1.5%',
											marginBottom: '1%',
										}}
									>
										<div>
											<Button
												type='button'
												className='p-button-success mr-2'
												icon='pi pi-file-excel'
												onClick={() => {
													exportExcel(filteredSarfReportTemplates);
												}}
												tooltip='XLS'
											></Button>
										</div>
										<div>
											{' '}
											<Button
												type='button'
												icon='pi pi-plus'
												onClick={() => openAddSarfParameterModal()}
												className='p-button-raised p-button-rounded p-button-outlined mr-3'
												label={t('create_sarf_parameter_template')}
											></Button>
										</div>
									</div>
								</div>

								<DataTable
									value={sarfReportTemplates}
									showGridlines
									scrollable
									scrollHeight='350px'
									dataKey='id'
									selection={selectedList}
									onValueChange={(e) => setSelectedList(e.value)}
									filters={filters2}
									filterDisplay='row'
									tableStyle={{
										height: '100%',
									}}
									globalFilterFields={[
										'name',
										'templateType.name',
										'description',
									]}
									emptyMessage={t('no_records_found')}
									currentPageReportTemplate={t('currentPageReportTemplate')}
								>
									<Column
										style={{ maxWidth: '160px' }}
										headerStyle={{
											minWidth: '4rem',
											textAlign: 'center',
											display: 'flex',
											justifyContent: 'center',
										}}
										bodyStyle={{
											height: '4rem',
											textAlign: 'center',
										}}
										body={actionBodyTemplate2}
										header={t('action')}
									/>
									<Column
										headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
										bodyStyle={{
											height: '4rem',
										}}
										field='name'
										filter
										sortable={true}
										header={t('sarf_report_name')}
									></Column>
									<Column
										headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
										bodyStyle={{
											height: '4rem',
										}}
										filter
										sortable={true}
										field='templateType.name'
										header={t('sarf_report_template_type')}
									></Column>
									<Column
										headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
										bodyStyle={{
											height: '4rem',
										}}
										filter
										sortable={true}
										field='description'
										header={t('description')}
									></Column>
								</DataTable>
							</div>
						</TabPanel>
					</TabView>
				</div>
				<UpdateSarfParameterTemplateModal
					customerId={customerId}
					setLoading={setLoading}
					getAllSarfReports={getAllSarfReports}
					updateSarfParameterTemplateOpen={updateSarfParameterTemplateOpen}
					handleCloseUpdateSarfParameterTemplateModal={
						handleCloseUpdateSarfParameterTemplateModal
					}
					getAllSarfReportParametersTemplates={
						getAllSarfReportParametersTemplates
					}
					selectedSarfReportTemplate={selectedSarfReportTemplate}
					langValue={langValue}
					t={t}
				></UpdateSarfParameterTemplateModal>
				<AddSarfParameterTemplateModal
					setLoading={setLoading}
					customerId={customerId}
					addSarfParameterTemplateOpen={addSarfParameterTemplateOpen}
					handleCloseAddSarfParameterTemplateModal={
						handleCloseAddSarfParameterTemplateModal
					}
					getAllSarfReports={getAllSarfReports}
					getAllSarfReportParametersTemplates={
						getAllSarfReportParametersTemplates
					}
					langValue={langValue}
					t={t}
				></AddSarfParameterTemplateModal>
				<ConfigureSarfReportUsersModal
					setLoading={setLoading}
					customerId={customerId}
					configureSarfReportUserOpen={configureSarfReportUserOpen}
					handleCloseConfigureSarfReportUser={
						handleCloseConfigureSarfReportUser
					}
					getAllSarfReports={getAllSarfReports}
					selectedSarfReport={selectedSarfReport}
					t={t}
				></ConfigureSarfReportUsersModal>

				<CalculateAllAppointmentsModal
					setLoading={setLoading}
					customerId={customerId}
					calculateAllAppoinmentsOpen={calculateAllAppoinmentsOpen}
					handleCloseCalculateAllAppoinmentsModal={
						handleCloseCalculateAllAppoinmentsModal
					}
					selectedSarfReport={selectedSarfReport}
					t={t}
				></CalculateAllAppointmentsModal>

				<UpdateSarfReportModal
					setLoading={setLoading}
					customerId={customerId}
					updateSarfReportOpen={updateSarfReportOpen}
					handleCloseUpdateSarfReportModal={handleCloseUpdateSarfReportModal}
					getAllSarfReports={getAllSarfReports}
					selectedSarfReport={selectedSarfReport}
					t={t}
				></UpdateSarfReportModal>
				<AddSarfReportModal
					setLoading={setLoading}
					customerId={customerId}
					addSarfReportOpen={addSarfReportOpen}
					handleCloseAddSarfReportModal={handleCloseAddSarfReportModal}
					getAllSarfReports={getAllSarfReports}
					t={t}
				></AddSarfReportModal>
			</div>
		</>
	);
};
export default withTranslation()(CustomerSarfDetails);
