import React from 'react';
import { Box, Button, Container, Typography } from '@material-ui/core';
import NotFoundSvg from '../assets/svg/404NotFound.svg';
import LayoutComponent from '../components/LayoutComponent';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const NotFound404 = ({ t }) => {
  let history = useHistory();
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        marginTop: '20px',
      }}
    >
      <Container maxWidth="md">
        <Box sx={{ textAlign: 'center' }}>
          <img
            alt="Not Found"
            src={NotFoundSvg}
            style={{
              marginBottom: 50,
              display: 'inline-block',
              maxWidth: '100%',
              width: 560,
            }}
          />
        </Box>
        <Typography align="center" color="textPrimary" variant="h4">
          {t('page_not_found')}
        </Typography>
        <Box
          marginTop="20px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push('/')}
          >
            {t('home_page')}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default withTranslation()(NotFound404);
