import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './Reports.css';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import ExpertReviewAppointmentService from '../../service/ExpertReviewAppointmentService';
import { useParams } from 'react-router-dom';
import Spinner from '../../components/common/Spinner';
import NotFoundImage from '../../assets/images/no-data-gorseli.png';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

const SocioEmotional = ({ t }) => {
  // const chart = useRef(null);
  // const classes = useStyles();
  // let history = useHistory();
  // let {path, url} = useRouteMatch();
  const [detail, setDetail] = useState({});
  const params = useParams();
  const appointmentId = parseInt(params.id);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);

  useLayoutEffect(() => {
    setLoading(true);
    ExpertReviewAppointmentService.getDetail(appointmentId).then((res) => {
      if (res != null && res?.data != null) {
        let item = res.data.filter((f) => f.metricModule?.id === 3)[0];
        if (item != null) {
          setDetail(item);
          setLoading(false);
        } else {
          setDisplay(true);
          setLoading(false);
        }
      }
    });
  }, [appointmentId]);

  const Image = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <img style={{ maxWidth: '600px' }} src={NotFoundImage} alt="" />
        <h1 style={{ fontWeight: 'bold' }}>{t('data_not_found')}</h1>
      </div>
    );
  };

  return (
    <div>
      <Spinner loading={loading} />
      <h3 style={{ fontSize: '20px', fontWeight: 'bold' }}>
        {t('socio_emotional')}
      </h3>
      <div dangerouslySetInnerHTML={{ __html: detail.reviewText }} />
      {display ? <Image /> : null}
    </div>
  );
};

export default withTranslation()(SocioEmotional);
