import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import Button from '@material-ui/core/Button';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MainButton } from './common/MainButton';
import FormInput from './common/FormInput';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AuthenticationService from '../service/AuthenticationService';
import { useSnapshot } from 'valtio';
import store from './../Store';
import { Link, useHistory } from 'react-router-dom';
import DashboardMenu from './DashboardMenu';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import i18next, { t } from 'i18next';
import {
  IconFlagTR,
  IconFlagDE,
  IconFlagUS,
  IconFlagRU,
} from 'material-ui-flags';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  rootFlexGrow: {
    flexGrow: 1,
  },
  image: {
    width: 200,
    [theme.breakpoints.down('md')]: {
      width: 100,
    },
    [theme.breakpoints.down('lg')]: {
      width: 150,
    },
  },
  logoWrapper: {
    float: 'left',
    width: 200,
    height: '100%',
    marginLeft: 50,
    marginRight: 50,

    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginLeft: 10,
      marginRight: 10,
    },
  },
  appBar: {
    backgroundColor: '#001529',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
    display: 'inline-block',
  },
  menuButtonHidden: {
    color: theme.palette.common.white,
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    backgroundColor: '#001529',
    color: theme.palette.common.white,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    width: 0,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingInline: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  muiIcon: {
    padding: 2,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  // necessary for content to be below app bar
  toolbar: {
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  button: { textTransform: 'none' },
  tab: {
    textTransform: 'none',
    fontSize: theme.typography.h6.fontSize,
  },
}));
const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [passwordModal, setPasswordModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  const { t } = useTranslation();

  const Schema = Yup.object().shape({
    password: Yup.string()
      .required(`${t('schema_shape.empty_password')}`)
      .min(8, `${t('schema_shape.min_password')}`),
    confirmPassword: Yup.string()
      .required(`${t('schema_shape.empty_password')}`)
      .oneOf(
        [Yup.ref('password'), null],
        `${t('schema_shape.match_password')}`
      ),
  });
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(Schema),
  });

  const selected = localStorage.getItem('i18nextLng') || 'en';
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimenion]);

  const menuOpen = Boolean(anchorEl);

  let history = useHistory();
  const user = AuthenticationService.getLoggedInUser();
  const fullName = `${user.name} ${user.surname}`;

  const snapshot = useSnapshot(store);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    AuthenticationService.logout();
    history.push('/');
  };

  const unlisten = history.listen(() => {
    if (windowDimenion.winWidth <= 768) {
      setOpen(false);
    } else if (windowDimenion.winWidth >= 768) {
      setOpen(true);
    }
    return;
  });

  useEffect(() => {
    unlisten();
  }, []);
  const [lang, setLang] = useState(selected);
  // const selected = setLang(localStorage.getItem("i18nextLng") || "en");

  const languageItems = [
    { label: 'English', value: 'en', code: 'US', flag: `${IconFlagUS}` },
    { label: 'Türkçe', value: 'tr', code: 'TR', flag: `${IconFlagTR}` },
    // { label: 'Русский', value: 'ru', code: 'RU', flag: `${IconFlagRU}` },
  ];
  const onLangChange = (e) => {
    setLang(e.value);
    i18next.changeLanguage(e.value);
  };
  const countryOptionTemplate = (option) => {
    switch (option.value) {
      case 'en':
        return (
          <div className='country-item'>
            <IconButton className={classes.muiIcon}>
              <IconFlagUS />
            </IconButton>
            <div>{option.label}</div>
          </div>
        );
      case 'tr':
        return (
          <div className='country-item'>
            <IconButton className={classes.muiIcon}>
              <IconFlagTR />
            </IconButton>
            <div>{option.label}</div>
          </div>
        );
      case 'ru':
        return (
          <div className='country-item'>
            <IconButton className={classes.muiIcon}>
              <IconFlagRU />
            </IconButton>
            <div>{option.label}</div>
          </div>
        );
      default:
        return (
          <div className='country-item'>
            <IconButton className={classes.muiIcon}>
              <IconFlagTR />
            </IconButton>
            <div>{option.label}</div>
          </div>
        );
    }
  };
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className='country-item country-item-value'>
          {option.value === 'en' ? (
            <IconButton className={classes.muiIcon}>
              <IconFlagUS />
            </IconButton>
          ) : (
            <IconButton className={classes.muiIcon}>
              <IconFlagTR />
            </IconButton>
          )}
          <div>{option.label}</div>
        </div>
      );
    }
  };

  const passwordModalOpen = () => {
    setPasswordModal(true);
    setAnchorEl(null);
  };

  const PasswordChangeFormModal = () => {
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClosePasswordModal = () => {
      setPasswordModal(false);
    };

    const changePassword = (data) => {
      const payload = {
        password: data.password,
        userId: user.userId,
      };
      axios
        .post('/users/changePassword', payload)
        .then(() => {
          enqueueSnackbar(`${t('enqueueSnackbar_message.change_password')}`, {
            variant: 'success',
          });
          handleClosePasswordModal();
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          });
          handleClosePasswordModal();
        });
    };

    return (
      <div>
        <Dialog open={passwordModal} onClose={handleClosePasswordModal}>
          <DialogTitle style={{ textAlign: 'center' }}>
            {t('changed_password')}
          </DialogTitle>
          <DialogContent>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(changePassword)}
            >
              <Controller
                name='password'
                control={control}
                render={(props) => (
                  <FormInput
                    {...props}
                    margin='dense'
                    type='password'
                    required
                    label={t('password')}
                  />
                )}
              />
              <Controller
                name='confirmPassword'
                control={control}
                render={(props) => (
                  <FormInput
                    {...props}
                    type='password'
                    margin='dense'
                    required
                    label={t('repeat_password')}
                  />
                )}
              />
              <div className='d-flex justify-content-around mt-5'>
                <MainButton
                  type='submit'
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                >
                  {t('UPDATE')}
                </MainButton>
                <MainButton
                  type='button'
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  onClick={handleClosePasswordModal}
                >
                  {t('GIVE_UP')}
                </MainButton>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <PasswordChangeFormModal />
      <AppBar
        position='absolute'
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.logoWrapper}>
            <img
              id='company-logo'
              alt='NeoAuvra logo'
              src='/logo.png'
              className={classes.image}
            />
          </div>
          <Tabs
            value={snapshot.activeTabIndex}
            onChange={(e, index) => (store.activeTabIndex = index)}
          >
            <Tab
              style={
                AuthenticationService.isAdmin() ||
                AuthenticationService.isOperator() ||
                AuthenticationService.isExpert() ||
                AuthenticationService.isLocationAdmin()
                  ? { display: 'none' }
                  : ''
              }
              className={classes.tab}
              component={Link}
              to='/'
              label={t('home_page')}
            />
            {(AuthenticationService.isAdmin() ||
              AuthenticationService.isOperator() ||
              AuthenticationService.isExpert() ||
              AuthenticationService.isLocationAdmin() ||
              AuthenticationService.isDoctor()) && (
              <Tab
                className={classes.tab}
                component={Link}
                to='/panel'
                label={t('dashboard')}
              />
            )}
            <Tab
              style={
                AuthenticationService.isAdmin() ||
                AuthenticationService.isOperator() ||
                AuthenticationService.isExpert() ||
                AuthenticationService.isLocationAdmin()
                  ? { display: 'none' }
                  : ''
              }
              className={classes.tab}
              component={Link}
              to='/demographicsInfo'
              label={t('demographics_info')}
            />
          </Tabs>
          <div className={classes.rootFlexGrow} />

          <div>
            <Button
              color='inherit'
              size='large'
              onClick={handleMenu}
              className={classes.button}
              startIcon={<AccountCircle />}
              endIcon={<ArrowDropDownRoundedIcon />}
            >
              {fullName}
            </Button>
            <Menu
              id='menu-appbar'
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={menuOpen}
              onClose={handleClose}
            >
              <MenuItem onClick={passwordModalOpen}>
                {t('change_password')}
              </MenuItem>
              <MenuItem onClick={handleLogout}>{t('log_out')}</MenuItem>
            </Menu>
          </div>
          <div className='country'>
            <Dropdown
              appendTo='self'
              value={lang}
              options={languageItems}
              onChange={onLangChange}
              optionLabel='label'
              placeholder={t('select_language')}
              valueTemplate={selectedCountryTemplate}
              itemTemplate={countryOptionTemplate}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton style={{ color: 'white' }} onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <DashboardMenu drawerOpen={open} />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Grid container className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

export default DashboardLayout;
