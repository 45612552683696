import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';

const WelcomeDoctor = ({ t }) => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Box marginTop={15}>
        <Typography variant="h5">
          {t('doctor_demographic_completed_message')}
        </Typography>
      </Box>
    </Grid>
  );
};

export default withTranslation()(WelcomeDoctor);
