import axios from 'axios';

class CustomerSarfModuleParametersService {
	getAllSarfModuleParameters() {
		return axios.get('/lookup/getAllSarfReportModuleParameters');
	}

	getModulesBySarfReportParametersTemplateId(id) {
		return axios.get(
			'/lookup/getSarfReportModuleParametersBySarfReportParametersTemplateId/' +
				id
		);
	}

	getById(id) {
		return axios.get('/lookup/getSarfReportModuleParameterById/' + id);
	}

	save(row) {
		return axios.post('/lookup/saveSarfReportModuleParameter', row);
	}

	update(row) {
		return axios.post('/lookup/updateSarfReportModuleParameter', row);
	}
	delete(id) {
		return axios.delete('/lookup/deleteSarfReportModuleParameterById/' + id);
	}
}

export default new CustomerSarfModuleParametersService();
