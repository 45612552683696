import React, { useEffect, useState, useContext, useLayoutEffect } from 'react';
// import Button from '@material-ui/core/Button'
import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { IconButton, Typography, withStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@mui/material/Tooltip';
import { logDOM } from '@testing-library/react';
import store from '../../Store';
const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogComponent = ({
  children,
  visible,
  title,
  maxWidth,
  buttonText,
  appointmentId,
  buttonColor,
  svg,
  onCreate,
}) => {
  const [open, setOpen] = useState(false);
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant='h6'>{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  useEffect(() => {
    setOpen(false);
  }, [visible]);

  return (
    <div>
      <Button
        className='p-button-text'
        tooltip={buttonText}
        onClick={() => setOpen(true)}
      >
        {svg ? svg : buttonText}
      </Button>

      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={maxWidth ? maxWidth : 'lg'}
        onClose={() => setOpen(false)}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title' onClose={() => setOpen(false)}>
          {title}
          {appointmentId && <> - {appointmentId}</>}
        </DialogTitle>

        <DialogContent
          onClose={() => setOpen(false)}
          style={{ minHeight: 400 }}
        >
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default React.memo(DialogComponent);
