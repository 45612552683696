import React from "react";
import ComponentWrapper from "./../components/ComponentWrapper";
import {useSnapshot} from "valtio";
import store from "./../Store";
import {FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography,} from "@material-ui/core";
import AuthenticationService from "../service/AuthenticationService";
import {withTranslation} from "react-i18next";

const Welcome = () => {
  const snapshot = useSnapshot(store);

  store.consentGiven = true;
  const onChange = (e) => {
    if (e.target.value === "true") {
      store.consentGiven = true;
    } else {
      store.consentGiven = false;
    }
  };

  return (
    <ComponentWrapper subtitle="neOAUVRA Ön İzlem formuna Hoşgeldiniz!">
      <Grid container>
        {AuthenticationService.isDoctor() && (
        <Typography variant="body1" gutterBottom>
          Bu formda sizden aşağıdaki başlıklarla ilgili olarak çeşitli bilgiler
          istenecektir: <br />
          <br />
          - Doktor bilgileri <br />
        </Typography>
            )}
        {!AuthenticationService.isDoctor() && (
            <Typography variant="body1" gutterBottom>
              Bu formda sizden aşağıdaki başlıklarla ilgili olarak çeşitli bilgiler
              istenecektir: <br />
              <br />
              - Demografik bilgiler <br />
              - Yaşam biçimi <br />
              - Günlük işlevsellik <br />
              - Sağlık geçmişi <br />
              - Bireysel değerlendirme <br />
              - Fiziksel aktiviteye hazır olma anketi <br />
            </Typography>
        )}

        <Typography variant="body1" gutterBottom>
          Bu başlıklardaki maddelere dair vereceğiniz cevapların doğruluğu,
          neOAUVRA ürünlerinden elde edeceğiniz deneyimin size daha uygun olması
          için oldukça önemlidir.
          <br />
          <br />
          {/*Formda istenilen bilgiler tamamen araştırma amaçlı kullanılacak olup;*/}
          {/*üçüncü kişi ve kurumlarla paylaşılmayacaktır.*/}
        </Typography>
        <br />
        {/*<Typography variant="body1" gutterBottom>*/}
        {/*  Formu doldurmayı ve bilgilerimin saklanmasını;*/}
        {/*</Typography>*/}
        <br />
        <br />
        {/*<Grid container>*/}
        {/*  <FormControl component="fieldset">*/}
        {/*    <RadioGroup*/}
        {/*      aria-label="gender"*/}
        {/*      name="gender1"*/}
        {/*      value={snapshot.consentGiven ? "true" : "false"}*/}
        {/*      onChange={onChange}*/}
        {/*    >*/}
        {/*      <FormControlLabel*/}
        {/*        value="true"*/}
        {/*        control={<Radio />}*/}
        {/*        label="Kabul ediyorum"*/}
        {/*      />*/}
        {/*      <FormControlLabel*/}
        {/*        value="false"*/}
        {/*        control={<Radio />}*/}
        {/*        label="Kabul etmiyorum"*/}
        {/*      />*/}
        {/*    </RadioGroup>*/}
        {/*  </FormControl>*/}
        {/*</Grid>*/}
      </Grid>
    </ComponentWrapper>
  );
};

export default withTranslation()(Welcome);
