import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
}))

const Popconfirm = ({
  onConfirm,
  title,
  description,
  buttonText,
  style,
  okText,
  cancelText,
  color,
  variant,
  disabled,
}) => {
  const anchorRef = React.useRef(null)
  const [arrowRef, setArrowRef] = React.useState(null)

  const [open, setOpen] = React.useState(false)

  const handleClickButton = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClickAway = () => {
    setOpen(false)
  }

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Button
        style={style}
        ref={anchorRef}
        variant={variant ? variant : 'outlined'}
        color={color ? color : 'primary'}
        disabled={disabled}
        onClick={handleClickButton}
      >
        {buttonText}
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom"
        disablePortal={false}
        className={classes.popper}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <span className={classes.arrow} ref={setArrowRef} />
            <Paper className={classes.paper}>
              <DialogTitle>{title}</DialogTitle>
              <DialogContent>
                <DialogContentText>{description}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClickButton}>{cancelText}</Button>
                <Button
                  onClick={(e) => {
                    setOpen(false)
                    onConfirm(e)
                  }}
                  color="primary"
                  variant="contained"
                >
                  {okText}
                </Button>
              </DialogActions>
            </Paper>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

export default Popconfirm
