import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import PageTitle from './../components/PageTitle';
import moment from 'moment';
import 'moment/locale/tr';
// import Datatable from './../components/common/Datatable'
import { Button } from '@material-ui/core';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { withTranslation } from 'react-i18next';
import AlertDialog from '../components/DialogComp';
import AuthenticationService from '../service/AuthenticationService';

const Appointments = ({ t }) => {
	const [appointments, setAppointments] = useState([]);
	const [dateChangeLang, setDateChangeLang] = useState('tr-TR');

	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		approved: { value: null, matchMode: FilterMatchMode.EQUALS },
		appointmentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		parentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		userId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		name: { value: null, matchMode: FilterMatchMode.CONTAINS },
		surname: { value: null, matchMode: FilterMatchMode.CONTAINS },
		type: { value: null, matchMode: FilterMatchMode.CONTAINS },
		stationFull: { value: null, matchMode: FilterMatchMode.CONTAINS },
		completed: { value: null, matchMode: FilterMatchMode.CONTAINS },
		start: { value: null, matchMode: FilterMatchMode.CONTAINS },
		end: { value: null, matchMode: FilterMatchMode.CONTAINS },
		date: { value: null, matchMode: FilterMatchMode.EQUALS },
		operatorId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		operatorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
		assessmentPackageName: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [loading, setLoading] = useState(true);
	const langValue = localStorage.getItem('i18nextLng') ?? 'tr';

	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [checkIsAdminFetchUrl, setCheckIsAdminFetchUrl] = useState();
	const toast = useRef(null);
	const user = JSON.parse(localStorage.authenticatedUser);

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };
		_filters['global'].value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	useEffect(() => {
		if (AuthenticationService.isAdmin()) {
			fetchAllAppointments();
		} else if (AuthenticationService.isOperator()) {
			fetchAllAppointmentsForOperator();
		} else {
			fetchAllAppointmentsForLocationAdmin();
		}
	}, [langValue]);

	const fetchAllAppointments = async () => {
		setLoading(true);
		const { data } = await axios.get('/appointments/all');
		setFetchedData(data);
	};
	const fetchAllAppointmentsForOperator = async () => {
		setLoading(true);
		const { data } = await axios.get(
			'/appointments/allForOperator/' + user.userId
		);
		setFetchedData(data);
	};
	const fetchAllAppointmentsForLocationAdmin = async () => {
		setLoading(true);
		const { data } = await axios.get(
			'/appointments/allForLocation/' + user.userId
		);
		setFetchedData(data);
	};

	const setFetchedData = (response) => {
		for (let data of response) {
			const location = data.station?.location;
			data.stationFull =
				location?.country?.name +
				'/' +
				location?.city?.name +
				'/' +
				location?.name +
				'/' +
				data.station?.name;
			data.completed = data.completed ? `${t('yes')}` : `${t('no')}`;
			data.approved = data.approved
				? `${t('approved')}`
				: `${t('pending_approve')}`;
			data.fullName = data?.name + ' ' + data?.surname;
		}
		setAppointments(response);
		setLoading(false);
	};

	useEffect(() => {
		if (langValue === 'en') {
			setDateChangeLang('en-US');
		} else {
			setDateChangeLang('tr-TR');
		}
	}, [langValue]);

	const renderHeader = () => {
		return (
			<div className='clearfix'>
				<div className='float-right p-2'>
					<span className='p-input-icon-left'>
						<i className='pi pi-search' />
						<InputText
							value={globalFilterValue}
							className='p-inputtext-sm'
							onChange={onGlobalFilterChange}
							placeholder={t('search...')}
						/>
					</span>
				</div>
			</div>
		);
	};

	const actionOperatorEdit = (rowData) => {
		return (
			<>
				<AlertDialog
					operatorInfo={rowData.operatorId}
					selected={rowData.operatorName}
				></AlertDialog>
			</>
		);
	};

	return (
		<>
			<PageTitle>{t('all_appointments')}</PageTitle>
			<Toast ref={toast} />
			<div className='card card-custom gutter-b'>
				<div className='card-body'>
					<DataTable
						value={appointments}
						paginator
						showGridlines
						responsiveLayout='scroll'
						header={renderHeader}
						rows={10}
						paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
						rowsPerPageOptions={[10, 25, 50]}
						dataKey='id'
						rowHover
						filters={filters}
						filterDisplay='row'
						loading={loading}
						globalFilterFields={[
							'location.name',
							'name',
							'surname',
							'appointmentId',
							'name',
						]}
						emptyMessage={t('no_records_found')}
						currentPageReportTemplate={t('currentPageReportTemplate')}
					>
						{AuthenticationService.isLocationAdmin() && (
							<Column
								header={t('name_surname')}
								sortable={true}
								field='fullName'
								filter
								style={{ maxWidth: '100px' }}
							></Column>
						)}

						<Column
							field='approved'
							header={t('confirmation_status')}
							bodyClassName='text-center'
							style={{ minWidth: '100px' }}
							filter
							sortable={true}
						/>

						<Column
							header={t('appointment_id')}
							sortable={true}
							field='appointmentId'
							filter
							style={{ maxWidth: '100px' }}
						></Column>
						{AuthenticationService.isAdmin() && (
							<Column
								header={t('parent_id')}
								sortable={true}
								field='parentId'
								filter
								style={{ maxWidth: '100px' }}
							></Column>
						)}
						<Column
							header={t('user_id')}
							sortable={true}
							field='userId'
							filter
							style={{ maxWidth: '100px' }}
						></Column>
						{AuthenticationService.isAdmin() && (
							<Column
								header={t('name')}
								sortable={true}
								field='name'
								filter
								style={{ minWidth: '100px' }}
							></Column>
						)}
						{AuthenticationService.isAdmin() && (
							<Column
								header={t('surname')}
								sortable={true}
								field='surname'
								filter
								style={{ minWidth: '100px' }}
							></Column>
						)}
						<Column
							header={t('assessment_package')}
							field='assessmentPackageName'
							sortable={true}
							filter
							style={{ maxWidth: '120px' }}
						></Column>
						<Column
							header={t('type')}
							field='type'
							sortable={true}
							filter
							style={{ maxWidth: '100px' }}
						></Column>

						<Column
							header={t('hub')}
							sortable={true}
							field='stationFull'
							filter
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('is_completed')}
							sortable={true}
							field='completed'
							filter
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('start_time')}
							sortable={true}
							field='start'
							filter
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('end_time')}
							sortable={true}
							field='end'
							filter
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('date')}
							sortable={true}
							field='date'
							body={(rowData) => {
								let d = rowData.date;
								let date = new Date(d);
								let formattedDate = date.toLocaleDateString(dateChangeLang);
								return formattedDate;
							}}
							filter
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('operatorId')}
							sortable={true}
							field='operatorId'
							// body={actionOperatorEdit}
							filter
							style={{
								maxWidth: '100px',
								cursor: 'pointer',
							}}
						></Column>
						<Column
							header={t('operatorName')}
							sortable={true}
							field='operatorName'
							filter
							style={{ minWidth: '100px' }}
						></Column>
					</DataTable>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(Appointments);
