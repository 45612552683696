import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import PageTitle from './../components/PageTitle';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/tr';
import { Divider, Grid, Typography } from '@material-ui/core';
import { MTableToolbar } from 'material-table';
import Popconfirm from '../components/common/Popconfirm';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { confirmPopup } from 'primereact/confirmpopup';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { withTranslation } from 'react-i18next';
import AlertDialog from '../components/DialogComp';
import CreateNewAppointment from '../components/CreateNewAppointment';
import DialogComponent from '../components/common/DialogComponent';
import AuthenticationService from '../service/AuthenticationService';
import DatePicker, { registerLocale } from 'react-datepicker';
const CustomDropdown = styled(Dropdown)`
	/* Target the dropdown input */
	.p-dropdown-label {
		padding: 0.36rem;
		/* Add more styles here */
	}
`;

const NotCompletedAppointments = ({ t }) => {
	const [appointments, setAppointments] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [appoint, setAppoint] = useState([]);
	const toast = useRef(null);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(true);
	const [dateChangeLang, setDateChangeLang] = useState('tr-TR');
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		appointmentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		parentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		userId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
		userSurname: { value: null, matchMode: FilterMatchMode.CONTAINS },
		type: { value: null, matchMode: FilterMatchMode.CONTAINS },
		hub: { value: null, matchMode: FilterMatchMode.CONTAINS },
		completedYesNo: { value: null, matchMode: FilterMatchMode.CONTAINS },
		startTime: { value: null, matchMode: FilterMatchMode.CONTAINS },
		endTime: { value: null, matchMode: FilterMatchMode.CONTAINS },
		date: { value: null, matchMode: FilterMatchMode.CONTAINS },
		operatorId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		operatorFullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
		assessmentPackageName: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [first, setFirst] = useState(0); // Add this line
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState(null);
	const [startDate, setStartDate] = useState('');
	const [endTime, setEndTime] = useState('');
	const [startTime, setStartTime] = useState('');
	const [rows, setRows] = useState(10);
	const [assessmentPackages, setAssesmentPackages] = useState([]);
	const langValue = localStorage.getItem('i18nextLng') ?? 'tr';
	const user = JSON.parse(localStorage.authenticatedUser);
	const appointmentTypes = [
		{
			type: 'A',
			label: 'A',
			description: `${t('create_appointment_types.desc_A')}`,
		},
		{
			type: 'B',
			label: 'B',
			description: `${t('create_appointment_types.desc_B')}`,
		},
		{
			type: 'C',
			label: 'C',
			description: `${t('create_appointment_types.desc_C')}`,
		},
		{
			type: 'X',
			label: 'GEN',
			description: `${t('create_appointment_types.desc_X')}`,
		},
		{
			type: 'Z',
			label: `${t('gen_managers')}`,
			description: `${t('create_appointment_types.desc_Z')}`,
		},
	];

	useEffect(() => {
		getAssesmentPackages();
		fetchApprovedAppointments(first, rows, sortField, sortOrder, filters);
	}, [langValue, first, rows, sortField, sortOrder, filters]);

	const getAssesmentPackages = async () => {
		try {
			const result = await axios.get('/users/getAssessmentPackages');

			setAssesmentPackages(result.data);
		} catch (err) {
			console.log(err);
		}
	};

	const fetchApprovedAppointments = async (
		first,
		rows,
		sortField,
		sortOrder,
		filters
	) => {
		setLoading(true);
		let page = Math.floor(first / rows) ? Math.floor(first / rows) : 0;
		let formattedFilters = formatParams(filters);
		const payload = {
			page: page,
			size: rows,
			sortOrder: sortOrder === 1 ? 'asc' : 'desc',
			...formattedFilters,
			sortBy: sortField,
			isApproved: true,
			isCompleted: false,
			isActive: false,
		};
		if (AuthenticationService.isAdmin()) {
			try {
				const { data } = await axios.get(
					'/appointments/getAppointmentsPagination',
					{ params: payload }
				);
				getFormatData(data?.appointmentDTOList);
				setTotalRecords(data?.totalRecords);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		} else if (AuthenticationService.isOperator()) {
			try {
				const { data } = await axios.get(
					'/appointments/getAppointmentsPagination',
					{
						params: {
							...payload,
							operatorId: user.userId,
							isOperatorPanel: true,
						},
					}
				);
				getFormatData(data?.appointmentDTOList);
				setTotalRecords(data?.totalRecords);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		} else {
			try {
				const { data } = await axios.get(
					'/appointments/getAppointmentsPagination',
					{ params: { ...payload, locationAdminId: user.userId } }
				);
				getFormatData(data?.appointmentDTOList);
				setTotalRecords(data?.totalRecords);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		}
	};
	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};
	const formatParams = (params) => {
		let formattedParams = {};

		if (params) {
			for (let field in params) {
				if (params[field].value !== undefined && params[field].value !== null) {
					formattedParams[field] = params[field].value;
				}
			}
		}

		return formattedParams;
	};
	const onSort = (event) => {
		setSortField(event.sortField);
		setSortOrder(event.sortOrder);
	};

	const getFormatData = (res) => {
		if (res != null) {
			for (const data of res) {
				data.hub =
					data.station?.location?.country?.name +
					'/' +
					data.station?.location?.city?.name +
					'/' +
					data.station?.location?.name +
					'/' +
					data.station?.name;
				data.userName = data.name; // assign the value of data.name to data.userName
				data.userSurname = data.surname; // assign the value of data.surname to data.userSurname
				data.startTime = data.start;
				data.endTime = data.end;
				data.operatorFullName = data.operatorName;
				delete data.name; // remove the name property from data
				delete data.surname; // remove the surname property from data
				delete data.start; // remove the start property from data
				delete data.end; // remove the end property from data
				delete data.operatorName; // remove the operatorName property from data
				data.fullName = data?.userName + ' ' + data?.userSurname; // Use data.userName instead of data.name
			}
			setAppointments(res);
			setLoading(false);
		}
	};

	const onGlobalFilterChange = (e) => {
		const value = e.target.value.trim();
		let _filters = { ...filters };
		_filters['global'].value = value;

		if (value.length > 2 || value.length === 0) {
			setFilters(_filters);
		}

		setGlobalFilterValue(value);
	};

	const accept1 = (id) => {
		toast.current.show({
			severity: 'info',
			summary: `${t('appointment_alert_messages.congratulations')}`,
			detail: `${t('appointment_alert_messages.appointment_delete_success')}`,
			life: 3000,
		});
		cancelAppointment(id);
	};

	const reject1 = () => {
		toast.current.show({
			severity: 'warn',
			summary: `${t('appointment_alert_messages.denied')}`,
			detail: `${t('appointment_alert_messages.appointment_delete_error')}`,
			life: 3000,
		});
	};

	const cancelAppointment = (id) => {
		axios
			.get(`/appointments/cancelAppointment/${id}`)
			.then((res) => {
				fetchApprovedAppointments(first, rows, sortField, sortOrder, filters);
			})
			.catch((err) => {
				error(err);
			});
	};

	const accept = (id) => {
		updateAppointment(id);
	};

	const reject = () => {
		toast.current.show({
			severity: 'warn',
			summary: `${t('appointment_alert_messages.denied')}`,
			detail: `${t('appointment_alert_messages.action_declined')}`,
			life: 3000,
		});
	};

	const completeAppointment = (id) => {
		axios.get('/appointments/setCompleted', { params: { id } }).then((res) => {
			setAppoint(res.data);
			// fetchApprovedAppointments()
		});
	};

	const updateAppointment = (id) => {
		axios
			.get(`/appointments/updateActiveAppointment/${id}`)
			.then((res) => {
				toast.current.show({
					severity: 'info',
					summary: `${t('appointment_alert_messages.congratulations')}`,
					detail: `${t('appointment_alert_messages.data_active_success')}`,
					life: 3000,
				});
				fetchApprovedAppointments(first, rows, sortField, sortOrder, filters);
			})
			.catch((err) => {
				error(err);
			});
	};

	const error = (err) => {
		toast.current.show({
			severity: 'error',
			summary: `${t('appointment_alert_messages.short_error')}`,
			detail: `${err.response.data}`,
			life: 3000,
		});
	};
	const confirm1 = (id) => {
		confirmDialog({
			message: `${id} ${t(
				'appointment_alert_messages.want_delete_appointment'
			)}`,
			header: `${t('appointment_alert_messages.confirmation')}`,
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
			accept: () => accept1(id),
			reject: () => reject1(),
		});
	};

	const confirm = (id) => {
		confirmDialog({
			message: `${id} ${t(
				'appointment_alert_messages.confirm_sure_activated_appointment'
			)}`,
			header: `${t('appointment_alert_messages.confirmation')}`,
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
			accept: () => accept(id),
			reject: () => reject(),
		});
	};

	const renderHeader = () => {
		return (
			<div className='clearfix'>
				<div className='float-right p-2'>
					<span className='p-input-icon-left'>
						<i className='pi pi-search' />
						<InputText
							value={globalFilterValue}
							className='p-inputtext-sm'
							onKeyPress={(e) => {
								if (e.target.value.length === 0 && e.key === ' ') {
									e.preventDefault();
								}
							}}
							onChange={onGlobalFilterChange}
							placeholder={t('search...')}
						/>
					</span>
				</div>
			</div>
		);
	};

	const deleteSvg = (
		<svg
			fill='#EC0044'
			height='32'
			viewBox='0 0 20 20'
			width='32'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M11.5 4C11.5 3.17157 10.8284 2.5 10 2.5C9.17157 2.5 8.5 3.17157 8.5 4H7.5C7.5 2.61929 8.61929 1.5 10 1.5C11.3807 1.5 12.5 2.61929 12.5 4H17C17.2761 4 17.5 4.22386 17.5 4.5C17.5 4.77614 17.2761 5 17 5H16.446L15.1499 16.2292C15.0335 17.2384 14.179 18 13.1631 18H6.83688C5.821 18 4.9665 17.2384 4.85006 16.2292L3.553 5H3C2.75454 5 2.55039 4.82312 2.50806 4.58988L2.5 4.5C2.5 4.22386 2.72386 4 3 4H11.5ZM15.438 5H4.561L5.84347 16.1146C5.90169 16.6192 6.32894 17 6.83688 17H13.1631C13.6711 17 14.0983 16.6192 14.1565 16.1146L15.438 5ZM8.5 7.5C8.74546 7.5 8.94961 7.65477 8.99194 7.85886L9 7.9375V14.0625C9 14.3041 8.77614 14.5 8.5 14.5C8.25454 14.5 8.05039 14.3452 8.00806 14.1411L8 14.0625V7.9375C8 7.69588 8.22386 7.5 8.5 7.5ZM11.5 7.5C11.7455 7.5 11.9496 7.65477 11.9919 7.85886L12 7.9375V14.0625C12 14.3041 11.7761 14.5 11.5 14.5C11.2545 14.5 11.0504 14.3452 11.0081 14.1411L11 14.0625V7.9375C11 7.69588 11.2239 7.5 11.5 7.5Z'
				fill='#EC0044'
			/>
		</svg>
	);

	const editSvg = (
		<svg
			fill='#0099F7'
			height='32'
			viewBox='0 0 20 20'
			width='32'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7 7.5C7 7.22386 7.22386 7 7.5 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H7.5C7.22386 8 7 7.77614 7 7.5ZM7.5 10C7.22386 10 7 10.2239 7 10.5C7 10.7761 7.22386 11 7.5 11H12.5C12.7761 11 13 10.7761 13 10.5C13 10.2239 12.7761 10 12.5 10H7.5ZM7 13.5C7 13.2239 7.22386 13 7.5 13H9.5C9.77614 13 10 13.2239 10 13.5C10 13.7761 9.77614 14 9.5 14H7.5C7.22386 14 7 13.7761 7 13.5ZM6 2.5C6 2.22386 6.22386 2 6.5 2C6.77614 2 7 2.22386 7 2.5V3H9.5V2.5C9.5 2.22386 9.72386 2 10 2C10.2761 2 10.5 2.22386 10.5 2.5V3H13V2.5C13 2.22386 13.2239 2 13.5 2C13.7761 2 14 2.22386 14 2.5V3H14.5C15.3284 3 16 3.67157 16 4.5V9.23163C15.6738 9.37139 15.3683 9.5744 15.102 9.84065L15 9.94266V4.5C15 4.22386 14.7761 4 14.5 4H5.5C5.22386 4 5 4.22386 5 4.5V16.5C5 16.7761 5.22386 17 5.5 17H9.22047L9.05716 17.6532C9.02795 17.7701 9.01015 17.8859 9.00286 18H5.5C4.67157 18 4 17.3284 4 16.5V4.5C4 3.67157 4.67157 3 5.5 3H6V2.5ZM15.8092 10.5478L10.9798 15.3772C10.6982 15.6588 10.4984 16.0116 10.4018 16.3979L10.0274 17.8958C9.86451 18.5472 10.4546 19.1372 11.106 18.9744L12.6039 18.5999C12.9902 18.5033 13.343 18.3036 13.6246 18.022L18.454 13.1926C19.1843 12.4622 19.1843 11.2781 18.454 10.5478C17.7237 9.81741 16.5395 9.81741 15.8092 10.5478Z'
				fill='#0099F7'
			/>
		</svg>
	);

	const reload = () => {
		setVisible(!visible);
	};

	// Toplam Randevu : {props.data.length}
	const actionBodyTemplate = (rowData) => {
		return (
			<div
				style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
			>
				<Button
					className='p-button-text'
					type='button'
					tooltip={t('activated')}
					onClick={() => confirm(rowData.appointmentId)}
				>
					<svg
						fill='#0099F7'
						height='35'
						viewBox='0 0 20 20'
						width='35'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M17.2204 8.68703C18.2558 9.25661 18.2558 10.7434 17.2204 11.313L7.2234 16.812C6.22371 17.362 5 16.6393 5 15.4991L5 4.50093C5 3.36068 6.22371 2.63805 7.2234 3.18795L17.2204 8.68703ZM16.7381 10.4377C17.0833 10.2478 17.0833 9.7522 16.7381 9.56234L6.74113 4.06327C6.4079 3.87997 6 4.12084 6 4.50093L6 15.4991C6 15.8792 6.4079 16.12 6.74114 15.9367L16.7381 10.4377Z'
							fill='#0099F7'
						/>
					</svg>
				</Button>
				{/* <Button
          className="p-button-text"
          type="button"
          tooltip="Randevu Düzenle"
          onClick={() => confirm(rowData.appointmentId)}
        >
          <svg
            fill="#0099F7"
            height="32"
            viewBox="0 0 20 20"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 7.5C7 7.22386 7.22386 7 7.5 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H7.5C7.22386 8 7 7.77614 7 7.5ZM7.5 10C7.22386 10 7 10.2239 7 10.5C7 10.7761 7.22386 11 7.5 11H12.5C12.7761 11 13 10.7761 13 10.5C13 10.2239 12.7761 10 12.5 10H7.5ZM7 13.5C7 13.2239 7.22386 13 7.5 13H9.5C9.77614 13 10 13.2239 10 13.5C10 13.7761 9.77614 14 9.5 14H7.5C7.22386 14 7 13.7761 7 13.5ZM6 2.5C6 2.22386 6.22386 2 6.5 2C6.77614 2 7 2.22386 7 2.5V3H9.5V2.5C9.5 2.22386 9.72386 2 10 2C10.2761 2 10.5 2.22386 10.5 2.5V3H13V2.5C13 2.22386 13.2239 2 13.5 2C13.7761 2 14 2.22386 14 2.5V3H14.5C15.3284 3 16 3.67157 16 4.5V9.23163C15.6738 9.37139 15.3683 9.5744 15.102 9.84065L15 9.94266V4.5C15 4.22386 14.7761 4 14.5 4H5.5C5.22386 4 5 4.22386 5 4.5V16.5C5 16.7761 5.22386 17 5.5 17H9.22047L9.05716 17.6532C9.02795 17.7701 9.01015 17.8859 9.00286 18H5.5C4.67157 18 4 17.3284 4 16.5V4.5C4 3.67157 4.67157 3 5.5 3H6V2.5ZM15.8092 10.5478L10.9798 15.3772C10.6982 15.6588 10.4984 16.0116 10.4018 16.3979L10.0274 17.8958C9.86451 18.5472 10.4546 19.1372 11.106 18.9744L12.6039 18.5999C12.9902 18.5033 13.343 18.3036 13.6246 18.022L18.454 13.1926C19.1843 12.4622 19.1843 11.2781 18.454 10.5478C17.7237 9.81741 16.5395 9.81741 15.8092 10.5478Z"
              fill="#0099F7"
            />
          </svg>
        </Button> */}

				<DialogComponent
					maxWidth={false}
					visible={visible}
					title={t('appointment_id')}
					buttonText={t('edit_appointment')}
					appointmentId={rowData.appointmentId}
					buttonColor='secondary'
					svg={editSvg}
					onCreate={reload}
				>
					<CreateNewAppointment
						fullName={rowData.userName + ' ' + rowData.userSurname}
						onCreate={() => reload()}
						appointmentId={rowData.appointmentId}
						userId={rowData.userId}
						firstOperatorName={rowData?.operatorFullName}
					/>
				</DialogComponent>

				<Button
					className='p-button-text'
					tooltip={t('cancel_appointment')}
					onClick={() => confirm1(rowData.appointmentId)}
				>
					{deleteSvg}
				</Button>
			</div>
		);
	};

	useEffect(() => {
		if (langValue === 'en') {
			setDateChangeLang('en-US');
		} else setDateChangeLang('tr-TR');
	}, [langValue]);

	const actionOperatorEdit = (rowData) => {
		return (
			<>
				<AlertDialog
					operatorInfo={rowData.operatorId}
					selected={rowData.operatorName}
				></AlertDialog>
			</>
		);
	};
	const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
		<InputText
			style={{ padding: '.5rem', borderRadius: '4px', borderColor: '#ddd' }} // your custom styles
			type='text'
			className='form-control'
			onClick={onClick}
			value={value}
			ref={ref}
		/>
	));
	const numberofpages = Math.ceil(totalRecords / rows);

	return (
		<>
			<ConfirmDialog />
			<PageTitle>{t('confirmed_appointments')}</PageTitle>
			<Toast ref={toast} />
			<div className='card card-custom gutter-b'>
				<div className='card-body'>
					<DataTable
						value={appointments}
						totalRecords={totalRecords}
						first={first}
						rows={rows}
						paginator
						onPage={onPageChange}
						showGridlines
						onSort={onSort}
						sortField={sortField}
						sortOrder={sortOrder}
						responsiveLayout='scroll'
						header={renderHeader}
						paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
						rowsPerPageOptions={[10, 25, 50]}
						dataKey='id'
						rowHover
						lazy='true'
						sortMode='single'
						filters={filters}
						filterDisplay='row'
						loading={loading}
						globalFilterFields={[
							'location.name',
							'name',
							'surname',
							'appointmentId',
							'name',
						]}
						emptyMessage={t('no_records_found')}
						currentPageReportTemplate={t('currentPageReportTemplate')}
						numberofpages={numberofpages}
					>
						<Column
							headerStyle={{ width: '12rem', textAlign: 'center' }}
							header={t('action')}
							body={actionBodyTemplate}
							className='actionBody'
							bodyStyle={{ textAlign: 'center' }}
						/>
						{AuthenticationService.isLocationAdmin() && (
							<Column
								header={t('name_surname')}
								sortable={true}
								field='fullName'
								filter
								filterElement={
									<InputText
										onKeyPress={(e) => {
											if (e.target.value.length === 0 && e.key === ' ') {
												e.preventDefault();
											}
										}}
										onChange={(e) => {
											if (
												e.target.value.trim().length > 2 ||
												e.target.value.trim().length === 0
											) {
												setFilters({
													...filters,
													userName: {
														value: e.target.value.trim(),
														matchMode: FilterMatchMode.CONTAINS,
													},
												});
											}
										}}
									/>
								}
								style={{ maxWidth: '100px' }}
							></Column>
						)}
						<Column
							header={t('appointment_id')}
							sortable={true}
							field='appointmentId'
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (
											!/\d/.test(e.key) ||
											(e.target.value.length === 0 && e.key === ' ')
										) {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 0 ||
											e.target.value.trim().length === 0
										) {
											setFilters({
												...filters,
												appointmentId: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							style={{ maxWidth: '100px' }}
						></Column>
						<Column
							header={t('parent_id')}
							sortable={true}
							field='parentId'
							filter
							style={{ maxWidth: '100px' }}
						></Column>
						<Column
							header={t('user_id')}
							sortable={true}
							field='userId'
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (
											!/\d/.test(e.key) ||
											(e.target.value.length === 0 && e.key === ' ')
										) {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 0 ||
											e.target.value.trim().length === 0
										) {
											setFilters({
												...filters,
												userId: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							style={{ maxWidth: '100px' }}
						></Column>
						{AuthenticationService.isAdmin() && (
							<Column
								header={t('name')}
								sortable={true}
								field='userName'
								filter
								filterElement={
									<InputText
										onKeyPress={(e) => {
											if (e.target.value.length === 0 && e.key === ' ') {
												e.preventDefault();
											}
										}}
										onChange={(e) => {
											if (
												e.target.value.trim().length > 2 ||
												e.target.value.trim().length === 0
											) {
												setFilters({
													...filters,
													userName: {
														value: e.target.value.trim(),
														matchMode: FilterMatchMode.CONTAINS,
													},
												});
											}
										}}
									/>
								}
								style={{ minWidth: '100px' }}
							></Column>
						)}
						{AuthenticationService.isAdmin() && (
							<Column
								header={t('surname')}
								sortable={true}
								field='userSurname'
								filter
								filterElement={
									<InputText
										onKeyPress={(e) => {
											if (e.target.value.length === 0 && e.key === ' ') {
												e.preventDefault();
											}
										}}
										onChange={(e) => {
											if (
												e.target.value.trim().length > 2 ||
												e.target.value.trim().length === 0
											) {
												setFilters({
													...filters,
													userSurname: {
														value: e.target.value.trim(),
														matchMode: FilterMatchMode.CONTAINS,
													},
												});
											}
										}}
									/>
								}
								style={{ minWidth: '100px' }}
							></Column>
						)}
						<Column
							header={t('assessment_package')}
							field='assessmentPackageName'
							sortable={true}
							filter
							filterElement={
								<CustomDropdown
									options={assessmentPackages?.map((option) => ({
										label: option?.packageName,
										value: option?.packageName,
									}))}
									showClear={true}
									value={filters?.assessmentPackageName?.value}
									onChange={(e) => {
										setFilters({
											...filters,
											assessmentPackageName: {
												value: e.value,
												matchMode: FilterMatchMode.CONTAINS,
											},
										});
									}}
								/>
							}
							style={{ maxWidth: '120px' }}
						></Column>
						<Column
							header={t('type')}
							field='type'
							sortable={true}
							filter
							filterElement={
								<CustomDropdown
									options={appointmentTypes?.map((option) => ({
										label: option?.type,
										value: option?.type,
									}))}
									showClear={true}
									value={filters?.type?.value}
									onChange={(e) => {
										setFilters({
											...filters,
											type: {
												value: e.value,
												matchMode: FilterMatchMode.CONTAINS,
											},
										});
									}}
								/>
							}
							style={{ maxWidth: '100px' }}
						></Column>

						<Column
							header={t('hub')}
							sortable={true}
							field='hub'
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (e.target.value.length === 0 && e.key === ' ') {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 2 ||
											e.target.value.trim().length === 0
										) {
											setFilters({
												...filters,
												hub: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('start_time')}
							sortable={true}
							field='startTime'
							filter
							filterElement={
								<div className='appointment-start-time'>
									<DatePicker
										selected={startTime}
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={1}
										key={localStorage.getItem('i18nextLng')}
										locale={
											localStorage.getItem('i18nextLng') === 'tr' ? 'tr' : 'en'
										}
										customInput={<CustomInput />}
										timeCaption={t('start_time')}
										isClearable={'true'}
										dateFormat='HH:mm'
										onKeyDown={(e) => e.preventDefault()}
										onChange={(date) => {
											const formattedDate = moment(date).format('HH:mm');

											if (date === 'Invalid date') {
												return;
											}
											setFirst(0);
											setFilters(
												date
													? {
															...filters,
															startTime: {
																value: formattedDate,
																matchMode: FilterMatchMode.EQUALS,
															},
													  }
													: {
															...filters,
															startTime: {
																value: null,
																matchMode: FilterMatchMode.EQUALS,
															},
													  }
											);
											setStartTime(date);
										}}
									/>
								</div>
							}
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('end_time')}
							sortable={true}
							field='endTime'
							filter
							filterElement={
								<div className='appointment-start-time'>
									{' '}
									<DatePicker
										selected={endTime}
										showTimeSelect
										showTimeSelectOnly
										key={localStorage.getItem('i18nextLng')}
										locale={
											localStorage.getItem('i18nextLng') === 'tr' ? 'tr' : 'en'
										}
										customInput={<CustomInput />}
										timeCaption={t('end_time')}
										isClearable={'true'}
										dateFormat='HH:mm'
										timeIntervals={1}
										onKeyDown={(e) => e.preventDefault()}
										onChange={(date) => {
											const formattedDate = moment(date).format('HH:mm');

											if (date === 'Invalid date') {
												return;
											}
											setFirst(0);
											setFilters(
												date
													? {
															...filters,
															endTime: {
																value: formattedDate,
																matchMode: FilterMatchMode.EQUALS,
															},
													  }
													: {
															...filters,
															endTime: {
																value: null,
																matchMode: FilterMatchMode.EQUALS,
															},
													  }
											);
											setEndTime(date);
										}}
									/>{' '}
								</div>
							}
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('date')}
							sortable={true}
							field='date'
							body={(rowData) => {
								let d = rowData.date;
								let date = new Date(d);
								let formattedDate = date.toLocaleDateString(dateChangeLang);
								return formattedDate;
							}}
							filter
							filterElement={
								<DatePicker
									dateFormat={langValue === 'tr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
									key={localStorage.getItem('i18nextLng')}
									locale={
										localStorage.getItem('i18nextLng') === 'tr' ? 'tr' : 'en'
									}
									selected={startDate}
									showMonthDropdown
									showYearDropdown
									yearDropdownItemNumber={478}
									scrollableYearDropdown
									scrollableMonthDropdown
									popperPlacement='bottom-start'
									dropdownMode='scroll'
									customInput={<CustomInput />}
									isClearable={true}
									onKeyDown={(e) => e.preventDefault()}
									onChange={(date) => {
										const formattedDate = moment(date).format('YYYY-MM-DD');

										if (date === 'Invalid date') {
											return;
										}
										setFirst(0);
										setFilters(
											date
												? {
														...filters,
														date: {
															value: formattedDate,
															matchMode: FilterMatchMode.EQUALS,
														},
												  }
												: {
														...filters,
														date: {
															value: null,
															matchMode: FilterMatchMode.EQUALS,
														},
												  }
										);
										setStartDate(date);
									}}
								/>
							}
							style={{ minWidth: '100px' }}
						></Column>
						<Column
							header={t('operatorId')}
							sortable={true}
							field='operatorId'
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (
											!/\d/.test(e.key) ||
											(e.target.value.length === 0 && e.key === ' ')
										) {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 0 ||
											e.target.value.trim().length === 0
										) {
											setFilters({
												...filters,
												operatorId: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							// body={actionOperatorEdit}
							filter
							style={{
								maxWidth: '100px',
								cursor: 'pointer',
							}}
						></Column>
						<Column
							header={t('operatorName')}
							sortable={true}
							field='operatorFullName'
							filter
							filterElement={
								<InputText
									onKeyPress={(e) => {
										if (e.target.value.length === 0 && e.key === ' ') {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (
											e.target.value.trim().length > 2 ||
											e.target.value.trim().length === 0
										) {
											setFilters({
												...filters,
												operatorFullName: {
													value: e.target.value.trim(),
													matchMode: FilterMatchMode.CONTAINS,
												},
											});
										}
									}}
								/>
							}
							style={{ minWidth: '100px' }}
						></Column>
					</DataTable>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(NotCompletedAppointments);
