import axios from 'axios';

class SarfCompetencyService {
	getList() {
		return axios.get('/lookup/getAllSarfCompetencies');
	}

	getById(id) {
		return axios.get('/lookup/getSarfCompetenciesById/' + id);
	}

	save(row) {
		return axios.post('/lookup/saveNewSarfCompetencies', row);
	}

	update(row) {
		return axios.post('/lookup/updateSarfCompetencies', row);
	}
	delete(id) {
		return axios.delete(`/lookup/deleteSarfCompetenciesById/${id}`);
	}
}

export default new SarfCompetencyService();
