import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Link } from 'react-router-dom';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventIcon from '@material-ui/icons/Event';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PeopleIcon from '@material-ui/icons/People';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useSnapshot } from 'valtio';
import store from './../Store';
import AuthenticationService from '../service/AuthenticationService';
import InsightsIcon from '@mui/icons-material/Insights';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(5),
	},
	nested_nested: {
		paddingLeft: theme.spacing(6),
		background: theme.palette.nested_primary.main,
	},
}));

const ListItem = withStyles((theme) => ({
	root: {
		'&$selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
			'& .MuiSvgIcon-root': {
				color: theme.palette.common.white,
			},
		},
		'&$selected:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		'&:hover': {
			color: 'white',
			backgroundColor: theme.palette.primary.main,
			'& .MuiSvgIcon-root': {
				color: theme.palette.common.white,
			},
		},
	},
	selected: {},
}))(MuiListItem);

const DashboardMenu = ({ drawerOpen }) => {
	const classes = useStyles();

	const snapshot = useSnapshot(store);
	const [open, setOpen] = React.useState(true);
	const [open2, setOpen2] = React.useState(false);
	const [open3, setOpen3] = React.useState(false);
	const [open4, setOpen4] = React.useState(false);
	const [open5, setOpen5] = React.useState(false);
	const [open6, setOpen6] = React.useState(false);
	const [openDefinition, setOpenDefinition] = React.useState(false);
	const [openAutoReport, setOpenAutoReport] = React.useState(false);
	const [isCustomAdmin, setIsCustomAdmin] = useState(false);
	const { t } = useTranslation();
	const changeActiveItem = (index) => (store.dashboardActiveLinkIndex = index);
	const user = AuthenticationService.getLoggedInUser();
	useEffect(() => {
		if (AuthenticationService.isUserLoggedIn()) {
			store.dashboardActiveLinkIndex = 0;
			if (user.email === 'vra.admin@neoauvra.com') {
				setIsCustomAdmin(true);
			}
		}
	}, [user]);

	return (
		<div>
			<List component='nav'>
				{AuthenticationService.isAdmin() && (
					<ListItem
						selected={snapshot.dashboardActiveLinkIndex === 0}
						onClick={() => {
							changeActiveItem(0);
							setOpen(!open);
						}}
						button
					>
						<ListItemIcon>
							<DashboardIcon color='secondary' />
						</ListItemIcon>
						<ListItemText primary={t('dashboard')} />
						{open ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
				)}
				{AuthenticationService.isAdmin() && (
					<Collapse in={open} timeout='auto' unmountOnExit>
						<List component='div' disablePadding>
							<ListItem
								component={Link}
								to='/panel/genel-arama'
								button
								className={classes.nested}
							>
								<ListItemText primary={t('menu.general_search')} />
							</ListItem>
							<ListItem
								component={Link}
								to='/panel/bireysel-arama'
								button
								className={classes.nested}
							>
								<ListItemText primary={t('menu.individual_search')} />
							</ListItem>
						</List>
					</Collapse>
				)}

				{AuthenticationService.isAdmin() && (
					<ListItem
						selected={snapshot.dashboardActiveLinkIndex === 20}
						onClick={() => {
							changeActiveItem(20);
							setOpenDefinition(!openDefinition);
						}}
						button
					>
						<ListItemIcon>
							<DashboardIcon color='secondary' />
						</ListItemIcon>
						<ListItemText primary={t('menu.definitions')} />
						{openDefinition ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
				)}
				{AuthenticationService.isAdmin() && (
					<Collapse in={openDefinition} timeout='auto' unmountOnExit>
						<List component='div' disablePadding>
							<ListItem
								component={Link}
								to='/panel/lokasyonlar'
								button
								className={classes.nested}
							>
								<ListItemText primary={t('menu.identification_of_location')} />
							</ListItem>
							<ListItem
								component={Link}
								to='/panel/lokasyon-admin'
								button
								className={classes.nested}
							>
								<ListItemText primary={t('menu.location_admin_list')} />
							</ListItem>

							<ListItem
								component={Link}
								to='/panel/istasyonlar'
								button
								className={classes.nested}
							>
								<ListItemText primary={t('menu.hub_identification')} />
							</ListItem>
							<ListItem
								component={Link}
								to='/panel/pctanimlari'
								button
								className={classes.nested}
							>
								<ListItemText primary={t('menu.pc_identification')} />
							</ListItem>

							<ListItem
								component={Link}
								to='/panel/musteriler'
								button
								className={classes.nested}
							>
								<ListItemText primary={t('menu.identification_of_customer')} />
							</ListItem>
						</List>
					</Collapse>
				)}

				{(AuthenticationService.isAdmin() ||
					AuthenticationService.isOperator() ||
					AuthenticationService.isExpert() ||
					AuthenticationService.isLocationAdmin()) && (
					<ListItem
						selected={snapshot.dashboardActiveLinkIndex === 9}
						onClick={() => {
							changeActiveItem(9);
							setOpen2(!open2);
						}}
						button
					>
						<ListItemIcon>
							<DashboardIcon color='secondary' />
						</ListItemIcon>
						<ListItemText primary={t('menu.appointment_management')} />
						{open2 ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
				)}

				{(AuthenticationService.isAdmin() ||
					AuthenticationService.isOperator() ||
					AuthenticationService.isExpert() ||
					AuthenticationService.isLocationAdmin()) && (
					<Collapse in={open2} timeout='auto' unmountOnExit>
						<List component='div' disablePadding>
							{(AuthenticationService.isAdmin() ||
								AuthenticationService.isOperator() ||
								AuthenticationService.isExpert() ||
								AuthenticationService.isLocationAdmin()) && (
								<Collapse in={open2} timeout='auto' unmountOnExit>
									<List component='div' disablePadding>
										{(AuthenticationService.isAdmin() ||
											AuthenticationService.isOperator() ||
											AuthenticationService.isLocationAdmin()) && (
											<>
												<ListItem
													component={Link}
													to='/panel/randevu-takvimi'
													button
													className={classes.nested}
												>
													<ListItemText
														primary={t('menu.appointment_calendar')}
													/>
												</ListItem>
												{/* <ListItem
                      component={Link}
                      to="/randevu-olustur"
                      button
                      className={classes.nested}
                    >
                      <ListItemText primary="Randevu Oluştur" />
                    </ListItem> */}
												<ListItem
													component={Link}
													to='/panel/tüm-randevular'
													button
													className={classes.nested}
												>
													<ListItemText primary={t('menu.all_appointments')} />
												</ListItem>
												<ListItem
													component={Link}
													to='/panel/bekleyen-randevular'
													button
													className={classes.nested}
												>
													<ListItemText primary={t('menu.awaiting_approval')} />
												</ListItem>
												<ListItem
													component={Link}
													to='/panel/onaylanmislar'
													button
													className={classes.nested}
												>
													<ListItemText primary={t('menu.confirmed')} />
												</ListItem>
												<ListItem
													component={Link}
													to='/panel/aktif-randevular'
													button
													className={classes.nested}
												>
													<ListItemText
														primary={t('menu.active_appointment')}
													/>
												</ListItem>
											</>
										)}
										<ListItem
											component={Link}
											to='/panel/tamamlanmis-randevular'
											button
											className={classes.nested}
										>
											<ListItemText primary={t('menu.complete_appointment')} />
										</ListItem>
									</List>
								</Collapse>
							)}
						</List>
					</Collapse>
				)}

				{(AuthenticationService.isAdmin() ||
					AuthenticationService.isExpert()) && (
					<ListItem
						selected={snapshot.dashboardActiveLinkIndex === 19}
						onClick={() => {
							changeActiveItem(19);
							setOpenAutoReport(!openAutoReport);
						}}
						button
					>
						<ListItemIcon>
							<DashboardIcon color='secondary' />
						</ListItemIcon>
						<ListItemText primary={t('menu.auto_report')} />
						{openAutoReport ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
				)}

				{(AuthenticationService.isAdmin() ||
					AuthenticationService.isExpert()) && (
					<Collapse in={openAutoReport} timeout='auto' unmountOnExit>
						<List component='div' disablePadding>
							<Collapse in={openAutoReport} timeout='auto' unmountOnExit>
								<List component='div' disablePadding>
									<>
										<ListItem
											component={Link}
											to='/panel/oto-rapor-tum-liste'
											button
											className={classes.nested}
										>
											<ListItemText primary={t('menu.auto_report_all')} />
										</ListItem>
										<ListItem
											component={Link}
											to='/panel/oto-rapor-bekleyen'
											button
											className={classes.nested}
										>
											<ListItemText primary={t('menu.auto_report_pending')} />
										</ListItem>
										<ListItem
											component={Link}
											to='/panel/oto-rapor-onaylanmis'
											button
											className={classes.nested}
										>
											<ListItemText primary={t('menu.auto_report_confirmed')} />
										</ListItem>
										<ListItem
											component={Link}
											to='/panel/oto-rapor-hazirlaniyor'
											button
											className={classes.nested}
										>
											<ListItemText primary={t('menu.auto_report_preparing')} />
										</ListItem>
										<ListItem
											component={Link}
											to='/panel/oto-rapor-hazirlandi'
											button
											className={classes.nested}
										>
											<ListItemText primary={t('menu.auto_report_prepared')} />
										</ListItem>
									</>

									<ListItem
										component={Link}
										to='/panel/oto-rapor-hata'
										button
										className={classes.nested}
									>
										<ListItemText primary={t('menu.auto_report_error')} />
									</ListItem>
								</List>
							</Collapse>
						</List>
					</Collapse>
				)}

				{(AuthenticationService.isAdmin() ||
					AuthenticationService.isOperator() ||
					AuthenticationService.isExpert() ||
					AuthenticationService.isLocationAdmin()) && (
					<ListItem
						button
						selected={snapshot.dashboardActiveLinkIndex === 1}
						onClick={() => {
							changeActiveItem(1);
							setOpen3(!open3);
						}}
					>
						<ListItemIcon>
							<PeopleIcon color='secondary' />
						</ListItemIcon>
						<ListItemText primary={t('menu.users')} />
						{open3 ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
				)}

				{(AuthenticationService.isAdmin() ||
					AuthenticationService.isOperator() ||
					AuthenticationService.isExpert() ||
					AuthenticationService.isLocationAdmin()) && (
					<Collapse in={open3} timeout='auto' unmountOnExit>
						<List component='div' disablePadding>
							<ListItem
								component={Link}
								to='/panel/kullanicilar'
								button
								className={classes.nested}
							>
								<ListItemText primary={t('menu.users')} />
							</ListItem>

							{(AuthenticationService.isAdmin() ||
								AuthenticationService.isLocationAdmin()) && (
								<ListItem
									component={Link}
									to='/panel/onkayitli-kullanicilar'
									button
									className={classes.nested}
								>
									<ListItemText primary={t('menu.pre_registered_users')} />
								</ListItem>
							)}
							{(AuthenticationService.isOperator() ||
								AuthenticationService.isLocationAdmin()) && (
								<ListItem
									component={Link}
									to='/panel/kullanici-id-sorgulama'
									button
									className={classes.nested}
								>
									<ListItemText primary={t('menu.user_id_query')} />
								</ListItem>
							)}
						</List>
					</Collapse>
				)}

				{/* {AuthenticationService.isAdmin() && (
          <ListItem
            button
            component={Link}
            to="/panel/skorlar"
            selected={snapshot.dashboardActiveLinkIndex === 2}
            onClick={() => changeActiveItem(2)}
          >
            <ListItemIcon>
              <AssessmentIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Skorlar" />
          </ListItem>
        )} */}

				{/* {AuthenticationService.isAdmin() && (
					<ListItem
						button
						component={Link}
						to='/panel/bekleyen-doktorlar'
						selected={snapshot.dashboardActiveLinkIndex === 4}
						onClick={() => changeActiveItem(4)}
					>
						<ListItemIcon>
							<EventIcon color='secondary' />
						</ListItemIcon>
						<ListItemText primary={t('menu.not_approval_doctors')} />
					</ListItem>
				)} */}
				{AuthenticationService.isAdmin() && (
					<ListItem
						selected={snapshot.dashboardActiveLinkIndex === 29}
						onClick={() => {
							changeActiveItem(29);
							setOpen4(!open4);
						}}
						button
					>
						<ListItemIcon>
							<DashboardIcon color='secondary' />
						</ListItemIcon>
						<ListItemText primary={t('menu.system_parameters')} />
						{open4 ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
				)}
				{AuthenticationService.isAdmin() && (
					<Collapse in={open4} timeout='auto' unmountOnExit>
						<List component='div' disablePadding>
							<ListItem
								button
								component={Link}
								className={classes.nested}
								to='/panel/rapor-paragraflari'
							>
								<ListItemText primary={t('report_texts')} />
							</ListItem>
							<ListItem
								button
								component={Link}
								className={classes.nested}
								to='/panel/endustri-categorileri'
							>
								<ListItemText primary={t('industry_categories')} />
							</ListItem>
							<ListItem
								button
								component={Link}
								className={classes.nested}
								to='/panel/sirket-buyuklukleri'
							>
								<ListItemText primary={t('company_sizes')} />
							</ListItem>
							<ListItem
								button
								component={Link}
								className={classes.nested}
								to='/panel/sirket-merkez-lokasyonlari'
							>
								<ListItemText
									primary={t('customer_headquarter_locations_menu')}
								/>
							</ListItem>
							<ListItem
								button
								component={Link}
								className={classes.nested}
								to='/panel/tecrube-seviyeleri'
							>
								<ListItemText primary={t('seniority_levels')} />
							</ListItem>
							<ListItem
								button
								component={Link}
								className={classes.nested}
								to='/panel/nesiller'
							>
								<ListItemText primary={t('generations')} />
							</ListItem>
						</List>
					</Collapse>
				)}
				{AuthenticationService.isAdmin() && (
					<ListItem
						selected={snapshot.dashboardActiveLinkIndex === 30}
						onClick={() => {
							changeActiveItem(30);
							setOpen5(!open5);
						}}
						button
					>
						<ListItemIcon>
							<DashboardIcon color='secondary' />
						</ListItemIcon>
						<ListItemText primary={t('sarf_report_definition')} />
						{open5 ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
				)}
				{AuthenticationService.isAdmin() && (
					<Collapse in={open5} timeout='auto' unmountOnExit>
						<List component='div' disablePadding>
							<ListItem
								button
								component={Link}
								className={classes.nested}
								to='/panel/sarf-modulleri'
							>
								<ListItemText primary={t('sarf_modules')} />
							</ListItem>
							<ListItem
								button
								component={Link}
								className={classes.nested}
								to='/panel/sarf-yetkinlikleri'
							>
								<ListItemText primary={t('sarf_competencies')} />
							</ListItem>
							<ListItem
								button
								component={Link}
								className={classes.nested}
								to='/panel/yetkinlik-alanlari'
							>
								<ListItemText primary={t('sarf_competency_domains')} />
							</ListItem>
							<ListItem
								button
								component={Link}
								className={classes.nested}
								to='/panel/sarf-paketleri'
							>
								<ListItemText primary={t('sarf_packages')} />
							</ListItem>
							<ListItem
								button
								component={Link}
								className={classes.nested}
								to='/panel/sarf-raporu-tanimlamalari'
							>
								<ListItemText primary={t('sarf_report_texts')} />
							</ListItem>
						</List>
					</Collapse>
				)}
				{(AuthenticationService.isAdmin() ||
					AuthenticationService.isOperator() ||
					AuthenticationService.isLocationAdmin()) && (
					<ListItem
						selected={snapshot.dashboardActiveLinkIndex === 31}
						onClick={() => {
							changeActiveItem(31);
							setOpen6(!open6);
						}}
						button
					>
						<ListItemIcon>
							<DashboardIcon color='secondary' />
						</ListItemIcon>
						<ListItemText primary={t('system_monitoring')} />
						{open6 ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
				)}

				{isCustomAdmin && (
					<Collapse in={open6} timeout='auto' unmountOnExit>
						<List component='div' disablePadding>
							<ListItem
								button
								component={Link}
								className={classes.nested}
								to='/panel/sistem-loglari'
							>
								<ListItemText primary={t('system_logs')} />
							</ListItem>
						</List>
					</Collapse>
				)}

				{isCustomAdmin && (
					<Collapse in={open6} timeout='auto' unmountOnExit>
						<List component='div' disablePadding>
							<ListItem
								button
								component={Link}
								className={classes.nested}
								to='/panel/mail-loglari'
							>
								<ListItemText primary={t('mail_logs')} />
							</ListItem>
						</List>
					</Collapse>
				)}

				{(AuthenticationService.isAdmin() ||
					AuthenticationService.isOperator() ||
					AuthenticationService.isLocationAdmin()) && (
					<Collapse in={open6} timeout='auto' unmountOnExit>
						<List component='div' disablePadding>
							<ListItem
								button
								component={Link}
								className={classes.nested}
								to='/panel/kullanicilar-pagination'
							>
								<ListItemText primary={t('menu.users')} />
							</ListItem>
						</List>
					</Collapse>
				)}

				{(AuthenticationService.isAdmin() ||
					AuthenticationService.isLocationAdmin()) && (
					<Collapse in={open6} timeout='auto' unmountOnExit>
						<List component='div' disablePadding>
							<ListItem
								button
								component={Link}
								className={classes.nested}
								to='/panel/onkayitli-kullanicilar-pagination'
							>
								<ListItemText primary={t('menu.pre_registered_users')} />
							</ListItem>
						</List>
					</Collapse>
				)}

				{(AuthenticationService.isAdmin() ||
					AuthenticationService.isOperator() ||
					AuthenticationService.isLocationAdmin()) && (
					<Collapse in={open6} timeout='auto' unmountOnExit>
						<List component='div' disablePadding>
							<ListItem
								component={Link}
								to='/panel/tüm-randevular-pagination'
								button
								className={classes.nested}
							>
								<ListItemText primary={t('menu.all_appointments')} />
							</ListItem>
							<ListItem
								component={Link}
								to='/panel/bekleyen-randevular-pagination'
								button
								className={classes.nested}
							>
								<ListItemText primary={t('menu.awaiting_approval')} />
							</ListItem>
							<ListItem
								component={Link}
								to='/panel/onaylanmislar-pagination'
								button
								className={classes.nested}
							>
								<ListItemText primary={t('menu.confirmed')} />
							</ListItem>
							<ListItem
								component={Link}
								to='/panel/aktif-randevular-pagination'
								button
								className={classes.nested}
							>
								<ListItemText primary={t('menu.active_appointment')} />
							</ListItem>
							<ListItem
								component={Link}
								to='/panel/tamamlanmis-randevular-pagination'
								button
								className={classes.nested}
							>
								<ListItemText primary={t('menu.complete_appointment')} />
							</ListItem>
						</List>
					</Collapse>
				)}

				{AuthenticationService.isDoctor() && (
					<ListItem
						button
						component={Link}
						to='/panel/doktorlar-hastalar'
						selected={snapshot.dashboardActiveLinkIndex === 7}
						onClick={() => changeActiveItem(7)}
					>
						<ListItemIcon>
							<DateRangeIcon color='secondary' />
						</ListItemIcon>
						<ListItemText primary={t('menu.your_patient_list')} />
					</ListItem>
				)}
				{AuthenticationService.isDoctor() && (
					<ListItem
						button
						component={Link}
						to='/panel/doktor-randevu-takvimi'
						selected={snapshot.dashboardActiveLinkIndex === 8}
						onClick={() => changeActiveItem(8)}
					>
						<ListItemIcon>
							<DateRangeIcon color='secondary' />
						</ListItemIcon>
						<ListItemText primary={t('menu.your_appointments')} />
					</ListItem>
				)}
				{/* <ListItem
          button
          component={Link}
          to="/panel/raporlar"
          selected={snapshot.dashboardActiveLinkIndex === 6}
          onClick={() => changeActiveItem(6)}
        >
          <ListItemIcon>
            <InsightsIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Raporlar" />
        </ListItem> */}
			</List>
		</div>
	);
};

export default DashboardMenu;
