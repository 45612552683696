import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import ButtonMaterial from '@mui/material/Button';
import SarfModuleService from '../../../service/SarfModuleService';
import CustomerSarfModuleParametersService from '../../../service/CustomerSarfModuleParametersService';
const UpdateParameterModulModal = ({
	getModulesBySarfReportParamatersTemplateId,
	customerId,
	parameterTemplateId,
	handleCloseUpdateSarfTemplateModulModal,
	updateSarfTemplateModulModalOpen,
	getDomainsBySarfReportParametersTemplateId,
	getCompetenciesBySarfReportParametersTemplateId,
	t,
	setLoading,
	selectedSarfTemplateModul,
}) => {
	const toast = useRef(null);
	const toastInside = useRef(null);
	const [weight, setWeight] = useState();
	const [selectedModule, setSelectedModule] = useState(null);
	const [modules, setModules] = useState([]);

	useEffect(() => {
		let unmounted = false;
		if (
			(selectedSarfTemplateModul != null ||
				selectedSarfTemplateModul != undefined) &&
			updateSarfTemplateModulModalOpen
		) {
			getSarfModules();
			setWeight(selectedSarfTemplateModul?.weight);
			setSelectedModule(selectedSarfTemplateModul?.sarfModules);
		} else {
			return;
		}
		return () => {
			unmounted = true;
		};
	}, [updateSarfTemplateModulModalOpen]);
	const getSarfModules = () => {
		SarfModuleService.getList()
			.then((res) => {
				setModules(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateSarfTemplateModule = () => {
		if (customerId == null || customerId == undefined) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('appointment_alert_messages.transaction_error')}`,
				life: 3000,
			});

			return;
		}
		if (selectedModule == null || selectedModule == undefined) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_sarf_module')}`,
				life: 3000,
			});

			return;
		}
		if (weight == '' || weight == null || weight == undefined) {
			toastInside.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('enter_sarf_competency_weight')}`,
				life: 3000,
			});
			return;
		}

		const dataSent = {
			id: selectedSarfTemplateModul?.id,
			sarfModuleId: selectedModule?.id,
			sarfReportParametersTemplateId: parameterTemplateId,
			weight: weight,
		};
		setLoading(true);
		CustomerSarfModuleParametersService.update(dataSent)
			.then((res) => {
				setTimeout(() => {
					handleCloseUpdateSarfTemplateModulModal();
					setLoading(false);
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
						life: 3000,
					});
				}, 2000);
				getModulesBySarfReportParamatersTemplateId(parameterTemplateId);
				getDomainsBySarfReportParametersTemplateId(parameterTemplateId);
				getCompetenciesBySarfReportParametersTemplateId(parameterTemplateId);
			})
			.catch((error) => {
				setLoading(false);
				toastInside.current.show({
					severity: 'error',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: error.response.data,
					life: 3000,
				});
			});
	};

	return (
		<>
			<Toast ref={toast} />

			<Dialog
				open={updateSarfTemplateModulModalOpen}
				fullWidth={true}
				maxWidth={'sm'}
				onClose={() => {
					handleCloseUpdateSarfTemplateModulModal();
				}}
			>
				<Toast ref={toastInside} />

				<DialogContent
					style={{
						overflowY: 'visible',
						display: 'flex',
						justifyContent: 'start',
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<DialogContentText
						style={{
							marginBottom: '30px',
							textAlign: 'center',
							fontWeight: 'bold',
						}}
					>
						{t('update_sarf_template_module')}
					</DialogContentText>

					<FormControl>
						<div style={{ width: '100%' }}>
							<label htmlFor=''>{t('sarf_module')}</label>

							<Dropdown
								value={selectedModule}
								scrollHeight={'150px'}
								style={{
									width: '100%',
									marginTop: '10px',
									marginBottom: '10px',
								}}
								appendTo='self'
								className='p-inputtext-sm'
								onChange={(e) => {
									setSelectedModule(e.value);
								}}
								options={modules}
								optionLabel='name'
							/>
						</div>

						<div style={{ width: '18%' }}>
							<label htmlFor='inputtext'>
								{t('contribution_weight') + ' (%) '}
							</label>
							<InputNumber
								id='inputtext'
								style={{
									width: '100%',
									marginTop: '10px',
									marginBottom: '10px',
								}}
								min={0}
								maxFractionDigits={0}
								value={weight}
								onValueChange={(e) => setWeight(e.value)}
							/>
						</div>
						<DialogActions style={{ paddingRight: '0' }}>
							<ButtonMaterial
								onClick={() => {
									handleCloseUpdateSarfTemplateModulModal();
								}}
							>
								{' '}
								{t('Cancel')}
							</ButtonMaterial>
							<ButtonMaterial
								variant='contained'
								onClick={() => {
									updateSarfTemplateModule();
								}}
							>
								{' '}
								{t('update')}
							</ButtonMaterial>
						</DialogActions>
					</FormControl>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default withTranslation()(UpdateParameterModulModal);
