import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

const Text = styled.span`
  color: ${(props) => (props.disabled ? "rgba(0,0,0,0.45)" : "#fff")};
  display: inline-block;
  transform: skew(20deg);
  -webkit-transform: skew(20deg);
  -o-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -ms-transform: skew(20deg);
`;

const MyButton = styled(Button)`
  text-transform: uppercase;
  min-width: 100px;
  transform: skew(-20deg);
  -webkit-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -ms-transform: skew(-20deg);
`;

export const MainButton = ({ children, ...props }) => {
  return (
    <MyButton variant="contained" {...props}>
      <Text disabled={props.disabled} type={props.type}>
        {children}
      </Text>
    </MyButton>
  );
};
