import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormHelperText, FormLabel, Grid, Input } from '@material-ui/core';

import ReactPhoneInput from 'react-phone-input-material-ui';
import 'react-phone-input-material-ui/lib/material.css';
import './phone-input.css';
import tr from 'react-phone-input-material-ui/lang/tr.json';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  countryList: {
    ...theme.typography.body1,
  },
  phoneContainer: {
    width: '100%',
  },
  root: {
    marginTop: 15,
    marginBottom: 15,
  },
}));

const PhoneInput = (props, ref) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    field,
    fieldState: { error },
    defaultValue,
    disabled,
  } = props;

  return (
    <>
      <Grid
        container
        justifyContent='center'
        className={classes.root}
        alignItems='center'
      >
        <Grid container xs={3} alignItems='center'>
          <FormLabel>{props.placeholder}</FormLabel>
        </Grid>
        <Grid item xs={9}>
          <ReactPhoneInput
            value={field.value ? field.value : props.defaultValue}
            onChange={field.onChange}
            country='tr'
            isValid={!error}
            // onlyCountries={['tr', 'de', 'us']}
            masks={{ tr: '(...) ... .. ..' }}
            localization={tr}
            enableSearch={true}
            disableSearchIcon={true}
            containerClass={classes.phoneContainer}
            dropdownClass={classes.countryList}
            component={Input}
            searchPlaceholder={t('search_country')}
            searchNotFound={t('searchNotFoundCountry')}
            specialLabel={null}
            disabled={props.disabled}
            inputProps={{
              placeholder: `${t('phone_number')}`,
              disableUnderline: true,
              multiline: true,
              readOnly: props.readOnly,
            }}
          />

          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        </Grid>
      </Grid>
    </>
  );
};

export default PhoneInput;
