import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { MainButton } from './common/MainButton';
import { useSnackbar } from 'notistack';
import DatePicker, { registerLocale } from 'react-datepicker';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import 'react-datepicker/dist/react-datepicker.css';
import './override.css';
import { tr } from 'date-fns/locale';
import FormInput from './common/FormInput';
import moment from 'moment';
import AuthenticationService from '../service/AuthenticationService';
import { useTranslation } from 'react-i18next';

const MySwal = withReactContent(Swal);

registerLocale('tr', tr);

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 250,
  },
}));

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DomainScores = ({ scores }) => {
  return (
    <List>
      {scores &&
        scores.map((domainScore) => (
          <ListItemText>
            {domainScore.domain.name} - {domainScore.score}
          </ListItemText>
        ))}
    </List>
  );
};

export default function CalculateScoreModal({ userId }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [domains, setDomains] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control } = useForm({
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    axios
      .get('/lookup/domains')
      .then((res) => setDomains([{ id: 0, name: '-- All --' }, ...res.data]))
      .catch((err) => console.log(err));
  }, []);

  const calculateScoreAndRedirect = ({ testDateRange, domain }) => {
    const dateRange = {
      startDate: moment(testDateRange[0]).format('YYYY-MM-DD'),
      endDate: moment(testDateRange[1]).format('YYYY-MM-DD'),
    };

    const formData = { userId, dateRange, domainId: domain.id };

    axios
      .post('/scores/calculateScore', formData)
      .then(({ data }) => {
        if (data.error) {
          let message =
            data.reason === `${t('DEMOGRAPHICS_NOT_FILLED')}`
              ? `${t('demographic_form_popup.demographic_info_empty')}`
              : `${t('demographic_form_popup.score_calc_error')}`;
          enqueueSnackbar(message, {
            variant: 'error',
          });
        } else {
          setOpen(false);
          MySwal.fire({
            position: 'center',
            icon: 'success',
            title: `${t('demographic_form_popup.score_calc_success')}`,
            html: <DomainScores scores={data.scores} />,
            showConfirmButton: true,
          });
        }
      })
      .catch((res) => {
        enqueueSnackbar(`${t('demographic_form_popup.score_calc_error')}`, {
          variant: 'error',
        });
      });
  };

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <TextField
      className={classes.formControl}
      variant="outlined"
      size="small"
      margin="dense"
      labelId="date-label"
      placeholder=""
      value={value}
      onClick={onClick}
      ref={ref}
    />
  ));

  return (
    <div>
      <Button
        style={
          !AuthenticationService.isDoctor()
            ? { minWidth: 140, margin: 5 }
            : { display: 'none' }
        }
        color="primary"
        variant="contained"
        onClick={() => setOpen(true)}
      >
        {t('calc_score')}
      </Button>

      <Dialog
        open={open}
        fullWidth={true}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" onClose={() => setOpen(false)}>
          {t('Calc_Score')}
        </DialogTitle>

        <DialogContent style={{ minHeight: 400 }}>
          <form noValidate onSubmit={handleSubmit(calculateScoreAndRedirect)}>
            <Grid container spacing={4}>
              <Grid item={true} xs={8}>
                <Controller
                  control={control}
                  name="domain"
                  defaultValue=""
                  render={(props) => (
                    <FormInput
                      {...props}
                      select
                      label={t('select_domain')}
                      options={domains}
                    />
                  )}
                />
              </Grid>
              <Grid item={true} xs={8}>
                <Controller
                  control={control}
                  name="testDateRange"
                  defaultValue={[null, null]}
                  render={({ field }) => (
                    <>
                      <FormLabel id="date-label">
                        {t('select_test_and_date')}
                      </FormLabel>
                      <DatePicker
                        selectsRange
                        dateFormat="dd/MM/yyyy"
                        startDate={field.value[0]}
                        endDate={field.value[1]}
                        onChange={(update) => field.onChange(update)}
                        customInput={<ExampleCustomInput />}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <MainButton type="submit" color="primary">
                  Hesapla
                </MainButton>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
