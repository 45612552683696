import { useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import Spinner from '../common/Spinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import SettingsIcon from '@mui/icons-material/Settings';
import { FilterMatchMode } from 'primereact/api';

import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { TabPanel, TabView } from 'primereact/tabview';

import CustomerService from '../../service/CustomerService';
import { useEffect } from 'react';
import CustomerSarfReportParametersTemplateService from '../../service/CustomerSarfReportParametersTemplateService';
import CustomerSarfModuleParametersService from '../../service/CustomerSarfModuleParametersService';
import AddParameterModulModal from './ParameterModule/AddParameterModulModal';
import UpdateParameterModulModal from './ParameterModule/UpdateParameterModulModal';
import CompetencyConfigurationModal from './ParameterCompetency/CompetencyConfigurationModal';
import CustomerSarfModuleCompetencyService from '../../service/CustomerSarfModuleCompetencyService';
import DomainConfigurationModal from './ParameterDomain/DomainConfigurationModal';
import CustomerSarfCompetencyDomainParametersService from '../../service/CustomerSarfCompetencyDomainParametersService';

const SarfParameterTemplateConfiguration = ({
	t,
	customerId,
	parameterTemplateId,
}) => {
	const [customer, setCustomer] = useState({
		name: '',
		id: '',
	});
	const [globalFilterValue1, setGlobalFilterValue1] = useState('');
	const [globalFilterValue2, setGlobalFilterValue2] = useState('');
	const [globalFilterValue3, setGlobalFilterValue3] = useState('');
	const [selectedList, setSelectedList] = useState(null);
	const [selectedSarfTemplateModul, setSelectedSarfTemplateModul] = useState();

	const [selectedSarfTemplateCompetency, setSelectedSarfTemplateCompetency] =
		useState();
	const [total, setTotal] = useState(0);

	const [parameterTemplate, setParameterTemplate] = useState({
		name: '',
		id: '',
	});
	const [templateModules, setTemplateModules] = useState([]);
	const [filteredTemplateModules, setFilteredTemplateModuls] = useState([]);
	const [sarfCompetencies, setSarfCompetencies] = useState([]);
	const [filteredSarfCompetencies, setFilteredSarfCompetencies] = useState([]);
	const [sarfDomains, setSarfDomains] = useState([]);
	const [filteredSarfDomains, setFilteredSarfDomains] = useState([]);
	const [loading, setLoading] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);
	const [addSarfTemplateModulModalOpen, setAddSarfTemplateModulModalOpen] =
		useState(false);
	const [updateSarfTemplateModulModalOpen, setUpdateSarfTemplateModulModal] =
		useState(false);
	const [
		configureSarfTemplateModulModalOpen,
		setConfigureSarfTemplateModulModalOpen,
	] = useState(false);

	const [domainConfigurationModalOpen, setDomeinConfigurationModalOpen] =
		useState(false);
	const [filters1, setFilters1] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		'sarfModules.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
		weight: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});

	const [filters2, setFilters2] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		'sarfReportModuleParameters.sarfModules.name': {
			value: null,
			matchMode: FilterMatchMode.CONTAINS,
		},
		'sarfCompetencies.name': {
			value: null,
			matchMode: FilterMatchMode.CONTAINS,
		},
		weight: {
			value: null,
			matchMode: FilterMatchMode.CONTAINS,
		},
	});

	const [filters3, setFilters3] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		'sarfCompetencyDomains.name': {
			value: null,
			matchMode: FilterMatchMode.CONTAINS,
		},
		'sarfReportCompetencyParameters.sarfReportModuleParameters.sarfModules.name':
			{ value: null, matchMode: FilterMatchMode.CONTAINS },
		'sarfReportCompetencyParameters.sarfCompetencies.name': {
			value: null,
			matchMode: FilterMatchMode.CONTAINS,
		},
		'sarfReportCompetencyParameters.sarfReportModuleParameters.weight': {
			value: null,
			matchMode: FilterMatchMode.CONTAINS,
		},
		'sarfReportCompetencyParameters.weight': {
			value: null,
			matchMode: FilterMatchMode.CONTAINS,
		},
		weight: {
			value: null,
			matchMode: FilterMatchMode.CONTAINS,
		},
	});

	const params = useParams();
	const toast = useRef(null);

	customerId = params.id;
	parameterTemplateId = params.sarfParameterTemplateId;

	const history = useHistory();
	const handleOnClick = useCallback(
		() => history.push(`/panel/musteriler/${customerId}`),
		[history]
	);
	const onGlobalFilterChange1 = (e) => {
		const value = e.target.value;
		let _filters1 = { ...filters1 };
		_filters1['global'].value = value;

		setFilters1(_filters1);
		setGlobalFilterValue1(value);
	};
	const onGlobalFilterChange2 = (e) => {
		const value = e.target.value;
		let _filters2 = { ...filters2 };
		_filters2['global'].value = value;

		setFilters2(_filters2);
		setGlobalFilterValue2(value);
	};
	const onGlobalFilterChange3 = (e) => {
		const value = e.target.value;
		let _filters3 = { ...filters3 };
		_filters3['global'].value = value;

		setFilters3(_filters3);
		setGlobalFilterValue3(value);
	};

	useEffect(() => {
		let unmounted = false;

		getCustomerById(customerId);
		getSarfParamaterTemplateById(parameterTemplateId);
		getModulesBySarfReportParamatersTemplateId(parameterTemplateId);
		getCompetenciesBySarfReportParametersTemplateId(parameterTemplateId);
		getDomainsBySarfReportParametersTemplateId(parameterTemplateId);
		return () => {
			unmounted = true;
		};
	}, []);

	useEffect(() => {
		calculateTotal();
	}, [templateModules]);

	const getCustomerById = (customerId) => {
		if (customerId !== null && customerId != 0) {
			setLoading(true);
			CustomerService.getById(customerId)
				.then((res) => {
					setCustomer(res.data);
					setTimeout(() => {
						setLoading(false);
					}, 1000);
				})
				.catch((err) => {
					setLoading(false);
					console.log(err);
				});
		}
	};
	const getSarfParamaterTemplateById = (id) => {
		CustomerSarfReportParametersTemplateService.getById(id)
			.then((res) => {
				setParameterTemplate(res?.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getModulesBySarfReportParamatersTemplateId = (id) => {
		CustomerSarfModuleParametersService.getModulesBySarfReportParametersTemplateId(
			id
		)
			.then((res) => {
				let total = [];
				setTemplateModules(res.data);

				const filteredSarfTemplateModules = res.data;

				for (let filter of filteredSarfTemplateModules) {
					total.push({
						SarfModule: filter?.sarfModules?.name,
						Weight: filter?.weight,
					});
				}
				setFilteredTemplateModuls(total);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getCompetenciesBySarfReportParametersTemplateId = (id) => {
		CustomerSarfModuleCompetencyService.getCompetenciesBySarfReportParametersTemplateId(
			id
		)
			.then((res) => {
				let total = [];
				setSarfCompetencies(res.data);
				const filteredSarfCompetencies = res.data;

				for (let filter of filteredSarfCompetencies) {
					total.push({
						Sarf_Module: filter?.sarfReportModuleParameters?.sarfModules?.name,
						Sarf_Competency: filter?.sarfCompetencies?.name,
						Weight: filter?.weight,
					});
				}
				setFilteredSarfCompetencies(total);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getDomainsBySarfReportParametersTemplateId = (id) => {
		CustomerSarfCompetencyDomainParametersService.getDomainsByParameterTemplateId(
			id
		)
			.then((res) => {
				let total = [];
				setSarfDomains(res.data);
				const filteredSarfDomains = res.data;

				for (let filter of filteredSarfDomains) {
					total.push({
						Sarf_Module:
							filter?.sarfReportCompetencyParameters?.sarfReportModuleParameters
								?.sarfModules?.name,
						Module_Weight:
							filter?.sarfReportCompetencyParameters?.sarfReportModuleParameters
								?.weight,
						Sarf_Competency:
							filter?.sarfReportCompetencyParameters?.sarfCompetencies?.name,
						Competency_Weight: filter?.sarfReportCompetencyParameters?.weight,
						Competency_Domains: filter?.sarfCompetencyDomains?.name,
						Domain_Weight: filter?.weight,
					});
				}
				setFilteredSarfDomains(total);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const openAddSarfTemplateModulModal = () => {
		if (
			(parameterTemplateId != 0) &
			(parameterTemplateId != undefined) &
			(parameterTemplateId != null)
		) {
			setAddSarfTemplateModulModalOpen(true);
		} else {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('Please save customer ')}`,
			});
		}
	};
	const handleCloseAddSarfTemplateModulModal = () => {
		setAddSarfTemplateModulModalOpen(false);
	};

	const openUpdateSarfTemplateModulModal = () => {
		if (
			(parameterTemplateId != 0) &
			(parameterTemplateId != undefined) &
			(parameterTemplateId != null)
		) {
			setUpdateSarfTemplateModulModal(true);
		} else {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('Please save customer ')}`,
			});
		}
	};
	const handleCloseUpdateSarfTemplateModulModal = () => {
		setUpdateSarfTemplateModulModal(false);
	};
	const openConfigureSarfTemplateModulModal = () => {
		if (
			(parameterTemplateId != 0) &
			(parameterTemplateId != undefined) &
			(parameterTemplateId != null)
		) {
			setConfigureSarfTemplateModulModalOpen(true);
		} else {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('Please save customer ')}`,
			});
		}
	};

	const handleCloseConfigureSarfTemplateModulModal = () => {
		setConfigureSarfTemplateModulModalOpen(false);
	};

	const reject = () => {
		toast.current.show({
			severity: 'info',
			summary: `${t('appointment_alert_messages.transaction_status')}`,
			detail: `${t('appointment_alert_messages.oparation_cancel')}`,
			life: 3000,
		});
	};

	const openDomainConfigurationModal = () => {
		if (
			(parameterTemplateId != 0) &
			(parameterTemplateId != undefined) &
			(parameterTemplateId != null)
		) {
			setDomeinConfigurationModalOpen(true);
		} else {
			toast.current.show({
				severity: 'error',
				summary: `${t('appointment_alert_messages.transaction_status')}`,
				detail: `${t('Please save customer ')}`,
			});
		}
	};

	const handleCloseDomainConfigurationModal = () => {
		setDomeinConfigurationModalOpen(false);
	};

	const confirmDelete1 = (event, rowData) => {
		confirmPopup({
			target: event.currentTarget,
			message: `${t('appointment_alert_messages.want_delete_register')}`,
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: () => acceptProcess1(rowData),
			reject,
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
		});
	};
	const acceptProcess1 = (rowData) => {
		deleteSarfTemplateModul(rowData.id);
	};

	const deleteSarfTemplateModul = (id) => {
		setLoading(true);
		CustomerSarfModuleParametersService.delete(id)
			.then((res) => {
				setTimeout(() => {
					setLoading(false);
					toast.current.show({
						severity: 'success',
						summary: `${t('appointment_alert_messages.transaction_status')}`,
						detail: `${t('appointment_alert_messages.transaction_successful')}`,
						life: 3000,
					});
				}, 2000);
				getModulesBySarfReportParamatersTemplateId(parameterTemplateId);
				getCompetenciesBySarfReportParametersTemplateId(parameterTemplateId);
			})
			.catch((err) => {
				setLoading(false);
				toast.current.show({
					severity: 'error',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: `${err.response.data}`,
					life: 3000,
				});
			});
	};

	const exportExcel = (fileName) => {
		import('xlsx').then((xlsx) => {
			const worksheet = xlsx.utils.json_to_sheet(fileName);
			const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
			const excelBuffer = xlsx.write(workbook, {
				bookType: 'xlsx',
				type: 'array',
			});
			saveAsExcelFile(excelBuffer, 'files');
		});
	};
	const saveAsExcelFile = (buffer, fileName) => {
		import('file-saver').then((module) => {
			if (module && module.default) {
				let EXCEL_TYPE =
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				let EXCEL_EXTENSION = '.xlsx';
				const data = new Blob([buffer], {
					type: EXCEL_TYPE,
				});

				module.default.saveAs(
					data,
					fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
				);
			}
		});
	};

	const calculateTotal = () => {
		const sumOfWeights = templateModules?.reduce(
			(accumulator, currentValue) => {
				return accumulator + currentValue.weight;
			},
			0
		);

		setTotal(sumOfWeights);
	};

	const deleteSvg = (
		<svg
			fill='#EC0044'
			height='32'
			viewBox='0 0 20 20'
			width='24'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M11.5 4C11.5 3.17157 10.8284 2.5 10 2.5C9.17157 2.5 8.5 3.17157 8.5 4H7.5C7.5 2.61929 8.61929 1.5 10 1.5C11.3807 1.5 12.5 2.61929 12.5 4H17C17.2761 4 17.5 4.22386 17.5 4.5C17.5 4.77614 17.2761 5 17 5H16.446L15.1499 16.2292C15.0335 17.2384 14.179 18 13.1631 18H6.83688C5.821 18 4.9665 17.2384 4.85006 16.2292L3.553 5H3C2.75454 5 2.55039 4.82312 2.50806 4.58988L2.5 4.5C2.5 4.22386 2.72386 4 3 4H11.5ZM15.438 5H4.561L5.84347 16.1146C5.90169 16.6192 6.32894 17 6.83688 17H13.1631C13.6711 17 14.0983 16.6192 14.1565 16.1146L15.438 5ZM8.5 7.5C8.74546 7.5 8.94961 7.65477 8.99194 7.85886L9 7.9375V14.0625C9 14.3041 8.77614 14.5 8.5 14.5C8.25454 14.5 8.05039 14.3452 8.00806 14.1411L8 14.0625V7.9375C8 7.69588 8.22386 7.5 8.5 7.5ZM11.5 7.5C11.7455 7.5 11.9496 7.65477 11.9919 7.85886L12 7.9375V14.0625C12 14.3041 11.7761 14.5 11.5 14.5C11.2545 14.5 11.0504 14.3452 11.0081 14.1411L11 14.0625V7.9375C11 7.69588 11.2239 7.5 11.5 7.5Z'
				fill='#EC0044'
			/>
		</svg>
	);
	const editSvg = (
		<svg
			fill='#0099F7'
			height='32'
			viewBox='0 0 20 20'
			width='24'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7 7.5C7 7.22386 7.22386 7 7.5 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H7.5C7.22386 8 7 7.77614 7 7.5ZM7.5 10C7.22386 10 7 10.2239 7 10.5C7 10.7761 7.22386 11 7.5 11H12.5C12.7761 11 13 10.7761 13 10.5C13 10.2239 12.7761 10 12.5 10H7.5ZM7 13.5C7 13.2239 7.22386 13 7.5 13H9.5C9.77614 13 10 13.2239 10 13.5C10 13.7761 9.77614 14 9.5 14H7.5C7.22386 14 7 13.7761 7 13.5ZM6 2.5C6 2.22386 6.22386 2 6.5 2C6.77614 2 7 2.22386 7 2.5V3H9.5V2.5C9.5 2.22386 9.72386 2 10 2C10.2761 2 10.5 2.22386 10.5 2.5V3H13V2.5C13 2.22386 13.2239 2 13.5 2C13.7761 2 14 2.22386 14 2.5V3H14.5C15.3284 3 16 3.67157 16 4.5V9.23163C15.6738 9.37139 15.3683 9.5744 15.102 9.84065L15 9.94266V4.5C15 4.22386 14.7761 4 14.5 4H5.5C5.22386 4 5 4.22386 5 4.5V16.5C5 16.7761 5.22386 17 5.5 17H9.22047L9.05716 17.6532C9.02795 17.7701 9.01015 17.8859 9.00286 18H5.5C4.67157 18 4 17.3284 4 16.5V4.5C4 3.67157 4.67157 3 5.5 3H6V2.5ZM15.8092 10.5478L10.9798 15.3772C10.6982 15.6588 10.4984 16.0116 10.4018 16.3979L10.0274 17.8958C9.86451 18.5472 10.4546 19.1372 11.106 18.9744L12.6039 18.5999C12.9902 18.5033 13.343 18.3036 13.6246 18.022L18.454 13.1926C19.1843 12.4622 19.1843 11.2781 18.454 10.5478C17.7237 9.81741 16.5395 9.81741 15.8092 10.5478Z'
				fill='#0099F7'
			/>
		</svg>
	);
	const actionBodyTemplate1 = (rowData) => {
		return (
			<>
				{' '}
				<Button
					type='button'
					tooltip={t('delete')}
					onClick={(event) => {
						confirmDelete1(event, rowData);
					}}
					className='p-button-text'
					style={{ display: 'flex', justifyContent: 'center' }}
				>
					{deleteSvg}
				</Button>
				<Button
					type='button'
					tooltip={t('edit')}
					onClick={() => {
						openUpdateSarfTemplateModulModal();
						setSelectedSarfTemplateModul(rowData);
					}}
					className='p-button-text'
					style={{ display: 'flex', justifyContent: 'center' }}
				>
					{editSvg}
				</Button>
				<Button
					type='button'
					tooltip={t('configure')}
					onClick={() => {
						openConfigureSarfTemplateModulModal();
						setSelectedSarfTemplateModul(rowData);
					}}
					className='p-button-text'
					style={{ display: 'flex', justifyContent: 'center' }}
				>
					<SettingsIcon
						sx={{ color: 'gray', backgroundColor: 'white' }}
					></SettingsIcon>
				</Button>
			</>
		);
	};
	const actionBodyTemplate2 = (rowData) => {
		return (
			<>
				{' '}
				<Button
					type='button'
					tooltip={t('configure')}
					className='p-button-text'
					onClick={() => {
						openDomainConfigurationModal();
						setSelectedSarfTemplateCompetency(rowData);
					}}
					style={{ display: 'flex', justifyContent: 'center' }}
				>
					<SettingsIcon
						sx={{ color: 'gray', backgroundColor: 'white' }}
					></SettingsIcon>
				</Button>
			</>
		);
	};
	const tableTotalFooter = () => {
		return (
			<>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					<div>
						<span style={{ fontWeight: 'bold' }}>{t('total')}:</span>
					</div>
					<div>
						{' '}
						<span>{total}</span>
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			<ConfirmDialog />
			<ConfirmPopup />
			<Spinner loading={loading}></Spinner>
			<Toast ref={toast}></Toast>

			<div className='card card-custom gutter-b'>
				<div className='card-header'>
					<div className='card-title'>
						<h3 className='card-label'>
							{t('parameter_template_configuration')}
						</h3>
					</div>
					<div className='card-toolbar'>
						<ul className='ust_menu'>
							<li tooltip={t('turn_back')} onClick={handleOnClick}>
								<i className='pi pi-arrow-left' />
							</li>
						</ul>
					</div>
				</div>
				<div className='card-body'>
					<form className='p-fluid grid'>
						<div>
							<span style={{ marginRight: '1rem', fontWeight: 'bold' }}>
								{t('customer_name')}:
							</span>
							<span>{customer?.name}</span>
						</div>
						<div className='col-12'> </div>

						<div>
							<span style={{ marginRight: '1rem', fontWeight: 'bold' }}>
								{t('sarf_parameter_template_name')}:
							</span>
							<span>{parameterTemplate?.name}</span>
						</div>
						<div className='field col-3 md:col-4'></div>
					</form>
				</div>
				<div className='card'>
					{' '}
					<TabView
						activeIndex={activeIndex}
						onTabChange={(e) => setActiveIndex(e.index)}
					>
						<TabPanel header={t('moduls_and_competencies')}>
							<div style={{ width: '66%' }}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										width: '100%',
										marginLeft: '1.5%',
										paddingRight: '0',
										marginBottom: '1%',
									}}
								>
									<div>
										<Button
											type='button'
											onClick={() => {
												exportExcel(filteredTemplateModules);
											}}
											className='p-button-success mr-2'
											tooltip='XLS'
											icon='pi pi-file-excel'
										></Button>
									</div>
									<div>
										{' '}
										<Button
											type='button'
											icon='pi pi-plus'
											onClick={() => {
												openAddSarfTemplateModulModal();
											}}
											className='p-button-raised p-button-rounded p-button-outlined mr-3'
											label={t('create_sarf_template_module')}
										></Button>
									</div>
								</div>

								<DataTable
									showGridlines
									scrollable
									value={templateModules}
									scrollHeight='flex'
									dataKey='id'
									selection={selectedList}
									onValueChange={(e) => setSelectedList(e.value)}
									filters={filters1}
									filterDisplay='row'
									tableStyle={{
										height: '100%',
									}}
									globalFilterFields={['sarfModules.name', 'weight']}
									emptyMessage={t('no_records_found')}
									currentPageReportTemplate={t('currentPageReportTemplate')}
								>
									<Column
										style={{ maxWidth: '160px' }}
										headerStyle={{ textAlign: 'center' }}
										bodyStyle={{
											textAlign: 'center',
											height: '4rem',
										}}
										body={actionBodyTemplate1}
										header={t('action')}
									/>
									<Column
										headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
										bodyStyle={{
											minWidth: '8rem',
											height: '4rem',
										}}
										filter
										sortable={true}
										field='sarfModules.name'
										header={t('sarf_module')}
									></Column>
									<Column
										headerStyle={{
											minWidth: '8rem',
											justifyContent: 'flex-end',
										}}
										bodyStyle={{
											minWidth: '8rem',
											height: '4rem',
											justifyContent: 'flex-end',
										}}
										footer={tableTotalFooter}
										sortable={true}
										filter
										field='weight'
										header={t('contribution_weight') + ' (%) '}
									></Column>
								</DataTable>
							</div>
						</TabPanel>

						<TabPanel header={t('competencies_and_competency_domains')}>
							<div style={{ width: '66%' }}>
								<div
									style={{
										width: '100%',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'flex-start',
											width: '100%',
											marginLeft: '1.5%',
											paddingRight: '1.5%',
											marginBottom: '1%',
										}}
									>
										<div>
											<Button
												type='button'
												className='p-button-success mr-2'
												icon='pi pi-file-excel'
												onClick={() => {
													exportExcel(filteredSarfCompetencies);
												}}
												tooltip='XLS'
											></Button>
										</div>
									</div>
								</div>

								<DataTable
									value={sarfCompetencies}
									responsiveLayout='scroll'
									paginator
									showGridlines
									scrollable
									scrollHeight='flex'
									rows={10}
									paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
									rowsPerPageOptions={[10, 25, 50]}
									dataKey='id'
									selection={selectedList}
									onValueChange={(e) => setSelectedList(e.value)}
									filters={filters2}
									filterDisplay='row'
									tableStyle={{
										height: '100%',
									}}
									globalFilterFields={[
										'sarfReportModuleParameters.sarfModules.name',
										'sarfCompetencies.name',
										'weight',
									]}
									emptyMessage={t('no_records_found')}
									currentPageReportTemplate={t('currentPageReportTemplate')}
								>
									<Column
										style={{ maxWidth: '90px' }}
										headerStyle={{ minWidth: '4rem', textAlign: 'center' }}
										bodyStyle={{
											height: '4rem',
											textAlign: 'center',
										}}
										body={actionBodyTemplate2}
										header={t('action')}
									/>
									<Column
										headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
										bodyStyle={{
											height: '4rem',
										}}
										field='sarfReportModuleParameters.sarfModules.name'
										filter
										sortable={true}
										header={t('sarf_module')}
									></Column>
									<Column
										headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
										bodyStyle={{
											height: '4rem',
										}}
										filter
										sortable={true}
										field='sarfCompetencies.name'
										header={t('sarf_competency')}
									></Column>
									<Column
										headerStyle={{
											minWidth: '8rem',
											justifyContent: 'flex-end',
										}}
										bodyStyle={{
											minWidth: '8rem',
											height: '4rem',
											justifyContent: 'flex-end',
										}}
										filter
										sortable={true}
										field='weight'
										header={t('contribution_weight') + ' (%) '}
									></Column>
								</DataTable>
							</div>
						</TabPanel>
						<TabPanel header={t('all_list')}>
							<div style={{ width: '100%' }}>
								<div
									style={{
										width: '100%',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'flex-start',
											width: '100%',
											marginLeft: '1.0%',
											paddingRight: '1.5%',
											marginBottom: '1%',
										}}
									>
										<div>
											<Button
												type='button'
												className='p-button-success mr-2'
												icon='pi pi-file-excel'
												onClick={() => {
													exportExcel(filteredSarfDomains);
												}}
												tooltip='XLS'
											></Button>
										</div>
									</div>
								</div>

								<DataTable
									value={sarfDomains}
									responsiveLayout='scroll'
									paginator
									showGridlines
									scrollable
									scrollHeight='flex'
									rows={10}
									paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
									rowsPerPageOptions={[10, 25, 50]}
									dataKey='id'
									selection={selectedList}
									onValueChange={(e) => setSelectedList(e.value)}
									filters={filters3}
									filterDisplay='row'
									tableStyle={{
										height: '100%',
									}}
									globalFilterFields={[
										'sarfCompetencyDomains.name',
										'sarfReportCompetencyParameters.sarfReportModuleParameters.sarfModules.name',
										'sarfReportCompetencyParameters.sarfCompetencies.name',
										'sarfReportCompetencyParameters.sarfReportModuleParameters.weight',
										'sarfReportCompetencyParameters.weight',
									]}
									emptyMessage={t('no_records_found')}
									currentPageReportTemplate={t('currentPageReportTemplate')}
								>
									<Column
										headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
										bodyStyle={{
											height: '4rem',
										}}
										field='sarfReportCompetencyParameters.sarfReportModuleParameters.sarfModules.name'
										filter
										sortable={true}
										header={t('sarf_module')}
									></Column>
									<Column
										headerStyle={{
											minWidth: '8rem',
											justifyContent: 'flex-end',
										}}
										bodyStyle={{
											minWidth: '8rem',
											height: '4rem',
											justifyContent: 'flex-end',
										}}
										filter
										sortable={true}
										field='sarfReportCompetencyParameters.sarfReportModuleParameters.weight'
										header={t('contribution_weight') + ' (%) '}
									></Column>
									<Column
										headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
										bodyStyle={{
											height: '4rem',
										}}
										filter
										sortable={true}
										field='sarfReportCompetencyParameters.sarfCompetencies.name'
										header={t('sarf_competencies')}
									></Column>
									<Column
										headerStyle={{
											minWidth: '8rem',
											justifyContent: 'flex-end',
										}}
										bodyStyle={{
											minWidth: '8rem',
											height: '4rem',
											justifyContent: 'flex-end',
										}}
										filter
										sortable={true}
										field='sarfReportCompetencyParameters.weight'
										header={t('contribution_weight') + ' (%) '}
									></Column>
									<Column
										headerStyle={{ minWidth: '8rem' }}
										bodyStyle={{
											height: '4rem',
										}}
										filter
										sortable={true}
										field='sarfCompetencyDomains.name'
										header={t('sarf_competency_domains')}
									></Column>
									<Column
										headerStyle={{
											minWidth: '8rem',
											justifyContent: 'flex-end',
										}}
										bodyStyle={{
											minWidth: '8rem',
											height: '4rem',
											justifyContent: 'flex-end',
										}}
										filter
										sortable={true}
										field='weight'
										header={t('contribution_weight') + ' (%) '}
									></Column>
								</DataTable>
							</div>
						</TabPanel>
					</TabView>
				</div>

				<AddParameterModulModal
					getModulesBySarfReportParamatersTemplateId={
						getModulesBySarfReportParamatersTemplateId
					}
					getCompetenciesBySarfReportParametersTemplateId={
						getCompetenciesBySarfReportParametersTemplateId
					}
					getDomainsBySarfReportParametersTemplateId={
						getDomainsBySarfReportParametersTemplateId
					}
					customerId={customerId}
					parameterTemplateId={parameterTemplateId}
					addSarfTemplateModulModalOpen={addSarfTemplateModulModalOpen}
					handleCloseAddSarfTemplateModulModal={
						handleCloseAddSarfTemplateModulModal
					}
					setLoading={setLoading}
				></AddParameterModulModal>

				<UpdateParameterModulModal
					getModulesBySarfReportParamatersTemplateId={
						getModulesBySarfReportParamatersTemplateId
					}
					getCompetenciesBySarfReportParametersTemplateId={
						getCompetenciesBySarfReportParametersTemplateId
					}
					getDomainsBySarfReportParametersTemplateId={
						getDomainsBySarfReportParametersTemplateId
					}
					customerId={customerId}
					parameterTemplateId={parameterTemplateId}
					selectedSarfTemplateModul={selectedSarfTemplateModul}
					setLoading={setLoading}
					handleCloseUpdateSarfTemplateModulModal={
						handleCloseUpdateSarfTemplateModulModal
					}
					updateSarfTemplateModulModalOpen={updateSarfTemplateModulModalOpen}
				></UpdateParameterModulModal>

				<CompetencyConfigurationModal
					exportExcel={exportExcel}
					getCompetenciesBySarfReportParametersTemplateId={
						getCompetenciesBySarfReportParametersTemplateId
					}
					getDomainsBySarfReportParametersTemplateId={
						getDomainsBySarfReportParametersTemplateId
					}
					customerId={customerId}
					parameterTemplateId={parameterTemplateId}
					selectedSarfTemplateModul={selectedSarfTemplateModul}
					setLoading={setLoading}
					handleCloseConfigureSarfTemplateModulModal={
						handleCloseConfigureSarfTemplateModulModal
					}
					configureSarfTemplateModulModalOpen={
						configureSarfTemplateModulModalOpen
					}
				></CompetencyConfigurationModal>

				<DomainConfigurationModal
					getCompetenciesBySarfReportParametersTemplateId={
						getCompetenciesBySarfReportParametersTemplateId
					}
					exportExcel={exportExcel}
					setLoading={setLoading}
					getDomainsBySarfReportParametersTemplateId={
						getDomainsBySarfReportParametersTemplateId
					}
					customerId={customerId}
					parameterTemplateId={parameterTemplateId}
					selectedSarfTemplateCompetency={selectedSarfTemplateCompetency}
					handleCloseDomainConfigurationModal={
						handleCloseDomainConfigurationModal
					}
					domainConfigurationModalOpen={domainConfigurationModalOpen}
				></DomainConfigurationModal>
			</div>
		</>
	);
};

export default withTranslation()(SarfParameterTemplateConfiguration);
