import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

// import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import 'primereact/resources/themes/saga-blue/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import 'primeflex/primeflex.min.css'; //flex
import App from './App';
import './index.css';
import './i18nextConf';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { DialogProvider } from './context/DialogComponentContext';
import AuthenticationService from './service/AuthenticationService';
import { Redirect } from 'react-router-dom';
axios.defaults.baseURL = 'https://vraglobal.neoauvra.com:5000/api/v1/';

// axios.interceptors.response.use((res) => {
//   // Add configurations here
//   if (res.status === 404) {
//     return;
//   }
//   return res;
// });
// axios.interceptors.response.use(
//   function (response) {
//     console.log(response);
//     return response;
//   },
//   function (error) {
//     if (error.response.status === 403) {
//       // acces denied
//       history.push('/');
//     } else if (error.response.status === 404) {
//       // page not found
//       history.push('404');
//     }
//     return Promise.reject(error);
//   }
// );
const loadingMarkup = (
	<div className='py-4 text-center'>
		<h3>Loading..</h3>
	</div>
);
// ReactDOM.render(<App />, document.getElementById('root'))
ReactDOM.render(
	<Suspense fallback={loadingMarkup}>
		<I18nextProvider i18n={i18next}>
			<DialogProvider>
				<App />
			</DialogProvider>
		</I18nextProvider>
	</Suspense>,
	document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
