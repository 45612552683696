/* eslint-disable no-undef */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import UserService from '../service/UserService';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import parsePhoneNumber from 'libphonenumber-js';
import { DropzoneDialog } from 'material-ui-dropzone';
import {
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Box,
  Grid,
  Tooltip,
} from '@material-ui/core';
import axios from 'axios';
import * as Yup from 'yup';
import 'yup-phone';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { MainButton } from '../components/common/MainButton';
import FormInput from '../components/common/FormInput';
import PhoneInput from '../components/common/PhoneInput';
import SelectInput from './common/SelectInput';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import './userInfo.css';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 20,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  commChannels: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  register: {
    '&:hover': {
      textDecoration: 'underline',
    },
    textTransform: 'none',
  },
}));

export const EditOperator = ({ operatorInfo, onClose, selected }) => {
  const { t } = useTranslation();
  const selectedName = selected;
  const operatorId = operatorInfo;
  const toast = useRef(null);
  // let {path} = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();

  let history = useHistory();
  const classes = useStyles();
  const { control, handleSubmit, reset } = useForm();

  const [open, setOpen] = useState(false);
  const [operators, setOperators] = useState([]);
  const [operator, setOperator] = useState(null);

  const getOperators = (selected) => {
    axios.get('/users/getOperators').then((res) => {
      const result = res.data;
      const total = [];
      for (let i = 0; i < result.length; i++) {
        total.push({
          id: result[i].name + ' ' + result[i].surname,
          fullName: result[i].name + ' ' + result[i].surname,
        });
      }
      setOperators(total);
      const filtered = total.filter((name) => name.fullName === selected);
      setOperator(filtered[0]?.fullName);
    });
  };

  const matchName = (selectedName) => {
    const filtered = operators.filter(
      (name) => name.fullName === 'Murat12 Sükuti12'
    );
  };

  const onSubmit = async (updateForm) => {};

  function handleChange(e) {
    setOperator(e.value);
  }
  //   useEffect(() => {
  //     matchName(selectedName)
  //   }, [])
  useEffect(() => {
    reset(operators);
  }, [operators]);

  useEffect(() => {
    getOperators(selected);
  }, []);

  return (
    <div className="card card-custom gutter-b" style={{ height: '300px' }}>
      <div className="card-body">
        <div className={classes.paper}>
          <Grid item={true} xs={12} sm={12} md={12} lg={12}>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <h3 className="bold text-center">Lütfen bir operatör seçiniz.</h3>
              <Controller
                control={control}
                id="autocomplete-off"
                name="operator"
                autocomplete="off"
                render={(props) => (
                  <div
                    style={{
                      marginTop: '10px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <label style={{ paddingRight: '10px' }}>
                      {t('operators')}
                    </label>
                    <Dropdown
                      value={operator}
                      style={{ width: '100%' }}
                      options={operators}
                      appendTo="self"
                      onChange={(e) => setOperator(e.value)}
                      optionLabel="fullName"
                      optionValue="id"
                    />
                  </div>
                )}
              />
              <div
                className="d-flex justify-content-around"
                style={{ marginTop: '60px' }}
              >
                <MainButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {t('UPDATE')}
                </MainButton>
                <MainButton
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={onClose}
                >
                  {t('GIVE_UP')}
                </MainButton>
              </div>
            </form>
          </Grid>
        </div>
      </div>
    </div>
  );
};
