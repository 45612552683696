import { makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import React from 'react'
import styled from 'styled-components'
import { Slash } from './Slash'

const Wrapper = styled.div`
  padding: ${(props) => (props.compact ? 0 : '20px')};
  background-color: #ffffff;
`

const useStyles = makeStyles((theme) => ({
  subtitle: {
    background: '#929ea8',
    color: theme.palette.common.white,
    padding: '10px 0 10px 20px',
  },
}))

const ComponentWrapper = ({ subtitle, children, compact }) => {
  const classes = useStyles()
  return (
    <>
      <Typography className={classes.subtitle} variant="h5" component="h2">
        <Slash compact color="#ffffff" />
        {subtitle}
      </Typography>

      <Wrapper compact={compact}>{children}</Wrapper>
    </>
  )
}

ComponentWrapper.propTypes = {
  subtitle: PropTypes.string.isRequired,
}
export default ComponentWrapper
