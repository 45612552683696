import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Toast } from 'primereact/toast';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import ButtonMaterial from '@mui/material/Button';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CustomerSarfReportUserService from '../../service/CustomerSarfReportUserService';
import axios from 'axios';

const CalculateAllAppointmentsModal = ({
	customerId,
	t,
	calculateAllAppoinmentsOpen,
	handleCloseCalculateAllAppoinmentsModal,

	selectedSarfReport,
	setLoading,
}) => {
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		name: { value: null, matchMode: FilterMatchMode.CONTAINS },
		surname: { value: null, matchMode: FilterMatchMode.CONTAINS },
		appointmentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const toast = useRef(null);
	const toastappoint = useRef(null);
	const [selectedList, setSelectedList] = useState(null);

	const [appointments, setAppointments] = useState([]);

	useEffect(() => {
		let unmounted = false;

		if (
			(selectedSarfReport != null || selectedSarfReport != undefined) &&
			calculateAllAppoinmentsOpen
		) {
			getAllAppointmentBySarfReportId(selectedSarfReport);
		} else {
			return;
		}
		return () => {
			unmounted = true;
		};
	}, [selectedSarfReport, customerId, calculateAllAppoinmentsOpen]);

	const getAllAppointmentBySarfReportId = (selectedSarfReport) => {
		setLoading(true);
		CustomerSarfReportUserService.getCompletedAppointmentByReportId(
			selectedSarfReport?.id
		)
			.then((res) => {
				setAppointments(res?.data);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const calculateAllAppointments = () => {
		setLoading(true);
		axios
			.get('/sarfReport/createSarfReport/' + selectedSarfReport?.id)
			.then((response) => {
				const data = response.data;
				// Handle the retrieved data
				toast.current.show({
					severity: 'success',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: `${t('appointment_alert_messages.transaction_successful')}`,
					life: 3000,
				});
				handleCloseCalculateAllAppoinmentsModal();
				return data;
			})
			.catch((error) => {
				// Handle any errors
				console.log(error);
				toastappoint.current.show({
					severity: 'error',
					summary: `${t('appointment_alert_messages.transaction_status')}`,
					detail: `${t('enqueueSnackbar_message.error')}`,
					life: 3000,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	// In order to put all functions  together when close
	const handleModalClose = () => {
		handleCloseCalculateAllAppoinmentsModal();
		setAppointments([]);
	};

	return (
		<>
			<Toast ref={toast} />

			<Dialog
				open={calculateAllAppoinmentsOpen}
				fullWidth={true}
				maxWidth={'md'}
				onClose={handleModalClose}
			>
				<Toast ref={toastappoint} />
				<DialogContent
					style={{
						overflowY: 'visible',
						display: 'flex',
						justifyContent: 'start',
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<DialogContentText
						style={{ marginBottom: '30px', textAlign: 'center' }}
					>
						{t('report_users')}
					</DialogContentText>

					<FormControl>
						<div className='card'>
							<DataTable
								value={appointments}
								showGridlines
								scrollable
								paginator
								scrollHeight='flex'
								dataKey='id'
								rows={6}
								paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
								rowsPerPageOptions={[6, 18, 48]}
								rowHover
								selection={selectedList}
								onValueChange={(e) => setSelectedList(e.value)}
								filters={filters}
								filterDisplay='row'
								tableStyle={{
									height: '100%',
								}}
								globalFilterFields={['appointmentId', 'name', 'surname']}
								emptyMessage={t('no_records_found')}
								currentPageReportTemplate={t('currentPageReportTemplate')}
							>
								<Column
									header={t('sequence_no')}
									headerStyle={{ width: '3rem' }}
									body={(data, options) => options.rowIndex + 1}
								></Column>

								<Column
									headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
									bodyStyle={{
										height: '4rem',
									}}
									filter
									sortable={true}
									field='appointmentId'
									header={t('appointment_id')}
								></Column>

								<Column
									headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
									bodyStyle={{
										height: '4rem',
									}}
									field='name'
									filter
									sortable={true}
									header={t('name')}
								></Column>
								<Column
									headerStyle={{ minWidth: '8rem', textAlign: 'center' }}
									bodyStyle={{
										height: '4rem',
									}}
									filter
									sortable={true}
									field='surname'
									header={t('surname')}
								></Column>
							</DataTable>
						</div>

						<DialogActions style={{ paddingRight: '0', marginTop: '0.5rem' }}>
							<ButtonMaterial className='ml-3' onClick={handleModalClose}>
								{' '}
								{t('Cancel')}
							</ButtonMaterial>
							<ButtonMaterial
								variant='contained'
								className='ml-3'
								onClick={calculateAllAppointments}
							>
								{' '}
								{t('Calculate')}
							</ButtonMaterial>
						</DialogActions>
					</FormControl>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default withTranslation()(CalculateAllAppointmentsModal);
