import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLng = ['tr'];
const availableLanguages = ['en', 'tr'];

i18n
  .use(HttpApi) // load translations using http (default                                               public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng, // fallback language is english.

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
    // Options for language detector
    detection: {
      checkWhitelist: true, // options for language detection
      order: ['localStorage'],
      caches: ['localStorage'],
    },
    debug: false,

    whitelist: availableLanguages,
    react: { useSuspense: false },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translations.json',
    },
  });

export default i18n;
