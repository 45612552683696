/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import PageTitle from './../../components/PageTitle';
import moment from 'moment';
import 'moment/locale/tr';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { withTranslation } from 'react-i18next';
import { ObjectUtils } from 'primereact//utils';

const AutoReportPreparing = ({ t }) => {
	const [reports, setReports] = useState([]);
	const [visible, setVisible] = useState(false);
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		appointmentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		userId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
		updatedAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
		fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [loading, setLoading] = useState(true);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [dateChangeLang, setDateChangeLang] = useState('tr-TR');

	const toast = useRef(null);
	const user = JSON.parse(localStorage.authenticatedUser);

	const langValue = localStorage.getItem('i18nextLng') ?? 'tr';

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };
		_filters['global'].value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const fetchUsers = () => {
		setLoading(true);
		axios.get('/reports/getCreateReportQueueCreatingReport').then((res) => {
			if (res.data != null) {
				for (const data of res.data) {
					data.createdAt = moment
						.unix(data.createdAt)
						.format('DD.MM.YYYY HH:mm');
					data.updatedAt = moment
						.unix(data.updatedAt)
						.format('DD.MM.YYYY HH:mm');
					data.appointmentId = data.appointment.id;
					data.userId = data.user.id;
					data.fullName = data.approvingUserFullName ?? '';
					// data.fullName = (data.approvingUser?.name??"")+" " + (data.approvingUser?.lastname??"");
				}
			}

			setReports(res.data);
			setLoading(false);
		});
	};
	const renderHeader = () => {
		return (
			<div className='clearfix'>
				<div className='float-right p-2'>
					<span className='p-input-icon-left'>
						<i className='pi pi-search' />
						<InputText
							value={globalFilterValue}
							className='p-inputtext-sm'
							onChange={onGlobalFilterChange}
							placeholder={t('search...')}
						/>
					</span>
				</div>
			</div>
		);
	};

	useEffect(() => {
		fetchUsers();
	}, [langValue]);

	const confirm = (id) => {
		confirmDialog({
			message: `${id} ${t(
				'appointment_alert_messages.confirm_sure_cancel_appointment'
			)}`,
			header: `${t('appointment_alert_messages.confirmation')}`,
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: `${t('yes')}`,
			rejectLabel: `${t('no')}`,
			accept: () => accept(id),
			reject: () => reject(),
		});
	};

	const accept = (id) => {
		toast.current.show({
			severity: 'info',
			summary: `${t('appointment_alert_messages.congratulations')}`,
			detail: `${t('appointment_alert_messages.appointment_delete_success')}`,
			life: 3000,
		});
		//cancelAppointment(id);
	};

	const reject = (err) => {
		toast.current.show({
			severity: 'warn',
			summary: `${t('appointment_alert_messages.denied')}`,
			detail: `${t('appointment_alert_messages.action_declined')}`,
			life: 3000,
		});
	};

	const reload = () => {
		setVisible(!visible);
	};
	useEffect(() => {
		if (langValue === 'en') {
			setDateChangeLang('en-US');
		} else {
			setDateChangeLang('tr-TR');
		}
	}, [langValue]);

	//Custom sort function for dates
	const sortDatesFunction = (event) => {
		let sortOrder = event.order;
		let sortField = event.field;

		var dates = reports;

		dates.sort((data1, data2) => {
			// ObjectUtils  is custom primereact method

			let a = ObjectUtils.resolveFieldData(data1, sortField);
			let b = ObjectUtils.resolveFieldData(data2, sortField);

			let fullDateA = a.split(' ');
			let dateA = fullDateA[0].split('.');
			let yearA = dateA[2];
			let monthA = dateA[1];
			let dayA = dateA[0];

			let timeA = fullDateA[1].split(':');

			let hourA = timeA[0];
			let minutesA = timeA[1];
			let newDateA = `${yearA}${monthA}${dayA}${hourA}${minutesA}`;

			let fullDateB = b.split(' ');

			let dateB = fullDateB[0].split('.');
			let yearB = dateB[2];
			let monthB = dateB[1];
			let dayB = dateB[0];

			let timeB = fullDateB[1].split(':');

			let hourB = timeB[0];
			let minutesB = timeB[1];
			let newDateB = `${yearB}${monthB}${dayB}${hourB}${minutesB}`;
			a = parseInt(newDateA);

			b = parseInt(newDateB);

			let result = null;

			if (a == null && b != null) result = -1;
			else if (a != null && b == null) result = 1;
			else if (a == null && b == null) result = 0;
			else if (typeof a === 'string' && typeof b === 'string')
				result = a.localeCompare(b);
			else result = a < b ? -1 : a > b ? 1 : 0;

			return sortOrder * result;
		});
		return dates;
	};

	const deleteSvg = (
		<svg
			fill='#EC0044'
			height='32'
			viewBox='0 0 20 20'
			width='32'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M11.5 4C11.5 3.17157 10.8284 2.5 10 2.5C9.17157 2.5 8.5 3.17157 8.5 4H7.5C7.5 2.61929 8.61929 1.5 10 1.5C11.3807 1.5 12.5 2.61929 12.5 4H17C17.2761 4 17.5 4.22386 17.5 4.5C17.5 4.77614 17.2761 5 17 5H16.446L15.1499 16.2292C15.0335 17.2384 14.179 18 13.1631 18H6.83688C5.821 18 4.9665 17.2384 4.85006 16.2292L3.553 5H3C2.75454 5 2.55039 4.82312 2.50806 4.58988L2.5 4.5C2.5 4.22386 2.72386 4 3 4H11.5ZM15.438 5H4.561L5.84347 16.1146C5.90169 16.6192 6.32894 17 6.83688 17H13.1631C13.6711 17 14.0983 16.6192 14.1565 16.1146L15.438 5ZM8.5 7.5C8.74546 7.5 8.94961 7.65477 8.99194 7.85886L9 7.9375V14.0625C9 14.3041 8.77614 14.5 8.5 14.5C8.25454 14.5 8.05039 14.3452 8.00806 14.1411L8 14.0625V7.9375C8 7.69588 8.22386 7.5 8.5 7.5ZM11.5 7.5C11.7455 7.5 11.9496 7.65477 11.9919 7.85886L12 7.9375V14.0625C12 14.3041 11.7761 14.5 11.5 14.5C11.2545 14.5 11.0504 14.3452 11.0081 14.1411L11 14.0625V7.9375C11 7.69588 11.2239 7.5 11.5 7.5Z'
				fill='#EC0044'
			/>
		</svg>
	);

	const approvSvg = (
		<svg
			fill='#0099F7'
			height='35'
			viewBox='0 0 20 20'
			width='35'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7.08535 3C7.29127 2.4174 7.84689 2 8.5 2H11.5C12.1531 2 12.7087 2.4174 12.9146 3H14.5C15.3284 3 16 3.67157 16 4.5V9.20703C15.6777 9.11588 15.3434 9.05337 15 9.02242V4.5C15 4.22386 14.7761 4 14.5 4H12.9146C12.7087 4.5826 12.1531 5 11.5 5H8.5C7.84689 5 7.29127 4.5826 7.08535 4H5.5C5.22386 4 5 4.22386 5 4.5V16.5C5 16.7761 5.22386 17 5.5 17H9.59971C9.78261 17.3578 10.0035 17.6929 10.2572 18H5.5C4.67157 18 4 17.3284 4 16.5V4.5C4 3.67157 4.67157 3 5.5 3H7.08535ZM8.5 3C8.22386 3 8 3.22386 8 3.5C8 3.77614 8.22386 4 8.5 4H11.5C11.7761 4 12 3.77614 12 3.5C12 3.22386 11.7761 3 11.5 3H8.5ZM19 14.5C19 16.9853 16.9853 19 14.5 19C12.0147 19 10 16.9853 10 14.5C10 12.0147 12.0147 10 14.5 10C16.9853 10 19 12.0147 19 14.5ZM16.8536 12.6464C16.6583 12.4512 16.3417 12.4512 16.1464 12.6464L13.5 15.2929L12.8536 14.6464C12.6583 14.4512 12.3417 14.4512 12.1464 14.6464C11.9512 14.8417 11.9512 15.1583 12.1464 15.3536L13.1464 16.3536C13.3417 16.5488 13.6583 16.5488 13.8536 16.3536L16.8536 13.3536C17.0488 13.1583 17.0488 12.8417 16.8536 12.6464Z'
				fill='#0099F7'
			/>
		</svg>
	);

	const editSvg = (
		<svg
			fill='#0099F7'
			height='32'
			viewBox='0 0 20 20'
			width='32'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7 7.5C7 7.22386 7.22386 7 7.5 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H7.5C7.22386 8 7 7.77614 7 7.5ZM7.5 10C7.22386 10 7 10.2239 7 10.5C7 10.7761 7.22386 11 7.5 11H12.5C12.7761 11 13 10.7761 13 10.5C13 10.2239 12.7761 10 12.5 10H7.5ZM7 13.5C7 13.2239 7.22386 13 7.5 13H9.5C9.77614 13 10 13.2239 10 13.5C10 13.7761 9.77614 14 9.5 14H7.5C7.22386 14 7 13.7761 7 13.5ZM6 2.5C6 2.22386 6.22386 2 6.5 2C6.77614 2 7 2.22386 7 2.5V3H9.5V2.5C9.5 2.22386 9.72386 2 10 2C10.2761 2 10.5 2.22386 10.5 2.5V3H13V2.5C13 2.22386 13.2239 2 13.5 2C13.7761 2 14 2.22386 14 2.5V3H14.5C15.3284 3 16 3.67157 16 4.5V9.23163C15.6738 9.37139 15.3683 9.5744 15.102 9.84065L15 9.94266V4.5C15 4.22386 14.7761 4 14.5 4H5.5C5.22386 4 5 4.22386 5 4.5V16.5C5 16.7761 5.22386 17 5.5 17H9.22047L9.05716 17.6532C9.02795 17.7701 9.01015 17.8859 9.00286 18H5.5C4.67157 18 4 17.3284 4 16.5V4.5C4 3.67157 4.67157 3 5.5 3H6V2.5ZM15.8092 10.5478L10.9798 15.3772C10.6982 15.6588 10.4984 16.0116 10.4018 16.3979L10.0274 17.8958C9.86451 18.5472 10.4546 19.1372 11.106 18.9744L12.6039 18.5999C12.9902 18.5033 13.343 18.3036 13.6246 18.022L18.454 13.1926C19.1843 12.4622 19.1843 11.2781 18.454 10.5478C17.7237 9.81741 16.5395 9.81741 15.8092 10.5478Z'
				fill='#0099F7'
			/>
		</svg>
	);

	const actionBodyTemplate = (rowData) => {
		return (
			<div
				style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
			>
				{/*
        <Button
          type="button"
          tooltip={t('approve')}
          className="p-button-text"
          onClick={() => {
            confirm(rowData.appointmentId);
          }}
        >
          {approvSvg}
        </Button>
            */}
			</div>
		);
	};

	return (
		<>
			<ConfirmDialog />
			<PageTitle>{t('menu.auto_report_preparing')}</PageTitle>
			<Toast ref={toast} />
			<div className='card card-custom gutter-b'>
				<div className='card-body'>
					<DataTable
						value={reports}
						paginator
						showGridlines
						responsiveLayout='scroll'
						header={renderHeader}
						rows={10}
						paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
						rowsPerPageOptions={[10, 25, 50]}
						dataKey='id'
						rowHover
						filters={filters}
						filterDisplay='row'
						loading={loading}
						globalFilterFields={[
							'userId',
							'appointmentId',
							'createdAt',
							'updatedAt',
							'fullName',
						]}
						emptyMessage={t('no_records_found')}
						currentPageReportTemplate={t('currentPageReportTemplate')}
					>
						<Column
							hidden={true}
							headerStyle={{ textAlign: 'center' }}
							header={t('action')}
							body={actionBodyTemplate}
							className='actionBody'
							bodyStyle={{ textAlign: 'center' }}
						/>
						<Column
							header={t('appointment_id')}
							field='appointmentId'
							sortable={true}
							filter
							style={{ maxWidth: '100px' }}
						></Column>

						<Column
							header={t('user_id')}
							sortable={true}
							field='userId'
							filter
							style={{ maxWidth: '100px' }}
						></Column>

						<Column
							header={t('created_at')}
							sortable='custom'
							sortFunction={sortDatesFunction}
							body={(rowData) => {
								var fullDate = rowData.createdAt.split(' ');

								var dateString = fullDate[0].split('.');
								var date = new Date(
									dateString[2],
									dateString[1] - 1,
									dateString[0]
								);
								var formattedDate = date.toLocaleDateString(dateChangeLang);

								return `${formattedDate} ${fullDate[1]}`;
							}}
							field='createdAt'
							filter
							style={{ maxWidth: '130px' }}
						></Column>

						<Column
							header={t('updated_at')}
							sortable='custom'
							sortFunction={sortDatesFunction}
							body={(rowData) => {
								var fullDate = rowData.updatedAt.split(' ');

								var dateString = fullDate[0].split('.');
								var date = new Date(
									dateString[2],
									dateString[1] - 1,
									dateString[0]
								);
								var formattedDate = date.toLocaleDateString(dateChangeLang);

								return `${formattedDate} ${fullDate[1]}`;
							}}
							field='updatedAt'
							filter
							style={{ maxWidth: '130px' }}
						></Column>

						<Column
							header={t('user_who_approves')}
							sortable={true}
							field='fullName'
							filter
							style={{ minWidth: '100px' }}
						></Column>
					</DataTable>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(AutoReportPreparing);
