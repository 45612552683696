import React from 'react'
import Color from 'color'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CardActionArea from '@material-ui/core/CardActionArea'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
// import { alpha } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  actionArea: {
    borderRadius: 16,
    transition: '0.2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  card: ({ color }) => ({
    minWidth: 256,
    borderRadius: 16,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: `0 6px 12px 0 ${Color(color)
        .rotate(-12)
        .darken(0.2)
        .alpha(0.5)}`,
    },
  }),
  content: ({ color }) => {
    return {
      backgroundColor: color,
      padding: '1rem 1.5rem 1.5rem',
    }
  },
  cardMedia: {
    width: 300,
    height: 250,
    padding: theme.spacing(4),
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    backgroundSize: 'cover',
  },
  title: {
    fontSize: '2rem',
    color: '#fff',
    textTransform: 'uppercase',
  },
  subtitle: {
    color: '#fff',
    opacity: 0.87,
    marginTop: '2rem',
    fontWeight: 500,
    fontSize: 14,
  },
}))

const FancyCard = ({ image, title, subtitle }) => {
  const classes = useStyles({ color: '#001529' })
  return (
    <CardActionArea className={classes.actionArea}>
      <Card className={classes.card}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src={image} className={classes.cardMedia} />
        <CardContent className={classes.content}>
          <Typography className={classes.title} variant={'h2'}>
            {title}
          </Typography>
          <Typography className={classes.subtitle}>{subtitle}</Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  )
}

export default FancyCard
